import storage from 'implementations/storage';
const get = (serial, key) => {
    var _a;
    const store = ((_a = storage.get('promark-store')) === null || _a === void 0 ? void 0 : _a[serial]) || {};
    return (key ? store[key] : store);
};
const set = (serial, key, data) => {
    const store = storage.get('promark-store') || {};
    store[serial] = Object.assign(Object.assign({}, store[serial]), { [key]: data });
    storage.set('promark-store', store);
};
const update = (serial, data) => {
    const store = storage.get('promark-store') || {};
    store[serial] = Object.assign(Object.assign({}, store[serial]), data);
    storage.set('promark-store', store);
};
export default {
    get,
    set,
    update,
};
