import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Switch } from 'antd';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import { getDefaultConfig, getPromarkLimit } from 'helpers/layer/layer-config-helper';
import { getSupportInfo } from 'app/constants/add-on';
import styles from './PresetsManagementPanel.module.scss';
const PromarkInputs = ({ preset, maxSpeed, minSpeed, isInch = false, lengthUnit = 'mm', handleChange, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const tLaserPanel = useI18n().beambox.right_panel.laser_panel;
    const t = tLaserPanel.preset_management;
    const supportInfo = useMemo(() => getSupportInfo('fpm1'), []);
    const defaultConfig = useMemo(getDefaultConfig, []);
    const focusStepMax = useMemo(() => {
        if (preset.repeat <= 1)
            return 10;
        return 10 / (preset.repeat - 1);
    }, [preset.repeat]);
    const limit = useMemo(getPromarkLimit, []);
    return (React.createElement("div", { className: styles.inputs },
        React.createElement("div", null,
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.strength),
                React.createElement(UnitInput, { "data-testid": "power", className: styles.input, disabled: preset.isDefault, value: (_a = preset.power) !== null && _a !== void 0 ? _a : defaultConfig.power, max: 100, min: 0, precision: 0, addonAfter: "%", onChange: (value) => handleChange('power', value), clipValue: true })),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.speed),
                React.createElement(UnitInput, { "data-testid": "speed", className: styles.input, disabled: preset.isDefault, value: (_b = preset.speed) !== null && _b !== void 0 ? _b : defaultConfig.speed, max: maxSpeed, min: minSpeed, precision: isInch ? 2 : 1, addonAfter: `${lengthUnit}/s`, isInch: isInch, onChange: (value) => handleChange('speed', value), clipValue: true })),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.repeat),
                React.createElement(UnitInput, { "data-testid": "repeat", className: styles.input, disabled: preset.isDefault, value: (_c = preset.repeat) !== null && _c !== void 0 ? _c : defaultConfig.repeat, max: 100, min: 0, precision: 0, addonAfter: tLaserPanel.times, onChange: (value) => handleChange('repeat', value), clipValue: true })),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.dottingTime),
                React.createElement(UnitInput, { "data-testid": "dottingTime", className: styles.input, disabled: preset.isDefault, value: (_d = preset.dottingTime) !== null && _d !== void 0 ? _d : defaultConfig.dottingTime, min: 1, max: 10000, precision: 0, addonAfter: "us", onChange: (value) => handleChange('dottingTime', value), clipValue: true })),
            supportInfo.lowerFocus && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.field },
                    React.createElement("div", { className: styles.label }, t.lower_focus_by),
                    React.createElement(UnitInput, { "data-testid": "focus", className: styles.input, disabled: preset.isDefault, value: Math.max((_e = preset.focus) !== null && _e !== void 0 ? _e : defaultConfig.focus, 0), max: 10, min: 0, precision: 2, addonAfter: lengthUnit, isInch: isInch, onChange: (value) => handleChange('focus', value > 0 ? value : -0.01), clipValue: true })),
                React.createElement("div", { className: styles.field },
                    React.createElement("div", { className: styles.label }, tLaserPanel.z_step),
                    React.createElement(UnitInput, { "data-testid": "focusStep", className: styles.input, disabled: preset.isDefault || preset.repeat <= 1, value: Math.max((_f = preset.focusStep) !== null && _f !== void 0 ? _f : defaultConfig.focusStep, 0), max: focusStepMax, min: 0, precision: 2, addonAfter: lengthUnit, isInch: isInch, onChange: (value) => handleChange('focusStep', value > 0 ? value : -0.01), clipValue: true }))))),
        React.createElement("div", null,
            limit.pulseWidth && (React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.pulse_width),
                React.createElement(UnitInput, { "data-testid": "pulseWidth", className: styles.input, disabled: preset.isDefault, value: (_g = preset.pulseWidth) !== null && _g !== void 0 ? _g : defaultConfig.pulseWidth, max: limit.pulseWidth.max, min: limit.pulseWidth.min, precision: 0, addonAfter: "ns", onChange: (value) => handleChange('pulseWidth', value), clipValue: true }))),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.frequency),
                React.createElement(UnitInput, { "data-testid": "frequency", className: styles.input, disabled: preset.isDefault, value: (_h = preset.frequency) !== null && _h !== void 0 ? _h : defaultConfig.frequency, max: limit.frequency.max, min: limit.frequency.min, precision: 0, addonAfter: "kHz", onChange: (value) => handleChange('frequency', value), clipValue: true })),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.fill_interval),
                React.createElement(UnitInput, { "data-testid": "fillInterval", className: styles.input, disabled: preset.isDefault, value: (_j = preset.fillInterval) !== null && _j !== void 0 ? _j : defaultConfig.fillInterval, max: 100, min: 0.0001, precision: 4, step: 0.0001, addonAfter: "mm", onChange: (value) => handleChange('fillInterval', value), controls: false, clipValue: true })),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.fill_angle),
                React.createElement(UnitInput, { "data-testid": "fillAngle", className: styles.input, disabled: preset.isDefault, value: (_k = preset.fillAngle) !== null && _k !== void 0 ? _k : defaultConfig.fillAngle, max: 360, min: -360, precision: 1, addonAfter: "deg", onChange: (value) => handleChange('fillAngle', value), clipValue: true })),
            React.createElement("div", { className: classNames(styles.field, styles['with-switch']) },
                React.createElement("div", { className: styles.label }, tLaserPanel.bi_directional),
                React.createElement(Switch, { "data-testid": "biDirectional", checked: (_l = preset.biDirectional) !== null && _l !== void 0 ? _l : defaultConfig.biDirectional, onChange: (value) => handleChange('biDirectional', value) })),
            React.createElement("div", { className: classNames(styles.field, styles['with-switch']) },
                React.createElement("div", { className: styles.label }, tLaserPanel.cross_hatch),
                React.createElement(Switch, { "data-testid": "crossHatch", checked: (_m = preset.crossHatch) !== null && _m !== void 0 ? _m : defaultConfig.crossHatch, onChange: (value) => handleChange('crossHatch', value) })))));
};
export default PromarkInputs;
