var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { memo, useContext } from 'react';
import { Button, ConfigProvider } from 'antd';
import ActionsPanel from 'app/views/beambox/Right-Panels/ActionsPanel';
import ConfigPanel from 'app/views/beambox/Right-Panels/ConfigPanel/ConfigPanel';
import dialogCaller from 'app/actions/dialog-caller';
import DimensionPanel from 'app/views/beambox/Right-Panels/DimensionPanel/DimensionPanel';
import FnWrapper from 'app/actions/beambox/svgeditor-function-wrapper';
import ObjectPanelIcons from 'app/icons/object-panel/ObjectPanelIcons';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import OptionsPanel from 'app/views/beambox/Right-Panels/OptionsPanel';
import useI18n from 'helpers/useI18n';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { iconButtonTheme } from 'app/constants/antd-config';
import { ObjectPanelContext } from 'app/views/beambox/Right-Panels/contexts/ObjectPanelContext';
import { SelectedElementContext } from 'app/contexts/SelectedElementContext';
import { useIsMobile } from 'helpers/system-helper';
import styles from './ObjectPanel.module.scss';
let svgCanvas;
let svgEditor;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
    svgEditor = globalSVG.Editor;
});
function ObjectPanel({ hide }) {
    const lang = useI18n();
    const tObjectpanel = lang.beambox.right_panel.object_panel;
    const isMobile = useIsMobile();
    const context = useContext(ObjectPanelContext);
    const { selectedElement: elem } = useContext(SelectedElementContext);
    const getAvailableFunctions = () => {
        if (!elem) {
            return {};
        }
        let elems = [elem];
        if (elems.length > 0 && elems[0].getAttribute('data-tempgroup') === 'true') {
            elems = Array.from(elems[0].childNodes);
        }
        const allowBooleanOperations = (e) => {
            if (['rect', 'polygon', 'ellipse'].includes(e.tagName.toLowerCase())) {
                return true;
            }
            return e.tagName.toLowerCase() === 'path' && svgCanvas.isElemFillable(e);
        };
        const isSingleGroup = (elems === null || elems === void 0 ? void 0 : elems.length) === 1 && elems[0].tagName.toLowerCase() === 'g';
        return {
            group: !isSingleGroup || (elems === null || elems === void 0 ? void 0 : elems.length) > 1,
            ungroup: isSingleGroup &&
                !elem.getAttribute('data-textpath-g') &&
                !elem.getAttribute('data-pass-through'),
            dist: (elems === null || elems === void 0 ? void 0 : elems.length) > 2,
            boolean: (elems === null || elems === void 0 ? void 0 : elems.length) > 1 && (elems === null || elems === void 0 ? void 0 : elems.every(allowBooleanOperations)),
            union: (elems === null || elems === void 0 ? void 0 : elems.length) > 1 && (elems === null || elems === void 0 ? void 0 : elems.every(allowBooleanOperations)),
            subtract: (elems === null || elems === void 0 ? void 0 : elems.length) === 2 && (elems === null || elems === void 0 ? void 0 : elems.every(allowBooleanOperations)),
            intersect: (elems === null || elems === void 0 ? void 0 : elems.length) > 1 && (elems === null || elems === void 0 ? void 0 : elems.every(allowBooleanOperations)),
            difference: (elems === null || elems === void 0 ? void 0 : elems.length) > 1 && (elems === null || elems === void 0 ? void 0 : elems.every(allowBooleanOperations)),
        };
    };
    const renderToolBtn = (label, icon, disabled, onClick, id) => (React.createElement(Button, { type: "text", id: id, icon: icon, onClick: onClick, disabled: disabled, title: label }));
    const renderCommonActionPanel = () => (React.createElement("div", { className: styles.tools },
        React.createElement(ObjectPanelItem.Item, { id: "delete", content: React.createElement(ObjectPanelIcons.Trash, null), label: lang.topbar.menu.delete, onClick: () => svgEditor.deleteSelected() }),
        React.createElement(ObjectPanelItem.Item, { id: "duplicate", content: React.createElement(ObjectPanelIcons.Duplicate, null), label: lang.topbar.menu.duplicate, onClick: () => __awaiter(this, void 0, void 0, function* () { return svgCanvas.cloneSelectedElements(20, 20); }) }),
        React.createElement(ObjectPanelItem.Item, { id: "parameter", content: React.createElement(ObjectPanelIcons.Parameter, null), label: lang.beambox.right_panel.laser_panel.parameters, onClick: () => {
                dialogCaller.addDialogComponent('config-panel', React.createElement(ConfigPanel, { UIType: "modal" }));
            }, autoClose: false })));
    const renderToolBtns = () => {
        const buttonAvailability = getAvailableFunctions();
        return isMobile ? (React.createElement("div", { className: styles.tools },
            React.createElement(ObjectPanelItem.Divider, null),
            React.createElement(ObjectPanelItem.Item, { id: "group", content: React.createElement(ObjectPanelIcons.Group, null), label: tObjectpanel.group, onClick: () => svgCanvas.groupSelectedElements(), disabled: !buttonAvailability.group }),
            React.createElement(ObjectPanelItem.Item, { id: "ungroup", content: React.createElement(ObjectPanelIcons.Ungroup, null), label: tObjectpanel.ungroup, onClick: () => svgCanvas.ungroupSelectedElement(), disabled: !buttonAvailability.ungroup }),
            React.createElement(ObjectPanelItem.ActionList, { id: "align", actions: [
                    {
                        icon: React.createElement(ObjectPanelIcons.VAlignTop, null),
                        label: tObjectpanel.top_align,
                        onClick: FnWrapper.alignTop,
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.VAlignMid, null),
                        label: tObjectpanel.middle_align,
                        onClick: FnWrapper.alignMiddle,
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.VAlignBot, null),
                        label: tObjectpanel.bottom_align,
                        onClick: FnWrapper.alignBottom,
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.HAlignLeft, null),
                        label: tObjectpanel.left_align,
                        onClick: FnWrapper.alignLeft,
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.HAlignMid, null),
                        label: tObjectpanel.center_align,
                        onClick: FnWrapper.alignCenter,
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.HAlignRight, null),
                        label: tObjectpanel.right_align,
                        onClick: FnWrapper.alignRight,
                    },
                ], content: React.createElement(ObjectPanelIcons.VAlignMid, null), label: tObjectpanel.align }),
            React.createElement(ObjectPanelItem.ActionList, { id: "distribute", actions: [
                    {
                        icon: React.createElement(ObjectPanelIcons.HDist, null),
                        label: tObjectpanel.hdist,
                        onClick: () => svgCanvas.distHori(),
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.VDist, null),
                        label: tObjectpanel.vdist,
                        onClick: () => svgCanvas.distVert(),
                    },
                ], content: React.createElement(ObjectPanelIcons.VDist, null), label: tObjectpanel.distribute, disabled: !buttonAvailability.dist }),
            React.createElement(ObjectPanelItem.ActionList, { id: "boolean", actions: [
                    {
                        icon: React.createElement(ObjectPanelIcons.Union, null),
                        label: tObjectpanel.union,
                        onClick: () => svgCanvas.booleanOperationSelectedElements('union'),
                        disabled: !buttonAvailability.union,
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.Subtract, null),
                        label: tObjectpanel.subtract,
                        onClick: () => svgCanvas.booleanOperationSelectedElements('diff'),
                        disabled: !buttonAvailability.subtract,
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.Intersect, null),
                        label: tObjectpanel.intersect,
                        onClick: () => svgCanvas.booleanOperationSelectedElements('intersect'),
                        disabled: !buttonAvailability.intersect,
                    },
                    {
                        icon: React.createElement(ObjectPanelIcons.Diff, null),
                        label: tObjectpanel.difference,
                        onClick: () => svgCanvas.booleanOperationSelectedElements('xor'),
                        disabled: !buttonAvailability.difference,
                    },
                ], content: React.createElement(ObjectPanelIcons.Union, null), label: tObjectpanel.boolean, disabled: !buttonAvailability.boolean }))) : (React.createElement("div", { className: styles.tools },
            React.createElement(ConfigProvider, { theme: iconButtonTheme },
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: classNames(styles.half, styles.left, styles.sep) },
                        renderToolBtn(tObjectpanel.hdist, React.createElement(ObjectPanelIcons.HDist, null), !buttonAvailability.dist, () => svgCanvas.distHori(), 'hdist'),
                        renderToolBtn(tObjectpanel.top_align, React.createElement(ObjectPanelIcons.VAlignTop, null), false, FnWrapper.alignTop, 'top_align'),
                        renderToolBtn(tObjectpanel.middle_align, React.createElement(ObjectPanelIcons.VAlignMid, null), false, FnWrapper.alignMiddle, 'middle_align'),
                        renderToolBtn(tObjectpanel.bottom_align, React.createElement(ObjectPanelIcons.VAlignBot, null), false, FnWrapper.alignBottom, 'bottom_align')),
                    React.createElement("div", { className: classNames(styles.half, styles.right) },
                        renderToolBtn(tObjectpanel.vdist, React.createElement(ObjectPanelIcons.VDist, null), !buttonAvailability.dist, () => svgCanvas.distVert(), 'vdist'),
                        renderToolBtn(tObjectpanel.left_align, React.createElement(ObjectPanelIcons.HAlignLeft, null), false, FnWrapper.alignLeft, 'left_align'),
                        renderToolBtn(tObjectpanel.center_align, React.createElement(ObjectPanelIcons.HAlignMid, null), false, FnWrapper.alignCenter, 'center_align'),
                        renderToolBtn(tObjectpanel.right_align, React.createElement(ObjectPanelIcons.HAlignRight, null), false, FnWrapper.alignRight, 'right_align'))),
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: classNames(styles.half, styles.left) },
                        renderToolBtn(tObjectpanel.group, React.createElement(ObjectPanelIcons.Group, null), !buttonAvailability.group, () => svgCanvas.groupSelectedElements(), 'group'),
                        renderToolBtn(tObjectpanel.ungroup, React.createElement(ObjectPanelIcons.Ungroup, null), !buttonAvailability.ungroup, () => svgCanvas.ungroupSelectedElement(), 'ungroup')),
                    React.createElement("div", { className: classNames(styles.half, styles.right) },
                        renderToolBtn(tObjectpanel.union, React.createElement(ObjectPanelIcons.Union, null), !buttonAvailability.union, () => svgCanvas.booleanOperationSelectedElements('union'), 'union'),
                        renderToolBtn(tObjectpanel.subtract, React.createElement(ObjectPanelIcons.Subtract, null), !buttonAvailability.subtract, () => svgCanvas.booleanOperationSelectedElements('diff'), 'subtract'),
                        renderToolBtn(tObjectpanel.intersect, React.createElement(ObjectPanelIcons.Intersect, null), !buttonAvailability.intersect, () => svgCanvas.booleanOperationSelectedElements('intersect'), 'intersect'),
                        renderToolBtn(tObjectpanel.difference, React.createElement(ObjectPanelIcons.Diff, null), !buttonAvailability.difference, () => svgCanvas.booleanOperationSelectedElements('xor'), 'difference'))))));
    };
    const renderDimensionPanel = () => {
        const { updateDimensionValues, getDimensionValues } = context;
        return (React.createElement(DimensionPanel, { elem: elem, updateDimensionValues: updateDimensionValues, getDimensionValues: getDimensionValues }));
    };
    const renderOptionPanel = () => {
        const { polygonSides, dimensionValues, updateDimensionValues, updateObjectPanel } = context;
        return (React.createElement(OptionsPanel, { elem: elem, rx: dimensionValues.rx, polygonSides: polygonSides, updateObjectPanel: updateObjectPanel, updateDimensionValues: updateDimensionValues }));
    };
    const renderActionPanel = () => React.createElement(ActionsPanel, { elem: elem });
    const contents = isMobile ? (React.createElement(React.Fragment, null,
        renderCommonActionPanel(),
        renderOptionPanel(),
        renderDimensionPanel(),
        renderToolBtns(),
        renderActionPanel())) : (React.createElement(React.Fragment, null,
        renderToolBtns(),
        renderDimensionPanel(),
        renderOptionPanel(),
        renderActionPanel()));
    return (React.createElement("div", { id: "object-panel", className: classNames(styles.container, { [styles.hide]: hide }) }, elem ? contents : null));
}
export default memo(ObjectPanel);
