import React from 'react';
import { Flex, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import styles from './Block.module.scss';
const FieldBlock = ({ isInch, width, field, setField }) => {
    const { promark_settings: t, beambox: { document_panel: tDocu }, } = useI18n();
    const { offsetX, offsetY, angle } = field;
    return (React.createElement(Flex, { className: styles.block, vertical: true, gap: 8 },
        React.createElement("div", { className: styles.title }, t.field),
        React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
            React.createElement(Flex, { align: "center" },
                React.createElement("span", { className: styles.label }, tDocu.workarea),
                React.createElement(Tooltip, { title: t.workarea_hint },
                    React.createElement(QuestionCircleOutlined, { className: styles.tooltip }))),
            React.createElement(UnitInput, { className: styles.input, size: "small", value: width, addonAfter: "mm", disabled: true })),
        React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
            React.createElement("span", { className: styles.label }, t.offsetX),
            React.createElement(UnitInput, { "data-testid": "offset-x", size: "small", className: styles.input, value: offsetX, precision: isInch ? 5 : 3, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, onChange: (val) => setField((cur) => (Object.assign(Object.assign({}, cur), { offsetX: val }))) })),
        React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
            React.createElement("span", { className: styles.label }, t.offsetY),
            React.createElement(UnitInput, { "data-testid": "offset-y", size: "small", className: styles.input, value: offsetY, precision: isInch ? 5 : 3, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, onChange: (val) => setField((cur) => (Object.assign(Object.assign({}, cur), { offsetY: val }))) })),
        React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
            React.createElement("span", { className: styles.label }, t.angle),
            React.createElement(UnitInput, { "data-testid": "angle", size: "small", className: styles.input, value: angle, precision: 3, addonAfter: "deg", step: 0.001, onChange: (val) => setField((cur) => (Object.assign(Object.assign({}, cur), { angle: val }))) }))));
};
export default FieldBlock;
