var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext } from 'react';
import { Tooltip } from 'antd';
import ExportFuncs from 'app/actions/beambox/export-funcs';
import FormatDuration from 'helpers/duration-formatter';
import useI18n from 'helpers/useI18n';
import webNeedConnectionWrapper from 'helpers/web-need-connection-helper';
import WorkareaIcons from 'app/icons/workarea/WorkareaIcons';
import { CanvasContext, CanvasMode } from 'app/contexts/CanvasContext';
import { TimeEstimationButtonContext } from 'app/contexts/TimeEstimationButtonContext';
import styles from './TimeEstimationButton.module.scss';
const TimeEstimationButton = () => {
    const { estimatedTime, setEstimatedTime } = useContext(TimeEstimationButtonContext);
    const { mode } = useContext(CanvasContext);
    const lang = useI18n().beambox.time_est_button;
    if (mode === CanvasMode.PathPreview)
        return React.createElement("div", null);
    const calculateEstimatedTime = () => __awaiter(void 0, void 0, void 0, function* () {
        webNeedConnectionWrapper(() => __awaiter(void 0, void 0, void 0, function* () {
            const estimateTime = yield ExportFuncs.estimateTime();
            setEstimatedTime(estimateTime);
        }));
    });
    const renderButton = () => (React.createElement(Tooltip, { title: lang.calculate },
        React.createElement("div", { className: styles.btn, onClick: calculateEstimatedTime },
            React.createElement(WorkareaIcons.Time, null))));
    const renderResult = () => (React.createElement("div", { className: styles.result },
        lang.estimate_time,
        " ",
        FormatDuration(Math.max(estimatedTime, 1))));
    return typeof estimatedTime === 'number' ? renderResult() : renderButton();
};
export default TimeEstimationButton;
