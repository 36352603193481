var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { Button, Modal } from 'antd';
import { Stage } from '@react-three/drei';
import Canvas from 'app/widgets/three/Canvas';
import constant from 'app/actions/beambox/constant';
import previewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import getCanvasImage from './getCanvasImage';
import Plane from './Plane';
import styles from './CurveEngraving.module.scss';
// TODO: Add unit tests
const CurveEngraving = ({ data: initData, onRemeasure, onClose }) => {
    const lang = useI18n();
    const [data, setData] = useState(initData);
    const [image, setImage] = useState();
    const [displayCanvas, setDisplayCanvas] = useState(false);
    const [displayCamera, setDisplayCamera] = useState(false);
    const [selectedIndices, setSelectedIndices] = useState(new Set());
    const [isAntdMotionCompleted, setIsAntdMotionCompleted] = useState(false);
    const { bbox } = data;
    useEffect(() => {
        setTimeout(() => {
            setIsAntdMotionCompleted(true);
            // 0.3s according to antd global config motionDurationSlow
        }, 300);
    }, []);
    const canvasImagePromise = useMemo(() => __awaiter(void 0, void 0, void 0, function* () {
        const { x, y, width, height } = bbox;
        const { dpmm } = constant;
        const res = yield getCanvasImage(x * dpmm, y * dpmm, width * dpmm, height * dpmm);
        return res;
    }), [bbox]);
    const cameraImagePromise = useMemo(() => __awaiter(void 0, void 0, void 0, function* () {
        const { x, y, width, height } = bbox;
        const { dpmm } = constant;
        const canvasUrl = previewModeBackgroundDrawer.getCameraCanvasUrl();
        if (!canvasUrl)
            return null;
        const i = new Image();
        yield new Promise((resolve) => {
            i.onload = resolve;
            i.src = canvasUrl;
        });
        const imageBitmap = yield createImageBitmap(i, x * dpmm, y * dpmm, width * dpmm, height * dpmm);
        return imageBitmap;
    }), [bbox]);
    const updateImage = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!displayCamera && !displayCanvas)
            return setImage(undefined);
        const { dpmm } = constant;
        const { width, height } = bbox;
        const outCanvas = document.createElement('canvas');
        outCanvas.width = Math.round(width * dpmm);
        outCanvas.height = Math.round(height * dpmm);
        const ctx = outCanvas.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, outCanvas.width, outCanvas.height);
        if (displayCamera) {
            const cameraImage = yield cameraImagePromise;
            if (cameraImage)
                ctx.drawImage(cameraImage, 0, 0);
        }
        if (displayCanvas) {
            const canvasImage = yield canvasImagePromise;
            ctx.drawImage(canvasImage, 0, 0);
        }
        const base64 = outCanvas.toDataURL('image/jpeg', 1);
        return setImage(base64);
    }), [bbox, displayCanvas, displayCamera, canvasImagePromise, cameraImagePromise]);
    useEffect(() => {
        updateImage();
    }, [updateImage, displayCanvas, displayCamera]);
    const toggleSelectIdx = (idx) => {
        if (selectedIndices.has(idx))
            selectedIndices.delete(idx);
        else
            selectedIndices.add(idx);
        setSelectedIndices(new Set(selectedIndices));
    };
    const handleRemeasure = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const indices = Array.from(selectedIndices).sort((a, b) => a - b);
        const newData = yield onRemeasure(indices);
        if (newData) {
            setData(newData);
            setSelectedIndices(new Set());
        }
    }), [onRemeasure, selectedIndices]);
    return (React.createElement(Modal, { title: lang.curve_engraving.preview_3d_curve, open: true, centered: true, width: 540, maskClosable: false, onCancel: onClose, footer: [
            React.createElement(Button, { key: "remeasure", onClick: handleRemeasure, disabled: selectedIndices.size === 0 }, lang.curve_engraving.remeasure),
            React.createElement(Button, { key: "close", type: "primary", onClick: onClose }, lang.buttons.done),
        ] },
        React.createElement("div", { className: styles.container }, isAntdMotionCompleted && (React.createElement(Canvas, { camera: {
                fov: 55,
                near: 0.1,
                far: 1000,
                position: [0, 0, Math.max(bbox.width, bbox.height)],
            }, gl: { antialias: true, toneMapping: THREE.NoToneMapping }, linear: true },
            React.createElement(Stage, { adjustCamera: 1, shadows: false, environment: null },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Plane, { data: data, textureSource: image, selectedIndices: selectedIndices, toggleSelectedIndex: toggleSelectIdx })))))),
        React.createElement("div", { className: styles.buttons },
            React.createElement(Button, { shape: "round", ghost: displayCanvas, type: displayCanvas ? 'primary' : 'default', onClick: () => setDisplayCanvas((cur) => !cur) }, lang.curve_engraving.apply_arkwork),
            React.createElement(Button, { shape: "round", ghost: displayCamera, type: displayCamera ? 'primary' : 'default', onClick: () => setDisplayCamera((cur) => !cur) }, lang.curve_engraving.apply_camera)),
        React.createElement("div", { className: styles.hint }, lang.curve_engraving.click_to_select_point)));
};
export default CurveEngraving;
export const showCurveEngraving = (data, onRemeasure) => __awaiter(void 0, void 0, void 0, function* () {
    if (!isIdExist('curve-engraving')) {
        return new Promise((resolve) => {
            addDialogComponent('curve-engraving', React.createElement(CurveEngraving, { data: data, onRemeasure: onRemeasure, onClose: () => {
                    popDialogById('curve-engraving');
                    resolve();
                } }));
        });
    }
    return Promise.resolve();
});
