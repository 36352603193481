var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Button, ConfigProvider } from 'antd';
import ActionPanelIcons from 'app/icons/action-panel/ActionPanelIcons';
import Dialog from 'app/actions/dialog-caller';
import dialog from 'implementations/dialog';
import FontFuncs from 'app/actions/beambox/font-funcs';
import i18n from 'helpers/i18n';
import imageEdit from 'helpers/image-edit';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import autoFit from 'app/svgedit/operations/autoFit';
import textActions from 'app/svgedit/text/textactions';
import textEdit from 'app/svgedit/text/textedit';
import textPathEdit from 'app/actions/beambox/textPathEdit';
import updateElementColor from 'helpers/color/updateElementColor';
import useI18n from 'helpers/useI18n';
import webNeedConnectionWrapper from 'helpers/web-need-connection-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { isMobile } from 'helpers/system-helper';
import { showRotaryWarped } from 'app/views/dialogs/image-edit/RotaryWarped';
import { textButtonTheme } from 'app/constants/antd-config';
import styles from './ActionsPanel.module.scss';
let svgCanvas;
let svgEditor;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
    svgEditor = globalSVG.Editor;
});
const ActionsPanel = ({ elem }) => {
    const t = useI18n();
    const lang = t.beambox.right_panel.object_panel.actions_panel;
    const replaceImage = () => __awaiter(void 0, void 0, void 0, function* () {
        setTimeout(() => ObjectPanelController.updateActiveKey(null), 300);
        const option = {
            filters: [
                {
                    name: 'Images',
                    extensions: [
                        'png',
                        'jpg',
                        'jpeg',
                        'jpe',
                        'jif',
                        'jfif',
                        'jfi',
                        'bmp',
                        'jp2',
                        'j2k',
                        'jpf',
                        'jpx',
                        'jpm',
                    ],
                },
            ],
        };
        const fileBlob = yield dialog.getFileFromDialog(option);
        if (fileBlob) {
            svgEditor.replaceBitmap(fileBlob, elem);
        }
    });
    const convertTextToPath = () => __awaiter(void 0, void 0, void 0, function* () {
        const isTextPath = elem.getAttribute('data-textpath-g');
        const textElem = isTextPath ? elem.querySelector('text') : elem;
        if (textActions.isEditing) {
            textActions.toSelectMode();
        }
        svgCanvas.clearSelection();
        yield FontFuncs.convertTextToPath(textElem);
    });
    const weldText = () => __awaiter(void 0, void 0, void 0, function* () {
        const isTextPath = elem.getAttribute('data-textpath-g');
        const textElem = isTextPath ? elem.querySelector('text') : elem;
        if (textActions.isEditing) {
            textActions.toSelectMode();
        }
        svgCanvas.clearSelection();
        yield FontFuncs.convertTextToPath(textElem, { weldingTexts: true });
    });
    const renderButtons = useCallback((id, label, onClick, icon, mobileIcon, opts = {}) => {
        const { isFullLine, isDisabled, autoClose, mobileLabel } = opts;
        return isMobile() ? (React.createElement(ObjectPanelItem.Item, { key: mobileLabel || label, id: id, content: mobileIcon, label: mobileLabel || label, onClick: onClick, disabled: isDisabled, autoClose: autoClose })) : (React.createElement("div", { className: classNames(styles['btn-container'], { [styles.half]: !isFullLine }), key: label },
            React.createElement(Button, { className: styles.btn, id: id, icon: icon, onClick: onClick, disabled: isDisabled, block: true },
                React.createElement("span", { className: styles.label }, label))));
    }, []);
    const renderAutoFitButon = (opts = {}) => {
        if (i18n.getActiveLang() === 'zh-cn')
            return null;
        return renderButtons('auto-fit', `${lang.auto_fit} (Beta)`, () => autoFit(elem), React.createElement(ActionPanelIcons.AutoFit, null), React.createElement(ActionPanelIcons.AutoFit, null), Object.assign({ isFullLine: true, autoClose: false }, opts));
    };
    const renderArrayButton = (opts = {}) => renderButtons('array', lang.array, () => svgEditor.triggerGridTool(), React.createElement(ActionPanelIcons.Array, null), React.createElement(ActionPanelIcons.ArrayMobile, null), Object.assign({ autoClose: false }, opts));
    const renderOffsetButton = (opts = {}) => renderButtons('offset', lang.offset, () => svgEditor.triggerOffsetTool(), React.createElement(ActionPanelIcons.Offset, null), React.createElement(ActionPanelIcons.Offset, null), Object.assign({ autoClose: false }, opts));
    const renderSmartNestButton = (opts = {}) => renderButtons('smart-nest', lang.smart_nest, () => Dialog.showSvgNestButtons(), React.createElement(ActionPanelIcons.SmartNest, null), React.createElement(ActionPanelIcons.SmartNest, null), Object.assign({ isFullLine: true }, opts));
    const renderImageActions = () => {
        const isShading = elem.getAttribute('data-shading') === 'true';
        const content = {
            autoFit: renderAutoFitButon(),
            smartNest: renderSmartNestButton(),
            replace_with: renderButtons('replace_with', lang.replace_with, replaceImage, React.createElement(ActionPanelIcons.Replace, null), React.createElement(ActionPanelIcons.ReplaceMobile, null), { isFullLine: true, autoClose: false, mobileLabel: lang.replace_with_short }),
            'bg-removal': renderButtons('bg-removal', lang.ai_bg_removal, () => imageEdit.removeBackground(elem), React.createElement(ActionPanelIcons.BackgroungRemoval, null), React.createElement(ActionPanelIcons.BackgroungRemovalMobile, null), { isFullLine: true, mobileLabel: lang.ai_bg_removal_short }),
            trapezoid: renderButtons('trapezoid', t.beambox.photo_edit_panel.rotary_warped, () => showRotaryWarped(elem), React.createElement(ActionPanelIcons.RotaryWarped, null), React.createElement(ActionPanelIcons.RotaryWarped, null), { isFullLine: true }),
            trace: renderButtons('trace', lang.trace, () => imageEdit.traceImage(elem), React.createElement(ActionPanelIcons.Trace, null), React.createElement(ActionPanelIcons.Trace, null), { isDisabled: isShading }),
            grading: renderButtons('grading', lang.grading, () => Dialog.showPhotoEditPanel('curve'), React.createElement(ActionPanelIcons.Grading, null), React.createElement(ActionPanelIcons.Brightness, null), { autoClose: false, mobileLabel: lang.brightness }),
            sharpen: renderButtons('sharpen', lang.sharpen, () => {
                webNeedConnectionWrapper(() => Dialog.showPhotoEditPanel('sharpen'));
            }, React.createElement(ActionPanelIcons.Sharpen, null), React.createElement(ActionPanelIcons.SharpenMobile, null), { autoClose: false }),
            crop: renderButtons('crop', lang.crop, () => Dialog.showCropPanel(), React.createElement(ActionPanelIcons.Crop, null), React.createElement(ActionPanelIcons.Crop, null), { autoClose: false }),
            bevel: renderButtons('bevel', lang.bevel, () => imageEdit.generateStampBevel(elem), React.createElement(ActionPanelIcons.Bevel, null), React.createElement(ActionPanelIcons.BevelMobile, null)),
            invert: renderButtons('invert', lang.invert, () => imageEdit.colorInvert(elem), React.createElement(ActionPanelIcons.Invert, null), React.createElement(ActionPanelIcons.Invert, null)),
            array: renderArrayButton(),
            potrace: renderButtons('potrace', lang.outline, () => imageEdit.potrace(elem), React.createElement(ActionPanelIcons.Outline, null), React.createElement(ActionPanelIcons.Outline, null)),
        };
        const contentOrder = isMobile()
            ? [
                'autoFit',
                'replace_with',
                'potrace',
                'grading',
                'sharpen',
                'crop',
                'bevel',
                'invert',
                'array',
                'trace',
                'bg-removal',
                'smartNest',
                'trapezoid',
            ]
            : [
                'autoFit',
                'replace_with',
                'bg-removal',
                'smartNest',
                'trace',
                'grading',
                'sharpen',
                'crop',
                'bevel',
                'invert',
                'array',
                'potrace',
                'trapezoid',
            ];
        const contentInOrder = contentOrder.map((key) => content[key]);
        return contentInOrder;
    };
    const renderTextActions = () => {
        const content = [
            renderAutoFitButon(),
            renderButtons('convert_to_path', lang.convert_to_path, convertTextToPath, React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), {
                isFullLine: true,
                mobileLabel: lang.outline,
            }),
            renderButtons('weld', lang.weld_text, weldText, React.createElement(ActionPanelIcons.WeldText, null), React.createElement(ActionPanelIcons.WeldText, null), {
                isFullLine: true,
            }),
            renderSmartNestButton(),
            renderArrayButton({ isFullLine: true }),
        ];
        return content;
    };
    const renderTextPathActions = () => {
        const content = [
            renderAutoFitButon(),
            renderButtons('edit_path', lang.edit_path, () => textPathEdit.editPath(elem), React.createElement(ActionPanelIcons.EditPath, null), React.createElement(ActionPanelIcons.EditPathMobile, null), { isFullLine: true }),
            renderButtons('detach_path', lang.detach_path, () => {
                const { text, path } = textPathEdit.detachText(elem);
                textEdit.renderText(text);
                svgCanvas.multiSelect([text, path], true);
            }, React.createElement(ActionPanelIcons.DecomposeTextpath, null), React.createElement(ActionPanelIcons.DecomposeTextpath, null), { isFullLine: true, mobileLabel: lang.detach_path_short }),
            renderButtons('convert_to_path', lang.convert_to_path, convertTextToPath, React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: lang.outline }),
            renderSmartNestButton(),
            renderArrayButton({ isFullLine: true }),
        ];
        return content;
    };
    const renderPathActions = () => {
        const content = [
            renderAutoFitButon(),
            renderButtons('edit_path', lang.edit_path, () => svgCanvas.pathActions.toEditMode(elem), React.createElement(ActionPanelIcons.EditPath, null), React.createElement(ActionPanelIcons.EditPathMobile, null), { isFullLine: true }),
            renderButtons('decompose_path', lang.decompose_path, () => svgCanvas.decomposePath(), React.createElement(ActionPanelIcons.Decompose, null), React.createElement(ActionPanelIcons.DecomposeMobile, null), { isFullLine: true }),
            renderSmartNestButton(),
            renderOffsetButton(),
            renderArrayButton(),
            renderButtons('simplify', lang.simplify, () => svgCanvas.simplifyPath(), React.createElement(ActionPanelIcons.Simplify, null), React.createElement(ActionPanelIcons.SimplifyMobile, null), { isFullLine: true }),
        ];
        return content;
    };
    const renderRectActions = () => {
        const content = [
            renderAutoFitButon(),
            renderButtons('convert_to_path', lang.convert_to_path, () => svgCanvas.convertToPath(elem), React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: lang.outline }),
            renderSmartNestButton(),
            renderOffsetButton(),
            renderArrayButton(),
        ];
        return content;
    };
    const renderEllipseActions = () => {
        const content = [
            renderAutoFitButon(),
            renderButtons('convert_to_path', lang.convert_to_path, () => svgCanvas.convertToPath(elem), React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: lang.outline }),
            renderSmartNestButton(),
            renderOffsetButton(),
            renderArrayButton(),
        ];
        return content;
    };
    const renderPolygonActions = () => {
        const content = [
            renderAutoFitButon(),
            renderButtons('convert_to_path', lang.convert_to_path, () => svgCanvas.convertToPath(elem), React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: lang.outline }),
            renderSmartNestButton(),
            renderOffsetButton(),
            renderArrayButton(),
        ];
        return content;
    };
    const renderLineActions = () => {
        const content = [
            renderAutoFitButon(),
            renderButtons('convert_to_path', lang.convert_to_path, () => svgCanvas.convertToPath(elem), React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: lang.outline }),
            renderSmartNestButton(),
            renderOffsetButton(),
            renderArrayButton(),
        ];
        return content;
    };
    const renderUseActions = () => {
        const content = [
            renderAutoFitButon(),
            renderButtons('disassemble_use', lang.disassemble_use, () => svgCanvas.disassembleUse2Group(), React.createElement(ActionPanelIcons.Disassemble, null), React.createElement(ActionPanelIcons.DisassembleMobile, null), { isFullLine: true }),
            renderSmartNestButton(),
            renderArrayButton({ isFullLine: true }),
        ];
        return content;
    };
    const renderGroupActions = () => {
        const content = [
            renderAutoFitButon(),
            renderSmartNestButton(),
            renderArrayButton({ isFullLine: true }),
        ];
        return content;
    };
    const renderMultiSelectActions = () => {
        const children = Array.from(elem.childNodes);
        const supportOffset = children.every((child) => !['g', 'text', 'image', 'use'].includes(child.nodeName));
        const appendOptionalButtons = (buttons) => {
            const text = children.find((child) => child.nodeName === 'text');
            const pathLike = children.find((child) => ['path', 'ellipse', 'line', 'polygon', 'rect'].includes(child.nodeName));
            if (children.length === 2 && text && pathLike) {
                buttons.push(renderButtons('create_textpath', lang.create_textpath, () => {
                    svgCanvas.ungroupTempGroup();
                    let path = pathLike;
                    if (pathLike.nodeName !== 'path') {
                        path = svgCanvas.convertToPath(path).path;
                    }
                    textPathEdit.attachTextToPath(text, path);
                    updateElementColor(text);
                }, React.createElement(ActionPanelIcons.CreateTextpath, null), React.createElement(ActionPanelIcons.CreateTextpath, null), { isFullLine: true, mobileLabel: lang.create_textpath_short }));
            }
        };
        let content = [];
        appendOptionalButtons(content);
        content = [
            renderAutoFitButon(),
            ...content,
            renderSmartNestButton(),
            renderOffsetButton({ isDisabled: !supportOffset }),
            renderArrayButton(),
        ];
        return content;
    };
    let content = null;
    if (elem) {
        const tagName = elem.tagName.toLowerCase();
        if (tagName === 'image' || tagName === 'img') {
            content = renderImageActions();
        }
        else if (tagName === 'text') {
            content = renderTextActions();
        }
        else if (tagName === 'path') {
            content = renderPathActions();
        }
        else if (tagName === 'rect') {
            content = renderRectActions();
        }
        else if (tagName === 'ellipse') {
            content = renderEllipseActions();
        }
        else if (tagName === 'polygon') {
            content = renderPolygonActions();
        }
        else if (tagName === 'line') {
            content = renderLineActions();
        }
        else if (tagName === 'use') {
            content = renderUseActions();
        }
        else if (tagName === 'g') {
            if (elem.getAttribute('data-tempgroup') === 'true') {
                content = renderMultiSelectActions();
            }
            else if (elem.getAttribute('data-textpath-g')) {
                content = renderTextPathActions();
            }
            else {
                content = renderGroupActions();
            }
        }
    }
    return isMobile() ? (React.createElement("div", { className: styles.container },
        React.createElement(ObjectPanelItem.Divider, null),
        content)) : (React.createElement("div", { className: styles.panel },
        React.createElement("div", { className: styles.title }, "ACTIONS"),
        React.createElement("div", { className: styles['btns-container'] },
            React.createElement(ConfigProvider, { theme: textButtonTheme }, content))));
};
export default ActionsPanel;
