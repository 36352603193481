var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import constant from 'app/actions/beambox/constant';
import CustomCommand from 'app/svgedit/history/CustomCommand';
import cursorIconUrl from 'app/icons/left-panel/curve-select.svg?url';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import getDevice from 'helpers/device/get-device';
import i18n from 'helpers/i18n';
import NS from 'app/constants/namespaces';
import progressCaller from 'app/actions/progress-caller';
import RawModeCurveMeasurer from 'helpers/device/curve-measurer/raw';
import RedLightCurveMeasurer from 'helpers/device/curve-measurer/red-light';
import workareaManager from 'app/svgedit/workarea';
import { CanvasMode } from 'app/contexts/CanvasContext';
import { getSupportInfo } from 'app/constants/add-on';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { getWorkarea } from 'app/constants/workarea-constants';
import { showCurveEngraving } from 'app/components/dialogs/CurveEngraving/CurveEngraving';
import { showMeasureArea } from 'app/components/dialogs/CurveEngraving/MeasureArea';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const canvasEventEmitter = eventEmitterFactory.createEventEmitter('canvas');
// TODO: add unit tests
class CurveEngravingModeController {
    constructor() {
        this.start = () => {
            this.started = true;
            this.updateBoundaryPath();
            this.toAreaSelectMode();
            canvasEventEmitter.emit('SET_MODE', CanvasMode.CurveEngraving);
        };
        this.end = () => {
            this.started = false;
            this.updateBoundaryPath();
        };
        this.back = (mode = CanvasMode.Draw) => {
            this.end();
            svgCanvas.setMode('select');
            const workarea = document.querySelector('#workarea');
            if (workarea) {
                if (mode === CanvasMode.Preview) {
                    workarea.style.cursor = 'url(img/camera-cursor.svg) 9 12, cell';
                }
                else {
                    workarea.style.cursor = 'auto';
                }
            }
            canvasEventEmitter.emit('SET_MODE', mode);
        };
        this.toAreaSelectMode = () => {
            svgCanvas.setMode('curve-engraving');
            const workarea = document.querySelector('#workarea');
            if (workarea) {
                workarea.style.cursor = `url(${cursorIconUrl}) 25 7, cell`;
            }
        };
        this.toCanvasSelectMode = () => {
            svgCanvas.setMode('select');
            const workarea = document.querySelector('#workarea');
            if (workarea) {
                workarea.style.cursor = 'auto';
            }
        };
        this.applyMeasureData = (data) => {
            this.data = Object.assign(Object.assign({}, this.data), data);
        };
        this.initMeasurer = () => __awaiter(this, void 0, void 0, function* () {
            if (this.measurer)
                yield this.clearMeasurer();
            const { device } = yield getDevice();
            if (!device)
                return false;
            const { redLight } = getSupportInfo(device.model);
            if (redLight)
                this.measurer = new RedLightCurveMeasurer(device);
            else
                this.measurer = new RawModeCurveMeasurer(device);
            progressCaller.openNonstopProgress({ id: 'init-measurer' });
            try {
                const setupRes = yield this.measurer.setup((text) => progressCaller.update('init-measurer', { message: text }));
                if (!setupRes)
                    return false;
                return true;
            }
            catch (error) {
                return false;
            }
            finally {
                progressCaller.popById('init-measurer');
            }
        });
        this.clearMeasurer = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.measurer)
                return;
            yield this.measurer.end();
            this.measurer = null;
        });
        this.remeasurePoints = (indices) => __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            let canceled = false;
            const progressId = 'remeasure-points';
            progressCaller.openSteppingProgress({
                id: progressId,
                message: lang.message.connectingMachine,
                onCancel: () => {
                    canceled = true;
                },
            });
            if (canceled) {
                progressCaller.popById(progressId);
                return null;
            }
            try {
                let completedCount = 0;
                const res = yield this.measurer.measurePoints(this.data, indices, {
                    checkCancel: () => canceled,
                    onProgressText: (text) => progressCaller.update(progressId, {
                        message: `${lang.curve_engraving.remeasuring_points} ${completedCount}/${indices.length}<br>${text}`,
                    }),
                    onPointFinished: (count) => {
                        completedCount = count;
                        progressCaller.update(progressId, { percentage: (count / indices.length) * 100 });
                    },
                });
                if (!res)
                    return null;
                this.applyMeasureData(res);
                return this.data;
            }
            catch (error) {
                return null;
            }
            finally {
                progressCaller.popById(progressId);
            }
        });
        this.setArea = (bbox) => __awaiter(this, void 0, void 0, function* () {
            let { x, y, width, height } = bbox;
            const workarea = beamboxPreference.read('workarea');
            const { width: workareaW, height: workareaH, autoFocusOffset = [0, 0, 0], } = getWorkarea(workarea);
            const leftBound = autoFocusOffset[0] > 0 ? autoFocusOffset[0] : 0;
            const rightBound = autoFocusOffset[0] < 0 ? workareaW + autoFocusOffset[0] : workareaW;
            const topBound = autoFocusOffset[1] > 0 ? autoFocusOffset[1] : 0;
            const bottomBound = autoFocusOffset[1] < 0 ? workareaH + autoFocusOffset[1] : workareaH;
            if (x < leftBound) {
                width -= leftBound - x;
                x = leftBound;
            }
            if (x + width > rightBound) {
                width = rightBound - x;
            }
            if (y < topBound) {
                height -= topBound - y;
                y = topBound;
            }
            if (y + height > bottomBound) {
                height = bottomBound - y;
            }
            if (width <= 0 || height <= 0)
                return;
            const newBBox = { x, y, width, height };
            try {
                const initMeasurerRes = yield this.initMeasurer();
                if (!initMeasurerRes) {
                    alertCaller.popUpError({ message: 'Failed to start curve engraving measurer.' });
                    return;
                }
                const res = yield showMeasureArea(newBBox, this.measurer);
                if (!res)
                    return;
                this.data = Object.assign({ bbox }, res);
                yield showCurveEngraving(this.data, this.remeasurePoints);
                this.updateAreaPath();
                canvasEventEmitter.emit('CURVE_ENGRAVING_AREA_SET');
            }
            finally {
                this.clearMeasurer();
            }
        });
        this.clearArea = (showAlert = true) => __awaiter(this, void 0, void 0, function* () {
            if (showAlert) {
                const res = yield new Promise((resolve) => alertCaller.popUp({
                    message: i18n.lang.curve_engraving.sure_to_delete,
                    buttonType: alertConstants.CONFIRM_CANCEL,
                    onConfirm: () => resolve(true),
                    onCancel: () => resolve(false),
                }));
                if (!res)
                    return;
            }
            this.data = null;
            this.updateAreaPath();
            canvasEventEmitter.emit('CURVE_ENGRAVING_AREA_SET');
        });
        this.hasArea = () => Boolean(this.data);
        this.preview = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            try {
                const initMeasurerRes = yield this.initMeasurer();
                if (!initMeasurerRes) {
                    alertCaller.popUpError({ message: 'Failed to start curve engraving measurer.' });
                    return;
                }
                yield showCurveEngraving(this.data, this.remeasurePoints);
            }
            finally {
                this.clearMeasurer();
            }
        });
        this.createContainer = () => {
            var _a;
            if (this.boundarySvg)
                return;
            this.boundarySvg = document.createElementNS(NS.SVG, 'svg');
            this.boundarySvg.setAttribute('id', 'curve-engraving-boundary');
            this.boundarySvg.setAttribute('width', '100%');
            this.boundarySvg.setAttribute('height', '100%');
            const { width, height } = workareaManager;
            this.boundarySvg.setAttribute('viewBox', `0 0 ${width} ${height}`);
            this.boundarySvg.setAttribute('style', 'pointer-events:none');
            (_a = document.getElementById('canvasBackground')) === null || _a === void 0 ? void 0 : _a.appendChild(this.boundarySvg);
        };
        this.updateContainer = () => {
            if (!this.boundarySvg)
                return;
            const { width, height } = workareaManager;
            this.boundarySvg.setAttribute('viewBox', `0 0 ${width} ${height}`);
            this.updateBoundaryPath();
        };
        this.updateBoundaryPath = () => {
            var _a;
            this.createContainer();
            if (!this.started) {
                (_a = this.boundaryPath) === null || _a === void 0 ? void 0 : _a.setAttribute('d', '');
                return;
            }
            if (!this.boundaryPath) {
                this.boundaryPath = document.createElementNS(NS.SVG, 'path');
                this.boundaryPath.setAttribute('fill', '#CCC');
                this.boundaryPath.setAttribute('fill-opacity', '0.4');
                this.boundaryPath.setAttribute('fill-rule', 'evenodd');
                this.boundaryPath.setAttribute('stroke', 'none');
                this.boundarySvg.appendChild(this.boundaryPath);
            }
            const workarea = beamboxPreference.read('workarea');
            const { width: workareaW, height: workareaH, autoFocusOffset = [0, 0, 0], } = getWorkarea(workarea);
            const { width, height } = workareaManager;
            const { dpmm } = constant;
            const leftBound = (autoFocusOffset[0] > 0 ? autoFocusOffset[0] : 0) * dpmm;
            const rightBound = (autoFocusOffset[0] < 0 ? workareaW + autoFocusOffset[0] : workareaW) * dpmm;
            const topBound = (autoFocusOffset[1] > 0 ? autoFocusOffset[1] : 0) * dpmm;
            const bottomBound = (autoFocusOffset[1] < 0 ? workareaH + autoFocusOffset[1] : workareaH) * dpmm;
            const d1 = `M0,0H${width}V${height}H0V0Z`;
            const d2 = `M${leftBound},${topBound}H${rightBound}V${bottomBound}H${leftBound}V${topBound}Z`;
            this.boundaryPath.setAttribute('d', `${d1} ${d2}`);
        };
        this.updateAreaPath = () => {
            var _a;
            this.createContainer();
            if (!this.data) {
                (_a = this.areaPath) === null || _a === void 0 ? void 0 : _a.setAttribute('d', '');
                return;
            }
            if (!this.areaPath) {
                this.areaPath = document.createElementNS(NS.SVG, 'path');
                this.areaPath.setAttribute('fill', '#1890ff');
                this.areaPath.setAttribute('fill-opacity', '0.25');
                this.areaPath.setAttribute('fill-rule', 'evenodd');
                this.areaPath.setAttribute('stroke', '#1890ff');
                this.areaPath.setAttribute('stroke-width', '5');
                this.boundarySvg.appendChild(this.areaPath);
            }
            const { width, height } = workareaManager;
            let { x, y, width: w, height: h } = this.data.bbox;
            const { dpmm } = constant;
            x *= dpmm;
            y *= dpmm;
            w *= dpmm;
            h *= dpmm;
            const d1 = `M0,0H${width}V${height}H0V0Z`;
            const d2 = `M${x},${y}H${x + w}V${y + h}H${x}V${y}Z`;
            this.areaPath.setAttribute('d', `${d1} ${d2}`);
        };
        this.loadData = (data, opts = {}) => {
            const origData = this.data;
            const cmd = new CustomCommand('Load Curve Engraving Data', () => {
                this.data = data;
            }, () => {
                this.data = origData;
            });
            const postLoadData = () => {
                this.updateContainer();
                this.updateAreaPath();
            };
            cmd.onAfter = postLoadData;
            cmd.apply();
            postLoadData();
            const { parentCmd } = opts;
            if (parentCmd)
                parentCmd.addSubCommand(cmd);
            return cmd;
        };
        this.started = false;
        this.data = null;
        canvasEventEmitter.on('canvas-change', this.updateContainer);
    }
}
const curveEngravingModeController = new CurveEngravingModeController();
export default curveEngravingModeController;
