export const blockSettingScopes = ['column', 'row'];
export const blockSettingParams = ['count', 'size', 'spacing'];
export const blockSettingValues = ['value', 'min', 'max'];
const getBlockInfo = () => ({
    count: { value: 10, min: 1, max: 20 },
    size: { value: 10, min: 1, max: Number.MAX_SAFE_INTEGER },
    spacing: { value: 5, min: 1, max: Number.MAX_SAFE_INTEGER },
});
export const getBlockSetting = () => ({
    column: getBlockInfo(),
    row: getBlockInfo(),
});
