var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
import i18n from 'helpers/i18n';
import BaseCurveMeasurer from './base';
export default class RawModeCurveMeasurer extends BaseCurveMeasurer {
    constructor() {
        super(...arguments);
        this.setup = (onProgressText) => __awaiter(this, void 0, void 0, function* () {
            const res = this.setupDevice();
            if (!res)
                return false;
            const { lang } = i18n;
            if (deviceMaster.currentControlMode !== 'raw') {
                onProgressText === null || onProgressText === void 0 ? void 0 : onProgressText(lang.message.enteringRawMode);
                yield deviceMaster.enterRawMode();
            }
            onProgressText(lang.message.homing);
            yield deviceMaster.rawHome();
            return true;
        });
        this.end = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.device)
                return;
            try {
                if (deviceMaster.currentControlMode === 'raw') {
                    yield deviceMaster.rawLooseMotor();
                    yield deviceMaster.endRawMode();
                }
            }
            catch (error) {
                console.error('Failed to end measure mode', error);
            }
        });
        this.measurePoint = (x, y, feedrate, offset, objectHeight, lowest) => __awaiter(this, void 0, void 0, function* () {
            const target = offset ? [Math.max(x - offset[0], 0), Math.max(y - offset[1], 0)] : [x, y];
            const [targetX, targetY] = target;
            yield deviceMaster.rawMove({ x: targetX, y: targetY, f: feedrate });
            if (this.currentPosition) {
                const dist = Math.hypot(targetX - this.currentPosition.x, targetY - this.currentPosition.y);
                const time = (dist / feedrate) * 60;
                yield new Promise((resolve) => setTimeout(resolve, time * 1000));
                this.currentPosition = { x: targetX, y: targetY };
            }
            const z = yield deviceMaster.rawMeasureHeight(lowest === null ? { relZ: objectHeight } : { baseZ: Math.max(lowest - objectHeight, 0) });
            return z;
        });
    }
}
