var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, InputNumber, Modal, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import alertCaller from 'app/actions/alert-caller';
import ObjectPanelIcons from 'app/icons/object-panel/ObjectPanelIcons';
import useI18n from 'helpers/useI18n';
import { solvePnPFindCorners, solvePnPCalculate, updateData, } from 'helpers/camera-calibration-helper';
import ExposureSlider from './ExposureSlider';
import styles from './SolvePnP.module.scss';
import Title from './Title';
import useCamera from './useCamera';
import { adorPnPPoints } from './solvePnPConstants';
const SolvePnP = ({ params, dh, hasNext = false, refPoints = adorPnPPoints, imgSource = 'wifi', titleLink, onClose, onNext, onBack, }) => {
    const [img, setImg] = useState(null);
    const [imgLoaded, setImgLoaded] = useState(false);
    const [points, setPoints] = useState([]);
    const [selectedPointIdx, setSelectedPointIdx] = useState(-1);
    const dragStartPos = useRef(null);
    const svgRef = useRef(null);
    const scaleRef = useRef(1);
    const imageSizeRef = useRef({ width: 0, height: 0 });
    const imgContainerRef = useRef(null);
    const zoomDelta = useRef(0);
    const zoomProcess = useRef(null);
    const zoomCenter = useRef(null);
    const lang = useI18n();
    useEffect(() => () => {
        URL.revokeObjectURL(img === null || img === void 0 ? void 0 : img.url);
    }, [img]);
    const scrollToZoomCenter = useCallback(() => {
        if (zoomCenter.current && imgContainerRef.current) {
            const { x, y } = zoomCenter.current;
            imgContainerRef.current.scrollLeft =
                x * scaleRef.current - imgContainerRef.current.clientWidth / 2;
            imgContainerRef.current.scrollTop =
                y * scaleRef.current - imgContainerRef.current.clientHeight / 2;
        }
    }, []);
    const updateScale = useCallback((newValue, scrollToCenter = false) => {
        if (scrollToCenter && imgContainerRef.current) {
            const currentCenter = {
                x: imgContainerRef.current.scrollLeft + imgContainerRef.current.clientWidth / 2,
                y: imgContainerRef.current.scrollTop + imgContainerRef.current.clientHeight / 2,
            };
            zoomCenter.current = {
                x: currentCenter.x / scaleRef.current,
                y: currentCenter.y / scaleRef.current,
            };
        }
        scaleRef.current = newValue;
        if (svgRef.current) {
            svgRef.current.style.width = `${imageSizeRef.current.width * newValue}px`;
            svgRef.current.style.height = `${imageSizeRef.current.height * newValue}px`;
            const circles = svgRef.current.querySelectorAll('circle');
            circles.forEach((c) => {
                if (c.classList.contains('center')) {
                    c.setAttribute('r', `${1 / newValue}`);
                }
                else {
                    c.setAttribute('r', `${5 / newValue}`);
                }
            });
            if (scrollToCenter)
                scrollToZoomCenter();
        }
    }, [scrollToZoomCenter]);
    const zoomToAllPoints = useCallback((targetPoints) => {
        if (!imgContainerRef.current || !(targetPoints === null || targetPoints === void 0 ? void 0 : targetPoints.length))
            return;
        const coord = targetPoints.reduce((acc, p) => {
            acc.maxX = Math.max(acc.maxX, p[0]);
            acc.maxY = Math.max(acc.maxY, p[1]);
            acc.minX = Math.min(acc.minX, p[0]);
            acc.minY = Math.min(acc.minY, p[1]);
            return acc;
        }, { maxX: 0, maxY: 0, minX: Infinity, minY: Infinity });
        const width = coord.maxX - coord.minX;
        const height = coord.maxY - coord.minY;
        const center = [(coord.maxX + coord.minX) / 2, (coord.maxY + coord.minY) / 2];
        const scaleW = imgContainerRef.current.clientWidth / width;
        const scaleH = imgContainerRef.current.clientHeight / height;
        const targetScale = Math.min(scaleW, scaleH) * 0.8;
        updateScale(targetScale);
        imgContainerRef.current.scrollLeft =
            center[0] * targetScale - imgContainerRef.current.clientWidth / 2;
        imgContainerRef.current.scrollTop =
            center[1] * targetScale - imgContainerRef.current.clientHeight / 2;
    }, [updateScale]);
    const handleImg = useCallback((imgBlob) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield solvePnPFindCorners(imgBlob, dh, refPoints);
            if (res.success) {
                const { success, blob, data } = res;
                setImg({ blob, url: URL.createObjectURL(blob), success });
                setPoints(data.points);
                zoomToAllPoints(data.points);
            }
            else if (res.success === false) {
                const { data } = res;
                if (data.info === 'NO_DATA') {
                    if (params.k && params.d) {
                        yield updateData(params);
                        return yield handleImg(imgBlob);
                    }
                    alertCaller.popUpError({
                        message: 'No chessboard data detected, please restart calibration.',
                    });
                }
                return false;
            }
        }
        catch (err) {
            alertCaller.popUpError({ message: err.message });
        }
        return true;
    }), [dh, params, refPoints, zoomToAllPoints]);
    const { exposureSetting, setExposureSetting, handleTakePicture } = useCamera(handleImg, imgSource);
    const handleContainerDragStart = useCallback((e) => {
        dragStartPos.current = {
            x: e.screenX,
            y: e.screenY,
            startX: e.currentTarget.scrollLeft,
            startY: e.currentTarget.scrollTop,
        };
    }, []);
    const handlePointDragStart = useCallback((idx, e) => {
        var _a, _b;
        e.stopPropagation();
        setSelectedPointIdx(idx);
        dragStartPos.current = {
            x: e.screenX,
            y: e.screenY,
            startX: (_a = points[idx]) === null || _a === void 0 ? void 0 : _a[0],
            startY: (_b = points[idx]) === null || _b === void 0 ? void 0 : _b[1],
            pointIdx: idx,
        };
    }, [points]);
    const handleDragMove = useCallback((e) => {
        var _a;
        if (dragStartPos.current) {
            const { x, y, startX, startY, pointIdx } = dragStartPos.current;
            const dx = e.screenX - x;
            const dy = e.screenY - y;
            if (pointIdx !== undefined) {
                (_a = imgContainerRef.current
                    .querySelectorAll('svg > g')[pointIdx]) === null || _a === void 0 ? void 0 : _a.querySelectorAll('circle').forEach((c) => {
                    c.setAttribute('cx', `${startX + dx / scaleRef.current}`);
                    c.setAttribute('cy', `${startY + dy / scaleRef.current}`);
                });
            }
            else {
                e.currentTarget.scrollLeft = startX - dx;
                e.currentTarget.scrollTop = startY - dy;
            }
        }
    }, []);
    const handleDragEnd = useCallback(() => {
        if (dragStartPos.current) {
            const { pointIdx } = dragStartPos.current;
            if (pointIdx !== undefined) {
                const circle = imgContainerRef.current
                    .querySelectorAll('svg > g')[pointIdx].querySelector('circle');
                const x = parseInt(circle.getAttribute('cx'), 10);
                const y = parseInt(circle.getAttribute('cy'), 10);
                setPoints((prev) => prev.map((p, i) => (i === pointIdx ? [x, y] : p)));
            }
        }
        dragStartPos.current = null;
    }, []);
    const handleZoom = useCallback((delta) => {
        const cur = scaleRef.current;
        const newScale = Math.round(Math.max(Math.min(2, cur + delta), 0.2) * 100) / 100;
        if (newScale === cur)
            return;
        updateScale(newScale, true);
    }, [updateScale]);
    const handleImgLoad = useCallback((e) => {
        imageSizeRef.current = {
            width: e.currentTarget.naturalWidth,
            height: e.currentTarget.naturalHeight,
        };
        setImgLoaded(true);
        zoomToAllPoints(points);
    }, [zoomToAllPoints, points]);
    const handleWheel = useCallback((e) => {
        var _a;
        // @ts-expect-error use wheelDelta if exists
        const { deltaY, wheelDelta, detail, ctrlKey } = e;
        const delta = (_a = wheelDelta !== null && wheelDelta !== void 0 ? wheelDelta : -detail) !== null && _a !== void 0 ? _a : 0;
        if (Math.abs(deltaY) >= 40) {
            // mouse
            e.preventDefault();
            e.stopPropagation();
        }
        else if (!ctrlKey)
            return;
        zoomDelta.current += delta / 12000;
        if (!zoomProcess.current) {
            zoomProcess.current = setTimeout(() => {
                if (zoomDelta.current !== 0)
                    handleZoom(zoomDelta.current);
                zoomDelta.current = 0;
                zoomProcess.current = null;
            }, 20);
        }
    }, [handleZoom]);
    useEffect(() => {
        const imgContainer = imgContainerRef.current;
        imgContainer === null || imgContainer === void 0 ? void 0 : imgContainer.addEventListener('wheel', handleWheel);
        return () => {
            imgContainer === null || imgContainer === void 0 ? void 0 : imgContainer.removeEventListener('wheel', handleWheel);
        };
    }, [handleWheel]);
    const handleDone = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield solvePnPCalculate(dh, points, refPoints);
        if (res.success) {
            const { rvec, tvec } = res.data;
            onNext(rvec, tvec);
        }
        else {
            alertCaller.popUpError({ message: 'Failed to solvePnP' });
        }
    });
    const positionText = useMemo(() => [
        lang.calibration.align_olt,
        lang.calibration.align_ort,
        lang.calibration.align_olb,
        lang.calibration.align_orb,
        lang.calibration.align_ilt,
        lang.calibration.align_irt,
        lang.calibration.align_ilb,
        lang.calibration.align_irb,
    ][selectedPointIdx], [lang, selectedPointIdx]);
    return (React.createElement(Modal, { width: "80vw", open: true, centered: true, onCancel: () => onClose(false), title: React.createElement(Title, { title: lang.calibration.camera_calibration, link: titleLink }), footer: [
            React.createElement(Button, { className: styles['footer-button'], onClick: onBack, key: "back" }, lang.buttons.back),
            React.createElement(Button, { className: styles['footer-button'], onClick: () => handleTakePicture(), key: "retry" }, lang.calibration.retake),
            React.createElement(Button, { className: styles['footer-button'], onClick: handleDone, disabled: !(img === null || img === void 0 ? void 0 : img.success), key: "done", type: "primary" }, hasNext ? lang.buttons.next : lang.buttons.done),
        ], closable: true, maskClosable: false },
        React.createElement("ol", { className: styles.steps },
            React.createElement("li", null, lang.calibration.solve_pnp_step1),
            React.createElement("li", null, lang.calibration.solve_pnp_step2)),
        React.createElement(Row, { gutter: [16, 12] },
            React.createElement(Col, { span: 16 },
                React.createElement("div", { className: styles.container },
                    React.createElement("div", { ref: imgContainerRef, className: styles['img-container'], onMouseDown: handleContainerDragStart, onMouseMove: handleDragMove, onMouseUp: handleDragEnd, onMouseLeave: handleDragEnd },
                        !img && (React.createElement(Spin, { className: styles.spin, indicator: React.createElement(LoadingOutlined, { className: styles.spinner, spin: true }) })),
                        img &&
                            (!imgLoaded ? (React.createElement("img", { src: img === null || img === void 0 ? void 0 : img.url, onLoad: handleImgLoad })) : (React.createElement("svg", { ref: svgRef, width: imageSizeRef.current.width * scaleRef.current, height: imageSizeRef.current.height * scaleRef.current, viewBox: `0 0 ${imageSizeRef.current.width} ${imageSizeRef.current.height}` },
                                React.createElement("image", { width: imageSizeRef.current.width, height: imageSizeRef.current.height, href: img === null || img === void 0 ? void 0 : img.url }),
                                points.map((p, idx) => (React.createElement("g", { 
                                    // eslint-disable-next-line react/no-array-index-key
                                    key: idx, className: classNames({ [styles.selected]: idx === selectedPointIdx }) },
                                    React.createElement("circle", { cx: p[0], cy: p[1], r: 5 / scaleRef.current, onMouseDown: (e) => handlePointDragStart(idx, e) }),
                                    React.createElement("circle", { className: classNames('center', styles.center), cx: p[0], cy: p[1], r: 1 / scaleRef.current })))))))),
                    React.createElement("div", { className: styles['zoom-block'] },
                        React.createElement("button", { type: "button", onClick: () => handleZoom(-0.2) },
                            React.createElement(ObjectPanelIcons.Minus, { width: "20", height: "20" })),
                        React.createElement("button", { type: "button", onClick: () => handleZoom(0.2) },
                            React.createElement(ObjectPanelIcons.Plus, { width: "20", height: "20" }))))),
            React.createElement(Col, { span: 8 }, selectedPointIdx >= 0 && points[selectedPointIdx] && (React.createElement(Row, { gutter: [0, 12], align: "middle" },
                React.createElement(Col, { span: 24, className: styles['point-id'] },
                    "Point #",
                    selectedPointIdx),
                React.createElement(Col, { span: 24 }, positionText),
                React.createElement(Col, { span: 4 }, "X"),
                React.createElement(Col, { span: 20 },
                    React.createElement(InputNumber, { type: "number", value: points[selectedPointIdx][0], onChange: (val) => setPoints((prev) => prev.map((p, i) => (i === selectedPointIdx ? [val, p[1]] : p))), step: 1, precision: 0, onKeyUp: (e) => e.stopPropagation(), onKeyDown: (e) => e.stopPropagation() })),
                React.createElement(Col, { span: 4 }, "Y"),
                React.createElement(Col, { span: 20 },
                    React.createElement(InputNumber, { type: "number", value: points[selectedPointIdx][1], onChange: (val) => setPoints((prev) => prev.map((p, i) => (i === selectedPointIdx ? [p[0], val] : p))), step: 1, precision: 0, onKeyUp: (e) => e.stopPropagation(), onKeyDown: (e) => e.stopPropagation() }))))),
            exposureSetting && (React.createElement(React.Fragment, null,
                React.createElement(Col, { span: 16 },
                    React.createElement(ExposureSlider, { exposureSetting: exposureSetting, setExposureSetting: setExposureSetting, onChanged: handleTakePicture })),
                React.createElement(Col, { span: 8 },
                    React.createElement("div", { className: styles.value }, exposureSetting.value)))))));
};
export default SolvePnP;
