var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Flex, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import applyRedDot from 'helpers/device/promark/apply-red-dot';
import checkDeviceStatus from 'helpers/check-device-status';
import deviceMaster from 'helpers/device-master';
import icons from 'app/icons/icons';
import promarkDataStore from 'helpers/device/promark/promark-data-store';
import storage from 'implementations/storage';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { defaultField, defaultGalvoParameters, defaultRedLight, } from 'app/constants/promark-constants';
import { getWorkarea } from 'app/constants/workarea-constants';
import { generateCalibrationTaskString, loadTaskToSwiftray, } from 'helpers/device/promark/calibration';
import { swiftrayClient } from 'helpers/api/swiftray-client';
import FieldBlock from './FieldBlock';
import LensBlock from './LensBlock';
import ParametersBlock from './ParametersBlock';
import RedDotBlock from './RedDotBlock';
import styles from './PromarkSettings.module.scss';
import blockStyles from './Block.module.scss';
const PromarkSettings = ({ device, initData, onClose }) => {
    const { global: tGlobal, promark_settings: t } = useI18n();
    const { model, serial } = device;
    const isInch = useMemo(() => storage.get('default-units') === 'inches', []);
    const [field, setField] = useState(initData.field || defaultField);
    const [redDot, setRedDot] = useState(initData.redDot || defaultRedLight);
    const [galvoParameters, setGalvoCorrection] = useState(initData.galvoParameters || defaultGalvoParameters);
    const [parameters, setParameters] = useState({ power: 100, speed: 350 });
    const { power, speed } = parameters;
    const { width } = useMemo(() => getWorkarea(model), [model]);
    const [isPreviewing, setIsPreviewing] = useState(false);
    const previewTask = useRef('');
    const markTask = useRef('');
    useEffect(() => {
        previewTask.current = '';
    }, [redDot, width]);
    const uploadPreviewTask = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!previewTask.current) {
            previewTask.current = yield generateCalibrationTaskString({ width });
        }
        yield loadTaskToSwiftray(previewTask.current, model);
    }), [model, width]);
    useEffect(() => {
        markTask.current = '';
    }, [width, power, speed]);
    const uploadMarkTask = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!markTask.current) {
            markTask.current = yield generateCalibrationTaskString({ width, power, speed });
        }
        yield loadTaskToSwiftray(markTask.current, model);
    }), [model, width, power, speed]);
    const handleUpdateParameter = (shouldApplyRedDot = false) => __awaiter(void 0, void 0, void 0, function* () {
        if (shouldApplyRedDot) {
            const { field: newField, galvoParameters: newGalvo } = applyRedDot(redDot, field, galvoParameters);
            yield deviceMaster.setField(width, newField);
            yield deviceMaster.setGalvoParameters(newGalvo);
        }
        else {
            yield deviceMaster.setField(width, field);
            yield deviceMaster.setGalvoParameters(galvoParameters);
        }
    });
    useEffect(() => {
        const abortPreview = () => setIsPreviewing(false);
        swiftrayClient.on('disconnected', abortPreview);
        return () => {
            swiftrayClient.off('disconnected', abortPreview);
        };
    }, []);
    const handlePreview = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isPreviewing) {
            yield uploadPreviewTask();
            yield handleUpdateParameter(true);
            yield deviceMaster.startFraming();
            setIsPreviewing(true);
        }
        else {
            yield deviceMaster.stopFraming();
            setIsPreviewing(false);
        }
    });
    const handleMark = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isPreviewing) {
            yield deviceMaster.stopFraming();
            setIsPreviewing(false);
            // Wait 0.5s to ensure stop before start in swiftray
            yield new Promise((resolve) => setTimeout(resolve, 500));
        }
        yield uploadMarkTask();
        yield handleUpdateParameter();
        yield deviceMaster.doPromarkCalibration();
    });
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        promarkDataStore.update(serial, { field, redDot, galvoParameters });
        try {
            if (isPreviewing)
                yield deviceMaster.stopFraming();
            yield handleUpdateParameter();
        }
        catch (error) {
            console.error('Failed to apply promark settings state', error);
        }
        onClose();
    });
    const handleCancel = () => {
        const restore = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (isPreviewing)
                    yield deviceMaster.stopFraming();
                yield deviceMaster.setField(width, initData.field || { offsetX: 0, offsetY: 0, angle: 0 });
                yield deviceMaster.setGalvoParameters(initData.galvoParameters || {
                    x: { scale: 100, bulge: 1, skew: 1, trapezoid: 1 },
                    y: { scale: 100, bulge: 1, skew: 1, trapezoid: 1 },
                });
            }
            catch (err) {
                console.error('Failed to restore from promark settings state', err);
            }
        });
        restore();
        onClose();
    };
    const footer = (React.createElement(Flex, { className: styles.footer, justify: "space-between", align: "center" },
        React.createElement(Flex, { gap: 8, align: "center" },
            React.createElement(Button, { className: styles.button, onClick: handlePreview },
                t.preview,
                isPreviewing ? (React.createElement(Spin, { indicator: React.createElement(LoadingOutlined, { className: styles.icon, spin: true }) })) : (React.createElement(icons.Play, { className: styles.icon }))),
            React.createElement(Button, { className: styles.button, onClick: handleMark }, t.mark)),
        React.createElement(Flex, { gap: 8, align: "center" },
            React.createElement(Button, { className: styles.button, onClick: handleCancel }, tGlobal.cancel),
            React.createElement(Button, { className: styles.button, type: "primary", onClick: handleSave }, tGlobal.save))));
    return (React.createElement(Modal, { open: true, centered: true, maskClosable: false, width: 620, title: t.title, onCancel: handleCancel, footer: footer },
        React.createElement("div", { className: styles.container },
            React.createElement(FieldBlock, { width: width, isInch: isInch, field: field, setField: setField }),
            React.createElement(RedDotBlock, { isInch: isInch, redDot: redDot, setRedDot: setRedDot }),
            React.createElement(LensBlock, { data: galvoParameters, setData: setGalvoCorrection }),
            React.createElement(Flex, { className: blockStyles['full-row'], justify: "space-between", align: "center", gap: 8 },
                React.createElement("div", { className: blockStyles.title }, t.mark_parameters),
                React.createElement(ParametersBlock, { isInch: isInch, parameters: parameters, setParameters: setParameters })))));
};
export const showPromarkSettings = (device) => __awaiter(void 0, void 0, void 0, function* () {
    yield deviceMaster.select(device);
    const res = yield checkDeviceStatus(device);
    if (!res)
        return;
    const { serial } = device;
    const data = promarkDataStore.get(serial);
    const id = 'promark-settings';
    if (!isIdExist(id)) {
        addDialogComponent(id, React.createElement(PromarkSettings, { device: device, initData: data, onClose: () => popDialogById(id) }));
    }
});
export default PromarkSettings;
