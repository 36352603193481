import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Button, Flex } from 'antd';
import PromarkIcons from 'app/icons/promark/PromarkIcons';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import styles from './Block.module.scss';
const LensBlock = ({ data, setData }) => {
    const { promark_settings: t } = useI18n();
    const handleSwitch = useCallback(() => setData(({ x, y }) => ({ x: y, y: x })), [setData]);
    const { x, y } = data;
    return (React.createElement(Flex, { vertical: true, className: styles['full-row'], gap: 8 },
        React.createElement("div", { className: styles['lens-title'] },
            React.createElement("div", { className: styles.title }, t.galvo_configuration),
            React.createElement("div", { className: styles.connector }),
            React.createElement(Button, { size: "small", onClick: handleSwitch }, t.switchXY)),
        React.createElement(Flex, { gap: 40, justify: "space-between" }, [
            { axis: 'x', source: x, title: 'X' },
            { axis: 'y', source: y, title: 'Y' },
        ].map(({ axis, source: { scale, bulge, skew, trapezoid }, title }) => {
            const handleChange = (key, val) => {
                setData((cur) => (Object.assign(Object.assign({}, cur), { [axis]: Object.assign(Object.assign({}, cur[axis]), { [key]: val }) })));
            };
            return (React.createElement(Flex, { key: axis, className: styles.block, align: "center", gap: 8 },
                React.createElement("div", { className: styles.subtitle }, title),
                React.createElement(Flex, { className: classNames(styles.block, styles['left-border']), vertical: true, gap: 8 },
                    React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
                        React.createElement("span", { className: styles.label }, t.scale),
                        React.createElement(UnitInput, { "data-testid": `scale-${axis}`, className: styles.input, size: "small", value: scale, precision: 3, addonAfter: "%", onChange: (val) => handleChange('scale', val) })),
                    React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
                        React.createElement("span", { className: styles.label },
                            React.createElement(PromarkIcons.Bulge, { className: styles.icon }),
                            t.bulge),
                        React.createElement(UnitInput, { "data-testid": `bulge-${axis}`, className: styles.input, size: "small", value: bulge, precision: 3, step: 0.001, onChange: (val) => handleChange('bulge', val) })),
                    React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
                        React.createElement("span", { className: styles.label },
                            React.createElement(PromarkIcons.Skew, { className: styles.icon }),
                            t.skew),
                        React.createElement(UnitInput, { "data-testid": `skew-${axis}`, className: styles.input, size: "small", value: skew, precision: 3, step: 0.001, onChange: (val) => handleChange('skew', val) })),
                    React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
                        React.createElement("span", { className: styles.label },
                            React.createElement(PromarkIcons.Trapezoid, { className: styles.icon }),
                            t.trapezoid),
                        React.createElement(UnitInput, { "data-testid": `trapezoid-${axis}`, className: styles.input, size: "small", value: trapezoid, precision: 3, step: 0.001, onChange: (val) => handleChange('trapezoid', val) })))));
        }))));
};
export default LensBlock;
