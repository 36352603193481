// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-camera-common-Instruction-module__container--BXeI9 .src-web-app-components-dialogs-camera-common-Instruction-module__step---yLDe{margin:none;white-space:pre-line}.src-web-app-components-dialogs-camera-common-Instruction-module__container--BXeI9 .src-web-app-components-dialogs-camera-common-Instruction-module__video--1x0lk{width:100%;min-height:225px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/camera/common/Instruction.module.scss"],"names":[],"mappings":"AACE,iKACE,WAAA,CACA,oBAAA,CAGF,kKACE,UAAA,CACA,gBAAA","sourcesContent":[".container {\n  .step {\n    margin: none;\n    white-space: pre-line;\n  }\n\n  .video {\n    width: 100%;\n    min-height: 225px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-dialogs-camera-common-Instruction-module__container--BXeI9",
	"step": "src-web-app-components-dialogs-camera-common-Instruction-module__step---yLDe",
	"video": "src-web-app-components-dialogs-camera-common-Instruction-module__video--1x0lk"
};
export default ___CSS_LOADER_EXPORT___;
