var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import { SpinLoading } from 'antd-mobile';
import alertCaller from 'app/actions/alert-caller';
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { updateData } from 'helpers/camera-calibration-helper';
import styles from './CheckpointData.module.scss';
import Title from './Title';
const CheckpointData = ({ allowCheckPoint = true, askUser, titleLink, getData, updateParam, onClose, onNext, }) => {
    const progressId = useMemo(() => 'camera-check-point', []);
    const [checkpointData, setCheckpointData] = useState(null);
    const lang = useI18n();
    const checkData = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({
            id: progressId,
            message: lang.calibration.checking_checkpoint,
        });
        let res = null;
        try {
            if (getData) {
                res = yield getData();
            }
            else {
                const data = yield deviceMaster.downloadFile('fisheye', 'fisheye_params.json');
                const [, blob] = data;
                const dataString = yield blob.text();
                res = JSON.parse(dataString);
            }
            if (res.v === 3) {
                setCheckpointData({
                    file: 'fisheye_params.json',
                    data: {
                        k: res.k,
                        d: res.d,
                        rvec: res.rvec,
                        tvec: res.tvec,
                    },
                });
                progressCaller.popById(progressId);
                return;
            }
            if (res.v === 2) {
                setCheckpointData({
                    file: 'fisheye_params.json',
                    data: {
                        k: res.k,
                        d: res.d,
                        rvec: res.rvec,
                        tvec: res.tvec,
                        refHeight: res.refHeight,
                        source: res.source,
                    },
                });
                progressCaller.popById(progressId);
                return;
            }
        }
        catch (_a) {
            /* do nothing */
        }
        if (allowCheckPoint) {
            try {
                const data = yield deviceMaster.downloadFile('fisheye', 'checkpoint.json');
                const [, blob] = data;
                const dataString = yield blob.text();
                res = JSON.parse(dataString);
                if (res) {
                    setCheckpointData({
                        file: 'checkpoint.json',
                        data: res,
                    });
                    progressCaller.popById(progressId);
                    return;
                }
            }
            catch (_b) {
                /* do nothing */
            }
        }
        progressCaller.popById(progressId);
        onNext(false);
    }), [lang, allowCheckPoint, getData, progressId, onNext]);
    const handleOk = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({
            id: progressId,
            message: lang.calibration.downloading_checkpoint,
        });
        try {
            const { data } = checkpointData;
            try {
                yield updateData(data);
                updateParam(data);
            }
            catch (e) {
                console.error(e);
                alertCaller.popUpError({ message: lang.calibration.failed_to_parse_checkpoint });
                onNext(false);
            }
            onNext(true);
        }
        finally {
            progressCaller.popById(progressId);
        }
    }), [checkpointData, lang, onNext, progressId, updateParam]);
    useEffect(() => {
        checkData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (checkpointData && !askUser)
            handleOk();
    }, [checkpointData, askUser, handleOk]);
    if (!askUser)
        return (React.createElement(Modal, { width: 400, open: true, centered: true, maskClosable: false, closable: !!onClose, onCancel: () => onClose === null || onClose === void 0 ? void 0 : onClose(false), footer: [] },
            React.createElement(SpinLoading, { className: styles.spinner, color: "primary", style: { '--size': '48px' } })));
    return (React.createElement(Modal, { width: 400, open: true, centered: true, maskClosable: false, title: React.createElement(Title, { title: lang.calibration.check_checkpoint_data, link: titleLink }), closable: !!onClose, onCancel: () => onClose === null || onClose === void 0 ? void 0 : onClose(false), footer: [
            React.createElement(Button, { key: "no", onClick: () => onNext(false) }, lang.alert.no),
            React.createElement(Button, { key: "yes", type: "primary", onClick: handleOk }, lang.alert.yes),
        ] },
        !checkpointData && lang.calibration.checking_checkpoint,
        (checkpointData === null || checkpointData === void 0 ? void 0 : checkpointData.data) &&
            (checkpointData.file === 'fisheye_params.json'
                ? lang.calibration.use_old_camera_parameter
                : lang.calibration.found_checkpoint)));
};
export default CheckpointData;
