import classNames from 'classnames';
import React, { memo, useCallback, useState } from 'react';
import { SettingFilled, SettingOutlined } from '@ant-design/icons';
import FillSettingModal from 'app/views/beambox/Right-Panels/ConfigPanel/FillSettingModal';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import useI18n from 'helpers/useI18n';
import styles from './FillBlock.module.scss';
function FillBlock({ type = 'default', }) {
    const lang = useI18n();
    const t = lang.beambox.right_panel.laser_panel;
    const [showModal, setShowModal] = useState(false);
    const openModal = useCallback(() => setShowModal(true), []);
    const closeModal = useCallback(() => setShowModal(false), []);
    return (React.createElement(React.Fragment, null,
        type === 'panel-item' ? (React.createElement(ObjectPanelItem.Item, { id: "fill-setting", content: React.createElement(SettingFilled, { className: styles['panel-icon'] }), label: t.fill_setting, onClick: openModal })) : (React.createElement("div", { className: classNames(styles.panel, styles[type]) },
            React.createElement("span", { className: styles.title },
                t.fill_setting,
                React.createElement("span", { className: styles.icon, title: t.fill_setting, onClick: openModal },
                    React.createElement(SettingOutlined, { className: styles.icon }))))),
        showModal && React.createElement(FillSettingModal, { onClose: closeModal })));
}
export default memo(FillBlock);
