var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useEffect } from 'react';
import { Input as AntdInput } from 'antd';
import communicator from 'implementations/communicator';
const setEditingInput = () => communicator.send('SET_EDITING_STANDARD_INPUT', true);
const setStopEditingInput = () => communicator.send('SET_EDITING_STANDARD_INPUT', false);
/**
 * Basically Antd Input Wrapper
 * with onFocus and onBlur to setEditingInput and setStopEditingInput in order to disable electron shortcuts
 */
const Input = forwardRef((_a, ref) => {
    var { onFocus, onBlur } = _a, props = __rest(_a, ["onFocus", "onBlur"]);
    useEffect(() => () => setStopEditingInput(), []);
    return (React.createElement(AntdInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { ref: ref, onFocus: (e) => {
            setEditingInput();
            onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
        }, onBlur: (e) => {
            setStopEditingInput();
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        } })));
});
export default Input;
