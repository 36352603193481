var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import { sprintf } from 'sprintf-js';
import alertCaller from 'app/actions/alert-caller';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import webcamHelper from 'helpers/webcam-helper';
import { calibrateChessboard } from 'helpers/camera-calibration-helper';
import styles from './Chessboard.module.scss';
import Title from '../common/Title';
const Chessboard = ({ chessboard, updateParam, onNext, onClose }) => {
    const lang = useI18n().calibration;
    const webCamConnection = useRef(null);
    const videoRef = useRef(null);
    const [webcamConnected, setWebcamConnected] = useState(false);
    useEffect(() => {
        const video = videoRef.current;
        webcamHelper.connectWebcam({ video }).then((conn) => {
            webCamConnection.current = conn;
            setWebcamConnected(!!conn);
        });
        return () => {
            var _a;
            (_a = webCamConnection.current) === null || _a === void 0 ? void 0 : _a.end();
        };
    }, []);
    const handleCalibrate = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        progressCaller.openNonstopProgress({ id: 'calibrate-chessboard', message: lang.calibrating });
        videoRef.current.pause();
        let success = false;
        try {
            const imgBlob = yield ((_a = webCamConnection.current) === null || _a === void 0 ? void 0 : _a.getPicture());
            const res = yield calibrateChessboard(imgBlob, 0, chessboard);
            if (res.success === true) {
                const { ret, k, d, rvec, tvec } = res.data;
                const resp = yield new Promise((resolve) => {
                    let rank = lang.res_excellent;
                    if (ret > 5)
                        rank = lang.res_poor;
                    else if (ret > 1)
                        rank = lang.res_average;
                    alertCaller.popUp({
                        message: sprintf(lang.calibrate_chessboard_success_msg, rank, ret),
                        buttons: [
                            {
                                label: lang.next,
                                onClick: () => resolve(true),
                                className: 'primary',
                            },
                            {
                                label: lang.cancel,
                                onClick: () => resolve(false),
                            },
                        ],
                    });
                });
                if (!resp)
                    return;
                updateParam({ ret, k, d, rvec, tvec });
                onNext();
                success = true;
                return;
            }
            const { reason } = res.data;
            alertCaller.popUpError({ message: `${lang.failed_to_calibrate_chessboard} ${reason}` });
        }
        catch (error) {
            console.error(error);
        }
        finally {
            progressCaller.popById('calibrate-chessboard');
            if (!success)
                videoRef.current.play();
        }
    });
    return (React.createElement(Modal, { width: "80vw", open: true, centered: true, maskClosable: false, title: React.createElement(Title, { title: lang.camera_calibration, link: lang.promark_help_link }), okText: lang.next, cancelText: lang.cancel, onOk: handleCalibrate, onCancel: () => onClose(false), okButtonProps: { disabled: !webcamConnected } },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.desc },
                React.createElement("div", null,
                    "1. ",
                    lang.put_chessboard_promark_1),
                React.createElement("div", null,
                    "2. ",
                    lang.put_chessboard_promark_2)),
            React.createElement("div", { className: styles.imgContainer },
                React.createElement("video", { ref: videoRef }),
                !webcamConnected && (React.createElement(Spin, { className: styles.spin, indicator: React.createElement(LoadingOutlined, { className: styles.spinner, spin: true }) }))))));
};
export default Chessboard;
