import React, { useMemo } from 'react';
import TestState from 'app/constants/connection-test';
import useI18n from 'helpers/useI18n';
import { promarkModels } from 'app/actions/beambox/constant';
import styles from './TestInfo.module.scss';
const TestInfo = ({ testState, connectionCountDown, firmwareVersion = '' }) => {
    const tConnect = useI18n().initialize.connect_machine_ip;
    const { model } = useMemo(() => {
        const queryString = window.location.hash.split('?')[1] || '';
        const urlParams = new URLSearchParams(queryString);
        return { model: urlParams.get('model') };
    }, []);
    const isPromark = promarkModels.has(model);
    const renderBasicConnectionInfo = (reached = tConnect.check_ip, unreachableError = tConnect.unreachable) => {
        if (testState === TestState.NONE) {
            return null;
        }
        let status = 'OK ✅';
        switch (testState) {
            case TestState.IP_TESTING:
                status = '';
                break;
            case TestState.IP_FORMAT_ERROR:
                status = `${tConnect.invalid_ip}${tConnect.invalid_format}`;
                break;
            case TestState.IP_UNREACHABLE:
                status = unreachableError;
                break;
            default:
                break;
        }
        return React.createElement("div", { id: "ip-test-info", className: styles.info }, `${reached}... ${status}`);
    };
    const renderConnectionTestInfo = () => {
        if (testState < TestState.CONNECTION_TESTING)
            return null;
        let status = 'OK ✅';
        if (testState === TestState.CONNECTION_TESTING)
            status = `${connectionCountDown}s`;
        else if (testState === TestState.CONNECTION_TEST_FAILED)
            status = 'Fail';
        return (React.createElement("div", { id: "machine-test-info", className: styles.info }, `${tConnect.check_connection}... ${status}`));
    };
    const renderFirmwareVersion = () => {
        if (testState < TestState.CAMERA_TESTING)
            return null;
        return React.createElement("div", { className: styles.info }, `${tConnect.check_firmware}... ${firmwareVersion}`);
    };
    const renderCameraTesting = () => {
        if (testState < TestState.CAMERA_TESTING)
            return null;
        let status = '';
        if (testState === TestState.TEST_COMPLETED)
            status = 'OK ✅';
        else if (testState === TestState.CAMERA_TEST_FAILED)
            status = 'Fail';
        return React.createElement("div", { className: styles.info }, `${tConnect.check_camera}... ${status}`);
    };
    return (React.createElement("div", { className: styles.container },
        isPromark
            ? renderBasicConnectionInfo(tConnect.check_swiftray_connection, tConnect.check_swiftray_connection_unreachable)
            : renderBasicConnectionInfo(),
        renderConnectionTestInfo(),
        renderFirmwareVersion(),
        renderCameraTesting(),
        testState === TestState.TEST_COMPLETED && (React.createElement("div", { className: styles.info }, tConnect.succeeded_message))));
};
export default TestInfo;
