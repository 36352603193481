import { defaultField, defaultGalvoParameters, defaultRedLight, } from 'app/constants/promark-constants';
const applyRedDot = (redDot, field, galvoParameters) => {
    const { offsetX, offsetY, scaleX, scaleY } = redDot !== null && redDot !== void 0 ? redDot : defaultRedLight;
    const newField = Object.assign({}, (field !== null && field !== void 0 ? field : defaultField));
    const newGalvo = {
        x: Object.assign({}, (galvoParameters !== null && galvoParameters !== void 0 ? galvoParameters : defaultGalvoParameters).x),
        y: Object.assign({}, (galvoParameters !== null && galvoParameters !== void 0 ? galvoParameters : defaultGalvoParameters).y),
    };
    newField.offsetX += offsetX;
    newField.offsetY += offsetY;
    newGalvo.x.scale *= scaleX;
    newGalvo.y.scale *= scaleY;
    return { field: newField, galvoParameters: newGalvo };
};
export default applyRedDot;
