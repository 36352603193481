const lang = {
    global: {
        cancel: 'Hủy',
        back: 'Quay lại',
        save: 'Lưu',
        ok: 'OK',
        stop: 'Dừng',
    },
    general: {
        processing: 'Đang xử lý...',
        choose_folder: 'Chọn Thư mục',
    },
    buttons: {
        next: 'Tiếp theo',
        back: 'Quay lại',
        done: 'Hoàn tất',
        back_to_beam_studio: 'Quay lại Beam Studio',
    },
    topbar: {
        untitled: 'Chưa đặt tên',
        titles: {
            settings: 'Tùy chọn',
        },
        export: 'XUẤT',
        preview: 'XEM TRƯỚC',
        preview_title: 'Xem trước',
        preview_press_esc_to_stop: 'Nhấn ESC để dừng xem trước camera.',
        curve_engrave: 'Đường cong 3D',
        task_preview: 'Xem trước đường đi',
        frame_task: 'Đang chạy khung',
        borderless: '(MỞ PHÍA DƯỚI)',
        tag_names: {
            rect: 'Hình chữ nhật',
            ellipse: 'Hình elip',
            path: 'Đường dẫn',
            polygon: 'Đa giác',
            image: 'Hình ảnh',
            text: 'Văn bản',
            text_path: 'Văn bản trên đường dẫn',
            pass_through_object: 'Đối Tượng Xuyên Qua',
            line: 'Đường thẳng',
            g: 'Nhóm',
            multi_select: 'Nhiều đối tượng',
            use: 'Đối tượng nhập khẩu',
            svg: 'Đối tượng SVG',
            dxf: 'Đối tượng DXF',
            no_selection: 'Không có lựa chọn',
        },
        alerts: {
            start_preview_timeout: '#803 Đã xảy ra lỗi khi bắt đầu chế độ xem trước. Vui lòng khởi động lại máy hoặc Beam Studio. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">hướng dẫn này</a>.',
            fail_to_start_preview: '#803 Không thể bắt đầu chế độ xem trước. Vui lòng khởi động lại máy hoặc Beam Studio. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">hướng dẫn này</a>.',
            fail_to_connect_with_camera: '#803 Không thể kết nối với camera máy. Vui lòng khởi động lại máy hoặc Beam Studio. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">hướng dẫn này</a>.',
            add_content_first: 'Vui lòng thêm đối tượng trước khi thêm nội dung',
            headtype_mismatch: 'Phát hiện mô-đun không chính xác. ',
            headtype_none: 'Không tìm thấy mô-đun. ',
            headtype_unknown: 'Phát hiện mô-đun không xác định. ',
            install_correct_headtype: 'Vui lòng lắp đặt mô-đun laser diode 10W/20W đúng để bật laser thấp cho khung chạy.',
            door_opened: 'Vui lòng đóng nắp để bật laser thấp cho khung chạy.',
            fail_to_get_door_status: 'Vui lòng đảm bảo nắp đã đóng để bật laser thấp cho khung chạy.',
            QcleanScene: 'Bạn có muốn xóa bản vẽ không?<br/>Điều này cũng sẽ xóa lịch sử hoàn tác của bạn!',
            power_too_high: 'CÔNG SUẤT QUÁ CAO',
            power_too_high_msg: 'Sử dụng công suất laser thấp hơn (dưới 70%) sẽ kéo dài tuổi thọ ống laser.\nNhập "ĐÃ GHI NHẬN" để tiếp tục.',
            power_too_high_confirm: 'ĐÃ GHI NHẬN',
            pwm_unavailable: 'Chế Độ Độ Sâu yêu cầu phiên bản firmware 4.3.4 / 5.3.4 hoặc cao hơn. Bạn có muốn cập nhật firmware bây giờ không?',
            job_origin_unavailable: 'Thiết lập điểm gốc công việc yêu cầu phiên bản firmware 4.3.5 / 5.3.5 trở lên. Bạn có muốn cập nhật firmware ngay bây giờ không?',
            job_origin_warning: 'Bạn hiện đang sử dụng “vị trí hiện tại” làm điểm bắt đầu. Vui lòng đảm bảo đầu laser được di chuyển đến vị trí chính xác để tránh va chạm.',
        },
        hint: {
            polygon: 'Nhấn phím + / - để tăng / giảm số cạnh',
        },
        menu: {
            preferences: 'Tùy chọn',
            hide: 'Ẩn Beam Studio',
            hideothers: 'Ẩn các cửa sổ khác',
            service: 'Dịch vụ',
            quit: 'Thoát',
            window: 'Cửa sổ',
            minimize: 'Thu nhỏ',
            close: 'Đóng cửa sổ',
            file: 'Tệp',
            edit: 'Chỉnh sửa',
            help: 'Trợ giúp',
            open: 'Mở',
            samples: 'Ví dụ',
            example_files: 'Tệp Mẫu',
            material_test: 'Kiểm Tra Vật Liệu',
            calibration: 'Hiệu Chuẩn',
            import_hello_beamo: 'Ví dụ của beamo',
            import_hello_beambox: 'Ví dụ của Beambox',
            import_beambox_2_example: 'Ví dụ về Beambox II',
            import_material_testing_old: 'Kiểm tra vật liệu - Cổ điển',
            import_material_testing_simple_cut: 'Kiểm tra cắt vật liệu - Đơn giản',
            import_material_testing_cut: 'Kiểm tra cắt vật liệu',
            import_material_testing_engrave: 'Kiểm tra khắc vật liệu',
            import_material_testing_line: 'Kiểm tra đường vật liệu',
            import_material_printing_test: 'Kiểm tra in vật liệu',
            import_ador_laser_example: 'Ví dụ của Ador Laser',
            import_ador_printing_example_single: 'Ví dụ in của Ador - Một màu',
            import_ador_printing_example_full: 'Ví dụ in của Ador - Đầy đủ màu',
            import_acrylic_focus_probe: 'Đầu dò tiêu điểm acrylic - 3mm',
            import_beambox_2_focus_probe: 'Đầu dò Lấy Nét Beambox II - 3mm',
            import_promark_example: 'Ví dụ về Promark',
            import_hexa_example: 'Ví dụ về HEXA',
            export_to: 'Xuất ra...',
            export_flux_task: 'Nhiệm vụ FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Lưu',
            save_as: 'Lưu dưới dạng...',
            save_to_cloud: 'Lưu lên đám mây',
            about_beam_studio: 'Về Beam Studio',
            undo: 'Hoàn tác',
            redo: 'Làm lại',
            cut: 'Cắt',
            copy: 'Sao chép',
            paste: 'Dán',
            paste_in_place: 'Dán tại vị trí',
            group: 'Nhóm',
            ungroup: 'Bỏ nhóm',
            delete: 'Xóa',
            duplicate: 'Nhân bản',
            offset: 'Dịch chuyển',
            scale: 'Tỷ lệ',
            rotate: 'Xoay',
            reset: 'Đặt lại',
            align_center: 'Căn giữa',
            photo_edit: 'Ảnh',
            svg_edit: 'SVG',
            path: 'Đường dẫn',
            decompose_path: 'Phân tách',
            object: 'Đối tượng',
            layer_setting: 'Lớp',
            layer_color_config: 'Cài đặt màu',
            image_sharpen: 'Làm nét',
            image_crop: 'Cắt',
            image_invert: 'Đảo ngược',
            image_stamp: 'Khắc dấu',
            image_vectorize: 'Vẽ vector',
            image_curve: 'Đường cong',
            align_to_edges: 'Căn theo cạnh',
            document_setting: 'Thiết lập tài liệu',
            document_setting_short: 'Tài liệu',
            rotary_setup: 'Cài Đặt Xoay',
            clear_scene: 'Tệp mới',
            machines: 'Máy in',
            add_new_machine: 'Thiết lập máy in',
            help_center: 'Trung tâm trợ giúp',
            show_gesture_tutorial: 'Hướng dẫn cử chỉ',
            show_start_tutorial: 'Xem hướng dẫn bắt đầu',
            show_ui_intro: 'Xem giới thiệu giao diện',
            questionnaire: 'Bảng câu hỏi phản hồi',
            change_logs: 'Nhật ký thay đổi',
            contact: 'Liên hệ chúng tôi',
            tutorial: 'Hướng dẫn in Delta',
            design_market: 'Thị trường thiết kế',
            forum: 'Diễn đàn cộng đồng',
            software_update: 'Cập nhật phần mềm',
            bug_report: 'Báo cáo lỗi',
            dashboard: 'Bảng điều khiển',
            machine_info: 'Thông tin máy in',
            network_testing: 'Kiểm tra cài đặt mạng',
            commands: 'Các lệnh',
            update_firmware: 'Cập nhật phần mềm',
            using_beam_studio_api: 'Sử dụng API Beam Studio',
            set_as_default: 'Đặt làm mặc định',
            calibrate_beambox_camera: 'Hiệu chỉnh máy ảnh',
            calibrate_printer_module: 'Hiệu chuẩn mô-đun in ấn',
            calibrate_ir_module: 'Hiệu chuẩn mô-đun hồng ngoại',
            calibrate_beambox_camera_borderless: 'Hiệu chỉnh máy ảnh (mở đáy)',
            calibrate_diode_module: 'Hiệu chuẩn mô-đun laser đi-ốt',
            calibrate_camera_advanced: 'Hiệu chỉnh máy ảnh (Nâng cao)',
            manage_account: 'Quản lý tài khoản của tôi',
            sign_in: 'Đăng nhập',
            sign_out: 'Đăng xuất',
            account: 'Tài khoản',
            my_account: 'Tài khoản của tôi',
            download_log: 'Tải xuống nhật ký',
            download_log_canceled: 'Đã hủy tải xuống nhật ký',
            download_log_error: 'Đã xảy ra lỗi không xác định, vui lòng thử lại sau',
            keyboard_shortcuts: 'Phím tắt',
            log: {
                network: 'Mạng',
                hardware: 'Phần cứng',
                discover: 'Khám phá',
                usb: 'USB',
                usblist: 'Danh sách USB',
                camera: 'Camera',
                cloud: 'Đám mây',
                player: 'Trình phát',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Xem',
            zoom_in: 'Phóng to',
            zoom_out: 'Thu nhỏ',
            fit_to_window: 'Vừa với kích thước cửa sổ',
            zoom_with_window: 'Tự động vừa với kích thước cửa sổ',
            borderless_mode: 'Chế độ không viền',
            show_grids: 'Hiển thị lưới',
            show_rulers: 'Hiển thị thước kẻ',
            show_layer_color: 'Sử dụng màu lớp',
            anti_aliasing: 'Làm mịn cạnh',
            disassemble_use: 'Tháo rời',
            about: 'Về Beam Studio',
            switch_to_beta: 'Chuyển sang Bản Beta',
            switch_to_latest: 'Chuyển sang Bản Ổn Định',
            reload_app: 'Tải lại Ứng Dụng',
            recent: 'Mở Gần Đây',
            update: 'Kiểm Tra Cập Nhật',
            dev_tool: 'Công Cụ Gỡ Lỗi',
            camera_calibration_data: 'Dữ Liệu Hiệu Chỉnh Camera',
            upload_data: 'Tải Lên Dữ Liệu',
            download_data: 'Tải Xuống Dữ Liệu',
            tools: {
                title: 'Công cụ',
                material_test_generator: 'Trình tạo thử nghiệm vật liệu',
                code_generator: 'Trình tạo mã',
                box_generator: 'Trình Tạo Hộp',
            },
            follow_us: 'Theo dõi chúng tôi',
        },
        select_machine: 'Chọn máy',
    },
    support: {
        no_webgl: 'WebGL không được hỗ trợ. Vui lòng sử dụng thiết bị khác.',
        no_vcredist: 'Vui lòng cài đặt Visual C++ Redistributable 2015\nCó thể tải về tại flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[Lỗi không xác định] Đã xảy ra lỗi không xác định. Vui lòng khởi động lại Beam Studio và máy.',
        OPERATION_ERROR: '[Xung đột trạng thái] Xung đột trạng thái đã xảy ra, vui lòng thử lại hành động.',
        SUBSYSTEM_ERROR: '[Lỗi hệ thống phụ] Lỗi khi phần mềm máy thực hiện nhiệm vụ. Vui lòng khởi động lại máy.',
        UNKNOWN_COMMAND: '[Lệnh không xác định] Vui lòng cập nhật phần mềm thiết bị',
    },
    device_selection: {
        no_beambox: '#801 Chúng tôi không tìm thấy máy của bạn trên mạng.\nVui lòng làm theo <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">hướng dẫn</a> để khắc phục sự cố kết nối.',
        no_device_web: "#801 Vui lòng kiểm tra tình trạng của máy bạn, hoặc nhấn vào 'Thiết lập máy in' bên dưới để cài đặt máy.",
        select_usb_device: 'Chọn thiết bị USB',
    },
    update: {
        update: 'Cập nhật',
        release_note: 'Ghi chú phát hành:',
        firmware: {
            caption: 'Có bản cập nhật phần mềm máy',
            message_pattern_1: '"%s" đã sẵn sàng để cập nhật phần mềm.',
            message_pattern_2: 'Phiên bản phần mềm máy %s v%s hiện có sẵn - Bạn đang dùng phiên bản %s.',
            latest_firmware: {
                caption: 'Cập nhật phần mềm máy',
                message: 'Bạn đang sử dụng phiên bản phần mềm máy mới nhất',
                still_update: 'CẬP NHẬT',
                cant_get_latest: 'Không thể lấy thông tin về phiên bản phần mềm mới nhất.',
            },
            confirm: 'TẢI LÊN',
            upload_file: 'Tải lên firmware (*.bin / *.fxfw)',
            update_success: 'Cập nhật firmware thành công',
            update_fail: '#822 Cập nhật thất bại',
            too_old_for_web: 'Phiên bản firmware máy hiện tại của bạn là %s.\nNếu bạn muốn sử dụng phiên bản trực tuyến của Beam Studio, vui lòng cập nhật firmware máy lên phiên bản mới nhất.',
            force_update_message: '#814 Vui lòng cập nhật máy của bạn lên phiên bản firmware mới nhất.',
            firmware_too_old_update_by_sdcard: 'Phiên bản firmware quá cũ. Vui lòng cập nhật firmware bằng thẻ SD.',
        },
        software: {
            checking: 'Đang kiểm tra cập nhật',
            switch_version: 'Chuyển đổi phiên bản',
            check_update: 'Kiểm tra cập nhật',
            caption: 'Có bản cập nhật Beam Studio',
            downloading: 'Đang tải các bản cập nhật trong nền, bạn có thể nhấn "OK" để tiếp tục công việc.',
            install_or_not: 'sẵn sàng để cập nhật. Bạn có muốn khởi động lại và cập nhật ngay bây giờ?',
            switch_or_not: 'sẵn sàng để nâng cấp. Bạn có muốn khởi động lại và nâng cấp ngay bây giờ?',
            available_update: 'Beam Studio phiên bản %s đã sẵn sàng. Bạn đang dùng phiên bản %s. Bạn có muốn tải bản cập nhật?',
            available_switch: 'Beam Studio phiên bản %s đã sẵn sàng. Bạn đang dùng phiên bản %s. Bạn có muốn chuyển sang phiên bản này?',
            not_found: 'Bạn đang sử dụng phiên bản mới nhất của Beam Studio.',
            no_response: 'Không kết nối được máy chủ, vui lòng kiểm tra cài đặt mạng.',
            switch_version_not_found: 'Không tìm thấy Phiên Bản có thể Nâng cấp.',
            yes: 'Có',
            no: 'Không',
            update_for_ador: 'Phiên bản phần mềm hiện tại %s không tương thích, vui lòng tải phiên bản Beam Studio mới nhất cho Ador.',
        },
        updating: 'Đang cập nhật...',
        skip: 'Bỏ qua Phiên Bản này',
        preparing: 'Đang chuẩn bị...',
        later: 'SAU',
        download: 'CẬP NHẬT TRỰC TUYẾN',
        cannot_reach_internet: '#823 Không kết nối được máy chủ<br/>Vui lòng kiểm tra kết nối internet',
        install: 'CÀI ĐẶT',
        upload: 'TẢI LÊN',
    },
    topmenu: {
        version: 'Phiên bản',
        credit: 'Beam Studio được tạo ra nhờ dự án nguồn mở <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> và các <a target="_blank" href="https://flux3dp.com/credits/">phần mềm nguồn mở</a> khác.',
        ok: 'OK',
        file: {
            label: 'Tập tin',
            import: 'Nhập',
            save_fcode: 'Xuất FLUX Task',
            save_scene: 'Lưu Cảnh',
            save_svg: 'Xuất SVG',
            save_png: 'Xuất PNG',
            save_jpg: 'Xuất JPG',
            converting: 'Đang chuyển đổi sang Hình ảnh...',
            all_files: 'Tất cả Tập tin',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Cảnh Beam Studio',
            fcode_files: 'Mã FLUX',
            clear_recent: 'Xóa Mới Mở Gần Đây',
            path_not_exit: 'Dường như đường dẫn này không còn tồn tại trên đĩa nữa.',
        },
        device: {
            download_log_canceled: 'Đã hủy tải nhật ký',
            download_log_error: 'Đã xảy ra lỗi không xác định, vui lòng thử lại sau',
            log: {
                usblist: 'Danh sách USB',
            },
            network_test: 'Kiểm tra mạng',
        },
    },
    initialize: {
        next: 'Tiếp theo',
        start: 'Bắt đầu',
        skip: 'Bỏ qua',
        cancel: 'Hủy',
        confirm: 'Xác nhận',
        connect: 'Kết nối',
        back: 'Quay lại',
        retry: 'Thử lại',
        no_machine: 'Tôi không có máy hiện tại.',
        select_language: 'Chọn ngôn ngữ',
        select_machine_type: 'Chọn loại máy của bạn',
        select_beambox: 'Chọn Beambox của bạn',
        select_connection_type: 'Bạn muốn kết nối như thế nào?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Mạng có dây',
            ether2ether: 'Kết nối trực tiếp',
            usb: 'Kết nối USB',
        },
        connect_wifi: {
            title: 'Đang kết nối với Wi-Fi',
            tutorial1: '1. Đi đến Bảng điều khiển cảm ứng > Nhấp vào "Mạng" > "Kết nối với WiFi".',
            tutorial1_ador: '1. Đi đến Bảng điều khiển cảm ứng > Nhấp vào "MÁY" > Nhấp vào "Mạng" > "Kết nối với WiFi".',
            tutorial2: '2. Chọn và kết nối Wi-Fi ưa thích của bạn.',
            what_if_1: 'Nếu tôi không thấy Wi-Fi của mình thì sao?',
            what_if_1_content: '1. Chế độ mã hóa Wi-Fi nên là WPA2 hoặc không có mật khẩu.\n2. Chế độ mã hóa có thể được đặt trong giao diện quản trị bộ định tuyến Wi-Fi. Nếu bộ định tuyến không hỗ trợ WPA2 và bạn cần trợ giúp để chọn đúng bộ định tuyến, vui lòng liên hệ với bộ phận hỗ trợ FLUX.',
            what_if_2: 'Nếu tôi không thấy bất kỳ Wi-Fi nào thì sao?',
            what_if_2_content: '1. Đảm bảo rằng đầu nối Wi-Fi được cắm đầy đủ.\n2. Nếu không có Địa chỉ MAC của mạng không dây trên màn hình cảm ứng, vui lòng liên hệ với bộ phận hỗ trợ FLUX.\n3. Kênh Wi-Fi nên là 2.4Ghz (5Ghz không được hỗ trợ).',
        },
        connect_wired: {
            title: 'Kết nối với Mạng có dây',
            tutorial1: '1. Kết nối máy với bộ định tuyến của bạn.',
            tutorial2: '2. Nhấn "Mạng" để lấy IP mạng có dây.',
            tutorial2_ador: '2. Nhấn "MÁY" > "Mạng" để lấy IP mạng có dây.',
            what_if_1: 'Nếu IP trống thì sao?',
            what_if_1_content: '1. Đảm bảo cáp Ethernet được cắm đầy đủ.\n2. Nếu không có Địa chỉ MAC của mạng có dây trên màn hình cảm ứng, vui lòng liên hệ với bộ phận hỗ trợ FLUX.',
            what_if_2: 'Nếu IP bắt đầu bằng 169 thì sao?',
            what_if_2_content: '1. Nếu địa chỉ IP bắt đầu bằng 169.254, đó nên là vấn đề cài đặt DHCP, vui lòng liên hệ với nhà cung cấp dịch vụ internet của bạn để được hỗ trợ thêm.\n2. Nếu máy tính của bạn kết nối internet trực tiếp bằng PPPoE, vui lòng thay đổi sang sử dụng bộ định tuyến để kết nối bằng PPPoE, và bật tính năng DHCP trong bộ định tuyến.',
        },
        connect_ethernet: {
            title: 'Kết nối trực tiếp',
            tutorial1: '1. Kết nối máy với máy tính của bạn bằng cáp Ethernet.',
            tutorial2_1: '2. Làm theo ',
            tutorial2_a_text: 'hướng dẫn này',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: 'để biến máy tính của bạn thành một bộ định tuyến.',
            tutorial3: '3. Nhấn Tiếp theo.',
        },
        connect_usb: {
            title: 'Kết nối USB',
            title_sub: '(HEXA & Ador Chỉ)',
            tutorial1: 'Kết nối máy với máy tính của bạn bằng cáp USB.',
            tutorial2: "Nhấn 'Tiếp theo'.",
            turn_off_machine: 'Tắt máy.',
            turn_on_machine: 'Bật máy.',
            wait_for_turning_on: "Nhấn 'Tiếp theo' sau khi quá trình khởi động hoàn tất và bạn đã vào màn hình chính.",
            connect_camera: 'Kết nối máy ảnh của máy với máy tính của bạn bằng cáp USB.',
        },
        connect_machine_ip: {
            check_usb: 'Kiểm tra kết nối USB',
            enter_ip: 'Nhập địa chỉ IP của máy',
            check_ip: 'Đang kiểm tra tính khả dụng IP',
            invalid_ip: 'IP không hợp lệ: ',
            invalid_format: 'Định dạng không hợp lệ',
            starts_with_169254: 'Bắt đầu bằng 169.254',
            unreachable: 'Không thể truy cập IP',
            check_connection: 'Đang kiểm tra kết nối máy',
            check_firmware: 'Đang kiểm tra phiên bản firmware',
            check_camera: 'Đang kiểm tra tính khả dụng camera',
            retry: 'Thử lại',
            succeeded_message: 'Kết nối thành công 🎉',
            finish_setting: 'Bắt đầu tạo!',
            check_swiftray_connection: 'Đang kiểm tra kết nối với máy chủ',
            check_swiftray_connection_unreachable: 'không thể kết nối máy chủ',
            promark_hint: 'Nếu bạn liên tục không kết nối được qua USB, vui lòng tham khảo <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">bài viết tại Trung tâm trợ giúp</a>.',
            alert: {
                swiftray_connection_error: 'Không thể kết nối với máy chủ. Vui lòng khởi động lại Beam Studio và thử lại.',
            },
        },
        connecting: 'Đang kết nối...',
        setting_completed: {
            start: 'Bắt đầu',
            great: 'Chào mừng đến với Beam Studio',
            setup_later: 'Bạn luôn có thể thiết lập máy của mình từ Thanh menu > "Máy" > "Thiết lập máy"',
            back: 'Quay lại',
            ok: 'BẮT ĐẦU TẠO',
        },
        promark: {
            select_laser_source: 'Chọn Promark của bạn',
            select_workarea: 'Chọn Khu vực Làm việc',
            settings: 'Cài đặt Promark',
            qc_instructions: 'Điền vào các thông số nằm ở mặt sau của thẻ "QC Pass"',
            configuration_confirmation: 'Điều này đảm bảo rằng Promark của bạn được cấu hình đúng cách để đạt hiệu quả và độ chính xác tối ưu.',
            or_complete_later: `Hoặc bỏ qua bước này và hoàn thành Cài đặt Promark sau tại:
    Máy móc > "Tên Promark" > Cài đặt Promark`,
        },
    },
    error_pages: {
        screen_size: 'Lưu ý rằng Beam Studio có thể không hoạt động tối ưu trên thiết bị của bạn. Để trải nghiệm tốt nhất, nên sử dụng thiết bị có độ rộng màn hình tối thiểu 1024 pixel.',
    },
    menu: {
        mm: 'mm',
        inches: 'Inches',
    },
    settings: {
        on: 'Bật',
        off: 'Tắt',
        low: 'Thấp',
        normal: 'Cao',
        high: 'Cao',
        caption: 'Cài đặt',
        tabs: {
            general: 'Chung',
            device: 'Máy',
        },
        ip: 'Địa chỉ IP máy',
        guess_poke: 'Tìm kiếm địa chỉ IP máy',
        auto_connect: 'Tự động kết nối máy duy nhất',
        wrong_ip_format: 'Định dạng IP không hợp lệ',
        default_machine: 'Máy mặc định',
        default_machine_button: 'Trống',
        remove_default_machine_button: 'Xóa',
        confirm_remove_default: 'Máy mặc định sẽ bị xóa.',
        reset: 'Đặt lại Beam Studio',
        reset_now: 'Đặt lại Beam Studio',
        confirm_reset: 'Xác nhận đặt lại Beam Studio?',
        language: 'Ngôn ngữ',
        notifications: 'Thông báo trên màn hình',
        check_updates: 'Tự động kiểm tra cập nhật',
        autosave_enabled: 'Tự động lưu',
        autosave_path: 'Vị trí tự động lưu',
        autosave_interval: 'Lưu mỗi',
        autosave_number: 'Số lượng tự động lưu',
        autosave_path_not_correct: 'Đường dẫn không tồn tại.',
        preview_movement_speed: 'Tốc độ xem trước chuyển động',
        medium: 'Trung bình',
        default_units: 'Đơn vị mặc định',
        default_font_family: 'Phông chữ mặc định',
        default_font_style: 'Kiểu phông chữ mặc định',
        fast_gradient: 'Tối ưu tốc độ',
        engraving_direction: 'Hướng',
        top_down: 'Từ trên xuống',
        bottom_up: 'Từ dưới lên',
        vector_speed_constraint: 'Giới hạn tốc độ',
        loop_compensation: 'Bù vòng lặp',
        blade_radius: 'Bán kính lưỡi',
        blade_precut_switch: 'Cắt trước lưỡi',
        blade_precut_position: 'Vị trí cắt trước',
        default_beambox_model: 'Thiết lập mặc định',
        guides_origin: 'Gốc hướng dẫn',
        guides: 'Hướng dẫn',
        image_downsampling: 'Chất lượng xem trước ảnh',
        anti_aliasing: 'Làm mịn cạnh',
        continuous_drawing: 'Vẽ liên tục',
        trace_output: 'Kết quả trích xuất ảnh',
        single_object: 'Đối tượng đơn',
        grouped_objects: 'Nhóm đối tượng',
        simplify_clipper_path: 'Tối ưu hóa đường dẫn tính toán',
        enable_low_speed: 'Bật chế độ chuyển động tốc độ thấp',
        enable_custom_backlash: 'Bật bù lùi tùy chỉnh',
        calculation_optimization: 'Tăng tốc tính toán đường dẫn',
        auto_switch_tab: 'Chuyển đổi tự động giữa bảng Lớp và Đối tượng',
        custom_preview_height: 'Chiều cao xem trước tùy chỉnh',
        mask: 'Cắt vùng làm việc',
        text_path_calc_optimization: 'Tối ưu hóa tính toán đường dẫn văn bản',
        font_substitute: 'Thay thế ký tự không hỗ trợ',
        font_convert: 'Cài đặt bộ chuyển đổi văn bản thành đường dẫn',
        default_borderless_mode: 'Mở đáy mặc định',
        default_enable_autofocus_module: 'Lấy nét tự động mặc định',
        default_enable_diode_module: 'Laser đi-ốt mặc định',
        diode_offset: 'Bù đắp Laser Diode',
        autofocus_offset: 'Bù đắp Lấy nét tự động',
        diode_one_way_engraving: 'Khắc Laser Diode một chiều',
        diode_two_way_warning: 'Phát sáng hai chiều nhanh hơn và có thể tạo ra một số sai lệch vị trí khắc laser. Khuyến nghị kiểm tra trước.',
        share_with_flux: 'Chia sẻ Phân tích Beam Studio',
        none: 'Không có',
        close: 'Đóng',
        enabled: 'Bật',
        disabled: 'Tắt',
        cancel: 'Hủy',
        done: 'Áp dụng',
        module_offset_10w: 'Bù đắp Laser Diode 10W',
        module_offset_20w: 'Bù đắp Laser Diode 20W',
        module_offset_printer: 'Bù đắp Máy in',
        module_offset_2w_ir: 'Bù đắp Laser Hồng ngoại 2W',
        printer_advanced_mode: 'Chế độ nâng cao Máy in',
        default_laser_module: 'Module Laser mặc định',
        low_laser_for_preview: 'Laser cho Đang chạy khung',
        groups: {
            general: 'Chung',
            update: 'Cập nhật phần mềm',
            connection: 'Kết nối',
            autosave: 'Tự động lưu',
            camera: 'Camera',
            editor: 'Trình chỉnh sửa',
            engraving: 'Khắc (Quét)',
            path: 'Đường vẽ',
            mask: 'Vùng cắt',
            text_to_path: 'Chữ',
            modules: 'Phụ kiện',
            ador_modules: 'Các mô-đun Ador',
            privacy: 'Quyền riêng tư',
        },
        notification_on: 'Bật',
        notification_off: 'Tắt',
        update_latest: 'Mới nhất',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
            calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
        },
    },
    beambox: {
        tag: {
            g: 'Nhóm',
            use: 'Nhập SVG',
            image: 'Hình ảnh',
            text: 'Văn bản',
        },
        context_menu: {
            cut: 'Cắt',
            copy: 'Sao chép',
            paste: 'Dán',
            paste_in_place: 'Dán tại vị trí',
            duplicate: 'Nhân bản',
            delete: 'Xóa',
            group: 'Nhóm',
            ungroup: 'Bỏ nhóm',
            move_front: 'Đưa lên trước nhất',
            move_up: 'Đưa lên phía trước',
            move_down: 'Đẩy xuống phía sau',
            move_back: 'Đẩy xuống cuối cùng',
        },
        popup: {
            select_import_method: 'Chọn kiểu xếp lớp:',
            select_import_module: 'Chọn mô-đun:',
            touchpad: 'Bảng cảm ứng',
            mouse: 'Chuột',
            layer_by_layer: 'Theo từng lớp',
            layer_by_color: 'Màu sắc',
            nolayer: 'Lớp đơn',
            loading_image: 'Đang tải hình ảnh, vui lòng đợi...',
            no_support_text: 'Beam Studio hiện không hỗ trợ chữ. Vui lòng chuyển đổi chữ thành đường dẫn trước khi nhập.',
            speed_too_high_lower_the_quality: 'Sử dụng tốc độ quá cao ở độ phân giải này có thể làm giảm chất lượng khắc.',
            both_power_and_speed_too_high: 'Sử dụng công suất thấp hơn sẽ kéo dài tuổi thọ laser. Ngoài ra, tốc độ quá cao có thể làm giảm chất lượng khắc.',
            too_fast_for_path: 'Sử dụng tốc độ quá cao cho đường dẫn có thể làm giảm độ chính xác khi cắt. Chúng tôi không khuyến nghị dùng tốc độ trên %(limit)s khi cắt đường dẫn.',
            too_fast_for_path_and_constrain: 'Các lớp: %(layers)s \nchứa đường dẫn vector và có tốc độ trên %(limit)s.\nTốc độ cắt đường dẫn sẽ bị giới hạn ở %(limit)s.\nBạn có thể bỏ giới hạn này trong Cài đặt.',
            should_update_firmware_to_continue: '#814 Phần mềm của bạn không hỗ trợ phiên bản Beam Studio này. Vui lòng cập nhật firmware để tiếp tục. (Menu > Máy > [Máy của bạn] > Cập nhật firmware)',
            recommend_downgrade_software: 'Chúng tôi phát hiện ra phiên bản firmware cũ hơn. Chúng tôi đang khắc phục các vấn đề tương thích, nhưng hiện tại, khuyên bạn dùng <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'Chúng tôi phát hiện ra phiên bản firmware cũ hơn. Chúng tôi đang khắc phục các vấn đề tương thích, nhưng hiện tại, khuyên bạn nên cập nhật lên phiên bản firmware mới nhất.',
            still_continue: 'Tiếp tục',
            more_than_two_object: 'Chỉ hỗ trợ 2 đối tượng',
            not_support_object_type: 'Không hỗ trợ loại đối tượng này',
            select_first: 'Hãy chọn một đối tượng trước',
            select_at_least_two: 'Chọn hai đối tượng để tiếp tục',
            import_file_contain_invalid_path: '#808 Tệp SVG được nhập chứa đường dẫn hình ảnh không hợp lệ. Hãy đảm bảo tất cả hình ảnh tồn tại hoặc nhúng hình vào tệp',
            import_file_error_ask_for_upload: 'Nhập tệp SVG thất bại. Bạn có muốn cung cấp tệp cho nhóm phát triển để báo cáo lỗi?',
            upload_file_too_large: '#819 Tệp quá lớn để tải lên.',
            successfully_uploaded: 'Tải tệp thành công.',
            upload_failed: '#819 Tải tệp thất bại.',
            or_turn_off_borderless_mode: 'Hoặc tắt chế độ toàn màn hình',
            svg_1_1_waring: 'Phiên bản SVG này là 1.1, có thể gặp vấn đề tương thích',
            svg_image_path_waring: 'Tệp SVG này chứa <image> từ đường dẫn tệp. Điều này có thể gây lỗi khi mở.\nĐể tránh rủi ro, hãy dùng ảnh nhúng khi xuất SVG',
            dxf_version_waring: 'Phiên bản DXF này có thể gặp vấn đề tương thích',
            dont_show_again: 'Không hiển thị lần sau',
            convert_to_path_fail: 'Không thể chuyển thành đường dẫn',
            save_unsave_changed: 'Bạn có muốn lưu thay đổi chưa lưu?',
            dxf_bounding_box_size_over: 'Kích thước bản vẽ vượt quá khu vực làm việc. Vui lòng di chuyển gần gốc tọa độ hơn hoặc đảm bảo đơn vị chính xác',
            progress: {
                uploading: 'Đang tải lên',
                calculating: 'Đang tính toán',
            },
            backend_connect_failed_ask_to_upload: '#802 Lỗi liên tục khi kết nối backend. Bạn có muốn gửi báo cáo lỗi?',
            backend_error_hint: 'Các tính năng có thể không hoạt động do lỗi backend',
            pdf2svg: {
                error_when_converting_pdf: '#824 Lỗi khi chuyển đổi PDF sang SVG:',
                error_pdf2svg_not_found: '#825 Lỗi: Không tìm thấy lệnh pdf2svg. Vui lòng cài đặt pdf2svg',
            },
            ungroup_use: 'Điều này sẽ bỏ nhóm và có thể mất thời gian. Bạn có chắc tiếp tục?',
            vectorize_shading_image: 'Ảnh gradient mất nhiều thời gian vector hóa và dễ bị nhiễu. Vui lòng tắt trước khi thực thi',
            change_workarea_before_preview: 'Khu vực làm việc không khớp. Bạn có muốn đổi khu vực làm việc hiện tại?',
            bug_report: 'Báo cáo lỗi',
            sentry: {
                title: 'Hãy cùng cải thiện Beam Studio',
                message: 'Bạn có đồng ý tự động tải lên thông tin liên quan khi có lỗi xảy ra?',
            },
            questionnaire: {
                caption: 'Giúp chúng tôi điền vào bảng câu hỏi',
                message: 'Giúp chúng tôi điền bảng câu hỏi để Beam Studio tốt hơn',
                unable_to_get_url: 'Không lấy được liên kết đến bảng câu hỏi qua mạng. Vui lòng kiểm tra kết nối internet',
                no_questionnaire_available: 'Hiện không có bảng câu hỏi nào',
            },
            facebook_group_invitation: {
                title: 'Tham gia nhóm người dùng chính thức FLUX',
                message: 'Tham gia nhóm Facebook chính thức của chúng tôi để kết nối với các người dùng laser FLUX khác, thảo luận về sản phẩm laser FLUX, chia sẻ tác phẩm và cập nhật các bản cập nhật mới nhất. Chúng tôi mong được gặp bạn!',
                join_now: 'Tôi tham gia',
                later: 'Có lẽ sau',
                already_joined: 'Đã tham gia',
                dont_show_again: 'Không hiển thị lần sau',
            },
            ai_credit: {
                relogin_to_use: 'Vui lòng đăng nhập lại để sử dụng chức năng này.',
                insufficient_credit: 'Bạn đã hết Tín dụng',
                insufficient_credit_msg: 'Bạn không thể sử dụng %s. Vào trung tâm thành viên và mua Tín dụng AI.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Đi',
            },
            text_to_path: {
                caption: 'Bộ chuyển đổi Văn bản thành Đường dẫn 2.0',
                message: "Beam Studio giới thiệu một bộ chuyển đổi văn bản thành đường dẫn mới (Bộ chuyển đổi 2.0), tạo ra kết quả đáng tin cậy hơn! Bạn có muốn chuyển sang nó bây giờ không? \nBạn cũng có thể điều chỉnh cài đặt này sau trong phần 'Cài đặt bộ chuyển đổi văn bản thành đường dẫn' trong tùy chọn.",
            },
            auto_switch_tab: {
                title: 'Chuyển Đổi Tự Động Giữa Bảng Lớp và Đối Tượng',
                message: 'Một tùy chọn mới để tự động chuyển đổi giữa bảng Lớp và bảng Đối tượng đã được thêm vào Tùy chọn. Tùy chọn này bị tắt theo mặc định. Bạn có muốn bật chuyển đổi tự động ngay bây giờ không? <br/>Bạn có thể thay đổi cài đặt này bất kỳ lúc nào trong Tùy chọn.',
            },
        },
        zoom_block: {
            fit_to_window: 'Vừa với Cửa sổ',
        },
        time_est_button: {
            calculate: 'Ước tính thời gian',
            estimate_time: 'Thời gian ước tính:',
        },
        left_panel: {
            unpreviewable_area: 'Vùng mù',
            diode_blind_area: 'Vùng mù Phụ kiện Laser',
            borderless_blind_area: 'Vùng không khắc',
            borderless_preview: 'Xem trước Chế độ Mở đáy Máy ảnh',
            rectangle: 'Hình chữ nhật',
            ellipse: 'Hình elip',
            line: 'Đường thẳng',
            image: 'Hình ảnh',
            text: 'Văn bản',
            label: {
                cursor: 'Chọn',
                photo: 'Hình ảnh',
                text: 'Văn bản',
                line: 'Đường thẳng',
                rect: 'Hình chữ nhật',
                oval: 'Hình bầu dục',
                polygon: 'Đa giác',
                pen: 'Bút',
                shapes: 'Các phần tử',
                array: 'Mảng',
                preview: 'Xem trước Máy ảnh',
                trace: 'Truy tìm Hình ảnh',
                end_preview: 'Kết thúc xem trước',
                clear_preview: 'Xóa xem trước',
                choose_camera: 'Camera',
                live_feed: 'Phát trực tiếp',
                adjust_height: 'Điều chỉnh chiều cao',
                qr_code: 'Mã QR',
                boxgen: 'Boxgen',
                my_cloud: 'Đám mây của tôi',
                pass_through: 'Chế Độ Đi Qua',
                curve_engraving: {
                    title: 'Đường cong 3D',
                    exit: 'Thoát',
                    select_area: 'Chọn khu vực',
                    preview_3d_curve: 'Xem trước đường cong 3D',
                    clear_area: 'Xóa khu vực đã chọn',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Lớp',
                objects: 'Đối tượng',
                path_edit: 'Chỉnh sửa đường dẫn',
            },
            layer_panel: {
                layer1: 'Lớp 1',
                layer_bitmap: 'Ảnh điểm',
                layer_engraving: 'Khắc',
                layer_cutting: 'Cắt',
                current_layer: 'Lớp hiện tại',
                move_elems_to: 'Di chuyển các phần tử đến:',
                notification: {
                    dupeLayerName: 'Đã có một lớp trùng tên!',
                    newName: 'TÊN MỚI',
                    enterUniqueLayerName: 'Vui lòng nhập tên lớp duy nhất',
                    enterNewLayerName: 'Vui lòng nhập tên lớp mới',
                    layerHasThatName: 'Lớp đã có tên này',
                    QmoveElemsToLayer: "Di chuyển các phần tử đã chọn đến lớp '%s'?",
                    moveElemFromPrintingLayerTitle: 'Di chuyển phần tử đã chọn sang %s và chuyển nó thành phần tử laser?',
                    moveElemFromPrintingLayerMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt màu của phần tử đã chọn sẽ bị xóa và đặt theo %s.',
                    moveElemToPrintingLayerTitle: 'Di chuyển phần tử đã chọn sang %s và chuyển nó thành phần tử in ấn?',
                    moveElemToPrintingLayerMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt của phần tử đã chọn sẽ bị xóa và đặt theo %s.',
                    splitColorTitle: 'Bạn có muốn mở rộng lớp đã chọn thành các lớp CMYK?',
                    splitColorMsg: 'Lưu ý rằng nếu tiếp tục thao tác này, bạn sẽ không thể quay lại các lớp màu ban đầu.',
                    mergeLaserLayerToPrintingLayerTitle: 'Bạn có muốn hợp nhất các lớp này thành một lớp In ấn?',
                    mergeLaserLayerToPrintingLayerMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt của lớp laser sẽ bị xóa và đặt theo lớp hiện tại.',
                    mergePrintingLayerToLaserLayerTitle: 'Bạn có muốn hợp nhất các lớp này thành một lớp Laser?',
                    mergePrintingLayerToLaserLayerMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt màu của lớp in ấn sẽ bị xóa và đặt theo lớp hiện tại.',
                },
                layers: {
                    layer: 'Lớp',
                    layers: 'Lớp',
                    del: 'Xóa lớp',
                    move_down: 'Di chuyển lớp xuống',
                    new: 'Lớp mới',
                    rename: 'Đổi tên lớp',
                    move_up: 'Di chuyển lớp lên',
                    dupe: 'Nhân đôi lớp',
                    lock: 'Khóa lớp',
                    unlock: 'Mở khóa',
                    merge_down: 'Hợp nhất xuống dưới',
                    merge_all: 'Hợp nhất tất cả',
                    merge_selected: 'Hợp nhất các lớp đã chọn',
                    move_elems_to: 'Di chuyển các phần tử đến:',
                    move_selected: 'Di chuyển các phần tử đã chọn sang một lớp khác',
                    switchToFullColor: 'Chuyển sang lớp màu đầy đủ',
                    switchToSingleColor: 'Chuyển sang lớp màu đơn',
                    splitFullColor: 'Mở rộng lớp',
                    fullColor: 'Màu đầy đủ',
                },
            },
            laser_panel: {
                preset_setting: 'Thiết lập thông số (%s)',
                multi_layer: 'Nhiều lớp',
                parameters: 'Thông số',
                strength: 'Công suất',
                pwm_advanced_setting: 'Cài Đặt Công Suất Chế Độ Độ Sâu',
                pwm_advanced_desc: 'Đặt công suất tối thiểu cho chế độ độ sâu.',
                pwm_advanced_hint: 'Cài đặt này áp dụng cụ thể cho hình ảnh gradient khi sử dụng chế độ độ sâu.',
                low_power_warning: 'Công suất laser thấp (dưới 10%) có thể không phát ra ánh sáng laser.',
                speed: 'Tốc độ',
                speed_contrain_warning: 'Tốc độ cắt của các đối tượng đường vector sẽ bị giới hạn ở %(limit)s. Bạn có thể loại bỏ giới hạn này tại Cài đặt Tùy chọn.',
                low_speed_warning: 'Tốc độ thấp có thể gây cháy vật liệu.',
                promark_speed_desc: 'Các tham số tốc độ không áp dụng cho hình ảnh gradient.',
                repeat: 'Số Lần Cắt',
                advanced: 'Nâng Cao',
                lower_focus: 'Hạ Tiêu Cự',
                by: 'Bởi',
                stepwise_focusing: 'Lấy Nét Từng Bước',
                single_color: 'Màu Đơn',
                lower_focus_desc: 'Hạ độ cao tiêu cự theo một khoảng cách cụ thể sau khi lấy nét để cải thiện hiệu suất cắt.',
                stepwise_focusing_desc: 'Hạ dần khoảng cách tiêu cự dựa trên chiều cao của đối tượng trong mỗi lần cắt.',
                single_color_desc: 'Chỉ áp dụng cho lớp màu đầy đủ và không thể sử dụng với lớp màu đơn mở rộng.',
                focus_adjustment: 'Điều chỉnh tiêu điểm',
                height: 'Chiều cao vật thể',
                z_step: 'Bước Z',
                diode: 'Laser điốt',
                backlash: 'Độ rơ',
                ink_saturation: 'Độ bão hòa mực',
                print_multipass: 'Đa lớp',
                white_ink: 'Mực trắng',
                white_ink_settings: 'Cài đặt mực trắng',
                color_adjustment: 'Điều chỉnh kênh màu',
                color_adjustment_short: 'Kênh màu',
                halftone: 'Nửa tone',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Sức mạnh',
                times: 'lần',
                cut: 'Cắt',
                engrave: 'Khắc',
                more: 'Quản lý',
                apply: 'Áp dụng',
                custom_preset: 'Tùy chỉnh',
                various_preset: 'Các cài đặt mặc định khác nhau',
                module: 'Mô-đun',
                pulse_width: 'Độ Rộng Xung',
                frequency: 'Tần Số',
                fill_setting: 'Cài đặt Đổ đầy',
                fill_interval: 'Khoảng Cách Lấp Đầy',
                fill_angle: 'Góc Lấp Đầy',
                bi_directional: 'Hai Chiều',
                cross_hatch: 'Nét Chéo',
                dottingTime: 'Thời gian chấm điểm',
                gradient_only: 'Chỉ dành cho hình ảnh chuyển sắc',
                filled_path_only: 'Chỉ dành cho đường dẫn tô màu',
                slider: {
                    regular: 'Bình thường',
                    low: 'Thấp',
                    very_low: 'Rất thấp',
                    high: 'Cao',
                    very_high: 'Rất cao',
                    slow: 'Chậm',
                    very_slow: 'Rất chậm',
                    fast: 'Nhanh',
                    very_fast: 'Rất nhanh',
                },
                dropdown: {
                    parameters: 'Cài đặt mặc định',
                    save: 'Thêm thông số hiện tại',
                    mm: {
                        wood_3mm_cutting: 'Gỗ - Cắt 3mm',
                        wood_5mm_cutting: 'Gỗ - Cắt 5mm',
                        wood_7mm_cutting: 'Gỗ - Cắt 7mm',
                        wood_8mm_cutting: 'Gỗ - Cắt 8mm',
                        wood_10mm_cutting: 'Gỗ - Cắt 10mm',
                        wood_engraving: 'Gỗ - Khắc',
                        acrylic_3mm_cutting: 'Acrylic - Cắt 3mm',
                        acrylic_5mm_cutting: 'Acrylic - Cắt 5mm',
                        acrylic_8mm_cutting: 'Acrylic - Cắt 8mm',
                        acrylic_10mm_cutting: 'Acrylic - Cắt 10mm',
                        acrylic_engraving: 'Acrylic - Khắc',
                        mdf_3mm_cutting: 'MDF - Cắt 3mm',
                        mdf_5mm_cutting: 'MDF - Cắt 5mm',
                        mdf_engraving: 'MDF - Khắc',
                        leather_3mm_cutting: 'Da - Cắt 3mm',
                        leather_5mm_cutting: 'Da - Cắt 5mm',
                        leather_engraving: 'Da - Khắc',
                        denim_1mm_cutting: 'Denim - Cắt 1mm',
                        fabric_3mm_cutting: 'Vải - Cắt 3mm',
                        fabric_5mm_cutting: 'Vải - Cắt 5mm',
                        fabric_engraving: 'Vải - Khắc',
                        rubber_bw_engraving: 'Cao su - Khắc',
                        glass_bw_engraving: 'Kính - Khắc',
                        metal_bw_engraving: 'Kim loại - Khắc',
                        steel_engraving_spray_engraving: 'Kim loại - Khắc',
                        stainless_steel_bw_engraving_diode: 'Kim loại - Khắc (Laser điốt)',
                        gold_engraving: 'Vàng - Khắc',
                        brass_engraving: 'Đồng thau - Khắc',
                        ti_engraving: 'Titan - Khắc',
                        stainless_steel_engraving: 'Thép không gỉ - Khắc',
                        aluminum_engraving: 'Nhôm - Khắc',
                        black_acrylic_3mm_cutting: 'Nhựa acrylic đen - Cắt 3mm',
                        black_acrylic_5mm_cutting: 'Nhựa acrylic đen - Cắt 5mm',
                        black_acrylic_engraving: 'Nhựa acrylic đen - Khắc',
                        abs_engraving: 'ABS - Khắc',
                        silver_engraving: 'Bạc - Khắc',
                        iron_engraving: 'Sắt - Khắc',
                        fabric_printing: 'Vải - In',
                        canvas_printing: 'Vải bạt - In',
                        cardstock_printing: 'Giấy cứng - In',
                        wood_printing: 'Gỗ - In',
                        bamboo_printing: 'Tre - In',
                        cork_printing: 'Liège - In',
                        flat_stone_printing: 'Đá phẳng - In',
                        acrylic_printing: 'Nhựa acrylic - In',
                        pc_printing: 'PC - In',
                        stainless_steel_printing: 'Thép không gỉ - In',
                        gloss_leather_printing: 'Da bóng - In',
                        glass_printing: 'Kính - In',
                        aluminum_light: 'Nhôm (sáng)',
                        stainless_steel_dark: 'Thép không gỉ (tối)',
                        stainless_steel_light: 'Thép không gỉ (sáng)',
                        brass_dark: 'Đồng thau (tối)',
                        brass_light: 'Đồng thau (sáng)',
                        copper: 'Đồng',
                        titanium_dark: 'Titan (tối)',
                        titanium_light: 'Titan (sáng)',
                        black_abs: 'ABS đen',
                        white_abs: 'ABS trắng',
                        opaque_acrylic: 'Acrylic mờ',
                        stone: 'Đá',
                    },
                    inches: {
                        wood_3mm_cutting: 'Gỗ - 0.1" Cắt',
                        wood_5mm_cutting: 'Gỗ - 0.2" Cắt',
                        wood_7mm_cutting: 'Gỗ - 0.3" Cắt',
                        wood_8mm_cutting: 'Gỗ - 0.3" Cắt',
                        wood_10mm_cutting: 'Gỗ - 0.4" Cắt',
                        wood_engraving: 'Gỗ - Khắc',
                        acrylic_3mm_cutting: 'Acrylic - 0.1" Cắt',
                        acrylic_5mm_cutting: 'Acrylic - 0,2 inch Cắt',
                        acrylic_8mm_cutting: 'Acrylic - 0,3 inch Cắt',
                        acrylic_10mm_cutting: 'Acrylic - 0,4 inch Cắt',
                        acrylic_engraving: 'Acrylic - Khắc',
                        mdf_3mm_cutting: 'MDF New Zealand - 0,1 inch Cắt',
                        mdf_5mm_cutting: 'MDF New Zealand - 0,2 inch Cắt',
                        mdf_engraving: 'MDF New Zealand - Khắc',
                        leather_3mm_cutting: 'Da - 0,1 inch Cắt',
                        leather_5mm_cutting: 'Da - 0,2 inch Cắt',
                        leather_engraving: 'Da - Khắc',
                        denim_1mm_cutting: 'Denim - 0,04 inch Cắt',
                        fabric_3mm_cutting: 'Vải - 0,1 inch Cắt',
                        fabric_5mm_cutting: 'Vải - 0,2 inch Cắt',
                        fabric_engraving: 'Vải - Khắc',
                        rubber_bw_engraving: 'Cao su - Khắc',
                        glass_bw_engraving: 'Kính - Khắc',
                        metal_bw_engraving: 'Kim loại - Khắc',
                        steel_engraving_spray_engraving: 'Kim loại - Khắc',
                        stainless_steel_bw_engraving_diode: 'Kim loại - Khắc (Laser diode)',
                        gold_engraving: 'Vàng - Khắc',
                        brass_engraving: 'Đồng - Khắc',
                        ti_engraving: 'Titan - Khắc',
                        stainless_steel_engraving: 'Thép không gỉ - Khắc',
                        aluminum_engraving: 'Nhôm - Khắc',
                        black_acrylic_3mm_cutting: 'Nhựa acrylic đen - 0.1" Cắt',
                        black_acrylic_5mm_cutting: 'Nhựa acrylic đen - 0.2" Cắt',
                        black_acrylic_engraving: 'Nhựa acrylic đen - Khắc',
                        abs_engraving: 'ABS - Khắc',
                        silver_engraving: 'Bạc - Khắc',
                        iron_engraving: 'Sắt - Khắc',
                        fabric_printing: 'Vải - In',
                        canvas_printing: 'Vải bạt - In',
                        cardstock_printing: 'Giấy cứng - In',
                        wood_printing: 'Gỗ - In',
                        bamboo_printing: 'Tre - In',
                        cork_printing: 'Liège - In',
                        flat_stone_printing: 'Đá phẳng - In',
                        acrylic_printing: 'Nhựa acrylic - In',
                        pc_printing: 'PC - In',
                        stainless_steel_printing: 'Thép không gỉ - In',
                        gloss_leather_printing: 'Da bóng - In',
                        glass_printing: 'Kính - In',
                        aluminum_light: 'Nhôm (sáng)',
                        stainless_steel_dark: 'Thép không gỉ (tối)',
                        stainless_steel_light: 'Thép không gỉ (sáng)',
                        brass_dark: 'Đồng thau (tối)',
                        brass_light: 'Đồng thau (sáng)',
                        copper: 'Đồng',
                        titanium_dark: 'Titan (tối)',
                        titanium_light: 'Titan (sáng)',
                        black_abs: 'ABS đen',
                        white_abs: 'ABS trắng',
                        opaque_acrylic: 'Acrylic mờ',
                        stone: 'Đá',
                    },
                },
                laser_speed: {
                    text: 'Tốc độ',
                    unit: 'mm/s',
                    fast: 'Nhanh',
                    slow: 'Chậm',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Công suất',
                    high: 'Cao',
                    low: 'Thấp',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Loại mực',
                    normal: 'Mực',
                    UV: 'Mực UV',
                },
                para_in_use: 'Thông số này đang được sử dụng.',
                do_not_adjust_default_para: 'Không thể điều chỉnh thiết lập mặc định.',
                existing_name: 'Tên này đã được sử dụng.',
                presets: 'Thiết lập',
                preset_management: {
                    preset: 'Thiết Lập Trước',
                    title: 'Quản Lý Thông Số',
                    add_new: 'Thêm Mới',
                    lower_focus_by: 'Giảm Tiêu Điểm Bởi',
                    save_and_exit: 'Lưu và thoát',
                    delete: 'Xóa',
                    reset: 'Đặt lại',
                    sure_to_reset: 'Điều này sẽ xóa các tham số tùy chỉnh của bạn và đặt lại tất cả các cài đặt trước, bạn có chắc chắn muốn tiếp tục không?',
                    show_all: 'Hiển Thị Tất Cả',
                    laser: 'Laser',
                    print: 'In',
                    export: 'Xuất',
                    export_preset_title: 'Xuất cấu hình mặc định',
                    import: 'Nhập',
                    sure_to_import_presets: 'Điều này sẽ tải bố trí của các cài đặt trước và ghi đè các tham số tùy chỉnh, bạn có chắc chắn muốn tiếp tục không?',
                    new_preset_name: 'Tên cấu hình mới',
                },
            },
            object_panel: {
                zoom: 'Thu phóng',
                group: 'Nhóm',
                ungroup: 'Bỏ nhóm',
                distribute: 'Phân bố',
                hdist: 'Phân bố theo chiều ngang',
                vdist: 'Phân bố theo chiều dọc',
                align: 'Căn chỉnh',
                left_align: 'Căn trái',
                center_align: 'Căn giữa',
                right_align: 'Căn phải',
                top_align: 'Căn trên',
                middle_align: 'Căn giữa',
                bottom_align: 'Căn dưới',
                boolean: 'Phép toán Boole',
                union: 'Kết hợp',
                subtract: 'Trừ',
                intersect: 'Giao',
                difference: 'Khác biệt',
                flip: 'Lật',
                hflip: 'Lật ngang',
                vflip: 'Lật dọc',
                lock_aspect: 'Khóa tỷ lệ',
                unlock_aspect: 'Mở khóa tỷ lệ',
                option_panel: {
                    fill: 'Mức độ đặc',
                    rounded_corner: 'Góc bo tròn',
                    sides: 'Cạnh',
                    font_family: 'Phông chữ',
                    font_style: 'Kiểu',
                    font_size: 'Cỡ chữ',
                    letter_spacing: 'Khoảng cách chữ',
                    line_spacing: 'Khoảng cách dòng',
                    vertical_text: 'Chữ dọc',
                    start_offset: 'Lùi chữ',
                    vertical_align: 'Căn chỉnh',
                    text_infill: 'Độ đặc chữ',
                    path_infill: 'Độ đặc đường',
                    shading: 'Màu sắc',
                    pwm_engraving: 'Chế Độ Độ Sâu',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Ngưỡng',
                    threshold_short: 'Ngưỡng',
                    stroke: 'Đường viền',
                    stroke_color: 'Màu viền',
                    stroke_width: 'Độ rộng viền',
                    color: 'Màu sắc',
                },
                actions_panel: {
                    replace_with: 'Thay thế bằng...',
                    replace_with_short: 'Thay',
                    trace: 'Vẽ dọc theo đường viền',
                    grading: 'Phân cấp màu',
                    brightness: 'Độ sáng',
                    sharpen: 'Làm nét',
                    crop: 'Cắt',
                    bevel: 'Bo góc',
                    invert: 'Đảo ngược',
                    weld_text: 'Ghép chữ',
                    convert_to_path: 'Chuyển đổi thành đường viền',
                    fetching_web_font: 'Đang tải phông chữ...',
                    uploading_font_to_machine: 'Đang tải phông chữ lên máy...',
                    wait_for_parsing_font: 'Đang phân tích phông chữ... Vui lòng đợi',
                    offset: 'Offset',
                    array: 'Mảng',
                    auto_fit: 'Tự Động Vừa Khít',
                    smart_nest: 'Tổ Thông Minh',
                    decompose_path: 'Tách',
                    disassemble_use: 'Tháo rời',
                    create_textpath: 'Tạo chữ trên đường viền',
                    create_textpath_short: 'Chữ trên đường dẫn',
                    detach_path: 'Tách chữ khỏi đường dẫn',
                    detach_path_short: 'Tách',
                    edit_path: 'Chỉnh sửa đường dẫn',
                    disassembling: 'Đang tháo rời...',
                    ungrouping: 'Đang bỏ nhóm...',
                    simplify: 'Đơn giản hóa',
                    ai_bg_removal: 'Xóa nền',
                    ai_bg_removal_short: 'Xóa nền',
                    ai_bg_removal_reminder: 'Nhấn nút sẽ sử dụng ngay 0.2 Tín dụng, bạn có muốn tiếp tục không?',
                    outline: 'Đường viền',
                },
                path_edit_panel: {
                    node_type: 'KIỂU NODE',
                    sharp: 'Sắc nét',
                    round: 'Tròn',
                    connect: 'Kết nối',
                    disconnect: 'Ngắt kết nối',
                    delete: 'Xóa',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Đang chuyển đổi chữ thành đường dẫn...',
            retreive_image_data: 'Đang truy xuất dữ liệu hình ảnh...',
            export_file_error_ask_for_upload: 'Xuất tệp thất bại. Bạn có muốn cung cấp cảnh làm việc để báo cáo lỗi không?',
        },
        image_trace_panel: {
            apply: 'Áp dụng',
            back: 'Quay lại',
            cancel: 'Hủy',
            next: 'Tiếp',
            brightness: 'Độ sáng',
            contrast: 'Độ tương phản',
            threshold: 'Ngưỡng',
            okay: 'Đồng ý',
            tuning: 'Thiết lập',
        },
        photo_edit_panel: {
            apply: 'Áp dụng',
            back: 'Quay lại',
            cancel: 'Hủy',
            next: 'Tiếp',
            sharpen: 'Làm nét',
            sharpness: 'Độ sắc nét',
            radius: 'Bán kính',
            crop: 'Cắt',
            aspect_ratio: 'Tỷ lệ',
            original: 'Gốc',
            free: 'Tự do',
            curve: 'Đường cong',
            start: 'Bắt đầu',
            processing: 'Đang xử lý',
            invert: 'Đảo màu',
            okay: 'Đồng ý',
            compare: 'So sánh',
            phote_edit: 'Chỉnh sửa ảnh',
            brightness_and_contrast: 'Độ sáng / Độ tương phản',
            brightness: 'Độ sáng',
            contrast: 'Độ tương phản',
            rotary_warped: 'Biến Dạng Xoay',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Đường kính',
            circumference: 'Chu vi',
            warp: 'Biến Dạng',
        },
        document_panel: {
            document_settings: 'Cài đặt tài liệu',
            machine: 'Máy',
            laser_source: 'Nguồn laser',
            workarea: 'Khu vực làm việc',
            rotary_mode: 'Chế độ xoay',
            borderless_mode: 'Mở đáy',
            engrave_dpi: 'Độ phân giải',
            enable_diode: 'Laser điốt',
            enable_autofocus: 'Tự động lấy nét',
            extend_workarea: 'Mở rộng khu vực làm việc',
            mirror: 'Gương',
            pass_through: 'Đi Qua',
            pass_through_height_desc: 'Nhập chiều dài của đối tượng để mở rộng khu vực làm việc.',
            start_position: 'Vị Trí Bắt Đầu',
            start_from: 'Bắt Đầu Từ',
            origin: 'Gốc',
            current_position: 'Vị Trí Hiện Tại',
            job_origin: 'Gốc Công Việc',
            add_on: 'Phụ kiện',
            low: 'Thấp',
            medium: 'Trung bình',
            high: 'Cao',
            ultra: 'Rất cao',
            enable: 'Bật',
            disable: 'Tắt',
            notification: {
                changeFromPrintingWorkareaTitle: 'Bạn có muốn chuyển các lớp in sang lớp laser không?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Đang phân tích font... Vui lòng đợi',
            text_to_path: {
                font_substitute_pop: 'Văn bản của bạn chứa các ký tự không được hỗ trợ bởi font hiện tại. <br/>Bạn có muốn sử dụng <strong>%s</strong> làm ký tự thay thế?',
                check_thumbnail_warning: 'Một số văn bản đã được thay đổi sang các font khác khi chuyển đổi văn bản thành đường dẫn và một số ký tự có thể không được chuyển đổi bình thường. Vui lòng kiểm tra lại trước khi gửi.',
                error_when_parsing_text: 'Lỗi khi chuyển đổi văn bản thành đường dẫn',
                use_current_font: 'Sử dụng font hiện tại',
                retry: 'Vui lòng thử lại sau hoặc chọn font khác',
            },
            lock_desc: 'Giữ nguyên tỷ lệ chiều rộng và chiều cao (SHIFT)',
        },
        tool_panels: {
            cancel: 'Hủy',
            confirm: 'Xác nhận',
            grid_array: 'Tạo mảng lưới',
            array_dimension: 'Kích thước mảng',
            rows: 'Hàng',
            columns: 'Cột',
            array_interval: 'Khoảng cách mảng',
            dx: 'X',
            dy: 'Y',
            offset: 'Offset',
            nest: 'Sắp xếp tối ưu',
            _offset: {
                direction: 'Hướng offset',
                inward: 'Hướng trong',
                outward: 'Hướng ngoài',
                dist: 'Khoảng cách offset',
                corner_type: 'Góc',
                sharp: 'Sắc nét',
                round: 'Tròn',
                fail_message: 'Không thể offset đối tượng',
                not_support_message: 'Các phần tử được chọn chứa thẻ SVG không hỗ trợ:\nHình ảnh, Nhóm, Văn bản và Đối tượng nhập:\n',
            },
            _nest: {
                start_nest: 'Sắp xếp',
                stop_nest: 'Dừng',
                end: 'Đóng',
                spacing: 'Khoảng cách',
                rotations: 'Xoay có thể',
                no_element: 'Không có phần tử để sắp xếp',
            },
        },
        network_testing_panel: {
            network_testing: 'Kiểm tra mạng',
            local_ip: 'Địa chỉ IP cục bộ:',
            insert_ip: 'Địa chỉ IP thiết bị đích:',
            empty_ip: '#818 Vui lòng nhập địa chỉ IP thiết bị đích trước',
            start: 'Bắt đầu',
            end: 'Kết thúc',
            testing: 'Đang kiểm tra mạng...',
            invalid_ip: '#818 Địa chỉ IP không hợp lệ',
            ip_startswith_169: '#843 Địa chỉ IP máy bắt đầu bằng 169.254',
            connection_quality: 'Chất lượng kết nối',
            average_response: 'Thời gian phản hồi trung bình',
            test_completed: 'Kiểm tra hoàn tất',
            test_fail: 'Kiểm tra thất bại',
            cannot_connect_1: '#840 Không thể kết nối với địa chỉ IP đích.',
            cannot_connect_2: '#840 Không thể kết nối với địa chỉ IP đích. Hãy chắc chắn rằng địa chỉ đích cùng mạng.',
            network_unhealthy: '#841 Chất lượng kết nối <70 hoặc thời gian phản hồi trung bình >100ms',
            device_not_on_list: '#842 Máy không có trong danh sách, nhưng chất lượng kết nối >70 và thời gian phản hồi trung bình <100ms',
            hint_device_often_on_list: 'Máy thường không có trong danh sách?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Không thể kết nối khi gửi công việc?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Có lỗi kết nối khi gửi công việc?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Không lấy được địa chỉ IP cục bộ',
            fail_to_start_network_test: '#817 Không thể bắt đầu kiểm tra mạng',
            linux_permission_hint: 'Lỗi này thường do quyền truy cập không đủ. Hãy chạy "sudo beam-studio --no-sandbox" trong Terminal để có quyền và kiểm tra mạng',
        },
        layer_color_config_panel: {
            layer_color_config: 'Cấu hình màu theo lớp',
            color: 'Màu sắc',
            power: 'Công suất',
            speed: 'Tốc độ',
            repeat: 'Lặp lại',
            add: 'Thêm',
            default: 'Đặt lại mặc định',
            add_config: 'Thêm màu',
            in_use: 'Màu này đang được sử dụng',
            no_input: 'Vui lòng nhập mã màu hợp lệ',
            sure_to_reset: 'Bạn sẽ mất tất cả các thiết lập tùy chỉnh, bạn có chắc chắn muốn đặt lại về mặc định?',
            sure_to_delete: 'Bạn có chắc chắn muốn xóa cài đặt màu này?',
        },
        rating_panel: {
            title: 'Thích Beam Studio?',
            description: 'Nếu bạn thích Beam Studio, chúng tôi rất đánh giá cao nếu bạn có thể đánh giá ứng dụng của chúng tôi.',
            dont_show_again: 'Không hiển thị lần sau.',
            thank_you: 'Cảm ơn bạn!',
        },
        svg_editor: {
            unnsupported_file_type: 'Định dạng tệp không được hỗ trợ trực tiếp. Vui lòng chuyển đổi tệp thành SVG hoặc ảnh bitmap.',
            unable_to_fetch_clipboard_img: 'Không tải được hình ảnh từ bộ nhớ tạm.',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Phát',
            pause: 'Tạm dừng',
            stop: 'Dừng',
            play_speed: 'Tốc độ phát lại',
            travel_path: 'Đường đi',
            invert: 'Đảo ngược',
            preview_info: 'Thông tin xem trước',
            size: 'Kích thước',
            estimated_time: 'Tổng thời gian ước tính',
            cut_time: 'Thời gian cắt',
            rapid_time: 'Thời gian di chuyển',
            cut_distance: 'Khoảng cách cắt',
            rapid_distance: 'Khoảng cách di chuyển',
            current_position: 'Vị trí hiện tại',
            remark: '* Tất cả thông tin là giá trị ước tính tham khảo.',
            start_here: 'Bắt đầu tại đây',
            end_preview: 'Kết thúc xem trước',
        },
        shapes_panel: {
            title: 'Các yếu tố',
            basic: 'Cơ bản',
            shape: 'Hình dạng',
            graphics: 'Đồ họa',
            arrow: 'Mũi tên',
            label: 'Nhãn',
            decor: 'Trang trí',
            circular: 'Tròn',
            corner: 'Góc',
            line: 'Đường thẳng',
            photo: 'Ảnh',
            ribbon: 'Ruy băng',
            speech: 'Phát biểu',
            text: 'Khung văn bản',
            animals: 'Động vật',
            birds: 'Chim',
            land: 'Đất liền',
            sea: 'Biển',
            holidays: 'Ngày lễ',
            celebration: 'Kỷ niệm',
            CNY: 'Tết',
            easter: 'Phục Sinh',
            halloween: 'Halloween',
            valentines: 'Lễ Tình Nhân',
            Xmas: 'Giáng sinh',
            nature: 'Thiên nhiên',
            elements: 'Các yếu tố',
            environment: 'Môi trường',
            plants: 'Thực vật',
            weather: 'Thời tiết',
        },
        announcement_panel: {
            title: 'Thông báo',
            dont_show_again: 'Không hiển thị lại',
        },
    },
    editor: {
        prespray_area: 'Vùng làm ẩm ban đầu',
        opacity: 'Độ mờ xem trước',
        exposure: 'Xem trước phơi sáng',
    },
    flux_id_login: {
        connection_fail: '#847 Lỗi kết nối dịch vụ thành viên FLUX.',
        login_success: 'Đăng nhập thành công.',
        login: 'Đăng nhập',
        unlock_shape_library: 'Đăng nhập để mở khóa cơ sở dữ liệu hình dạng.',
        email: 'Email',
        password: 'Mật khẩu',
        remember_me: 'Nhớ tôi',
        forget_password: 'Quên mật khẩu?',
        register: 'Tạo tài khoản FLUX',
        offline: 'Làm việc ngoại tuyến',
        work_offline: 'Làm việc ngoại tuyến',
        incorrect: 'Email hoặc mật khẩu không chính xác',
        not_verified: 'Email chưa được xác minh',
        new_to_flux: 'Mới dùng FLUX? Đăng ký tài khoản',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Khám phá các gói FLUX+',
            thank_you: 'Cảm ơn vì đã là thành viên quý giá!',
            ai_credit_tooltip: 'Để xóa nền AI',
            flux_credit_tooltip: 'Để tải tệp thiết kế và xóa nền AI',
            goto_member_center: 'Đi đến Trung tâm Thành viên',
            access_plus_feature_1: 'Bạn đang truy cập một',
            access_plus_feature_2: 'tính năng.',
            access_plus_feature_note: 'Bạn cần có tư cách thành viên FLUX+ để truy cập tính năng này.',
            access_monotype_feature: 'Bạn không có bổ trợ Phông chữ Monotype.',
            access_monotype_feature_note: 'Bạn cần có tư cách thành viên FLUX+ Pro hoặc bổ trợ Phông chữ Monotype để truy cập tính năng này.',
            learn_more: 'Tìm hiểu thêm',
            get_addon: 'Nhận bổ trợ',
            subscribe_now: 'Đăng ký ngay',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Xóa nền bằng AI',
                my_cloud: 'Lưu trữ đám mây của tôi',
                boxgen: 'Tạo hình hộp 3D',
                dmkt: '1000+ mẫu thiết kế',
                monotype: '250+ phông chữ chuyên nghiệp',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Đăng nhập để mở khóa thư viện hình dạng',
        enjoy_shape_library: 'Tận hưởng thư viện hình dạng',
        shapes: 'Hình dạng',
        elements: 'Các yếu tố',
        recent: 'Gần đây',
        search: 'Tìm kiếm',
        clear: 'Xóa',
        export_svg_title: 'Không thể Xuất SVG',
        export_svg_warning: 'Dự án này chứa các đối tượng được bảo vệ bởi luật sở hữu trí tuệ. Vì vậy Beam Studio sẽ tự động loại trừ các đối tượng này trong quá trình xuất. Bạn vẫn có thể lưu dự án trong định dạng Beam Studio Scene (.beam) để giữ toàn bộ dữ liệu. Bạn vẫn muốn xuất?',
        learn_more: 'Tìm hiểu thêm',
    },
    change_logs: {
        change_log: 'Nhật ký thay đổi:',
        added: 'Đã thêm:',
        fixed: 'Đã sửa:',
        changed: 'Đã thay đổi:',
        see_older_version: 'Xem phiên bản cũ',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Chọn thiết bị',
        auth_failure: '#811 Xác thực thất bại',
        unable_to_connect: '#810 Không thể kết nối ổn định với máy',
    },
    device: {
        pause: 'Tạm dừng',
        paused: 'Đã tạm dừng',
        pausing: 'Đang tạm dừng',
        select_printer: 'Chọn máy in',
        retry: 'Thử lại',
        status: 'Trạng thái',
        busy: 'Bận',
        ready: 'Sẵn sàng',
        reset: 'Khởi động lại',
        abort: 'Hủy',
        start: 'Bắt đầu',
        please_wait: 'Vui lòng chờ...',
        quit: 'Thoát',
        completing: 'Hoàn tất',
        aborted: 'Đã hủy',
        completed: 'Đã hoàn thành',
        aborting: 'Đang hủy',
        starting: 'Đang khởi động',
        preparing: 'Đang chuẩn bị',
        resuming: 'Đang tiếp tục',
        scanning: 'Đang quét',
        occupied: 'Đang sử dụng',
        running: 'Đang hoạt động',
        uploading: 'Đang tải lên',
        processing: 'Đang xử lý',
        disconnectedError: {
            caption: 'Máy bị ngắt kết nối',
            message: 'Vui lòng kiểm tra kết nối mạng của %s',
        },
        unknown: 'Không xác định',
        pausedFromError: 'Tạm dừng do lỗi',
        model_name: 'Tên kiểu máy',
        IP: 'IP',
        serial_number: 'Số serial',
        firmware_version: 'Phiên bản firmware',
        UUID: 'UUID',
        select: 'Chọn',
        deviceList: 'Danh sách thiết bị',
        disable: 'Tắt',
        enable: 'Bật',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: 'Thay đổi đầu công cụ',
    },
    monitor: {
        monitor: 'GIÁM SÁT',
        go: 'Bắt đầu',
        resume: 'Tiếp tục',
        start: 'Bắt đầu',
        pause: 'Tạm dừng',
        stop: 'Dừng',
        record: 'Ghi lại',
        camera: 'Camera',
        taskTab: 'Nhiệm vụ',
        connecting: 'Đang kết nối, vui lòng đợi...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Lỗi nghiêm trọng: Bo mạch chủ ngoại tuyến. Vui lòng liên hệ hỗ trợ FLUX.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Lỗi nghiêm trọng: Hệ thống phụ không phản hồi. Vui lòng liên hệ hỗ trợ FLUX.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Vui lòng kiểm tra bể nước của bạn.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Đóng cửa để tiếp tục.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Quá nóng. Vui lòng đợi vài phút.',
        HARDWARE_ERROR_BOTTOM_OPENED: 'Vui lòng đóng đáy để tiếp tục.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: 'Lỗi khởi động trục Z',
        HARDWARE_ERROR_DRAWER_OPENED: 'Ngăn kéo đang mở',
        HARDWARE_ERROR_FIRE_DETECTED: 'Cảm biến lửa bất thường',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: 'Cảm biến luồng khí bất thường',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: 'Không phát hiện module quay',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: 'Lỗi kéo về vị trí gốc',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: 'Phát hiện module không khớp. Vui lòng lắp đúng module để tiếp tục.',
        HARDWARE_ERROR_HEADTYPE_NONE: 'Không phát hiện module. Vui lòng đảm bảo module được lắp đúng để tiếp tục.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: 'Phát hiện module không xác định. Vui lòng lắp đúng module để tiếp tục.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: 'Module máy in không phản hồi.',
        USER_OPERATION_ROTARY_PAUSE: 'Vui lòng chuyển sang module quay.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Vui lòng tháo hộp mực để tiếp tục.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: 'Phát hiện module không khớp. Vui lòng lắp đúng module để tiếp tục.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: 'Không phát hiện module. Vui lòng đảm bảo module được lắp đúng để tiếp tục.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: 'Phát hiện module không xác định. Vui lòng lắp đúng module để tiếp tục.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Vui lòng lắp đúng module để tiếp tục.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Vui lòng chèn hộp mực để tiếp tục.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Vui lòng chèn hộp mực chính xác để tiếp tục.',
        USER_OPERATION: 'Vui lòng tuân theo hướng dẫn trên bảng điều khiển của thiết bị để tiếp tục.',
        RESOURCE_BUSY: 'Máy đang bận\nNếu không đang chạy, vui lòng khởi động lại máy',
        DEVICE_ERROR: 'Đã xảy ra lỗi\nVui lòng khởi động lại máy',
        NO_RESPONSE: '#905 Lỗi kết nối với bo mạch chủ.\nVui lòng khởi động lại máy.',
        SUBSYSTEM_ERROR: '#402 Lỗi nghiêm trọng: Hệ thống con không phản hồi. Vui lòng liên hệ hỗ trợ FLUX.',
        HARDWARE_FAILURE: 'Đã xảy ra lỗi\nVui lòng khởi động lại máy',
        MAINBOARD_OFFLINE: '#905 Lỗi kết nối với bo mạch chủ.\nVui lòng khởi động lại máy.',
        bug_report: 'Báo cáo lỗi',
        processing: 'Đang xử lý',
        savingPreview: 'Đang tạo ảnh xem trước',
        hour: 'giờ',
        minute: 'phút',
        second: 'giây',
        left: 'trái',
        temperature: 'Nhiệt độ',
        forceStop: 'Bạn có muốn hủy công việc hiện tại không?',
        upload: 'Tải lên',
        download: 'Tải xuống',
        relocate: 'Di chuyển',
        cancel: 'Hủy',
        prepareRelocate: 'Đang chuẩn bị di chuyển',
        extensionNotSupported: 'Định dạng tệp không được hỗ trợ',
        fileExistContinue: 'Tệp đã tồn tại, bạn có muốn ghi đè không?',
        confirmFileDelete: 'Bạn có chắc chắn muốn xóa tệp này không?',
        ask_reconnect: 'Kết nối với thiết bị đã bị ngắt. Bạn có muốn kết nối lại không?',
        task: {
            BEAMBOX: 'Khắc laser',
            'N/A': 'Chế độ tự do',
        },
    },
    alert: {
        caption: 'Lỗi',
        duplicated_preset_name: 'Tên tiền định đã tồn tại',
        info: 'THÔNG TIN',
        warning: 'CẢNH BÁO',
        error: 'Ối...',
        instruction: 'Hướng dẫn',
        oops: 'Ối...',
        retry: 'Thử lại',
        abort: 'Hủy bỏ',
        confirm: 'Xác nhận',
        cancel: 'Hủy bỏ',
        close: 'Đóng',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Có',
        no: 'Không',
        stop: 'Dừng',
        save: 'Lưu',
        dont_save: 'Không lưu',
        learn_more: 'Tìm hiểu thêm',
    },
    caption: {
        connectionTimeout: 'Hết thời gian kết nối',
    },
    message: {
        cancelling: 'Hủy bỏ...',
        connecting: 'Đang kết nối...',
        connectingMachine: 'Đang kết nối %s...',
        tryingToConenctMachine: 'Đang thử kết nối với máy...',
        connected: 'Đã kết nối',
        authenticating: 'Đang xác thực...',
        enteringRawMode: 'Đang vào chế độ thô...',
        endingRawMode: 'Đang thoát chế độ thô...',
        enteringLineCheckMode: 'Đang vào chế độ kiểm tra đường...',
        endingLineCheckMode: 'Đang thoát chế độ kiểm tra đường...',
        enteringRedLaserMeasureMode: 'Đang vào chế độ đo laser đỏ...',
        redLaserTakingReference: 'Đang lấy tham chiếu...',
        exitingRotaryMode: 'Đang thoát chế độ xoay...',
        turningOffFan: 'Đang tắt quạt...',
        turningOffAirPump: 'Đang tắt bơm khí...',
        gettingLaserSpeed: 'Đang đọc tốc độ đầu laser...',
        settingLaserSpeed: 'Đang đặt tốc độ đầu laser...',
        retrievingCameraOffset: 'Đang đọc bù camera...',
        connectingCamera: 'Đang kết nối camera...',
        homing: 'Đang hoàn vị...',
        connectionTimeout: '#805 Hết thời gian kết nối thiết bị. Vui lòng kiểm tra kết nối mạng và đèn báo Wi-Fi của máy.',
        getProbePosition: 'Đang lấy vị trí đầu dò...',
        device_not_found: {
            caption: 'Không tìm thấy máy mặc định',
            message: '#812 Vui lòng kiểm tra đèn báo Wi-Fi của máy',
        },
        device_busy: {
            caption: 'Máy bận',
            message: 'Máy đang thực hiện tác vụ khác, vui lòng thử lại sau. Nếu máy ngừng hoạt động, vui lòng khởi động lại.',
        },
        device_is_used: 'Máy đang được sử dụng, bạn có muốn hủy tác vụ hiện tại không?',
        unknown_error: '#821 Ứng dụng gặp lỗi không xác định, vui lòng sử dụng Trợ giúp > Menu > Báo cáo lỗi.',
        unknown_device: '#826 Không thể kết nối với máy, vui lòng đảm bảo USB được cắm vào máy',
        unsupport_osx_version: 'Phiên bản macOS %s có thể không hỗ trợ một số chức năng. Vui lòng nâng cấp lên macOS 11+.',
        unsupport_win_version: 'Phiên bản HĐH %s có thể không hỗ trợ một số chức năng. Vui lòng nâng cấp lên phiên bản mới nhất.',
        need_password: 'Cần Mật khẩu để Kết nối với Máy',
        unavailableWorkarea: '#804 Khu vực làm việc hiện tại vượt quá khu vực làm việc của máy này. Vui lòng kiểm tra khu vực làm việc của máy đã chọn hoặc đặt lại khu vực làm việc.',
        please_enter_dpi: 'Vui lòng nhập Đơn vị của tệp tin (tính bằng mm)',
        auth_error: '#820 Lỗi xác thực: Vui lòng cập nhật Beam Studio và firmware máy lên phiên bản mới nhất.',
        usb_unplugged: 'Kết nối USB bị mất. Vui lòng kiểm tra kết nối USB của bạn',
        uploading_fcode: 'Đang tải lên FCode',
        cant_connect_to_device: '#827 Không thể kết nối với máy, vui lòng kiểm tra kết nối của bạn',
        unable_to_find_machine: 'Không tìm thấy máy ',
        disconnected: 'Kết nối không ổn định, vui lòng kiểm tra kết nối thiết bị và thử lại sau',
        unable_to_start: '#830 Không thể bắt đầu tác vụ. Vui lòng thử lại. Nếu điều này xảy ra lại, vui lòng liên hệ với chúng tôi để báo cáo lỗi:',
        camera: {
            camera_cable_unstable: 'Phát hiện camera đang truyền ảnh không ổn định. Xem trước vẫn có thể thực hiện bình thường, nhưng có thể gặp vấn đề xem trước chậm hoặc hết thời gian chờ.',
            fail_to_transmit_image: '#845 Đã xảy ra lỗi khi truyền ảnh. Vui lòng khởi động lại máy hoặc Beam Studio. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">hướng dẫn này</a>.',
            ws_closed_unexpectly: '#844 Kết nối với camera máy đã bị đóng bất ngờ. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">hướng dẫn này</a>.',
            continue_preview: 'Tiếp tục',
            abort_preview: 'Hủy bỏ',
        },
        preview: {
            camera_preview: 'Xem trước Camera',
            auto_focus: 'Lấy nét tự động',
            auto_focus_instruction: 'Vui lòng di chuyển đầu mô-đun laser phía trên vật thể, và làm theo hướng dẫn hoạt ảnh để nhấn AF để lấy nét.',
            already_performed_auto_focus: 'Bạn đã thực hiện lấy nét tự động, có sử dụng giá trị hiện tại không?',
            please_enter_height: 'Vui lòng nhập chiều cao của vật thể. Để chụp ảnh camera chính xác.',
            apply: 'Áp dụng',
            enter_manually: 'Nhập thủ công',
            adjust: 'Điều chỉnh',
            adjust_height_tooltip: 'Nhấp vào hộp kiểm để bật chỉnh sửa.',
        },
        unsupported_example_file: 'Tệp ví dụ đã chọn không được hỗ trợ bởi khu vực làm việc hiện tại.',
        time_remaining: 'Thời gian còn lại:',
        promark_disconnected: '#850 Kết nối thiết bị bị gián đoạn, vui lòng kiểm tra trạng thái kết nối của thiết bị.',
        swiftray_disconnected: 'Không thể kết nối với backend, đang thử kết nối lại.',
        swiftray_reconnected: 'Backend đã được kết nối lại, vui lòng thử gửi lại công việc.',
        device_blocked: {
            caption: 'Số Serial Không Hợp Lệ',
            online: 'Số serial của thiết bị dường như đã bị vô hiệu hóa. Vui lòng cung cấp số serial của thiết bị (nằm ở mặt sau của thiết bị) cho nhà bán lẻ của bạn và yêu cầu họ liên hệ với support@flux3dp.com để kích hoạt quyền sử dụng thiết bị.',
            offline: 'Số serial của thiết bị bị giới hạn chỉ sử dụng ngoại tuyến vì dường như đã bị vô hiệu hóa. Vui lòng cung cấp số serial của thiết bị (nằm ở mặt sau của thiết bị) cho nhà bán lẻ của bạn và yêu cầu họ liên hệ với support@flux3dp.com để kích hoạt quyền sử dụng trực tuyến. Nếu bạn muốn sử dụng thiết bị ngoại tuyến, vui lòng liên hệ trực tiếp với support@flux3dp.com.',
        },
    },
    machine_status: {
        '0': 'Nghỉ',
        '1': 'Khởi động',
        '2': 'ST_TRANSFORM',
        '4': 'Khởi động',
        '6': 'Tiếp tục',
        '16': 'Đang hoạt động',
        '18': 'Tiếp tục',
        '32': 'Tạm dừng',
        '36': 'Tạm dừng',
        '38': 'Đang tạm dừng',
        '48': 'Tạm dừng',
        '50': 'Đang tạm dừng',
        '64': 'Hoàn thành',
        '66': 'Đang hoàn thành',
        '68': 'Đang chuẩn bị',
        '128': 'Đã hủy',
        '256': 'Cảnh báo',
        '512': 'Lỗi nghiêm trọng',
        '-17': 'Chế độ Cartridge IO',
        '-10': 'Chế độ bảo trì',
        '-2': 'Đang quét',
        '-1': 'Đang bảo trì',
        UNKNOWN: 'Không xác định',
    },
    calibration: {
        update_firmware_msg1: 'Phần mềm của bạn không hỗ trợ chức năng này. Vui lòng nâng cấp lên phiên bản',
        update_firmware_msg2: 'trở lên để tiếp tục。 (Menu > Máy > [Máy của bạn] > Cập nhật phần mềm)',
        camera_calibration: 'Hiệu chỉnh camera',
        diode_calibration: 'Hiệu chỉnh module laser điốt',
        module_calibration_printer: 'Hiệu chỉnh module máy in',
        module_calibration_2w_ir: 'Hiệu chỉnh module hồng ngoại',
        back: 'Quay Lại',
        next: 'Tiếp',
        skip: 'Bỏ Qua',
        cancel: 'Hủy Bỏ',
        finish: 'Hoàn Tất',
        do_engraving: 'Thực Hiện Khắc',
        start_engrave: 'Bắt Đầu Khắc',
        start_printing: 'Bắt Đầu In Ấn',
        ask_for_readjust: 'Bạn có muốn bỏ qua bước khắc và chụp ảnh để hiệu chỉnh trực tiếp?',
        please_goto_beambox_first: 'Vui lòng chuyển sang Chế độ Khắc (Beambox) để sử dụng tính năng này.',
        please_place_paper: 'Vui lòng đặt một tờ giấy khổ A4 hoặc Letter màu trắng ở góc trái trên của khu vực làm việc.',
        please_place_paper_center: 'Vui lòng đặt một tờ giấy khổ A4 hoặc Letter màu trắng ở giữa khu vực làm việc.',
        please_place_dark_colored_paper: 'Vui lòng đặt một tờ giấy khổ A4 hoặc Letter màu tối ở giữa khu vực làm việc.',
        please_refocus: {
            beambox: 'Vui lòng điều chỉnh bệ đỡ đến điểm tiêu cự (chiều cao của mặt acrylic úp xuống)',
            beamo: 'Vui lòng điều chỉnh đầu laser để lấy nét vào vật khắc (chiều cao của mặt acrylic úp xuống)',
            beamo_af: 'Vui lòng nhấn đúp nút bên của phụ kiện lấy nét tự động và để đầu dò chạm nhẹ vào vật liệu.',
            hexa: 'Nhấn đúp nút điều chỉnh chiều cao để nâng bàn tổ ong lên và làm cho đầu dò chạm vào vật liệu khắc.',
        },
        without_af: 'Không có lấy nét tự động',
        with_af: 'Có lấy nét tự động',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Xoay',
        x_ratio: 'Tỷ lệ X',
        y_ratio: 'Tỷ lệ Y',
        show_last_config: 'Hiển thị kết quả gần nhất',
        use_last_config: 'Sử dụng giá trị hiệu chỉnh gần nhất',
        taking_picture: 'Đang chụp ảnh...',
        analyze_result_fail: 'Không thể phân tích ảnh chụp.<br/>Vui lòng đảm bảo:<br/>1. Ảnh chụp phủ kín toàn bộ tờ giấy.<br/>2. Bệ đỡ được lấy nét đúng cách.',
        drawing_calibration_image: 'Đang vẽ ảnh hiệu chỉnh...',
        calibrate_done: 'Hiệu chỉnh hoàn tất. Độ chính xác của camera tốt hơn khi lấy nét chính xác.',
        calibrate_done_diode: 'Hiệu chỉnh hoàn tất. Offset của mô-đun laser đi-ốt đã được lưu.',
        hint_red_square: 'Vui lòng căn chỉnh mép ngoài của hình vuông đỏ với hình vuông cắt.',
        hint_adjust_parameters: 'Sử dụng các thông số này để điều chỉnh hình vuông đỏ',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Khi hiệu chỉnh mô-đun laser đi-ốt, cần có máy ảnh.\nHãy đảm bảo máy ảnh của máy này đã được hiệu chỉnh.\nVà hãy điều chỉnh nền tảng đến điểm tiêu cự (chiều cao của acrylic đã quay xuống)',
            beamo: 'Khi hiệu chỉnh mô-đun laser đi-ốt, cần có máy ảnh.\nHãy đảm bảo máy ảnh của máy này đã được hiệu chỉnh.\nVà hãy điều chỉnh đầu laser để lấy nét vào vật khắc (chiều cao của acrylic đã quay xuống)',
        },
        downloading_pictures: 'Đang tải ảnh...',
        failed_to_download_pictures: '#848 Tải ảnh thất bại, vui lòng liên hệ hỗ trợ FLUX.',
        uploading_images: 'Đang tải ảnh lên...',
        calculating_camera_matrix: 'Đang tính toán ma trận camera...',
        calculating_regression_parameters: 'Đang tính toán tham số hồi quy...',
        failed_to_calibrate_camera: '#848 Hiệu chỉnh camera thất bại, vui lòng liên hệ hỗ trợ FLUX.',
        failed_to_save_calibration_results: '#849 Lưu kết quả hiệu chỉnh thất bại, vui lòng thử lại. Nếu vẫn xảy ra, vui lòng liên hệ hỗ trợ FLUX.',
        ador_autofocus_material: 'Nhấn biểu tượng “AF” trên màn hình chính của máy trong 3 giây và để đầu dò chạm nhẹ vào vật liệu.',
        ador_autofocus_focusing_block: 'Nhấn biểu tượng “AF” trên màn hình chính của máy trong 3 giây và để đầu dò chạm vào khối lấy nét.',
        align_red_cross_cut: 'Hãy căn chỉnh giữa dấu cộng đỏ với dấu cắt.',
        align_red_cross_print: 'Hãy căn chỉnh giữa dấu cộng đỏ với dấu in.',
        retake: 'Chụp lại',
        calibrate_camera_before_calibrate_modules: 'Vui lòng hiệu chỉnh camera trước khi hiệu chỉnh các mô-đun.',
        check_checkpoint_data: 'Dữ liệu kiểm tra điểm',
        checking_checkpoint: 'Đang kiểm tra dữ liệu kiểm tra điểm...',
        found_checkpoint: 'Dữ liệu kiểm tra điểm được tìm thấy trên thiết bị của bạn. Bạn có muốn khôi phục từ điểm kiểm tra không?',
        use_old_camera_parameter: 'Bạn có muốn sử dụng thông số ống kính máy ảnh hiện tại không?',
        downloading_checkpoint: 'Đang tải dữ liệu kiểm tra điểm...',
        failed_to_parse_checkpoint: 'Không thể phân tích dữ liệu kiểm tra điểm.',
        check_device_pictures: 'Kiểm tra hình ảnh thiết bị',
        checking_pictures: 'Đang kiểm tra hình ảnh thiết bị...',
        no_picutre_found: '#846 Thiết bị của bạn không có ảnh nguyên bản nào để hiệu chỉnh. Vui lòng liên hệ hỗ trợ FLUX.',
        unable_to_load_camera_parameters: "#851 Không có thông số hiệu chỉnh máy ảnh nào khả dụng trên thiết bị của bạn. Đi tới 'Hiệu chỉnh' > 'Hiệu chỉnh máy ảnh (Nâng cao)' để hoàn tất hiệu chỉnh và nhận thông số.",
        calibrating_with_device_pictures: 'Đang hiệu chỉnh với hình ảnh thiết bị...',
        failed_to_calibrate_with_pictures: '#848 Không thể hiệu chỉnh với hình ảnh thiết bị.',
        getting_plane_height: 'Đang Lấy Độ Cao Mặt Phẳng...',
        preparing_to_take_picture: 'Chuẩn bị để chụp ảnh...',
        put_paper: 'Đặt Giấy',
        put_paper_step1: 'Vui lòng đặt một tờ giấy màu trắng kích thước A4 hoặc Thư vào giữa khu vực làm việc.',
        put_paper_step2: 'Cố định bốn góc của tờ giấy để đảm bảo nó nằm phẳng.',
        put_paper_step3: 'Nhấp vào "Bắt Đầu Khắc".',
        put_paper_promark_1: 'Đặt tấm bìa đen từ hộp phụ kiện lên bề mặt làm việc.',
        put_paper_promark_2: 'Điều chỉnh tiêu cự đúng cách, sau đó nhấn "Bắt Đầu Khắc" để tiếp tục khắc.',
        put_paper_skip: 'Nếu bản vẽ hiệu chỉnh không tự động chuyển sang chụp ảnh, hãy nhấn "Bỏ qua" để chụp ảnh.',
        solve_pnp_title: 'Cân chỉnh điểm đánh dấu',
        solve_pnp_step1: 'Vui lòng căn chỉnh các điểm khắc theo số và vị trí mong đợi của từng điểm đánh dấu màu đỏ.',
        solve_pnp_step2: 'Bạn có thể nhấn "Chụp lại" để điều chỉnh lại hoặc điều chỉnh vị trí của các đánh dấu theo cách thủ công.',
        align_olt: 'Vị trí: Căn chỉnh với điểm khắc ở phía trên cùng bên trái bên ngoài.',
        align_ort: 'Vị trí: Căn chỉnh với điểm khắc ở phía trên cùng bên phải bên ngoài.',
        align_olb: 'Vị trí: Căn chỉnh với điểm khắc ở phía dưới cùng bên trái bên ngoài.',
        align_orb: 'Vị trí: Căn chỉnh với điểm khắc ở phía dưới cùng bên phải bên ngoài.',
        align_ilt: 'Vị trí: Căn chỉnh với điểm khắc ở phía trên cùng bên trái bên trong.',
        align_irt: 'Vị trí: Căn chỉnh với điểm khắc ở phía trên cùng bên phải bên trong.',
        align_ilb: 'Vị trí: Căn chỉnh với điểm khắc ở phía dưới cùng bên trái bên trong.',
        align_irb: 'Vị trí: Căn chỉnh với điểm khắc ở phía dưới cùng bên phải bên trong.',
        elevate_and_cut: 'Nâng và Cắt',
        elevate_and_cut_step_1: 'Đặt một miếng gỗ màu sáng kích thước A4 ở giữa không gian làm việc và nâng lên đến 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Sử dụng Ador Prism Lift với chiều dài tối đa là 14 mm cùng với một miếng gỗ có độ dày ít nhất 6 mm để nâng cao gỗ lên đến độ cao 20 mm.',
        with_prism_lift: 'Với Ador Prism Lift',
        without_prism_lift: 'Không có Ador Prism Lift',
        camera_parameter_saved_successfully: 'Thông số máy ảnh đã được lưu thành công.',
        failed_to_save_camera_parameter: 'Lưu thông số máy ảnh không thành công.',
        failed_to_solve_pnp: 'Không thể giải quyết vị trí của camera.',
        calibrating: 'Đang hiệu chỉnh...',
        moving_laser_head: 'Đang di chuyển đầu laser...',
        failed_to_move_laser_head: 'Không thể di chuyển đầu laser.',
        put_chessboard: 'Đặt Bàn Cờ',
        put_chessboard_bb2_desc_1: 'Vui lòng tải xuống tệp bàn cờ sau đây và in nó trên giấy A4 (bàn cờ in ra phải có các ô kích thước 1x1 cm).',
        put_chessboard_bb2_desc_2: 'Gắn giấy bàn cờ đã in lên một tấm bảng không bị biến dạng, chẳng hạn như acrylic hoặc kính, đảm bảo bàn cờ phẳng, không có nếp gấp hoặc mép bị nhô lên.',
        put_chessboard_bb2_desc_3: 'Đặt bảng với bàn cờ nằm phẳng ở trung tâm khu vực làm việc.',
        put_chessboard_1: 'Vui lòng đi tới bảng điều khiển máy và nhấn AF để thực hiện lấy nét tự động.',
        put_chessboard_2: 'Vui lòng di chuyển giấy bàn cờ hoặc đầu laser cho đến khi toàn bộ bàn cờ được xác nhận nằm trong khung đỏ trong cửa sổ xem trước trực tiếp.',
        put_chessboard_3: 'Nhấp chuột phải để tải xuống hình ảnh bàn cờ ở vị trí hiện tại và kiểm tra độ rõ nét.',
        put_chessboard_promark_desc_1: 'Vui lòng sử dụng giấy bàn cờ được cung cấp trong hộp phụ kiện hoặc in mẫu bàn cờ sau trên giấy A4 để hiệu chỉnh máy ảnh.',
        put_chessboard_promark_desc_2: 'Vui lòng đặt bàn cờ phẳng và ở trung tâm trên nền làm việc.',
        put_chessboard_promark_1: 'Điều chỉnh khoảng cách tiêu cự đến điểm lấy nét phù hợp cho ống kính trường.',
        put_chessboard_promark_2: 'Hãy đảm bảo giấy bàn cờ hiển thị rõ ràng mà không bị chói. Nhấn "Tiếp theo" để chụp ảnh.',
        download_chessboard_file: 'Tải xuống tệp bàn cờ',
        failed_to_calibrate_chessboard: 'Không thể hiệu chỉnh với hình ảnh bàn cờ.',
        calibrate_chessboard_success_msg: 'Đã chụp thành công ảnh bàn cờ.<br/>Điểm cho ảnh này là %s (%.2f).',
        res_excellent: 'Xuất sắc',
        res_average: 'Trung bình',
        res_poor: 'Kém',
        perform_autofocus_bb2: 'Vui lòng đi tới bảng điều khiển máy và nhấn AF để thực hiện lấy nét tự động.',
        promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    },
    input_machine_password: {
        require_password: '"%s" yêu cầu mật khẩu',
        connect: 'KẾT NỐI',
        password: 'Mật khẩu',
    },
    tutorial: {
        skip: 'Bỏ qua',
        welcome: 'CHÀO MỪNG',
        suggest_calibrate_camera_first: 'Chúng tôi khuyên người dùng nên hiệu chỉnh máy ảnh ban đầu và làm nét lại trước mỗi lần xem trước để có kết quả tốt nhất.<br/>Bạn có muốn xác nhận để thực hiện hiệu chỉnh ngay bây giờ không?<br/>(Bạn có thể bỏ qua nó bây giờ và làm sau bằng cách nhấp vào "Menu" > "Máy" > [Máy của Bạn] > "Hiệu chỉnh Máy Ảnh".)',
        camera_calibration_failed: 'Hiệu chỉnh camera thất bại',
        ask_retry_calibration: 'Bạn có muốn hiệu chuẩn lại máy ảnh không?',
        needNewUserTutorial: 'Bạn có muốn bắt đầu một hướng dẫn không?<br/>(Bạn có thể bỏ qua bây giờ và bắt đầu sau bằng cách nhấp vào "Trợ giúp" > "Hiện Hướng dẫn Bắt đầu".)',
        needNewInterfaceTutorial: 'Bạn có muốn bắt đầu một hướng dẫn cho giao diện mới của Beam Studio không?<br/>(Bạn có thể bỏ qua bây giờ và bắt đầu sau bằng cách nhấp vào "Trợ giúp" > "Hiện Giới Thiệu Giao Diện".)',
        next: 'TIẾP',
        look_for_machine: 'Đang tìm kiếm máy cho hướng dẫn...',
        unable_to_find_machine: 'Không tìm thấy máy cho Hướng dẫn. Bạn có muốn đến trang cài đặt kết nối, thử lại hoặc bỏ qua hướng dẫn?',
        skip_tutorial: 'Bạn đã bỏ qua hướng dẫn. Bạn luôn có thể bắt đầu hướng dẫn bằng cách nhấp vào "Trợ giúp" > "Hiển thị hướng dẫn bắt đầu"',
        set_connection: 'Thiết lập kết nối',
        retry: 'Thử lại',
        newUser: {
            draw_a_circle: 'Vẽ một vòng tròn',
            drag_to_draw: 'Kéo để vẽ',
            infill: 'Bật độ rỗng',
            switch_to_object_panel: 'Chuyển đổi sang Bảng Đối tượng',
            switch_to_layer_panel: 'Chuyển sang Bảng lớp',
            set_preset_wood_engraving: 'Đặt cài đặt sẵn: Gỗ - Khắc',
            set_preset_wood_cut: 'Đặt cài đặt sẵn: Gỗ - Cắt',
            add_new_layer: 'Thêm lớp mới',
            draw_a_rect: 'Vẽ một hình chữ nhật',
            switch_to_preview_mode: 'Chuyển sang Chế độ xem trước',
            preview_the_platform: 'Xem trước nền tảng',
            end_preview_mode: 'Kết thúc Chế độ xem trước',
            put_wood: '1. Đặt mẫu gỗ',
            adjust_focus: '2. Điều chỉnh tiêu điểm',
            close_cover: '3. Đóng nắp',
            send_the_file: 'Gửi tệp',
            end_alert: 'Bạn có chắc chắn muốn kết thúc hướng dẫn?',
            please_select_wood_engraving: 'Vui lòng chọn cài đặt sẵn "Gỗ - Khắc".',
            please_select_wood_cutting: 'Vui lòng chọn cài đặt sẵn "Gỗ - Cắt".',
        },
        newInterface: {
            camera_preview: 'Xem trước Camera',
            select_image_text: 'Chọn / Hình ảnh / Văn bản',
            basic_shapes: 'Hình cơ bản',
            pen_tool: 'Công cụ bút',
            add_new_layer: 'Thêm lớp mới',
            rename_by_double_click: 'Đổi tên bằng cách nhấp đúp',
            drag_to_sort: 'Kéo để sắp xếp',
            layer_controls: 'Nhấp phải để chọn Điều khiển lớp:\nNhân bản / Gộp / Khóa / Xóa các Lớp',
            switch_between_layer_panel_and_object_panel: 'Chuyển đổi giữa Bảng lớp và Bảng đối tượng',
            align_controls: 'Căn chỉnh các điều khiển',
            group_controls: 'Nhóm các điều khiển',
            shape_operation: 'Thao tác hình dạng',
            flip: 'Lật',
            object_actions: 'Hành động đối tượng',
            end_alert: 'Bạn có chắc chắn muốn kết thúc hướng dẫn giao diện mới?',
            select_machine: 'Chọn máy',
            start_work: 'Bắt đầu công việc',
        },
        gesture: {
            pan: 'Cuộn màn hình bằng hai ngón tay.',
            zoom: 'Phóng to / thu nhỏ màn hình bằng hai ngón tay.',
            click: 'Chạm để chọn đối tượng.',
            drag: 'Kéo để chọn nhiều đối tượng.',
            hold: 'Nhấn và giữ để mở menu ngữ cảnh.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Đó là tất cả cho hướng dẫn. Bây giờ là lúc để tạo!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: 'Laser diode 10W',
        laser_20w_diode: 'Laser diode 20W',
        laser_2w_infrared: 'Laser hồng ngoại 2W',
        printing: 'In ấn',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Bạn có muốn chuyển đổi mô-đun In ấn thành mô-đun Laser?',
            convertFromPrintingModuleMsg: 'Vui lòng lưu ý rằng nếu bạn hoàn tất thao tác này, các cài đặt màu của lớp in ấn sẽ bị xóa và được đặt theo lớp hiện tại.',
            convertFromLaserModuleTitle: 'Bạn có muốn chuyển đổi Module Laser thành Module In ấn?',
            convertFromLaserModuleMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt của lớp laser sẽ bị xóa và được đặt theo lớp hiện tại.',
            importedDocumentContainsPrinting: 'Tài liệu chứa lớp in ấn, bạn có muốn chuyển sang Không gian Làm việc Ador?',
            printingLayersCoverted: 'Các lớp in ấn đã được chuyển đổi thành các lớp laser.',
            performPrintingCaliTitle: 'Thực hiện hiệu chuẩn Module In ấn',
            performPrintingCaliMsg: 'Nhấp “Xác nhận” để thực hiện hiệu chuẩn, hoặc truy cập hiệu chuẩn qua menu trên cùng.<br /> (Máy > [Tên máy của bạn] > Hiệu chuẩn Module In ấn)',
            performIRCaliTitle: 'Thực hiện hiệu chuẩn Module Hồng ngoại',
            performIRCaliMsg: 'Nhấp “Xác nhận” để thực hiện hiệu chuẩn, hoặc truy cập hiệu chuẩn qua menu trên cùng. <br />(Máy > [Tên máy của bạn] > Hiệu chuẩn Module Hồng ngoại)',
        },
        non_working_area: 'Khu Vực Không Làm Việc',
    },
    qr_code_generator: {
        title: 'Tạo mã QR',
        placeholder: 'Nhập liên kết hoặc văn bản',
        preview: 'Xem trước',
        error_tolerance: 'Sai số dung sai',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Đảo ngược màu nền',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Hộp cơ bản',
        coming_soon: 'Sắp ra mắt',
        workarea: 'Khu vực làm việc',
        max_dimension_tooltip: 'Kích thước tối đa là %s.',
        volume: 'Thể tích',
        outer: 'Bên ngoài',
        inner: 'Bên trong',
        width: 'Chiều rộng',
        height: 'Chiều cao',
        depth: 'Chiều sâu',
        cover: 'Nắp đậy',
        thickness: 'Độ dày',
        add_option: 'Thêm tùy chọn',
        joints: 'Khớp nối',
        finger: 'Ngón tay',
        finger_warning: 'Chiều dài cạnh trong của hộp cần ít nhất 6mm (0,24 inch) để tương thích với khớp nối ngón tay.',
        tSlot: 'Rãnh T',
        tSlot_warning: 'Chiều dài cạnh hộp cần ít nhất 30mm (1,18 inch) để tương thích với khớp nối T.',
        edge: 'Cạnh',
        tCount: 'Số lượng T',
        tCount_tooltip: 'Số lượng khớp T áp dụng cho cạnh ngắn; số lượng ở cạnh dài được tính dựa trên chiều dài của nó.',
        tDiameter: 'Đường kính T',
        tLength: 'Chiều dài T',
        continue_import: 'Tiếp tục nhập',
        customize: 'Tùy chỉnh',
        merge: 'Gộp',
        text_label: 'Nhãn',
        beam_radius: 'Bù đường kính tia laser',
        beam_radius_warning: 'Xóa bỏ bù độ rộng lưỡi cắt khi các cạnh hoặc khớp của hộp ngắn để đảm bảo lắp ráp hộp',
        import: 'Nhập',
        cancel: 'Hủy',
        reset: 'Đặt lại',
        zoom: 'Thu phóng',
        control_tooltip: 'Trái chuột để xoay\nCuộn để thu phóng\nPhải chuột để di chuyển',
        control_tooltip_touch: 'Kéo để xoay\nVuốt để thu phóng\nHai ngón tay để di chuyển',
    },
    my_cloud: {
        title: 'Đám mây của tôi',
        loading_file: 'Đang tải...',
        no_file_title: 'Lưu tệp lên Đám mây của tôi để bắt đầu.',
        no_file_subtitle: 'Vào Menu > "Tệp" > "Lưu lên đám mây"',
        file_limit: 'Tệp miễn phí',
        upgrade: 'Nâng cấp',
        sort: {
            most_recent: 'Gần đây nhất',
            oldest: 'Cũ nhất',
            a_to_z: 'Tên: A-Z',
            z_to_a: 'Tên: Z-A',
        },
        action: {
            open: 'Mở',
            rename: 'Đổi tên',
            duplicate: 'Nhân bản',
            download: 'Tải xuống',
            delete: 'Xóa',
            confirmFileDelete: 'Bạn có chắc chắn muốn xóa tệp tin này không? Hành động này không thể hoàn tác.',
        },
        save_file: {
            choose_action: 'Lưu tệp:',
            save: 'Lưu',
            save_new: 'Lưu thành tệp mới',
            input_file_name: 'Nhập tên tệp:',
            invalid_char: 'Ký tự không hợp lệ:',
            storage_limit_exceeded: 'Bộ nhớ đám mây của bạn đã đạt giới hạn. Vui lòng xóa các tệp không cần thiết trước khi lưu các tệp mới.',
        },
    },
    camera_data_backup: {
        title: 'Sao lưu dữ liệu máy ảnh',
        no_picture_found: 'Không tìm thấy hình ảnh trong máy.',
        folder_not_exists: 'Thư mục đã chọn không tồn tại.',
        incorrect_folder: 'Không thể tải lên dữ liệu hiệu chuẩn. Vui lòng kiểm tra xem thư mục bạn đã chọn có đúng không.',
        downloading_data: 'Đang tải dữ liệu...',
        estimated_time_left: 'Thời gian còn lại ước tính:',
        uploading_data: 'Đang tải lên dữ liệu...',
        download_success: 'Dữ liệu máy ảnh đã được tải xuống thành công.',
        upload_success: 'Dữ liệu máy ảnh đã được tải lên thành công.',
    },
    insecure_websocket: {
        extension_detected: 'Phát hiện Phần mở rộng Beam Studio Connect',
        extension_detected_description: "Chúng tôi đã phát hiện bạn đã cài đặt phần mở rộng Beam Studio Connect. Nhấn vào 'Xác nhận' để chuyển hướng đến HTTPS, hoặc nhấn vào 'Hủy' để tiếp tục sử dụng HTTP.",
        extension_not_deteced: 'Không thể phát hiện Phần mở rộng Beam Studio Connect',
        extension_not_deteced_description: "Để sử dụng HTTPS, vui lòng nhấp vào 'Xác nhận' để cài đặt tiện ích mở rộng Beam Studio Connect. Sau khi cài đặt tiện ích mở rộng, vui lòng làm mới trang để kích hoạt nó.<br/>Nếu không, hãy nhấp vào liên kết bên dưới để xem cách sử dụng HTTP trên Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Liên kết</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Đo khu vực lấy nét tự động',
        amount: 'Số lượng',
        gap: 'Khoảng cách',
        rows: 'Hàng',
        coloumns: 'Cột',
        row_gap: 'Khoảng cách hàng',
        column_gap: 'Khoảng cách cột',
        set_object_height: 'Đặt chiều cao đối tượng',
        set_object_height_desc: 'Đo độ dày tối đa của đối tượng.',
        reselect_area: 'Chọn lại Khu vực',
        start_autofocus: 'Bắt đầu Lấy nét tự động',
        starting_measurement: 'Bắt đầu đo...',
        preview_3d_curve: 'Xem trước Đường cong 3D',
        apply_arkwork: 'Áp dụng Tác phẩm nghệ thuật lên Đường cong 3D',
        apply_camera: 'Áp dụng Hình ảnh Camera lên Đường cong 3D',
        click_to_select_point: 'Nhấp để chọn hoặc bỏ chọn các điểm để đo lại.',
        remeasure: 'Đo lại',
        remeasuring_points: 'Đang đo lại các điểm...',
        take_reference: 'Lấy tham chiếu',
        take_reference_desc: 'Vui lòng di chuyển đầu laser đến điểm cao nhất của đối tượng, hạ đầu dò tiêu điểm xuống và nhấp vào "Xác nhận" để lấy nét.',
        sure_to_delete: 'Bạn có muốn xóa dữ liệu tiêu điểm của đường cong 3D không?',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    },
    pass_through: {
        title: 'Chế Độ Đi Qua',
        help_text: 'Cách thiết lập Chế Độ Đi Qua cho Ador?',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'Chiều Dài Đối Tượng',
        workarea_height: 'Khu Vực Làm Việc (Chiều Cao):',
        height_desc: 'Cài đặt chiều cao của mỗi phần khu vực làm việc.',
        ref_layer: 'Lớp Tham Chiếu',
        ref_layer_desc: 'Lưu ý rằng việc thực hiện lớp tham chiếu được đặt mặc định là 0. Nó sẽ không được thực hiện mà chỉ để tham chiếu căn chỉnh.',
        ref_layer_name: 'Tham Chiếu',
        guide_mark: 'Dấu Hướng Dẫn',
        guide_mark_length: 'Đường kính:',
        guide_mark_x: 'Tọa Độ X:',
        guide_mark_desc: 'Dấu hướng dẫn sẽ được khắc làm điểm tham chiếu để căn chỉnh tác phẩm nghệ thuật.',
        export: 'Xuất sang Khu Vực Làm Việc',
        exporting: 'Đang xuất',
    },
    auto_fit: {
        title: 'Tự Động Vừa Khít',
        step1: '1. Đặt đối tượng vào vị trí mong muốn trên một miếng vật liệu.',
        step2: '2. Nhấn "Xác nhận" để sao chép đối tượng lên tất cả các miếng vật liệu tương tự trong chế độ xem trước của máy ảnh.',
        preview_first: 'Vui lòng thực hiện xem trước máy ảnh trước.',
        failed_to_auto_fit: 'Tự động điều chỉnh thất bại, vui lòng kiểm tra:',
        error_tip1: '1. Tác phẩm nghệ thuật có được đặt đúng vị trí trên vật liệu không?',
        error_tip2: '2. Các đường viền của vật liệu có đủ rõ để nhận diện không?',
        learn_more: 'Tìm hiểu cách thức hoạt động của tự động điều chỉnh.',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'loại',
        object_diameter: 'Đường Kính Vật Thể',
        circumference: 'Chu Vi',
    },
    framing: {
        low_laser: 'Laser yếu',
        low_laser_desc: 'Đặt giá trị laser công suất thấp cho nhiệm vụ đóng khung.',
        framing: 'Đóng khung',
        hull: 'Vỏ',
        area_check: 'Kiểm tra khu vực',
        framing_desc: 'Xem trước hộp giới hạn của đối tượng.',
        hull_desc: 'Xem trước hình dạng bám sát thiết kế, giống như dây cao su bao quanh đối tượng.',
        areacheck_desc: 'Đảm bảo khu vực làm việc an toàn bằng cách xem trước hộp giới hạn của đối tượng và vùng gia tốc của đầu laser.',
        calculating_task: 'Đang tính toán nhiệm vụ...',
    },
    material_test_generator: {
        title: 'Trình tạo thử nghiệm vật liệu',
        table_settings: 'Cài đặt bảng',
        block_settings: 'Cài đặt khối',
        text_settings: 'Cài đặt văn bản',
        preview: 'Xem trước',
        export: 'Xuất',
        cut: 'Cắt',
        engrave: 'Khắc',
        columns: 'Cột',
        rows: 'Hàng',
        parameter: 'Tham số',
        min: 'Tối thiểu',
        max: 'Tối đa',
        count: 'Số lượng',
        size: 'Kích thước (CxR)',
        spacing: 'Khoảng cách',
    },
    web_cam: {
        no_permission: 'Beam Studio không có quyền truy cập vào camera. Vui lòng đảm bảo rằng quyền đã được cấp cho Beam Studio trong cài đặt trình duyệt hoặc hệ thống.',
        no_device: 'Không thể phát hiện thiết bị camera. Vui lòng kết nối lại camera và thử lại.',
    },
    promark_settings: {
        title: 'Cài đặt Promark',
        field: 'Trường',
        red_dot: 'Chấm Đỏ',
        galvo_configuration: 'Cấu hình Galvo',
        switchXY: 'Chuyển đổi X/Y',
        workarea_hint: 'Bạn có thể thay đổi khu vực làm việc trong "Cài đặt Tài liệu".',
        offsetX: 'Dịch chuyển X',
        offsetY: 'Dịch chuyển Y',
        angle: 'Góc',
        scaleX: 'Tỉ lệ X',
        scaleY: 'Tỉ lệ Y',
        scale: 'Tỉ lệ',
        bulge: 'Phồng',
        skew: 'Nghiêng',
        trapezoid: 'Hình thang',
        mark_parameters: 'Tham số đánh dấu',
        preview: 'Xem trước',
        mark: 'Đánh dấu',
        z_axis_adjustment: {
            title: 'Điều Chỉnh Trục Z',
            section1: 'Điều chỉnh độ cao trục Z để tinh chỉnh tiêu điểm.',
            tooltip1: 'Thử đánh dấu một hình vuông 1x1 cm để xác nhận nếu khoảng cách tiêu điểm hiện tại là phù hợp.',
        },
    },
    code_generator: {
        qr_code: 'Mã QR',
        barcode: 'Mã vạch',
    },
    barcode_generator: {
        bar_width: 'Chiều rộng thanh',
        bar_height: 'Chiều cao thanh',
        text_margin: 'Lề văn bản',
        invert_color: 'Đảo màu',
        font: 'Phông chữ',
        font_size: 'Kích thước phông chữ',
        hide_text: 'Ẩn văn bản',
        barcode: {
            invalid_value: 'Giá trị không hợp lệ cho định dạng đã chọn.',
        },
    },
    social_media: {
        instagram: 'Nhận những cảm hứng, ưu đãi và quà tặng mới nhất!',
        facebook: 'Trò chuyện với FLUXers, đặt câu hỏi và học mẹo!',
        youtube: 'Xem hướng dẫn Beam Studio và ý tưởng thủ công.',
    },
};
export default lang;
