import { createContext } from 'react';
import { getDefaultConfig } from 'helpers/layer/layer-config-helper';
export const getDefaultState = () => {
    const defaultConfig = getDefaultConfig();
    const keys = Object.keys(defaultConfig);
    const initState = {};
    keys.forEach((key) => {
        initState[key] = { value: defaultConfig[key] };
    });
    return initState;
};
export const reducer = (state, action) => {
    if (action.type === 'update')
        return Object.assign(Object.assign({}, state), action.payload);
    if (action.type === 'change') {
        const { payload } = action;
        const newState = Object.assign({}, state);
        Object.keys(payload).forEach((key) => {
            if (key !== 'selectedLayer')
                newState[key] = { value: payload[key] };
            else
                newState[key] = payload[key];
        });
        return newState;
    }
    if (action.type === 'rename') {
        const { payload } = action;
        const newState = Object.assign({}, state);
        newState.configName = { value: payload };
        return newState;
    }
    return state;
};
export default createContext({});
