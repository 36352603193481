var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import shortcuts from 'helpers/shortcuts';
import TopBarController from 'app/views/beambox/TopBar/contexts/TopBarController';
import TopBarIcons from 'app/icons/top-bar/TopBarIcons';
import useI18n from 'helpers/useI18n';
import { CanvasContext, CanvasMode } from 'app/contexts/CanvasContext';
import { promarkModels } from 'app/actions/beambox/constant';
import { showFramingModal } from 'app/components/dialogs/FramingModal';
import styles from './FrameButton.module.scss';
const FrameButton = () => {
    const lang = useI18n();
    const { mode } = useContext(CanvasContext);
    useEffect(() => {
        const shortcutHandler = () => __awaiter(void 0, void 0, void 0, function* () {
            if (mode !== CanvasMode.Draw)
                return;
            const device = TopBarController.getSelectedDevice();
            showFramingModal(promarkModels.has(device === null || device === void 0 ? void 0 : device.model));
        });
        const unregister = shortcuts.on(['F1'], shortcutHandler);
        return () => unregister === null || unregister === void 0 ? void 0 : unregister();
    }, [mode]);
    return (React.createElement("div", { className: classNames(styles.button, { [styles.disabled]: mode !== CanvasMode.Draw }), onClick: () => showFramingModal(), title: lang.topbar.frame_task },
        React.createElement(TopBarIcons.Frame, null)));
};
export default FrameButton;
