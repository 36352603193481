var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useMemo, useRef, useState } from 'react';
import alertCaller from 'app/actions/alert-caller';
import checkDeviceStatus from 'helpers/check-device-status';
import deviceMaster from 'helpers/device-master';
import dialog from 'implementations/dialog';
import progressCaller from 'app/actions/progress-caller';
import promarkDataStore from 'helpers/device/promark/promark-data-store';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { getWorkarea } from 'app/constants/workarea-constants';
import { loadCameraCalibrationTask } from 'helpers/device/promark/calibration';
import CheckpointData from './common/CheckpointData';
import Chessboard from './Promark/Chessboard';
import Instruction from './common/Instruction';
import SolvePnP from './common/SolvePnP';
import Title from './common/Title';
import { promarkPnPPoints } from './common/solvePnPConstants';
import styles from './Calibration.module.scss';
var Steps;
(function (Steps) {
    Steps[Steps["CHECKPOINT_DATA"] = 0] = "CHECKPOINT_DATA";
    Steps[Steps["PRE_CHESSBOARD"] = 1] = "PRE_CHESSBOARD";
    Steps[Steps["CHESSBOARD"] = 2] = "CHESSBOARD";
    Steps[Steps["PUT_PAPER"] = 3] = "PUT_PAPER";
    Steps[Steps["SOLVE_PNP_INSTRUCTION"] = 4] = "SOLVE_PNP_INSTRUCTION";
    Steps[Steps["SOLVE_PNP"] = 5] = "SOLVE_PNP";
})(Steps || (Steps = {}));
const PROGRESS_ID = 'promark-calibration';
const PromarkCalibration = ({ device: { serial, model }, onClose }) => {
    const lang = useI18n();
    const tCali = lang.calibration;
    const workareaWidth = useMemo(() => getWorkarea(model).width, [model]);
    const calibratingParam = useRef({});
    const useOldData = useRef(false);
    const [step, setStep] = useState(Steps.CHECKPOINT_DATA);
    const updateParam = useCallback((param) => {
        calibratingParam.current = Object.assign(Object.assign({}, calibratingParam.current), param);
    }, []);
    if (step === Steps.CHECKPOINT_DATA) {
        return (React.createElement(CheckpointData, { askUser: true, allowCheckPoint: false, updateParam: updateParam, getData: () => promarkDataStore.get(serial, 'cameraParameters'), onClose: onClose, onNext: (res) => {
                if (res) {
                    useOldData.current = true;
                    setStep(Steps.PUT_PAPER);
                }
                else
                    setStep(Steps.PRE_CHESSBOARD);
            } }));
    }
    if (step === Steps.PRE_CHESSBOARD) {
        const handleDownloadChessboard = () => {
            dialog.writeFileDialog(() => __awaiter(void 0, void 0, void 0, function* () {
                const resp = yield fetch('assets/promark-chessboard.pdf');
                const blob = yield resp.blob();
                return blob;
            }), tCali.download_chessboard_file, 'Chessboard', [
                {
                    name: window.os === 'MacOS' ? 'PDF (*.pdf)' : 'PDF',
                    extensions: ['pdf'],
                },
            ]);
        };
        return (React.createElement(Instruction, { title: React.createElement(Title, { title: tCali.put_chessboard, link: tCali.promark_help_link }), steps: [tCali.put_chessboard_promark_desc_1, tCali.put_chessboard_promark_desc_2], buttons: [
                {
                    label: tCali.next,
                    onClick: () => setStep(Steps.CHESSBOARD),
                    type: 'primary',
                },
            ], onClose: onClose, animationSrcs: [
                { src: 'video/promark-calibration/1-chessboard.webm', type: 'video/webm' },
                { src: 'video/promark-calibration/1-chessboard.mp4', type: 'video/mp4' },
            ] },
            React.createElement("div", { className: styles.link, onClick: handleDownloadChessboard }, tCali.download_chessboard_file)));
    }
    if (step === Steps.CHESSBOARD) {
        return (React.createElement(Chessboard, { chessboard: [18, 18], updateParam: updateParam, onNext: () => setStep(Steps.PUT_PAPER), onClose: onClose }));
    }
    if (step === Steps.PUT_PAPER) {
        const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
            const deviceStatus = yield checkDeviceStatus(deviceMaster.currentDevice.info);
            if (!deviceStatus)
                return;
            try {
                progressCaller.openNonstopProgress({
                    id: PROGRESS_ID,
                    message: tCali.drawing_calibration_image,
                });
                yield loadCameraCalibrationTask(model, workareaWidth);
                yield deviceMaster.doPromarkCalibration();
                progressCaller.update(PROGRESS_ID, { message: tCali.preparing_to_take_picture });
                setStep(Steps.SOLVE_PNP_INSTRUCTION);
            }
            catch (err) {
                console.error(err);
            }
            finally {
                progressCaller.popById(PROGRESS_ID);
            }
        });
        return (React.createElement(Instruction, { onClose: () => onClose(false), title: React.createElement(Title, { title: tCali.put_paper, link: tCali.promark_help_link }), steps: [tCali.put_paper_promark_1, tCali.put_paper_promark_2], buttons: [
                {
                    label: tCali.back,
                    onClick: () => setStep(useOldData.current ? Steps.CHECKPOINT_DATA : Steps.CHESSBOARD),
                },
                { label: tCali.start_engrave, onClick: () => handleNext(), type: 'primary' },
            ], animationSrcs: [
                { src: 'video/promark-calibration/2-cut.webm', type: 'video/webm' },
                { src: 'video/promark-calibration/2-cut.mp4', type: 'video/mp4' },
            ] }));
    }
    if (step === Steps.SOLVE_PNP_INSTRUCTION) {
        return (React.createElement(Instruction, { onClose: () => onClose(false), animationSrcs: [
                { src: 'video/promark-calibration/3-align.webm', type: 'video/webm' },
                { src: 'video/promark-calibration/3-align.mp4', type: 'video/mp4' },
            ], title: tCali.solve_pnp_title, steps: [tCali.solve_pnp_step1, tCali.solve_pnp_step2], buttons: [
                { label: tCali.back, onClick: () => setStep(Steps.PUT_PAPER) },
                { label: tCali.next, onClick: () => setStep(Steps.SOLVE_PNP), type: 'primary' },
            ] }));
    }
    if (step === Steps.SOLVE_PNP) {
        return (React.createElement(SolvePnP, { params: calibratingParam.current, dh: 0, refPoints: promarkPnPPoints[workareaWidth], imgSource: "usb", titleLink: tCali.promark_help_link, onClose: onClose, onBack: () => setStep(Steps.SOLVE_PNP_INSTRUCTION), onNext: (rvec, tvec) => __awaiter(void 0, void 0, void 0, function* () {
                progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: lang.device.processing });
                updateParam({ rvec, tvec });
                console.log('calibratingParam.current', calibratingParam.current);
                progressCaller.popById(PROGRESS_ID);
                const param = {
                    k: calibratingParam.current.k,
                    d: calibratingParam.current.d,
                    rvec,
                    tvec,
                    v: 3,
                };
                promarkDataStore.set(serial, 'cameraParameters', param);
                alertCaller.popUp({ message: tCali.camera_parameter_saved_successfully });
                onClose(true);
            }) }));
    }
    onClose();
    return React.createElement(React.Fragment, null);
};
export const showPromarkCalibration = (device) => {
    const id = 'promark-calibration';
    const onClose = () => popDialogById(id);
    if (isIdExist(id))
        onClose();
    return new Promise((resolve) => {
        addDialogComponent(id, React.createElement(PromarkCalibration, { device: device, onClose: (completed = false) => {
                onClose();
                resolve(completed);
            } }));
    });
};
export default PromarkCalibration;
