var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useMemo, useState } from 'react';
import { Flex } from 'antd';
import classNames from 'classnames';
import useI18n from 'helpers/useI18n';
import deviceMaster from 'helpers/device-master';
import { defaultField, defaultGalvoParameters, defaultRedLight, } from 'app/constants/promark-constants';
import storage from 'implementations/storage';
import FieldBlock from 'app/components/dialogs/promark/FieldBlock';
import RedDotBlock from 'app/components/dialogs/promark/RedDotBlock';
import LensBlock from 'app/components/dialogs/promark/LensBlock';
import dialogCaller from 'app/actions/dialog-caller';
import promarkDataStore from 'helpers/device/promark/promark-data-store';
import { getWorkarea } from 'app/constants/workarea-constants';
import { getSerial } from 'helpers/device/promark/promark-info';
import styles from './index.module.scss';
export default function PromarkSettings() {
    const { initialize: t } = useI18n();
    const [field, setField] = useState(defaultField);
    const [redDot, setRedDot] = useState(defaultRedLight);
    const [galvoParameters, setGalvoParameters] = useState(defaultGalvoParameters);
    const isInch = useMemo(() => storage.get('default-units') === 'inches', []);
    const serial = useMemo(getSerial, []);
    const { width } = useMemo(() => getWorkarea('fpm1'), []);
    useEffect(() => {
        const { field = defaultField, redDot = defaultRedLight, galvoParameters = defaultGalvoParameters, } = promarkDataStore.get(serial);
        setField(field);
        setRedDot(redDot);
        setGalvoParameters(galvoParameters);
    }, [serial]);
    const handleUpdateParameter = () => __awaiter(this, void 0, void 0, function* () {
        yield deviceMaster.setField(width, field);
        yield deviceMaster.setGalvoParameters(galvoParameters);
    });
    const handleNext = () => __awaiter(this, void 0, void 0, function* () {
        promarkDataStore.update(serial, { field, redDot, galvoParameters });
        try {
            yield handleUpdateParameter();
        }
        catch (error) {
            console.error('Failed to apply promark settings state', error);
        }
        dialogCaller.showLoadingWindow();
        window.location.hash = '#studio/beambox';
        window.location.reload();
    });
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles['top-bar'] }),
        React.createElement(Flex, { justify: "space-between", style: { width: 560 } },
            React.createElement("div", null,
                React.createElement("div", { className: styles.title }, t.promark.settings),
                React.createElement(Flex, { vertical: true, gap: 12 },
                    React.createElement("div", { className: styles.subtitle }, t.promark.qc_instructions),
                    React.createElement("div", { className: styles.text }, t.promark.configuration_confirmation),
                    React.createElement("div", { className: styles.text }, t.promark.or_complete_later)),
                React.createElement("div", { className: styles['table-container'] },
                    width && (React.createElement(FieldBlock, { width: width, isInch: isInch, field: field, setField: setField })),
                    React.createElement(RedDotBlock, { isInch: isInch, redDot: redDot, setRedDot: setRedDot }),
                    React.createElement(LensBlock, { data: galvoParameters, setData: setGalvoParameters })))),
        React.createElement("div", { className: styles.btns },
            React.createElement("div", { className: styles.btn, onClick: () => window.history.back() }, t.back),
            React.createElement("div", { className: classNames(styles.btn, styles.primary), onClick: handleNext }, t.connect_machine_ip.finish_setting))));
}
