import React from 'react';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import { baseConfig } from 'helpers/layer/layer-config-helper';
import styles from './PresetsManagementPanel.module.scss';
const LaserInputs = ({ preset, maxSpeed, minSpeed, isInch = false, lengthUnit = 'mm', handleChange, }) => {
    var _a, _b, _c, _d, _e;
    const tLaserPanel = useI18n().beambox.right_panel.laser_panel;
    const t = tLaserPanel.preset_management;
    return (React.createElement("div", { className: styles.inputs },
        React.createElement("div", null,
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.strength),
                React.createElement(UnitInput, { "data-testid": "power", className: styles.input, disabled: preset.isDefault, value: (_a = preset.power) !== null && _a !== void 0 ? _a : baseConfig.power, max: 100, min: 0, precision: 0, addonAfter: "%", onChange: (value) => handleChange('power', value) })),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.speed),
                React.createElement(UnitInput, { "data-testid": "speed", className: styles.input, disabled: preset.isDefault, value: (_b = preset.speed) !== null && _b !== void 0 ? _b : baseConfig.speed, max: maxSpeed, min: minSpeed, precision: isInch ? 2 : 1, addonAfter: `${lengthUnit}/s`, isInch: isInch, onChange: (value) => handleChange('speed', value) })),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.repeat),
                React.createElement(UnitInput, { "data-testid": "repeat", className: styles.input, disabled: preset.isDefault, value: (_c = preset.repeat) !== null && _c !== void 0 ? _c : baseConfig.repeat, max: 100, min: 0, precision: 0, addonAfter: tLaserPanel.times, onChange: (value) => handleChange('repeat', value) }))),
        React.createElement("div", null,
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, t.lower_focus_by),
                React.createElement(UnitInput, { "data-testid": "focus", className: styles.input, disabled: preset.isDefault, value: Math.max((_d = preset.focus) !== null && _d !== void 0 ? _d : baseConfig.focus, 0), max: 10, min: 0, precision: isInch ? 2 : 1, addonAfter: lengthUnit, isInch: isInch, onChange: (value) => handleChange('focus', value) })),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.z_step),
                React.createElement(UnitInput, { "data-testid": "focusStep", className: styles.input, disabled: preset.isDefault || preset.repeat <= 1, value: Math.max((_e = preset.focusStep) !== null && _e !== void 0 ? _e : baseConfig.focusStep, 0), max: 10, min: 0, precision: isInch ? 2 : 1, addonAfter: lengthUnit, isInch: isInch, onChange: (value) => {
                        /**
                         * update both zStep and focusStep, to make the user experience consistent
                         * zStep is for beamo only
                         * focusStep is for the other models
                         */
                        handleChange('zStep', value);
                        handleChange('focusStep', value);
                    } })))));
};
export default LaserInputs;
