var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useMemo, useRef } from 'react';
import { Flex, Radio } from 'antd';
import useI18n from 'helpers/useI18n';
import DraggableModal from 'app/widgets/DraggableModal';
import styles from './index.module.scss';
import QRCodeGenerator from './QRCodeGenerator';
import BarcodeGenerator from './BarcodeGenerator';
import { importBarcodeSvgElement, importQrCodeSvgElement } from './svgOperation';
export default function CodeGenerator({ onClose }) {
    const { alert: tAlert, topbar: { menu: { tools: tTools }, }, code_generator: tCodeGenerator, } = useI18n();
    const [tabKey, setTabKey] = useState('qrcode');
    const [isInvert, setIsInvert] = useState(false);
    const [text, setText] = useState('');
    const generatorRef = useRef(null);
    const handleOk = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const svgElement = (_a = generatorRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('svg');
        if (!svgElement) {
            return;
        }
        if (tabKey === 'qrcode') {
            yield importQrCodeSvgElement(svgElement, isInvert);
        }
        else {
            yield importBarcodeSvgElement(svgElement, isInvert);
        }
        svgElement.remove();
        onClose();
    });
    const options = useMemo(() => [
        { label: tCodeGenerator.qr_code, value: 'qrcode' },
        { label: tCodeGenerator.barcode, value: 'barcode' },
    ], [tCodeGenerator]);
    const renderContent = () => tabKey === 'qrcode' ? (React.createElement(QRCodeGenerator, { ref: generatorRef, isInvert: isInvert, setIsInvert: setIsInvert, text: text, setText: setText })) : (React.createElement(BarcodeGenerator, { ref: generatorRef, isInvert: isInvert, setIsInvert: setIsInvert, text: text, setText: setText }));
    return (React.createElement(DraggableModal, { open: true, centered: true, title: React.createElement(Flex, { gap: 12, className: styles['title-flex'] },
            React.createElement("div", null, tTools.code_generator),
            React.createElement(Radio.Group, { className: styles['fw-n'], size: "small", optionType: "button", options: options, defaultValue: tabKey, onChange: (e) => {
                    setTabKey(e.target.value);
                    setIsInvert(false);
                } })), onCancel: onClose, onOk: handleOk, width: "520", cancelText: tAlert.cancel, okText: tAlert.confirm, okButtonProps: { disabled: !text }, className: styles.modal }, renderContent()));
}
