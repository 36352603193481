/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { Checkbox, Modal } from 'antd';
import alertConfig from 'helpers/api/alert-config';
import Input from 'app/widgets/Input';
import InputKeyWrapper from 'app/widgets/InputKeyWrapper';
import useI18n from 'helpers/useI18n';
import styles from './Prompt.module.scss';
function Prompt({ caption, message, placeholder, defaultValue = '', confirmValue, alertConfigKey, onYes, onCancel = () => { }, onClose, }) {
    const lang = useI18n();
    const langAlert = lang.alert;
    const inputRef = React.useRef(null);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const messageContent = typeof message === 'string' ? message.split('\n').map((t) => React.createElement("p", { key: t }, t)) : message;
    const handleOk = () => {
        var _a;
        const inputElem = inputRef.current;
        const value = (_a = inputElem === null || inputElem === void 0 ? void 0 : inputElem.input) === null || _a === void 0 ? void 0 : _a.value;
        onYes(value);
        if (!confirmValue || (value === null || value === void 0 ? void 0 : value.toLowerCase()) === (confirmValue === null || confirmValue === void 0 ? void 0 : confirmValue.toLowerCase())) {
            if (alertConfigKey && checkboxChecked)
                alertConfig.write(alertConfigKey, true);
            onClose();
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.nativeEvent.isComposing)
            handleOk();
    };
    return (React.createElement(Modal, { open: true, title: caption, centered: true, onOk: handleOk, onCancel: () => {
            var _a;
            const inputElem = inputRef.current;
            onCancel === null || onCancel === void 0 ? void 0 : onCancel((_a = inputElem === null || inputElem === void 0 ? void 0 : inputElem.input) === null || _a === void 0 ? void 0 : _a.value);
            onClose();
        }, okText: langAlert.ok2, cancelText: langAlert.cancel },
        messageContent,
        React.createElement(InputKeyWrapper, { inputRef: inputRef },
            React.createElement(Input, { autoFocus: true, ref: inputRef, className: "text-input", type: "text", onKeyDown: handleKeyDown, placeholder: placeholder, defaultValue: defaultValue })),
        alertConfigKey && (React.createElement(Checkbox, { className: styles.checkbox, onClick: () => setCheckboxChecked(!checkboxChecked) }, lang.beambox.popup.dont_show_again))));
}
export default Prompt;
