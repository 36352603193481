/* eslint-disable @typescript-eslint/no-shadow */
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, ConfigProvider, Flex, Form, Input, InputNumber, Radio, Space, } from 'antd';
import fontFuncs from 'app/actions/beambox/font-funcs';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, BoldOutlined, ItalicOutlined, } from '@ant-design/icons';
import useI18n from 'helpers/useI18n';
import Select from 'app/widgets/AntdSelect';
import fontHelper from 'helpers/fonts/fontHelper';
import FluxIcons from 'app/icons/flux/FluxIcons';
import classNames from 'classnames';
import { Barcode, defaultOptions, formats } from './Barcode';
import styles from './BarcodeGenerator.module.scss';
// copied from src/web/app/views/beambox/Right-Panels/Options-Blocks/TextOptions.tsx
const renderOption = (option) => {
    const src = fontHelper.getWebFontPreviewUrl(option.value);
    if (src) {
        return (React.createElement("div", { className: styles['family-option'] },
            React.createElement("div", { className: styles['img-container'] },
                React.createElement("img", { src: src, alt: option.label, draggable: "false" })),
            src.includes('monotype') && React.createElement(FluxIcons.FluxPlus, null)));
    }
    return React.createElement("div", { style: { fontFamily: `'${option.value}'`, maxHeight: 24 } }, option.label);
};
// end of copied code
export default forwardRef(({ isInvert, setIsInvert, text, setText }, ref) => {
    const { barcode_generator: t } = useI18n();
    const [options, setOptions] = useState(defaultOptions);
    const [validFontStyles, setValidFontStyles] = useState([]);
    const formatOptions = formats.map((value) => ({ label: value, value }));
    const fontFamilies = fontFuncs.requestAvailableFontFamilies();
    const fontOptions = useMemo(() => fontFamilies.map((value) => {
        const fontName = fontFuncs.fontNameMap.get(value);
        const label = renderOption({
            value,
            label: typeof fontName === 'string' ? fontName : value,
        });
        return { value, label };
    }), [fontFamilies]);
    const [isBold, isItalic] = useMemo(() => [options.fontOptions.includes('bold'), options.fontOptions.includes('italic')], [options.fontOptions]);
    useEffect(() => {
        const fontStyles = fontFuncs
            .requestFontsOfTheFontFamily(options.font)
            .map(({ style }) => style);
        setValidFontStyles(fontStyles);
    }, [options.font]);
    return (React.createElement("div", { ref: ref },
        React.createElement(Barcode, { className: styles['barcode-container'], value: text, options: options, renderer: "svg" }),
        React.createElement(ConfigProvider, { theme: { components: { Form: { itemMarginBottom: 12 } } } },
            React.createElement(Form, null,
                React.createElement(Space.Compact, { className: classNames(styles['w-100'], styles['mb-20']) },
                    React.createElement(Input, { value: text, onKeyDown: (e) => e.stopPropagation(), onChange: (e) => setText(e.target.value) }),
                    React.createElement(ConfigProvider, { theme: { token: { colorBgContainer: '#FAFAFA' } } },
                        React.createElement(Select, { value: [options.format], options: formatOptions, allowClear: false, onKeyDown: (e) => e.stopPropagation(), onChange: (format) => setOptions(Object.assign(Object.assign({}, options), { format })), showSearch: true, popupMatchSelectWidth: false, placement: "bottomRight" }))),
                React.createElement(Flex, { justify: "center", gap: 32 },
                    React.createElement(Flex, { vertical: true },
                        React.createElement(Form.Item, { label: t.bar_width, className: styles['flex-child'] },
                            React.createElement(InputNumber, { className: styles['w-100'], max: 4, min: 1, value: options.width, onKeyDown: (e) => e.stopPropagation(), onChange: (width) => setOptions(Object.assign(Object.assign({}, options), { width })) })),
                        React.createElement(Form.Item, { label: t.bar_height, className: styles['flex-child'] },
                            React.createElement(InputNumber, { className: styles['w-100'], max: 300, min: 1, value: options.height, onKeyDown: (e) => e.stopPropagation(), onChange: (height) => setOptions(Object.assign(Object.assign({}, options), { height })) })),
                        React.createElement(Form.Item, { label: t.text_margin, className: styles['flex-child'] },
                            React.createElement(InputNumber, { className: styles['w-100'], max: 100, value: options.textMargin, onKeyDown: (e) => e.stopPropagation(), onChange: (textMargin) => setOptions(Object.assign(Object.assign({}, options), { textMargin })) })),
                        React.createElement(Form.Item, { className: styles['flex-child'] },
                            React.createElement(Checkbox, { checked: isInvert, onChange: () => {
                                    const [black, white] = ['#000000', '#ffffff'];
                                    setIsInvert(!isInvert);
                                    setOptions(Object.assign(Object.assign({}, options), { background: isInvert ? white : black, lineColor: isInvert ? black : white }));
                                } }, t.invert_color))),
                    React.createElement(Flex, { vertical: true },
                        React.createElement(Form.Item, { label: t.font, className: styles['flex-child'] },
                            React.createElement(Select, { value: [options.font], options: fontOptions, allowClear: false, showSearch: true, filterOption: (input, option) => {
                                    if (option === null || option === void 0 ? void 0 : option.value) {
                                        const searchKey = input.toLowerCase();
                                        if (option.value.toLowerCase().includes(searchKey)) {
                                            return true;
                                        }
                                        const fontName = fontFuncs.fontNameMap.get(option.value) || '';
                                        if (fontName.toLowerCase().includes(searchKey)) {
                                            return true;
                                        }
                                    }
                                    return false;
                                }, onKeyDown: (e) => e.stopPropagation(), onChange: (font) => setOptions(Object.assign(Object.assign({}, options), { font, fontOptions: '' })) })),
                        React.createElement(Form.Item, { label: t.font_size, className: styles['flex-child'] },
                            React.createElement(InputNumber, { className: styles['w-100'], min: 1, max: 100, value: options.fontSize, onKeyDown: (e) => e.stopPropagation(), onChange: (fontSize) => setOptions(Object.assign(Object.assign({}, options), { fontSize })) })),
                        React.createElement(Flex, { justify: "space-between" },
                            React.createElement(Form.Item, null,
                                React.createElement(Radio.Group, { optionType: "button", value: options.textAlign, options: [
                                        { label: React.createElement(AlignLeftOutlined, null), value: 'left' },
                                        { label: React.createElement(AlignCenterOutlined, null), value: 'center' },
                                        { label: React.createElement(AlignRightOutlined, null), value: 'right' },
                                    ], onChange: (e) => setOptions(Object.assign(Object.assign({}, options), { textAlign: e.target.value })) })),
                            React.createElement(Flex, { gap: 4 },
                                React.createElement(Form.Item, null,
                                    React.createElement(Button, { defaultChecked: isBold, className: isBold ? styles['check-text-option'] : '', icon: React.createElement(BoldOutlined, null), onClick: () => {
                                            const { fontOptions } = options;
                                            setOptions(Object.assign(Object.assign({}, options), { fontOptions: !isBold
                                                    ? `${fontOptions} bold`
                                                    : fontOptions.replace('bold', '').trim() }));
                                        }, disabled: !validFontStyles.some((style) => /bold/i.test(style)) })),
                                React.createElement(Form.Item, null,
                                    React.createElement(Button, { defaultChecked: isItalic, className: isItalic ? styles['check-text-option'] : '', icon: React.createElement(ItalicOutlined, null), onClick: () => {
                                            const { fontOptions } = options;
                                            setOptions(Object.assign(Object.assign({}, options), { fontOptions: !isItalic
                                                    ? `${fontOptions} italic`
                                                    : fontOptions.replace('italic', '').trim() }));
                                        }, disabled: !validFontStyles.some((style) => /italic/i.test(style)) })))),
                        React.createElement(Form.Item, { className: styles['flex-child'] },
                            React.createElement(Checkbox, { onChange: (e) => setOptions(Object.assign(Object.assign({}, options), { displayValue: !e.target.checked })) }, t.hide_text))))))));
});
