var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useRef, useState } from 'react';
import alertCaller from 'app/actions/alert-caller';
import checkDeviceStatus from 'helpers/check-device-status';
import deviceMaster from 'helpers/device-master';
import dialog from 'implementations/dialog';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { setFisheyeConfig } from 'helpers/camera-calibration-helper';
import CheckpointData from './common/CheckpointData';
import Chessboard from './BB2Calibration/Chessboard';
import Instruction from './common/Instruction';
import moveLaserHead from './BB2Calibration/moveLaserHead';
import SolvePnP from './common/SolvePnP';
import { bb2PnPPoints } from './common/solvePnPConstants';
import styles from './Calibration.module.scss';
var Steps;
(function (Steps) {
    Steps[Steps["CHECKPOINT_DATA"] = 0] = "CHECKPOINT_DATA";
    Steps[Steps["PRE_CHESSBOARD"] = 1] = "PRE_CHESSBOARD";
    Steps[Steps["CHESSBOARD"] = 2] = "CHESSBOARD";
    Steps[Steps["PUT_PAPER"] = 3] = "PUT_PAPER";
    Steps[Steps["SOLVE_PNP_INSTRUCTION"] = 4] = "SOLVE_PNP_INSTRUCTION";
    Steps[Steps["SOLVE_PNP"] = 5] = "SOLVE_PNP";
})(Steps || (Steps = {}));
const PROGRESS_ID = 'bb2-calibration';
const BB2Calibration = ({ isAdvanced, onClose }) => {
    const lang = useI18n();
    const tCali = lang.calibration;
    const calibratingParam = useRef({});
    const [step, setStep] = useState(isAdvanced ? Steps.PRE_CHESSBOARD : Steps.CHECKPOINT_DATA);
    const updateParam = useCallback((param) => {
        calibratingParam.current = Object.assign(Object.assign({}, calibratingParam.current), param);
    }, []);
    if (step === Steps.CHECKPOINT_DATA) {
        return (React.createElement(CheckpointData, { askUser: false, allowCheckPoint: false, updateParam: updateParam, onClose: onClose, onNext: (res) => {
                if (res) {
                    setStep(Steps.PUT_PAPER);
                }
                else {
                    alertCaller.popUpError({
                        message: tCali.unable_to_load_camera_parameters,
                    });
                    onClose(false);
                }
            } }));
    }
    if (step === Steps.PRE_CHESSBOARD) {
        const handleDownloadChessboard = () => {
            dialog.writeFileDialog(() => __awaiter(void 0, void 0, void 0, function* () {
                const resp = yield fetch('assets/bb2-chessboard.pdf');
                const blob = yield resp.blob();
                return blob;
            }), tCali.download_chessboard_file, 'Chessboard', [
                {
                    name: window.os === 'MacOS' ? 'PDF (*.pdf)' : 'PDF',
                    extensions: ['pdf'],
                },
            ]);
        };
        return (React.createElement(Instruction, { title: tCali.put_chessboard, steps: [
                tCali.put_chessboard_bb2_desc_1,
                tCali.put_chessboard_bb2_desc_2,
                tCali.put_chessboard_bb2_desc_3,
            ], buttons: [
                {
                    label: tCali.next,
                    onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const res = yield moveLaserHead();
                        if (res)
                            setStep(Steps.CHESSBOARD);
                    }),
                    type: 'primary',
                },
            ], animationSrcs: [
                { src: 'video/bb2-calibration/1-chessboard.webm', type: 'video/webm' },
                { src: 'video/bb2-calibration/1-chessboard.mp4', type: 'video/mp4' },
            ], onClose: onClose },
            React.createElement("div", { className: styles.link, onClick: handleDownloadChessboard }, tCali.download_chessboard_file)));
    }
    if (step === Steps.CHESSBOARD) {
        return (React.createElement(Chessboard, { chessboard: [24, 14], updateParam: updateParam, onNext: () => setStep(Steps.PUT_PAPER), onClose: onClose }));
    }
    if (step === Steps.PUT_PAPER) {
        const handleNext = (doEngraving = true) => __awaiter(void 0, void 0, void 0, function* () {
            const deviceStatus = yield checkDeviceStatus(deviceMaster.currentDevice.info);
            if (!deviceStatus)
                return;
            try {
                progressCaller.openNonstopProgress({
                    id: PROGRESS_ID,
                    message: tCali.drawing_calibration_image,
                });
                if (doEngraving)
                    yield deviceMaster.doBB2Calibration();
                progressCaller.update(PROGRESS_ID, { message: tCali.preparing_to_take_picture });
                const res = yield moveLaserHead();
                if (!res)
                    return;
                setStep(Steps.SOLVE_PNP_INSTRUCTION);
            }
            catch (err) {
                console.error(err);
            }
            finally {
                progressCaller.popById(PROGRESS_ID);
            }
        });
        return (React.createElement(Instruction, { animationSrcs: [
                { src: 'video/bb2-calibration/2-cut.webm', type: 'video/webm' },
                { src: 'video/bb2-calibration/2-cut.mp4', type: 'video/mp4' },
            ], title: tCali.put_paper, steps: [
                tCali.put_paper_step1,
                tCali.put_paper_step2,
                tCali.perform_autofocus_bb2,
                tCali.put_paper_step3,
                tCali.put_paper_skip,
            ], buttons: [
                isAdvanced
                    ? { label: tCali.back, onClick: () => setStep(Steps.CHESSBOARD) }
                    : {
                        label: tCali.cancel,
                        onClick: () => onClose(false),
                    },
                { label: tCali.skip, onClick: () => handleNext(false) },
                { label: tCali.start_engrave, onClick: () => handleNext(), type: 'primary' },
            ], onClose: () => onClose(false) }));
    }
    if (step === Steps.SOLVE_PNP_INSTRUCTION) {
        return (React.createElement(Instruction, { onClose: () => onClose(false), animationSrcs: [
                { src: 'video/bb2-calibration/3-align.webm', type: 'video/webm' },
                { src: 'video/bb2-calibration/3-align.mp4', type: 'video/mp4' },
            ], title: tCali.solve_pnp_title, steps: [tCali.solve_pnp_step1, tCali.solve_pnp_step2], buttons: [
                { label: tCali.back, onClick: () => setStep(Steps.PUT_PAPER) },
                { label: tCali.next, onClick: () => setStep(Steps.SOLVE_PNP), type: 'primary' },
            ] }));
    }
    if (step === Steps.SOLVE_PNP) {
        return (React.createElement(SolvePnP, { params: calibratingParam.current, dh: 0, refPoints: bb2PnPPoints, onClose: onClose, onBack: () => setStep(Steps.SOLVE_PNP_INSTRUCTION), onNext: (rvec, tvec) => __awaiter(void 0, void 0, void 0, function* () {
                progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: lang.device.processing });
                updateParam({ rvec, tvec });
                console.log('calibratingParam.current', calibratingParam.current);
                progressCaller.popById(PROGRESS_ID);
                const param = {
                    k: calibratingParam.current.k,
                    d: calibratingParam.current.d,
                    rvec,
                    tvec,
                    v: 3,
                };
                const res = yield setFisheyeConfig(param);
                if (res.status === 'ok') {
                    alertCaller.popUp({ message: tCali.camera_parameter_saved_successfully });
                    onClose(true);
                }
                else {
                    alertCaller.popUpError({
                        message: `${tCali.failed_to_save_camera_parameter}:<br />${JSON.stringify(res)}`,
                    });
                }
            }) }));
    }
    onClose();
    return React.createElement(React.Fragment, null);
};
export const showBB2Calibration = (isAdvanced = false) => {
    const id = 'bb2-calibration';
    const onClose = () => popDialogById(id);
    if (isIdExist(id))
        onClose();
    return new Promise((resolve) => {
        addDialogComponent(id, React.createElement(BB2Calibration, { isAdvanced: isAdvanced, onClose: (completed = false) => {
                onClose();
                resolve(completed);
            } }));
    });
};
export default BB2Calibration;
