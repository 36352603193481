// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-camera-Calibration-module__link---JT6b{color:#1890ff;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/camera/Calibration.module.scss","webpack://./src/web/styles/_variables.scss"],"names":[],"mappings":"AAEA,uEACE,aCMa,CDLb,cAAA","sourcesContent":["@use 'styles/variables' as variables;\n\n.link {\n  color: variables.$primary-blue;\n  cursor: pointer;\n}\n","$print-default-font-color: #4A4A4A;\n$default-button-border-radius: 4px;\n$size: 200px;\n$backgroundColor: #F0F0F0;\n$sidePanelWidth: 242px;\n$winSidePanelWidth: 258px;\n$panelBorderColor: #E0E0E0;\n$topBarHeight: 40px;\n\n$primary-blue: #1890ff;\n$primary-gray: #1e1e1e;\n$secondary-gray: #7c7c7c;\n$s3: 'https://beam-studio-web.s3.ap-northeast-1.amazonaws.com';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "src-web-app-components-dialogs-camera-Calibration-module__link---JT6b"
};
export default ___CSS_LOADER_EXPORT___;
