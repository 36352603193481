import classNames from 'classnames';
import React from 'react';
import { Flex } from 'antd';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import styles from './Block.module.scss';
const ParametersBlock = ({ isInch, parameters: { power, speed }, setParameters, }) => {
    const { beambox: { right_panel: { laser_panel: tLaserPanel }, }, } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(Flex, { justify: "space-between", align: "center", gap: 20 },
            React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center", gap: 12 },
                React.createElement("span", { className: styles.label }, tLaserPanel.strength),
                React.createElement(UnitInput, { "data-testid": "power", className: classNames(styles.input, styles.short), size: "small", value: power, min: 0, max: 100, precision: 0, addonAfter: "%", onChange: (val) => setParameters((cur) => (Object.assign(Object.assign({}, cur), { power: val }))) })),
            React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center", gap: 12 },
                React.createElement("span", { className: styles.label }, tLaserPanel.speed),
                React.createElement(UnitInput, { "data-testid": "speed", className: styles.input, size: "small", isInch: isInch, value: speed, min: 100, max: 3000, precision: 0, addonAfter: isInch ? 'in/s' : 'mm/s', onChange: (val) => setParameters((cur) => (Object.assign(Object.assign({}, cur), { speed: val }))) })))));
};
export default ParametersBlock;
