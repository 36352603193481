var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { ConfigProvider, InputNumber, Slider, Switch } from 'antd';
import { Popover } from 'antd-mobile';
import { QuestionCircleOutlined } from '@ant-design/icons';
import browser from 'implementations/browser';
import history from 'app/svgedit/history/history';
import ImageData from 'helpers/image-data';
import i18n from 'helpers/i18n';
import LayerPanelController from 'app/views/beambox/Right-Panels/contexts/LayerPanelController';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import OptionPanelIcons from 'app/icons/option-panel/OptionPanelIcons';
import UnitInput from 'app/widgets/Unit-Input-v2';
import useWorkarea from 'helpers/hooks/useWorkarea';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { isMobile } from 'helpers/system-helper';
import { promarkModels } from 'app/actions/beambox/constant';
import { sliderTheme } from 'app/constants/antd-config';
import styles from './ImageOptions.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const LANG = i18n.lang.beambox.right_panel.object_panel.option_panel;
const ImageOptions = ({ elem, updateObjectPanel }) => {
    const thresholdCache = useRef(new Array(256).fill(null));
    const curCallID = useRef(0);
    const nextCallID = useRef(1);
    // FIXME: Swiftray Gcode converter(Promark) treat transparent pixel as white pixel
    // This is a temporary workaround to prevent engraving of transparent pixels when threshold is set to 255
    const workarea = useWorkarea();
    const maxThreshold = useMemo(() => (promarkModels.has(workarea) ? 254 : 255), [workarea]);
    const changeAttribute = useCallback((changes) => {
        const batchCommand = new history.BatchCommand('Image Option Panel');
        const setAttribute = (key, value) => {
            svgCanvas.undoMgr.beginUndoableChange(key, [elem]);
            elem.setAttribute(key, value);
            const cmd = svgCanvas.undoMgr.finishUndoableChange();
            if (!cmd.isEmpty()) {
                batchCommand.addSubCommand(cmd);
            }
        };
        const keys = Object.keys(changes);
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            setAttribute(key, changes[key]);
        }
        if (changes['data-pwm']) {
            ObjectPanelController.events.emit('pwm-changed');
            batchCommand.onAfter = () => {
                ObjectPanelController.events.emit('pwm-changed');
            };
        }
        if (!batchCommand.isEmpty()) {
            svgCanvas.undoMgr.addCommandToHistory(batchCommand);
        }
    }, [elem]);
    const generateImageData = useCallback((isShading, threshold) => new Promise((resolve) => {
        ImageData(elem.getAttribute('origImage'), {
            width: parseFloat(elem.getAttribute('width')),
            height: parseFloat(elem.getAttribute('height')),
            grayscale: {
                is_rgba: true,
                is_shading: isShading,
                threshold,
                is_svg: false,
            },
            onComplete: (result) => {
                resolve(result);
            },
        });
    }), [elem]);
    const handleGradientClick = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        let isShading = elem.getAttribute('data-shading') === 'true';
        isShading = !isShading;
        const threshold = isShading ? 254 : 128;
        const imageData = yield generateImageData(isShading, threshold);
        const { pngBase64 } = imageData;
        changeAttribute({
            'data-shading': isShading,
            'data-threshold': isShading ? 254 : 128,
            'xlink:href': pngBase64,
        });
        updateObjectPanel();
        LayerPanelController.checkGradient();
    }), [elem, changeAttribute, generateImageData, updateObjectPanel]);
    const handlePwmClick = useCallback(() => {
        const cur = elem.getAttribute('data-pwm') === '1';
        changeAttribute({
            'data-pwm': cur ? '0' : '1',
        });
        updateObjectPanel();
    }, [elem, changeAttribute, updateObjectPanel]);
    const handleThresholdChange = useCallback((val) => __awaiter(void 0, void 0, void 0, function* () {
        const callID = nextCallID.current;
        nextCallID.current += 1;
        let result = thresholdCache.current[val];
        if (!result) {
            const isShading = elem.getAttribute('data-shading') === 'true';
            const imageData = yield generateImageData(isShading, val);
            result = imageData.pngBase64;
            thresholdCache.current[val] = result;
        }
        if (callID >= curCallID.current) {
            curCallID.current = callID;
            changeAttribute({
                'data-threshold': val,
                'xlink:href': result,
            });
            updateObjectPanel();
        }
    }), [elem, changeAttribute, generateImageData, updateObjectPanel]);
    const isGradient = elem.getAttribute('data-shading') === 'true';
    const isPwm = elem.getAttribute('data-pwm') === '1';
    const activeKey = ObjectPanelController.getActiveKey();
    const thresholdVisible = useMemo(() => activeKey === 'threshold', [activeKey]);
    const gradientBlock = isMobile() ? (React.createElement(React.Fragment, null,
        React.createElement(ObjectPanelItem.Item, { id: "gradient", content: React.createElement(Switch, { checked: isGradient }), label: LANG.shading, onClick: handleGradientClick }),
        isGradient && (React.createElement(ObjectPanelItem.Item, { id: "pwm", content: React.createElement(Switch, { checked: isPwm }), label: LANG.pwm_engraving, onClick: handlePwmClick })))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles['option-block'], key: "gradient" },
            React.createElement("div", { className: styles.label }, LANG.shading),
            React.createElement(Switch, { size: "small", checked: isGradient, onChange: handleGradientClick })),
        isGradient && (React.createElement("div", { className: styles['option-block'], key: "pwm" },
            React.createElement("div", { className: styles.label },
                LANG.pwm_engraving,
                React.createElement(QuestionCircleOutlined, { className: styles.icon, onClick: () => browser.open(LANG.pwm_engraving_link) })),
            React.createElement(Switch, { size: "small", checked: isPwm, onChange: handlePwmClick })))));
    let thresholdBlock = null;
    if (!isGradient) {
        const threshold = parseInt(elem.getAttribute('data-threshold'), 10) || 128;
        thresholdBlock = isMobile() ? (React.createElement(Popover, { visible: thresholdVisible, content: React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.threshold_short),
                React.createElement(ConfigProvider, { theme: { token: { borderRadius: 100 } } },
                    React.createElement(InputNumber, { className: styles.input, type: "number", min: 1, max: maxThreshold, value: threshold, precision: 0, onChange: handleThresholdChange, controls: false })),
                React.createElement(Slider, { className: styles.slider, min: 1, max: maxThreshold, step: 1, marks: { 128: '128' }, value: threshold, onChange: handleThresholdChange })) },
            React.createElement(ObjectPanelItem.Item, { id: "threshold", content: React.createElement(OptionPanelIcons.Threshold, null), label: LANG.threshold_short, autoClose: false }))) : (React.createElement(Fragment, { key: "threshold" },
            React.createElement("div", { className: classNames(styles['option-block'], styles['with-slider']) },
                React.createElement("div", { className: styles.label }, LANG.threshold),
                React.createElement(UnitInput, { min: 1, max: maxThreshold, decimal: 0, unit: "", className: { [styles['option-input']]: true }, defaultValue: threshold, getValue: handleThresholdChange })),
            React.createElement(ConfigProvider, { theme: sliderTheme },
                React.createElement(Slider, { min: 1, max: maxThreshold, step: 1, value: threshold, onChange: handleThresholdChange }))));
    }
    return isMobile() ? (React.createElement(React.Fragment, null,
        gradientBlock,
        thresholdBlock)) : (React.createElement("div", { className: styles.options },
        gradientBlock,
        thresholdBlock));
};
export default ImageOptions;
