import { promarkModels } from 'app/actions/beambox/constant';
import { LaserType } from 'app/constants/promark-constants';
import { getWorkarea } from 'app/constants/workarea-constants';
import { getPromarkLimit } from 'helpers/layer/layer-config-helper';
export const commonTableParams = ['strength', 'speed', 'repeat'];
export const promarkTableParams = ['fillInterval', 'frequency'];
export const mopaTableParams = ['pulseWidth'];
export const tableParams = [
    ...commonTableParams,
    ...promarkTableParams,
    ...mopaTableParams,
];
const getCommonTableSetting = (workarea) => {
    const { maxSpeed } = getWorkarea(workarea);
    return {
        strength: {
            minValue: 15,
            maxValue: 100,
            min: 1,
            max: 100,
            default: 15,
            selected: 0,
        },
        speed: {
            minValue: 20,
            maxValue: maxSpeed,
            min: 1,
            max: maxSpeed,
            default: 20,
            selected: 1,
        },
        repeat: {
            minValue: 1,
            maxValue: 5,
            min: 1,
            max: 100,
            default: 1,
            selected: 2,
        },
    };
};
const getPromarkTableSetting = (workarea, { laserType }) => {
    const { minSpeed, maxSpeed } = getWorkarea(workarea);
    const limit = getPromarkLimit();
    return Object.assign({ strength: {
            minValue: 15,
            maxValue: 100,
            min: 1,
            max: 100,
            default: 15,
            selected: 0,
        }, speed: {
            minValue: minSpeed,
            maxValue: maxSpeed,
            min: minSpeed,
            max: maxSpeed,
            default: 1000,
            selected: 1,
        }, repeat: {
            minValue: 1,
            maxValue: 5,
            min: 1,
            max: 100,
            default: 1,
            selected: 2,
        }, fillInterval: {
            minValue: 0.01,
            maxValue: 1,
            min: 0.0001,
            max: 100,
            default: 0.01,
            selected: 2,
        }, frequency: {
            minValue: limit.frequency.min,
            maxValue: limit.frequency.max,
            min: limit.frequency.min,
            max: limit.frequency.max,
            default: limit.frequency.min,
            selected: 2,
        } }, (laserType === LaserType.MOPA && {
        pulseWidth: {
            minValue: limit.pulseWidth.min,
            maxValue: limit.pulseWidth.max,
            min: limit.pulseWidth.min,
            max: limit.pulseWidth.max,
            default: 350,
            selected: 2,
        },
    }));
};
export const getTableSetting = (workarea, { laserType } = { laserType: LaserType.Desktop }) => {
    if (promarkModels.has(workarea)) {
        return getPromarkTableSetting(workarea, { laserType });
    }
    return getCommonTableSetting(workarea);
};
