var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import Dialog from 'app/actions/dialog-caller';
import Monitor from 'app/views/monitor/Monitor';
import { checkBlockedSerial } from 'helpers/device/check-blocked-serial';
import { Mode } from 'app/constants/monitor-constants';
import { MonitorContextProvider } from 'app/contexts/MonitorContext';
const monitorController = {
    showMonitor: (device, mode = Mode.FILE, previewTask) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield checkBlockedSerial(device.serial);
        if (!res)
            return;
        Dialog.addDialogComponent('monitor', React.createElement(MonitorContextProvider, { device: device, mode: mode, previewTask: previewTask, onClose: () => Dialog.popDialogById('monitor') },
            React.createElement(Monitor, { device: device })));
    }),
};
export default monitorController;
