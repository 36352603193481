/**
 * key code map
 */
export default {
    KEY_L_CMD: 91,
    KEY_R_CMD: 93,
    KEY_RETURN: 13,
    KEY_PLUS: 187,
    KEY_MINUS: 189,
    KEY_MULTIPLY: 56,
    KEY_DIVIDE: 191,
    KEY_SHIFT: 16,
    KEY_CTRL: 17,
    KEY_ALT: 18,
    KEY_DEL: navigator.platform === 'Win32' ? 46 : 8,
    KEY_BACK: 8,
    KEY_TAB: 9,
    KEY_ESC: 27,
    KEY_LEFT: 37,
    KEY_UP: 38,
    KEY_RIGHT: 39,
    KEY_DOWN: 40,
    KEY_BACKSLASH: 220,
    KEY_A: 65,
    KEY_C: 67,
    KEY_V: 86,
    KEY_X: 88,
    KEY_NUM_PLUS: 107,
    KEY_NUM_MINUS: 109,
    KEY_F1: 112,
    KEY_F2: 113,
};
