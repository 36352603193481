// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-camera-common-Title-module__title--N8zXG{display:inline-flex;align-items:center}.src-web-app-components-dialogs-camera-common-Title-module__title--N8zXG .src-web-app-components-dialogs-camera-common-Title-module__icon--NO6qf{margin-left:10px;font-size:14px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/camera/common/Title.module.scss"],"names":[],"mappings":"AAAA,yEACE,mBAAA,CACA,kBAAA,CAEA,iJACE,gBAAA,CACA,cAAA,CACA,cAAA","sourcesContent":[".title {\n  display: inline-flex;\n  align-items: center;\n\n  .icon {\n    margin-left: 10px;\n    font-size: 14px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "src-web-app-components-dialogs-camera-common-Title-module__title--N8zXG",
	"icon": "src-web-app-components-dialogs-camera-common-Title-module__icon--NO6qf"
};
export default ___CSS_LOADER_EXPORT___;
