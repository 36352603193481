var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Flex, Modal, Spin, Tooltip } from 'antd';
import checkDeviceStatus from 'helpers/check-device-status';
import deviceMaster from 'helpers/device-master';
import storage from 'implementations/storage';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import UnitInput from 'app/widgets/UnitInput';
import { ArrowDownOutlined, ArrowUpOutlined, LoadingOutlined, QuestionCircleOutlined, } from '@ant-design/icons';
import { swiftrayClient } from 'helpers/api/swiftray-client';
import { generateCalibrationTaskString, loadTaskToSwiftray, } from 'helpers/device/promark/calibration';
import Icons from 'app/icons/icons';
import deviceConstants from 'app/constants/device-constants';
import styles from './ZAxisAdjustment.module.scss';
import ParametersBlock from './ParametersBlock';
export const ZAxisAdjustment = ({ device, onClose }) => {
    const { global: tGlobal, promark_settings: t } = useI18n();
    const { model } = device;
    const isInch = useMemo(() => storage.get('default-units') === 'inches', []);
    const [zAxis, setZAxis] = useState(1);
    const [isPreviewing, setIsPreviewing] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [parameters, setParameters] = useState({ power: 100, speed: 350 });
    const previewTask = useRef('');
    const markTask = useRef('');
    const movingTimeout = useRef();
    const waitTillNotRunning = () => __awaiter(void 0, void 0, void 0, function* () {
        yield deviceMaster.waitTillStatusPredicate({
            predicate: (status) => status !== deviceConstants.status.RUNNING,
        });
    });
    const uploadPreviewTask = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!previewTask.current) {
            previewTask.current = yield generateCalibrationTaskString({ width: 10 });
        }
        yield loadTaskToSwiftray(previewTask.current, model);
    }), [model]);
    useEffect(() => {
        markTask.current = '';
    }, [parameters]);
    const uploadMarkTask = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { power, speed } = parameters;
        if (!markTask.current) {
            markTask.current = yield generateCalibrationTaskString({ width: 10, power, speed });
        }
        yield loadTaskToSwiftray(markTask.current, model);
    }), [model, parameters]);
    useEffect(() => {
        const abortPreview = () => __awaiter(void 0, void 0, void 0, function* () {
            setIsPreviewing(false);
        });
        swiftrayClient.on('disconnected', abortPreview);
        return () => {
            swiftrayClient.off('disconnected', abortPreview);
        };
    }, []);
    const stopPreview = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield deviceMaster.stopFraming();
            yield waitTillNotRunning();
            setIsPreviewing(false);
        }
        catch (err) {
            console.error('🚀 ~ file: ZAxisAdjustment.tsx:92 ~ stopPreview ~ err:', err);
        }
    });
    const handlePreview = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isPreviewing) {
            yield uploadPreviewTask();
            yield deviceMaster.startFraming();
            setIsPreviewing(true);
        }
        else {
            yield stopPreview();
        }
    });
    const handleMove = (distance) => __awaiter(void 0, void 0, void 0, function* () {
        const getTime = (distance) => (Math.abs(distance) * 1000) / 3 + 500;
        if (isPreviewing) {
            yield stopPreview();
        }
        setIsMoving(true);
        yield deviceMaster.adjustZAxis(distance);
        movingTimeout.current = setTimeout(() => {
            setIsMoving(false);
        }, getTime(distance));
    });
    const handleMark = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isPreviewing) {
            yield stopPreview();
        }
        yield uploadMarkTask();
        yield deviceMaster.doPromarkCalibration();
    });
    const handleStop = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (isMoving) {
                yield deviceMaster.stop();
                yield waitTillNotRunning();
                if (movingTimeout.current) {
                    clearTimeout(movingTimeout.current);
                    setIsMoving(false);
                }
            }
        }
        catch (err) {
            console.error('🚀 ~ file: ZAxisAdjustment.tsx:143 ~ handleStop ~ err:', err);
        }
    });
    const handleClose = () => __awaiter(void 0, void 0, void 0, function* () {
        yield handleStop();
        if (isPreviewing) {
            yield stopPreview();
        }
        onClose();
    });
    const footer = (React.createElement(Flex, { className: styles.footer, justify: "space-between", align: "center" },
        React.createElement("div", null,
            React.createElement(Button, { className: styles.button, onClick: handlePreview, disabled: isMoving },
                t.preview,
                isPreviewing ? (React.createElement(Spin, { indicator: React.createElement(LoadingOutlined, { className: styles.icon, spin: true }) })) : (React.createElement(Icons.Play, { className: styles.icon }))),
            React.createElement(Button, { className: styles.button, onClick: handleMark, disabled: isMoving }, t.mark)),
        React.createElement(Button, { className: styles.button, type: "primary", onClick: handleClose }, tGlobal.ok)));
    return (React.createElement(Modal, { open: true, centered: true, maskClosable: false, width: 425, title: t.z_axis_adjustment.title, onCancel: handleClose, footer: footer },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles['mb-12'] }, t.z_axis_adjustment.section1),
            React.createElement(Flex, { justify: "flex-start", className: styles['mb-28'], gap: 8 },
                React.createElement(Button, { icon: React.createElement(ArrowUpOutlined, null), disabled: isMoving, onClick: () => handleMove(zAxis) }),
                React.createElement(UnitInput, { isInch: isInch, className: styles.input, value: zAxis, min: 1, max: 100, precision: isInch ? 6 : 2, step: isInch ? 25.4 : 1, addonAfter: isInch ? 'in' : 'mm', onChange: (value) => setZAxis(Math.min(100, Math.max(1, value))) }),
                React.createElement(Button, { icon: React.createElement(ArrowDownOutlined, null), disabled: isMoving, onClick: () => handleMove(-zAxis) }),
                React.createElement(Button, { className: styles.button, danger: true, onClick: handleStop, disabled: !isMoving }, tGlobal.stop)),
            React.createElement(Flex, { justify: "flex-start", className: styles['mb-12'] },
                React.createElement("div", { className: styles['mark-title'] }, t.mark_parameters),
                React.createElement(Tooltip, { title: t.z_axis_adjustment.tooltip1 },
                    React.createElement(QuestionCircleOutlined, null))),
            React.createElement(ParametersBlock, { isInch: isInch, parameters: parameters, setParameters: setParameters }))));
};
export const showZAxisAdjustment = (device) => __awaiter(void 0, void 0, void 0, function* () {
    const id = 'z-axis-adjustment';
    yield deviceMaster.select(device);
    const res = yield checkDeviceStatus(device);
    if (!res) {
        return;
    }
    if (!isIdExist(id)) {
        addDialogComponent(id, React.createElement(ZAxisAdjustment, { device: device, onClose: () => popDialogById(id) }));
    }
});
