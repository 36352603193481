const namingMap = {
    strength: 'P',
    speed: 'S',
    repeat: 'C',
    pulseWidth: 'PW',
    frequency: 'F',
    fillInterval: 'FI',
};
export default function generateSvgInfo({ tableSetting, blockSetting: { column: { count: { value: colLength }, }, row: { count: { value: rowLength }, }, }, }) {
    const [col, row, ...staticParams] = Object.entries(tableSetting).sort(([, { selected: a }], [, { selected: b }]) => a - b);
    const generateRange = (length, [key, { minValue, maxValue }]) => Array.from({ length }, (_, i) => {
        const value = minValue + (maxValue - minValue) * (i / (length !== 1 ? length - 1 : 1));
        // Round to 4 decimal places for fillInterval
        if (key === 'fillInterval') {
            return Math.round(value * 10000) / 10000;
        }
        return Math.ceil(value);
    });
    const colRange = generateRange(colLength, col);
    const rowRange = generateRange(rowLength, row);
    return colRange.flatMap((c) => rowRange.map((r) => (Object.assign({ name: `${namingMap[col[0]]}${c}-${namingMap[row[0]]}${r}`, [col[0]]: c, [row[0]]: r }, staticParams.map(([key, value]) => ({ [key]: value.default }))))));
}
