// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-pages-ConnectMachineIp-Hint-module__link--2rqmU{text-decoration:underline;cursor:pointer}.src-web-app-pages-ConnectMachineIp-Hint-module__link--2rqmU:hover{color:#606060}", "",{"version":3,"sources":["webpack://./src/web/app/pages/ConnectMachineIp/Hint.module.scss"],"names":[],"mappings":"AAAA,6DACE,yBAAA,CACA,cAAA,CAEA,mEACE,aAAA","sourcesContent":[".link {\n  text-decoration: underline;\n  cursor: pointer;\n\n  &:hover {\n    color: #606060;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "src-web-app-pages-ConnectMachineIp-Hint-module__link--2rqmU"
};
export default ___CSS_LOADER_EXPORT___;
