var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import alertCaller from 'app/actions/alert-caller';
import checkDeviceStatus from 'helpers/check-device-status';
import durationFormatter from 'helpers/duration-formatter';
import i18n from 'helpers/i18n';
import { getWorkarea } from 'app/constants/workarea-constants';
import deviceMaster from 'helpers/device-master';
export default class BaseCurveMeasurer {
    constructor(device) {
        this.setup = () => __awaiter(this, void 0, void 0, function* () {
            throw new Error('Not implemented');
        });
        this.end = () => __awaiter(this, void 0, void 0, function* () {
            throw new Error('Not implemented');
        });
        this.measurePoint = (x, y, feedrate, offset, objectHeight, lowest) => __awaiter(this, void 0, void 0, function* () {
            throw new Error('Not implemented');
        });
        this.measureArea = (xRange, yRange, objectHeight, opts = {}) => __awaiter(this, void 0, void 0, function* () {
            try {
                const points = yRange.map((y) => xRange.map((x) => [x, y, null]));
                const totalPoints = xRange.length * yRange.length;
                const targetIndices = Array.from({ length: totalPoints }, (_, i) => i);
                const data = yield this.measurePoints({
                    points,
                    objectHeight,
                    lowest: null,
                    highest: null,
                    gap: [xRange[1] - xRange[0], yRange[1] - yRange[0]],
                }, targetIndices, opts);
                return data;
            }
            catch (error) {
                alertCaller.popUpError({ message: `Failed to measure area ${error.message}` });
                console.log(error);
                return null;
            }
        });
        this.device = device;
    }
    setupDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield deviceMaster.select(this.device);
            if (!res)
                return false;
            const deviceStatus = yield checkDeviceStatus(this.device);
            if (!deviceStatus)
                return false;
            return true;
        });
    }
    measurePoints(curData, targetIndices, opts = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            const { checkCancel, onProgressText, onPointFinished } = opts;
            onProgressText === null || onProgressText === void 0 ? void 0 : onProgressText(lang.curve_engraving.starting_measurement);
            const workarea = getWorkarea(this.device.model);
            const [offsetX, offsetY, offsetZ] = workarea.autoFocusOffset || [0, 0, 0];
            const feedrate = 6000;
            const { objectHeight, points } = curData;
            let { lowest = null, highest = null } = curData;
            // deep copy
            const newPoints = JSON.parse(JSON.stringify(points));
            const start = Date.now();
            const columns = newPoints[0].length;
            for (let i = 0; i < targetIndices.length; i += 1) {
                if (checkCancel === null || checkCancel === void 0 ? void 0 : checkCancel())
                    return null;
                const idx = targetIndices[i];
                const row = Math.floor(idx / columns);
                const column = idx % columns;
                const point = newPoints[row][column];
                const [x, y] = point;
                try {
                    // eslint-disable-next-line no-await-in-loop
                    const z = yield this.measurePoint(x, y, feedrate, [offsetX, offsetY, offsetZ], objectHeight, lowest);
                    const pointZ = typeof z === 'number' ? Math.max(0, z - offsetZ) : null;
                    if (lowest === null || z > lowest)
                        lowest = z; // actually the max measured value
                    // actually the min measured value, use pointZ to display Plane when z is null
                    if (highest === null || z < highest)
                        highest = pointZ;
                    newPoints[row][column][2] = pointZ;
                }
                catch (error) {
                    console.error(`Failed to measure height at point ${x}, ${y}`, error);
                }
                const elapsedTime = Date.now() - start;
                const finished = i + 1;
                const finishedRatio = (i + 1) / targetIndices.length;
                const remainingTime = (elapsedTime / finishedRatio - elapsedTime) / 1000;
                onProgressText === null || onProgressText === void 0 ? void 0 : onProgressText(`${lang.message.time_remaining} ${durationFormatter(remainingTime)}`);
                onPointFinished === null || onPointFinished === void 0 ? void 0 : onPointFinished(finished);
            }
            return Object.assign(Object.assign({}, curData), { points: newPoints, lowest,
                highest });
        });
    }
}
