var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { promarkModels } from 'app/actions/beambox/constant';
import deviceMaster from 'helpers/device-master';
import webcamHelper from 'helpers/webcam-helper';
// according video resolution, 2400 * 1600 from [webcamHelper](src/web/helpers/webcam-helper.ts)
const PROMARK_GOOD_PICTURE_THRESHOLD = (2400 * 1600) / 4;
const checkCameraCommon = (device) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const selectResult = yield deviceMaster.select(device);
        if (!selectResult.success) {
            throw new Error(selectResult.error || 'Fail to select device');
        }
        yield deviceMaster.connectCamera();
        yield deviceMaster.takeOnePicture();
        deviceMaster.disconnectCamera();
        return true;
    }
    catch (e) {
        console.log(e);
    }
    return false;
});
const checkCameraPromark = (_device) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const isWebCamExist = yield webcamHelper.getDevice();
        if (!isWebCamExist) {
            return false;
        }
        const webcam = yield webcamHelper.connectWebcam();
        const pic = yield webcam.getPicture();
        // size bigger than PROMARK_GOOD_PICTURE_THRESHOLD is considered as a good picture
        return pic.size > PROMARK_GOOD_PICTURE_THRESHOLD;
    }
    catch (e) {
        console.log(e);
    }
    return false;
});
const checkCamera = (device) => __awaiter(void 0, void 0, void 0, function* () {
    if (promarkModels.has(device.model)) {
        return checkCameraPromark(device);
    }
    return checkCameraCommon(device);
});
export default checkCamera;
