var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import i18n from 'helpers/i18n';
import BaseCurveMeasurer from './base';
export default class RedLightCurveMeasurer extends BaseCurveMeasurer {
    constructor() {
        super(...arguments);
        this.setup = (onProgressText) => __awaiter(this, void 0, void 0, function* () {
            const res = this.setupDevice();
            if (!res)
                return false;
            const { lang } = i18n;
            if (deviceMaster.currentControlMode !== 'red_laser_measure') {
                onProgressText === null || onProgressText === void 0 ? void 0 : onProgressText(lang.message.enteringRedLaserMeasureMode);
                yield deviceMaster.enterRedLaserMeasureMode();
            }
            return true;
        });
        this.showTakeReferenceDialog = () => __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            const progressId = 'take-reference';
            progressCaller.openNonstopProgress({ id: progressId });
            if (deviceMaster.currentControlMode !== 'red_laser_measure') {
                progressCaller.update(progressId, { message: lang.message.enteringRedLaserMeasureMode });
            }
            const res = yield new Promise((resolve) => {
                alertCaller.popUp({
                    caption: lang.curve_engraving.take_reference,
                    message: lang.curve_engraving.take_reference_desc,
                    buttonType: alertConstants.CONFIRM_CANCEL,
                    onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                        try {
                            const z = yield deviceMaster.takeReferenceZ();
                            resolve(z);
                        }
                        catch (error) {
                            alertCaller.popUpError({ message: `Failed to take reference ${error.message}` });
                            resolve(null);
                        }
                    }),
                    onCancel: () => resolve(null),
                });
            });
            if (res)
                this.hasTakenReference = true;
            progressCaller.popById(progressId);
            return res;
        });
        this.end = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.device)
                return;
            try {
                if (deviceMaster.currentControlMode === 'red_laser_measure') {
                    yield deviceMaster.endRedLaserMeasureMode();
                }
            }
            catch (error) {
                console.error('Failed to exit red laser measure mode', error);
            }
        });
        this.measurePoint = (x, y, feedrate) => __awaiter(this, void 0, void 0, function* () {
            const z = yield deviceMaster.measureZ({ X: x, Y: y, F: feedrate });
            return z;
        });
    }
    measurePoints(curData, targetIndices, opts) {
        const _super = Object.create(null, {
            measurePoints: { get: () => super.measurePoints }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hasTakenReference) {
                const res = yield this.showTakeReferenceDialog();
                if (!res)
                    return null;
            }
            const res = yield _super.measurePoints.call(this, curData, targetIndices, opts);
            return res;
        });
    }
}
