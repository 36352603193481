import React, { memo, useCallback } from 'react';
import AlertIcons from 'app/icons/alerts/AlertIcons';
import browser from 'implementations/browser';
import styles from './Title.module.scss';
const Title = ({ title, link }) => {
    const openLink = useCallback(() => {
        if (link)
            browser.open(link);
    }, [link]);
    if (!link)
        return title;
    return (React.createElement("div", { className: styles.title },
        title,
        React.createElement(AlertIcons.ExtLink, { className: styles.icon, onClick: openLink })));
};
export default memo(Title);
