import React, { useContext, useState } from 'react';
import { Modal, Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import UnitInput from 'app/widgets/Unit-Input-v2';
import useI18n from 'helpers/useI18n';
import { getLayerByName } from 'helpers/layer/layer-helper';
import { writeDataLayer } from 'helpers/layer/layer-config-helper';
import ConfigPanelContext from './ConfigPanelContext';
import styles from './FillSettingModal.module.scss';
const FillSettingModal = ({ onClose }) => {
    const { global: tGlobal, beambox: { right_panel: { laser_panel: t }, }, } = useI18n();
    const { dispatch, selectedLayers, state } = useContext(ConfigPanelContext);
    const [draftValue, setDraftValue] = useState({
        fillInterval: state.fillInterval,
        fillAngle: state.fillAngle,
        biDirectional: state.biDirectional,
        crossHatch: state.crossHatch,
    });
    const handleSave = () => {
        const keys = ['fillInterval', 'fillAngle', 'biDirectional', 'crossHatch'];
        selectedLayers.forEach((layerName) => {
            const layer = getLayerByName(layerName);
            keys.forEach((key) => {
                if (state[key].value !== draftValue[key].value ||
                    state[key].hasMultiValue !== draftValue[key].hasMultiValue) {
                    writeDataLayer(layer, key, draftValue[key].value);
                }
            });
        });
        dispatch({ type: 'update', payload: draftValue });
        eventEmitterFactory
            .createEventEmitter('time-estimation-button')
            .emit('SET_ESTIMATED_TIME', null);
        onClose();
    };
    const handleValueChange = (key, value) => {
        setDraftValue((cur) => (Object.assign(Object.assign({}, cur), { [key]: { value, hasMultiValue: false } })));
    };
    return (React.createElement(Modal, { centered: true, open: true, maskClosable: false, width: 350, onOk: handleSave, onCancel: onClose, cancelText: tGlobal.cancel, okText: tGlobal.save, title: React.createElement("div", null,
            t.fill_setting,
            React.createElement(Tooltip, { title: t.filled_path_only },
                React.createElement(QuestionCircleOutlined, { className: styles.hint }))) },
        React.createElement("div", { className: styles.container },
            React.createElement("div", null,
                React.createElement("span", null, t.fill_interval),
                React.createElement(UnitInput, { id: "fillInterval", className: { [styles.input]: true }, defaultValue: draftValue.fillInterval.value, getValue: (value) => handleValueChange('fillInterval', value), min: 0.0001, max: 100, unit: "mm", decimal: 4, step: 0.0001, displayMultiValue: draftValue.fillInterval.hasMultiValue, forceUsePropsUnit: true })),
            React.createElement("div", null,
                React.createElement("span", null, t.fill_angle),
                React.createElement(UnitInput, { id: "fillAngle", className: { [styles.input]: true }, defaultValue: draftValue.fillAngle.value, getValue: (value) => handleValueChange('fillAngle', value), min: -360, max: 360, unit: "deg", decimal: 1, displayMultiValue: draftValue.fillAngle.hasMultiValue })),
            React.createElement("div", { onClick: () => handleValueChange('biDirectional', !draftValue.biDirectional.value) },
                React.createElement("label", { htmlFor: "biDirectional" }, t.bi_directional),
                React.createElement(Switch, { id: "biDirectional", checked: draftValue.biDirectional.value, onChange: (value) => handleValueChange('biDirectional', value) })),
            React.createElement("div", { onClick: () => handleValueChange('crossHatch', !draftValue.crossHatch.value) },
                React.createElement("label", { htmlFor: "crossHatch" }, t.cross_hatch),
                React.createElement(Switch, { id: "crossHatch", checked: draftValue.crossHatch.value, onChange: (value) => handleValueChange('crossHatch', value) })))));
};
export default FillSettingModal;
