// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-actions-camera-preview-helper-BasePreviewManager-module__mt-24--Xt6pE{margin-top:24px}", "",{"version":3,"sources":["webpack://./src/web/app/actions/camera/preview-helper/BasePreviewManager.module.scss"],"names":[],"mappings":"AAAA,mFACE,eAAA","sourcesContent":[".mt-24 {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mt-24": "src-web-app-actions-camera-preview-helper-BasePreviewManager-module__mt-24--Xt6pE"
};
export default ___CSS_LOADER_EXPORT___;
