import React, { useState } from 'react';
import { Divider, Flex, Radio, Space } from 'antd';
import classNames from 'classnames';
import useI18n from 'helpers/useI18n';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import { laserSourceWattMap, LaserType, workareaOptions } from 'app/constants/promark-constants';
import { setPromarkInfo } from 'helpers/device/promark/promark-info';
import styles from './index.module.scss';
export default function ChoosePromarkLaserSource() {
    const { initialize: t } = useI18n();
    const [laserSource, setLaserSource] = useState('');
    const [workarea, setWorkarea] = useState(0);
    const generateLaserSourceOptions = (source) => laserSourceWattMap[source].map((watt) => ({
        label: `${source} - ${watt}W`,
        value: `${source}-${watt}`,
    }));
    const generateWorkareaOptions = () => workareaOptions.map((area) => ({ label: `${area}x${area}`, value: area }));
    const renderLaserSourceRadio = (options) => (React.createElement(Space, { direction: "vertical" }, options.map(({ label, value }) => (React.createElement(Radio, { key: value, value: value }, label)))));
    const onLaserSourceChange = ({ target: { value } }) => {
        setLaserSource(value);
        // setup 110mm for Promark MOPA 20W by default, otherwise 220mm
        if (value === 'MOPA-20') {
            setWorkarea(110);
        }
        else {
            setWorkarea(220);
        }
    };
    const onWorkareaChange = (e) => setWorkarea(e.target.value);
    const handleNext = () => {
        var _a;
        const customizedDimension = (_a = beamboxPreference.read('customized-dimension')) !== null && _a !== void 0 ? _a : {};
        const [source, watt] = laserSource.split('-');
        beamboxPreference.write('customized-dimension', Object.assign(Object.assign({}, customizedDimension), { fpm1: { width: workarea, height: workarea } }));
        setPromarkInfo({ laserType: LaserType[source], watt: Number(watt) });
        window.location.hash = `#initialize/connect/promark-settings`;
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles['top-bar'] }),
        React.createElement(Flex, { justify: "space-between", gap: 40 },
            React.createElement("div", { className: styles.image },
                React.createElement("img", { src: "core-img/init-panel/promark-real.png", draggable: "false", width: 300, height: 300 })),
            React.createElement(Flex, { vertical: true },
                React.createElement("div", { className: styles['mb-32px'] },
                    React.createElement("div", { className: styles.title }, t.promark.select_laser_source),
                    React.createElement(Flex, null,
                        React.createElement(Radio.Group, { onChange: onLaserSourceChange, value: laserSource },
                            React.createElement(Space, { split: React.createElement(Divider, { type: "vertical", className: styles['space-divider'] }) },
                                renderLaserSourceRadio(generateLaserSourceOptions('Desktop')),
                                renderLaserSourceRadio(generateLaserSourceOptions('MOPA')))))),
                React.createElement("div", null,
                    React.createElement("div", { className: classNames(styles.subtitle, styles['mb-12px']) }, t.promark.select_workarea),
                    React.createElement(Flex, null,
                        React.createElement(Radio.Group, { onChange: onWorkareaChange, options: generateWorkareaOptions(), value: workarea }))))),
        React.createElement("div", { className: styles.btns },
            React.createElement("div", { className: styles.btn, onClick: () => window.history.back() }, t.back),
            React.createElement("div", { className: classNames(styles.btn, styles.primary, { [styles.disabled]: !laserSource }), onClick: handleNext }, t.next))));
}
