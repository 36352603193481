var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo, useState } from 'react';
import { Checkbox, Modal, Segmented, Switch } from 'antd';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import changeWorkarea from 'app/svgedit/operations/changeWorkarea';
import localeHelper from 'helpers/locale-helper';
import RotaryIcons from 'app/icons/rotary/RotaryIcons';
import rotaryAxis from 'app/actions/canvas/rotary-axis';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { CHUCK_ROTARY_DIAMETER, getSupportInfo, RotaryType } from 'app/constants/add-on';
import styles from './RotarySettings.module.scss';
const RotarySettings = ({ onClose }) => {
    var _a, _b;
    const { topbar: { menu: tMenu }, global: tGlobal, rotary_settings: t, beambox: { document_panel: tDocu }, } = useI18n();
    const workarea = useMemo(() => beamboxPreference.read('workarea'), []);
    const supportInfo = useMemo(() => getSupportInfo(workarea), [workarea]);
    const [rotaryMode, setRotaryMode] = useState((_a = beamboxPreference.read('rotary_mode')) !== null && _a !== void 0 ? _a : 0);
    const [rotaryType, setRotaryType] = useState(beamboxPreference.read('rotary-type') || RotaryType.Roller);
    const [diameter, setDiaMeter] = useState((_b = beamboxPreference.read('rotary-chuck-obj-d')) !== null && _b !== void 0 ? _b : CHUCK_ROTARY_DIAMETER);
    const [extend, setExtend] = useState(Boolean(beamboxPreference.read('extend-rotary-workarea')));
    const [mirror, setMirror] = useState(Boolean(beamboxPreference.read('rotary-mirror')));
    const isInch = useMemo(() => storage.get('default-units') === 'inches', []);
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        const rotaryChanged = rotaryMode !== beamboxPreference.read('rotary_mode');
        const extendChanged = extend !== Boolean(beamboxPreference.read('extend-rotary-workarea'));
        beamboxPreference.write('rotary_mode', rotaryMode);
        beamboxPreference.write('rotary-type', rotaryType);
        if (rotaryType === RotaryType.Chuck)
            beamboxPreference.write('rotary-chuck-obj-d', diameter);
        if (supportInfo.rotary.mirror)
            beamboxPreference.write('rotary-mirror', mirror);
        if (supportInfo.rotary.extendWorkarea)
            beamboxPreference.write('extend-rotary-workarea', extend);
        if (rotaryChanged || extendChanged)
            changeWorkarea(workarea, { toggleModule: false });
        if (rotaryChanged)
            rotaryAxis.toggleDisplay();
    });
    const rotaryDisabled = rotaryMode === 0;
    const chuckOptionDisabled = rotaryDisabled || !supportInfo.rotary.chuck || rotaryType !== RotaryType.Chuck;
    return (React.createElement(Modal, { open: true, centered: true, title: tMenu.rotary_setup, onCancel: onClose, onOk: () => {
            handleSave();
            onClose();
        }, cancelText: tGlobal.cancel, okText: tGlobal.save },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.table },
                React.createElement("div", { className: styles.title },
                    React.createElement("label", { htmlFor: "rotary_mode" }, tDocu.rotary_mode)),
                React.createElement("div", { className: styles.control },
                    React.createElement(Switch, { id: "rotary_mode", className: styles.switch, checked: rotaryMode > 0, onChange: () => setRotaryMode((cur) => (cur > 0 ? 0 : 1)) })),
                React.createElement("div", { className: styles.title },
                    React.createElement("label", { htmlFor: "rotary_type" }, t.type)),
                React.createElement("div", { className: styles.control },
                    React.createElement(Segmented, { id: "rotary_type", disabled: rotaryDisabled || !supportInfo.rotary.chuck, value: supportInfo.rotary.chuck ? rotaryType : RotaryType.Roller, onChange: (val) => setRotaryType(val), options: [
                            {
                                label: (React.createElement("div", { className: styles.seg },
                                    React.createElement(RotaryIcons.Roller, null),
                                    React.createElement("div", null, "Roller"))),
                                value: RotaryType.Roller,
                            },
                            {
                                label: (React.createElement("div", { className: styles.seg },
                                    React.createElement(RotaryIcons.Chuck, null),
                                    React.createElement("div", null, localeHelper.isTwOrHk ? 'Chuck' : 'Coming Soon'))),
                                value: RotaryType.Chuck,
                                disabled: !localeHelper.isTwOrHk,
                            },
                        ] })),
                React.createElement("div", { className: styles.title },
                    React.createElement("label", { htmlFor: "object_diameter" }, t.object_diameter)),
                React.createElement("div", { className: styles.control },
                    React.createElement(UnitInput, { id: "object_diameter", disabled: chuckOptionDisabled, className: styles.input, value: diameter, min: 0, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, precision: isInch ? 4 : 2, onChange: setDiaMeter })),
                React.createElement("div", { className: styles.title },
                    React.createElement("label", { htmlFor: "circumference" }, t.circumference)),
                React.createElement("div", { className: styles.control },
                    React.createElement(UnitInput, { id: "circumference", disabled: chuckOptionDisabled, className: styles.input, value: diameter * Math.PI, min: 0, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, precision: isInch ? 6 : 4, onChange: (val) => setDiaMeter(val / Math.PI) })),
                (supportInfo.rotary.mirror || supportInfo.rotary.extendWorkarea) && (React.createElement("div", { className: styles.row },
                    supportInfo.rotary.mirror && (React.createElement(Checkbox, { id: "mirror", disabled: rotaryDisabled, checked: mirror, onChange: (e) => setMirror(e.target.checked) }, tDocu.mirror)),
                    supportInfo.rotary.extendWorkarea && (React.createElement(Checkbox, { id: "extend", disabled: rotaryDisabled, checked: extend, onChange: (e) => setExtend(e.target.checked) }, tDocu.extend_workarea))))))));
};
export default RotarySettings;
export const showRotarySettings = () => {
    if (!isIdExist('rotary-settings')) {
        addDialogComponent('rotary-settings', React.createElement(RotarySettings, { onClose: () => popDialogById('rotary-settings') }));
    }
};
