/* eslint-disable @typescript-eslint/no-shadow */
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import useI18n from 'helpers/useI18n';
import styles from './ConnectUsb.module.scss';
export default function ConnectUsb() {
    const { initialize: t } = useI18n();
    const { search } = useLocation();
    const model = useMemo(() => new URLSearchParams(search).get('model'), [search]);
    const renderInformation = {
        ado1: {
            title: 'Ador',
            steps: [
                t.connect_usb.turn_off_machine,
                t.connect_usb.tutorial1,
                t.connect_usb.turn_on_machine,
                t.connect_usb.wait_for_turning_on,
            ],
        },
        fhexa1: {
            title: 'HEXA',
            steps: [t.connect_usb.tutorial1, t.connect_usb.tutorial2],
        },
        fpm1: {
            title: 'Promark Series',
            steps: [t.connect_usb.tutorial1, t.connect_usb.connect_camera, t.connect_usb.tutorial2],
        },
        fbb2: {
            title: 'Beambox II',
            steps: [
                t.connect_usb.turn_off_machine,
                t.connect_usb.tutorial1,
                t.connect_usb.turn_on_machine,
                t.connect_usb.wait_for_turning_on,
            ],
        },
    };
    const handleNext = () => {
        const urlParams = new URLSearchParams({ model, usb: '1' });
        const queryString = urlParams.toString();
        window.location.hash = `#initialize/connect/connect-machine-ip?${queryString}`;
    };
    const renderStep = (model) => model ? (React.createElement("div", { className: classNames(styles.contents, styles.tutorial) },
        React.createElement("div", { className: styles.subtitle }, renderInformation[model].title),
        React.createElement("div", { className: styles.contents }, renderInformation[model].steps.map((step, index) => (React.createElement("div", { key: `usb-step-${index + 1}` },
            index + 1,
            ". ",
            step)))))) : null;
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles['top-bar'] }),
        React.createElement("div", { className: styles.btns },
            React.createElement("div", { className: styles.btn, onClick: () => window.history.back() }, t.back),
            React.createElement("div", { className: classNames(styles.btn, styles.primary), onClick: handleNext }, t.next)),
        React.createElement("div", { className: styles.main },
            React.createElement("div", { className: styles.image },
                React.createElement("div", { className: classNames(styles.circle, styles.c1) }),
                React.createElement("img", { src: "img/init-panel/icon-usb-cable.svg", draggable: "false" }),
                React.createElement("div", { className: classNames(styles.circle, styles.c2) })),
            React.createElement("div", { className: styles.text },
                React.createElement("div", { className: styles.title }, t.connect_usb.title),
                renderStep(model)))));
}
