import storage from 'implementations/storage';
import TopBarController from 'app/views/beambox/TopBar/contexts/TopBarController';
import { LaserType } from 'app/constants/promark-constants';
import { promarkModels } from 'app/actions/beambox/constant';
import promarkDataStore from './promark-data-store';
const defaultValue = {
    laserType: LaserType.Desktop,
    watt: 20,
};
export const getSerial = () => {
    var _a;
    const { model, serial } = (_a = TopBarController.getSelectedDevice()) !== null && _a !== void 0 ? _a : {};
    if (promarkModels.has(model))
        return serial;
    return storage.get('last-promark-serial') || 'no-serial';
};
export const getPromarkInfo = () => {
    const serial = getSerial();
    return promarkDataStore.get(serial, 'info') || defaultValue;
};
export const setPromarkInfo = (info) => {
    const serial = getSerial();
    if (serial)
        promarkDataStore.set(serial, 'info', info);
};
export default {
    getPromarkInfo,
    setPromarkInfo,
};
