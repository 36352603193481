const lang = {
    global: {
        cancel: 'Cancelar',
        back: 'Voltar',
        save: 'Salvar',
        ok: 'OK',
        stop: 'Parar',
    },
    general: {
        processing: 'Processando...',
        choose_folder: 'Escolher pasta',
    },
    buttons: {
        next: 'Próximo',
        back: 'Voltar',
        done: 'Concluído',
        back_to_beam_studio: 'Voltar para Beam Studio',
    },
    topbar: {
        untitled: 'Sem título',
        titles: {
            settings: 'Configurações',
        },
        export: 'Exportar',
        preview: 'PRÉ-VISUALIZAÇÃO',
        preview_title: 'Pré-visualização',
        preview_press_esc_to_stop: 'Pressione ESC para parar a pré-visualização da câmera.',
        curve_engrave: 'Curva 3D',
        task_preview: 'Visualização da trajetória',
        frame_task: 'Executando quadro',
        borderless: '(ABERTO EM BAIXO)',
        tag_names: {
            rect: 'Retângulo',
            ellipse: 'Elipse',
            path: 'Caminho',
            polygon: 'Polígono',
            image: 'Imagem',
            text: 'Texto',
            text_path: 'Texto em Caminho',
            pass_through_object: 'Objeto de Passagem',
            line: 'Linha',
            g: 'Grupo',
            multi_select: 'Múltipla seleção',
            use: 'Objeto Importado',
            svg: 'Objeto SVG',
            dxf: 'Objeto DXF',
            no_selection: 'Sem seleção',
        },
        alerts: {
            start_preview_timeout: '#803 O tempo limite ocorreu ao iniciar o modo de visualização. Reinicie seu computador ou o Beam Studio. Se este erro persistir, siga <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">este guia</a>.',
            fail_to_start_preview: '#803 Falha ao iniciar o modo de visualização. Reinicie seu computador ou o Beam Studio. Se este erro persistir, siga <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">este guia</a>.',
            fail_to_connect_with_camera: '#803 Falha ao conectar com a câmera da máquina. Reinicie seu computador ou o Beam Studio. Se este erro persistir, siga <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">este guia</a>.',
            add_content_first: 'Por favor, adicione objetos primeiro',
            headtype_mismatch: 'Módulo incorreto detectado. ',
            headtype_none: 'Módulo não detectado. ',
            headtype_unknown: 'Módulo desconhecido detectado. ',
            install_correct_headtype: 'Instale os módulos laser de diodo de 10W/20W corretos para habilitar o laser baixo para executar o quadro.',
            door_opened: 'Feche a tampa da porta para habilitar o laser baixo para executar o quadro.',
            fail_to_get_door_status: 'Certifique-se de que a tampa da porta esteja fechada para habilitar o laser baixo para executar o quadro.',
            QcleanScene: 'Quer apagar o desenho?<br/>Isso também apagará seu histórico de desfazer!',
            power_too_high: 'POTÊNCIA MUITO ALTA',
            power_too_high_msg: 'Usar potência laser mais baixa (abaixo de 70%) estenderá a vida útil do tubo laser.\nDigite "ENTENDI" para continuar.',
            power_too_high_confirm: 'ENTENDI',
            pwm_unavailable: 'O Modo de Profundidade requer a versão 4.3.4 / 5.3.4 ou superior do firmware. Gostaria de atualizar o firmware agora?',
            job_origin_unavailable: 'Configurar a origem do trabalho requer a versão de firmware 4.3.5 / 5.3.5 ou superior. Deseja atualizar o firmware agora?',
            job_origin_warning: 'Atualmente, você está usando a “posição atual” como ponto de partida. Certifique-se de mover a cabeça do laser para a posição correta para evitar colisões.',
        },
        hint: {
            polygon: 'Pressione as teclas + / - para aumentar / diminuir os lados.',
        },
        menu: {
            preferences: 'Preferências',
            hide: 'Ocultar Beam Studio',
            hideothers: 'Ocultar outros',
            service: 'Serviços',
            quit: 'Sair',
            window: 'Janela',
            minimize: 'Minimizar',
            close: 'Fechar janela',
            file: 'Arquivo',
            edit: 'Editar',
            help: 'Ajuda',
            open: 'Abrir',
            samples: 'Exemplos',
            example_files: 'Arquivos de Exemplo',
            material_test: 'Teste de Material',
            calibration: 'Calibrações',
            import_hello_beamo: 'Exemplo beamo',
            import_hello_beambox: 'Exemplo de Beambox',
            import_beambox_2_example: 'Exemplo de Beambox II',
            import_material_testing_old: 'Teste de gravação de material - Clássico',
            import_material_testing_simple_cut: 'Teste de corte de material - Simples',
            import_material_testing_cut: 'Teste de corte de material',
            import_material_testing_engrave: 'Teste de gravação de material',
            import_material_testing_line: 'Teste de linha de material',
            import_material_printing_test: 'Teste de impressão de material',
            import_ador_laser_example: 'Exemplo de Ador Laser',
            import_ador_printing_example_single: 'Exemplo de impressão Ador - Cor única',
            import_ador_printing_example_full: 'Exemplo de impressão Ador - Cores completas',
            import_acrylic_focus_probe: 'Sonda de foco em acrílico - 3mm',
            import_beambox_2_focus_probe: 'Sonda de Foco Beambox II - 3 mm',
            import_promark_example: 'Exemplo de Promark',
            import_hexa_example: 'Exemplo de HEXA',
            export_to: 'Exportar para...',
            export_flux_task: 'exportar tarefa FLUX',
            export_BVG: 'exportar BVG',
            export_SVG: 'exportar SVG',
            export_PNG: 'exportar PNG',
            export_JPG: 'exportar JPG',
            save_scene: 'Salvar',
            save_as: 'Salvar como...',
            save_to_cloud: 'Salvar na nuvem',
            about_beam_studio: 'Sobre o Beam Studio',
            undo: 'Desfazer',
            redo: 'Refazer',
            cut: 'Recortar',
            copy: 'Copiar',
            paste: 'Colar',
            paste_in_place: 'Colar no lugar',
            group: 'Agrupar',
            ungroup: 'Desagrupar',
            delete: 'Excluir',
            duplicate: 'Duplicar',
            offset: 'Deslocamento',
            scale: 'Escala',
            rotate: 'Girar',
            reset: 'Redefinir',
            align_center: 'Alinhar ao centro',
            photo_edit: 'Imagem',
            svg_edit: 'SVG',
            path: 'Caminho',
            decompose_path: 'Descompor',
            object: 'Objeto',
            layer_setting: 'Camada',
            layer_color_config: 'Configurações de Cor',
            image_sharpen: 'Nitidez',
            image_crop: 'Recortar',
            image_invert: 'Inverter',
            image_stamp: 'Bisel',
            image_vectorize: 'Vetorizar',
            image_curve: 'Curva',
            align_to_edges: 'Alinhar aos vértices',
            document_setting: 'Configurações do documento',
            document_setting_short: 'Documento',
            rotary_setup: 'Configuração Rotativa',
            clear_scene: 'Novos arquivos',
            machines: 'Máquinas',
            add_new_machine: 'Configuração da máquina',
            help_center: 'Central de ajuda',
            show_gesture_tutorial: 'Tutorial de gestos',
            show_start_tutorial: 'Mostrar tutorial inicial',
            show_ui_intro: 'Mostrar introdução da interface',
            questionnaire: 'Questionário de Feedback',
            change_logs: 'Registro de Alterações',
            contact: 'Contate-nos',
            tutorial: 'Iniciar Tutorial de Impressão da Família Delta',
            design_market: 'Mercado de Design',
            forum: 'Fórum da Comunidade',
            software_update: 'Atualização de Software',
            bug_report: 'Relatório de Erros',
            dashboard: 'Painel',
            machine_info: 'Informações da Máquina',
            network_testing: 'Testar Configurações de Rede',
            commands: 'Comandos',
            update_firmware: 'Atualizar firmware',
            using_beam_studio_api: 'Usando API do Beam Studio',
            set_as_default: 'Definir como padrão',
            calibrate_beambox_camera: 'Calibrar câmera',
            calibrate_printer_module: 'Calibrar módulo de impressão',
            calibrate_ir_module: 'Calibrar módulo infravermelho',
            calibrate_beambox_camera_borderless: 'Calibrar câmera (abrir parte inferior)',
            calibrate_diode_module: 'Calibrar módulo de laser de diodo',
            calibrate_camera_advanced: 'Calibrar câmera (Avançado)',
            manage_account: 'Gerenciar minha conta',
            sign_in: 'Entrar',
            sign_out: 'Sair',
            account: 'Conta',
            my_account: 'Minha Conta',
            download_log: 'Baixar Registros',
            download_log_canceled: 'Download de registros cancelado',
            download_log_error: 'Ocorreu um erro desconhecido, tente novamente mais tarde',
            keyboard_shortcuts: 'Atalhos de teclado',
            log: {
                network: 'Rede',
                hardware: 'Hardware',
                discover: 'Descobrir',
                usb: 'USB',
                usblist: 'Lista USB',
                camera: 'Câmera',
                cloud: 'Nuvem',
                player: 'Reprodutor',
                robot: 'Robô',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Visualizar',
            zoom_in: 'Ampliar',
            zoom_out: 'Reduzir',
            fit_to_window: 'Ajustar à Janela',
            zoom_with_window: 'Ajuste Automático à Janela',
            borderless_mode: 'Modo sem bordas',
            show_grids: 'Mostrar grades',
            show_rulers: 'Mostrar réguas',
            show_layer_color: 'Usar cor da camada',
            anti_aliasing: 'Suavização de serrilhado',
            disassemble_use: 'Desmontar',
            about: 'Sobre o Beam Studio',
            switch_to_beta: 'Mudar para a versão beta',
            switch_to_latest: 'Mudar para a libertação estável',
            reload_app: 'Aplicação de recarga',
            recent: 'Aberto Recente',
            update: 'Verificar atualização',
            dev_tool: 'Ferramenta de depuração',
            camera_calibration_data: 'Dados de calibração da câmera',
            upload_data: 'Enviar dados',
            download_data: 'Baixar dados',
            tools: {
                title: 'Ferramentas',
                material_test_generator: 'Gerador de Teste de Material',
                code_generator: 'Gerador de código',
                box_generator: 'Gerador de Caixas',
            },
            follow_us: 'Siga-nos',
        },
        select_machine: 'Selecionar uma máquina',
    },
    support: {
        no_webgl: 'WebGL não é suportado. Por favor, use outros dispositivos.',
        no_vcredist: 'Instale o Visual C++ Redistributable 2015<br/>Que pode ser baixado em flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Ocorreu um erro desconhecido. Reinicie o Beam Studio e a máquina.',
        OPERATION_ERROR: '[OE] Ocorreu um conflito de status, tente novamente a ação.',
        SUBSYSTEM_ERROR: '[SE] Erro ao executar a tarefa no firmware da máquina. Reinicie a máquina.',
        UNKNOWN_COMMAND: '[UC] Atualize o firmware do dispositivo',
    },
    device_selection: {
        no_beambox: '#801 Não conseguimos encontrar sua máquina na rede.\nSiga o <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guia</a> para resolver o problema de conexão.',
        no_device_web: "#801 Verifique o estado da sua máquina ou clique em 'Configuração da máquina' abaixo para configurar a máquina.",
        select_usb_device: 'Selecionar dispositivo USB',
    },
    update: {
        update: 'Atualizar',
        release_note: 'Notas da versão:',
        firmware: {
            caption: 'Uma atualização de firmware para a máquina está disponível',
            message_pattern_1: '"%s" está pronto para atualização de firmware.',
            message_pattern_2: '%s Firmware v%s está agora disponível - Você tem v%s.',
            latest_firmware: {
                caption: 'Atualização de firmware da máquina',
                message: 'Você tem o firmware mais recente da máquina',
                still_update: 'ATUALIZAR',
                cant_get_latest: 'Não foi possível obter informações sobre o firmware mais recente.',
            },
            confirm: 'ENVIAR',
            upload_file: 'Carregar firmware (*.bin / *.fxfw)',
            update_success: 'Firmware atualizado com sucesso',
            update_fail: '#822 Falha na atualização',
            too_old_for_web: 'A versão atual do firmware da sua máquina é v%s.\nSe desejar usar a versão online do Beam Studio, atualize o firmware da máquina para a versão mais recente.',
            force_update_message: '#814 Atualize sua máquina para a versão mais recente do firmware.',
            firmware_too_old_update_by_sdcard: 'A versão do firmware é muito antiga. Atualize o firmware usando um cartão SD.',
        },
        software: {
            checking: 'Verificando atualizações',
            switch_version: 'Mudar versão',
            check_update: 'Verificar atualizações',
            caption: 'Uma atualização de software para o Beam Studio está disponível',
            downloading: 'Baixando atualizações em segundo plano, você pode clicar em "OK" para continuar seu trabalho.',
            install_or_not: 'está pronto para atualização. Deseja reiniciar e atualizar agora?',
            switch_or_not: 'está pronto para atualizar. Gostaria de reiniciar e atualizar agora?',
            available_update: 'O Beam Studio v%s está disponível agora. Você tem a v%s. Gostaria de baixar a atualização?',
            available_switch: 'O Beam Studio v%s está disponível agora. Você tem a v%s. Gostaria de mudar para esta versão?',
            not_found: 'Você está usando a versão mais recente do Beam Studio.',
            no_response: 'Falha ao conectar ao servidor, verifique a configuração de rede.',
            switch_version_not_found: 'Versão para atualização não encontrada.',
            yes: 'Sim',
            no: 'Não',
            update_for_ador: 'A versão de software atual %s é incompatível, baixe a versão mais recente do Beam Studio para Ador.',
        },
        updating: 'Atualizando...',
        skip: 'Pular esta versão',
        preparing: 'Preparando...',
        later: 'MAIS TARDE',
        download: 'ATUALIZAÇÃO ONLINE',
        cannot_reach_internet: '#823 Servidor inacessível<br/>Verifique a conexão com a Internet',
        install: 'INSTALAR',
        upload: 'ENVIAR',
    },
    topmenu: {
        version: 'Versão',
        credit: 'Beam Studio é possível graças ao projeto de código aberto <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> e outro <a target="_blank" href="https://flux3dp.com/credits/">software de código aberto</a>.',
        ok: 'OK',
        file: {
            label: 'Arquivo',
            import: 'Importar',
            save_fcode: 'Exportar Tarefa FLUX',
            save_scene: 'Salvar Cena',
            save_svg: 'Exportar SVG',
            save_png: 'Exportar PNG',
            save_jpg: 'Exportar JPG',
            converting: 'Convertendo para Imagem...',
            all_files: 'Todos os Arquivos',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Cena do Beam Studio',
            fcode_files: 'Código FLUX',
            clear_recent: 'Limpar Recentes',
            path_not_exit: 'Este caminho não parece mais existir no disco.',
        },
        device: {
            download_log_canceled: 'Download de log cancelado',
            download_log_error: 'Ocorreu um erro desconhecido, tente novamente mais tarde',
            log: {
                usblist: 'Lista USB',
            },
            network_test: 'Testar Rede',
        },
    },
    initialize: {
        next: 'Próximo',
        start: 'Iniciar',
        skip: 'Pular',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        connect: 'Conectar',
        back: 'Voltar',
        retry: 'Tentar novamente',
        no_machine: 'Não tenho uma máquina agora.',
        select_language: 'Selecionar Idioma',
        select_machine_type: 'Selecione o Tipo da Sua Máquina',
        select_beambox: 'Selecione o seu Beambox',
        select_connection_type: 'Como deseja se conectar?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Rede com Fio',
            ether2ether: 'Conexão Direta',
            usb: 'Conexão USB',
        },
        connect_wifi: {
            title: 'Conectando ao Wi-Fi',
            tutorial1: '1. Vá para o Painel de Toque > Clique em "Rede" > "Conectar ao Wi-Fi".',
            tutorial1_ador: '1. Vá para o Painel de Toque > Clique em "MÁQUINA" > Clique em "Rede" > "Conectar ao Wi-Fi".',
            tutorial2: '2. Selecione e conecte-se à rede Wi-Fi de sua preferência.',
            what_if_1: 'O que fazer se não vir a minha rede Wi-Fi?',
            what_if_1_content: '1. O modo de criptografia Wi-Fi deve ser WPA2 ou sem senha.\n2. O modo de criptografia pode ser definido na interface de administração do roteador Wi-Fi. Se o roteador não suportar WPA2 e você precisar de ajuda para escolher o roteador certo, entre em contato com o Suporte FLUX.',
            what_if_2: 'O que fazer se não vir nenhuma rede Wi-Fi?',
            what_if_2_content: '1. Certifique-se de que o adaptador Wi-Fi esteja totalmente conectado.\n2. Se não houver endereço MAC da rede sem fio na tela de toque, entre em contato com o Suporte FLUX.\n3. O canal Wi-Fi deve ser 2.4Ghz (5Ghz não é suportado).',
        },
        connect_wired: {
            title: 'Conectando à rede com fio',
            tutorial1: '1. Conecte a máquina com o seu roteador.',
            tutorial2: '2. Pressione "Rede" para obter o IP da rede com fio.',
            tutorial2_ador: '2. Pressione "MÁQUINA" > "Rede" para obter o IP da rede com fio.',
            what_if_1: 'O que fazer se o IP estiver vazio?',
            what_if_1_content: '1. Certifique-se de que o cabo Ethernet esteja totalmente conectado.\n2. Se não houver endereço MAC da rede com fio na tela de toque, entre em contato com o Suporte FLUX.',
            what_if_2: 'O que fazer se o IP começar com 169?',
            what_if_2_content: '1. Se o endereço IP começar com 169.254, deve ser um problema de configuração DHCP, entre em contato com seu ISP (provedor de serviços de internet) para mais assistência.\n2. Se o seu computador se conectar à internet diretamente usando PPPoE, mude para usar o roteador para conectar usando PPPoE e habilite o recurso DHCP no roteador.',
        },
        connect_ethernet: {
            title: 'Conexão Direta',
            tutorial1: '1. Conecte a máquina ao seu computador com um cabo Ethernet.',
            tutorial2_1: '2. Siga',
            tutorial2_a_text: 'este guia',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' para configurar o seu computador como um roteador.',
            tutorial3: '3. Clique em Avançar.',
        },
        connect_usb: {
            title: 'Conexão USB',
            title_sub: ' (HEXA e Ador Apenas)',
            tutorial1: 'Conecte a máquina ao seu computador com um cabo USB.',
            tutorial2: "Clique em 'Próximo'.",
            turn_off_machine: 'Desligue a máquina.',
            turn_on_machine: 'Ligue a máquina.',
            wait_for_turning_on: 'Clique em "Próximo" após o processo de inicialização estar concluído e você ter entrado na tela principal.',
            connect_camera: 'Conecte a câmera da máquina ao seu computador com um cabo USB.',
        },
        connect_machine_ip: {
            check_usb: 'Verificar conexão USB',
            enter_ip: 'Insira o IP da sua máquina',
            check_ip: 'Verificando disponibilidade de IP',
            invalid_ip: 'IP inválido: ',
            invalid_format: 'Formato inválido',
            starts_with_169254: 'Começa com 169.254',
            unreachable: 'IP inacessível',
            check_connection: 'Verificando conexão da máquina',
            check_firmware: 'Verificando versão do firmware',
            check_camera: 'Verificando disponibilidade da câmera',
            retry: 'Tentar novamente',
            succeeded_message: 'Conectado com sucesso 🎉',
            finish_setting: 'Comece a criar!',
            check_swiftray_connection: 'Verificando conexão com o servidor',
            check_swiftray_connection_unreachable: 'servidor inacessível',
            promark_hint: 'Se você não conseguir se conectar repetidamente via USB, consulte o <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">artigo do Centro de Ajuda</a>.',
            alert: {
                swiftray_connection_error: 'Não foi possível conectar ao servidor. Reinicie o Beam Studio e tente novamente.',
            },
        },
        connecting: 'Conectando...',
        setting_completed: {
            start: 'Começar',
            great: 'Bem-vindo ao Beam Studio',
            setup_later: 'Você pode configurar sua máquina a qualquer momento em Barra de título > "Máquinas" > "Configuração de máquina"',
            back: 'Voltar',
            ok: 'COMECE A CRIAR',
        },
        promark: {
            select_laser_source: 'Selecione o seu Promark',
            select_workarea: 'Selecione a Área de Trabalho',
            settings: 'Configurações do Promark',
            qc_instructions: 'Preencha os parâmetros localizados no verso do cartão "QC Pass"',
            configuration_confirmation: 'Isso garante que seu Promark esteja configurado corretamente para máxima eficiência e precisão.',
            or_complete_later: `Ou, pule esta etapa e conclua as configurações do Promark mais tarde em:
    Máquinas > "Nome do Promark" > Configurações do Promark`,
        },
    },
    error_pages: {
        screen_size: 'Observe que o Beam Studio pode não funcionar idealmente no seu dispositivo. Para uma experiência ideal, o dispositivo deve ter uma largura de tela de pelo menos 1024 pixels.',
    },
    menu: {
        mm: 'mm',
        inches: 'Polegadas',
    },
    settings: {
        on: 'Ligado',
        off: 'Desligado',
        low: 'Baixo',
        normal: 'Alto',
        high: 'Alto',
        caption: 'Configurações',
        tabs: {
            general: 'Geral',
            device: 'Máquina',
        },
        ip: 'Endereço IP da Máquina',
        guess_poke: 'Procurar endereço IP da máquina',
        auto_connect: 'Selecionar automaticamente a única máquina',
        wrong_ip_format: 'Formatos de IP incorretos',
        default_machine: 'Máquina padrão',
        default_machine_button: 'Selecionar',
        remove_default_machine_button: 'Remover',
        confirm_remove_default: 'A máquina padrão será removida.',
        reset: 'Redefinir Beam Studio',
        reset_now: 'Redefinir Beam Studio agora',
        confirm_reset: 'Confirmar redefinição do Beam Studio?',
        language: 'Idioma',
        notifications: 'Notificações na área de trabalho',
        check_updates: 'Verificação automática de atualizações',
        autosave_enabled: 'Salvamento automático ativado',
        autosave_path: 'Local para salvamento automático',
        autosave_interval: 'Salvar a cada',
        autosave_number: 'Número de salvamentos automáticos',
        autosave_path_not_correct: 'Caminho especificado não encontrado.',
        preview_movement_speed: 'Velocidade de visualização do movimento',
        medium: 'Médio',
        default_units: 'Unidades padrão',
        default_font_family: 'Fonte padrão',
        default_font_style: 'Estilo de fonte padrão',
        fast_gradient: 'Otimização de velocidade',
        engraving_direction: 'Direção',
        top_down: 'De cima para baixo',
        bottom_up: 'De baixo para cima',
        vector_speed_constraint: 'Limite de velocidade',
        loop_compensation: 'Compensação de loop',
        blade_radius: 'Raio da lâmina',
        blade_precut_switch: 'Precorte da lâmina',
        blade_precut_position: 'Posição de pré-corte',
        default_beambox_model: 'Configuração de documento padrão',
        guides_origin: 'Origem das guias',
        guides: 'Guias',
        image_downsampling: 'Qualidade da pré-visualização de bitmap',
        anti_aliasing: 'Suavização de serrilhado',
        continuous_drawing: 'Desenho contínuo',
        trace_output: 'Saída de rastreamento de imagem',
        single_object: 'Objeto único',
        grouped_objects: 'Objetos agrupados',
        simplify_clipper_path: 'Otimizar caminho calculado',
        enable_low_speed: 'Ativar movimento de baixa velocidade',
        enable_custom_backlash: 'Ativar compensação de folga personalizada',
        calculation_optimization: 'Aceleração do cálculo de caminho',
        auto_switch_tab: 'Troca automática entre painel de camadas e objetos',
        custom_preview_height: 'Altura de visualização personalizada',
        mask: 'Máscara da área de trabalho',
        text_path_calc_optimization: 'Otimização de cálculo de caminho de texto',
        font_substitute: 'Substituir caracteres não suportados',
        font_convert: 'Conversor de Texto para Trajetória',
        default_borderless_mode: 'Abrir sem borda por padrão',
        default_enable_autofocus_module: 'Foco automático padrão',
        default_enable_diode_module: 'Laser de diodo padrão',
        diode_offset: 'Compensação do laser de diodo',
        autofocus_offset: 'Compensação de foco automático',
        diode_one_way_engraving: 'Gravação unidirecional com laser de diodo',
        diode_two_way_warning: 'A emissão bidirecional de luz é mais rápida e provavelmente gera alguma imprecisão na posição da gravação a laser. Recomenda-se testar primeiro.',
        share_with_flux: 'Compartilhar análises do Beam Studio',
        none: 'Nenhum',
        close: 'Fechar',
        enabled: 'Habilitado',
        disabled: 'Desabilitado',
        cancel: 'Cancelar',
        done: 'Aplicar',
        module_offset_10w: 'Compensação de laser de diodo de 10W',
        module_offset_20w: 'Deslocamento do laser de diodo de 20W',
        module_offset_printer: 'Deslocamento da impressora',
        module_offset_2w_ir: 'Deslocamento do laser infravermelho de 2W',
        printer_advanced_mode: 'Modo avançado da impressora',
        default_laser_module: 'Módulo laser padrão',
        low_laser_for_preview: 'Laser para Executando quadro',
        groups: {
            general: 'Geral',
            update: 'Atualizações de software',
            connection: 'Conexão',
            autosave: 'Salvamento automático',
            camera: 'Câmera',
            editor: 'Editor',
            engraving: 'Gravação (digitalização)',
            path: 'Vetor (Contornos)',
            mask: 'Máscara de área de trabalho',
            text_to_path: 'Texto',
            modules: 'Complementos',
            ador_modules: 'Módulos Ador',
            privacy: 'Privacidade',
        },
        notification_on: 'Ligado',
        notification_off: 'Desligado',
        update_latest: 'Última versão',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
            calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
        },
    },
    beambox: {
        tag: {
            g: 'Grupo',
            use: 'Importar SVG',
            image: 'Imagem',
            text: 'Texto',
        },
        context_menu: {
            cut: 'Recortar',
            copy: 'Copiar',
            paste: 'Colar',
            paste_in_place: 'Colar no lugar',
            duplicate: 'Duplicar',
            delete: 'Excluir',
            group: 'Agrupar',
            ungroup: 'Desagrupar',
            move_front: 'Trazer para frente',
            move_up: 'Mover para frente',
            move_down: 'Mover para trás',
            move_back: 'Enviar para trás',
        },
        popup: {
            select_import_method: 'Selecionar estilo de camadas:',
            select_import_module: 'Selecionar Módulo:',
            touchpad: 'TouchPad',
            mouse: 'Mouse',
            layer_by_layer: 'Camada',
            layer_by_color: 'Cor',
            nolayer: 'Camada única',
            loading_image: 'Carregando imagem, aguarde...',
            no_support_text: 'O Beam Studio não suporta a tag de texto atualmente. Por favor, converta o texto em caminho antes de importar.',
            speed_too_high_lower_the_quality: 'Usar velocidade muito alta nesta resolução pode resultar em menor qualidade de gravação sombreada.',
            both_power_and_speed_too_high: 'Usar menor potência do laser estenderá a vida útil do tubo de laser. Além disso, velocidade muito alta nesta resolução pode resultar em menor qualidade de gravação sombreada.',
            too_fast_for_path: 'Usar velocidade muito alta em camadas contendo objetos de caminho pode resultar em menor precisão ao cortar. Não recomendamos usar velocidade superior a %(limit)s ao cortar.',
            too_fast_for_path_and_constrain: 'As seguintes camadas: %(layers)s \ncontêm objetos de caminho vetorial e têm velocidade superior a %(limit)s. A velocidade de corte dos objetos de caminho vetorial será limitada a %(limit)s. Você pode remover este limite nas Configurações de Preferências.',
            should_update_firmware_to_continue: '#814 Seu firmware não suporta esta versão do Beam Studio. Atualize o firmware para continuar. (Menu> Máquina> [Sua Máquina]> Atualizar Firmware)',
            recommend_downgrade_software: 'Detectamos uma versão de firmware mais antiga. Estamos abordando ativamente problemas de compatibilidade, mas por enquanto recomendamos reverter para <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'Detectamos uma versão de firmware mais antiga. Estamos abordando ativamente problemas de compatibilidade, mas por enquanto recomendamos atualizar para o firmware mais recente.',
            still_continue: 'Continuar',
            more_than_two_object: 'Objetos demais. Suporte apenas para 2 objetos',
            not_support_object_type: 'Tipo de objeto não suportado',
            select_first: 'Selecione um objeto primeiro.',
            select_at_least_two: 'Selecione pelo menos dois objetos para continuar',
            import_file_contain_invalid_path: '#808 O arquivo SVG importado contém caminho de imagem inválido. Certifique-se de que todas as imagens existam ou incorpore a imagem no arquivo',
            import_file_error_ask_for_upload: 'Falha ao importar arquivo SVG. Deseja enviar o arquivo à equipe de desenvolvimento para relatar erros?',
            upload_file_too_large: '#819 O arquivo é muito grande para upload.',
            successfully_uploaded: 'Upload de arquivo bem-sucedido.',
            upload_failed: '#819 Falha no upload do arquivo.',
            or_turn_off_borderless_mode: ' Ou desative o modo Open Bottom.',
            svg_1_1_waring: 'A versão deste arquivo SVG é v 1.1, pode haver problemas potenciais de incompatibilidade.',
            svg_image_path_waring: 'Este arquivo SVG contém <image> carregando do caminho do arquivo. Isso pode causar falhas ao carregar. \nPara evitar esse risco, use a imagem incorporada ao exportar SVG.',
            dxf_version_waring: 'A versão deste arquivo DXF não é 2013, pode haver problemas potenciais de incompatibilidade.',
            dont_show_again: 'Não mostrar novamente.',
            convert_to_path_fail: 'Falha ao converter para caminho.',
            save_unsave_changed: 'Você quer salvar as alterações não salvas?',
            dxf_bounding_box_size_over: 'O tamanho do desenho está fora da área de trabalho. Por favor, mova seu desenho mais perto da origem no seu software CAD, ou certifique-se de que a unidade está configurada corretamente.',
            progress: {
                uploading: 'Enviando',
                calculating: 'Calculando',
            },
            backend_connect_failed_ask_to_upload: '#802 Erros continuam ocorrendo ao tentar conectar ao backend. Deseja enviar seu registro de relatório de erros?',
            backend_error_hint: 'Recursos podem não estar funcionando corretamente devido a um erro no backend.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Erro ao converter PDF para SVG:',
                error_pdf2svg_not_found: '#825 Erro: Comando pdf2svg não encontrado. Por favor, instale pdf2svg com o seu gerenciador de pacotes (por exemplo, "yum install pdf2svg" ou "apt-get install pdf2svg").',
            },
            ungroup_use: 'Isso irá desagrupar DXF ou SVG importados. Como o arquivo pode conter um grande número de elementos, pode demorar para desagrupar. Tem certeza de que deseja continuar?',
            vectorize_shading_image: 'Imagens com gradiente levam mais tempo para traçar e são propensas a ruído. Por favor, desative o gradiente da imagem antes de executar.',
            change_workarea_before_preview: 'A área de trabalho de %s não corresponde à área de trabalho atualmente definida. Deseja alterar a área de trabalho atual?',
            bug_report: 'Relatório de erros',
            sentry: {
                title: 'Vamos melhorar o Beam Studio juntos',
                message: 'Você concorda em enviar automaticamente informações relevantes para a equipe de desenvolvimento quando ocorrerem erros?',
            },
            questionnaire: {
                caption: 'Ajude-nos a responder o questionário',
                message: 'Ajude-nos a responder o questionário para melhorar o Beam Studio.',
                unable_to_get_url: 'Falha ao obter o link para o questionário pela internet. Confirme sua conexão com a internet.',
                no_questionnaire_available: 'Não há questionário disponível agora.',
            },
            facebook_group_invitation: {
                title: 'Junte-se ao grupo oficial de usuários FLUX',
                message: 'Junte-se ao nosso grupo oficial no Facebook para conectar-se com outros usuários de laser FLUX, discutir lasers FLUX, compartilhar trabalhos a laser e ficar por dentro das últimas atualizações dos nossos produtos. Mal podemos esperar para vê-lo por lá!',
                join_now: 'Conte comigo',
                later: 'Talvez mais tarde',
                already_joined: 'Já entrei',
                dont_show_again: 'Não mostrar novamente',
            },
            ai_credit: {
                relogin_to_use: 'Por favor, faça login novamente para usar esta função.',
                insufficient_credit: 'Você está sem crédito',
                insufficient_credit_msg: 'Você não pode usar o %s. Vá para o centro de membros e compre Créditos de IA.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Ir',
            },
            text_to_path: {
                caption: 'Conversor de Texto para Trajetória 2.0',
                message: 'O Beam Studio agora apresenta um novo conversor de texto para trajetória (Conversor 2.0), que produz resultados mais confiáveis! Gostaria de mudar para ele agora? \nVocê também pode ajustar essa configuração posteriormente na seção "Conversor de Texto para Trajetória" nas preferências.',
            },
            auto_switch_tab: {
                title: 'Alternância Automática Entre o Painel de Camadas e Objetos',
                message: 'Uma nova opção para alternar automaticamente entre o painel de Camadas e o painel de Objetos foi adicionada às Preferências. Esta opção está desativada por padrão. Gostaria de ativar a troca automática agora? <br/>Você pode alterar esta configuração a qualquer momento nas Preferências.',
            },
        },
        zoom_block: {
            fit_to_window: 'Ajustar à janela',
        },
        time_est_button: {
            calculate: 'Estimar tempo',
            estimate_time: 'Tempo estimado:',
        },
        left_panel: {
            unpreviewable_area: 'Área cega',
            diode_blind_area: 'Área cega do complemento híbrido a laser',
            borderless_blind_area: 'Área não gravável',
            borderless_preview: 'Visualização da câmera no modo de fundo aberto',
            rectangle: 'Retângulo',
            ellipse: 'Elipse',
            line: 'Linha',
            image: 'Imagem',
            text: 'Texto',
            label: {
                cursor: 'Selecionar',
                photo: 'Imagem',
                text: 'Texto',
                line: 'Linha',
                rect: 'Retângulo',
                oval: 'Elipse',
                polygon: 'Polígono',
                pen: 'Caneta',
                shapes: 'Formas',
                array: 'Matriz',
                preview: 'Pré-visualização da Câmera',
                trace: 'Traçar Imagem',
                end_preview: 'Terminar Pré-visualização',
                clear_preview: 'Limpar Pré-visualização',
                choose_camera: 'Câmera',
                live_feed: 'Transmissão ao vivo',
                adjust_height: 'Ajustar Altura',
                qr_code: 'Código QR',
                boxgen: 'Boxgen',
                my_cloud: 'Minha nuvem',
                pass_through: 'Passar Por',
                curve_engraving: {
                    title: 'Curva 3D',
                    exit: 'Sair',
                    select_area: 'Selecionar área',
                    preview_3d_curve: 'Visualizar curva 3D',
                    clear_area: 'Limpar área selecionada',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Camadas',
                objects: 'Objetos',
                path_edit: 'Editar Caminho',
            },
            layer_panel: {
                layer1: 'Camada 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Gravação',
                layer_cutting: 'Corte',
                current_layer: 'Camada Atual',
                move_elems_to: 'Mover elementos para:',
                notification: {
                    dupeLayerName: 'Já existe uma camada com esse nome!',
                    newName: 'NOVO NOME',
                    enterUniqueLayerName: 'Insira um nome de camada exclusivo',
                    enterNewLayerName: 'Insira o novo nome da camada',
                    layerHasThatName: 'A camada já tem esse nome',
                    QmoveElemsToLayer: 'Mover os elementos selecionados para a camada "%s"?',
                    moveElemFromPrintingLayerTitle: 'Mover o elemento selecionado para %s e convertê-lo em elemento a laser?',
                    moveElemFromPrintingLayerMsg: 'Observe que se concluir esta operação, as configurações de cor do elemento selecionado serão removidas e definidas de acordo com %s.',
                    moveElemToPrintingLayerTitle: 'Mover o elemento selecionado para %s e convertê-lo em elemento de impressão?',
                    moveElemToPrintingLayerMsg: 'Observe que se concluir esta operação, as configurações do elemento selecionado serão removidas e definidas de acordo com %s.',
                    splitColorTitle: 'Deseja expandir a camada selecionada em camadas CMYK?',
                    splitColorMsg: 'Observe que se continuar com esta operação, não poderá voltar às camadas de cor originais.',
                    mergeLaserLayerToPrintingLayerTitle: 'Deseja mesclar essas camadas em uma camada de Impressão?',
                    mergeLaserLayerToPrintingLayerMsg: 'Observe que se concluir esta operação, as configurações da camada a laser serão removidas e definidas de acordo com a camada atual.',
                    mergePrintingLayerToLaserLayerTitle: 'Deseja mesclar essas camadas em uma camada Laser?',
                    mergePrintingLayerToLaserLayerMsg: 'Observe que se concluir esta operação, as configurações de cor da camada de impressão serão removidas e definidas de acordo com a camada atual.',
                },
                layers: {
                    layer: 'Camada',
                    layers: 'Camadas',
                    del: 'Excluir camada',
                    move_down: 'Mover camada para baixo',
                    new: 'Nova camada',
                    rename: 'Renomear camada',
                    move_up: 'Mover camada para cima',
                    dupe: 'Duplicar camada',
                    lock: 'Bloquear camada',
                    unlock: 'Desbloquear',
                    merge_down: 'Mesclar com camada inferior',
                    merge_all: 'Mesclar todas',
                    merge_selected: 'Mesclar camadas selecionadas',
                    move_elems_to: 'Mover elementos para:',
                    move_selected: 'Mover elementos selecionados para uma camada diferente',
                    switchToFullColor: 'Mudar para camada de cores completas',
                    switchToSingleColor: 'Mudar para camada de cor única',
                    splitFullColor: 'Expandir Camada',
                    fullColor: 'Cores completas',
                },
            },
            laser_panel: {
                preset_setting: 'Configurações de Parâmetros (%s)',
                multi_layer: 'Várias Camadas',
                parameters: 'Parâmetros',
                strength: 'Potência',
                pwm_advanced_setting: 'Configurações de Potência do Modo de Profundidade',
                pwm_advanced_desc: 'Defina uma potência mínima para o modo de profundidade.',
                pwm_advanced_hint: 'Esta configuração aplica-se especificamente a imagens de gradiente ao usar o modo de profundidade.',
                low_power_warning: 'Potência laser mais baixa (abaixo de 10%) pode não emitir a luz do laser.',
                speed: 'Velocidade',
                speed_contrain_warning: 'A velocidade de corte dos objetos de caminho vetorial será limitada a %(limit)s. Você pode remover este limite nas Configurações de Preferências.',
                low_speed_warning: 'Baixa velocidade pode causar queima do material.',
                promark_speed_desc: 'Os parâmetros de velocidade não se aplicam a imagens com gradientes.',
                repeat: 'Contagem de Passes',
                advanced: 'Avançado',
                lower_focus: 'Abaixar Foco',
                by: 'Em',
                stepwise_focusing: 'Foco por Etapas',
                single_color: 'Cor Única',
                lower_focus_desc: 'Abaixar a altura do foco por uma distância específica após focar para melhorar o desempenho de corte.',
                stepwise_focusing_desc: 'Abaixar gradualmente a distância do foco com base na altura do objeto durante cada contagem de passes.',
                single_color_desc: 'Apenas aplicável à camada de cor completa e não pode ser usado com a camada de cor única expandida.',
                focus_adjustment: 'Ajuste de Foco',
                height: 'Altura do Objeto',
                z_step: 'Passo Z',
                diode: 'Laser de Diodo',
                backlash: 'Folga',
                ink_saturation: 'Saturação',
                print_multipass: 'Multi-passagem',
                white_ink: 'Tinta Branca',
                white_ink_settings: 'Configurações de tinta branca',
                color_adjustment: 'Ajuste de canal',
                color_adjustment_short: 'Canal',
                halftone: 'Meio-tom',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Intensidade',
                times: 'vezes',
                cut: 'Cortar',
                engrave: 'Gravar',
                more: 'Gerenciar',
                apply: 'Aplicar',
                custom_preset: 'Personalizado',
                various_preset: 'Várias Predefinições',
                module: 'Módulo',
                pulse_width: 'Largura de Pulso',
                frequency: 'Frequência',
                fill_setting: 'Configuração de Preenchimento',
                fill_interval: 'Intervalo de Preenchimento',
                fill_angle: 'Ângulo de Preenchimento',
                bi_directional: 'Bidirecional',
                cross_hatch: 'Hachura Cruzada',
                dottingTime: 'Tempo de pontuação',
                gradient_only: 'Apenas para imagens em gradiente',
                filled_path_only: 'Apenas para caminhos de preenchimento',
                slider: {
                    regular: 'Regular',
                    low: 'Baixo',
                    very_low: 'Muito baixo',
                    high: 'Alto',
                    very_high: 'Muito alto',
                    slow: 'Lento',
                    very_slow: 'Muito lento',
                    fast: 'Rápido',
                    very_fast: 'Muito rápido',
                },
                dropdown: {
                    parameters: 'Predefinições',
                    save: 'Adicionar parâmetros atuais',
                    mm: {
                        wood_3mm_cutting: 'Madeira - Corte 3mm',
                        wood_5mm_cutting: 'Madeira - Corte 5mm',
                        wood_7mm_cutting: 'Madeira - Corte 7mm',
                        wood_8mm_cutting: 'Madeira - Corte 8mm',
                        wood_10mm_cutting: 'Madeira - Corte 10mm',
                        wood_engraving: 'Madeira - Gravura',
                        acrylic_3mm_cutting: 'Acrílico - Corte 3mm',
                        acrylic_5mm_cutting: 'Acrílico - Corte 5mm',
                        acrylic_8mm_cutting: 'Acrílico - Corte 8mm',
                        acrylic_10mm_cutting: 'Acrílico - Corte 10mm',
                        acrylic_engraving: 'Acrílico - Gravura',
                        mdf_3mm_cutting: 'MDF - 3mm Corte',
                        mdf_5mm_cutting: 'MDF - 5mm Corte',
                        mdf_engraving: 'MDF - Gravura',
                        leather_3mm_cutting: 'Couro - 3mm Corte',
                        leather_5mm_cutting: 'Couro - 5mm Corte',
                        leather_engraving: 'Couro - Gravura',
                        denim_1mm_cutting: 'Denim - 1mm Corte',
                        fabric_3mm_cutting: 'Tecido - 3mm Corte',
                        fabric_5mm_cutting: 'Tecido - 5mm Corte',
                        fabric_engraving: 'Tecido - Gravura',
                        rubber_bw_engraving: 'Borracha - Gravura',
                        glass_bw_engraving: 'Vidro - Gravura',
                        metal_bw_engraving: 'Metal - Gravura',
                        steel_engraving_spray_engraving: 'Metal - Gravura',
                        stainless_steel_bw_engraving_diode: 'Metal - Gravura (Laser de Diodo)',
                        gold_engraving: 'Ouro - Gravura',
                        brass_engraving: 'Latão - Gravura',
                        ti_engraving: 'Titânio - Gravura',
                        stainless_steel_engraving: 'Aço inoxidável - Gravura',
                        aluminum_engraving: 'Alumínio - Gravura',
                        black_acrylic_3mm_cutting: 'Acrílico Preto - 3mm Corte',
                        black_acrylic_5mm_cutting: 'Acrílico Preto - 5mm Corte',
                        black_acrylic_engraving: 'Acrílico Preto - Gravura',
                        abs_engraving: 'ABS - Gravura',
                        silver_engraving: 'Prata - Gravação',
                        iron_engraving: 'Ferro - Gravação',
                        fabric_printing: 'Tecido - Impressão',
                        canvas_printing: 'Lona - Impressão',
                        cardstock_printing: 'Cartolina - Impressão',
                        wood_printing: 'Madeira - Impressão',
                        bamboo_printing: 'Bambu - Impressão',
                        cork_printing: 'Cortiça - Impressão',
                        flat_stone_printing: 'Pedra Plana - Impressão',
                        acrylic_printing: 'Acrílico - Impressão',
                        pc_printing: 'PC - Impressão',
                        stainless_steel_printing: 'Aço Inoxidável - Impressão',
                        gloss_leather_printing: 'Couro brilhante - Impressão',
                        glass_printing: 'Vidro - Impressão',
                        aluminum_light: 'Alumínio (claro)',
                        stainless_steel_dark: 'Aço inoxidável (escuro)',
                        stainless_steel_light: 'Aço inoxidável (claro)',
                        brass_dark: 'Latão (escuro)',
                        brass_light: 'Latão (claro)',
                        copper: 'Cobre',
                        titanium_dark: 'Titânio (escuro)',
                        titanium_light: 'Titânio (claro)',
                        black_abs: 'ABS preto',
                        white_abs: 'ABS branco',
                        opaque_acrylic: 'Acrílico opaco',
                        stone: 'Pedra',
                    },
                    inches: {
                        wood_3mm_cutting: 'Madeira - 0,1" Corte',
                        wood_5mm_cutting: 'Madeira - 0,2" Corte',
                        wood_7mm_cutting: 'Madeira - 0,3" Corte',
                        wood_8mm_cutting: 'Madeira - 0,3" Corte',
                        wood_10mm_cutting: 'Madeira - 0,4" Corte',
                        wood_engraving: 'Madeira - Gravura',
                        acrylic_3mm_cutting: 'Acrílico - 0,1" Corte',
                        acrylic_5mm_cutting: 'Acrílico - 0,2" Corte',
                        acrylic_8mm_cutting: 'Acrílico - 0,3" Corte',
                        acrylic_10mm_cutting: 'Acrílico - 0,4" Corte',
                        acrylic_engraving: 'Acrílico - Gravação',
                        mdf_3mm_cutting: 'MDF da Nova Zelândia - 0,1" Corte',
                        mdf_5mm_cutting: 'MDF da Nova Zelândia - 0,2" Corte',
                        mdf_engraving: 'MDF da Nova Zelândia - Gravação',
                        leather_3mm_cutting: 'Couro - 0,1" Corte',
                        leather_5mm_cutting: 'Couro - 0,2" Corte',
                        leather_engraving: 'Couro - Gravação',
                        denim_1mm_cutting: 'Denim - 0,04" Corte',
                        fabric_3mm_cutting: 'Tecido - 0,1" Corte',
                        fabric_5mm_cutting: 'Tecido - 0,2"Corte',
                        fabric_engraving: 'Tecido - Gravação',
                        rubber_bw_engraving: 'Borracha - Gravação',
                        glass_bw_engraving: 'Vidro - Gravura',
                        metal_bw_engraving: 'Metal - Gravura',
                        steel_engraving_spray_engraving: 'Metal - Gravura',
                        stainless_steel_bw_engraving_diode: 'Metal - Gravura (Laser de Diodo)',
                        gold_engraving: 'Ouro - Gravura',
                        brass_engraving: 'Latão - Gravura',
                        ti_engraving: 'Titânio - Gravura',
                        stainless_steel_engraving: 'Aço inoxidável - Gravura',
                        aluminum_engraving: 'Alumínio - Gravura',
                        black_acrylic_3mm_cutting: 'Acrílico Preto - 0,1" Corte',
                        black_acrylic_5mm_cutting: 'Acrílico Preto - 0,2" Corte',
                        black_acrylic_engraving: 'Acrílico Preto - Gravura',
                        abs_engraving: 'ABS - Gravura',
                        silver_engraving: 'Prata - Gravura',
                        iron_engraving: 'Ferro - Gravura',
                        fabric_printing: 'Tecido - Impressão',
                        canvas_printing: 'Lona - Impressão',
                        cardstock_printing: 'Cartolina - Impressão',
                        wood_printing: 'Madeira - Impressão',
                        bamboo_printing: 'Bambu - Impressão',
                        cork_printing: 'Cortiça - Impressão',
                        flat_stone_printing: 'Pedra Plana - Impressão',
                        acrylic_printing: 'Acrílico - Impressão',
                        pc_printing: 'PC - Impressão',
                        stainless_steel_printing: 'Aço Inoxidável - Impressão',
                        gloss_leather_printing: 'Couro brilhante - Impressão',
                        glass_printing: 'Vidro - Impressão',
                        aluminum_light: 'Alumínio (claro)',
                        stainless_steel_dark: 'Aço inoxidável (escuro)',
                        stainless_steel_light: 'Aço inoxidável (claro)',
                        brass_dark: 'Latão (escuro)',
                        brass_light: 'Latão (claro)',
                        copper: 'Cobre',
                        titanium_dark: 'Titânio (escuro)',
                        titanium_light: 'Titânio (claro)',
                        black_abs: 'ABS preto',
                        white_abs: 'ABS branco',
                        opaque_acrylic: 'Acrílico opaco',
                        stone: 'Pedra',
                    },
                },
                laser_speed: {
                    text: 'Velocidade',
                    unit: 'mm/s',
                    fast: 'Rápido',
                    slow: 'Lento',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Potência',
                    high: 'Alta',
                    low: 'Baixa',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Tipo de tinta',
                    normal: 'Tinta',
                    UV: 'Tinta UV',
                },
                para_in_use: 'Este parâmetro está em uso.',
                do_not_adjust_default_para: 'As predefinições padrão não podem ser ajustadas.',
                existing_name: 'Este nome já foi utilizado.',
                presets: 'Predefinição',
                preset_management: {
                    preset: 'Predefinição',
                    title: 'Gerenciar Parâmetros',
                    add_new: 'Adicionar Novo',
                    lower_focus_by: 'Baixar Foco em',
                    save_and_exit: 'Salvar e Sair',
                    delete: 'Excluir',
                    reset: 'Redefinir',
                    sure_to_reset: 'Isso excluirá seus parâmetros personalizados e redefinirá todos os predefinidos. Tem certeza de que deseja continuar?',
                    show_all: 'Mostrar Tudo',
                    laser: 'Laser',
                    print: 'Imprimir',
                    export: 'Exportar',
                    export_preset_title: 'Exportar Predefinições',
                    import: 'Importar',
                    sure_to_import_presets: 'Isso carregará a organização dos predefinidos e substituirá os parâmetros personalizados. Tem certeza de que deseja continuar?',
                    new_preset_name: 'Novo Nome de Predefinição',
                },
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Grupo',
                ungroup: 'Desagrupar',
                distribute: 'Distribuir',
                hdist: 'Distribuição horizontal',
                vdist: 'Distribuição vertical',
                align: 'Alinhar',
                left_align: 'Alinhar à esquerda',
                center_align: 'Alinhar ao centro',
                right_align: 'Alinhar à direita',
                top_align: 'Alinhar ao topo',
                middle_align: 'Alinhar ao meio',
                bottom_align: 'Alinhar à base',
                boolean: 'Booleano',
                union: 'União',
                subtract: 'Subtrair',
                intersect: 'Interseção',
                difference: 'Diferença',
                flip: 'Inverter',
                hflip: 'Inverter horizontalmente',
                vflip: 'Inverter verticalmente',
                lock_aspect: 'Bloquear proporção',
                unlock_aspect: 'Desbloquear proporção',
                option_panel: {
                    fill: 'Preenchimento',
                    rounded_corner: 'Canto arredondado',
                    sides: 'Lados',
                    font_family: 'Fonte',
                    font_style: 'Estilo',
                    font_size: 'Tamanho',
                    letter_spacing: 'Espaçamento entre letras',
                    line_spacing: 'Espaçamento entre linhas',
                    vertical_text: 'Texto vertical',
                    start_offset: 'Deslocamento de texto',
                    vertical_align: 'Alinhamento',
                    text_infill: 'Preenchimento de texto',
                    path_infill: 'Preenchimento de caminho',
                    shading: 'Sombreamento',
                    pwm_engraving: 'Modo de Profundidade',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Limiar de brilho',
                    threshold_short: 'Limiar',
                    stroke: 'Traço',
                    stroke_color: 'Cor do traço',
                    stroke_width: 'Largura da linha',
                    color: 'Cor',
                },
                actions_panel: {
                    replace_with: 'Substituir por...',
                    replace_with_short: 'Substituir',
                    trace: 'Traçar',
                    grading: 'Classificação',
                    brightness: 'Brilho',
                    sharpen: 'Nitidez',
                    crop: 'Recortar',
                    bevel: 'Bisel',
                    invert: 'Inverter',
                    weld_text: 'Fundir texto',
                    convert_to_path: 'Converter para contorno',
                    fetching_web_font: 'Obtendo fonte online...',
                    uploading_font_to_machine: 'Enviando fonte para a máquina...',
                    wait_for_parsing_font: 'Analisando fonte... Por favor, aguarde um segundo',
                    offset: 'Deslocamento',
                    array: 'Matriz',
                    auto_fit: 'Ajuste Automático',
                    smart_nest: 'Aninhamento Inteligente',
                    decompose_path: 'Descompor',
                    disassemble_use: 'Desmontar',
                    create_textpath: 'Criar texto no contorno',
                    create_textpath_short: 'Texto no contorno',
                    detach_path: 'Descompor texto no contorno',
                    detach_path_short: 'Descompor',
                    edit_path: 'Editar caminho',
                    disassembling: 'Desmontando...',
                    ungrouping: 'Desagrupando...',
                    simplify: 'Simplificar',
                    ai_bg_removal: 'Remoção de fundo',
                    ai_bg_removal_short: 'Remoção de fundo',
                    ai_bg_removal_reminder: 'Pressionar o botão usará imediatamente 0,2 créditos de, deseja continuar?',
                    outline: 'Contorno',
                },
                path_edit_panel: {
                    node_type: 'TIPO DE NÓ',
                    sharp: 'Afiado',
                    round: 'Arredondado',
                    connect: 'Conectar',
                    disconnect: 'Desconectar',
                    delete: 'Excluir',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Converter Texto em Caminho...',
            retreive_image_data: 'Recuperar Dados de Imagem...',
            export_file_error_ask_for_upload: 'Falha ao exportar tarefa. Deseja fornecer a cena de trabalho para a equipe de desenvolvimento relatar bugs?',
        },
        image_trace_panel: {
            apply: 'Aplicar',
            back: 'Voltar',
            cancel: 'Cancelar',
            next: 'Próximo',
            brightness: 'Brilho',
            contrast: 'Contraste',
            threshold: 'Limite',
            okay: 'OK',
            tuning: 'Configurações',
        },
        photo_edit_panel: {
            apply: 'Aplicar',
            back: 'Voltar',
            cancel: 'Cancelar',
            next: 'Próximo',
            sharpen: 'Nitidez',
            sharpness: 'Nitidez',
            radius: 'Raio',
            crop: 'Recortar',
            aspect_ratio: 'Proporção',
            original: 'Original',
            free: 'Livre',
            curve: 'Curva',
            start: 'Iniciar',
            processing: 'Processando',
            invert: 'Inverter Cor',
            okay: 'OK',
            compare: 'Comparar',
            phote_edit: 'Edição de Foto',
            brightness_and_contrast: 'Brilho / Contraste',
            brightness: 'Brilho',
            contrast: 'Contraste',
            rotary_warped: 'Distorcido Rotativo',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Diâmetro',
            circumference: 'Circunferência',
            warp: 'Distorção',
        },
        document_panel: {
            document_settings: 'Configurações do documento',
            machine: 'Máquina',
            laser_source: 'Fonte de laser',
            workarea: 'Área de trabalho',
            rotary_mode: 'Modo rotativo',
            borderless_mode: 'Abrir parte inferior',
            engrave_dpi: 'Resolução',
            enable_diode: 'Laser de diodo',
            enable_autofocus: 'Foco automático',
            extend_workarea: 'Expandir área de trabalho',
            mirror: 'Espelho',
            pass_through: 'Passar Por',
            pass_through_height_desc: 'Insira o comprimento do objeto para estender a área de trabalho.',
            start_position: 'Posição de Início',
            start_from: 'Começar de',
            origin: 'Origem',
            current_position: 'Posição Atual',
            job_origin: 'Origem do Trabalho',
            add_on: 'Complementos',
            low: 'Baixo',
            medium: 'Médio',
            high: 'Alto',
            ultra: 'Ultra Alto',
            enable: 'Habilitar',
            disable: 'Desabilitar',
            notification: {
                changeFromPrintingWorkareaTitle: 'Deseja converter as Camadas de Impressão em Camadas de Laser?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Analisando fonte... Por favor, aguarde um momento',
            text_to_path: {
                font_substitute_pop: 'Seu texto contém caracteres que não são suportados pela fonte atual. <br/>Gostaria de usar <strong>%s</strong> como substituição?',
                check_thumbnail_warning: 'Alguns textos foram alterados para outras fontes ao converter textos em caminhos e alguns caracteres podem não ter sido convertidos corretamente. \nVerifique a visualização novamente antes de enviar a tarefa.',
                error_when_parsing_text: 'Erro ao converter texto em caminho',
                use_current_font: 'Usar Fonte Atual',
                retry: 'Por favor, tente novamente mais tarde ou escolha outra fonte',
            },
            lock_desc: 'Manter proporção de largura e altura (SHIFT)',
        },
        tool_panels: {
            cancel: 'Cancelar',
            confirm: 'Confirmar',
            grid_array: 'Criar Matriz de Grade',
            array_dimension: 'Dimensão da Matriz',
            rows: 'Linhas',
            columns: 'Colunas',
            array_interval: 'Intervalo da Matriz',
            dx: 'X',
            dy: 'Y',
            offset: 'Deslocamento',
            nest: 'Otimização de arranjo',
            _offset: {
                direction: 'Direção de deslocamento',
                inward: 'Para dentro',
                outward: 'Para fora',
                dist: 'Distância de deslocamento',
                corner_type: 'Canto',
                sharp: 'Afiado',
                round: 'Arredondado',
                fail_message: 'Falha ao deslocar objetos.',
                not_support_message: 'Elementos selecionados contendo tag SVG não suportada:\nImagem, Grupo, Texto e Objeto Importado.',
            },
            _nest: {
                start_nest: 'Organizar',
                stop_nest: 'Parar',
                end: 'Fechar',
                spacing: 'Espaçamento',
                rotations: 'Possíveis Rotações',
                no_element: 'Não há elementos para organizar.',
            },
        },
        network_testing_panel: {
            network_testing: 'Teste de Rede',
            local_ip: 'Endereço IP local:',
            insert_ip: 'Endereço IP do dispositivo de destino:',
            empty_ip: '#818 Por favor, insira primeiro o endereço IP do dispositivo de destino.',
            start: 'Iniciar',
            end: 'Encerrar',
            testing: 'Testando Rede...',
            invalid_ip: '#818 Endereço IP inválido',
            ip_startswith_169: '#843 O endereço IP da máquina começa com 169.254',
            connection_quality: 'Qualidade da conexão',
            average_response: 'Tempo médio de resposta',
            test_completed: 'Teste concluído',
            test_fail: 'Falha no teste',
            cannot_connect_1: '#840 Falha ao conectar ao IP de destino.',
            cannot_connect_2: '#840 Falha ao conectar ao IP de destino. Certifique-se de que o destino está na mesma rede.',
            network_unhealthy: '#841 Qualidade da conexão <70 ou tempo médio de resposta >100ms',
            device_not_on_list: '#842 A máquina não está na lista, mas a qualidade da conexão é >70 e o tempo médio de resposta é <100ms',
            hint_device_often_on_list: 'A máquina geralmente não é encontrada na lista?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Falha ao conectar ao enviar um trabalho?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Tempo limite ocorre ao iniciar a visualização da câmera?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Falha ao obter endereço IP local.',
            fail_to_start_network_test: '#817 Falha ao iniciar teste de rede.',
            linux_permission_hint: 'Este erro geralmente ocorre devido a permissões insuficientes.\nExecute "sudo beam-studio --no-sandbox" no terminal para obter permissões e executar o teste de rede.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Configurações de Cor de Camada',
            color: 'Cor',
            power: 'Potência',
            speed: 'Velocidade',
            repeat: 'Repetir',
            add: 'Adicionar',
            default: 'Redefinir padrão',
            add_config: 'Adicionar cor',
            in_use: 'Esta cor está em uso.',
            no_input: 'Por favor, insira um código de cor hexadecimal válido.',
            sure_to_reset: 'Você perderá todos os parâmetros personalizados, tem certeza que deseja redefinir para o padrão?',
            sure_to_delete: 'Tem certeza que deseja excluir esta configuração de cor?',
        },
        rating_panel: {
            title: 'Gosta do Beam Studio?',
            description: 'Se você gosta do Beam Studio, agradeceríamos muito se você pudesse nos avaliar.',
            dont_show_again: 'Não mostrar novamente',
            thank_you: 'Obrigado!',
        },
        svg_editor: {
            unnsupported_file_type: 'O tipo de arquivo não é diretamente suportado. Por favor, converta o arquivo em SVG ou bitmap',
            unable_to_fetch_clipboard_img: 'Falha ao obter imagem da sua área de transferência',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Reproduzir',
            pause: 'Pausar',
            stop: 'Parar',
            play_speed: 'Velocidade de reprodução',
            travel_path: 'Caminho de deslocamento',
            invert: 'Inverter',
            preview_info: 'Visualizar informações',
            size: 'Tamanho',
            estimated_time: 'Tempo total estimado',
            cut_time: 'Tempo de corte',
            rapid_time: 'Tempo de deslocamento',
            cut_distance: 'Distância de corte',
            rapid_distance: 'Distância de deslocamento rápido',
            current_position: 'Posição atual',
            remark: '* Todas as informações são valores estimados apenas para referência.',
            start_here: 'Começar aqui',
            end_preview: 'Finalizar pré-visualização',
        },
        shapes_panel: {
            title: 'Elementos',
            basic: 'Básico',
            shape: 'Forma',
            graphics: 'Gráficos',
            arrow: 'Seta',
            label: 'Rótulo',
            decor: 'Decoração',
            circular: 'Circular',
            corner: 'Canto',
            line: 'Linha',
            photo: 'Foto',
            ribbon: 'Fita',
            speech: 'Discurso',
            text: 'Caixa de texto',
            animals: 'Animal',
            birds: 'Pássaro',
            land: 'Terra',
            sea: 'Mar',
            holidays: 'Feriado',
            celebration: 'Celebração',
            CNY: 'Ano Novo Chinês',
            easter: 'Páscoa',
            halloween: 'Dia das Bruxas',
            valentines: 'Dia dos Namorados',
            Xmas: 'Natal',
            nature: 'Natureza',
            elements: 'Elementos',
            environment: 'Meio Ambiente',
            plants: 'Plantas',
            weather: 'Clima',
        },
        announcement_panel: {
            title: 'Anúncio',
            dont_show_again: 'Não exibir novamente',
        },
    },
    editor: {
        prespray_area: 'Área de preparação',
        opacity: 'Opacidade da pré-visualização',
        exposure: 'Exposição da pré-visualização',
    },
    flux_id_login: {
        connection_fail: '#847 Falha ao conectar ao serviço de membros FLUX.',
        login_success: 'Conectado com sucesso.',
        login: 'Entrar',
        unlock_shape_library: 'Faça login para desbloquear a biblioteca de formas.',
        email: 'E-mail',
        password: 'Senha',
        remember_me: 'Lembrar de mim',
        forget_password: 'Esqueceu a senha?',
        register: 'Criar sua conta FLUX',
        offline: 'Trabalhar offline',
        work_offline: 'Trabalhar offline',
        incorrect: 'O endereço de e-mail ou a senha não está correto.',
        not_verified: 'O endereço de e-mail ainda não foi verificado.',
        new_to_flux: 'Novo no FLUX? Crie uma conta.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Explore planos FLUX+',
            thank_you: 'Obrigado por ser um membro valioso!',
            ai_credit_tooltip: 'Para remoção de fundo por IA',
            flux_credit_tooltip: 'Para arquivos do Mercado de Design e remoção de fundo por IA',
            goto_member_center: 'Acesse o Centro de Membros',
            access_plus_feature_1: 'Você está acessando um',
            access_plus_feature_2: 'recurso.',
            access_plus_feature_note: 'Você precisa da assinatura FLUX+ para acessar este recurso.',
            access_monotype_feature: 'Você não tem o complemento Fontes Monotype.',
            access_monotype_feature_note: 'Você precisa da assinatura FLUX+ Pro ou do complemento Fontes Monotype para acessar este recurso.',
            learn_more: 'Saiba mais',
            get_addon: 'Obter complemento',
            subscribe_now: 'Inscreva-se agora',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Remoção de fundo por IA',
                my_cloud: 'Armazenamento em nuvem ilimitado',
                boxgen: 'Gerador de caixas 3D',
                dmkt: '1000+ arquivos de design',
                monotype: '250+ fontes premium',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Faça login para desbloquear o banco de dados de formas.',
        enjoy_shape_library: 'Aproveite o banco de dados de formas.',
        shapes: 'Formas',
        elements: 'Elementos',
        recent: 'Recentes',
        search: 'Pesquisar',
        clear: 'Limpar',
        export_svg_title: 'Não é possível exportar SVG',
        export_svg_warning: 'Este projeto contém objetos protegidos por direitos autorais. Portanto, o Beam Studio removerá automaticamente esses objetos durante a exportação. Você ainda pode salvar seu projeto no formato Beam Studio Scene (.beam) para manter todos os seus dados. Você ainda deseja exportar?',
        learn_more: 'Saiba Mais',
    },
    change_logs: {
        change_log: 'Registros de alterações:',
        added: 'Adicionado:',
        fixed: 'Corrigido:',
        changed: 'Alterado:',
        see_older_version: 'Ver versões anteriores',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Selecionar Dispositivo',
        auth_failure: '#811 Falha na autenticação',
        unable_to_connect: '#810 Incapaz de estabelecer uma conexão estável com a máquina',
    },
    device: {
        pause: 'Pausar',
        paused: 'Pausado',
        pausing: 'Pausando',
        select_printer: 'Selecionar Impressora',
        retry: 'Tentar novamente',
        status: 'Status',
        busy: 'Ocupado',
        ready: 'Pronto',
        reset: 'Redefinir',
        abort: 'Abortar',
        start: 'Iniciar',
        please_wait: 'Aguarde...',
        quit: 'Sair',
        completing: 'Concluindo',
        aborted: 'Abortado',
        completed: 'Concluído',
        aborting: 'Abortando',
        starting: 'Iniciando',
        preparing: 'Preparando',
        resuming: 'Retomando',
        scanning: 'Verificando',
        occupied: 'Ocupado',
        running: 'Em execução',
        uploading: 'Enviando',
        processing: 'Processando',
        disconnectedError: {
            caption: 'Máquina desconectada',
            message: 'Confirme se o acesso à rede de %s está disponível',
        },
        unknown: 'Desconhecido',
        pausedFromError: 'Pausado por erro',
        model_name: 'Nome do modelo',
        IP: 'IP',
        serial_number: 'Número de série',
        firmware_version: 'Versão do firmware',
        UUID: 'UUID',
        select: 'Selecionar',
        deviceList: 'Lista de Máquinas',
        disable: 'Desativar',
        enable: 'Ativar',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: 'Trocar Cabeça da Ferramenta',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Iniciar',
        resume: 'Retomar',
        start: 'Iniciar',
        pause: 'Pausar',
        stop: 'Parar',
        record: 'Gravar',
        camera: 'Câmera',
        taskTab: 'Tarefa',
        connecting: 'Conectando, aguarde...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Erro Crítico: Placa-mãe offline. Contate o Suporte FLUX.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Erro Crítico: Subsistema sem resposta. Contate o Suporte FLUX.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Verifique seu tanque de água.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Feche a porta para continuar.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Superaquecimento. Aguarde alguns minutos.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Base aberta. Feche a base para continuar.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Falha ao redefinir eixo Z',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Gaveta aberta',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensor de chama anômalo',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensor de fluxo de ar anômalo',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Nenhum módulo rotativo detectado',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Erro de afastamento de homing',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: 'Módulo incorreto detectado. Instale o módulo correto para continuar.',
        HARDWARE_ERROR_HEADTYPE_NONE: 'Módulo não detectado. Certifique-se de que o módulo esteja instalado corretamente para continuar.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: 'Módulo desconhecido detectado. Instale o módulo correto para continuar.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: 'Sem resposta do módulo da impressora.',
        USER_OPERATION_ROTARY_PAUSE: 'Por favor, mude para o motor rotativo.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Por favor, remova o cartucho para continuar.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: 'Módulo incorreto detectado. Instale o módulo correto para continuar.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: 'Módulo não detectado. Certifique-se de que o módulo esteja instalado corretamente para continuar.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: 'Módulo desconhecido detectado. Instale o módulo correto para continuar.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Instale o módulo correto para continuar.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Por favor, insira o cartucho para continuar.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Por favor, insira o cartucho correto para continuar.',
        USER_OPERATION: 'Por favor, siga as instruções no painel do dispositivo para continuar.',
        RESOURCE_BUSY: 'A máquina está ocupada. Se não estiver em execução, reinicie a máquina',
        DEVICE_ERROR: 'Ocorreu um erro. Por favor, reinicie a máquina',
        NO_RESPONSE: 'Erro ao conectar à placa principal. Por favor, reinicie a máquina.',
        SUBSYSTEM_ERROR: 'Erro Crítico: Falha de resposta do subsistema. Entre em contato com o suporte.',
        HARDWARE_FAILURE: 'Ocorreu um erro. Reinicie a máquina',
        MAINBOARD_OFFLINE: 'Erro ao conectar à placa-mãe. Reinicie a máquina.',
        bug_report: 'Relatório de erro',
        processing: 'Processando',
        savingPreview: 'Gerando miniaturas',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: 'esquerda',
        temperature: 'Temperatura',
        forceStop: 'Deseja cancelar a tarefa atual?',
        upload: 'Enviar',
        download: 'Baixar',
        relocate: 'Mover',
        cancel: 'Cancelar',
        prepareRelocate: 'Preparando para mover localização',
        extensionNotSupported: 'Este formato de arquivo não é suportado',
        fileExistContinue: 'O arquivo já existe, deseja substituí-lo?',
        confirmFileDelete: 'Tem certeza de que deseja excluir este arquivo?',
        ask_reconnect: 'A conexão com a máquina foi interrompida. Deseja reconectar?',
        task: {
            BEAMBOX: 'Gravação a laser',
            'N/A': 'Modo livre',
        },
    },
    alert: {
        caption: 'Erro',
        duplicated_preset_name: 'Nome de predefinição duplicado',
        info: 'INFORMAÇÃO',
        warning: 'AVISO',
        error: 'Erro',
        instruction: 'Instrução',
        oops: 'Oops...',
        retry: 'Tentar novamente',
        abort: 'Abortar',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        close: 'Fechar',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Sim',
        no: 'Não',
        stop: 'Parar',
        save: 'Salvar',
        dont_save: 'Não salvar',
        learn_more: 'Saiba mais',
    },
    caption: {
        connectionTimeout: 'Tempo limite de conexão',
    },
    message: {
        cancelling: 'Cancelando...',
        connecting: 'Conectando...',
        connectingMachine: 'Conectando %s...',
        tryingToConenctMachine: 'Tentando conectar à máquina...',
        connected: 'Conectado',
        authenticating: 'Autenticando...',
        enteringRawMode: 'Entrando no modo bruto...',
        endingRawMode: 'Saindo do modo bruto...',
        enteringLineCheckMode: 'Entrando no modo de verificação de linha...',
        endingLineCheckMode: 'Saindo do modo de verificação de linha...',
        enteringRedLaserMeasureMode: 'Entrando no modo de medição com laser vermelho...',
        redLaserTakingReference: 'Pegando referência...',
        exitingRotaryMode: 'Saindo do modo rotativo...',
        turningOffFan: 'Desligando o ventilador...',
        turningOffAirPump: 'Desligando a bomba de ar...',
        gettingLaserSpeed: 'Lendo a velocidade do laser...',
        settingLaserSpeed: 'Definindo a velocidade do laser...',
        retrievingCameraOffset: 'Lendo o deslocamento da câmera...',
        connectingCamera: 'Conectando a câmera...',
        homing: 'Efetuando homing...',
        connectionTimeout: '#805 Tempo limite de conexão do dispositivo. Verifique o estado da rede e o indicador Wi-Fi da sua máquina.',
        getProbePosition: 'Obtendo a posição da sonda...',
        device_not_found: {
            caption: 'Máquina padrão não encontrada',
            message: '#812 Verifique o indicador Wi-Fi da sua máquina',
        },
        device_busy: {
            caption: 'Máquina ocupada',
            message: 'A máquina está executando outra tarefa, tente novamente mais tarde. Se parar de funcionar, reinicie a máquina.',
        },
        device_is_used: 'A máquina está sendo usada, deseja abortar a tarefa atual?',
        unknown_error: '#821 O aplicativo encontrou um erro desconhecido, use Ajuda > Menu > Relatório de erros.',
        unknown_device: '#826 Não é possível conectar à máquina, verifique se o USB está conectado à máquina',
        unsupport_osx_version: 'A versão atual do macOS %s pode não suportar algumas funções. Atualize para o macOS 11+.',
        unsupport_win_version: 'A versão atual do SO %s pode não suportar algumas funções. Atualize para a versão mais recente.',
        need_password: 'É necessária senha para conectar à máquina',
        unavailableWorkarea: '#804 A área de trabalho atual excede a área de trabalho desta máquina. Verifique a área de trabalho da máquina selecionada ou defina a área de trabalho em Editar > Configurações do Documento.',
        please_enter_dpi: 'Informe a resolução do seu arquivo (em mm)',
        auth_error: '#820 Erro de autenticação: atualize o Beam Studio e o firmware da máquina para a versão mais recente.',
        usb_unplugged: 'A conexão USB foi perdida. Verifique a conexão USB',
        uploading_fcode: 'Enviando FCode',
        cant_connect_to_device: '#827 Não foi possível conectar à máquina, verifique a conexão',
        unable_to_find_machine: 'Não foi possível encontrar a máquina ',
        disconnected: 'Conexão instável, verifique a conexão com o dispositivo e tente novamente mais tarde',
        unable_to_start: '#830 Não foi possível iniciar a tarefa. Tente novamente. Se o problema persistir, entre em contato conosco com um relatório de erro:',
        camera: {
            camera_cable_unstable: 'Foi detectado que a câmera está transferindo fotos de forma instável. A visualização ainda pode ser realizada normalmente, mas pode haver problema de visualização lenta ou tempo limite.',
            fail_to_transmit_image: '#845 Ocorreu um erro na transmissão da imagem. Por favor, reinicie sua máquina ou o Beam Studio. Se este erro persistir, siga <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">este guia</a>.',
            ws_closed_unexpectly: '#844 A conexão com a câmera da máquina foi fechada inesperadamente. Se este erro persistir, siga <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">este guia</a>.',
            continue_preview: 'Continuar',
            abort_preview: 'Cancelar',
        },
        preview: {
            camera_preview: 'Visualização da Câmera',
            auto_focus: 'Foco Automático',
            auto_focus_instruction: 'Por favor, mova o módulo laser acima do objeto e siga as instruções de animação para pressionar AF para focar.',
            already_performed_auto_focus: 'Você já realizou o foco automático, deseja usar os valores existentes?',
            please_enter_height: 'Por favor, insira a altura do objeto para capturar a imagem da câmera com precisão.',
            apply: 'Aplicar',
            enter_manually: 'Inserir Manualmente',
            adjust: 'Ajustar',
            adjust_height_tooltip: 'Clique na caixa de seleção para habilitar a edição.',
        },
        unsupported_example_file: 'O arquivo de exemplo selecionado não é suportado por esta área de trabalho.',
        time_remaining: 'Tempo restante:',
        promark_disconnected: '#850 Conexão do dispositivo interrompida, por favor verifique o estado da conexão.',
        swiftray_disconnected: 'Não foi possível conectar ao backend, tentando reconectar.',
        swiftray_reconnected: 'Backend reconectado, tente reenviar o trabalho.',
        device_blocked: {
            caption: 'Número de série não autorizado',
            online: 'O número de série do seu dispositivo parece estar desativado. Por favor, forneça o número de série da máquina (localizado na parte de trás do dispositivo) ao seu revendedor e peça para que ele entre em contato com support@flux3dp.com para ativar a autorização do dispositivo.',
            offline: 'O número de série do seu dispositivo está restrito ao uso offline, pois parece estar desativado. Por favor, forneça o número de série da máquina (localizado na parte de trás do dispositivo) ao seu revendedor e peça para que ele entre em contato com support@flux3dp.com para ativar a autorização online. Caso deseje usar o dispositivo offline, entre em contato diretamente com support@flux3dp.com.',
        },
    },
    machine_status: {
        '0': 'Ocioso',
        '1': 'Iniciando',
        '2': 'ST_TRANSFORM',
        '4': 'Iniciando',
        '6': 'Retomando',
        '16': 'Trabalhando',
        '18': 'Retomando',
        '32': 'Pausado',
        '36': 'Pausado',
        '38': 'Pausando',
        '48': 'Pausado',
        '50': 'Pausando',
        '64': 'Concluído',
        '66': 'Concluindo',
        '68': 'Preparando',
        '128': 'Abortado',
        '256': 'Alarme',
        '512': 'Fatal',
        '-17': 'Modo de E/S do cartucho',
        '-10': 'Modo de manutenção',
        '-2': 'Digitalizando',
        '-1': 'Manutenção',
        UNKNOWN: 'Desconhecido',
    },
    calibration: {
        update_firmware_msg1: 'Seu firmware não suporta esta função. Atualize o firmware para v',
        update_firmware_msg2: 'ou superior para continuar. (Menu > Máquina > [Sua Máquina] > Atualizar Firmware)',
        camera_calibration: 'Calibração da Câmera',
        diode_calibration: 'Calibração do Módulo de Laser de Diodo',
        module_calibration_printer: 'Calibração do Módulo da Impressora',
        module_calibration_2w_ir: 'Calibração do Módulo Infravermelho',
        back: 'Voltar',
        next: 'Próximo',
        skip: 'Pular',
        cancel: 'Cancelar',
        finish: 'Concluído',
        do_engraving: 'Fazer Gravação',
        start_engrave: 'Iniciar Gravação',
        start_printing: 'Iniciar Impressão',
        ask_for_readjust: 'Deseja pular a etapa de gravação e calibrar diretamente tirando uma foto?',
        please_goto_beambox_first: 'Por favor, mude para o Modo de Gravação (Beambox) para usar este recurso.',
        please_place_paper: 'Por favor, coloque uma folha de papel branco tamanho A4 ou Carta no canto superior esquerdo da área de trabalho.',
        please_place_paper_center: 'Por favor, coloque uma folha de papel branco tamanho A4 ou Carta no centro da área de trabalho.',
        please_place_dark_colored_paper: 'Por favor, coloque uma folha de papel escuro tamanho A4 ou Carta no centro da área de trabalho.',
        please_refocus: {
            beambox: 'Por favor, ajuste a plataforma ao ponto focal (a altura do acrílico virado para baixo)',
            beamo: 'Por favor, ajuste a cabeça do laser para focar no objeto de gravação (a altura do acrílico virado para baixo)',
            beamo_af: 'Por favor, toque duas vezes no botão lateral do acessório de foco automático e deixe a sonda tocar o material suavemente.',
            hexa: 'Clique duas vezes no botão de ajuste de altura para elevar a mesa de favo de mel e fazer a sonda tocar o material de gravação.',
        },
        without_af: 'Sem Foco Automático',
        with_af: 'Com Foco Automático',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotação',
        x_ratio: 'Proporção X',
        y_ratio: 'Proporção Y',
        show_last_config: 'Mostrar Último Resultado',
        use_last_config: 'Usar Último Valor de Calibração',
        taking_picture: 'Tirando Foto...',
        analyze_result_fail: 'Falha ao analisar a imagem capturada.<br/>Certifique-se de que:<br/>1. A imagem capturada está totalmente coberta com papel branco.<br/>2. A plataforma está devidamente focada.',
        drawing_calibration_image: 'Desenhando imagem de calibração...',
        calibrate_done: 'Calibração concluída. Maior precisão da câmera é obtida quando o foco está correto.',
        calibrate_done_diode: 'Calibração concluída. O deslocamento do módulo de laser de diodo foi salvo.',
        hint_red_square: 'Por favor, alinhe o exterior do quadrado vermelho com o quadrado de corte.',
        hint_adjust_parameters: 'Use esses parâmetros para ajustar o quadrado vermelho',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Ao calibrar o módulo de laser de diodo, a câmera é necessária.\nCertifique-se de que a câmera desta máquina foi calibrada.\nE ajuste gentilmente a plataforma ao ponto focal (a altura do acrílico virado para baixo)',
            beamo: 'Ao calibrar o módulo de laser de diodo, a câmera é necessária.\nCertifique-se de que a câmera desta máquina foi calibrada.\nE ajuste gentilmente a cabeça do laser para focar no objeto de gravação (a altura do acrílico virado para baixo)',
        },
        downloading_pictures: 'Baixando Imagens...',
        failed_to_download_pictures: '#848 Falha ao baixar imagens, entre em contato com o Suporte FLUX.',
        uploading_images: 'Enviando Imagens...',
        calculating_camera_matrix: 'Calculando Matriz da Câmera...',
        calculating_regression_parameters: 'Calculando parâmetros de regressão...',
        failed_to_calibrate_camera: '#848 Falha ao calibrar a câmera, entre em contato com o suporte FLUX.',
        failed_to_save_calibration_results: '#849 Falha ao salvar resultados de calibração, tente novamente. Se isso continuar acontecendo, entre em contato com o suporte FLUX.',
        ador_autofocus_material: 'Pressione o ícone "AF" na página principal da máquina por 3 segundos e deixe a sonda tocar o material suavemente.',
        ador_autofocus_focusing_block: 'Pressione o ícone "AF" na página principal da máquina por 3 segundos e deixe a sonda tocar o bloco de foco.',
        align_red_cross_cut: 'Alinhe o centro da cruz vermelha com a cruz de corte.',
        align_red_cross_print: 'Alinhe o centro da cruz vermelha com a cruz impressa.',
        retake: 'Tirar foto novamente',
        calibrate_camera_before_calibrate_modules: 'Calibre a câmera antes de calibrar os módulos.',
        check_checkpoint_data: 'Dados do ponto de verificação',
        checking_checkpoint: 'Verificando dados do ponto de verificação...',
        found_checkpoint: 'Dados do ponto de verificação encontrados no seu dispositivo. Deseja recuperar do ponto de verificação?',
        use_old_camera_parameter: 'Deseja usar os parâmetros atuais da lente da câmera?',
        downloading_checkpoint: 'Baixando dados do ponto de verificação...',
        failed_to_parse_checkpoint: 'Falha ao analisar os dados do ponto de verificação.',
        check_device_pictures: 'Verificar imagens do dispositivo',
        checking_pictures: 'Verificando imagens do dispositivo...',
        no_picutre_found: '#846 Seu dispositivo não possui fotos brutas disponíveis para calibração. Entre em contato com o suporte da FLUX.',
        unable_to_load_camera_parameters: "#851 Não há parâmetros de calibração de câmera disponíveis no seu dispositivo. Vá para 'Calibração' > 'Calibrar câmera (Avançado)' para concluir a calibração e obter os parâmetros.",
        calibrating_with_device_pictures: 'Calibrando com imagens do dispositivo...',
        failed_to_calibrate_with_pictures: '#848 Falha ao calibrar com imagens do dispositivo.',
        getting_plane_height: 'Obtendo Altura do Plano...',
        preparing_to_take_picture: 'Preparando para tirar uma foto...',
        put_paper: 'Coloque o papel',
        put_paper_step1: 'Por favor, coloque um papel branco de tamanho A4 ou Carta no centro da área de trabalho.',
        put_paper_step2: 'Fixe os quatro cantos do papel para garantir que ele fique plano.',
        put_paper_step3: 'Clique em "Iniciar Gravação".',
        put_paper_promark_1: 'Coloque o cartão preto da caixa de acessórios na plataforma de trabalho.',
        put_paper_promark_2: 'Ajuste o foco corretamente e clique em "Iniciar Gravação" para continuar com a gravação.',
        put_paper_skip: 'Se o desenho de calibração não prosseguir automaticamente para tirar a foto, pressione "Pular" para tirar a foto.',
        solve_pnp_title: 'Alinhar Pontos de Marcação',
        solve_pnp_step1: 'Alinhe os pontos de gravação de acordo com o número e a posição esperada de cada ponto de marcador vermelho.',
        solve_pnp_step2: 'Você pode pressionar "Tirar foto novamente" para realinhar ou ajustar manualmente as posições dos marcadores.',
        align_olt: 'Posição: Alinhe com o ponto gravado na parte superior esquerda externa.',
        align_ort: 'Posição: Alinhe com o ponto gravado na parte superior direita externa.',
        align_olb: 'Posição: Alinhe com o ponto gravado na parte inferior esquerda externa.',
        align_orb: 'Posição: Alinhe com o ponto gravado na parte inferior direita externa.',
        align_ilt: 'Posição: Alinhe com o ponto gravado na parte superior esquerda interna.',
        align_irt: 'Posição: Alinhe com o ponto gravado na parte superior direita interna.',
        align_ilb: 'Posição: Alinhe com o ponto gravado na parte inferior esquerda interna.',
        align_irb: 'Posição: Alinhe com o ponto gravado na parte inferior direita interna.',
        elevate_and_cut: 'Elevar e Cortar',
        elevate_and_cut_step_1: 'Coloque um pedaço de madeira de cor clara de tamanho A4 no centro da área de trabalho, elevado a 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Use o Ador Prism Lift com seu comprimento máximo de 14 mm junto com uma madeira com pelo menos 6 mm de espessura para elevar a madeira a uma altura de 20 mm.',
        with_prism_lift: 'Com Ador Prism Lift',
        without_prism_lift: 'Sem Ador Prism Lift',
        camera_parameter_saved_successfully: 'Parâmetros da câmera salvos com sucesso.',
        failed_to_save_camera_parameter: 'Falha ao salvar os parâmetros da câmera.',
        failed_to_solve_pnp: 'Falha ao resolver a posição da câmera.',
        calibrating: 'Calibrando...',
        moving_laser_head: 'Movendo a cabeça do laser...',
        failed_to_move_laser_head: 'Falha ao mover a cabeça do laser.',
        put_chessboard: 'Colocar Tabuleiro de Xadrez',
        put_chessboard_bb2_desc_1: 'Por favor, baixe o seguinte arquivo de tabuleiro de xadrez e imprima-o em papel A4 (o tabuleiro de xadrez impresso deve ter quadrados de 1x1 cm).',
        put_chessboard_bb2_desc_2: 'Fixe o tabuleiro de xadrez impresso em uma superfície indeformável, como acrílico ou vidro, garantindo que o tabuleiro esteja liso, sem rugas ou bordas levantadas.',
        put_chessboard_bb2_desc_3: 'Coloque a superfície com o tabuleiro de xadrez plana no centro da área de trabalho.',
        put_chessboard_1: 'Por favor, vá ao painel de controle da máquina e pressione AF para realizar o foco automático.',
        put_chessboard_2: 'Por favor, mova o papel do tabuleiro de xadrez ou a cabeça do laser até que todo o tabuleiro de xadrez esteja confirmado dentro da moldura vermelha na janela de visualização ao vivo.',
        put_chessboard_3: 'Clique com o botão direito para baixar a imagem do tabuleiro de xadrez na localização atual e verificar sua nitidez.',
        put_chessboard_promark_desc_1: 'Por favor, utilize o papel de xadrez fornecido na caixa de acessórios, ou imprima o seguinte padrão de xadrez em papel A4 para a calibração da câmera.',
        put_chessboard_promark_desc_2: 'Coloque o tabuleiro de xadrez plano e centralizado na plataforma de trabalho.',
        put_chessboard_promark_1: 'Ajuste a distância focal para o foco adequado da lente de campo.',
        put_chessboard_promark_2: 'Certifique-se de que o papel de xadrez esteja claramente visível, sem reflexo. Clique em "Próximo" para tirar uma foto.',
        download_chessboard_file: 'Baixar arquivo de tabuleiro de xadrez',
        failed_to_calibrate_chessboard: 'Falha na calibração com a imagem do tabuleiro de xadrez.',
        calibrate_chessboard_success_msg: 'A foto do tabuleiro de xadrez foi capturada com sucesso.<br/>A pontuação para esta foto é %s (%.2f).',
        res_excellent: 'Excelente',
        res_average: 'Média',
        res_poor: 'Fraco',
        perform_autofocus_bb2: 'Por favor, vá ao painel de controle da máquina e pressione AF para realizar o foco automático.',
        promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    },
    input_machine_password: {
        require_password: '"%s" requer uma senha',
        connect: 'CONECTAR',
        password: 'Senha',
    },
    tutorial: {
        skip: 'Pular',
        welcome: 'BEM-VINDO',
        suggest_calibrate_camera_first: 'Recomendamos aos usuários que calibrem a câmera inicialmente e refaçam o foco antes de cada visualização para obter resultados ótimos.<br/>Gostaria de confirmar para realizar a calibração agora?<br/>(Você pode pular agora e fazer mais tarde clicando em "Menu" > "Máquina" > [Sua Máquina] > "Calibrar Câmera".)',
        camera_calibration_failed: 'Falha na calibração da câmera',
        ask_retry_calibration: 'Deseja calibrar a câmera novamente?',
        needNewUserTutorial: 'Gostaria de iniciar um tutorial?<br/>(Você pode pular agora e iniciar mais tarde clicando em "Ajuda" > "Mostrar Tutorial de Início".)',
        needNewInterfaceTutorial: 'Gostaria de iniciar um tutorial para a nova interface do Beam Studio?<br/>(Você pode pular agora e iniciar mais tarde clicando em "Ajuda" > "Mostrar Introdução à Interface".)',
        next: 'PRÓXIMO',
        look_for_machine: 'Procurando máquina para o tutorial...',
        unable_to_find_machine: 'Não foi possível encontrar a máquina para o tutorial. Deseja ir para a página de configurações de conexão, tentar novamente ou pular o tutorial?',
        skip_tutorial: 'Você pulou o tutorial. Você sempre pode iniciar o tutorial clicando em "Ajuda" > "Mostrar Tutorial Inicial"',
        set_connection: 'Definir Conexão',
        retry: 'Tentar novamente',
        newUser: {
            draw_a_circle: 'Desenhe um círculo',
            drag_to_draw: 'Arraste para desenhar',
            infill: 'Ative o preenchimento',
            switch_to_object_panel: 'Mudar para o painel de objetos',
            switch_to_layer_panel: 'Mudar para o painel de camadas',
            set_preset_wood_engraving: 'Definir predefinição: Madeira - Gravura',
            set_preset_wood_cut: 'Definir predefinição: Madeira - Corte',
            add_new_layer: 'Adicionar uma nova camada',
            draw_a_rect: 'Desenhe um retângulo',
            switch_to_preview_mode: 'Mudar para o modo de visualização',
            preview_the_platform: 'Pré-visualizar a plataforma',
            end_preview_mode: 'Sair do modo de pré-visualização',
            put_wood: '1. Colocar a amostra de madeira',
            adjust_focus: '2. Ajustar o foco',
            close_cover: '3. Fechar a tampa',
            send_the_file: 'Enviar o arquivo',
            end_alert: 'Tem certeza que deseja finalizar o tutorial?',
            please_select_wood_engraving: 'Selecione gentilmente o Predefinição "Madeira - Gravura".',
            please_select_wood_cutting: 'Selecione gentilmente o Predefinição "Madeira - Corte".',
        },
        newInterface: {
            camera_preview: 'Pré-visualização da câmera',
            select_image_text: 'Selecionar imagem/texto',
            basic_shapes: 'Formas básicas',
            pen_tool: 'Ferramenta caneta',
            add_new_layer: 'Adicionar nova camada',
            rename_by_double_click: 'Renomear com duplo clique',
            drag_to_sort: 'Arraste para ordenar',
            layer_controls: 'Clique com o botão direito para selecionar Controles de Camada: \nDuplicar / Mesclar / Bloquear / Excluir Camadas',
            switch_between_layer_panel_and_object_panel: 'Alternar entre o Painel de Camadas e o Painel de Objetos',
            align_controls: 'Controles de Alinhamento',
            group_controls: 'Controles de Agrupamento',
            shape_operation: 'Operação de Forma',
            flip: 'Inverter',
            object_actions: 'Ações de Objeto',
            end_alert: 'Tem certeza de que deseja finalizar a introdução da nova interface do usuário?',
            select_machine: 'Selecione uma máquina',
            start_work: 'Começar o trabalho',
        },
        gesture: {
            pan: 'Role a tela com dois dedos.',
            zoom: 'Aproxime ou afaste dois dedos para aplicar zoom.',
            click: 'Toque para selecionar o objeto.',
            drag: 'Arraste para selecionar vários objetos.',
            hold: 'Pressione sem soltar para abrir o menu de contexto.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Este é o fim do tutorial. Agora é hora de criar!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: 'Laser de diodo de 10W',
        laser_20w_diode: 'Laser de diodo de 20W',
        laser_2w_infrared: 'Laser infravermelho de 2W',
        printing: 'Impressão',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Deseja converter o módulo de Impressão para o módulo Laser?',
            convertFromPrintingModuleMsg: 'Tenha em mente que se completar esta operação, as configurações de cor da camada de impressão serão removidas e definidas de acordo com a camada atual.',
            convertFromLaserModuleTitle: 'Deseja converter o módulo Laser em módulo de Impressão?',
            convertFromLaserModuleMsg: 'Tenha em mente que se completar esta operação, as configurações da camada laser serão removidas e definidas de acordo com a camada atual.',
            importedDocumentContainsPrinting: 'O documento contém camada de impressão, deseja alterar a área de trabalho para Ador?',
            printingLayersCoverted: 'As camadas de impressão foram convertidas em camadas laser.',
            performPrintingCaliTitle: 'Executar a calibração do módulo de impressão',
            performPrintingCaliMsg: 'Clique em "Confirmar" para executar a calibração ou acesse a calibração pelo menu superior.<br />(Máquina > [Nome da sua máquina] > Calibrar módulo de impressão)',
            performIRCaliTitle: 'Executar a calibração do módulo Infravermelho',
            performIRCaliMsg: 'Clique em "Confirmar" para executar a calibração ou acesse a calibração pelo menu superior.<br />(Máquina > [Nome da sua máquina] > Calibrar módulo Infravermelho)',
        },
        non_working_area: 'Área Não Laboral',
    },
    qr_code_generator: {
        title: 'Gerador de código QR',
        placeholder: 'Insira um link ou texto',
        preview: 'Pré-visualização',
        error_tolerance: 'Tolerância de erro',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Inverter cor de fundo',
    },
    boxgen: {
        title: 'CAIXA',
        basic_box: 'Caixa Básica',
        coming_soon: 'Em breve',
        workarea: 'Área de trabalho',
        max_dimension_tooltip: 'A configuração máxima de largura/altura/profundidade é %s.',
        volume: 'Volume',
        outer: 'Externo',
        inner: 'Interno',
        width: 'Largura',
        height: 'Altura',
        depth: 'Profundidade',
        cover: 'Tampa',
        thickness: 'Espessura',
        add_option: 'Adicionar Opção',
        joints: 'Junta',
        finger: 'Dedo',
        finger_warning: 'O comprimento interno do lado da caixa precisa ser de pelo menos 6 mm (0,24 polegadas) para ser compatível com a junção de dedos.',
        tSlot: 'T-Slot',
        tSlot_warning: 'O comprimento do lado da caixa precisa ser de pelo menos 30 mm (1,18 polegadas) para ser compatível com a junção de ranhuras em T.',
        edge: 'Borda',
        tCount: 'Contagem T',
        tCount_tooltip: 'A contagem de ranhuras em T aplica-se ao lado curto; a quantidade no lado longo é calculada com base no seu comprimento.',
        tDiameter: 'Diâmetro T',
        tLength: 'Comprimento T',
        continue_import: 'Continuar a Importar',
        customize: 'Personalizar',
        merge: 'Mesclar',
        text_label: 'Rótulo',
        beam_radius: 'Compensação do raio do feixe',
        beam_radius_warning: 'Remova a compensação de corte quando as bordas ou juntas da caixa forem curtas para garantir a montagem da caixa',
        import: 'Importar',
        cancel: 'Cancelar',
        reset: 'Redefinir',
        zoom: 'Zoom',
        control_tooltip: 'Botão esquerdo para rotacionar\nRoda para aplicar zoom\nBotão direito para mover',
        control_tooltip_touch: 'Arraste para rotacionar\nAproxime os dedos para aplicar zoom\nDois dedos para mover',
    },
    my_cloud: {
        title: 'Minha nuvem',
        loading_file: 'Carregando...',
        no_file_title: 'Salve arquivos em Minha nuvem para começar.',
        no_file_subtitle: 'Vá para Menu > "Arquivo" > "Salvar na nuvem"',
        file_limit: 'Arquivo gratuito',
        upgrade: 'Atualizar',
        sort: {
            most_recent: 'Mais recentes',
            oldest: 'Mais antigos',
            a_to_z: 'Nome: A - Z',
            z_to_a: 'Nome: Z - A',
        },
        action: {
            open: 'Abrir',
            rename: 'Renomear',
            duplicate: 'Duplicar',
            download: 'Baixar',
            delete: 'Excluir',
            confirmFileDelete: 'Tem certeza que deseja excluir este arquivo? Esta ação não pode ser desfeita.',
        },
        save_file: {
            choose_action: 'Salvar arquivo:',
            save: 'Salvar',
            save_new: 'Salvar como novo arquivo',
            input_file_name: 'Digite o nome do arquivo:',
            invalid_char: 'Caracteres inválidos:',
            storage_limit_exceeded: 'Seu armazenamento em nuvem atingiu o limite superior. Exclua quaisquer arquivos desnecessários antes de salvar novos.',
        },
    },
    camera_data_backup: {
        title: 'Backup de Dados da Câmera',
        no_picture_found: 'Nenhuma imagem encontrada na máquina.',
        folder_not_exists: 'A pasta selecionada não existe.',
        incorrect_folder: 'Falha ao carregar os dados de calibração. Por favor, verifique se a pasta selecionada está correta.',
        downloading_data: 'Baixando dados...',
        estimated_time_left: 'Tempo estimado restante:',
        uploading_data: 'Enviando dados...',
        download_success: 'Dados da câmera baixados com sucesso.',
        upload_success: 'Dados da câmera enviados com sucesso.',
    },
    insecure_websocket: {
        extension_detected: 'Extensão Beam Studio Connect detectada',
        extension_detected_description: "Detectamos que você instalou a extensão Beam Studio Connect. Clique em 'Confirmar' para redirecionar para HTTPS, ou clique em 'Cancelar' para continuar usando HTTP.",
        extension_not_deteced: 'Não foi possível detectar a extensão Beam Studio Connect',
        extension_not_deteced_description: "Para usar HTTPS, clique em 'Confirmar' para instalar a extensão Beam Studio Connect. Após instalar a extensão, atualize a página para ativá-la.<br/>Caso contrário, clique no link abaixo para ver como usar HTTP no Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Medir área de autofoco',
        amount: 'Quantidade',
        gap: 'Espaço',
        rows: 'Linhas',
        coloumns: 'Colunas',
        row_gap: 'Espaço entre linhas',
        column_gap: 'Espaço entre colunas',
        set_object_height: 'Definir altura do objeto',
        set_object_height_desc: 'Meça a espessura máxima do objeto.',
        reselect_area: 'Re-selecionar área',
        start_autofocus: 'Iniciar autofoco',
        starting_measurement: 'Iniciando medição...',
        preview_3d_curve: 'Visualizar Curva 3D',
        apply_arkwork: 'Aplicar Arte na Curva 3D',
        apply_camera: 'Aplicar Imagem da Câmera na Curva 3D',
        click_to_select_point: 'Clique para selecionar ou desselecionar pontos para medir novamente.',
        remeasure: 'Re-medir',
        remeasuring_points: 'Re-medindo pontos...',
        take_reference: 'Pegar referência',
        take_reference_desc: 'Por favor, mova a cabeça do laser para o ponto mais alto do objeto, abaixe a sonda de foco e clique em "Confirmar" para focar.',
        sure_to_delete: 'Deseja excluir os dados de foco da curva 3D?',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    },
    pass_through: {
        title: 'Modo De Passar Por',
        help_text: 'Como configurar Modo De Passar Por para Ador?',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'Comprimento do Objeto',
        workarea_height: 'Área de Trabalho (Altura):',
        height_desc: 'Defina a altura de cada seção da área de trabalho.',
        ref_layer: 'Camada de Referência',
        ref_layer_desc: 'Observe que a execução da camada de referência está configurada como 0 por padrão. Ela não será executada, mas é apenas para referência de alinhamento.',
        ref_layer_name: 'Referência',
        guide_mark: 'Marca de Guia',
        guide_mark_length: 'Diâmetro:',
        guide_mark_x: 'Coordenada X:',
        guide_mark_desc: 'As marcas de guia serão gravadas como um ponto de referência para alinhar a obra de arte.',
        export: 'Exportar para Área de Trabalho',
        exporting: 'Exportando...',
    },
    auto_fit: {
        title: 'Ajuste Automático',
        step1: '1. Coloque o objeto na posição desejada em uma peça de material.',
        step2: '2. Pressione "Confirmar" para duplicar o objeto em todas as peças de material semelhantes na pré-visualização da câmera.',
        preview_first: 'Por favor, realize a pré-visualização da câmera primeiro.',
        failed_to_auto_fit: 'Falha ao ajustar automaticamente, por favor verifique:',
        error_tip1: '1. A obra de arte está colocada corretamente no material?',
        error_tip2: '2. Os contornos do material são claros o suficiente para reconhecimento?',
        learn_more: 'Aprenda como funciona o ajuste automático.',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'Tipo',
        object_diameter: 'Diâmetro do Objeto',
        circumference: 'Circunferência',
    },
    framing: {
        low_laser: 'Laser baixo',
        low_laser_desc: 'Defina um valor de laser de baixa potência para a tarefa de moldura.',
        framing: 'Moldura',
        hull: 'Casco',
        area_check: 'Verificação da Área',
        framing_desc: 'Visualiza a caixa delimitadora do objeto.',
        hull_desc: 'Exibe uma pré-visualização de uma forma que segue de perto o design, como um elástico envolvido ao redor do objeto.',
        areacheck_desc: 'Garante a segurança da área de trabalho exibindo a caixa delimitadora do objeto e a zona de aceleração da cabeça do laser.',
        calculating_task: 'Calculando tarefa...',
    },
    material_test_generator: {
        title: 'Gerador de Teste de Material',
        table_settings: 'Configurações de Tabela',
        block_settings: 'Configurações de Bloco',
        text_settings: 'Configurações de texto',
        preview: 'Pré-visualização',
        export: 'Exportar',
        cut: 'Cortar',
        engrave: 'Gravar',
        columns: 'Colunas',
        rows: 'Linhas',
        parameter: 'Parâmetro',
        min: 'Min',
        max: 'Máx',
        count: 'Quantidade',
        size: 'Tamanho (AxL)',
        spacing: 'Espaçamento',
    },
    web_cam: {
        no_permission: 'O Beam Studio não tem permissão para acessar a câmera. Certifique-se de que a permissão foi concedida ao Beam Studio nas configurações do navegador ou do sistema.',
        no_device: 'Não foi possível detectar o dispositivo da câmera. Por favor, reconecte a câmera e tente novamente.',
    },
    promark_settings: {
        title: 'Configurações do Promark',
        field: 'Campo',
        red_dot: 'Ponto vermelho',
        galvo_configuration: 'Configuração Galvo',
        switchXY: 'Alternar X/Y',
        workarea_hint: 'Você pode alterar a área de trabalho nas "Configurações do documento".',
        offsetX: 'Deslocamento X',
        offsetY: 'Deslocamento Y',
        angle: 'Ângulo',
        scaleX: 'Escala X',
        scaleY: 'Escala Y',
        scale: 'Escala',
        bulge: 'Abaulamento',
        skew: 'Inclinação',
        trapezoid: 'Trapézio',
        mark_parameters: 'Parâmetros de marcação',
        preview: 'Pré-visualização',
        mark: 'Marcar',
        z_axis_adjustment: {
            title: 'Ajuste do Eixo Z',
            section1: 'Ajuste a altura do eixo Z para ajustar o foco.',
            tooltip1: 'Tente marcar um quadrado de 1x1 cm para confirmar se a distância focal atual é apropriada.',
        },
    },
    code_generator: {
        qr_code: 'Código QR',
        barcode: 'Código de barras',
    },
    barcode_generator: {
        bar_width: 'Largura da barra',
        bar_height: 'Altura da barra',
        text_margin: 'Margem do texto',
        invert_color: 'Inverter cor',
        font: 'Fonte',
        font_size: 'Tamanho da fonte',
        hide_text: 'Ocultar texto',
        barcode: {
            invalid_value: 'O valor é inválido para o formato selecionado.',
        },
    },
    social_media: {
        instagram: 'Receba as últimas inspirações, ofertas e brindes!',
        facebook: 'Converse com FLUXers, faça perguntas e aprenda dicas!',
        youtube: 'Veja tutoriais do Beam Studio e ideias de artesanato.',
    },
};
export default lang;
