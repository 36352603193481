import React, { forwardRef } from 'react';
import { Checkbox, ConfigProvider, Flex, Input, QRCode, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import browser from 'implementations/browser';
import useI18n from 'helpers/useI18n';
import styles from './QRCodeGenerator.module.scss';
export default forwardRef(({ isInvert, setIsInvert, text, setText }, ref) => {
    const { qr_code_generator: t } = useI18n();
    const [errorLevel, setErrorLevel] = React.useState('L');
    return (React.createElement("div", { ref: ref },
        React.createElement(Input.TextArea, { className: styles.input, rows: 5, maxLength: 200, value: text, onKeyDown: (e) => e.stopPropagation(), onChange: (e) => setText(e.target.value), placeholder: t.placeholder, showCount: true }),
        React.createElement("div", { className: styles.content },
            React.createElement("div", { id: "qrcode-container", className: styles['qrcode-container'] }, text ? (React.createElement(QRCode, { type: "svg", className: styles.qrcode, value: text, size: 1000, errorLevel: errorLevel, color: isInvert ? 'white' : 'black', bgColor: isInvert ? 'black' : 'transparent' })) : (React.createElement("div", { className: styles.placeholder }, t.preview))),
            React.createElement("div", { className: styles.settings },
                React.createElement("div", { className: styles.label },
                    `${t.error_tolerance} `,
                    React.createElement(InfoCircleOutlined, { onClick: () => browser.open(t.error_tolerance_link) })),
                React.createElement(Flex, { vertical: true },
                    React.createElement(ConfigProvider, { theme: {
                            components: { Radio: { buttonPaddingInline: 16, wrapperMarginInlineEnd: 0 } },
                        } },
                        React.createElement(Radio.Group, { value: errorLevel, onChange: (e) => setErrorLevel(e.target.value), size: "small", options: [
                                { label: '7%', value: 'L' },
                                { label: '15%', value: 'M' },
                                { label: '20%', value: 'Q' },
                                { label: '30%', value: 'H' },
                            ] })),
                    React.createElement(Checkbox, { className: styles.checkbox, checked: isInvert, onChange: () => setIsInvert(!isInvert) }, t.invert))))));
});
