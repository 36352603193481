var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sprintf } from 'sprintf-js';
import constant from 'app/actions/beambox/constant';
import { swiftrayClient } from 'helpers/api/swiftray-client';
export const loadTaskToSwiftray = (scene, model) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    const uploadRes = yield swiftrayClient.loadSVG({
        data: scene,
        extension: 'svg',
        name: 'calibration.svg',
        uploadName: 'calibration.svg',
        thumbnail: '',
    }, { onProgressing: () => { }, onFinished: () => { }, onError: () => { } }, 
    // use mid dpi (254)
    { engraveDpi: 254, model, rotaryMode: false });
    if (!uploadRes.success)
        throw new Error(`Failed to load calibration task: ${(_b = (_a = uploadRes.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Unknown Error'}`);
    const convertRes = yield swiftrayClient.convert('gcode', { onProgressing: () => { }, onFinished: () => { }, onError: () => { } }, { model, travelSpeed: 4000 });
    if (!convertRes.success)
        throw new Error(`Failed to convert calibration task: ${(_d = (_c = convertRes.error) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : 'Unknown Error'}`);
});
export const generateCalibrationTaskString = ({ width, power = 100, speed = 350, }) => __awaiter(void 0, void 0, void 0, function* () {
    const fileName = 'fcode/promark-calibration.bvg';
    const resp = yield fetch(fileName);
    let res = yield resp.text();
    res = sprintf(res, { width: width * constant.dpmm, power, speed });
    return res;
});
export const loadCameraCalibrationTask = (model, width) => __awaiter(void 0, void 0, void 0, function* () {
    const fileName = `fcode/promark-calibration-${width}.bvg`;
    const resp = yield fetch(fileName);
    const scene = yield resp.text();
    yield loadTaskToSwiftray(scene, model);
});
export default { loadCameraCalibrationTask };
