import React, { useMemo } from 'react';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
import { PreviewSpeedLevel } from 'app/actions/beambox/constant';
function Camera({ enableCustomPreviewHeightOptions, getBeamboxPreferenceEditingValue, updateBeamboxPreferenceChange, }) {
    const { lang } = i18n;
    const previewSpeedLevel = getBeamboxPreferenceEditingValue('preview_movement_speed_level') || 1;
    const options = useMemo(() => [
        {
            value: PreviewSpeedLevel.SLOW,
            label: lang.settings.low,
            selected: previewSpeedLevel === PreviewSpeedLevel.SLOW,
        },
        {
            value: PreviewSpeedLevel.MEDIUM,
            label: lang.settings.medium,
            selected: previewSpeedLevel === PreviewSpeedLevel.MEDIUM,
        },
        {
            value: PreviewSpeedLevel.FAST,
            label: lang.settings.high,
            selected: previewSpeedLevel === PreviewSpeedLevel.FAST,
        },
    ], [lang, previewSpeedLevel]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.camera),
        React.createElement(SelectControl, { id: "set-camera-preview-speed-level", label: lang.settings.preview_movement_speed, options: options, onChange: (e) => updateBeamboxPreferenceChange('preview_movement_speed_level', parseInt(e.target.value, 10)) }),
        React.createElement(SelectControl, { id: "set-enable-custom-preview-height", label: lang.settings.custom_preview_height, options: enableCustomPreviewHeightOptions, onChange: (e) => updateBeamboxPreferenceChange('enable-custom-preview-height', e.target.value) })));
}
export default Camera;
