const lang = {
    global: {
        cancel: 'Annuler',
        back: 'Retour',
        save: 'Enregistrer',
        ok: 'OK',
        stop: 'Arrêter',
    },
    general: {
        processing: 'Traitement...',
        choose_folder: 'Choisir un dossier',
    },
    buttons: {
        next: 'Suivant',
        back: 'Retour',
        done: 'Terminé',
        back_to_beam_studio: 'Retourner à Beam Studio',
    },
    topbar: {
        untitled: 'Sans titre',
        titles: {
            settings: 'Préférences',
        },
        export: 'EXPORTER',
        preview: 'PRÉVISUALISATION',
        preview_title: 'Aperçu',
        preview_press_esc_to_stop: 'Appuyez sur ESC pour arrêter l’aperçu de la caméra.',
        curve_engrave: 'Courbe 3D',
        task_preview: 'Aperçu de la tâche',
        frame_task: 'Exécution du cadre',
        borderless: '(OUVRIR EN BAS)',
        tag_names: {
            rect: 'Rectangle',
            ellipse: 'Ellipse',
            path: 'Trajet',
            polygon: 'Polygone',
            image: 'Image',
            text: 'Texte',
            text_path: 'Texte sur trajet',
            pass_through_object: 'Objet de Passage',
            line: 'Ligne',
            g: 'Groupe',
            multi_select: 'Objets multiples',
            use: 'Objet importé',
            svg: 'Objet SVG',
            dxf: 'Objet DXF',
            no_selection: 'Aucune sélection',
        },
        alerts: {
            start_preview_timeout: '#803 Délai d’attente dépassé lors du démarrage du mode aperçu. Veuillez redémarrer votre machine ou Beam Studio. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ce guide</a>.',
            fail_to_start_preview: '#803 Échec du démarrage du mode aperçu. Veuillez redémarrer votre machine ou Beam Studio. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ce guide</a>.',
            fail_to_connect_with_camera: '#803 Échec de la connexion avec la caméra de la machine. Veuillez redémarrer votre machine ou Beam Studio. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ce guide</a>.',
            add_content_first: "Veuillez d'abord ajouter des objets",
            headtype_mismatch: 'Module incorrect détecté. ',
            headtype_none: 'Aucun module détecté. ',
            headtype_unknown: 'Module inconnu détecté. ',
            install_correct_headtype: 'Veuillez installer correctement les modules laser diode 10W/20W pour activer le laser de découpe.',
            door_opened: 'Veuillez fermer le couvercle de protection pour activer le laser de découpe.',
            fail_to_get_door_status: 'Veuillez vous assurer que le couvercle de protection est fermé pour activer le laser de découpe.',
            QcleanScene: 'Voulez-vous effacer le dessin?<br/>Cela effacera également votre historique des annulations !',
            power_too_high: 'PUISSANCE TROP ÉLEVÉE',
            power_too_high_msg: 'Utiliser une puissance laser inférieure (moins de 70 %) prolongera la durée de vie du tube laser.\nEntrez "PRIS EN COMPTE" pour continuer.',
            power_too_high_confirm: 'PRIS EN COMPTE',
            pwm_unavailable: 'Le Mode Profondeur nécessite une version de firmware 4.3.4 / 5.3.4 ou supérieure. Voulez-vous mettre à jour le firmware maintenant ?',
            job_origin_unavailable: "La définition de l'origine de la tâche nécessite la version du firmware 4.3.5 / 5.3.5 ou supérieure. Voulez-vous mettre à jour le firmware maintenant?",
            job_origin_warning: 'Vous utilisez actuellement la “position actuelle” comme point de départ. Veuillez vous assurer que la tête laser est déplacée à la position correcte pour éviter les collisions.',
        },
        hint: {
            polygon: 'Appuyez sur les touches + / - pour augmenter / diminuer les côtés.',
        },
        menu: {
            preferences: 'Préférences',
            hide: 'Masquer Beam Studio',
            hideothers: 'Masquer les autres',
            service: 'Services',
            quit: 'Quitter',
            window: 'Fenêtre',
            minimize: 'Minimiser',
            close: 'Fermer la fenêtre',
            file: 'Fichier',
            edit: 'Modifier',
            help: 'Aide',
            open: 'Ouvrir',
            samples: 'Exemples',
            example_files: 'Fichiers d’Exemple',
            material_test: 'Test de Matériau',
            calibration: 'Calibrages',
            import_hello_beamo: 'Exemple de beamo',
            import_hello_beambox: 'Exemple de Beambox',
            import_beambox_2_example: 'Exemple de Beambox II',
            import_material_testing_old: 'Test de gravure de matériau - Classique',
            import_material_testing_simple_cut: 'Test de découpe de matériau - Simple',
            import_material_testing_cut: 'Test de découpe de matériau',
            import_material_testing_engrave: 'Test de gravure de matériau',
            import_material_testing_line: 'Test de ligne de matériau',
            import_material_printing_test: "Test d'impression de matériau",
            import_ador_laser_example: 'Exemple de Ador Laser',
            import_ador_printing_example_single: "Exemple d'impression Ador - Couleur unique",
            import_ador_printing_example_full: "Exemple d'impression Ador - Couleurs complètes",
            import_acrylic_focus_probe: 'Sonde de mise au point acrylique - 3mm',
            import_beambox_2_focus_probe: 'Sonde de Mise au Point Beambox II - 3 mm',
            import_promark_example: 'Exemple de Promark',
            import_hexa_example: 'Exemple de HEXA',
            export_to: 'Exporter vers...',
            export_flux_task: 'Tâche FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Enregistrer',
            save_as: 'Enregistrer sous...',
            save_to_cloud: 'Enregistrer dans le cloud',
            about_beam_studio: 'À propos de Beam Studio',
            undo: 'Annuler',
            redo: 'Rétablir',
            cut: 'Couper',
            copy: 'Copier',
            paste: 'Coller',
            paste_in_place: 'Coller en place',
            group: 'Grouper',
            ungroup: 'Dégrouper',
            delete: 'Supprimer',
            duplicate: 'Dupliquer',
            offset: 'Décaler',
            scale: "Mettre à l'échelle",
            rotate: 'Pivoter',
            reset: 'Réinitialiser',
            align_center: 'Centrer',
            photo_edit: 'Image',
            svg_edit: 'SVG',
            path: 'Trajet',
            decompose_path: 'Décomposer',
            object: 'Objet',
            layer_setting: 'Calque',
            layer_color_config: 'Paramètres de couleur',
            image_sharpen: 'Netteté',
            image_crop: 'Rogner',
            image_invert: 'Inverser',
            image_stamp: 'Biseautage',
            image_vectorize: 'Vectoriser',
            image_curve: 'Courbe',
            align_to_edges: 'Aligner aux bords',
            document_setting: 'Paramètres du document',
            document_setting_short: 'Document',
            rotary_setup: 'Configuration du Rotary',
            clear_scene: 'Nouveaux fichiers',
            machines: 'Machines',
            add_new_machine: 'Configuration de la machine',
            help_center: "Centre d'aide",
            show_gesture_tutorial: 'Introduction aux gestes',
            show_start_tutorial: 'Afficher le didacticiel de démarrage',
            show_ui_intro: "Afficher l'introduction à l'interface",
            questionnaire: 'Questionnaire de commentaires',
            change_logs: 'Journal des modifications',
            contact: 'Nous contacter',
            tutorial: "Didacticiel d'impression Delta",
            design_market: 'Marché de conception',
            forum: 'Forum communautaire',
            software_update: 'Mise à jour du logiciel',
            bug_report: 'Rapport de bug',
            dashboard: 'Tableau de bord',
            machine_info: 'Informations sur la machine',
            network_testing: 'Tester les paramètres réseau',
            commands: 'Commandes',
            update_firmware: 'Mettre à jour le micrologiciel',
            using_beam_studio_api: "Utiliser l'API Beam Studio",
            set_as_default: 'Définir par défaut',
            calibrate_beambox_camera: 'Calibrer la caméra Beambox',
            calibrate_printer_module: "Calibrer le module d'impression",
            calibrate_ir_module: 'Calibrer le module infrarouge',
            calibrate_beambox_camera_borderless: 'Calibrer la caméra Beambox (ouverture inférieure)',
            calibrate_diode_module: 'Calibrer le module laser à diode',
            calibrate_camera_advanced: 'Calibrer la caméra (Avancé)',
            manage_account: 'Gérer mon compte',
            sign_in: 'Se connecter',
            sign_out: 'Se déconnecter',
            account: 'Compte',
            my_account: 'Mon compte',
            download_log: 'Télécharger les journaux',
            download_log_canceled: 'Téléchargement des journaux annulé',
            download_log_error: "Une erreur inconnue s'est produite, veuillez réessayer plus tard",
            keyboard_shortcuts: 'Raccourcis clavier',
            log: {
                network: 'Réseau',
                hardware: 'Matériel',
                discover: 'Découverte',
                usb: 'USB',
                usblist: 'Liste USB',
                camera: 'Caméra',
                cloud: 'Cloud',
                player: 'Lecteur',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Affichage',
            zoom_in: 'Zoom avant',
            zoom_out: 'Zoom arrière',
            fit_to_window: 'Ajuster à la taille de la fenêtre',
            zoom_with_window: 'Ajuster automatiquement à la taille de la fenêtre',
            borderless_mode: 'Mode plein écran',
            show_grids: 'Afficher la grille',
            show_rulers: 'Afficher les règles',
            show_layer_color: 'Utiliser la couleur de calque',
            anti_aliasing: 'Anticrénelage',
            disassemble_use: 'Désassembler',
            about: 'À propos de Beam Studio',
            switch_to_beta: 'Passer à la version bêta',
            switch_to_latest: 'Passer à la version stable',
            reload_app: "Recharger l'application",
            recent: 'Ouverte récente',
            update: 'Vérifier la mise à jour',
            dev_tool: 'Outil de débogage',
            camera_calibration_data: 'Données de calibration de la caméra',
            upload_data: 'Télécharger des données',
            download_data: 'Télécharger des données',
            tools: {
                title: 'Outils',
                material_test_generator: 'Générateur de Test de Matériau',
                code_generator: 'Générateur de code',
                box_generator: 'Générateur de Boîtes',
            },
            follow_us: 'Suivez-nous',
        },
        select_machine: 'Sélectionner une machine',
    },
    support: {
        no_webgl: "WebGL n'est pas pris en charge. Veuillez utiliser d'autres appareils.",
        no_vcredist: 'Veuillez installer Visual C++ Redistributable 2015<br/>Cela peut être téléchargé sur flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: "[UE] Une erreur inconnue s'est produite. Veuillez redémarrer Beam Studio et la machine.",
        OPERATION_ERROR: "[OE] Un conflit d'état s'est produit, veuillez réessayer l'action.",
        SUBSYSTEM_ERROR: "[SE] Erreur lors de l'exécution de la tâche par le micrologiciel de la machine. Veuillez redémarrer la machine.",
        UNKNOWN_COMMAND: "[UC] Veuillez mettre à jour le micrologiciel de l'appareil",
    },
    device_selection: {
        no_beambox: '#801 Nous ne pouvons pas trouver votre machine sur le réseau.\nVeuillez suivre le <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guide</a> pour résoudre le problème de connexion.',
        no_device_web: "#801 Vérifiez l'état de votre machine, ou cliquez sur 'Configuration de la machine' ci-dessous pour configurer la machine.",
        select_usb_device: 'Sélectionner un périphérique USB',
    },
    update: {
        update: 'Mettre à jour',
        release_note: 'Note de version :',
        firmware: {
            caption: 'Une mise à jour du micrologiciel de la machine est disponible',
            message_pattern_1: '"%s" est maintenant prêt pour la mise à jour du micrologiciel.',
            message_pattern_2: 'Micrologiciel %s v%s est maintenant disponible - Vous avez v%s.',
            latest_firmware: {
                caption: 'Mise à jour du micrologiciel de la machine',
                message: 'Vous avez le dernier micrologiciel de la machine',
                still_update: 'METTRE À JOUR',
                cant_get_latest: "Impossible d'obtenir des informations sur le dernier micrologiciel.",
            },
            confirm: 'TÉLÉCHARGER',
            upload_file: 'Téléchargement du micrologiciel (*.bin / *.fxfw)',
            update_success: 'Micrologiciel mis à jour avec succès',
            update_fail: '#822 Échec de la mise à jour',
            too_old_for_web: 'La version actuelle du micrologiciel de votre machine est la v%s.\nSi vous souhaitez utiliser la version en ligne de Beam Studio, veuillez mettre à jour le micrologiciel de la machine vers la dernière version.',
            force_update_message: '#814 Veuillez mettre à jour votre machine avec la dernière version du micrologiciel.',
            firmware_too_old_update_by_sdcard: "La version du micrologiciel est trop ancienne. Veuillez mettre à jour le micrologiciel à l'aide d'une carte SD.",
        },
        software: {
            checking: 'Vérification des mises à jour',
            switch_version: 'Changer de version',
            check_update: 'Rechercher les mises à jour',
            caption: 'Une mise à jour logicielle de Beam Studio est disponible',
            downloading: 'Téléchargement des mises à jour en arrière-plan, vous pouvez cliquer sur "OK" pour continuer votre travail.',
            install_or_not: 'est prêt pour la mise à jour. Voulez-vous redémarrer et mettre à jour maintenant ?',
            switch_or_not: 'est prêt pour le changement. Voulez-vous redémarrer et changer maintenant ?',
            available_update: 'Beam Studio v%s est disponible maintenant. Vous avez la v%s. Voulez-vous télécharger la mise à jour ?',
            available_switch: 'Beam Studio v%s est disponible maintenant. Vous avez la v%s. Voulez-vous passer à cette version ?',
            not_found: 'Vous utilisez la dernière version de Beam Studio.',
            no_response: 'Échec de la connexion au serveur, veuillez vérifier vos paramètres réseau.',
            switch_version_not_found: 'Version commutable introuvable.',
            yes: 'Oui',
            no: 'Non',
            update_for_ador: "La version logicielle actuelle %s n'est pas compatible, veuillez télécharger la dernière version de Beam Studio pour Ador.",
        },
        updating: 'Mise à jour en cours...',
        skip: 'Ignorer cette version',
        preparing: 'Préparation en cours...',
        later: 'PLUS TARD',
        download: 'MISE À JOUR EN LIGNE',
        cannot_reach_internet: '#823 Le serveur est inaccessible<br/>Veuillez vérifier la connexion Internet',
        install: 'INSTALLER',
        upload: 'TÉLÉCHARGER',
    },
    topmenu: {
        version: 'Version',
        credit: 'Beam Studio est rendu possible par le <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> projet open source et d\'autres <a target="_blank" href="https://flux3dp.com/credits/">logiciels open source</a>.',
        ok: 'OK',
        file: {
            label: 'Fichier',
            import: 'Importer',
            save_fcode: 'Exporter la tâche FLUX',
            save_scene: 'Enregistrer la scène',
            save_svg: 'Exporter le SVG',
            save_png: 'Exporter le PNG',
            save_jpg: 'Exporter le JPG',
            converting: 'Conversion en image...',
            all_files: 'Tous les fichiers',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Scènes Beam Studio',
            fcode_files: 'Code FLUX',
            clear_recent: 'Effacer les fichiers récemment ouverts',
            path_not_exit: 'Ce chemin semble ne plus exister sur le disque.',
        },
        device: {
            download_log_canceled: 'Téléchargement du journal annulé',
            download_log_error: "Une erreur inconnue s'est produite, veuillez réessayer plus tard",
            log: {
                usblist: 'Liste USB',
            },
            network_test: 'Tester le réseau',
        },
    },
    initialize: {
        next: 'Suivant',
        start: 'Démarrer',
        skip: 'Passer',
        cancel: 'Annuler',
        confirm: 'Confirmer',
        connect: 'Se connecter',
        back: 'Retour',
        retry: 'Réessayer',
        no_machine: "Je n'ai pas de machine pour le moment.",
        select_language: 'Sélectionnez la langue',
        select_machine_type: 'Sélectionnez votre type de machine',
        select_beambox: 'Sélectionnez votre Beambox',
        select_connection_type: 'Comment souhaitez-vous vous connecter ?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Réseau filaire',
            ether2ether: 'Connexion directe',
            usb: 'Connexion USB',
        },
        connect_wifi: {
            title: 'Connexion au Wi-Fi',
            tutorial1: '1. Accédez au panneau tactile > Cliquez sur "RÉSEAU" > "Se connecter au Wi-Fi".',
            tutorial1_ador: '1. Accédez au panneau tactile > Cliquez sur "MACHINE" > Cliquez sur "RÉSEAU" > "Se connecter au Wi-Fi".',
            tutorial2: '2. Sélectionnez et connectez-vous au Wi-Fi de votre choix.',
            what_if_1: 'Que faire si je ne vois pas mon Wi-Fi ?',
            what_if_1_content: "1. Le chiffrement Wi-Fi doit être WPA2 ou sans mot de passe.\n2. Le chiffrement peut être défini dans l'interface d'administration du routeur Wi-Fi. Si le routeur ne prend pas en charge WPA2 et que vous avez besoin d'aide pour choisir le bon routeur, veuillez contacter le support FLUX.",
            what_if_2: 'Que faire si je ne vois aucun Wi-Fi ?',
            what_if_2_content: "1. Assurez-vous que la clé Wi-Fi est entièrement branchée.\n2. S'il n'y a pas d'adresse MAC du réseau sans fil sur l'écran tactile, veuillez contacter le support FLUX.\n3. Le canal Wi-Fi doit être 2.4Ghz (5Ghz n'est pas pris en charge).",
        },
        connect_wired: {
            title: 'Connexion au réseau filaire',
            tutorial1: '1. Connectez la machine à votre routeur.',
            tutorial2: '2. Appuyez sur "RÉSEAU" pour obtenir l\'IP du réseau filaire.',
            tutorial2_ador: '2. Appuyez sur "MACHINE" > "RÉSEAU" pour obtenir l\'IP du réseau filaire.',
            what_if_1: "Que faire si l'IP est vide ?",
            what_if_1_content: "1. Assurez-vous que le câble Ethernet est entièrement branché.\n2. S'il n'y a pas d'adresse MAC du réseau filaire sur l'écran tactile, veuillez contacter le support FLUX.",
            what_if_2: "Que faire si l'IP commence par 169 ?",
            what_if_2_content: "1. Si l'adresse IP commence par 169.254, il devrait s'agir d'un problème de configuration DHCP, veuillez contacter votre fournisseur d'accès à Internet pour plus d'assistance.\n2. Si votre ordinateur se connecte à Internet directement en utilisant PPPoE, veuillez passer à l'utilisation du routeur pour se connecter en utilisant PPPoE, et activer la fonction DHCP dans le routeur.",
        },
        connect_ethernet: {
            title: 'Connexion directe',
            tutorial1: '1. Connectez la machine à votre ordinateur avec un câble Ethernet.',
            tutorial2_1: '2. Suivez ',
            tutorial2_a_text: 'ce guide',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: 'pour configurer votre ordinateur comme routeur.',
            tutorial3: '3. Cliquez sur Suivant.',
        },
        connect_usb: {
            title: 'Connexion USB',
            title_sub: ' (HEXA & Ador uniquement)',
            tutorial1: 'Connectez la machine à votre ordinateur avec un câble USB.',
            tutorial2: 'Cliquez sur "Suivant".',
            turn_off_machine: 'Éteignez la machine.',
            turn_on_machine: 'Allumez la machine.',
            wait_for_turning_on: 'Cliquez sur "Suivant" une fois le processus de démarrage terminé et que vous êtes arrivé sur l\'écran principal.',
            connect_camera: 'Connectez la caméra de la machine à votre ordinateur avec un câble USB.',
        },
        connect_machine_ip: {
            check_usb: 'Vérifier la connexion USB',
            enter_ip: "Entrez l'adresse IP de votre machine",
            check_ip: "Vérification de la disponibilité de l'IP",
            invalid_ip: 'IP non valide : ',
            invalid_format: 'Format non valide',
            starts_with_169254: 'Commence par 169.254',
            unreachable: 'IP injoignable',
            check_connection: 'Vérification de la connexion à la machine',
            check_firmware: 'Vérification de la version du firmware',
            check_camera: 'Vérification de la disponibilité de la caméra',
            retry: 'Réessayer',
            succeeded_message: 'Connexion réussie 🎉',
            finish_setting: 'Commencez à créer !',
            check_swiftray_connection: 'Vérification de la connexion au serveur',
            check_swiftray_connection_unreachable: 'serveur inaccessible',
            promark_hint: 'Si vous ne parvenez pas à vous connecter via USB de manière répétée, consultez l\'<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">article du Centre d\'aide</a>.',
            alert: {
                swiftray_connection_error: 'Impossible de se connecter au serveur. Veuillez redémarrer Beam Studio et réessayer.',
            },
        },
        connecting: 'Connexion en cours...',
        setting_completed: {
            start: 'Démarrer',
            great: 'Bienvenue sur Beam Studio',
            setup_later: 'Vous pouvez toujours configurer votre machine depuis la barre de titre > "Machines" > "Configuration de la machine"',
            back: 'Retour',
            ok: 'COMMENCER À CRÉER',
        },
        promark: {
            select_laser_source: 'Sélectionnez votre Promark',
            select_workarea: 'Sélectionnez la zone de travail',
            settings: 'Paramètres Promark',
            qc_instructions: 'Remplissez les paramètres au dos de la carte « QC Pass »',
            configuration_confirmation: 'Cela garantit que votre Promark est correctement configuré pour une efficacité et une précision optimales.',
            or_complete_later: `Ou, ignorez cette étape et complétez les paramètres Promark plus tard à :
    Machines > « Nom Promark » > Paramètres Promark`,
        },
    },
    error_pages: {
        screen_size: "Veuillez noter que Beam Studio peut ne pas fonctionner de manière optimale sur votre appareil. Pour une expérience optimale, il devrait s'agir d'un appareil avec une largeur d'écran d'au moins 1024 pixels.",
    },
    menu: {
        mm: 'mm',
        inches: 'Pouces',
    },
    settings: {
        on: 'Marche',
        off: 'Arrêt',
        low: 'Bas',
        normal: 'Normal',
        high: 'Élevé',
        caption: 'Paramètres',
        tabs: {
            general: 'Général',
            device: 'Machine',
        },
        ip: 'Adresse IP de la machine',
        guess_poke: "Rechercher l'adresse IP de la machine",
        auto_connect: 'Sélectionner automatiquement la seule machine',
        wrong_ip_format: 'Formats IP incorrects',
        default_machine: 'Machine par défaut',
        default_machine_button: 'Vide',
        remove_default_machine_button: 'Supprimer',
        confirm_remove_default: 'La machine par défaut va être supprimée.',
        reset: 'Réinitialiser Beam Studio',
        reset_now: 'Réinitialiser Beam Studio',
        confirm_reset: 'Confirmer la réinitialisation de Beam Studio?',
        language: 'Langue',
        notifications: 'Notifications de bureau',
        check_updates: 'Vérification automatique',
        autosave_enabled: 'Sauvegarde automatique',
        autosave_path: 'Emplacement de la sauvegarde automatique',
        autosave_interval: 'Enregistrer tous les',
        autosave_number: 'Nombre de sauvegardes automatiques',
        autosave_path_not_correct: 'Le chemin spécifié est introuvable.',
        preview_movement_speed: "Vitesse d'aperçu du mouvement",
        medium: 'Moyen',
        default_units: 'Unités par défaut',
        default_font_family: 'Police par défaut',
        default_font_style: 'Style de police par défaut',
        fast_gradient: 'Optimisation de la vitesse',
        engraving_direction: 'Direction',
        top_down: 'De haut en bas',
        bottom_up: 'De bas en haut',
        vector_speed_constraint: 'Limite de vitesse',
        loop_compensation: 'Compensation de boucle',
        blade_radius: 'Rayon de lame',
        blade_precut_switch: 'Précoupe de lame',
        blade_precut_position: 'Position de précoupe',
        default_beambox_model: 'Modèle par défaut Beambox',
        guides_origin: 'Origine des repères',
        guides: 'Repères',
        image_downsampling: "Qualité d'aperçu bitmap",
        anti_aliasing: 'Anticrénelage',
        continuous_drawing: 'Dessin continu',
        trace_output: "Sortie de tracé d'image",
        single_object: 'Objet unique',
        grouped_objects: 'Objets groupés',
        simplify_clipper_path: 'Optimiser le chemin calculé',
        enable_low_speed: 'Activer le mouvement à vitesse lente',
        enable_custom_backlash: 'Activer la compensation de jeu personnalisée',
        calculation_optimization: 'Accélération du calcul de trajectoire',
        auto_switch_tab: "Changement automatique entre le panneau de calques et d'objets",
        custom_preview_height: "Hauteur d'aperçu personnalisée",
        mask: 'Masque de la zone de travail',
        text_path_calc_optimization: 'Optimisation du calcul de chemin de texte',
        font_substitute: 'Remplacer les caractères non pris en charge',
        font_convert: 'Convertisseur de texte en tracé',
        default_borderless_mode: 'Ouverture inférieure par défaut',
        default_enable_autofocus_module: 'Autofocus par défaut',
        default_enable_diode_module: 'Laser à diode par défaut',
        diode_offset: 'Décalage du laser à diode',
        autofocus_offset: 'Décalage de la mise au point automatique',
        diode_one_way_engraving: 'Gravure à sens unique au laser à diode',
        diode_two_way_warning: "L'émission bidirectionnelle de lumière est plus rapide et risque de générer une certaine imprécision sur la position de gravure au laser. Il est recommandé de tester d'abord.",
        share_with_flux: 'Partager les analyses de Beam Studio',
        none: 'Aucun',
        close: 'Fermer',
        enabled: 'Activé',
        disabled: 'Désactivé',
        cancel: 'Annuler',
        done: 'Appliquer',
        module_offset_10w: 'Décalage du laser à diode 10W',
        module_offset_20w: 'Décalage du laser à diode 20W',
        module_offset_printer: "Décalage de l'imprimante",
        module_offset_2w_ir: 'Décalage du laser infrarouge 2W',
        printer_advanced_mode: "Mode avancé de l'imprimante",
        default_laser_module: 'Module laser par défaut',
        low_laser_for_preview: 'Laser pour Exécution du cadre',
        groups: {
            general: 'Général',
            update: 'Mises à jour logicielles',
            connection: 'Connexion',
            autosave: 'Sauvegarde automatique',
            camera: 'Caméra',
            editor: 'Éditeur',
            engraving: 'Gravure',
            path: 'Contour',
            mask: 'Découpage de la zone de travail',
            text_to_path: 'Texte',
            modules: 'Complément',
            ador_modules: 'Modules Ador',
            privacy: 'Confidentialité',
        },
        notification_on: 'Activé',
        notification_off: 'Désactivé',
        update_latest: 'Dernier',
        update_beta: 'Bêta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
            calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
        },
    },
    beambox: {
        tag: {
            g: 'Groupe',
            use: 'Importer SVG',
            image: 'Image',
            text: 'Texte',
        },
        context_menu: {
            cut: 'Couper',
            copy: 'Copier',
            paste: 'Coller',
            paste_in_place: 'Coller en place',
            duplicate: 'Dupliquer',
            delete: 'Supprimer',
            group: 'Grouper',
            ungroup: 'Dégrouper',
            move_front: 'Amener au premier plan',
            move_up: "Monter d'un cran",
            move_down: "Descendre d'un cran",
            move_back: 'Mettre en arrière-plan',
        },
        popup: {
            select_import_method: 'Sélectionner le style de superposition :',
            select_import_module: 'Sélectionner le module :',
            touchpad: 'Pavé tactile',
            mouse: 'Souris',
            layer_by_layer: 'Couche',
            layer_by_color: 'Couleur',
            nolayer: 'Couche unique',
            loading_image: "Chargement de l'image, veuillez patienter...",
            no_support_text: "Beam Studio ne prend pas en charge les balises de texte pour le moment. Veuillez convertir le texte en chemin avant l'importation.",
            speed_too_high_lower_the_quality: "Utiliser une vitesse trop élevée à cette résolution peut entraîner une qualité d'ombrage de gravure inférieure.",
            both_power_and_speed_too_high: "Utiliser une puissance laser inférieure prolongera la durée de vie du tube laser.\\nDe plus, une vitesse trop élevée à cette résolution peut entraîner une qualité d'ombrage de gravure inférieure.",
            too_fast_for_path: "Utiliser une vitesse trop élevée dans les couches contenant des objets de chemin peut entraîner une précision inférieure lors de la découpe.\\nNous ne recommandons pas d'utiliser une vitesse supérieure à %(limit)s lors de la découpe.",
            too_fast_for_path_and_constrain: 'Les couches suivantes : %(layers)s\ncontiennent des objets de chemin vectoriel, et ont une vitesse supérieure à %(limit)s.\\nLa vitesse de découpe des objets de chemin vectoriel sera limitée à %(limit)s.\\nVous pouvez supprimer cette limite dans les paramètres de préférences.',
            should_update_firmware_to_continue: '#814 Votre micrologiciel ne prend pas en charge cette version de Beam Studio. Veuillez mettre à jour le micrologiciel pour continuer. (Menu > Machine > [Votre machine] > Mettre à jour le micrologiciel)',
            recommend_downgrade_software: 'Nous avons détecté une ancienne version du micrologiciel. Nous traitons activement les problèmes de compatibilité, mais pour l\'instant, nous vous recommandons de revenir à <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: "Nous avons détecté une ancienne version du micrologiciel. Nous traitons activement les problèmes de compatibilité, mais pour l'instant, nous vous recommandons de mettre à jour vers le dernier micrologiciel.",
            still_continue: 'Continuer',
            more_than_two_object: "Trop d'objets. Seuls 2 objets sont pris en charge",
            not_support_object_type: "Type d'objet non pris en charge",
            select_first: "Sélectionnez d'abord un objet.",
            select_at_least_two: 'Sélectionnez deux objets pour continuer',
            import_file_contain_invalid_path: "#808 Le fichier SVG importé contient un chemin d'image non valide. Assurez-vous que tous les fichiers image existent ou intégrez l'image dans le fichier",
            import_file_error_ask_for_upload: "Échec de l'importation du fichier SVG. Êtes-vous prêt à fournir le fichier à l'équipe de développement pour le rapport de bug ?",
            upload_file_too_large: '#819 Le fichier est trop volumineux pour être téléversé.',
            successfully_uploaded: 'Téléversement du fichier réussi.',
            upload_failed: '#819 Échec du téléversement du fichier.',
            or_turn_off_borderless_mode: 'Ou désactivez le mode Open Bottom.',
            svg_1_1_waring: 'La version de ce fichier SVG est v 1.1, il pourrait y avoir des problèmes de compatibilité potentiels.',
            svg_image_path_waring: "Ce fichier SVG contient <image> chargé à partir du chemin du fichier. Cela pourrait causer des échecs lors du chargement. \\nPour éviter ce risque, veuillez utiliser une image intégrée lors de l'exportation SVG.",
            dxf_version_waring: "La version de ce fichier DXF n'est pas 2013, il pourrait y avoir des problèmes de compatibilité potentiels.",
            dont_show_again: 'Ne plus afficher ceci la prochaine fois.',
            convert_to_path_fail: 'Échec de la conversion en chemin.',
            save_unsave_changed: 'Voulez-vous enregistrer les modifications non enregistrées ?',
            dxf_bounding_box_size_over: "La taille du dessin dépasse la zone de travail. Veuillez déplacer votre dessin plus près de l'origine dans votre logiciel CAO, ou assurez-vous que l'unité est définie correctement.",
            progress: {
                uploading: 'Téléversement',
                calculating: 'Calcul',
            },
            backend_connect_failed_ask_to_upload: '#802 Des erreurs se sont produites en essayant de se connecter au backend. Voulez-vous télécharger votre journal de rapport de bug ?',
            backend_error_hint: "Les fonctionnalités pourraient ne pas fonctionner correctement en raison d'une erreur de backend.",
            pdf2svg: {
                error_when_converting_pdf: '#824 Erreur lors de la conversion PDF en SVG :',
                error_pdf2svg_not_found: '#825 Erreur : commande pdf2svg introuvable. Veuillez installer pdf2svg avec votre gestionnaire de paquets (par exemple, "yum install pdf2svg" ou "apt-get install pdf2svg").',
            },
            ungroup_use: "Cela dégroupera le DXF ou le SVG importé. Comme le fichier pourrait contenir un grand nombre d'éléments, le dégroupage pourrait prendre du temps. Êtes-vous sûr de procéder ?",
            vectorize_shading_image: "Les images dégradées prennent plus de temps à tracer et sont sujettes au bruit. Veuillez désactiver le dégradé de l'image avant d'exécuter.",
            change_workarea_before_preview: 'La zone de travail de %s ne correspond pas à la zone de travail actuellement définie. Voulez-vous basculer la zone de travail actuelle ?',
            bug_report: 'Rapport de bug',
            sentry: {
                title: 'Améliorons Beam Studio ensemble',
                message: "Acceptez-vous de télécharger automatiquement des informations pertinentes vers l'équipe de développement lorsque des erreurs se produisent ?",
            },
            questionnaire: {
                caption: 'Aidez-nous à remplir le questionnaire',
                message: 'Aidez-nous à remplir le questionnaire pour améliorer Beam Studio.',
                unable_to_get_url: "Échec de l'obtention du lien vers le questionnaire via Internet. Veuillez confirmer votre connexion Internet.",
                no_questionnaire_available: "Il n'y a pas de questionnaire disponible pour le moment.",
            },
            facebook_group_invitation: {
                title: 'Rejoignez le groupe officiel des utilisateurs FLUX',
                message: "Rejoignez notre groupe Facebook officiel pour vous connecter avec d'autres utilisateurs de découpe laser FLUX, discuter des lasers FLUX, partager des œuvres laser et rester à jour avec les dernières mises à jour sur nos produits. Nous avons hâte de vous y voir !",
                join_now: 'Comptez sur moi',
                later: 'Peut-être plus tard',
                already_joined: 'Déjà rejoint',
                dont_show_again: 'Ne plus afficher ceci',
            },
            ai_credit: {
                relogin_to_use: 'Veuillez vous reconnecter pour utiliser cette fonction.',
                insufficient_credit: "Vous n'avez plus de crédit",
                insufficient_credit_msg: 'Vous ne pouvez pas utiliser le %s. Allez au centre des membres et achetez un crédit IA.',
                buy_link: 'https://member.flux3dp.com/en-us/credit',
                go: 'Aller',
            },
            text_to_path: {
                caption: 'Convertisseur de texte en tracé 2.0',
                message: "Beam Studio introduit maintenant un nouveau convertisseur de texte en tracé (Convertisseur 2.0), qui produit des résultats plus fiables ! Voulez-vous passer à celui-ci maintenant ?\nVous pouvez également ajuster ce paramètre plus tard dans la section 'Convertisseur de texte en tracé' dans les préférences.",
            },
            auto_switch_tab: {
                title: 'Commutation Automatique entre le Panneau des Calques et des Objets',
                message: 'Une nouvelle option permettant de basculer automatiquement entre le panneau des calques et le panneau des objets a été ajoutée aux Préférences. Cette option est désactivée par défaut. Souhaitez-vous activer la commutation automatique maintenant ? <br/>Vous pouvez modifier ce paramètre à tout moment dans les Préférences.',
            },
        },
        zoom_block: {
            fit_to_window: 'Ajuster à la fenêtre',
        },
        time_est_button: {
            calculate: 'Estimer le temps',
            estimate_time: 'Temps estimé :',
        },
        left_panel: {
            unpreviewable_area: 'Zone non visible',
            diode_blind_area: 'Zone non visible du module laser hybride',
            borderless_blind_area: 'Zone non gravable',
            borderless_preview: 'Aperçu caméra en mode fond ouvert',
            rectangle: 'Rectangle',
            ellipse: 'Ellipse',
            line: 'Ligne',
            image: 'Image',
            text: 'Texte',
            label: {
                cursor: 'Sélectionner',
                photo: 'Image',
                text: 'Texte',
                line: 'Ligne',
                rect: 'Rectangle',
                oval: 'Ovale',
                polygon: 'Polygone',
                pen: 'Stylo',
                shapes: 'Formes',
                array: 'Tableau',
                preview: 'Aperçu caméra',
                trace: 'Tracer image',
                end_preview: 'Arrêter aperçu',
                clear_preview: "Effacer l'aperçu",
                choose_camera: 'Caméra',
                live_feed: 'Diffusion en direct',
                adjust_height: 'Ajuster la hauteur',
                qr_code: 'Code QR',
                boxgen: 'Boxgen',
                my_cloud: 'Mon cloud',
                pass_through: 'Mode Passage',
                curve_engraving: {
                    title: 'Courbe 3D',
                    exit: 'Quitter',
                    select_area: 'Sélectionner une zone',
                    preview_3d_curve: 'Aperçu de la courbe 3D',
                    clear_area: 'Effacer la zone sélectionnée',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Calques',
                objects: 'Objets',
                path_edit: 'Édition de chemin',
            },
            layer_panel: {
                layer1: 'Calque 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Gravure',
                layer_cutting: 'Découpe',
                current_layer: 'Calque actuel',
                move_elems_to: 'Déplacer les éléments vers :',
                notification: {
                    dupeLayerName: 'Il existe déjà un calque avec ce nom !',
                    newName: 'NOUVEAU NOM',
                    enterUniqueLayerName: 'Veuillez saisir un nom de calque unique',
                    enterNewLayerName: 'Veuillez saisir le nouveau nom de calque',
                    layerHasThatName: 'Le calque a déjà ce nom',
                    QmoveElemsToLayer: 'Déplacer les éléments sélectionnés vers le calque « %s » ?',
                    moveElemFromPrintingLayerTitle: "Déplacer l'élément sélectionné vers %s et le convertir en élément laser ?",
                    moveElemFromPrintingLayerMsg: "Veuillez noter que si vous terminez cette opération, les paramètres de couleur de l'élément sélectionné seront supprimés et définis en fonction de %s.",
                    moveElemToPrintingLayerTitle: "Déplacer l'élément sélectionné vers %s et le convertir en élément d'impression ?",
                    moveElemToPrintingLayerMsg: "Veuillez noter que si vous terminez cette opération, les paramètres de l'élément sélectionné seront supprimés et définis en fonction de %s.",
                    splitColorTitle: 'Voulez-vous décomposer le calque sélectionné en calques CMJN ?',
                    splitColorMsg: "Veuillez noter que si vous poursuivez cette opération, vous ne pourrez pas revenir aux calques de couleur d'origine.",
                    mergeLaserLayerToPrintingLayerTitle: "Voulez-vous fusionner ces calques en un seul calque d'impression ?",
                    mergeLaserLayerToPrintingLayerMsg: 'Veuillez noter que si vous terminez cette opération, les paramètres du calque laser seront supprimés et définis en fonction du calque actuel.',
                    mergePrintingLayerToLaserLayerTitle: 'Voulez-vous fusionner ces calques en un seul calque laser ?',
                    mergePrintingLayerToLaserLayerMsg: "Veuillez noter que si vous terminez cette opération, les paramètres de couleur du calque d'impression seront supprimés et définis en fonction du calque actuel.",
                },
                layers: {
                    layer: 'Calque',
                    layers: 'Calques',
                    del: 'Supprimer le calque',
                    move_down: 'Déplacer le calque vers le bas',
                    new: 'Nouveau calque',
                    rename: 'Renommer le calque',
                    move_up: 'Déplacer le calque vers le haut',
                    dupe: 'Dupliquer le calque',
                    lock: 'Verrouiller la couche',
                    unlock: 'Déverrouiller',
                    merge_down: 'Fusionner vers le bas',
                    merge_all: 'Fusionner tout',
                    merge_selected: 'Fusionner les couches sélectionnées',
                    move_elems_to: 'Déplacer les éléments vers :',
                    move_selected: 'Déplacer les éléments sélectionnés vers une autre couche',
                    switchToFullColor: 'Passer en couche couleur complète',
                    switchToSingleColor: 'Passer en couche couleur unie',
                    splitFullColor: 'Décomposer la couche',
                    fullColor: 'Couleur complète',
                },
            },
            laser_panel: {
                preset_setting: 'Paramètres prédéfinis (%s)',
                multi_layer: 'Couches multiples',
                parameters: 'Paramètres',
                strength: 'Puissance',
                pwm_advanced_setting: 'Paramètres de Puissance du Mode Profondeur',
                pwm_advanced_desc: 'Réglez une puissance minimale pour le mode profondeur.',
                pwm_advanced_hint: "Ce réglage s'applique spécifiquement aux images en dégradé lors de l'utilisation du mode profondeur.",
                low_power_warning: 'Une puissance laser inférieure (moins de 10 %) pourrait ne pas émettre la lumière laser.',
                speed: 'Vitesse',
                speed_contrain_warning: 'La vitesse de découpe des objets de trajet vectoriel sera limitée à %(limit)s. Vous pouvez supprimer cette limite dans les paramètres de préférences.',
                low_speed_warning: 'Une vitesse lente pourrait brûler le matériau.',
                promark_speed_desc: "Les paramètres de vitesse ne s'appliquent pas aux images avec dégradés.",
                repeat: 'Nombre de Passes',
                advanced: 'Avancé',
                lower_focus: 'Abaisser la Mise au Point',
                by: 'de',
                stepwise_focusing: 'Mise au Point Progressive',
                single_color: 'Couleur Unique',
                lower_focus_desc: "Abaisser la hauteur du focus d'une distance spécifique après la mise au point pour améliorer la performance de coupe.",
                stepwise_focusing_desc: "Abaissement progressif de la distance de mise au point en fonction de la hauteur de l'objet à chaque nombre de passes.",
                single_color_desc: 'Uniquement applicable à la couche couleur complète et ne peut pas être utilisé avec la couche couleur unique étendue.',
                focus_adjustment: 'Réglage de la mise au point',
                height: "Hauteur de l'objet",
                z_step: 'Pas en Z',
                diode: 'Laser à diode',
                backlash: 'Jeu',
                ink_saturation: 'Saturation',
                print_multipass: 'Multi-passes',
                white_ink: 'Encre blanche',
                white_ink_settings: 'Paramètres encre blanche',
                color_adjustment: 'Réglage couleur',
                color_adjustment_short: 'Couleur',
                halftone: 'Demi-teinte',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Intensité',
                times: 'fois',
                cut: 'Couper',
                engrave: 'Graver',
                more: 'Plus',
                apply: 'Appliquer',
                custom_preset: 'Personnalisé',
                various_preset: 'Divers préréglages',
                module: 'Module',
                pulse_width: "Largeur d'impulsion",
                frequency: 'Fréquence',
                fill_setting: 'Paramètres de Remplissage',
                fill_interval: 'Intervalle de Remplissage',
                fill_angle: 'Angle de Remplissage',
                bi_directional: 'Bidirectionnel',
                cross_hatch: 'Hachure Croisée',
                dottingTime: 'Temps de pointage',
                gradient_only: 'Uniquement pour les images dégradées',
                filled_path_only: 'Uniquement pour les chemins de remplissage',
                slider: {
                    regular: 'Régulier',
                    low: 'Bas',
                    very_low: 'Min',
                    high: 'Élevé',
                    very_high: 'Max',
                    slow: 'Lent',
                    very_slow: 'Très lent',
                    fast: 'Rapide',
                    very_fast: 'Très rapide',
                },
                dropdown: {
                    parameters: 'Préréglages',
                    save: 'Ajouter les paramètres actuels',
                    mm: {
                        wood_3mm_cutting: 'Bois - 3mm découpe',
                        wood_5mm_cutting: 'Bois - 5mm découpe',
                        wood_7mm_cutting: 'Bois - 7mm découpe',
                        wood_8mm_cutting: 'Bois - 8mm découpe',
                        wood_10mm_cutting: 'Bois - 10mm découpe',
                        wood_engraving: 'Bois - Gravure',
                        acrylic_3mm_cutting: 'Acrylique - 3mm découpe',
                        acrylic_5mm_cutting: 'Acrylique - 5mm découpe',
                        acrylic_8mm_cutting: 'Acrylique - 8mm découpe',
                        acrylic_10mm_cutting: 'Acrylique - 10mm découpe',
                        acrylic_engraving: 'Acrylique - Gravure',
                        mdf_3mm_cutting: 'MDF - 3mm découpe',
                        mdf_5mm_cutting: 'MDF - 5mm découpe',
                        mdf_engraving: 'MDF - Gravure',
                        leather_3mm_cutting: 'Cuir - 3mm découpe',
                        leather_5mm_cutting: 'Cuir - 5mm découpe',
                        leather_engraving: 'Cuir - Gravure',
                        denim_1mm_cutting: 'Jean - 1mm découpe',
                        fabric_3mm_cutting: 'Tissu - 3mm découpe',
                        fabric_5mm_cutting: 'Tissu - 5mm découpe',
                        fabric_engraving: 'Tissu - Gravure',
                        rubber_bw_engraving: 'Caoutchouc - Gravure',
                        glass_bw_engraving: 'Verre - Gravure',
                        metal_bw_engraving: 'Métal - Gravure',
                        steel_engraving_spray_engraving: 'Métal - Gravure',
                        stainless_steel_bw_engraving_diode: 'Acier inoxydable - Gravure (Laser diode)',
                        gold_engraving: 'Or - Gravure',
                        brass_engraving: 'Laiton - Gravure',
                        ti_engraving: 'Titane - Gravure',
                        stainless_steel_engraving: 'Acier inoxydable - Gravure',
                        aluminum_engraving: 'Aluminium - Gravure',
                        black_acrylic_3mm_cutting: 'Acrylique noire - 3 mm découpe',
                        black_acrylic_5mm_cutting: 'Acrylique noire - 5 mm découpe',
                        black_acrylic_engraving: 'Acrylique noire - Gravure',
                        abs_engraving: 'ABS - Gravure',
                        silver_engraving: 'Argent - Gravure',
                        iron_engraving: 'Fer - Gravure',
                        fabric_printing: 'Tissu - Impression',
                        canvas_printing: 'Toile - Impression',
                        cardstock_printing: 'Carton - Impression',
                        wood_printing: 'Bois - Impression',
                        bamboo_printing: 'Bambou - Impression',
                        cork_printing: 'Liège - Impression',
                        flat_stone_printing: 'Pierre plate - Impression',
                        acrylic_printing: 'Acrylique - Impression',
                        pc_printing: 'PC - Impression',
                        stainless_steel_printing: 'Acier inoxydable - Impression',
                        gloss_leather_printing: 'Cuir verni - Impression',
                        glass_printing: 'Verre - Impression',
                        aluminum_light: 'Aluminium (clair)',
                        stainless_steel_dark: 'Acier inoxydable (foncé)',
                        stainless_steel_light: 'Acier inoxydable (clair)',
                        brass_dark: 'Laiton (foncé)',
                        brass_light: 'Laiton (clair)',
                        copper: 'Cuivre',
                        titanium_dark: 'Titane (foncé)',
                        titanium_light: 'Titane (clair)',
                        black_abs: 'ABS noir',
                        white_abs: 'ABS blanc',
                        opaque_acrylic: 'Acrylique opaque',
                        stone: 'Pierre',
                    },
                    inches: {
                        wood_3mm_cutting: 'Bois - 3 mm - Découpe',
                        wood_5mm_cutting: 'Bois - 5 mm - Découpe',
                        wood_7mm_cutting: 'Bois - 7 mm - Découpe',
                        wood_8mm_cutting: 'Bois - 8 mm - Découpe',
                        wood_10mm_cutting: 'Bois - 10 mm - Découpe',
                        wood_engraving: 'Bois - Gravure',
                        acrylic_3mm_cutting: 'Acrylique - 0,1" Découpe',
                        acrylic_5mm_cutting: 'Acrylique - 0,2" Découpe',
                        acrylic_8mm_cutting: 'Acrylique - 0,3" Découpe',
                        acrylic_10mm_cutting: 'Acrylique - 0,4" Découpe',
                        acrylic_engraving: 'Acrylique - Gravure',
                        mdf_3mm_cutting: 'MDF - 0,1" Découpe',
                        mdf_5mm_cutting: 'MDF - 0,2" Découpe',
                        mdf_engraving: 'MDF - Gravure',
                        leather_3mm_cutting: 'Cuir - 0,1" Découpe',
                        leather_5mm_cutting: 'Cuir - 0,2" Découpe',
                        leather_engraving: 'Cuir - Gravure',
                        denim_1mm_cutting: 'Jean - 0,04" Découpe',
                        fabric_3mm_cutting: 'Tissu - 0,1" Découpe',
                        fabric_5mm_cutting: 'Tissu - 0,2" Découpe',
                        fabric_engraving: 'Tissu - Gravure',
                        rubber_bw_engraving: 'Caoutchouc - Gravure',
                        glass_bw_engraving: 'Verre - Gravure',
                        metal_bw_engraving: 'Métal - Gravure',
                        steel_engraving_spray_engraving: 'Métal - Gravure',
                        stainless_steel_bw_engraving_diode: 'Métal - Gravure (Laser à diode)',
                        gold_engraving: 'Or - Gravure',
                        brass_engraving: 'Laiton - Gravure',
                        ti_engraving: 'Titane - Gravure',
                        stainless_steel_engraving: 'Acier inoxydable - Gravure',
                        aluminum_engraving: 'Aluminium - Gravure',
                        black_acrylic_3mm_cutting: "Acrylique noir - 0,1'' Découpe",
                        black_acrylic_5mm_cutting: "Acrylique noir - 0,2'' Découpe",
                        black_acrylic_engraving: 'Acrylique noir - Gravure',
                        abs_engraving: 'ABS - Gravure',
                        silver_engraving: 'Argent - Gravure',
                        iron_engraving: 'Fer - Gravure',
                        fabric_printing: 'Tissu - Impression',
                        canvas_printing: 'Toile - Impression',
                        cardstock_printing: 'Carton - Impression',
                        wood_printing: 'Bois - Impression',
                        bamboo_printing: 'Bambou - Impression',
                        cork_printing: 'Liège - Impression',
                        flat_stone_printing: 'Pierre plate - Impression',
                        acrylic_printing: 'Acrylique - Impression',
                        pc_printing: 'PC - Impression',
                        stainless_steel_printing: 'Acier inoxydable - Impression',
                        gloss_leather_printing: 'Cuir verni - Impression',
                        glass_printing: 'Verre - Impression',
                        aluminum_light: 'Aluminium (clair)',
                        stainless_steel_dark: 'Acier inoxydable (foncé)',
                        stainless_steel_light: 'Acier inoxydable (clair)',
                        brass_dark: 'Laiton (foncé)',
                        brass_light: 'Laiton (clair)',
                        copper: 'Cuivre',
                        titanium_dark: 'Titane (foncé)',
                        titanium_light: 'Titane (clair)',
                        black_abs: 'ABS noir',
                        white_abs: 'ABS blanc',
                        opaque_acrylic: 'Acrylique opaque',
                        stone: 'Pierre',
                    },
                },
                laser_speed: {
                    text: 'Vitesse',
                    unit: 'mm/s',
                    fast: 'Rapide',
                    slow: 'Lent',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Puissance',
                    high: 'Haute',
                    low: 'Basse',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: "Type d'encre",
                    normal: 'Encre',
                    UV: 'Encre UV',
                },
                para_in_use: 'Ce paramètre est utilisé.',
                do_not_adjust_default_para: 'Les paramètres par défaut ne peuvent pas être modifiés.',
                existing_name: 'Ce nom existe déjà.',
                presets: 'Préréglages',
                preset_management: {
                    preset: 'Préréglage',
                    title: 'Gérer les Paramètres',
                    add_new: 'Ajouter Nouveau',
                    lower_focus_by: 'Abaisser la Mise au Point de',
                    save_and_exit: 'Enregistrer et quitter',
                    delete: 'Supprimer',
                    reset: 'Réinitialiser',
                    sure_to_reset: 'Cela supprimera vos paramètres personnalisés et réinitialisera tous les préréglages, êtes-vous sûr de vouloir continuer?',
                    show_all: 'Tout Afficher',
                    laser: 'Laser',
                    print: 'Imprimer',
                    export: 'Exporter',
                    export_preset_title: 'Exporter les paramètres prédéfinis',
                    import: 'Importer',
                    sure_to_import_presets: "Cela chargera l'agencement des préréglages et écrasera les paramètres personnalisés, êtes-vous sûr de vouloir continuer?",
                    new_preset_name: 'Nouveau nom de préréglage',
                },
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Grouper',
                ungroup: 'Dégrouper',
                distribute: 'Distribuer',
                hdist: 'Distribuer horizontalement',
                vdist: 'Distribuer verticalement',
                align: 'Aligner',
                left_align: 'Aligner à gauche',
                center_align: 'Centrer',
                right_align: 'Aligner à droite',
                top_align: 'Aligner en haut',
                middle_align: 'Aligner au milieu',
                bottom_align: 'Aligner en bas',
                boolean: 'Opérations booléennes',
                union: 'Union',
                subtract: 'Soustraire',
                intersect: 'Intersection',
                difference: 'Différence',
                flip: 'Retourner',
                hflip: 'Retourner horizontalement',
                vflip: 'Retournement vertical',
                lock_aspect: 'Verrouiller les proportions',
                unlock_aspect: 'Déverrouiller les proportions',
                option_panel: {
                    fill: 'Remplissage',
                    rounded_corner: 'Coin arrondi',
                    sides: 'Côtés',
                    font_family: 'Police',
                    font_style: 'Style',
                    font_size: 'Taille',
                    letter_spacing: 'Espacement des lettres',
                    line_spacing: 'Espacement des lignes',
                    vertical_text: 'Texte vertical',
                    start_offset: 'Décalage du texte',
                    vertical_align: 'Alignement',
                    text_infill: 'Remplissage du texte',
                    path_infill: 'Remplissage du tracé',
                    shading: 'Ombrage',
                    pwm_engraving: 'Mode Profondeur',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Seuil de luminosité',
                    threshold_short: 'Seuil',
                    stroke: 'Contour',
                    stroke_color: 'Couleur de contour',
                    stroke_width: 'Largeur de contour',
                    color: 'Couleur',
                },
                actions_panel: {
                    replace_with: 'Remplacer par...',
                    replace_with_short: 'Remplacer',
                    trace: 'Tracer',
                    grading: 'Dégradé',
                    brightness: 'Luminosité',
                    sharpen: 'Affûter',
                    crop: 'Rogner',
                    bevel: 'Chanfrein',
                    invert: 'Inverser',
                    weld_text: 'Souder le texte',
                    convert_to_path: 'Convertir en tracé',
                    fetching_web_font: 'Récupération de la police en ligne...',
                    uploading_font_to_machine: 'Téléchargement de la police vers la machine...',
                    wait_for_parsing_font: 'Analyse de la police... Veuillez patienter une seconde',
                    offset: 'Décalage',
                    array: 'Tableau',
                    auto_fit: 'Ajustement Automatique',
                    smart_nest: 'Nidification Intelligente',
                    decompose_path: 'Décomposer',
                    disassemble_use: 'Désassembler',
                    create_textpath: 'Créer un texte sur un tracé',
                    create_textpath_short: 'Texte sur tracé',
                    detach_path: 'Décomposer le texte sur tracé',
                    detach_path_short: 'Décomposer',
                    edit_path: 'Modifier le tracé',
                    disassembling: 'Désassemblage en cours...',
                    ungrouping: 'Dégroupement en cours...',
                    simplify: 'Simplifier',
                    ai_bg_removal: "Suppression de l'arrière-plan",
                    ai_bg_removal_short: 'Suppr. arrière-plan',
                    ai_bg_removal_reminder: "L'appui sur le bouton utilisera immédiatement 0,2 crédit, voulez-vous continuer ?",
                    outline: 'Contour',
                },
                path_edit_panel: {
                    node_type: 'TYPE DE NOEUD',
                    sharp: 'Angle vif',
                    round: 'Arrondi',
                    connect: 'Relier',
                    disconnect: 'Déconnecter',
                    delete: 'Supprimer',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Convertir le texte en tracé...',
            retreive_image_data: "Récupérer les données d'image...",
            export_file_error_ask_for_upload: "Échec de l'exportation de la tâche. Êtes-vous prêt à fournir la scène de travail à l'équipe de développement pour le rapport de bug ?",
        },
        image_trace_panel: {
            apply: 'Appliquer',
            back: 'Retour',
            cancel: 'Annuler',
            next: 'Suivant',
            brightness: 'Luminosité',
            contrast: 'Contraste',
            threshold: 'Seuil',
            okay: 'OK',
            tuning: 'Paramètres',
        },
        photo_edit_panel: {
            apply: 'Appliquer',
            back: 'Retour',
            cancel: 'Annuler',
            next: 'Suivant',
            sharpen: 'Netteté',
            sharpness: 'Netteté',
            radius: 'Rayon',
            crop: 'Recadrer',
            aspect_ratio: "Format d'image",
            original: 'Original',
            free: 'Libre',
            curve: 'Courbe',
            start: 'Démarrer',
            processing: 'Traitement',
            invert: 'Inverser les couleurs',
            okay: 'OK',
            compare: 'Comparer',
            phote_edit: 'Édition de photo',
            brightness_and_contrast: 'Luminosité / Contraste',
            brightness: 'Luminosité',
            contrast: 'Contraste',
            rotary_warped: 'Déformé Rotatif',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Diamètre',
            circumference: 'Circonférence',
            warp: 'Déformation',
        },
        document_panel: {
            document_settings: 'Paramètres du document',
            machine: 'Machine',
            laser_source: 'Source laser',
            workarea: 'Zone de travail',
            rotary_mode: 'Mode rotatif',
            borderless_mode: 'Mode sans bordure',
            engrave_dpi: 'Résolution',
            enable_diode: 'Laser à diode',
            enable_autofocus: 'Mise au point automatique',
            extend_workarea: 'Étendre la zone de travail',
            mirror: 'Miroir',
            pass_through: 'Mode Passage',
            pass_through_height_desc: "Entrez la longueur de l'objet pour étendre la zone de travail.",
            start_position: 'Position de Départ',
            start_from: 'Commencer À Partir de',
            origin: 'Origine',
            current_position: 'Position Actuelle',
            job_origin: 'Origine du Travail',
            add_on: 'Modules complémentaires',
            low: 'Faible',
            medium: 'Moyen',
            high: 'Élevé',
            ultra: 'Ultra élevé',
            enable: 'Activer',
            disable: 'Désactiver',
            notification: {
                changeFromPrintingWorkareaTitle: "Voulez-vous convertir les calques d'impression en calques laser?",
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Analyse de la police en cours... Veuillez patienter une seconde',
            text_to_path: {
                font_substitute_pop: 'Votre texte contient des caractères qui ne sont pas pris en charge par la police actuelle. <br/>Souhaitez-vous utiliser <strong>%s</strong> comme substitution ?',
                check_thumbnail_warning: "Certains textes ont été modifiés avec d'autres polices lors de la conversion des textes en tracés et certains caractères peuvent ne pas être convertis normalement. <br/> Veuillez vérifier à nouveau l'image d'aperçu avant d'envoyer la tâche.",
                error_when_parsing_text: 'Erreur lors de la conversion du texte en tracé',
                use_current_font: 'Utiliser la police actuelle',
                retry: 'Veuillez réessayer plus tard ou choisir une autre police',
            },
            lock_desc: 'Conserver le rapport largeur/hauteur (MAJ)',
        },
        tool_panels: {
            cancel: 'Annuler',
            confirm: 'Confirmer',
            grid_array: 'Créer une grille',
            array_dimension: 'Dimension du tableau',
            rows: 'Lignes',
            columns: 'Cols.',
            array_interval: 'Intervalle de tableau',
            dx: 'X',
            dy: 'Y',
            offset: 'Décalage',
            nest: "Optimisation de l'arrangement",
            _offset: {
                direction: 'Direction de décalage',
                inward: "Vers l'intérieur",
                outward: "Vers l'extérieur",
                dist: 'Distance de décalage',
                corner_type: 'Coin',
                sharp: 'Pointu',
                round: 'Arrondi',
                fail_message: 'Échec du décalage des objets.',
                not_support_message: 'Éléments sélectionnés contenant des balises SVG non prises en charge :\nImage, Groupe, Texte et Objet importé.',
            },
            _nest: {
                start_nest: 'Arranger',
                stop_nest: 'Arrêter',
                end: 'Fermer',
                spacing: 'Espacement',
                rotations: 'Rotation possible',
                no_element: "Il n'y a pas d'élément à arranger.",
            },
        },
        network_testing_panel: {
            network_testing: 'Test de réseau',
            local_ip: 'Adresse IP locale :',
            insert_ip: 'Adresse IP du périphérique cible :',
            empty_ip: "#818 Veuillez d'abord entrer l'adresse IP du périphérique cible.",
            start: 'Démarrer',
            end: 'Terminer',
            testing: 'Test du réseau...',
            invalid_ip: '#818 Adresse IP non valide',
            ip_startswith_169: "#843 L'adresse IP de la machine commence par 169.254",
            connection_quality: 'Qualité de connexion',
            average_response: 'Temps de réponse moyen',
            test_completed: 'Test terminé',
            test_fail: 'Échec du test',
            cannot_connect_1: "#840 Échec de connexion à l'adresse IP cible",
            cannot_connect_2: "#840 Échec de connexion à l'adresse IP cible. Assurez-vous que la cible se trouve dans le même réseau.",
            network_unhealthy: '#841 Qualité de connexion <70 ou temps de réponse moyen >100ms',
            device_not_on_list: '#842 La machine ne figure pas dans la liste, mais la qualité de la connexion est >70 et le temps de réponse moyen est <100ms',
            hint_device_often_on_list: 'La machine ne se trouve souvent pas dans la liste ?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: "Échec de la connexion lors de l'envoi d'une tâche ?",
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: "Délai d'attente lors du démarrage de l'aperçu de la caméra ?",
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: "Accès à l'adresse IP locale échoué.",
            fail_to_start_network_test: '#817 Échec du démarrage des tests réseau.',
            linux_permission_hint: 'Cette erreur se produit généralement en raison de permissions insuffisantes.\nVeuillez exécuter "sudo beam-studio --no-sandbox" dans le terminal pour obtenir les permissions et effectuer des tests réseau.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Configurations de couleur de couche',
            color: 'Couleur',
            power: 'Puissance',
            speed: 'Vitesse',
            repeat: 'Exécuter',
            add: 'Ajouter',
            default: 'Réinitialiser aux valeurs par défaut',
            add_config: 'Ajouter une couleur',
            in_use: 'Cette couleur est utilisée.',
            no_input: 'Veuillez saisir un code couleur hexadécimal valide.',
            sure_to_reset: 'Vous perdrez tous les paramètres personnalisés, êtes-vous sûr de réinitialiser aux paramètres par défaut ?',
            sure_to_delete: 'Êtes-vous sûr de supprimer ce paramètre de couleur ?',
        },
        rating_panel: {
            title: 'Aimez-vous Beam Studio ?',
            description: 'Si vous aimez Beam Studio, nous vous serions très reconnaissants si vous pouviez nous évaluer.',
            dont_show_again: 'Ne plus afficher',
            thank_you: 'Merci !',
        },
        svg_editor: {
            unnsupported_file_type: "Le type de fichier n'est pas directement pris en charge. Veuillez convertir le fichier en SVG ou bitmap",
            unable_to_fetch_clipboard_img: "Échec de la récupération de l'image depuis votre presse-papiers",
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Lire',
            pause: 'Pause',
            stop: 'Arrêter',
            play_speed: 'Vitesse de lecture',
            travel_path: 'Trajet',
            invert: 'Inverser',
            preview_info: "Informations d'aperçu",
            size: 'Taille',
            estimated_time: 'Temps total estimé',
            cut_time: 'Temps de découpe',
            rapid_time: 'Temps de déplacement',
            cut_distance: 'Distance de découpe',
            rapid_distance: 'Distance de déplacement',
            current_position: 'Position actuelle',
            remark: '* Toutes les informations sont des valeurs estimées à titre indicatif.',
            start_here: 'Commencer ici',
            end_preview: "Fin de l'aperçu",
        },
        shapes_panel: {
            title: 'Formes',
            basic: 'De base',
            shape: 'Forme',
            graphics: 'Graphiques',
            arrow: 'Flèche',
            label: 'Étiquette',
            decor: 'Décor',
            circular: 'Circulaire',
            corner: 'Coin',
            line: 'Ligne',
            photo: 'Photo',
            ribbon: 'Ruban',
            speech: 'Discours',
            text: 'Zone de texte',
            animals: 'Animal',
            birds: 'Oiseau',
            land: 'Terre',
            sea: 'Mer',
            holidays: 'Vacances',
            celebration: 'Célébration',
            CNY: 'Nouvel an chinois',
            easter: 'Pâques',
            halloween: 'Halloween',
            valentines: 'Saint-Valentin',
            Xmas: 'Noël',
            nature: 'Nature',
            elements: 'Éléments',
            environment: 'Environnement',
            plants: 'Plantes',
            weather: 'Météo',
        },
        announcement_panel: {
            title: 'Annonce',
            dont_show_again: 'Ne plus afficher',
        },
    },
    editor: {
        prespray_area: 'Zone de pré-pulvérisation',
        opacity: "Opacité de l'aperçu",
        exposure: "Aperçu de l'exposition",
    },
    flux_id_login: {
        connection_fail: '#847 Échec de la connexion au service de membres FLUX.',
        login_success: 'Connexion réussie.',
        login: 'Connexion',
        unlock_shape_library: 'Connectez-vous pour déverrouiller la bibliothèque de formes.',
        email: 'E-mail',
        password: 'Mot de passe',
        remember_me: 'Se souvenir de moi',
        forget_password: 'Mot de passe oublié ?',
        register: 'Créer votre compte FLUX',
        offline: 'Travailler hors ligne',
        work_offline: 'Travailler hors ligne',
        incorrect: "L'adresse e-mail ou le mot de passe est incorrect.",
        not_verified: "L'adresse e-mail n'a pas encore été vérifiée.",
        new_to_flux: 'Nouveau chez FLUX ? Créez un compte.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Explorez les forfaits FLUX+',
            thank_you: "Merci d'être un membre précieux !",
            ai_credit_tooltip: "Pour la suppression de l'arrière-plan par IA",
            flux_credit_tooltip: "Pour les fichiers Design Market et la suppression de l'arrière-plan par IA",
            goto_member_center: 'Accédez à votre espace membre',
            access_plus_feature_1: 'Vous accédez à une',
            access_plus_feature_2: 'fonctionnalité.',
            access_plus_feature_note: 'Vous devez être membre de FLUX+ pour accéder à cette fonctionnalité.',
            access_monotype_feature: "Vous n'avez pas le module complémentaire Polices Monotype.",
            access_monotype_feature_note: 'Vous devez être membre de FLUX+ Pro ou avoir le module complémentaire Polices Monotype pour accéder à cette fonctionnalité.',
            learn_more: 'En savoir plus',
            get_addon: 'Obtenir le module',
            subscribe_now: 'Abonnez-vous maintenant',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: "Suppression de l'arrière-plan par IA",
                my_cloud: 'Stockage cloud illimité',
                boxgen: 'Générateur de boîtes 3D',
                dmkt: '1000+ fichiers de conception',
                monotype: '250+ polices premium',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Connectez-vous pour déverrouiller la base de données de formes.',
        enjoy_shape_library: 'Profitez de la base de données de formes.',
        shapes: 'Formes',
        elements: 'Éléments',
        recent: 'Récent',
        search: 'Rechercher',
        clear: 'Effacer',
        export_svg_title: "Impossible d'exporter le SVG",
        export_svg_warning: "Ce projet contient des objets protégés par les droits d'auteur. Par conséquent, Beam Studio exclura automatiquement ces objets lors de l'exportation. Vous pouvez toujours enregistrer votre projet au format Beam Studio Scene (.beam) pour conserver toutes vos données. Voulez-vous toujours exporter ?",
        learn_more: 'En savoir plus',
    },
    change_logs: {
        change_log: 'Journaux de modifications :',
        added: 'Ajouté :',
        fixed: 'Corrigé :',
        changed: 'Modifié :',
        see_older_version: 'Voir les versions précédentes',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: "Sélectionner l'appareil",
        auth_failure: "#811 Échec de l'authentification",
        unable_to_connect: "#810 Impossible d'établir une connexion stable avec la machine",
    },
    device: {
        pause: 'Mettre en pause',
        paused: 'En pause',
        pausing: 'Mise en pause',
        select_printer: "Sélectionner l'imprimante",
        retry: 'Réessayer',
        status: 'Statut',
        busy: 'Occupé',
        ready: 'Prêt',
        reset: 'Réinitialiser',
        abort: 'Abandonner',
        start: 'Démarrer',
        please_wait: 'Veuillez patienter...',
        quit: 'Quitter',
        completing: 'Finalisation',
        aborted: 'Abandonné',
        completed: 'Terminé',
        aborting: 'Abandon',
        starting: 'Démarrage',
        preparing: 'Préparation',
        resuming: 'Reprise',
        scanning: 'Analyse',
        occupied: 'Maintenance',
        running: 'Fonctionnement',
        uploading: 'Téléversement',
        processing: 'Traitement',
        disconnectedError: {
            caption: 'Machine déconnectée',
            message: "Veuillez confirmer si l'accès réseau de %s est disponible",
        },
        unknown: 'Inconnu',
        pausedFromError: 'Interrompu suite à une erreur',
        model_name: 'Nom du modèle',
        IP: 'IP',
        serial_number: 'Numéro de série',
        firmware_version: 'Version micrologiciel',
        UUID: 'UUID',
        select: 'Sélectionner',
        deviceList: 'Liste appareils',
        disable: 'Désactiver',
        enable: 'Activer',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: "Changer la tête de l'outil",
    },
    monitor: {
        monitor: 'SURVEILLER',
        go: 'Démarrer',
        resume: 'Reprendre',
        start: 'Démarrer',
        pause: 'Mettre en pause',
        stop: 'Arrêter',
        record: 'Enregistrer',
        camera: 'Caméra',
        taskTab: 'Tâche',
        connecting: 'Connexion en cours, veuillez patienter...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Erreur critique : Carte mère hors ligne. Veuillez contacter le support FLUX.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Erreur critique : Sous-système sans réponse. Veuillez contacter le support FLUX.',
        HARDWARE_ERROR_PUMP_ERROR: "#900 Veuillez vérifier votre réservoir d'eau.",
        HARDWARE_ERROR_DOOR_OPENED: '#901 Fermez la porte pour continuer.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Surchauffe. Veuillez attendre quelques minutes.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Fond ouvert. Fermez le fond pour continuer.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: "#910 Échec de la réinitialisation de l'axe Z",
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Tiroir ouvert',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Détection de flamme anormale',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: "#913 Débit d'air anormal",
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Aucun module rotatif détecté',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: "#920 Erreur de retrait lors de l'homing",
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Module incorrect détecté. Veuillez installer le bon module pour continuer.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Aucun module détecté. Assurez-vous que le module est correctement installé pour continuer.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Module inconnu détecté. Veuillez installer le bon module pour continuer.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Aucune réponse du module imprimante.',
        USER_OPERATION_ROTARY_PAUSE: 'Veuillez passer au moteur rotatif.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Veuillez retirer la cartouche pour continuer.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Module incorrect détecté. Veuillez installer le bon module pour continuer.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Aucun module détecté. Assurez-vous que le module est correctement installé pour continuer.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Module inconnu détecté. Veuillez installer le bon module pour continuer.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Veuillez installer le bon module pour continuer.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Veuillez insérer la cartouche pour continuer.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Veuillez insérer la cartouche correcte pour continuer.',
        USER_OPERATION: "Veuillez suivre les instructions sur le panneau de l'appareil pour continuer.",
        RESOURCE_BUSY: 'La machine est occupée. \nSi elle ne fonctionne pas, veuillez redémarrer la machine',
        DEVICE_ERROR: "Quelque chose s'est mal passé\\nVeuillez redémarrer la machine",
        NO_RESPONSE: '#905 Erreur de connexion à la carte mère.\\nVeuillez redémarrer la machine.',
        SUBSYSTEM_ERROR: "#402 Erreur critique : aucune réponse du sous-système. Veuillez contacter l'assistance FLUX.",
        HARDWARE_FAILURE: "Quelque chose s'est mal passé\\nVeuillez redémarrer la machine",
        MAINBOARD_OFFLINE: '#905 Erreur de connexion à la carte mère.\\nVeuillez redémarrer la machine.',
        bug_report: 'Rapport de bug',
        processing: 'Traitement en cours',
        savingPreview: 'Génération de miniatures',
        hour: 'h',
        minute: 'min',
        second: 's',
        left: 'gauche',
        temperature: 'Température',
        forceStop: 'Voulez-vous annuler la tâche en cours ?',
        upload: 'Téléverser',
        download: 'Télécharger',
        relocate: 'Repositionner',
        cancel: 'Annuler',
        prepareRelocate: 'Préparation pour repositionnement',
        extensionNotSupported: "Ce format de fichier n'est pas pris en charge",
        fileExistContinue: 'Le fichier existe déjà, voulez-vous le remplacer ?',
        confirmFileDelete: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
        ask_reconnect: 'La connexion avec la machine a été interrompue. Voulez-vous vous reconnecter ?',
        task: {
            BEAMBOX: 'Gravure laser',
            'N/A': 'Mode libre',
        },
    },
    alert: {
        caption: 'Erreur',
        duplicated_preset_name: 'Nom de préréglage dupliqué',
        info: 'INFO',
        warning: 'AVERTISSEMENT',
        error: 'ERREUR',
        instruction: 'Instruction',
        oops: 'Oups...',
        retry: 'Réessayer',
        abort: 'Abandonner',
        confirm: 'Confirmer',
        cancel: 'Annuler',
        close: 'Fermer',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Oui',
        no: 'Non',
        stop: 'Arrêter',
        save: 'Enregistrer',
        dont_save: 'Ne pas enregistrer',
        learn_more: 'En savoir plus',
    },
    caption: {
        connectionTimeout: 'Délai de connexion',
    },
    message: {
        cancelling: 'Annulation...',
        connecting: 'Connexion en cours...',
        connectingMachine: 'Connexion de %s en cours...',
        tryingToConenctMachine: 'Tentative de connexion à la machine...',
        connected: 'Connecté',
        authenticating: 'Authentification en cours...',
        enteringRawMode: 'Accès au mode brut...',
        endingRawMode: 'Sortie du mode brut...',
        enteringLineCheckMode: 'Accès au mode de vérification de ligne...',
        endingLineCheckMode: 'Sortie du mode de vérification de ligne...',
        enteringRedLaserMeasureMode: 'Entrée en mode de mesure au laser rouge...',
        redLaserTakingReference: 'Prise de référence...',
        exitingRotaryMode: 'Sortie du mode rotatif...',
        turningOffFan: 'Arrêt du ventilateur...',
        turningOffAirPump: 'Arrêt de la pompe à air...',
        gettingLaserSpeed: 'Lecture de la vitesse de la tête laser...',
        settingLaserSpeed: 'Réglage de la vitesse de la tête laser...',
        retrievingCameraOffset: 'Lecture du décalage de la caméra...',
        connectingCamera: 'Connexion de la caméra...',
        homing: 'Homing...',
        connectionTimeout: "#805 Délai de connexion du périphérique. Veuillez vérifier l'état de votre réseau et le voyant Wi-Fi de votre machine.",
        getProbePosition: 'Obtention de la position de la sonde...',
        device_not_found: {
            caption: 'Machine par défaut introuvable',
            message: '#812 Veuillez vérifier le voyant Wi-Fi de votre machine',
        },
        device_busy: {
            caption: 'Machine occupée',
            message: 'La machine exécute une autre tâche, réessayez plus tard. Si elle ne fonctionne plus, veuillez redémarrer la machine.',
        },
        device_is_used: 'La machine est utilisée, voulez-vous annuler la tâche en cours ?',
        unknown_error: "#821 L'application a rencontré une erreur inconnue, veuillez utiliser Aide > Menu > Rapport de bug.",
        unknown_device: "#826 Impossible de se connecter à la machine, assurez-vous que l'USB est connecté à la machine",
        unsupport_osx_version: 'La version actuelle de macOS %s peut ne pas prendre en charge certaines fonctions. Veuillez mettre à jour vers macOS 11+.',
        unsupport_win_version: 'La version actuelle de %s peut ne pas prendre en charge certaines fonctions. Veuillez mettre à jour vers la dernière version.',
        need_password: 'Mot de passe requis pour se connecter à la machine',
        unavailableWorkarea: '#804 La zone de travail actuelle dépasse la zone de travail de cette machine. Veuillez vérifier la zone de travail de la machine sélectionnée ou définir la zone de travail à partir de Edition > Paramètres du document.',
        please_enter_dpi: "Veuillez entrer l'unité de votre fichier (en mm)",
        auth_error: "#820 Erreur d'authentification : veuillez mettre à jour Beam Studio et le firmware de la machine vers la dernière version.",
        usb_unplugged: 'La connexion USB est perdue. Veuillez vérifier votre connexion USB',
        uploading_fcode: 'Téléversement du code F',
        cant_connect_to_device: '#827 Impossible de se connecter à la machine, veuillez vérifier votre connexion',
        unable_to_find_machine: 'Impossible de trouver la machine',
        disconnected: "Connexion instable, veuillez vérifier la connexion de l'appareil et réessayer plus tard",
        unable_to_start: '#830 Impossible de démarrer la tâche. Veuillez réessayer. Si cela se reproduit, veuillez nous contacter avec un rapport de bug :',
        camera: {
            camera_cable_unstable: "Il est détecté que la caméra transfère des photos de manière instable. L'aperçu peut encore être effectué normalement, mais il peut y avoir un problème d'aperçu lent ou de dépassement de délai.",
            fail_to_transmit_image: '#845 Quelque chose s\'est mal passé avec la transmission de l\'image. Veuillez redémarrer votre machine ou Beam Studio. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">ce guide</a>.',
            ws_closed_unexpectly: '#844 La connexion à la caméra de la machine a été fermée de manière inattendue. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">ce guide</a>.',
            continue_preview: 'Continuer',
            abort_preview: 'Abandonner',
        },
        preview: {
            camera_preview: 'Aperçu de la caméra',
            auto_focus: 'Mise au point automatique',
            auto_focus_instruction: "Veuillez déplacer la tête du module laser au-dessus de l'objet et suivre les instructions d'animation pour appuyer sur AF pour effectuer la mise au point.",
            already_performed_auto_focus: 'Vous avez déjà effectué la mise au point automatique, utiliser les valeurs existantes ?',
            please_enter_height: "Veuillez entrer la hauteur de l'objet. Afin de capturer un cliché de caméra précis.",
            apply: 'Appliquer',
            enter_manually: 'Entrer manuellement',
            adjust: 'Ajuster',
            adjust_height_tooltip: "Cliquez sur la case à cocher pour activer l'édition.",
        },
        unsupported_example_file: "Le fichier d'exemple sélectionné n'est pas pris en charge par la zone de travail actuelle.",
        time_remaining: 'Temps restant:',
        promark_disconnected: "#850 Connexion de l'appareil interrompue, veuillez vérifier l'état de connexion de l'appareil.",
        swiftray_disconnected: 'Impossible de se connecter au backend, tentative de reconnexion en cours.',
        swiftray_reconnected: "Backend reconnecté, veuillez réessayer d'envoyer la tâche.",
        device_blocked: {
            caption: 'Numéro de série non autorisé',
            online: "Le numéro de série de votre appareil semble être désactivé. Veuillez fournir le numéro de série de la machine (situé à l'arrière de l'appareil) à votre revendeur et demandez-lui de contacter support@flux3dp.com pour activer l'autorisation de l'appareil.",
            offline: "Le numéro de série de votre appareil est limité à une utilisation hors ligne car il semble être désactivé. Veuillez fournir le numéro de série de la machine (situé à l'arrière de l'appareil) à votre revendeur et demandez-lui de contacter support@flux3dp.com pour activer l'autorisation en ligne. Si vous souhaitez utiliser l'appareil hors ligne, veuillez contacter directement support@flux3dp.com.",
        },
    },
    machine_status: {
        '0': 'Inactif',
        '1': 'Initialisation',
        '2': 'ST_TRANSFORM',
        '4': 'Démarrage',
        '6': 'Reprise',
        '16': 'Travail',
        '18': 'Reprise',
        '32': 'En pause',
        '36': 'En pause',
        '38': 'En pause',
        '48': 'En pause',
        '50': 'En pause',
        '64': 'Terminé',
        '66': 'Finalisation en cours',
        '68': 'Préparation',
        '128': 'Abandonné',
        '256': 'Alarme',
        '512': 'Fatal',
        '-17': 'Mode E/S de cartouche',
        '-10': 'Mode maintenance',
        '-2': 'Numérisation',
        '-1': 'Maintenance',
        UNKNOWN: 'Inconnu',
    },
    calibration: {
        update_firmware_msg1: 'Votre micrologiciel ne prend pas en charge cette fonctionnalité. Veuillez mettre à jour le micrologiciel en v',
        update_firmware_msg2: 'ou supérieur pour continuer。 (Menu > Machine > [Votre machine] > Mettre à jour le micrologiciel)',
        camera_calibration: 'Étalonnage de la caméra',
        diode_calibration: 'Étalonnage du module laser à diode',
        module_calibration_printer: "Étalonnage du module d'impression",
        module_calibration_2w_ir: 'Étalonnage du module infrarouge',
        back: 'Retour',
        next: 'Suivant',
        skip: 'Sauter',
        cancel: 'Annuler',
        finish: 'Terminer',
        do_engraving: 'Gravure',
        start_engrave: 'Commencer Gravure',
        start_printing: 'Commencer Impression',
        ask_for_readjust: "Voulez-vous sauter l'étape de gravure et ensuite prendre une photo et calibrer directement ?",
        please_goto_beambox_first: "Veuillez passer en mode gravure (Beambox) afin d'utiliser cette fonctionnalité.",
        please_place_paper: 'Veuillez placer une feuille de papier blanc de format A4 ou Letter dans le coin supérieur gauche de la zone de travail.',
        please_place_paper_center: 'Veuillez placer une feuille de papier blanc de format A4 ou Letter au centre de la zone de travail.',
        please_place_dark_colored_paper: 'Veuillez placer une feuille de papier de couleur foncée de format A4 ou Letter au centre de la zone de travail.',
        please_refocus: {
            beambox: "Veuillez ajuster la plateforme au point focal (la hauteur de l'acrylique retournée)",
            beamo: "Veuillez ajuster la tête laser pour se concentrer sur l'objet de gravure (la hauteur de l'acrylique retournée)",
            beamo_af: "Veuillez double-cliquer sur le bouton latéral de l'accessoire de mise au point automatique et laisser la sonde toucher doucement le matériau.",
            hexa: "Double-cliquez sur le bouton de réglage de la hauteur pour soulever la table en nid d'abeille et faire toucher la sonde au matériau de gravure.",
        },
        without_af: 'Sans mise au point automatique',
        with_af: 'Avec mise au point automatique',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotation',
        x_ratio: 'Ratio X',
        y_ratio: 'Ratio Y',
        show_last_config: 'Afficher le dernier résultat',
        use_last_config: "Utiliser la dernière valeur d'étalonnage",
        taking_picture: 'Prise de photo...',
        analyze_result_fail: "Échec de l'analyse de l'image capturée. <br/> Veuillez vous assurer : <br/> 1. L'image capturée couvre entièrement la feuille blanche. <br/> 2. La plateforme est correctement mise au point.",
        drawing_calibration_image: "Dessin de l'image d'étalonnage...",
        calibrate_done: "Étalonnage terminé. Une meilleure précision de l'appareil photo est obtenue lorsque la mise au point est précise.",
        calibrate_done_diode: 'Étalonnage terminé. Le décalage du module laser à diode a été enregistré.',
        hint_red_square: "Veuillez aligner l'extérieur du carré rouge avec le carré découpé.",
        hint_adjust_parameters: 'Utilisez ces paramètres pour ajuster le carré rouge',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us-fr/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: "Lors de l'étalonnage du module laser à diode, une caméra est nécessaire.\nVeuillez vous assurer que la caméra de cette machine a été étalonnée.\nEt veuillez régler la plateforme au point focal (la hauteur de l'acrylique retournée)",
            beamo: "Lors de l'étalonnage du module laser à diode, une caméra est nécessaire.\nVeuillez vous assurer que la caméra de cette machine a été étalonnée.\nEt veuillez régler la tête laser pour se concentrer sur l'objet de gravure (la hauteur de l'acrylique retournée)",
        },
        downloading_pictures: 'Téléchargement des images...',
        failed_to_download_pictures: '#848 Échec du téléchargement des images, veuillez contacter le support FLUX.',
        uploading_images: 'Téléversement des images...',
        calculating_camera_matrix: 'Calcul de la matrice de caméra...',
        calculating_regression_parameters: 'Calcul des paramètres de régression...',
        failed_to_calibrate_camera: "#848 Échec de l'étalonnage de la caméra, veuillez contacter le support FLUX.",
        failed_to_save_calibration_results: "#849 Échec de l'enregistrement des résultats d'étalonnage, veuillez réessayer. Si cela se reproduit, veuillez contacter le support FLUX.",
        ador_autofocus_material: "Appuyez sur l'icône « AF » sur la page principale de la machine pendant 3 secondes et laissez la sonde toucher doucement le matériau.",
        ador_autofocus_focusing_block: "Appuyez sur l'icône « AF » sur la page principale de la machine pendant 3 secondes et laissez la sonde toucher le bloc de mise au point.",
        align_red_cross_cut: 'Veuillez aligner le milieu de la croix rouge avec la croix de coupe.',
        align_red_cross_print: 'Veuillez aligner le milieu de la croix rouge avec la croix imprimée.',
        retake: 'Reprendre la photo',
        calibrate_camera_before_calibrate_modules: "Veuillez effectuer l'étalonnage de la caméra avant d'étalonner les modules.",
        check_checkpoint_data: 'Données de point de contrôle',
        checking_checkpoint: 'Vérification des données de point de contrôle...',
        found_checkpoint: 'Les données du point de contrôle ont été trouvées sur votre appareil. Souhaitez-vous les récupérer à partir du point de contrôle?',
        use_old_camera_parameter: "Voulez-vous utiliser les paramètres actuels de l'objectif de l'appareil photo?",
        downloading_checkpoint: 'Téléchargement des données du point de contrôle...',
        failed_to_parse_checkpoint: "Impossible d'analyser les données du point de contrôle.",
        check_device_pictures: "Vérifier les images de l'appareil",
        checking_pictures: "Vérification des images de l'appareil en cours...",
        no_picutre_found: "#846 Votre appareil n'a pas de photos brutes disponibles pour l'étalonnage. Veuillez contacter le support FLUX.",
        unable_to_load_camera_parameters: "#851 Aucun paramètre de calibration de la caméra n'est disponible sur votre appareil. Allez dans 'Calibration' > 'Calibrer la caméra (Avancé)' pour terminer la calibration et obtenir les paramètres.",
        calibrating_with_device_pictures: "Calibration avec les images de l'appareil en cours...",
        failed_to_calibrate_with_pictures: "#848 Échec de la calibration avec les images de l'appareil.",
        getting_plane_height: 'Obtenir la Hauteur du Plan...',
        preparing_to_take_picture: 'Préparation à la prise de photo...',
        put_paper: 'Mettre du papier',
        put_paper_step1: 'Veuillez placer une feuille de papier blanc de format A4 ou Lettre au centre de la zone de travail.',
        put_paper_step2: "Fixez les quatre coins du papier pour qu'il soit bien plat.",
        put_paper_step3: 'Cliquez sur "Commencer Gravure".',
        put_paper_promark_1: "Placez le carton noir de la boîte d'accessoires sur la plateforme de travail.",
        put_paper_promark_2: 'Ajustez la mise au point correctement, puis cliquez sur "Commencer Gravure" pour continuer la gravure.',
        put_paper_skip: 'Si le dessin de calibration ne passe pas automatiquement à la prise de photo, appuyez sur « Ignorer » pour prendre une photo.',
        solve_pnp_title: 'Aligner les points de repère',
        solve_pnp_step1: 'Veuillez aligner les points de gravure selon le numéro et la position attendue de chaque point marqueur rouge.',
        solve_pnp_step2: 'Vous pouvez appuyer sur "Reprendre la photo" pour réaligner ou ajuster manuellement les positions des marqueurs.',
        align_olt: 'Position: Alignez avec le point gravé en haut à gauche extérieur.',
        align_ort: 'Position: Alignez avec le point gravé en haut à droite extérieur.',
        align_olb: 'Position: Alignez avec le point gravé en bas à gauche extérieur.',
        align_orb: 'Position: Alignez avec le point gravé en bas à droite extérieur.',
        align_ilt: 'Position: Alignez avec le point gravé en haut à gauche intérieur.',
        align_irt: 'Position: Alignez avec le point gravé en haut à droite intérieur.',
        align_ilb: 'Position: Alignez avec le point gravé en bas à gauche intérieur.',
        align_irb: 'Position: Alignez avec le point gravé en bas à droite intérieur.',
        elevate_and_cut: 'Élever et Couper',
        elevate_and_cut_step_1: "Placez un morceau de bois de couleur claire de format A4 au centre de l'espace de travail, surélevé à 20 mm.",
        elevate_and_cut_step_1_prism_lift: "Utilisez Ador Prism Lift avec une longueur maximale de 14 mm avec un morceau de bois d'au moins 6 mm d'épaisseur.",
        with_prism_lift: 'Avec Ador Prism Lift',
        without_prism_lift: 'Sans Ador Prism Lift',
        camera_parameter_saved_successfully: "Paramètres de l'appareil photo enregistrés avec succès.",
        failed_to_save_camera_parameter: "Échec de l'enregistrement des paramètres de l'appareil photo.",
        failed_to_solve_pnp: 'Échec de la résolution de la position de la caméra.',
        calibrating: 'Calibrage...',
        moving_laser_head: 'Déplacement de la tête laser...',
        failed_to_move_laser_head: 'Échec du déplacement de la tête laser.',
        put_chessboard: 'Placer le Damier',
        put_chessboard_bb2_desc_1: "Téléchargez le fichier d'échiquier suivant et imprimez-le sur une feuille A4 (l'échiquier imprimé doit comporter des cases de 1x1 cm).",
        put_chessboard_bb2_desc_2: "Fixez l'échiquier imprimé sur une planche indéformable, comme de l'acrylique ou du verre, en vous assurant que l'échiquier est lisse et sans plis ni soulèvements.",
        put_chessboard_bb2_desc_3: "Placez la planche avec l'échiquier bien à plat au centre de la zone de travail.",
        put_chessboard_1: 'Veuillez vous rendre au panneau de commande de la machine et appuyez sur AF pour effectuer la mise au point automatique.',
        put_chessboard_2: "Veuillez déplacer le papier à damier ou la tête laser jusqu'à ce que l'ensemble du damier soit confirmé dans le cadre rouge de la fenêtre de prévisualisation en direct.",
        put_chessboard_3: "Cliquez avec le bouton droit pour télécharger l'image de l’échiquier à l'emplacement actuel et vérifier sa netteté.",
        put_chessboard_promark_desc_1: "Veuillez utiliser le papier quadrillé fourni dans la boîte d'accessoires ou imprimez le motif de quadrillage suivant sur du papier A4 pour l'étalonnage de la caméra.",
        put_chessboard_promark_desc_2: 'Veuillez placer le damier à plat et au centre de la plateforme de travail.',
        put_chessboard_promark_1: 'Ajustez la distance focale à la mise au point appropriée pour la lentille de champ.',
        put_chessboard_promark_2: 'Assurez-vous que le papier quadrillé est bien visible sans éblouissement. Cliquez sur "Suivant" pour prendre une photo.',
        download_chessboard_file: 'Télécharger le fichier échiquier',
        failed_to_calibrate_chessboard: "Échec de la calibration avec l'image de l'échiquier.",
        calibrate_chessboard_success_msg: 'La photo de l’échiquier a été capturée avec succès.<br/>Le score pour cette photo est %s (%.2f).',
        res_excellent: 'Excellent',
        res_average: 'Moyen',
        res_poor: 'Médiocre',
        perform_autofocus_bb2: 'Veuillez vous rendre au panneau de commande de la machine et appuyez sur AF pour effectuer la mise au point automatique.',
        promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    },
    input_machine_password: {
        require_password: '"%s" nécessite un mot de passe',
        connect: 'CONNECTER',
        password: 'Mot de passe',
    },
    tutorial: {
        skip: 'Passer',
        welcome: 'BIENVENUE',
        suggest_calibrate_camera_first: 'Nous conseillons aux utilisateurs de calibrer la caméra initialement et de refocaliser avant chaque prévisualisation pour des résultats optimaux.<br/>Voulez-vous confirmer pour effectuer la calibration maintenant ?<br/>(Vous pouvez le sauter maintenant et le faire plus tard en cliquant sur "Menu" > "Machine" > [Votre Machine] > "Calibrer la Caméra".)',
        camera_calibration_failed: "Échec d'étalonnage de la caméra",
        ask_retry_calibration: 'Voulez-vous réétalonner la caméra ?',
        needNewUserTutorial: 'Souhaitez-vous commencer un tutoriel ?<br/>(Vous pouvez le sauter maintenant et le commencer plus tard en cliquant sur "Aide" > "Afficher le Tutoriel de Démarrage".)',
        needNewInterfaceTutorial: 'Souhaitez-vous commencer un tutoriel pour la nouvelle interface de Beam Studio ?<br/>(Vous pouvez le sauter maintenant et le commencer plus tard en cliquant sur "Aide" > "Afficher l\'Introduction à l\'Interface".)',
        next: 'SUIVANT',
        look_for_machine: 'Recherche de la machine pour le didacticiel...',
        unable_to_find_machine: 'Impossible de trouver la machine pour le didacticiel. Voulez-vous accéder à la page des paramètres de connexion, réessayer ou ignorer le didacticiel ?',
        skip_tutorial: 'Vous avez sauté le tutoriel. Vous pouvez toujours démarrer le tutoriel en cliquant sur "Aide" > "Afficher le tutoriel de démarrage"',
        set_connection: 'Définir la connexion',
        retry: 'Réessayer',
        newUser: {
            draw_a_circle: 'Dessiner un cercle',
            drag_to_draw: 'Faites glisser pour dessiner',
            infill: 'Activer le remplissage',
            switch_to_object_panel: 'Passer au panneau des objets',
            switch_to_layer_panel: 'Passer au panneau des calques',
            set_preset_wood_engraving: 'Définir le préréglage : Bois - Gravure',
            set_preset_wood_cut: 'Définir le préréglage : Bois - Découpe',
            add_new_layer: 'Ajouter un nouveau calque',
            draw_a_rect: 'Dessiner un rectangle',
            switch_to_preview_mode: 'Passer en mode Aperçu',
            preview_the_platform: 'Aperçu de la plateforme',
            end_preview_mode: 'Quitter le mode Aperçu',
            put_wood: "1. Mettre l'échantillon de bois",
            adjust_focus: '2. Régler la mise au point',
            close_cover: '3. Fermer le couvercle',
            send_the_file: 'Envoyer le fichier',
            end_alert: 'Êtes-vous sûr de vouloir terminer le tutoriel ?',
            please_select_wood_engraving: 'Veuillez sélectionner le préréglage "Bois - Gravure".',
            please_select_wood_cutting: 'Veuillez sélectionner le préréglage "Bois - Découpe".',
        },
        newInterface: {
            camera_preview: 'Aperçu de la caméra',
            select_image_text: 'Sélectionner / Image / Texte',
            basic_shapes: 'Formes de base',
            pen_tool: 'Outil Plume',
            add_new_layer: 'Ajouter un nouveau calque',
            rename_by_double_click: 'Renommer en double-cliquant',
            drag_to_sort: 'Glisser pour trier',
            layer_controls: 'Clic droit pour sélectionner les contrôles de calque :\nDupliquer / Fusionner / Verrouiller / Supprimer des calques',
            switch_between_layer_panel_and_object_panel: 'Basculer entre le panneau des calques et le panneau des objets',
            align_controls: 'Aligner les commandes',
            group_controls: 'Grouper les commandes',
            shape_operation: 'Opération de forme',
            flip: 'Retourner',
            object_actions: "Actions sur l'objet",
            end_alert: "Êtes-vous sûr de vouloir terminer l'introduction à la nouvelle interface utilisateur ?",
            select_machine: 'Sélectionner une machine',
            start_work: 'Commencer le travail',
        },
        gesture: {
            pan: 'Faites défiler le canevas avec deux doigts.',
            zoom: 'Pincer avec deux doigts pour zoomer / dézoomer le canevas.',
            click: "Appuyez pour sélectionner l'objet.",
            drag: 'Faites glisser pour sélectionner plusieurs objets.',
            hold: 'Appuyez et maintenez enfoncé pour ouvrir le menu contextuel.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: "C'est tout pour le didacticiel. Maintenant, c'est le moment de créer !",
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: 'Laser diode 10W',
        laser_20w_diode: 'Laser diode 20W',
        laser_2w_infrared: 'Laser infrarouge 2W',
        printing: 'Impression',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Voulez-vous convertir le module Impression en module Laser ?',
            convertFromPrintingModuleMsg: "Veuillez noter que si vous terminez cette opération, les paramètres de couleur de la couche d'impression seront supprimés et définis en fonction de la couche actuelle.",
            convertFromLaserModuleTitle: 'Voulez-vous convertir le module Laser en module Impression ?',
            convertFromLaserModuleMsg: 'Veuillez noter que si vous terminez cette opération, les paramètres de la couche laser seront supprimés et définis en fonction de la couche actuelle.',
            importedDocumentContainsPrinting: "Le document contient une couche d'impression, souhaitez-vous changer l'espace de travail en Ador ?",
            printingLayersCoverted: "Les couches d'impression ont été converties en couches laser.",
            performPrintingCaliTitle: "Effectuez l'étalonnage du module d'impression",
            performPrintingCaliMsg: "Cliquez sur « Confirmer » pour exécuter l'étalonnage ou accédez à l'étalonnage via le menu supérieur. <br /> (Machine> [Nom de votre machine]> Étalonner le module d'impression)",
            performIRCaliTitle: "Effectuez l'étalonnage du module infrarouge",
            performIRCaliMsg: "Cliquez sur « Confirmer » pour exécuter l'étalonnage ou accédez à l'étalonnage via le menu supérieur. <br /> (Machine > [Nom de votre machine] > Étalonner le module infrarouge)",
        },
        non_working_area: 'Zone non-travail',
    },
    qr_code_generator: {
        title: 'Générateur de code QR',
        placeholder: 'Saisissez un lien ou du texte',
        preview: 'Aperçu',
        error_tolerance: 'Tolérance aux erreurs',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Inverser la couleur de fond',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Boîte basique',
        coming_soon: 'Bientôt disponible',
        workarea: 'Zone de travail',
        max_dimension_tooltip: 'La largeur/hauteur/profondeur maximale est de %s.',
        volume: 'Volume',
        outer: 'Extérieur',
        inner: 'Intérieur',
        width: 'Largeur',
        height: 'Hauteur',
        depth: 'Profondeur',
        cover: 'Couvercle',
        thickness: 'Épaisseur',
        add_option: 'Ajouter option',
        joints: 'Joint',
        finger: 'Doigt',
        finger_warning: "La longueur intérieure du côté de la boîte doit être d'au moins 6 mm (0,24 pouces) pour être compatible avec l'assemblage par doigt.",
        tSlot: 'Rainure en T',
        tSlot_warning: "La longueur du côté de la boîte doit être d'au moins 30 mm (1,18 pouce) pour être compatible avec l'assemblage par rainure en T.",
        edge: 'Bord',
        tCount: 'Nombre de T',
        tCount_tooltip: "Le nombre de rainures en T s'applique au côté court ; la quantité sur le côté long est calculée en fonction de sa longueur.",
        tDiameter: 'Diamètre de T',
        tLength: 'Longueur de T',
        continue_import: 'Continuer à importer',
        customize: 'Personnaliser',
        merge: 'Fusionner',
        text_label: 'Étiquette',
        beam_radius: 'Compensation du faisceau',
        beam_radius_warning: "Supprimez la compensation de la rainure lorsque les bords ou les joints de la boîte sont courts pour assurer l'assemblage de la boîte",
        import: 'Importer',
        cancel: 'Annuler',
        reset: 'Réinitialiser',
        zoom: 'Zoom',
        control_tooltip: 'Clic gauche pour pivoter\nMolette pour zoomer\nClic droit pour déplacer',
        control_tooltip_touch: 'Glisser pour pivoter\nPincer pour zoomer\nDeux doigts pour déplacer',
    },
    my_cloud: {
        title: 'Mon cloud',
        loading_file: 'Chargement...',
        no_file_title: 'Enregistrez des fichiers dans Mon cloud pour commencer.',
        no_file_subtitle: 'Accédez au menu > "Fichier" > "Enregistrer dans le cloud"',
        file_limit: 'Fichier gratuit',
        upgrade: 'Mettre à niveau',
        sort: {
            most_recent: 'Plus récent',
            oldest: 'Plus ancien',
            a_to_z: 'Nom : A - Z',
            z_to_a: 'Nom : Z - A',
        },
        action: {
            open: 'Ouvrir',
            rename: 'Renommer',
            duplicate: 'Dupliquer',
            download: 'Télécharger',
            delete: 'Supprimer',
            confirmFileDelete: 'Êtes-vous sûr de vouloir supprimer ce fichier ? Cette action est irréversible.',
        },
        save_file: {
            choose_action: 'Enregistrer le fichier :',
            save: 'Enregistrer',
            save_new: 'Enregistrer sous',
            input_file_name: 'Entrer le nom du fichier :',
            invalid_char: 'Caractères non valides :',
            storage_limit_exceeded: "Votre stockage cloud a atteint la limite supérieure. Veuillez supprimer les fichiers inutiles avant d'en sauvegarder de nouveaux.",
        },
    },
    camera_data_backup: {
        title: 'Sauvegarde des données de la caméra',
        no_picture_found: 'Aucune image trouvée dans la machine.',
        folder_not_exists: "Le dossier sélectionné n'existe pas.",
        incorrect_folder: 'Échec du téléversement des données de calibration. Veuillez vérifier si le dossier que vous avez sélectionné est correct.',
        downloading_data: 'Téléchargement des données...',
        estimated_time_left: 'Temps estimé restant :',
        uploading_data: 'Chargement des données...',
        download_success: 'Données de la caméra téléchargées avec succès.',
        upload_success: 'Données de la caméra téléchargées avec succès.',
    },
    insecure_websocket: {
        extension_detected: 'Extension Beam Studio Connect détectée',
        extension_detected_description: "Nous avons détecté que vous avez installé l'extension Beam Studio Connect. Veuillez cliquer sur 'Confirmer' pour rediriger vers HTTPS, ou cliquez sur 'Annuler' pour continuer à utiliser HTTP.",
        extension_not_deteced: "Impossible de détecter l'extension Beam Studio Connect",
        extension_not_deteced_description: "Pour utiliser HTTPS, cliquez sur 'Confirmer' pour installer l'extension Beam Studio Connect. Après avoir installé l'extension, veuillez actualiser la page pour l'activer.<br/>Sinon, cliquez sur le lien ci-dessous pour voir comment utiliser HTTP sur Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Lien</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Mesurer la zone de mise au point automatique',
        amount: 'Quantité',
        gap: 'Écart',
        rows: 'Rangées',
        coloumns: 'Colonnes',
        row_gap: 'Écart entre les rangées',
        column_gap: 'Écart entre les colonnes',
        set_object_height: "Définir la hauteur de l'objet",
        set_object_height_desc: "Mesurez l'épaisseur maximale de l'objet.",
        reselect_area: 'Re-sélectionner la zone',
        start_autofocus: 'Démarrer la mise au point automatique',
        starting_measurement: 'Démarrage de la mesure...',
        preview_3d_curve: 'Aperçu de la courbe 3D',
        apply_arkwork: "Appliquer l'œuvre d'art sur la courbe 3D",
        apply_camera: "Appliquer l'image de la caméra sur la courbe 3D",
        click_to_select_point: 'Cliquez pour sélectionner ou désélectionner les points à mesurer à nouveau.',
        remeasure: 'Re-mesurer',
        remeasuring_points: 'Remesure des points...',
        take_reference: 'Prendre référence',
        take_reference_desc: 'Veuillez déplacer la tête laser au point le plus haut de l’objet, abaisser la sonde de mise au point et cliquer sur « Confirmer » pour faire la mise au point.',
        sure_to_delete: 'Voulez-vous supprimer les données de mise au point de la courbe 3D ?',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    },
    pass_through: {
        title: 'Mode Passage',
        help_text: 'Comment configurer Mode Passage pour Ador?',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: "Longueur de l'Objet",
        workarea_height: 'Zone de Travail (Hauteur):',
        height_desc: 'Réglez la hauteur de chaque section de la zone de travail.',
        ref_layer: 'Couche de Référence',
        ref_layer_desc: "Veuillez noter que l'exécution de la couche de référence est par défaut définie sur 0. Elle ne sera pas exécutée mais est uniquement destinée à la référence d'alignement.",
        ref_layer_name: 'Référence',
        guide_mark: 'Marque de Guidage',
        guide_mark_length: 'Diamètre:',
        guide_mark_x: 'Coordonnée X:',
        guide_mark_desc: "Les marques de guidage seront gravées comme point de référence pour aligner l'œuvre d'art.",
        export: 'Exporter vers la Zone de Travail',
        exporting: 'Exportation...',
    },
    auto_fit: {
        title: 'Ajustement Automatique',
        step1: "1. Placez l'objet à l'emplacement souhaité sur une pièce de matériau.",
        step2: "2. Appuyez sur 'Confirmer' pour dupliquer l'objet sur toutes les pièces similaires de matériau dans l'aperçu de la caméra.",
        preview_first: "Veuillez d'abord effectuer l'aperçu de la caméra.",
        failed_to_auto_fit: "Échec de l'ajustement automatique, veuillez vérifier :",
        error_tip1: "1. L'œuvre est-elle correctement placée sur le matériau ?",
        error_tip2: '2. Les contours du matériau sont-ils suffisamment clairs pour être reconnus ?',
        learn_more: "Découvrez comment fonctionne l'ajustement automatique.",
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'Type',
        object_diameter: "Diamètre de l'Objet",
        circumference: 'Circonférence',
    },
    framing: {
        low_laser: 'Laser faible',
        low_laser_desc: 'Définissez une valeur de laser à faible puissance pour la tâche de cadrage.',
        framing: 'Cadrage',
        hull: 'Coque',
        area_check: 'Vérification de la zone',
        framing_desc: "Aperçu de la boîte englobante de l'objet.",
        hull_desc: "Affiche un aperçu d'une forme qui suit étroitement le design, comme un élastique enroulé autour de l'objet.",
        areacheck_desc: "Assure la sécurité de la zone de travail en prévisualisant la boîte englobante de l'objet et la zone d'accélération de la tête laser.",
        calculating_task: 'Calcul de la tâche...',
    },
    material_test_generator: {
        title: 'Générateur de Test de Matériau',
        table_settings: 'Paramètres du Tableau',
        block_settings: 'Paramètres du Bloc',
        text_settings: 'Paramètres texte',
        preview: 'Aperçu',
        export: 'Exporter',
        cut: 'Couper',
        engrave: 'Graver',
        columns: 'Colonnes',
        rows: 'Lignes',
        parameter: 'Paramètre',
        min: 'Min',
        max: 'Max',
        count: 'Quantité',
        size: 'Taille (HxL)',
        spacing: 'Espacement',
    },
    web_cam: {
        no_permission: "Beam Studio n'a pas l'autorisation d'accéder à la caméra. Assurez-vous que l'autorisation est accordée à Beam Studio dans les paramètres du navigateur ou du système.",
        no_device: "Impossible de détecter l'appareil photo. Veuillez reconnecter la caméra et réessayer.",
    },
    promark_settings: {
        title: 'Paramètres Promark',
        field: 'Champ',
        red_dot: 'Point rouge',
        galvo_configuration: 'Configuration Galvo',
        switchXY: 'Changer X/Y',
        workarea_hint: 'Vous pouvez changer la zone de travail dans les "Paramètres du document".',
        offsetX: 'Décalage X',
        offsetY: 'Décalage Y',
        angle: 'Angle',
        scaleX: 'Échelle X',
        scaleY: 'Échelle Y',
        scale: 'Échelle',
        bulge: 'Renflement',
        skew: 'Inclinaison',
        trapezoid: 'Trapèze',
        mark_parameters: 'Paramètres de marquage',
        preview: 'Aperçu',
        mark: 'Marquer',
        z_axis_adjustment: {
            title: 'Réglage de l’Axe Z',
            section1: 'Ajustez la hauteur de l’axe Z pour affiner la mise au point.',
            tooltip1: 'Essayez de marquer un carré de 1x1 cm pour confirmer si la distance focale actuelle est appropriée.',
        },
    },
    code_generator: {
        qr_code: 'Code QR',
        barcode: 'Code-barres',
    },
    barcode_generator: {
        bar_width: 'Largeur de barre',
        bar_height: 'Hauteur de barre',
        text_margin: 'Marge de texte',
        invert_color: 'Inverser couleur',
        font: 'Police',
        font_size: 'Taille de police',
        hide_text: 'Masquer le texte',
        barcode: {
            invalid_value: 'La valeur est invalide pour le format sélectionné.',
        },
    },
    social_media: {
        instagram: 'Obtenez les dernières inspirations, offres et cadeaux gratuits !',
        facebook: 'Discutez avec les FLUXers, posez des questions et apprenez des astuces !',
        youtube: 'Consultez les tutoriels de Beam Studio et des idées créatives.',
    },
};
export default lang;
