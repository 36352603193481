var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useCallback, useEffect, useRef, useState, } from 'react';
import alertCaller from 'app/actions/alert-caller';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import curveEngravingModeController from 'app/actions/canvas/curveEngravingModeController';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FnWrapper from 'app/actions/beambox/svgeditor-function-wrapper';
import getDevice from 'helpers/device/get-device';
import PreviewModeController from 'app/actions/beambox/preview-mode-controller';
import showResizeAlert from 'helpers/device/fit-device-workarea-alert';
import tutorialConstants from 'app/constants/tutorial-constants';
import tutorialController from 'app/views/tutorials/tutorialController';
import useForceUpdate from 'helpers/use-force-update';
import useI18n from 'helpers/useI18n';
import workareaManager from 'app/svgedit/workarea';
import { getLatestDeviceInfo } from 'helpers/api/discover';
import { getSupportInfo } from 'app/constants/add-on';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import shortcuts from 'helpers/shortcuts';
const canvasEventEmitter = eventEmitterFactory.createEventEmitter('canvas');
const topBarEventEmitter = eventEmitterFactory.createEventEmitter('top-bar');
const fluxIDEventEmitter = eventEmitterFactory.createEventEmitter('flux-id');
const workareaEventEmitter = eventEmitterFactory.createEventEmitter('workarea');
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const workareaEvents = eventEmitterFactory.createEventEmitter('workarea');
export var CanvasMode;
(function (CanvasMode) {
    CanvasMode[CanvasMode["Draw"] = 1] = "Draw";
    CanvasMode[CanvasMode["Preview"] = 2] = "Preview";
    CanvasMode[CanvasMode["PathPreview"] = 3] = "PathPreview";
    CanvasMode[CanvasMode["CurveEngraving"] = 4] = "CurveEngraving";
})(CanvasMode || (CanvasMode = {}));
const CanvasContext = createContext({
    changeToPreviewMode: () => { },
    currentUser: null,
    endPreviewMode: () => { },
    hasUnsavedChange: false,
    mode: CanvasMode.Draw,
    setMode: () => { },
    setupPreviewMode: () => { },
    togglePathPreview: () => { },
    updateCanvasContext: () => { },
    selectedDevice: null,
    setSelectedDevice: () => { },
    isColorPreviewing: false,
    setIsColorPreviewing: () => { },
    isPathEditing: false,
    setIsPathEditing: () => { },
    hasPassthroughExtension: false,
});
const CanvasProvider = (props) => {
    const lang = useI18n();
    const settingUpPreview = useRef(false);
    const forceUpdate = useForceUpdate();
    const [mode, setMode] = useState(CanvasMode.Draw);
    const [isColorPreviewing, setIsColorPreviewing] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [hasUnsavedChange, setHasUnsavedChange] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [isPathEditing, setIsPathEditing] = useState(false);
    const [hasPassthroughExtension, setHasPassthroughExtension] = useState(false);
    const unregisterEndPreviewShortcut = useRef(null);
    const endPreviewMode = () => {
        var _a;
        try {
            if (PreviewModeController.isPreviewMode()) {
                PreviewModeController.end();
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
        finally {
            if (tutorialController.getNextStepRequirement() === tutorialConstants.TO_EDIT_MODE) {
                tutorialController.handleNextStep();
            }
            // eslint-disable-next-line react-hooks/rules-of-hooks
            FnWrapper.useSelectTool();
            $('#workarea').off('contextmenu');
            workareaEventEmitter.emit('update-context-menu', { menuDisabled: false });
            // clear end preview shortcut after preview mode ended
            (_a = unregisterEndPreviewShortcut.current) === null || _a === void 0 ? void 0 : _a.call(unregisterEndPreviewShortcut);
            unregisterEndPreviewShortcut.current = null;
            setMode(CanvasMode.Draw);
        }
    };
    const setUser = useCallback((user) => setCurrentUser(Object.assign({}, user)), []);
    useEffect(() => {
        fluxIDEventEmitter.on('update-user', setUser);
        const handler = (e) => {
            setUser(e.detail.user);
        };
        window.addEventListener('update-user', handler);
        return () => {
            fluxIDEventEmitter.removeListener('update-user', setUser);
            window.removeEventListener('update-user', handler);
        };
    }, [setUser]);
    useEffect(() => {
        topBarEventEmitter.on('SET_HAS_UNSAVED_CHANGE', setHasUnsavedChange);
        topBarEventEmitter.on('SET_SELECTED_DEVICE', setSelectedDevice);
        return () => {
            topBarEventEmitter.removeListener('SET_HAS_UNSAVED_CHANGE', setHasUnsavedChange);
            topBarEventEmitter.removeListener('SET_SELECTED_DEVICE', setSelectedDevice);
        };
    }, []);
    useEffect(() => {
        const handler = (response) => {
            response.isPreviewMode = mode === CanvasMode.Preview;
        };
        topBarEventEmitter.on('GET_TOP_BAR_PREVIEW_MODE', handler);
        return () => {
            topBarEventEmitter.removeListener('GET_TOP_BAR_PREVIEW_MODE', handler);
        };
    }, [mode]);
    useEffect(() => {
        const handler = (response) => {
            response.selectedDevice = getLatestDeviceInfo(selectedDevice === null || selectedDevice === void 0 ? void 0 : selectedDevice.uuid);
        };
        topBarEventEmitter.on('GET_SELECTED_DEVICE', handler);
        return () => {
            topBarEventEmitter.removeListener('GET_SELECTED_DEVICE', handler);
        };
    }, [selectedDevice]);
    const updateCanvasContext = useCallback(() => {
        forceUpdate();
    }, [forceUpdate]);
    useEffect(() => {
        canvasEventEmitter.on('UPDATE_CONTEXT', updateCanvasContext); // This force rerender the context
        return () => {
            canvasEventEmitter.removeListener('UPDATE_CONTEXT', updateCanvasContext);
        };
    }, [updateCanvasContext]);
    useEffect(() => {
        canvasEventEmitter.on('SET_COLOR_PREVIEWING', setIsColorPreviewing);
        canvasEventEmitter.on('SET_PATH_EDITING', setIsPathEditing);
        canvasEventEmitter.on('SET_MODE', setMode);
        const canvasChangeHandler = () => setHasPassthroughExtension(beamboxPreference.read('pass-through') && getSupportInfo(workareaManager.model).passThrough);
        canvasChangeHandler();
        canvasEventEmitter.on('canvas-change', canvasChangeHandler);
        return () => {
            canvasEventEmitter.removeListener('SET_COLOR_PREVIEWING', setIsColorPreviewing);
            canvasEventEmitter.removeListener('SET_PATH_EDITING', setIsPathEditing);
            canvasEventEmitter.removeListener('SET_MODE', setMode);
            canvasEventEmitter.removeListener('canvas-change', canvasChangeHandler);
        };
    }, []);
    useEffect(() => {
        if (mode !== CanvasMode.CurveEngraving && curveEngravingModeController.started) {
            curveEngravingModeController.end();
        }
        const allLayers = document.querySelectorAll('g.layer');
        for (let i = 0; i < allLayers.length; i += 1) {
            const g = allLayers[i];
            if (mode === CanvasMode.Preview) {
                g.style.pointerEvents = 'none';
            }
            else {
                g.style.pointerEvents = '';
            }
        }
    }, [mode]);
    const setupPreviewMode = useCallback((opts = {}) => __awaiter(void 0, void 0, void 0, function* () {
        if (settingUpPreview.current)
            return;
        settingUpPreview.current = true;
        const { showModal, callback } = opts;
        const { device, isWorkareaMatched } = yield getDevice(showModal);
        if (!(yield PreviewModeController.checkDevice(device))) {
            settingUpPreview.current = false;
            return;
        }
        if (!isWorkareaMatched && !(yield showResizeAlert(device))) {
            settingUpPreview.current = false;
            return;
        }
        const t = lang.topbar;
        const workarea = document.getElementById('workarea');
        // eslint-disable-next-line react-hooks/rules-of-hooks
        FnWrapper.useSelectTool();
        svgCanvas.clearSelection();
        workarea.style.cursor = 'wait';
        const onPreviewError = (errMessage) => {
            if (errMessage === 'Timeout has occurred') {
                alertCaller.popUpError({
                    message: t.alerts.start_preview_timeout,
                });
            }
            else {
                alertCaller.popUpError({
                    message: `${t.alerts.fail_to_start_preview}<br/>${errMessage}`,
                });
            }
            setMode(CanvasMode.Draw);
            workarea.style.cursor = 'auto';
        };
        try {
            yield PreviewModeController.start(device, onPreviewError);
            if (!PreviewModeController.isPreviewModeOn) {
                workarea.style.cursor = 'auto';
                settingUpPreview.current = false;
                return;
            }
            const triggerEndPreview = () => {
                endPreviewMode();
            };
            unregisterEndPreviewShortcut.current = shortcuts.on(['esc'], triggerEndPreview, {
                isBlocking: true,
            });
            workarea.style.cursor = 'url(img/camera-cursor.svg) 9 12, cell';
            if (PreviewModeController.isFullScreen) {
                PreviewModeController.previewFullWorkarea(() => {
                    updateCanvasContext();
                    if (tutorialController.getNextStepRequirement() === tutorialConstants.PREVIEW_PLATFORM)
                        tutorialController.handleNextStep();
                });
            }
            setMode(CanvasMode.Preview);
            callback === null || callback === void 0 ? void 0 : callback();
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            if (error.message && error.message.startsWith('Camera WS')) {
                alertCaller.popUpError({
                    message: `${t.alerts.fail_to_connect_with_camera}<br/>${error.message || ''}`,
                });
            }
            else {
                alertCaller.popUpError({
                    message: `${t.alerts.fail_to_start_preview}<br/>${error.message || ''}`,
                });
            }
            // eslint-disable-next-line react-hooks/rules-of-hooks
            FnWrapper.useSelectTool();
        }
        finally {
            settingUpPreview.current = false;
        }
    }), [lang, updateCanvasContext]);
    useEffect(() => {
        canvasEventEmitter.addListener('SETUP_PREVIEW_MODE', setupPreviewMode);
        return () => {
            canvasEventEmitter.removeListener('SETUP_PREVIEW_MODE', setupPreviewMode);
        };
    }, [setupPreviewMode]);
    const changeToPreviewMode = () => {
        svgCanvas.setMode('select');
        workareaEvents.emit('update-context-menu', { menuDisabled: true });
        const workarea = document.getElementById('workarea');
        $('#workarea').contextmenu(() => {
            endPreviewMode();
            return false;
        });
        setMode(CanvasMode.Preview);
        if (workarea) {
            workarea.style.cursor = 'url(img/camera-cursor.svg) 9 12, cell';
        }
        if (tutorialController.getNextStepRequirement() === tutorialConstants.TO_PREVIEW_MODE) {
            tutorialController.handleNextStep();
        }
    };
    const togglePathPreview = () => {
        setMode(mode === CanvasMode.PathPreview ? CanvasMode.Draw : CanvasMode.PathPreview);
    };
    const { children } = props;
    return (React.createElement(CanvasContext.Provider, { value: {
            changeToPreviewMode,
            currentUser,
            endPreviewMode,
            hasUnsavedChange,
            mode,
            setMode,
            setupPreviewMode,
            togglePathPreview,
            updateCanvasContext,
            selectedDevice,
            setSelectedDevice,
            isColorPreviewing,
            setIsColorPreviewing,
            isPathEditing,
            setIsPathEditing,
            hasPassthroughExtension,
        } }, children));
};
export { CanvasContext, CanvasProvider };
