const lang = {
    global: {
        cancel: 'ยกเลิก',
        back: 'กลับ',
        save: 'บันทึก',
        ok: 'ตกลง',
        stop: 'หยุด',
    },
    general: {
        processing: 'กำลังประมวลผล...',
        choose_folder: 'เลือกโฟลเดอร์',
    },
    buttons: {
        next: 'ถัดไป',
        back: 'ย้อนกลับ',
        done: 'เสร็จสิ้น',
        back_to_beam_studio: 'กลับไปที่ Beam Studio',
    },
    topbar: {
        untitled: 'ไม่มีชื่อ',
        titles: {
            settings: 'ตั้งค่า',
        },
        export: 'ส่งออก',
        preview: 'แสดงตัวอย่าง',
        preview_title: 'ดูตัวอย่าง',
        preview_press_esc_to_stop: 'กด ESC เพื่อหยุดการแสดงตัวอย่างกล้อง.',
        curve_engrave: 'เส้นโค้ง 3D',
        task_preview: 'ตัวอย่างเส้นทาง',
        frame_task: 'กําลังประมวลผลเฟรม',
        borderless: '(เปิดด้านล่าง)',
        tag_names: {
            rect: 'สี่เหลี่ยม',
            ellipse: 'วงรี',
            path: 'เส้นทาง',
            polygon: 'รูปหลายเหลี่ยม',
            image: 'รูปภาพ',
            text: 'ข้อความ',
            text_path: 'ข้อความบนเส้นทาง',
            pass_through_object: 'วัตถุผ่านทะลุ',
            line: 'เส้น',
            g: 'กลุ่ม',
            multi_select: 'วัตถุหลายอย่าง',
            use: 'วัตถุที่นําเข้า',
            svg: 'วัตถุ SVG',
            dxf: 'วัตถุ DXF',
            no_selection: 'ไม่มีการเลือก',
        },
        alerts: {
            start_preview_timeout: '#803 หมดเวลาในการเริ่มโหมดแสดงตัวอย่าง โปรดรีสตาร์ทเครื่องของคุณหรือ Beam Studio หากยังคงเกิดข้อผิดพลาดนี้ โปรดปฏิบัติตาม <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">คําแนะนํานี้</a>',
            fail_to_start_preview: '#803 ไม่สามารถเริ่มโหมดแสดงตัวอย่างได้ โปรดรีสตาร์ทเครื่องของคุณหรือ Beam Studio หากยังคงเกิดข้อผิดพลาดนี้ โปรดปฏิบัติตาม <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">คําแนะนํานี้</a>',
            fail_to_connect_with_camera: '#803 ไม่สามารถเชื่อมต่อกับกล้องได้ โปรดรีสตาร์ทเครื่องหรือ Beam Studio หากยังคงเกิดข้อผิดพลาดนี้ โปรดปฏิบัติตาม <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">คําแนะนํานี้</a>',
            add_content_first: 'โปรดเพิ่มวัตถุก่อน',
            headtype_mismatch: 'ตรวจพบหัวเลเซอร์ไม่ถูกต้อง ',
            headtype_none: 'ไม่พบหัวเลเซอร์ ',
            headtype_unknown: 'ตรวจพบหัวเลเซอร์ที่ไม่รู้จัก ',
            install_correct_headtype: 'โปรดติดตั้งหัวเลเซอร์ไดโอด 10W/20W ให้ถูกต้องเพื่อเปิดใช้งานเลเซอร์ต่ํากําลังสําหรับการทํางาน',
            door_opened: 'โปรดปิดฝาครอบประตูเพื่อเปิดใช้งานเลเซอร์ต่ํากําลังสําหรับการทํางาน',
            fail_to_get_door_status: 'โปรดตรวจสอบให้แน่ใจว่าฝาครอบประตูปิด เพื่อเปิดใช้งานเลเซอร์ต่ํากําลังสําหรับการทํางาน',
            QcleanScene: 'คุณต้องการล้างภาพวาดหรือไม่?<br/>สิ่งนี้ยังจะล้างประวัติการเลิกทำของคุณด้วย!',
            power_too_high: 'พลังงานสูงเกินไป',
            power_too_high_msg: 'การใช้พลังงานเลเซอร์ต่ํากว่า 70% จะช่วยยืดอายุการใช้งานของหลอดเลเซอร์\nกด "ทราบแล้ว" เพื่อดําเนินการต่อ',
            power_too_high_confirm: 'ทราบแล้ว',
            pwm_unavailable: 'โหมดความลึกต้องใช้เฟิร์มแวร์เวอร์ชัน 4.3.4 / 5.3.4 หรือใหม่กว่า คุณต้องการอัปเดตเฟิร์มแวร์ตอนนี้หรือไม่?',
            job_origin_unavailable: 'การตั้งค่าจุดเริ่มต้นงานต้องการเฟิร์มแวร์เวอร์ชัน 4.3.5 / 5.3.5 ขึ้นไป คุณต้องการอัปเดตเฟิร์มแวร์ตอนนี้หรือไม่?',
            job_origin_warning: 'คุณกำลังใช้ "ตำแหน่งปัจจุบัน" เป็นจุดเริ่มต้น กรุณาตรวจสอบให้แน่ใจว่าหัวเลเซอร์ถูกย้ายไปยังตำแหน่งที่ถูกต้องเพื่อหลีกเลี่ยงการชนกัน',
        },
        hint: {
            polygon: 'กดปุ่ม + / - เพื่อเพิ่ม / ลดด้าน',
        },
        menu: {
            preferences: 'ตั้งค่า',
            hide: 'ซ่อน Beam Studio',
            hideothers: 'ซ่อนอื่นๆ',
            service: 'บริการ',
            quit: 'ออกจากระบบ',
            window: 'หน้าต่าง',
            minimize: 'ย่อหน้าต่าง',
            close: 'ปิดหน้าต่าง',
            file: 'ไฟล์',
            edit: 'แก้ไข',
            help: 'ช่วยเหลือ',
            open: 'เปิด',
            samples: 'ตัวอย่าง',
            example_files: 'ไฟล์ตัวอย่าง',
            material_test: 'การทดสอบวัสดุ',
            calibration: 'การปรับเทียบ',
            import_hello_beamo: 'ตัวอย่างของ beamo',
            import_hello_beambox: 'ตัวอย่างของ Beambox',
            import_beambox_2_example: 'ตัวอย่างของ Beambox II',
            import_material_testing_old: 'ทดสอบการตัดวัสดุ - คลาสสิก',
            import_material_testing_simple_cut: 'ทดสอบการตัดวัสดุ - ง่าย',
            import_material_testing_cut: 'ทดสอบการตัดวัสดุ',
            import_material_testing_engrave: 'ทดสอบการกัดวัสดุ',
            import_material_testing_line: 'ทดสอบเส้นวัสดุ',
            import_material_printing_test: 'ทดสอบการพิมพ์วัสดุ',
            import_ador_laser_example: 'ตัวอย่างของ Ador Laser',
            import_ador_printing_example_single: 'ตัวอย่างการพิมพ์ของ Ador - สีเดียว',
            import_ador_printing_example_full: 'ตัวอย่างการพิมพ์ของ Ador - สีเต็มรูปแบบ',
            import_acrylic_focus_probe: 'โฟกัสโพรบอะคริลิค - 3 มม.',
            import_beambox_2_focus_probe: 'หัววัดโฟกัส Beambox II - 3 มม.',
            import_promark_example: 'ตัวอย่างของ Promark',
            import_hexa_example: 'ตัวอย่าง HEXA',
            export_to: 'ส่งออกไปยัง...',
            export_flux_task: 'งาน FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'บันทึก',
            save_as: 'บันทึกเป็น...',
            save_to_cloud: 'บันทึกไปยังคลาวด์',
            about_beam_studio: 'เกี่ยวกับ Beam Studio',
            undo: 'เลิกทํา',
            redo: 'ทําซ้ํา',
            cut: 'ตัด',
            copy: 'คัดลอก',
            paste: 'วาง',
            paste_in_place: 'วางแทน',
            group: 'จัดกลุ่ม',
            ungroup: 'แยกกลุ่ม',
            delete: 'ลบ',
            duplicate: 'ทําสําเนา',
            offset: 'ชดเชย',
            scale: 'ปรับมาตราส่วน',
            rotate: 'หมุน',
            reset: 'รีเซ็ต',
            align_center: 'จัดกึ่งกลาง',
            photo_edit: 'รูปภาพ',
            svg_edit: 'SVG',
            path: 'เส้นทาง',
            decompose_path: 'แยกเส้นทาง',
            object: 'วัตถุ',
            layer_setting: 'เลเยอร์',
            layer_color_config: 'ตั้งค่าสี',
            image_sharpen: 'เพิ่มความคมชัด',
            image_crop: 'ตัดขอบภาพ',
            image_invert: 'กลับภาพ',
            image_stamp: 'ขอบเขต',
            image_vectorize: 'ติดตามเส้นทาง',
            image_curve: 'โค้ง',
            align_to_edges: 'จัดวางตําแหน่ง',
            document_setting: 'ตั้งค่าเอกสาร',
            document_setting_short: 'เอกสาร',
            rotary_setup: 'การตั้งค่าการหมุน',
            clear_scene: 'ไฟล์ใหม่',
            machines: 'เครื่อง',
            add_new_machine: 'ตั้งค่าเครื่อง',
            help_center: 'ศูนย์ช่วยเหลือ',
            show_gesture_tutorial: 'แนะนําท่ามือ',
            show_start_tutorial: 'แสดงการสอนเริ่มต้น',
            show_ui_intro: 'แสดงการแนะนําหน้าจอ',
            questionnaire: 'แบบสอบถามป้อนกลับ',
            change_logs: 'บันทึกการเปลี่ยนแปลง',
            contact: 'ติดต่อเรา',
            tutorial: 'คู่มือเริ่มต้น',
            design_market: 'ตลาดการออกแบบ',
            forum: 'ฟอรั่มชุมชน',
            software_update: 'อัปเดตซอฟต์แวร์',
            bug_report: 'รายงานข้อบกพร่อง',
            dashboard: 'แดชบอร์ด',
            machine_info: 'ข้อมูลเครื่อง',
            network_testing: 'ทดสอบการตั้งค่าเครือข่าย',
            commands: 'คําสั่ง',
            update_firmware: 'อัปเดตเฟิร์มแวร์',
            using_beam_studio_api: 'ใช้ Beam Studio API',
            set_as_default: 'ตั้งเป็นค่าเริ่มต้น',
            calibrate_beambox_camera: 'ปรับเทียบกล้อง',
            calibrate_printer_module: 'ปรับเทียบโมดูลการพิมพ์',
            calibrate_ir_module: 'ปรับเทียบโมดูลอินฟราเรด',
            calibrate_beambox_camera_borderless: 'ปรับเทียบกล้อง (เปิดด้านล่าง)',
            calibrate_diode_module: 'ปรับเทียบโมดูลเลเซอร์',
            calibrate_camera_advanced: 'การปรับเทียบกล้อง (ขั้นสูง)',
            manage_account: 'จัดการบัญชี',
            sign_in: 'เข้าสู่ระบบ',
            sign_out: 'ออกจากระบบ',
            account: 'บัญชี',
            my_account: 'บัญชีของฉัน',
            download_log: 'ดาวน์โหลดบันทึก',
            download_log_canceled: 'ยกเลิกการดาวน์โหลดบันทึก',
            download_log_error: 'เกิดข้อผิดพลาดที่ไม่รู้จัก โปรดลองอีกครั้งในภายหลัง',
            keyboard_shortcuts: 'ปุ่มลัดบนแป้นพิมพ์',
            log: {
                network: 'เครือข่าย',
                hardware: 'ฮาร์ดแวร์',
                discover: 'ค้นพบ',
                usb: 'USB',
                usblist: 'รายการ USB',
                camera: 'กล้อง',
                cloud: 'คลาวด์',
                player: 'เครื่องเล่น',
                robot: 'หุ่นยนต์',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'มุมมอง',
            zoom_in: 'ซูมเข้า',
            zoom_out: 'ซูมออก',
            fit_to_window: 'พอดีกับขนาดหน้าต่าง',
            zoom_with_window: 'ปรับพอดีกับขนาดหน้าต่างอัตโนมัติ',
            borderless_mode: 'โหมดไร้ขอบ',
            show_grids: 'แสดงตาราง',
            show_rulers: 'แสดงตัวบอกขนาด',
            show_layer_color: 'ใช้สีชั้น',
            anti_aliasing: 'การปรับความคมชัด',
            disassemble_use: 'ถอดประกอบ',
            about: 'เกี่ยวกับ Beam Studio',
            switch_to_beta: 'สลับเป็นรุ่นเบต้า',
            switch_to_latest: 'สลับเป็นรุ่นเสถียร',
            reload_app: 'รีโหลดแอป',
            recent: 'เปิดล่าสุด',
            update: 'ตรวจสอบการอัปเดต',
            dev_tool: 'เครื่องมือดีบัก',
            camera_calibration_data: 'ข้อมูลการปรับเทียบกล้อง',
            upload_data: 'อัปโหลดข้อมูล',
            download_data: 'ดาวน์โหลดข้อมูล',
            tools: {
                title: 'เครื่องมือ',
                material_test_generator: 'ตัวสร้างการทดสอบวัสดุ',
                code_generator: 'เครื่องสร้างโค้ด',
                box_generator: 'เครื่องสร้างกล่อง',
            },
            follow_us: 'ติดตามเรา',
        },
        select_machine: 'เลือกเครื่อง',
    },
    support: {
        no_webgl: 'WebGL ไม่รองรับ โปรดใช้อุปกรณ์อื่น',
        no_vcredist: 'โปรดติดตั้ง Visual C++ Redistributable 2015<br/>สามารถดาวน์โหลดได้ที่ flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] เกิดข้อผิดพลาดที่ไม่รู้จัก โปรดรีสตาร์ท Beam Studio และเครื่อง',
        OPERATION_ERROR: '[OE] เกิดความขัดแย้งสถานะ โปรดลองทําซ้ําการกระทํา',
        SUBSYSTEM_ERROR: '[SE] เกิดข้อผิดพลาดเมื่อเฟิร์มแวร์ของเครื่องกําลังปฏิบัติงาน โปรดรีสตาร์ทเครื่อง',
        UNKNOWN_COMMAND: '[UC] โปรดอัปเดตเฟิร์มแวร์อุปกรณ์',
    },
    device_selection: {
        no_beambox: '#801 เราไม่พบเครื่องของคุณในเครือข่าย\nโปรดทําตาม<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">คําแนะนํา</a>เพื่อแก้ไขปัญหาการเชื่อมต่อ',
        no_device_web: "#801 กรุณาตรวจสอบสถานะของเครื่องของคุณหรือคลิกที่ 'ตั้งค่าเครื่อง' ด้านล่างเพื่อตั้งค่าเครื่อง.",
        select_usb_device: 'เลือกอุปกรณ์ USB',
    },
    update: {
        update: 'อัปเดต',
        release_note: 'บันทึกการอัปเดต:',
        firmware: {
            caption: 'มีการอัปเดตเฟิร์มแวร์ของเครื่อง',
            message_pattern_1: '"%s" พร้อมสําหรับการอัปเดตเฟิร์มแวร์แล้ว',
            message_pattern_2: '%s เฟิร์มแวร์ v%s มีให้ใช้แล้ว - คุณมี v%s',
            latest_firmware: {
                caption: 'อัปเดตเฟิร์มแวร์เครื่อง',
                message: 'คุณมีเฟิร์มแวร์เครื่องล่าสุดอยู่แล้ว',
                still_update: 'อัปเดต',
                cant_get_latest: 'ไม่สามารถดึงข้อมูลเฟิร์มแวร์ล่าสุด',
            },
            confirm: 'อัปโหลด',
            upload_file: 'อัปโหลดเฟิร์มแวร์ (*.bin / *.fxfw)',
            update_success: 'อัปโหลดเฟิร์มแวร์สําเร็จแล้ว',
            update_fail: '#822 การอัปเดตล้มเหลว',
            too_old_for_web: 'เวอร์ชันเฟิร์มแวร์เครื่องปัจจุบันของคุณคือ v%s.\nหากต้องการใช้รุ่นออนไลน์ของ Beam Studio โปรดอัปเดตเฟิร์มแวร์เครื่องเป็นเวอร์ชันล่าสุด',
            force_update_message: '#814 โปรดอัปเดตเครื่องของคุณเป็นเวอร์ชันเฟิร์มแวร์ล่าสุด',
            firmware_too_old_update_by_sdcard: 'เวอร์ชันเฟิร์มแวร์เก่าเกินไป โปรดอัปเดตเฟิร์มแวร์โดยใช้การ์ด SD',
        },
        software: {
            checking: 'กําลังตรวจสอบการอัปเดต',
            switch_version: 'สลับเวอร์ชัน',
            check_update: 'ตรวจสอบการอัปเดต',
            caption: 'มีการอัปเดต Beam Studio',
            downloading: 'กําลังดาวน์โหลดการอัปเดตในพื้นหลัง คุณสามารถคลิก "ตกลง" เพื่อดําเนินการต่อ',
            install_or_not: 'พร้อมสําหรับการอัปเดต คุณต้องการรีสตาร์ทและอัปเดตเลยหรือไม่?',
            switch_or_not: 'พร้อมสําหรับการสลับ คุณต้องการรีสตาร์ทและสลับเลยหรือไม่?',
            available_update: 'Beam Studio v%s พร้อมใช้งานแล้ว คุณกําลังใช้ v%s คุณต้องการดาวน์โหลดอัปเดตหรือไม่?',
            available_switch: 'Beam Studio v%s พร้อมใช้งานแล้ว คุณกําลังใช้ v%s คุณต้องการสลับไปใช้เวอร์ชันนี้หรือไม่?',
            not_found: 'คุณกําลังใช้เวอร์ชันล่าสุดของ Beam Studio',
            no_response: 'เชื่อมต่อกับเซิร์ฟเวอร์ล้มเหลว โปรดตรวจสอบการตั้งค่าเครือข่ายของคุณ',
            switch_version_not_found: 'ไม่พบเวอร์ชันที่สามารถสลับได้',
            yes: 'ใช่',
            no: 'ไม่',
            update_for_ador: 'เวอร์ชันซอฟต์แวร์ปัจจุบัน %s ไม่สามารถใช้งานได้ โปรดดาวน์โหลดเวอร์ชันล่าสุดของ Beam Studio สําหรับ Ador',
        },
        updating: 'กําลังอัปเดต...',
        skip: 'ข้ามเวอร์ชันนี้',
        preparing: 'กําลังเตรียมการ...',
        later: 'ภายหลัง',
        download: 'อัปเดตออนไลน์',
        cannot_reach_internet: '#823 เซิร์ฟเวอร์เข้าถึงไม่ได้<br/>โปรดตรวจสอบการเชื่อมต่ออินเทอร์เน็ต',
        install: 'ติดตั้ง',
        upload: 'อัปโหลด',
    },
    topmenu: {
        version: 'เวอร์ชัน',
        credit: 'Beam Studio เป็นไปได้ด้วยโครงการโอเพนซอร์ส <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> และ <a target="_blank" href="https://flux3dp.com/credits/">ซอฟต์แวร์โอเพนซอร์ส</a> อื่นๆ',
        ok: 'ตกลง',
        file: {
            label: 'ไฟล์',
            import: 'นําเข้า',
            save_fcode: 'ส่งออกงาน FLUX',
            save_scene: 'บันทึกฉาก',
            save_svg: 'ส่งออก SVG',
            save_png: 'ส่งออก PNG',
            save_jpg: 'ส่งออก JPG',
            converting: 'กําลังแปลงเป็นรูปภาพ...',
            all_files: 'ทุกไฟล์',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio ฉาก',
            fcode_files: 'FLUX รหัส',
            clear_recent: 'ล้างที่เปิดล่าสุด',
            path_not_exit: 'เส้นทางนี้ไม่ดูเหมือนจะมีอยู่บนดิสก์อีกต่อไป',
        },
        device: {
            download_log_canceled: 'ยกเลิกการดาวน์โหลดบันทึกแล้ว',
            download_log_error: 'เกิดข้อผิดพลาดที่ไม่รู้จัก โปรดลองอีกครั้งในภายหลัง',
            log: {
                usblist: 'รายการ USB',
            },
            network_test: 'ทดสอบเครือข่าย',
        },
    },
    initialize: {
        next: 'ถัดไป',
        start: 'เริ่ม',
        skip: 'ข้าม',
        cancel: 'ยกเลิก',
        confirm: 'ยืนยัน',
        connect: 'เชื่อมต่อ',
        back: 'กลับ',
        retry: 'ลองใหม่',
        no_machine: 'ฉันไม่มีเครื่องในขณะนี้',
        select_language: 'เลือกภาษา',
        select_machine_type: 'เลือกรุ่นของเครื่อง',
        select_beambox: 'เลือก Beambox ของคุณ',
        select_connection_type: 'คุณต้องการเชื่อมต่ออย่างไร?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'เครือข่ายแบบมีสาย',
            ether2ether: 'การเชื่อมต่อโดยตรง',
            usb: 'การเชื่อมต่อ USB',
        },
        connect_wifi: {
            title: 'กําลังเชื่อมต่อ Wi-Fi',
            tutorial1: '1. ไปที่หน้าจอสัมผัส > คลิก "เครือข่าย" > "เชื่อมต่อ WiFi"',
            tutorial1_ador: '1. ไปที่หน้าจอสัมผัส > คลิก "MACHINE" > คลิก "Network" > "Connect to WiFi"',
            tutorial2: '2. เลือกและเชื่อมต่อ Wi-Fi ที่ต้องการ',
            what_if_1: 'ถ้าฉันไม่เห็น Wi-Fi ของฉันล่ะ?',
            what_if_1_content: '1. โหมดการเข้ารหัส Wi-Fi ควรเป็น WPA2 หรือไม่มีรหัสผ่าน\n2. โหมดการเข้ารหัสสามารถตั้งค่าได้ในหน้าจอการตั้งค่าเราเตอร์ Wi-Fi หากเราเตอร์ไม่รองรับ WPA2 และต้องการความช่วยเหลือในการเลือกเราเตอร์ที่เหมาะสม โปรดติดต่อฝ่ายสนับสนุน',
            what_if_2: 'ถ้าฉันไม่เห็น Wi-Fi?',
            what_if_2_content: '1. ตรวจสอบให้แน่ใจว่าตัวรับสัญญาณ Wi-Fi เสียบเข้ากับพอร์ตเต็มที่แล้ว\n2. หากไม่มีที่อยู่ MAC ของเครือข่ายไร้สายบนหน้าจอสัมผัส โปรดติดต่อฝ่ายสนับสนุน\n3. ช่องสัญญาณ Wi-Fi ควรเป็น 2.4Ghz (5Ghz ไม่รองรับ)',
        },
        connect_wired: {
            title: 'การเชื่อมต่อเครือข่ายแบบมีสาย',
            tutorial1: '1. เชื่อมต่อเครื่องกับเราเตอร์ของคุณ',
            tutorial2: '2. กด "เครือข่าย" เพื่อรับ IP ของเครือข่ายแบบมีสาย',
            tutorial2_ador: '2. กด "MACHINE" > "Network" เพื่อดู IP ของเครือข่ายแบบมีสาย',
            what_if_1: 'ถ้า IP ว่างเปล่าล่ะ',
            what_if_1_content: '1. ตรวจสอบให้แน่ใจว่าสาย Ethernet เสียบเข้ากับพอร์ตเต็มที่แล้ว\n2. หากไม่มีที่อยู่ MAC ของเครือข่ายแบบมีสายบนหน้าจอสัมผัส โปรดติดต่อฝ่ายสนับสนุน',
            what_if_2: 'ถ้า IP เริ่มต้นด้วย 169?',
            what_if_2_content: '1. หากที่อยู่ IP เริ่มต้นด้วย 169.254 น่าจะเป็นปัญหาการตั้งค่า DHCP โปรดติดต่อ ISP ของคุณ (ผู้ให้บริการอินเทอร์เน็ต) สําหรับความช่วยเหลือเพิ่มเติม\n2. หากคอมพิวเตอร์ของคุณเชื่อมต่อกับอินเทอร์เน็ตโดยตรงโดยใช้ PPPoE โปรดเปลี่ยนไปใช้เราเตอร์ในการเชื่อมต่อโดยใช้ PPPoE และเปิดใช้งานคุณสมบัติ DHCP ในเราเตอร์',
        },
        connect_ethernet: {
            title: 'การเชื่อมต่อโดยตรง',
            tutorial1: '1. เชื่อมต่อเครื่องกับคอมพิวเตอร์ของคุณด้วยสายอีเธอร์เน็ต',
            tutorial2_1: '2. ปฏิบัติตาม',
            tutorial2_a_text: 'คู่มือนี้',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' เพื่อทําให้คอมพิวเตอร์ของคุณเป็นเราเตอร์',
            tutorial3: '3. คลิกถัดไป',
        },
        connect_usb: {
            title: 'การเชื่อมต่อ USB',
            title_sub: ' (HEXA เท่านั้น)',
            tutorial1: '1. เชื่อมต่อเครื่องกับคอมพิวเตอร์ของคุณด้วยสาย USB',
            tutorial2: '2. คลิกถัดไป',
            turn_off_machine: 'ปิดเครื่อง',
            turn_on_machine: 'เปิดเครื่อง',
            wait_for_turning_on: 'คลิก "ถัดไป" หลังจากกระบวนการเริ่มต้นเสร็จสมบูรณ์และคุณเข้าสู่หน้าจอหลัก',
            connect_camera: 'เชื่อมต่อกล้องของเครื่องเข้ากับคอมพิวเตอร์ของคุณด้วยสาย USB.',
        },
        connect_machine_ip: {
            check_usb: 'ตรวจสอบการเชื่อมต่อ USB',
            enter_ip: 'ป้อน IP ของเครื่องของคุณ',
            check_ip: 'กําลังตรวจสอบความพร้อมใช้งานของ IP',
            invalid_ip: 'IP ไม่ถูกต้อง: ',
            invalid_format: 'รูปแบบไม่ถูกต้อง',
            starts_with_169254: 'เริ่มต้นด้วย 169.254',
            unreachable: 'IP เข้าถึงไม่ได้',
            check_connection: 'กําลังตรวจสอบการเชื่อมต่อเครื่อง',
            check_firmware: 'กําลังตรวจสอบเวอร์ชันเฟิร์มแวร์',
            check_camera: 'กําลังตรวจสอบกล้อง',
            retry: 'ลองใหม่',
            succeeded_message: 'เชื่อมต่อสําเร็จ 🎉',
            finish_setting: 'เริ่มสร้าง!',
            check_swiftray_connection: 'กำลังตรวจสอบการเชื่อมต่อกับเซิร์ฟเวอร์',
            check_swiftray_connection_unreachable: 'ไม่สามารถเข้าถึงเซิร์ฟเวอร์',
            promark_hint: 'หากไม่สามารถเชื่อมต่อผ่าน USB ซ้ำๆ กรุณาดู <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">บทความในศูนย์ช่วยเหลือ</a>.',
            alert: {
                swiftray_connection_error: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ กรุณารีสตาร์ท Beam Studio และลองอีกครั้ง.',
            },
        },
        connecting: 'กําลังเชื่อมต่อ...',
        setting_completed: {
            start: 'เริ่ม',
            great: 'ยินดีต้อนรับสู่ Beam Studio',
            setup_later: 'คุณสามารถตั้งค่าเครื่องของคุณได้ที่แถบชื่อ > "เครื่อง" > "ตั้งค่าเครื่อง"',
            back: 'กลับ',
            ok: 'เริ่มสร้าง',
        },
        promark: {
            select_laser_source: 'เลือก Promark ของคุณ',
            select_workarea: 'เลือกพื้นที่ทำงาน',
            settings: 'การตั้งค่า Promark',
            qc_instructions: 'กรอกพารามิเตอร์ที่อยู่ด้านหลังของบัตร "QC Pass"',
            configuration_confirmation: 'การดำเนินการนี้จะช่วยให้ Promark ของคุณได้รับการกำหนดค่าอย่างเหมาะสมเพื่อประสิทธิภาพและความแม่นยำที่ดีที่สุด',
            or_complete_later: `หรือข้ามขั้นตอนนี้และตั้งค่า Promark ภายหลังได้ที่:
    เครื่อง > "ชื่อ Promark" > การตั้งค่า Promark`,
        },
    },
    error_pages: {
        screen_size: 'โปรดทราบว่า Beam Studio อาจไม่ทํางานได้อย่างเต็มประสิทธิภาพบนอุปกรณ์ของคุณ สําหรับประสบการณ์ที่ดีที่สุด ควรเป็นอุปกรณ์ที่มีความกว้างของหน้าจออย่างน้อย 1024 พิกเซล',
    },
    menu: {
        mm: 'มม.',
        inches: 'นิ้ว',
    },
    settings: {
        on: 'เปิด',
        off: 'ปิด',
        low: 'ต่ํา',
        normal: 'สูง',
        high: 'สูง',
        caption: 'การตั้งค่า',
        tabs: {
            general: 'ทั่วไป',
            device: 'เครื่อง',
        },
        ip: 'ที่อยู่ IP เครื่อง',
        guess_poke: 'ค้นหาที่อยู่ IP เครื่อง',
        auto_connect: 'เลือกเครื่องโดยอัตโนมัติ',
        wrong_ip_format: 'รูปแบบ IP ไม่ถูกต้อง',
        default_machine: 'เครื่องเริ่มต้น',
        default_machine_button: 'ไม่มี',
        remove_default_machine_button: 'ลบ',
        confirm_remove_default: 'เครื่องเริ่มต้นกําลังจะถูกลบ',
        reset: 'รีเซ็ต Beam Studio',
        reset_now: 'รีเซ็ต Beam Studio',
        confirm_reset: 'ยืนยันการรีเซ็ต Beam Studio?',
        language: 'ภาษา',
        notifications: 'การแจ้งเตือนบนเดสก์ท็อป',
        check_updates: 'ตรวจสอบการอัปเดตอัตโนมัติ',
        autosave_enabled: 'บันทึกอัตโนมัติ',
        autosave_path: 'ตําแหน่งบันทึกอัตโนมัติ',
        autosave_interval: 'บันทึกทุก',
        autosave_number: 'จํานวนการบันทึกอัตโนมัติ',
        autosave_path_not_correct: 'ไม่พบเส้นทางที่ระบุ',
        preview_movement_speed: 'ความเร็วการแสดงตัวอย่าง',
        medium: 'ปานกลาง',
        default_units: 'หน่วยเริ่มต้น',
        default_font_family: 'แบบอักษรเริ่มต้น',
        default_font_style: 'รูปแบบแบบอักษรเริ่มต้น',
        fast_gradient: 'เพิ่มความเร็ว',
        engraving_direction: 'ทิศทาง',
        top_down: 'บนลงล่าง',
        bottom_up: 'ล่างขึ้นบน',
        vector_speed_constraint: 'ขีดจํากัดความเร็ว',
        loop_compensation: 'ชดเชยการวนลูป',
        blade_radius: 'รัศมีใบมีด',
        blade_precut_switch: 'เจาะล่วงหน้าด้วยใบมีด',
        blade_precut_position: 'ตําแหน่งเจาะล่วงหน้า',
        default_beambox_model: 'การตั้งค่าเอกสารเริ่มต้น',
        guides_origin: 'ต้นกําเนิดแนวประ',
        guides: 'แนวประ',
        image_downsampling: 'คุณภาพตัวอย่างภาพแรสเตอร์',
        anti_aliasing: 'ความต่อเนื่องของเส้น',
        continuous_drawing: 'การวาดต่อเนื่อง',
        trace_output: 'ผลลัพธ์การแปลงภาพ',
        single_object: 'วัตถุเดี่ยว',
        grouped_objects: 'กลุ่มวัตถุ',
        simplify_clipper_path: 'เพิ่มประสิทธิภาพเส้นทางที่คํานวณ',
        enable_low_speed: 'เปิดใช้งานความเร็วต่ํา',
        enable_custom_backlash: 'เปิดใช้งานการชดเชยการถอยหลังแบบกําหนดเอง',
        calculation_optimization: 'การเร่งความเร็วในการคำนวณเส้นทาง',
        auto_switch_tab: 'สลับชั้นอัตโนมัติและแผงวัตถุ',
        custom_preview_height: 'ความสูงแสดงตัวอย่างกําหนดเอง',
        mask: 'ตัดขอบพื้นที่ทํางาน',
        text_path_calc_optimization: 'เพิ่มประสิทธิภาพการคํานวณเส้นทาง',
        font_substitute: 'แทนที่อักขระที่ไม่รองรับ',
        font_convert: 'โปรแกรมแปลงข้อความเป็นเส้นทาง',
        default_borderless_mode: 'เปิดด้านล่างเริ่มต้น',
        default_enable_autofocus_module: 'เปิดใช้งานโหมดอัตโนมัติเริ่มต้น',
        default_enable_diode_module: 'เลเซอร์ไดโอดเริ่มต้น',
        diode_offset: 'ชดเชยเลเซอร์ไดโอด',
        autofocus_offset: 'ชดเชยการโฟกัสอัตโนมัติ',
        diode_one_way_engraving: 'การแกะสลักทางเดียวด้วยเลเซอร์ไดโอด',
        diode_two_way_warning: 'การส่องแสงสองทางจะเร็วกว่า แต่อาจทําให้ตําแหน่งลําเลเซอร์ผิดพลาดได้ ขอแนะนําให้ทดสอบก่อน',
        share_with_flux: 'แชร์ข้อมูลการใช้งาน Beam Studio',
        none: 'ไม่มี',
        close: 'ปิด',
        enabled: 'เปิดใช้งาน',
        disabled: 'ปิดใช้งาน',
        cancel: 'ยกเลิก',
        done: 'ตกลง',
        module_offset_10w: 'ชดเชยเลเซอร์ไดโอด 10 วัตต์',
        module_offset_20w: 'ชดเชยเลเซอร์ไดโอด 20 วัตต์',
        module_offset_printer: 'ชดเชยตัวพิมพ์',
        module_offset_2w_ir: 'ชดเชยเลเซอร์อินฟราเรด 2 วัตต์',
        printer_advanced_mode: 'โหมดขั้นสูงของเครื่องพิมพ์',
        default_laser_module: 'โมดูลเลเซอร์เริ่มต้น',
        low_laser_for_preview: 'เลเซอร์สําหรับ กําลังประมวลผลเฟรม',
        groups: {
            general: 'ทั่วไป',
            update: 'การอัปเดตซอฟต์แวร์',
            connection: 'การเชื่อมต่อ',
            autosave: 'บันทึกอัตโนมัติ',
            camera: 'กล้อง',
            editor: 'แก้ไข',
            engraving: 'การสแกน',
            path: 'เส้นทาง',
            mask: 'การตัดพื้นที่ทํางาน',
            text_to_path: 'ข้อความ',
            modules: 'โมดูลเสริม',
            ador_modules: 'โมดูล Ador',
            privacy: 'ความเป็นส่วนตัว',
        },
        notification_on: 'เปิด',
        notification_off: 'ปิด',
        update_latest: 'ล่าสุด',
        update_beta: 'เบต้า',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
            calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
        },
    },
    beambox: {
        tag: {
            g: 'กลุ่ม',
            use: 'นําเข้า SVG',
            image: 'รูปภาพ',
            text: 'ข้อความ',
        },
        context_menu: {
            cut: 'ตัด',
            copy: 'คัดลอก',
            paste: 'วาง',
            paste_in_place: 'วางในตําแหน่ง',
            duplicate: 'ทําสําเนา',
            delete: 'ลบ',
            group: 'จัดกลุ่ม',
            ungroup: 'แยกกลุ่ม',
            move_front: 'นําไปข้างหน้าสุด',
            move_up: 'นําไปข้างหน้า',
            move_down: 'ส่งไปข้างหลัง',
            move_back: 'ส่งไปข้างหลังสุด',
        },
        popup: {
            select_import_method: 'เลือกวิธีการวาง:',
            select_import_module: 'เลือกโมดูล:',
            touchpad: 'ทัชแพด',
            mouse: 'เมาส์',
            layer_by_layer: 'ชั้น',
            layer_by_color: 'สี',
            nolayer: 'ชั้นเดียว',
            loading_image: 'กําลังโหลดรูปภาพ โปรดรอสักครู่...',
            no_support_text: 'Beam Studio ไม่รองรับข้อความในขณะนี้ โปรดแปลงข้อความเป็นเส้นทางก่อนนําเข้า',
            speed_too_high_lower_the_quality: 'การใช้ความเร็วสูงเกินไปที่ความละเอียดนี้ อาจทําให้คุณภาพการกัดลายเงาแย่ลง',
            both_power_and_speed_too_high: 'การใช้พลังงานเลเซอร์ต่ําลงจะช่วยยืดอายุการใช้งานของหลอดเลเซอร์\nนอกจากนี้ ความเร็วที่สูงเกินไปที่ความละเอียดนี้ อาจทําให้คุณภาพการกัดลายเงาแย่ลง',
            too_fast_for_path: 'การใช้ความเร็วสูงเกินไปในชั้นที่มีวัตถุเส้นทาง อาจทําให้ความแม่นยําในการตัดลดลง\nเราไม่แนะนําให้ใช้ความเร็วเกิน %(limit)s เมื่อตัด',
            too_fast_for_path_and_constrain: 'ชั้นต่อไปนี้: %(layers)s \nมีวัตถุเส้นทางเวกเตอร์ และมีความเร็วเกิน %(limit)s\nความเร็วในการตัดวัตถุเส้นทางเวกเตอร์จะถูกจํากัดไว้ที่ %(limit)s\nคุณสามารถนําขีด จํากัดนี้ออกได้ที่การตั้งค่า',
            should_update_firmware_to_continue: '#814 เฟิร์มแวร์ของคุณไม่รองรับเวอร์ชัน Beam Studio นี้ โปรดอัปเดตเฟิร์มแวร์เพื่อดําเนินการต่อ (เมนู > เครื่อง > [เครื่องของคุณ] > อัปเดตเฟิร์มแวร์)',
            recommend_downgrade_software: 'เราตรวจพบเวอร์ชันเฟิร์มแวร์เก่า เรากําลังแก้ไขปัญหาด้านความเข้ากันได้อย่างแข็งขัน แต่ในตอนนี้ เราแนะนําให้กลับไปใช้ <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>',
            recommend_upgrade_firmware: 'เราตรวจพบรุ่นเฟิร์มแวร์เก่า เรากําลังแก้ไขปัญหาความเข้ากันได้อย่างเร่งด่วน แต่ในตอนนี้เราแนะนําให้อัปเดตเป็นรุ่นล่าสุด',
            still_continue: 'ดําเนินการต่อ',
            more_than_two_object: 'วัตถุมากเกินไป รองรับเพียง 2 วัตถุ',
            not_support_object_type: 'ไม่รองรับประเภทวัตถุ',
            select_first: 'เลือกวัตถุก่อน',
            select_at_least_two: 'เลือกสองวัตถุเพื่อดําเนินการต่อ',
            import_file_contain_invalid_path: '#808 ไฟล์ SVG ที่นําเข้ามีเส้นทางรูปภาพไม่ถูกต้อง โปรดตรวจสอบให้แน่ใจว่าไฟล์รูปภาพทั้งหมดมีอยู่หรือฝังรูปภาพในไฟล์',
            import_file_error_ask_for_upload: 'ล้มเหลวในการนําเข้าไฟล์ SVG คุณต้องการส่งไฟล์ให้ทีมพัฒนาเพื่อรายงานข้อผิดพลาดหรือไม่',
            upload_file_too_large: '#819 ไฟล์มีขนาดใหญ่เกินไปสําหรับการอัปโหลด',
            successfully_uploaded: 'อัปโหลดไฟล์สําเร็จ',
            upload_failed: '#819 การอัปโหลดไฟล์ล้มเหลว',
            or_turn_off_borderless_mode: ' หรือปิดโหมด Open Bottom',
            svg_1_1_waring: 'เวอร์ชันของไฟล์ SVG นี้คือ v 1.1 อาจมีปัญหาด้านความเข้ากันได้ที่อาจเกิดขึ้น',
            svg_image_path_waring: 'ไฟล์ SVG นี้มีการโหลด <image> จากเส้นทางไฟล์ ซึ่งอาจทําให้เกิดความล้มเหลวเมื่อโหลด\nเพื่อหลีกเลี่ยงความเสี่ยงนี้ โปรดใช้ภาพฝังเมื่อส่งออก SVG',
            dxf_version_waring: 'เวอร์ชันของไฟล์ DXF นี้ไม่ใช่ 2013 อาจมีปัญหาด้านความเข้ากันได้ที่อาจเกิดขึ้น',
            dont_show_again: 'ไม่แสดงอีก',
            convert_to_path_fail: 'การแปลงเป็นเส้นทางล้มเหลว',
            save_unsave_changed: 'คุณต้องการบันทึกการเปลี่ยนแปลงที่ยังไม่ได้บันทึกหรือไม่',
            dxf_bounding_box_size_over: 'ขนาดการวาดเกินพื้นที่ทํางาน โปรดย้ายการวาดให้อยู่ในบริเวณจุดกําเนิดในโปรแกรม CAD หรือตรวจสอบหน่วยให้ถูกต้อง',
            progress: {
                uploading: 'อัปโหลดกําลังดําเนินการ',
                calculating: 'กําลังคํานวณ',
            },
            backend_connect_failed_ask_to_upload: '#802 เกิดข้อผิดพลาดขณะเชื่อมต่อกับแบ็คเอนด์ คุณต้องการอัปโหลดรายงานข้อบกพร่องหรือไม่',
            backend_error_hint: 'คุณสมบัติอาจทํางานไม่ถูกต้องเนื่องจากข้อผิดพลาดของแบ็คเอนด์',
            pdf2svg: {
                error_when_converting_pdf: '#824 เกิดข้อผิดพลาดขณะแปลง PDF เป็น SVG:',
                error_pdf2svg_not_found: '#825 ไม่พบคําสั่ง pdf2svg โปรดติดตั้ง pdf2svg',
            },
            ungroup_use: 'การยกเลิกกลุ่มอาจใช้เวลาสักครู่ คุณแน่ใจหรือไม่ว่าต้องการดําเนินการต่อ',
            vectorize_shading_image: 'ภาพเกรเดียนต์ใช้เวลาในการติดตามมากขึ้นและมีโอกาสเกิดสัญญาณรบกวนสูง โปรดปิดเกรเดียนต์ของภาพก่อนดําเนินการ',
            change_workarea_before_preview: 'พื้นที่ทํางานไม่ตรงกับที่ตั้งไว้ คุณต้องการสลับเปลี่ยนหรือไม่',
            bug_report: 'รายงานข้อบกพร่อง',
            sentry: {
                title: 'ช่วยปรับปรุง Beam Studio',
                message: 'คุณตกลงอัปโหลดข้อมูลเมื่อเกิดข้อผิดพลาดไปยังทีมพัฒนาหรือไม่',
            },
            questionnaire: {
                caption: 'ช่วยเราตอบแบบสอบถาม',
                message: 'ช่วยตอบแบบสอบถามเพื่อปรับปรุง Beam Studio',
                unable_to_get_url: 'ไม่สามารถเชื่อมต่ออินเทอร์เน็ตเพื่อรับแบบสอบถาม โปรดตรวจสอบการเชื่อมต่อ',
                no_questionnaire_available: 'ไม่มีแบบสอบถามพร้อมใช้งานในขณะนี้',
            },
            facebook_group_invitation: {
                title: 'เข้าร่วมกลุ่มผู้ใช้ FLUX',
                message: 'เชื่อมต่อกับผู้ใช้เลเซอร์ FLUX อื่นๆ แชร์งานศิลปะ และรับข่าวสารผลิตภัณฑ์ใหม่ เรารอต้อนรับคุณ',
                join_now: 'เข้าร่วม',
                later: 'ทีหลัง',
                already_joined: 'เข้าร่วมแล้ว',
                dont_show_again: 'อย่าแสดงอีก',
            },
            ai_credit: {
                relogin_to_use: 'โปรดเข้าสู่ระบบใหม่เพื่อใช้ฟังก์ชันนี้',
                insufficient_credit: 'คุณหมดเครดิตแล้ว',
                insufficient_credit_msg: 'คุณไม่สามารถใช้ %s ได้ ไปที่ศูนย์สมาชิกและซื้อเครดิต AI',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'ไป',
            },
            text_to_path: {
                caption: 'โปรแกรมแปลงข้อความเป็นเส้นทาง 2.0',
                message: "Beam Studio แนะนําโปรแกรมแปลงข้อความเป็นเส้นทางใหม่ (โปรแกรมแปลง 2.0) ซึ่งให้ผลลัพธ์ที่เชื่อถือได้มากขึ้น! คุณต้องการสลับไปใช้โปรแกรมแปลงนี้เลยหรือไม่?\nคุณยังสามารถปรับการตั้งค่านี้ในภายหลังได้ในส่วน 'โปรแกรมแปลงข้อความเป็นเส้นทาง' ภายในการตั้งค่า",
            },
            auto_switch_tab: {
                title: 'สลับอัตโนมัติระหว่างแผงชั้นและวัตถุ',
                message: 'มีการเพิ่มตัวเลือกใหม่ในการสลับระหว่างแผงเลเยอร์และแผงวัตถุโดยอัตโนมัติในการตั้งค่า ตัวเลือกนี้ปิดใช้งานโดยค่าเริ่มต้น คุณต้องการเปิดการสลับอัตโนมัติในตอนนี้หรือไม่? <br/>คุณสามารถเปลี่ยนการตั้งค่านี้ได้ทุกเมื่อในการตั้งค่า',
            },
        },
        zoom_block: {
            fit_to_window: 'พอดีกับหน้าต่าง',
        },
        time_est_button: {
            calculate: 'ประมาณเวลา',
            estimate_time: 'เวลาที่ประมาณ:',
        },
        left_panel: {
            unpreviewable_area: 'พื้นที่มืด',
            diode_blind_area: 'พื้นที่มืดของเลเซอร์แอดออน',
            borderless_blind_area: 'พื้นที่ที่ไม่สามารถกัดได้',
            borderless_preview: 'เปิดกล้องด้านล่างเพื่อดูตัวอย่าง',
            rectangle: 'สี่เหลี่ยมผืนผ้า',
            ellipse: 'วงรี',
            line: 'เส้น',
            image: 'รูปภาพ',
            text: 'ข้อความ',
            label: {
                cursor: 'เลือก',
                photo: 'รูปภาพ',
                text: 'ข้อความ',
                line: 'เส้น',
                rect: 'สี่เหลี่ยมผืนผ้า',
                oval: 'วงรี',
                polygon: 'รูปเหลี่ยม',
                pen: 'ปากกา',
                shapes: 'องค์ประกอบ',
                array: 'แถวลําดับ',
                preview: 'ตัวอย่าง',
                trace: 'ตามเส้นทาง',
                end_preview: 'สิ้นสุดตัวอย่าง',
                clear_preview: 'ล้างตัวอย่าง',
                choose_camera: 'กล้อง',
                live_feed: 'ภาพถ่ายสด',
                adjust_height: 'ปรับระดับความสูง',
                qr_code: 'คิวอาร์โค้ด',
                boxgen: 'Boxgen',
                my_cloud: 'คลาวด์ของฉัน',
                pass_through: 'โหมดผ่าน',
                curve_engraving: {
                    title: 'เส้นโค้ง 3D',
                    exit: 'ออกจากระบบ',
                    select_area: 'เลือกพื้นที่',
                    preview_3d_curve: 'แสดงตัวอย่างเส้นโค้ง 3D',
                    clear_area: 'ล้างพื้นที่ที่เลือก',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'ชั้น',
                objects: 'วัตถุ',
                path_edit: 'แก้ไขเส้นทาง',
            },
            layer_panel: {
                layer1: 'ชั้น 1',
                layer_bitmap: 'รูปบิตแมป',
                layer_engraving: 'การแกะสลัก',
                layer_cutting: 'ตัด',
                current_layer: 'ชั้นปัจจุบัน',
                move_elems_to: 'ย้ายองค์ประกอบไปยัง:',
                notification: {
                    dupeLayerName: 'มีชื่อชั้นนี้อยู่แล้ว!',
                    newName: 'ชื่อใหม่',
                    enterUniqueLayerName: 'โปรดป้อนชื่อชั้นใหม่',
                    enterNewLayerName: 'โปรดป้อนชื่อชั้นใหม่',
                    layerHasThatName: 'ชั้นมีชื่อนี้อยู่แล้ว',
                    QmoveElemsToLayer: "ย้ายองค์ประกอบที่เลือกไปยังชั้น '%s'?",
                    moveElemFromPrintingLayerTitle: 'ย้ายองค์ประกอบที่เลือกไปยัง %s และแปลงเป็นส่วนสําหรับพิมพ์?',
                    moveElemFromPrintingLayerMsg: 'โปรดทราบว่าหากดําเนินการต่อ การตั้งค่าสีขององค์ประกอบที่เลือกจะถูกลบออกและตั้งค่าใหม่ตาม %s',
                    moveElemToPrintingLayerTitle: 'ย้ายองค์ประกอบที่เลือกไปยัง %s และแปลงเป็นองค์ประกอบสําหรับพิมพ์?',
                    moveElemToPrintingLayerMsg: 'โปรดทราบว่าหากดําเนินการต่อ การตั้งค่าขององค์ประกอบที่เลือกจะถูกลบออกและตั้งค่าใหม่ตาม %s',
                    splitColorTitle: 'คุณต้องการขยายชั้นที่เลือกเป็นชั้น CMYK หรือไม่?',
                    splitColorMsg: 'โปรดทราบว่าหากดําเนินการต่อ คุณจะไม่สามารถกลับไปใช้ชั้นสีเดิมได้',
                    mergeLaserLayerToPrintingLayerTitle: 'คุณต้องการรวมชั้นเหล่านี้เข้าด้วยกันเป็นชั้นสําหรับพิมพ์หรือไม่?',
                    mergeLaserLayerToPrintingLayerMsg: 'โปรดทราบว่าหากดําเนินการต่อ การตั้งค่าของชั้นเลเซอร์จะถูกลบออกและตั้งค่าใหม่ตามชั้นปัจจุบัน',
                    mergePrintingLayerToLaserLayerTitle: 'คุณต้องการรวมชั้นเหล่านี้เข้าด้วยกันเป็นชั้นเลเซอร์หรือไม่?',
                    mergePrintingLayerToLaserLayerMsg: 'โปรดทราบว่าหากดําเนินการต่อ การตั้งค่าสีของชั้นสําหรับพิมพ์จะถูกลบออกและตั้งค่าใหม่ตามชั้นปัจจุบัน',
                },
                layers: {
                    layer: 'ชั้น',
                    layers: 'ชั้น',
                    del: 'ลบชั้น',
                    move_down: 'ย้ายชั้นลง',
                    new: 'ชั้นใหม่',
                    rename: 'เปลี่ยนชื่อชั้น',
                    move_up: 'ย้ายชั้นขึ้น',
                    dupe: 'ทําสําเนาชั้น',
                    lock: 'ล็อคชั้น',
                    unlock: 'ปลดล็อค',
                    merge_down: 'รวมลง',
                    merge_all: 'รวมทั้งหมด',
                    merge_selected: 'รวมเลเยอร์ที่เลือก',
                    move_elems_to: 'ย้ายองค์ประกอบไปยัง:',
                    move_selected: 'ย้ายองค์ประกอบที่เลือกไปยังเลเยอร์อื่น',
                    switchToFullColor: 'สลับไปใช้ชั้นสีเต็มรูปแบบ',
                    switchToSingleColor: 'สลับไปใช้ชั้นสีเดียว',
                    splitFullColor: 'แยกชั้นสี',
                    fullColor: 'สีเต็มรูปแบบ',
                },
            },
            laser_panel: {
                preset_setting: 'การตั้งค่าพารามิเตอร์ (%s)',
                multi_layer: 'หลายเลเยอร์',
                parameters: 'พารามิเตอร์',
                strength: 'กําลัง',
                pwm_advanced_setting: 'การตั้งค่าพลังงานโหมดความลึก',
                pwm_advanced_desc: 'ตั้งค่ากำลังไฟขั้นต่ำสำหรับโหมดความลึก',
                pwm_advanced_hint: 'การตั้งค่านี้ใช้กับภาพไล่สีโดยเฉพาะเมื่อใช้โหมดความลึก',
                low_power_warning: 'กําลังเลเซอร์ต่ํา (ต่ํากว่า 10%) อาจไม่ส่งแสงเลเซอร์ออกมา',
                speed: 'ความเร็ว',
                speed_contrain_warning: 'ความเร็วการตัดของวัตถุเส้นทางเวกเตอร์จะถูกจํากัดไว้ที่ %(limit)s คุณสามารถลบขีดจํากัดนี้ออกได้ที่การตั้งค่าการตั้งค่า',
                low_speed_warning: 'ความเร็วต่ําอาจทําให้วัสดุไหม้',
                promark_speed_desc: 'พารามิเตอร์ความเร็วไม่สามารถใช้ได้กับภาพไล่เฉดสี',
                repeat: 'จำนวนรอบ',
                advanced: 'ขั้นสูง',
                lower_focus: 'ลดระยะโฟกัส',
                by: 'ตามค่า',
                stepwise_focusing: 'โฟกัสทีละขั้น',
                single_color: 'สีเดียว',
                lower_focus_desc: 'ลดความสูงของโฟกัสตามระยะทางที่กำหนดหลังจากโฟกัสเพื่อปรับปรุงประสิทธิภาพการตัด',
                stepwise_focusing_desc: 'ลดระยะโฟกัสลงเรื่อยๆ ตามความสูงของวัตถุในแต่ละจำนวนรอบ',
                single_color_desc: 'ใช้ได้เฉพาะกับเลเยอร์สีเต็มและไม่สามารถใช้กับเลเยอร์สีเดียวที่ขยายออกได้',
                focus_adjustment: 'การปรับโฟกัส',
                height: 'ความสูงของวัตถุ',
                z_step: 'ขั้นตอน Z',
                diode: 'เลเซอร์ไดโอด',
                backlash: 'การย้อนกลับ',
                ink_saturation: 'ความอิ่มตัวสี',
                print_multipass: 'พิมพ์หลายชั้น',
                white_ink: 'หมึกขาว',
                white_ink_settings: 'การตั้งค่าหมึกขาว',
                color_adjustment: 'การปรับสี',
                color_adjustment_short: 'ช่องสี',
                halftone: 'ฮาล์ฟทอน',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'ความเข้ม',
                times: 'ครั้ง',
                cut: 'ตัด',
                engrave: 'กัดสลัก',
                more: 'จัดการ',
                apply: 'ปรับใช้',
                custom_preset: 'กําหนดเอง',
                various_preset: 'การตั้งค่าล่วงหน้าหลากหลาย',
                module: 'โหมด',
                pulse_width: 'ความกว้างของพัลส์',
                frequency: 'ความถี่',
                fill_setting: 'การตั้งค่าการเติม',
                fill_interval: 'ช่วงเติมเต็ม',
                fill_angle: 'มุมเติมเต็ม',
                bi_directional: 'สองทิศทาง',
                cross_hatch: 'การฟักไข่ข้าม',
                dottingTime: 'เวลาแต้มจุด',
                gradient_only: 'ใช้ได้เฉพาะภาพไล่สี',
                filled_path_only: 'ใช้ได้เฉพาะเส้นทางเติมสี',
                slider: {
                    regular: 'ปกติ',
                    low: 'ต่ํา',
                    very_low: 'ต่ํามาก',
                    high: 'สูง',
                    very_high: 'สูงมาก',
                    slow: 'ช้า',
                    very_slow: 'ช้ามาก',
                    fast: 'เร็ว',
                    very_fast: 'เร็วมาก',
                },
                dropdown: {
                    parameters: 'การตั้งค่าล่วงหน้า',
                    save: 'เพิ่มพารามิเตอร์ปัจจุบัน',
                    mm: {
                        wood_3mm_cutting: 'ไม้ - ตัด 3 มม.',
                        wood_5mm_cutting: 'ไม้ - ตัด 5 มม.',
                        wood_7mm_cutting: 'ไม้ - ตัด 7 มม.',
                        wood_8mm_cutting: 'ไม้ - ตัด 8 มม.',
                        wood_10mm_cutting: 'ไม้ - ตัด 10 มม.',
                        wood_engraving: 'ไม้ - กัดขูด',
                        acrylic_3mm_cutting: 'อะคริลิค - ตัด 3 มม.',
                        acrylic_5mm_cutting: 'อะคริลิค - ตัด 5 มม.',
                        acrylic_8mm_cutting: 'อะคริลิค - ตัด 8 มม.',
                        acrylic_10mm_cutting: 'อะคริลิค - ตัด 10 มม.',
                        acrylic_engraving: 'อะคริลิค - กัดขูด',
                        mdf_3mm_cutting: 'ไม้อัด - 3 มม. ตัด',
                        mdf_5mm_cutting: 'ไม้อัด - 5 มม. ตัด',
                        mdf_engraving: 'ไม้อัด - กัดขูด',
                        leather_3mm_cutting: 'หนัง - ตัด 3 มม.',
                        leather_5mm_cutting: 'หนัง - ตัด 5 มม.',
                        leather_engraving: 'หนัง - กัดขูด',
                        denim_1mm_cutting: 'ยีนส์ - 1 มม. ตัด',
                        fabric_3mm_cutting: 'ผ้า - ตัด 3 มม.',
                        fabric_5mm_cutting: 'ผ้า - ตัด 5 มม.',
                        fabric_engraving: 'ผ้า - กัดขูด',
                        rubber_bw_engraving: 'ยาง - กัดขูด',
                        glass_bw_engraving: 'แก้ว - กัดขูด',
                        metal_bw_engraving: 'โลหะ - กัดขูด',
                        steel_engraving_spray_engraving: 'โลหะ - กัดขูด',
                        stainless_steel_bw_engraving_diode: 'โลหะ - กัดขูด (เลเซอร์ไดโอด)',
                        gold_engraving: 'ทองคํา - กัดขูด',
                        brass_engraving: 'ทองแดง - กัดขูด',
                        ti_engraving: 'ไทเทเนียม - กัดขูด',
                        stainless_steel_engraving: 'สแตนเลส - กัดขูด',
                        aluminum_engraving: 'อะลูมิเนียม - กัดขูด',
                        black_acrylic_3mm_cutting: 'อะคริลิคดํา - 3 มม. ตัด',
                        black_acrylic_5mm_cutting: 'อะคริลิคดํา - 5 มม. ตัด',
                        black_acrylic_engraving: 'อะคริลิคดํา - กัดขูด',
                        abs_engraving: 'ABS - กัดขูด',
                        silver_engraving: 'เงิน - กัดขูด',
                        iron_engraving: 'เหล็ก - กัดขูด',
                        fabric_printing: 'ผ้า - พิมพ์',
                        canvas_printing: 'ผ้าใบ - พิมพ์',
                        cardstock_printing: 'กระดาษอาร์ต - พิมพ์',
                        wood_printing: 'ไม้ - พิมพ์',
                        bamboo_printing: 'ไผ่ - พิมพ์',
                        cork_printing: 'คอร์ก - พิมพ์',
                        flat_stone_printing: 'หินแบน - พิมพ์',
                        acrylic_printing: 'อะคริลิก - พิมพ์',
                        pc_printing: 'พลาสติก - พิมพ์',
                        stainless_steel_printing: 'สแตนเลส - พิมพ์',
                        gloss_leather_printing: 'หนังเรียบเงา - พิมพ์',
                        glass_printing: 'แก้ว - พิมพ์',
                        aluminum_light: 'อลูมิเนียม (สว่าง)',
                        stainless_steel_dark: 'สแตนเลส (เข้ม)',
                        stainless_steel_light: 'สแตนเลส (สว่าง)',
                        brass_dark: 'ทองเหลือง (เข้ม)',
                        brass_light: 'ทองเหลือง (สว่าง)',
                        copper: 'ทองแดง',
                        titanium_dark: 'ไทเทเนียม (เข้ม)',
                        titanium_light: 'ไทเทเนียม (สว่าง)',
                        black_abs: 'ABS สีดำ',
                        white_abs: 'ABS สีขาว',
                        opaque_acrylic: 'อะคริลิคทึบแสง',
                        stone: 'หิน',
                    },
                    inches: {
                        wood_3mm_cutting: 'ไม้ - 0.1 นิ้ว ตัด',
                        wood_5mm_cutting: 'ไม้ - 0.2 นิ้ว ตัด',
                        wood_7mm_cutting: 'ไม้ - 0.3 นิ้ว ตัด',
                        wood_8mm_cutting: 'ไม้ - 0.3 นิ้ว ตัด',
                        wood_10mm_cutting: 'ไม้ - 0.4 นิ้ว ตัด',
                        wood_engraving: 'ไม้ - สกัดลวดลาย',
                        acrylic_3mm_cutting: 'อะคริลิค - 0.1 นิ้ว ตัด',
                        acrylic_5mm_cutting: 'อะคริลิค - 0.2 นิ้ว ตัด',
                        acrylic_8mm_cutting: 'อะคริลิค - 0.3 นิ้ว ตัด',
                        acrylic_10mm_cutting: 'อะคริลิค - 0.4 นิ้ว ตัด',
                        acrylic_engraving: 'อะคริลิค - สกัดลวดลาย',
                        mdf_3mm_cutting: 'ไม้อัด - 0.1 นิ้ว ตัด',
                        mdf_5mm_cutting: 'ไม้อัด - 0.2 นิ้ว ตัด',
                        mdf_engraving: 'ไม้อัด - กัดขูด',
                        leather_3mm_cutting: 'หนัง - 0.1 นิ้ว ตัด',
                        leather_5mm_cutting: 'หนัง - 0.2 นิ้ว ตัด',
                        leather_engraving: 'หนัง - สกัดลวดลาย',
                        denim_1mm_cutting: 'ยีนส์ - ตัด 0.04 นิ้ว',
                        fabric_3mm_cutting: 'ผ้า - 0.1 นิ้ว ตัด',
                        fabric_5mm_cutting: 'ผ้า - 0.2 นิ้ว ตัด',
                        fabric_engraving: 'ผ้า - สกัดลวดลาย',
                        rubber_bw_engraving: 'ยาง - สกัดลวดลาย',
                        glass_bw_engraving: 'แก้ว - สกัดลวดลาย',
                        metal_bw_engraving: 'โลหะ - สกัดลวดลาย',
                        steel_engraving_spray_engraving: 'โลหะ - กัดขูด',
                        stainless_steel_bw_engraving_diode: 'โลหะ - สกัดลวดลาย (เลเซอร์ไดโอด)',
                        gold_engraving: 'ทองคํา - กัดขูด',
                        brass_engraving: 'ทองแดง - กัดขูด',
                        ti_engraving: 'ไทเทเนียม - กัดขูด',
                        stainless_steel_engraving: 'สแตนเลส - กัดขูด',
                        aluminum_engraving: 'อะลูมิเนียม - กัดขูด',
                        black_acrylic_3mm_cutting: 'อะคริลิกดํา - ตัด 0.1 นิ้ว',
                        black_acrylic_5mm_cutting: 'อะคริลิกดํา - ตัด 0.2 นิ้ว',
                        black_acrylic_engraving: 'อะคริลิกดํา - กัดขูด',
                        abs_engraving: 'ABS - การกัดขูด',
                        silver_engraving: 'เงิน - การกัดขูด',
                        iron_engraving: 'เหล็ก - การกัดขูด',
                        fabric_printing: 'ผ้า - การพิมพ์',
                        canvas_printing: 'ผ้าใบ - การพิมพ์',
                        cardstock_printing: 'กระดาษปอนด์ - การพิมพ์',
                        wood_printing: 'ไม้ - การพิมพ์',
                        bamboo_printing: 'ไผ่ - การพิมพ์',
                        cork_printing: 'ยาง - การพิมพ์',
                        flat_stone_printing: 'หินแบน - การพิมพ์',
                        acrylic_printing: 'อะคริลิก - การพิมพ์',
                        pc_printing: 'PC - การพิมพ์',
                        stainless_steel_printing: 'สแตนเลสสตีล - การพิมพ์',
                        gloss_leather_printing: 'หนังเรียบเงา - การพิมพ์',
                        glass_printing: 'แก้ว - การพิมพ์',
                        aluminum_light: 'อลูมิเนียม (สว่าง)',
                        stainless_steel_dark: 'สแตนเลส (เข้ม)',
                        stainless_steel_light: 'สแตนเลส (สว่าง)',
                        brass_dark: 'ทองเหลือง (เข้ม)',
                        brass_light: 'ทองเหลือง (สว่าง)',
                        copper: 'ทองแดง',
                        titanium_dark: 'ไทเทเนียม (เข้ม)',
                        titanium_light: 'ไทเทเนียม (สว่าง)',
                        black_abs: 'ABS สีดำ',
                        white_abs: 'ABS สีขาว',
                        opaque_acrylic: 'อะคริลิคทึบแสง',
                        stone: 'หิน',
                    },
                },
                laser_speed: {
                    text: 'ความเร็ว',
                    unit: 'มม./วินาที',
                    fast: 'เร็ว',
                    slow: 'ช้า',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'กําลัง',
                    high: 'สูง',
                    low: 'ต่ํา',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'ชนิดหมึก',
                    normal: 'หมึก',
                    UV: 'หมึก UV',
                },
                para_in_use: 'กําลังใช้พารามิเตอร์นี้อยู่',
                do_not_adjust_default_para: 'ไม่สามารถปรับแต่งค่าเริ่มต้น',
                existing_name: 'มีชื่อนี้อยู่แล้ว',
                presets: 'ค่าที่ตั้งไว้',
                preset_management: {
                    preset: 'การตั้งค่าเริ่มต้น',
                    title: 'จัดการพารามิเตอร์',
                    add_new: 'เพิ่มใหม่',
                    lower_focus_by: 'ลดโฟกัสลงโดย',
                    save_and_exit: 'บันทึกและออก',
                    delete: 'ลบ',
                    reset: 'รีเซ็ต',
                    sure_to_reset: 'สิ่งนี้จะลบพารามิเตอร์ที่คุณกำหนดเองและรีเซ็ตการตั้งค่าล่วงหน้าทั้งหมด คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ?',
                    show_all: 'แสดงทั้งหมด',
                    laser: 'เลเซอร์',
                    print: 'พิมพ์',
                    export: 'ส่งออก',
                    export_preset_title: 'ส่งออกการตั้งค่าล่วงหน้า',
                    import: 'นําเข้า',
                    sure_to_import_presets: 'สิ่งนี้จะโหลดการจัดเรียงการตั้งค่าล่วงหน้าและเขียนทับพารามิเตอร์ที่กำหนดเอง คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ?',
                    new_preset_name: 'ชื่อการตั้งค่าล่วงหน้าใหม่',
                },
            },
            object_panel: {
                zoom: 'ซูม',
                group: 'จัดกลุ่ม',
                ungroup: 'แยกกลุ่ม',
                distribute: 'กระจาย',
                hdist: 'กระจายแนวนอน',
                vdist: 'กระจายแนวตั้ง',
                align: 'จัดแนว',
                left_align: 'ชิดซ้าย',
                center_align: 'กึ่งกลาง',
                right_align: 'ชิดขวา',
                top_align: 'บนสุด',
                middle_align: 'กึ่งกลางแนวตั้ง',
                bottom_align: 'ล่างสุด',
                boolean: 'บูลีน',
                union: 'รวม',
                subtract: 'ลบ',
                intersect: 'ตัด',
                difference: 'แตกต่าง',
                flip: 'พลิก',
                hflip: 'พลิกแนวนอน',
                vflip: 'พลิกแนวตั้ง',
                lock_aspect: 'ล็อคอัตราส่วน',
                unlock_aspect: 'ปลดล็อคอัตราส่วน',
                option_panel: {
                    fill: 'เติมสีใน',
                    rounded_corner: 'มุมมน',
                    sides: 'ด้าน',
                    font_family: 'แบบอักษร',
                    font_style: 'สไตล์',
                    font_size: 'ขนาด',
                    letter_spacing: 'ระยะห่างตัวอักษร',
                    line_spacing: 'ระยะบรรทัด',
                    vertical_text: 'ข้อความแนวตั้ง',
                    start_offset: 'ช่องว่างข้อความ',
                    vertical_align: 'การจัดแนว',
                    text_infill: 'เติมสีในข้อความ',
                    path_infill: 'เติมสีในเส้นทาง',
                    shading: 'ไล่ระดับสี',
                    pwm_engraving: 'โหมดความลึก',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'เกณฑ์ความสว่าง',
                    threshold_short: 'เกณฑ์',
                    stroke: 'เส้นขอบ',
                    stroke_color: 'สีเส้นขอบ',
                    stroke_width: 'ความกว้างของเส้น',
                    color: 'สี',
                },
                actions_panel: {
                    replace_with: 'แทนที่ด้วย...',
                    replace_with_short: 'แทนที่',
                    trace: 'ติดตาม',
                    grading: 'ความละเอียด',
                    brightness: 'ความสว่าง',
                    sharpen: 'คมชัด',
                    crop: 'ครอบตัด',
                    bevel: 'ขอบเขต',
                    invert: 'กลับด้าน',
                    weld_text: 'เชื่อมติดข้อความ',
                    convert_to_path: 'แปลงเป็นเส้นทาง',
                    fetching_web_font: 'กําลังดึงฟอนต์ออนไลน์...',
                    uploading_font_to_machine: 'กําลังอัปโหลดฟอนต์ไปยังเครื่อง...',
                    wait_for_parsing_font: 'กําลังวิเคราะห์ฟอนต์... กรุณารอสักครู่',
                    offset: 'ชดเชย',
                    array: 'แถวลําดับ',
                    auto_fit: 'ปรับให้พอดีอัตโนมัติ',
                    smart_nest: 'จัดวางอัจฉริยะ',
                    decompose_path: 'แยกส่วน',
                    disassemble_use: 'ถอดประกอบ',
                    create_textpath: 'สร้างข้อความบนเส้นทาง',
                    create_textpath_short: 'ข้อความบนเส้นทาง',
                    detach_path: 'แยกข้อความออกจากเส้นทาง',
                    detach_path_short: 'ถอดประกอบ',
                    edit_path: 'แก้ไขเส้นทาง',
                    disassembling: 'กําลังถอดประกอบ...',
                    ungrouping: 'กําลังแยกกลุ่ม...',
                    simplify: 'ลดรายละเอียด',
                    ai_bg_removal: 'ลบพื้นหลัง',
                    ai_bg_removal_short: 'ลบพื้นหลัง',
                    ai_bg_removal_reminder: 'กดปุ่มจะใช้เครดิต 0.2 เลย ต้องการดําเนินการต่อหรือไม่',
                    outline: 'เส้นกรอบ',
                },
                path_edit_panel: {
                    node_type: 'ประเภทจุดเชื่อม',
                    sharp: 'แหลม',
                    round: 'มน',
                    connect: 'เชื่อมต่อ',
                    disconnect: 'ตัดการเชื่อมต่อ',
                    delete: 'ลบ',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'แปลงข้อความเป็นเส้นทาง...',
            retreive_image_data: 'ดึงข้อมูลรูปภาพ...',
            export_file_error_ask_for_upload: 'ส่งออกล้มเหลว ต้องการส่งงานสู่ทีมพัฒนาเพื่อรายงานข้อบกพร่องหรือไม่',
        },
        image_trace_panel: {
            apply: 'นําไปใช้',
            back: 'กลับ',
            cancel: 'ยกเลิก',
            next: 'ถัดไป',
            brightness: 'ความสว่าง',
            contrast: 'ความคมชัด',
            threshold: 'เกณฑ์',
            okay: 'ตกลง',
            tuning: 'พารามิเตอร์',
        },
        photo_edit_panel: {
            apply: 'นําไปใช้',
            back: 'กลับ',
            cancel: 'ยกเลิก',
            next: 'ถัดไป',
            sharpen: 'คมชัด',
            sharpness: 'ระดับความคมชัด',
            radius: 'รัศมี',
            crop: 'ครอบตัด',
            aspect_ratio: 'อัตราส่วน',
            original: 'ต้นฉบับ',
            free: 'อิสระ',
            curve: 'เส้นโค้ง',
            start: 'เริ่ม',
            processing: 'กําลังประมวลผล',
            invert: 'กลับสี',
            okay: 'ตกลง',
            compare: 'เปรียบเทียบ',
            phote_edit: 'แก้ไขรูปภาพ',
            brightness_and_contrast: 'ความสว่าง / ความคมชัด',
            brightness: 'ความสว่าง',
            contrast: 'ความคมชัด',
            rotary_warped: 'บิดเบี้ยวแบบหมุน',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'เส้นผ่านศูนย์กลาง',
            circumference: 'เส้นรอบวง',
            warp: 'บิดเบี้ยว',
        },
        document_panel: {
            document_settings: 'การตั้งค่าเอกสาร',
            machine: 'เครื่อง',
            laser_source: 'แหล่งกำเนิดเลเซอร์',
            workarea: 'พื้นที่ทํางาน',
            rotary_mode: 'โหมดหมุน',
            borderless_mode: 'โหมดเปิดก้น',
            engrave_dpi: 'ความละเอียด',
            enable_diode: 'เลเซอร์ไดโอด',
            enable_autofocus: 'โฟกัสอัตโนมัติ',
            extend_workarea: 'ขยายพื้นที่ทำงาน',
            mirror: 'กระจก',
            pass_through: 'ผ่าน',
            pass_through_height_desc: 'ป้อนความยาวของวัตถุเพื่อขยายพื้นที่การทำงาน',
            start_position: 'ตำแหน่งเริ่มต้น',
            start_from: 'เริ่มจาก',
            origin: 'ต้นกำเนิด',
            current_position: 'ตำแหน่งปัจจุบัน',
            job_origin: 'ต้นกำเนิดงาน',
            add_on: 'เพิ่มเติม',
            low: 'ต่ํา',
            medium: 'ปานกลาง',
            high: 'สูง',
            ultra: 'สูงมาก',
            enable: 'เปิดใช้งาน',
            disable: 'ปิดใช้งาน',
            notification: {
                changeFromPrintingWorkareaTitle: 'คุณต้องการแปลงชั้นการพิมพ์เป็นชั้นเลเซอร์หรือไม่?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'กําลังแปลงฟอนต์... กรุณารอสักครู่',
            text_to_path: {
                font_substitute_pop: 'ข้อความของคุณมีอักขระที่แบบอักษรปัจจุบันไม่รองรับ <br/>คุณต้องการใช้ <strong>%s</strong> แทนไหม?',
                check_thumbnail_warning: 'บางข้อความถูกเปลี่ยนเป็นฟอนต์อื่นเมื่อแปลงข้อความเป็นเส้นทาง และบางอักขระอาจไม่ได้แปลงไปตามปกติ\nกรุณาตรวจสอบภาพตัวอย่างอีกครั้งก่อนส่งงาน',
                error_when_parsing_text: 'ข้อผิดพลาดเมื่อแปลงข้อความเป็นเส้นทาง',
                use_current_font: 'ใช้ฟอนต์ปัจจุบัน',
                retry: 'โปรดลองใหม่ในภายหลังหรือเลือกแบบอักษรอื่น',
            },
            lock_desc: 'รักษาสัดส่วนความกว้างและความสูง (SHIFT)',
        },
        tool_panels: {
            cancel: 'ยกเลิก',
            confirm: 'ยืนยัน',
            grid_array: 'สร้างตารางแบบกริด',
            array_dimension: 'มิติของตาราง',
            rows: 'แถว',
            columns: 'คอลัมน์',
            array_interval: 'ระยะห่างของตาราง',
            dx: 'X',
            dy: 'Y',
            offset: 'ชดเชย',
            nest: 'การจัดเรียงเพื่อเพิ่มประสิทธิภาพ',
            _offset: {
                direction: 'ทิศทางชดเชย',
                inward: 'เข้าหา',
                outward: 'ออกจาก',
                dist: 'ระยะห่างชดเชย',
                corner_type: 'มุม',
                sharp: 'แหลม',
                round: 'มน',
                fail_message: 'ไม่สามารถชดเชยวัตถุ',
                not_support_message: 'องค์ประกอบที่เลือกมีแท็ก SVG ไม่รองรับ: รูปภาพ, กลุ่ม, ข้อความ และวัตถุนําเข้า',
            },
            _nest: {
                start_nest: 'จัดเรียง',
                stop_nest: 'หยุด',
                end: 'ปิด',
                spacing: 'ระยะห่าง',
                rotations: 'การหมุน',
                no_element: 'ไม่มีองค์ประกอบสําหรับจัดเรียง',
            },
        },
        network_testing_panel: {
            network_testing: 'การทดสอบเครือข่าย',
            local_ip: 'ที่อยู่ IP ท้องถิ่น:',
            insert_ip: 'ที่อยู่ IP อุปกรณ์เป้าหมาย:',
            empty_ip: '#818 โปรดป้อนที่อยู่ IP อุปกรณ์เป้าหมายก่อน',
            start: 'เริ่ม',
            end: 'สิ้นสุด',
            testing: 'กําลังทดสอบเครือข่าย...',
            invalid_ip: '#818 ที่อยู่ IP ไม่ถูกต้อง',
            ip_startswith_169: '#843 ที่อยู่ IP เครื่องเริ่มต้นด้วย 169.254',
            connection_quality: 'คุณภาพการเชื่อมต่อ',
            average_response: 'เวลาตอบสนองเฉลี่ย',
            test_completed: 'ทดสอบเสร็จสิ้น',
            test_fail: 'ทดสอบล้มเหลว',
            cannot_connect_1: '#840 ไม่สามารถเชื่อมต่อกับ IP เป้าหมาย',
            cannot_connect_2: '#840 ไม่สามารถเชื่อมต่อกับ IP เป้าหมาย โปรดตรวจสอบให้แน่ใจว่าเป้าหมายอยู่ในเครือข่ายเดียวกัน',
            network_unhealthy: '#841 คุณภาพการเชื่อมต่อ <70 หรือเวลาตอบสนองเฉลี่ย >100 มิลลิวินาที',
            device_not_on_list: '#842 เครื่องไม่อยู่ในรายการ แต่คุณภาพการเชื่อมต่อ >70 และเวลาตอบสนองเฉลี่ย <100 มิลลิวินาที',
            hint_device_often_on_list: 'เครื่องไม่พบในรายการบ่อยครั้ง?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'ล้มเหลวในการเชื่อมต่อเมื่อส่งงาน?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'เกิดการหมดเวลาเมื่อเริ่มต้นดูตัวอย่างกล้อง?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'การเข้าถึง IP address ภายในล้มเหลว',
            fail_to_start_network_test: '#817 ล้มเหลวในการเริ่มต้นทดสอบเครือข่าย',
            linux_permission_hint: 'ข้อผิดพลาดนี้เกิดขึ้นจากสิทธิ์ที่ไม่เพียงพอ\nโปรดเรียกใช้ "sudo beam-studio --no-sandbox" ใน terminal เพื่อให้ได้สิทธิ์และทดสอบเครือข่าย',
        },
        layer_color_config_panel: {
            layer_color_config: 'การกําหนดสีชั้น',
            color: 'สี',
            power: 'กําลัง',
            speed: 'ความเร็ว',
            repeat: 'ทําซ้ํา',
            add: 'เพิ่ม',
            default: 'รีเซ็ตเป็นค่าเริ่มต้น',
            add_config: 'เพิ่มสี',
            in_use: 'กําลังใช้สีนี้อยู่',
            no_input: 'กรุณาใส่รหัสสีเฮ็กซ์ที่ถูกต้อง',
            sure_to_reset: 'คุณต้องการรีเซ็ตเป็นค่าเริ่มต้น ใช่หรือไม่ การตั้งค่าที่กําหนดเองทั้งหมดจะหายไป',
            sure_to_delete: 'คุณแน่ใจหรือไม่ที่จะลบการตั้งค่าสีนี้?',
        },
        rating_panel: {
            title: 'ชอบ Beam Studio?',
            description: 'ถ้าคุณชอบ Beam Studio เราจะขอบคุณอย่างยิ่งหากคุณสามารถให้คะแนนเรา',
            dont_show_again: 'อย่าแสดงอีก',
            thank_you: 'ขอบคุณ!',
        },
        svg_editor: {
            unnsupported_file_type: 'ประเภทไฟล์ไม่รองรับ โปรดแปลงไฟล์เป็น SVG หรือบิตแมป',
            unable_to_fetch_clipboard_img: 'ไม่สามารถดึงรูปภาพจากคลิปบอร์ดได้',
        },
        units: {
            walt: 'W',
            mm: 'มม.',
        },
        path_preview: {
            play: 'เล่น',
            pause: 'หยุดชั่วคราว',
            stop: 'หยุด',
            play_speed: 'ความเร็วในการเล่น',
            travel_path: 'เส้นทางเดินทาง',
            invert: 'กลับด้าน',
            preview_info: 'ข้อมูลแสดงตัวอย่าง',
            size: 'ขนาด',
            estimated_time: 'ประมาณการเวลาทั้งหมด',
            cut_time: 'เวลาตัด',
            rapid_time: 'เวลาเดินทาง',
            cut_distance: 'ระยะทางตัด',
            rapid_distance: 'ระยะทางเดินทาง',
            current_position: 'ตําแหน่งปัจจุบัน',
            remark: '* ข้อมูลทั้งหมดเป็นค่าประมาณการ',
            start_here: 'เริ่มต้นที่นี่',
            end_preview: 'สิ้นสุดการแสดงตัวอย่าง',
        },
        shapes_panel: {
            title: 'องค์ประกอบ',
            basic: 'พื้นฐาน',
            shape: 'รูปร่าง',
            graphics: 'กราฟิก',
            arrow: 'ลูกศร',
            label: 'ป้ายชื่อ',
            decor: 'ตกแต่ง',
            circular: 'วงกลม',
            corner: 'มุม',
            line: 'เส้น',
            photo: 'รูปภาพ',
            ribbon: 'ริบบิ้น',
            speech: 'คําพูด',
            text: 'กรอบข้อความ',
            animals: 'สัตว์',
            birds: 'นก',
            land: 'บก',
            sea: 'ทะเล',
            holidays: 'วันหยุด',
            celebration: 'งานเฉลิมฉลอง',
            CNY: 'ตรุษจีน',
            easter: 'อีสเตอร์',
            halloween: 'ฮาโลวีน',
            valentines: 'วาเลนไทน์',
            Xmas: 'คริสต์มาส',
            nature: 'ธรรมชาติ',
            elements: 'องค์ประกอบ',
            environment: 'สิ่งแวดล้อม',
            plants: 'พืช',
            weather: 'สภาพอากาศ',
        },
        announcement_panel: {
            title: 'ประกาศ',
            dont_show_again: 'ไม่แสดงซ้ําอีก',
        },
    },
    editor: {
        prespray_area: 'พื้นที่พ่นสารเคมี',
        opacity: 'ความโปร่งแสงของตัวอย่างภาพ',
        exposure: 'ดูตัวอย่างการรับแสง',
    },
    flux_id_login: {
        connection_fail: '#847 เชื่อมต่อกับบริการสมาชิก FLUX ไม่สําเร็จ',
        login_success: 'เข้าสู่ระบบสําเร็จ',
        login: 'เข้าสู่ระบบ',
        unlock_shape_library: 'เข้าสู่ระบบเพื่อปลดล็อกฐานข้อมูลรูปทรง',
        email: 'อีเมล',
        password: 'รหัสผ่าน',
        remember_me: 'จดจําฉันไว้',
        forget_password: 'ลืมรหัสผ่าน?',
        register: 'สร้างบัญชี FLUX',
        offline: 'ทํางานออฟไลน์',
        work_offline: 'ทํางานออฟไลน์',
        incorrect: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
        not_verified: 'อีเมลยังไม่ได้รับการยืนยัน',
        new_to_flux: 'ใหม่กับ FLUX? สร้างบัญชี',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'เรียกดูแผน FLUX+',
            thank_you: 'ขอบคุณที่เป็นสมาชิกที่มีค่าของเรา!',
            ai_credit_tooltip: 'สําหรับการลบพื้นหลัง AI',
            flux_credit_tooltip: 'สําหรับไฟล์ตลาดการออกแบบและการลบพื้นหลัง AI',
            goto_member_center: 'ไปที่ศูนย์สมาชิก',
            access_plus_feature_1: 'คุณกําลังเข้าถึง',
            access_plus_feature_2: 'คุณสมบัติ',
            access_plus_feature_note: 'คุณต้องเป็นสมาชิก FLUX+ เพื่อใช้คุณสมบัตินี้',
            access_monotype_feature: 'คุณไม่มีโมโนไทป์ ฟอนต์ แอดออน',
            access_monotype_feature_note: 'คุณต้องเป็นสมาชิก FLUX+ Pro หรือซื้อโมโนไทป์ ฟอนต์ แอดออน เพื่อใช้คุณสมบัตินี้',
            learn_more: 'เรียนรู้เพิ่มเติม',
            get_addon: 'รับแอดออน',
            subscribe_now: 'สมัครรับข่าวสารตอนนี้',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'การลบพื้นหลัง AI',
                my_cloud: 'พื้นที่จัดเก็บข้อมูลบนคลาวด์ไม่จํากัด',
                boxgen: 'โปรแกรมสร้างกล่อง 3 มิติ',
                dmkt: 'ไฟล์ดีไซน์ 1000+',
                monotype: 'ฟอนต์พรีเมียม 250+',
            },
        },
    },
    noun_project_panel: {
        login_first: 'เข้าสู่ระบบเพื่อปลดล็อกฐานข้อมูลรูปทรง',
        enjoy_shape_library: 'เพลิดเพลินกับฐานข้อมูลรูปทรง',
        shapes: 'รูปทรง',
        elements: 'องค์ประกอบ',
        recent: 'ล่าสุด',
        search: 'ค้นหา',
        clear: 'ล้าง',
        export_svg_title: 'ไม่สามารถส่งออก SVG',
        export_svg_warning: 'โปรเจ็กต์นี้มีวัตถุที่ได้รับความคุ้มครองโดยกฎหมายทรัพย์สินทางปัญญา ดังนั้น Beam Studio จะตัดวัตถุเหล่านี้ออกโดยอัตโนมัติระหว่างการส่งออก คุณยังคงสามารถบันทึกโปรเจ็กต์ในรูปแบบ Beam Studio Scene (.beam) เพื่อเก็บข้อมูลทั้งหมด คุณต้องการส่งออกอยู่หรือไม่?',
        learn_more: 'เรียนรู้เพิ่มเติม',
    },
    change_logs: {
        change_log: 'บันทึกการเปลี่ยนแปลง:',
        added: 'เพิ่ม:',
        fixed: 'แก้ไข:',
        changed: 'เปลี่ยน:',
        see_older_version: 'ดูเวอร์ชันเก่า',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'เลือกอุปกรณ์',
        auth_failure: '#811 การยืนยันตัวตนล้มเหลว',
        unable_to_connect: '#810 ไม่สามารถสร้างการเชื่อมต่อที่มั่นคงกับเครื่อง',
    },
    device: {
        pause: 'หยุดชั่วคราว',
        paused: 'หยุดชั่วคราว',
        pausing: 'กําลังหยุดชั่วคราว',
        select_printer: 'เลือกเครื่องพิมพ์',
        retry: 'ลองใหม่',
        status: 'สถานะ',
        busy: 'กําลังใช้งาน',
        ready: 'พร้อม',
        reset: 'รีเซ็ต',
        abort: 'ยกเลิก',
        start: 'เริ่ม',
        please_wait: 'โปรดรอสักครู่...',
        quit: 'ออกจากระบบ',
        completing: 'กําลังเสร็จสิ้น',
        aborted: 'ถูกยกเลิก',
        completed: 'เสร็จสิ้น',
        aborting: 'กําลังยกเลิก',
        starting: 'กําลังเริ่มต้น',
        preparing: 'กําลังเตรียม',
        resuming: 'กําลังดําเนินการต่อ',
        scanning: 'กําลังสแกน',
        occupied: 'กําลังบํารุงรักษา',
        running: 'กําลังทํางาน',
        uploading: 'กําลังอัปโหลด',
        processing: 'กําลังประมวลผล',
        disconnectedError: {
            caption: 'เครื่องตัดไม่ได้เชื่อมต่อ',
            message: 'โปรดตรวจสอบการเข้าถึงเครือข่ายของ %s',
        },
        unknown: 'ไม่ทราบ',
        pausedFromError: 'หยุดชั่วคราวจากข้อผิดพลาด',
        model_name: 'รุ่น',
        IP: 'IP',
        serial_number: 'หมายเลขซีเรียล',
        firmware_version: 'เวอร์ชันเฟิร์มแวร์',
        UUID: 'UUID',
        select: 'เลือก',
        deviceList: 'รายการอุปกรณ์',
        disable: 'ปิดใช้งาน',
        enable: 'เปิดใช้งาน',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: 'เปลี่ยนหัวเครื่องมือ',
    },
    monitor: {
        monitor: 'ตรวจสอบ',
        go: 'เริ่ม',
        resume: 'ดําเนินการต่อ',
        start: 'เริ่ม',
        pause: 'หยุดชั่วคราว',
        stop: 'หยุด',
        record: 'บันทึก',
        camera: 'กล้อง',
        taskTab: 'งาน',
        connecting: 'กําลังเชื่อมต่อ...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 ข้อผิดพลาดวิกฤติ: เมนบอร์ดออฟไลน์ โปรดติดต่อฝ่ายสนับสนุน',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 ข้อผิดพลาดวิกฤติ: ระบบย่อยไม่ตอบสนอง โปรดติดต่อฝ่ายสนับสนุน',
        HARDWARE_ERROR_PUMP_ERROR: '#900 โปรดตรวจสอบถังน้ําของคุณ',
        HARDWARE_ERROR_DOOR_OPENED: '#901 ปิดประตูเพื่อดําเนินการต่อ',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 ร้อนเกินไป โปรดรอสักครู่',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 ฐานเปิดอยู่ ปิดฐานเพื่อดําเนินการต่อ',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 รีเซ็ตแกน Z ล้มเหลว',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 ลิ้นชักเปิดอยู่',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 ตรวจพบไฟผิดปกติ',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 ตรวจพบการไหลของอากาศผิดปกติ',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 ไม่พบโมดูลหมุน',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 ข้อผิดพลาดการดึงกลับโฮมมิ่ง',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 โมดูลที่ตรวจพบไม่ถูกต้อง โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 ไม่พบโมดูล โปรดตรวจสอบให้แน่ใจว่าโมดูลติดตั้งอย่างเหมาะสมเพื่อดําเนินการต่อ',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 ตรวจพบโมดูลที่ไม่รู้จัก โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 ไม่มีการตอบสนองจากโมดูลพิมพ์',
        USER_OPERATION_ROTARY_PAUSE: 'โปรดสลับไปยังมอเตอร์หมุน',
        USER_OPERATION_REMOVE_CARTRIDGE: 'โปรดนําตลับหมึกออกเพื่อดําเนินการต่อ',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 โมดูลที่ตรวจพบไม่ถูกต้อง โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 ไม่พบโมดูล โปรดตรวจสอบให้แน่ใจว่าโมดูลติดตั้งอย่างเหมาะสมเพื่อดําเนินการต่อ',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 ตรวจพบโมดูลที่ไม่รู้จัก โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
        USER_OPERATION_CHANGE_TOOLHEAD: 'โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'กรุณาใส่แผ่นหมึกเพื่อดำเนินการต่อ',
        USER_OPERATION_CHANGE_CARTRIDGE: 'กรุณาใส่แผ่นหมึกที่ถูกต้องเพื่อดำเนินการต่อ',
        USER_OPERATION: 'กรุณาทำตามคำแนะนำในพาเนลของอุปกรณ์เพื่อดำเนินการต่อ',
        RESOURCE_BUSY: 'เครื่องกําลังทํางานอยู่\nหากไม่ทํางาน โปรดรีสตาร์ทเครื่อง',
        DEVICE_ERROR: 'เกิดข้อผิดพลาด\nโปรดรีสตาร์ทเครื่อง',
        NO_RESPONSE: '#905 ข้อผิดพลาดการเชื่อมต่อกับเมนบอร์ด\nโปรดรีสตาร์ทเครื่อง',
        SUBSYSTEM_ERROR: '#402 ข้อผิดพลาดวิกฤติ: ระบบย่อยไม่ตอบสนอง โปรดติดต่อฝ่ายสนับสนุน',
        HARDWARE_FAILURE: 'เกิดข้อผิดพลาด\nโปรดรีสตาร์ทเครื่อง',
        MAINBOARD_OFFLINE: '#905 ข้อผิดพลาดการเชื่อมต่อกับเมนบอร์ด\nโปรดรีสตาร์ทเครื่อง',
        bug_report: 'รายงานข้อบกพร่อง',
        processing: 'กําลังประมวลผล',
        savingPreview: 'กําลังสร้างภาพขนาดย่อ',
        hour: 'ชม.',
        minute: 'นาที',
        second: 'วินาที',
        left: 'เหลือ',
        temperature: 'อุณหภูมิ',
        forceStop: 'ยกเลิกงานปัจจุบัน',
        upload: 'อัปโหลด',
        download: 'ดาวน์โหลด',
        relocate: 'ย้ายตําแหน่ง',
        cancel: 'ยกเลิก',
        prepareRelocate: 'กําลังเตรียมการย้ายตําแหน่ง',
        extensionNotSupported: 'รูปแบบไฟล์ไม่รองรับ',
        fileExistContinue: 'ไฟล์มีอยู่แล้ว ต้องการแทนที่หรือไม่',
        confirmFileDelete: 'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์',
        ask_reconnect: 'การเชื่อมต่อถูกตัด ต้องการเชื่อมต่อใหม่หรือไม่',
        task: {
            BEAMBOX: 'เลเซอร์กัดขูด',
            'N/A': 'โหมดอิสระ',
        },
    },
    alert: {
        caption: 'ข้อผิดพลาด',
        duplicated_preset_name: 'ชื่อชุดค่าที่ซ้ํากัน',
        info: 'ข้อมูล',
        warning: 'คําเตือน',
        error: 'โอ้โห',
        instruction: 'คําแนะนํา',
        oops: 'โอ๊ย...',
        retry: 'ลองใหม่',
        abort: 'ยกเลิก',
        confirm: 'ยืนยัน',
        cancel: 'ยกเลิก',
        close: 'ปิด',
        ok: 'ตกลง',
        ok2: 'ตกลง',
        yes: 'ใช่',
        no: 'ไม่ใช่',
        stop: 'หยุด',
        save: 'บันทึก',
        dont_save: 'ไม่บันทึก',
        learn_more: 'เรียนรู้เพิ่มเติม',
    },
    caption: {
        connectionTimeout: 'หมดเวลาการเชื่อมต่อ',
    },
    message: {
        cancelling: 'ยกเลิก...',
        connecting: 'กําลังเชื่อมต่อ...',
        connectingMachine: 'กําลังเชื่อมต่อ %s...',
        tryingToConenctMachine: 'กําลังพยายามเชื่อมต่อเครื่อง...',
        connected: 'เชื่อมต่อแล้ว',
        authenticating: 'กําลังตรวจสอบสิทธิ์...',
        enteringRawMode: 'กําลังเข้าสู่โหมดดิบ...',
        endingRawMode: 'กําลังออกจากโหมดดิบ...',
        enteringLineCheckMode: 'กําลังเข้าสู่โหมดตรวจสอบเส้น...',
        endingLineCheckMode: 'ออกจากโหมดตรวจสอบเส้น...',
        enteringRedLaserMeasureMode: 'กำลังเข้าสู่โหมดวัดด้วยเลเซอร์สีแดง...',
        redLaserTakingReference: 'กำลังรับข้อมูลอ้างอิง...',
        exitingRotaryMode: 'ออกจากโหมดหมุน...',
        turningOffFan: 'ปิดแฟน...',
        turningOffAirPump: 'ปิดปั๊มลม...',
        gettingLaserSpeed: 'อ่านความเร็วหัวเลเซอร์...',
        settingLaserSpeed: 'ตั้งค่าความเร็วหัวเลเซอร์...',
        retrievingCameraOffset: 'อ่านค่าชดเชยกล้อง...',
        connectingCamera: 'เชื่อมต่อกล้อง...',
        homing: 'กําลังโฮมมิ่ง...',
        connectionTimeout: '#805 หมดเวลาเชื่อมต่ออุปกรณ์ โปรดตรวจสอบสถานะเครือข่ายและตัวบ่งชี้ Wi-Fi ของเครื่อง',
        getProbePosition: 'กําลังรับค่าตําแหน่งของโปรบ...',
        device_not_found: {
            caption: 'ไม่พบเครื่องพื้นฐาน',
            message: '#812 โปรดตรวจสอบตัวบ่งชี้ Wi-Fi ของเครื่อง',
        },
        device_busy: {
            caption: 'เครื่องกําลังใช้งาน',
            message: 'เครื่องกําลังทํางานอยู่ โปรดลองอีกครั้งในภายหลัง หากหยุดทํางาน โปรดรีสตาร์ทเครื่อง',
        },
        device_is_used: 'เครื่องกําลังใช้งานอยู่ ต้องการยกเลิกงานปัจจุบันหรือไม่?',
        unknown_error: '#821 แอปพลิเคชันพบข้อผิดพลาดที่ไม่รู้จัก โปรดใช้ ช่วยเหลือ > เมนู > รายงานข้อบกพร่อง',
        unknown_device: '#826 ไม่สามารถเชื่อมต่อกับเครื่องได้ โปรดตรวจสอบว่า USB เชื่อมต่อกับเครื่องอยู่',
        unsupport_osx_version: 'เวอร์ชัน macOS ปัจจุบัน %s อาจไม่สนับสนุนบางฟังก์ชัน โปรดอัปเดตเป็น macOS 11+',
        unsupport_win_version: 'เวอร์ชัน OS ปัจจุบัน %s อาจไม่สนับสนุนบางฟังก์ชัน โปรดอัปเดตเป็นเวอร์ชันล่าสุด',
        need_password: 'ต้องการรหัสผ่านในการเชื่อมต่อกับเครื่อง',
        unavailableWorkarea: '#804 พื้นที่ทํางานปัจจุบันเกินพื้นที่ทํางานของเครื่องนี้ โปรดตรวจสอบพื้นที่ทํางานของเครื่องที่เลือก หรือตั้งค่าพื้นที่ทํางานจาก แก้ไข > การตั้งค่าเอกสาร',
        please_enter_dpi: 'โปรดป้อนหน่วยของไฟล์ของคุณ (เป็นมิลลิเมตร)',
        auth_error: '#820 ข้อผิดพลาดการตรวจสอบสิทธิ์: โปรดอัปเดต Beam Studio และเฟิร์มแวร์เครื่องเป็นเวอร์ชันล่าสุด',
        usb_unplugged: 'การเชื่อมต่อ USB หายไป โปรดตรวจสอบการเชื่อมต่อ USB ของคุณ',
        uploading_fcode: 'กําลังอัปโหลด FCode',
        cant_connect_to_device: '#827 ไม่สามารถเชื่อมต่อกับเครื่องได้ โปรดตรวจสอบการเชื่อมต่อของคุณ',
        unable_to_find_machine: 'ไม่พบเครื่อง ',
        disconnected: 'การเชื่อมต่อไม่มั่นคง โปรดตรวจสอบการเชื่อมต่ออุปกรณ์และลองอีกครั้งในภายหลัง',
        unable_to_start: '#830 ไม่สามารถเริ่มงานได้ โปรดลองอีกครั้ง หากเกิดขึ้นอีก โปรดติดต่อเราพร้อมรายงานข้อบกพร่อง',
        camera: {
            camera_cable_unstable: 'ตรวจพบว่ากล้องกําลังส่งรูปภาพไม่มั่นคง การแสดงตัวอย่างยังคงทํางานได้ตามปกติ แต่อาจมีปัญหาการแสดงตัวอย่างช้าหรือหมดเวลา',
            fail_to_transmit_image: '#845 มีบางอย่างผิดพลาดกับการส่งภาพ โปรดรีสตาร์ทเครื่องของคุณหรือ Beam Studio หากข้อผิดพลาดนี้ยังคงเกิดขึ้น โปรดทําตาม<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">คู่มือนี้</a>',
            ws_closed_unexpectly: '#844 การเชื่อมต่อกล้องเครื่องถูกปิดโดยไม่คาดคิด หากข้อผิดพลาดนี้ยังคงเกิดขึ้น โปรดทําตาม<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">คู่มือนี้</a>',
            continue_preview: 'ดําเนินการต่อ',
            abort_preview: 'ยกเลิก',
        },
        preview: {
            camera_preview: 'ตัวอย่างกล้อง',
            auto_focus: 'โฟกัสอัตโนมัติ',
            auto_focus_instruction: 'โปรดย้ายหัวโมดูลเลเซอร์เหนือวัตถุ และทําตามคําแนะนําในภาพเคลื่อนไหวเพื่อกด AF เพื่อโฟกัส',
            already_performed_auto_focus: 'คุณได้ทําการโฟกัสอัตโนมัติแล้ว ต้องการใช้ค่าที่มีอยู่หรือไม่?',
            please_enter_height: 'โปรดป้อนความสูงของวัตถุ เพื่อจับภาพกล้องอย่างแม่นยํา',
            apply: 'นําไปใช้',
            enter_manually: 'ป้อนด้วยตนเอง',
            adjust: 'ปรับ',
            adjust_height_tooltip: 'คลิกที่ช่องทําเครื่องหมายเพื่อเปิดใช้การแก้ไข',
        },
        unsupported_example_file: 'ไฟล์ตัวอย่างที่เลือกไม่รองรับพื้นที่ทํางาน',
        time_remaining: 'เวลาที่เหลือ:',
        promark_disconnected: '#850 การเชื่อมต่ออุปกรณ์ถูกตัด กรุณาตรวจสอบสถานะการเชื่อมต่อของอุปกรณ์',
        swiftray_disconnected: 'ไม่สามารถเชื่อมต่อกับแบ็กเอนด์ กำลังพยายามเชื่อมต่อใหม่',
        swiftray_reconnected: 'แบ็กเอนด์เชื่อมต่อใหม่แล้ว โปรดลองส่งงานอีกครั้ง',
        device_blocked: {
            caption: 'หมายเลขซีเรียลไม่ได้รับอนุญาต',
            online: 'หมายเลขซีเรียลของอุปกรณ์คุณดูเหมือนจะถูกปิดการใช้งาน กรุณาแจ้งหมายเลขซีเรียลของอุปกรณ์ (อยู่ด้านหลังของอุปกรณ์) ให้กับตัวแทนจำหน่ายของคุณ และให้พวกเขาติดต่อ support@flux3dp.com เพื่อเปิดใช้งานการอนุญาตอุปกรณ์',
            offline: 'หมายเลขซีเรียลของอุปกรณ์คุณถูกจำกัดให้ใช้งานแบบออฟไลน์เนื่องจากดูเหมือนจะถูกปิดการใช้งาน กรุณาแจ้งหมายเลขซีเรียลของอุปกรณ์ (อยู่ด้านหลังของอุปกรณ์) ให้กับตัวแทนจำหน่ายของคุณ และให้พวกเขาติดต่อ support@flux3dp.com เพื่อเปิดใช้งานการอนุญาตออนไลน์ หากคุณต้องการใช้อุปกรณ์แบบออฟไลน์ โปรดติดต่อ support@flux3dp.com โดยตรง',
        },
    },
    machine_status: {
        '0': 'ว่าง',
        '1': 'กําลังเริ่มต้น',
        '2': 'ST_TRANSFORM',
        '4': 'กําลังเริ่มต้น',
        '6': 'กําลังดําเนินการต่อ',
        '16': 'กําลังดําเนินการต่อ',
        '18': 'กําลังทํางาน',
        '32': 'กําลังทํางาน',
        '36': 'หยุดชั่วคราว',
        '38': 'หยุดชั่วคราว',
        '48': 'กําลังหยุดชั่วคราว',
        '50': 'หยุดชั่วคราว',
        '64': 'กําลังหยุดชั่วคราว',
        '66': 'เสร็จสิ้น',
        '68': 'กําลังเสร็จสิ้น',
        '128': 'กําลังเตรียมการ',
        '256': 'ยกเลิก',
        '512': 'สัญญาณเตือน',
        '-17': 'โหมด IO ของตลับหมึก',
        '-10': 'วิกฤต',
        '-2': 'โหมดบํารุงรักษา',
        '-1': 'กําลังสแกน',
        UNKNOWN: 'กําลังบํารุงรักษา',
    },
    calibration: {
        update_firmware_msg1: 'เฟิร์มแวร์ของคุณไม่รองรับฟังก์ชันนี้ โปรดอัปเดตเฟิร์มแวร์เป็น v ',
        update_firmware_msg2: 'หรือสูงกว่าเพื่อดําเนินการต่อ (เมนู > เครื่อง > [เครื่องของคุณ] > อัปเดตเฟิร์มแวร์)',
        camera_calibration: 'การปรับเทียบกล้อง',
        diode_calibration: 'การปรับเทียบโมดูลเลเซอร์',
        module_calibration_printer: 'การปรับเทียบโมดูลพิมพ์',
        module_calibration_2w_ir: 'การปรับเทียบโมดูลอินฟราเรด',
        back: 'กลับ',
        next: 'ถัดไป',
        skip: 'ข้าม',
        cancel: 'ยกเลิก',
        finish: 'เสร็จสิ้น',
        do_engraving: 'ทําการกัดขูด',
        start_engrave: 'เริ่มกัดขูด',
        start_printing: 'เริ่มพิมพ์',
        ask_for_readjust: 'คุณต้องการข้ามขั้นตอนการกัดขูดและถ่ายรูปเพื่อปรับเทียบเลยหรือไม่?',
        please_goto_beambox_first: 'โปรดสลับไปที่โหมดกัดขูด (Beambox) เพื่อใช้คุณสมบัตินี้',
        please_place_paper: 'โปรดวางกระดาษขนาด A4 หรือ Letter สีขาว ที่มุมซ้ายบนของพื้นที่ทํางาน',
        please_place_paper_center: 'โปรดวางกระดาษขนาด A4 หรือ Letter สีขาว ที่กลางพื้นที่ทํางาน',
        please_place_dark_colored_paper: 'โปรดวางกระดาษสีเข้มขนาด A4 หรือ Letter ที่กลางพื้นที่ทํางาน',
        please_refocus: {
            beambox: 'โปรดปรับแท่นวางให้อยู่ที่จุดโฟกัส (ความสูงของอะคริลิคที่พลิกกลับ)',
            beamo: 'โปรดปรับหัวเลเซอร์ให้โฟกัสที่วัตถุที่จะกัดขูด (ความสูงของอะคริลิคที่พลิกกลับ)',
            beamo_af: 'โปรดกดปุ่มด้านข้างของอุปกรณ์เสริมตั้งโฟกัสอัตโนมัติสองครั้งและให้เซ็นเซอร์สัมผัสวัสดุอย่างอ่อนโยน',
            hexa: 'โปรดกดปุ่มปรับระดับสองครั้งเพื่อยกโต๊ะรังผึ้งขึ้นและให้เซ็นเซอร์สัมผัสวัสดุที่จะกัดขูด',
        },
        without_af: 'ไม่มีตั้งโฟกัสอัตโนมัติ',
        with_af: 'มีตั้งโฟกัสอัตโนมัติ',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'การหมุน',
        x_ratio: 'อัตราส่วน X',
        y_ratio: 'อัตราส่วน Y',
        show_last_config: 'แสดงผลล่าสุด',
        use_last_config: 'ใช้ค่าปรับเทียบล่าสุด',
        taking_picture: 'กําลังถ่ายภาพ...',
        analyze_result_fail: 'ล้มเหลวในการวิเคราะห์ภาพที่บันทึก<br/>โปรดตรวจสอบ:<br/>1. ภาพที่บันทึกครอบคลุมกระดาษสีขาวทั้งหมด<br/>2. แท่นวางโฟกัสเหมาะสม',
        drawing_calibration_image: 'กําลังวาดภาพสําหรับการปรับเทียบ...',
        calibrate_done: 'ปรับเทียบเสร็จสิ้น ความแม่นยําของกล้องจะดีขึ้นเมื่อโฟกัสอย่างแม่นยํา',
        calibrate_done_diode: 'ปรับเทียบเสร็จสิ้น ค่าชดเชยของโมดูลเลเซอร์ไดโอดได้รับการบันทึกแล้ว',
        hint_red_square: 'โปรดจัดด้านนอกของสี่เหลี่ยมสีแดงให้ตรงกับสี่เหลี่ยมที่ตัด',
        hint_adjust_parameters: 'โปรดใช้พารามิเตอร์เหล่านี้เพื่อปรับกรอบสีแดง',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'เมื่อทําการปรับเทียบโมดูลเลเซอร์ ไดโอด กล้องจําเป็นต้องใช้งาน\nโปรดตรวจสอบให้แน่ใจว่ากล้องของเครื่องนี้ได้รับการปรับเทียบแล้ว\nและโปรดปรับแท่นวางให้อยู่ในโฟกัส',
            beamo: 'เมื่อทําการปรับเทียบโมดูลเลเซอร์ ไดโอด กล้องจําเป็นต้องใช้งาน\nโปรดตรวจสอบให้แน่ใจว่ากล้องของเครื่องนี้ได้รับการปรับเทียบแล้ว\nและโปรดปรับหัวเลเซอร์ให้โฟกัสกับวัตถุที่จะกัดเซาะ',
        },
        downloading_pictures: 'กําลังดาวน์โหลดรูปภาพ...',
        failed_to_download_pictures: '#848 ไม่สามารถดาวน์โหลดรูปภาพได้ โปรดติดต่อฝ่ายสนับสนุน FLUX',
        uploading_images: 'กําลังอัปโหลดรูปภาพ...',
        calculating_camera_matrix: 'กําลังคํานวณเมทริกซ์กล้อง...',
        calculating_regression_parameters: 'กําลังคํานวณพารามิเตอร์การถดถอย...',
        failed_to_calibrate_camera: '#848 ไม่สามารถปรับเทียบกล้องได้ โปรดติดต่อฝ่ายสนับสนุน FLUX',
        failed_to_save_calibration_results: '#849 ไม่สามารถบันทึกผลการปรับเทียบได้ โปรดลองอีกครั้ง หากยังคงเกิดขึ้นอยู่ โปรดติดต่อฝ่ายสนับสนุน FLUX',
        ador_autofocus_material: 'กดไอคอน “AF” บนหน้าหลักของเครื่องเป็นเวลา 3 วินาที แล้วให้โพรบสัมผัสวัสดุอย่างอ่อนโยน',
        ador_autofocus_focusing_block: 'กดไอคอน "AF" บนหน้าหลักของเครื่องเป็นเวลา 3 วินาที แล้วให้โพรบสัมผัส Focusing block',
        align_red_cross_cut: 'โปรดจัดตําแหน่งกลางกากบาทสีแดงให้ตรงกับกากบาทตัด',
        align_red_cross_print: 'โปรดจัดตําแหน่งกลางกากบาทสีแดงให้ตรงกับกากบาทพิมพ์',
        retake: 'ถ่ายรูปใหม่',
        calibrate_camera_before_calibrate_modules: 'โปรดทําการปรับเทียบกล้องก่อนปรับเทียบโมดูล',
        check_checkpoint_data: 'ข้อมูลจุดตรวจสอบ',
        checking_checkpoint: 'กำลังตรวจสอบข้อมูลจุดตรวจสอบ...',
        found_checkpoint: 'พบข้อมูลจุดตรวจสอบบนอุปกรณ์ของคุณ คุณต้องการกู้คืนจากจุดตรวจสอบหรือไม่?',
        use_old_camera_parameter: 'คุณต้องการใช้พารามิเตอร์เลนส์กล้องปัจจุบันหรือไม่?',
        downloading_checkpoint: 'กำลังดาวน์โหลดข้อมูลจุดตรวจสอบ...',
        failed_to_parse_checkpoint: 'การวิเคราะห์ข้อมูลจุดตรวจสอบล้มเหลว.',
        check_device_pictures: 'ตรวจสอบภาพอุปกรณ์',
        checking_pictures: 'กำลังตรวจสอบภาพอุปกรณ์...',
        no_picutre_found: '#846 อุปกรณ์ของคุณไม่มีรูปถ่ายดิบที่มีสำหรับการปรับแต่ง กรุณาติดต่อ FLUX support',
        unable_to_load_camera_parameters: "#851 ไม่มีพารามิเตอร์การปรับเทียบกล้องที่พร้อมใช้งานบนอุปกรณ์ของคุณ โปรดไปที่ 'การปรับเทียบ' > 'การปรับเทียบกล้อง (ขั้นสูง)' เพื่อดำเนินการปรับเทียบให้เสร็จสิ้นและรับพารามิเตอร์",
        calibrating_with_device_pictures: 'กำลังปรับแต่งด้วยภาพอุปกรณ์...',
        failed_to_calibrate_with_pictures: '#848 การปรับแต่งด้วยภาพอุปกรณ์ล้มเหลว',
        getting_plane_height: 'กำลังรับความสูงของระดับพื้น...',
        preparing_to_take_picture: 'กำลังเตรียมพร้อมในการถ่ายภาพ...',
        put_paper: 'วางกระดาษ',
        put_paper_step1: 'โปรดวางกระดาษสีขาวขนาด A4 หรือจดหมายในศูนย์กลางของพื้นที่ทำงาน',
        put_paper_step2: 'รัดมุมสี่มุมของกระดาษเพื่อให้แน่ใจว่ามันเรียบ',
        put_paper_step3: 'คลิก "เริ่มกัดขูด"',
        put_paper_promark_1: 'วางกระดาษแข็งสีดำจากกล่องอุปกรณ์ลงบนพื้นที่ทำงาน',
        put_paper_promark_2: 'ปรับโฟกัสให้ถูกต้อง จากนั้นคลิก "เริ่มกัดขูด" เพื่อดำเนินการแกะสลักต่อไป',
        put_paper_skip: 'หากการวาดการปรับเทียบไม่ดำเนินการถ่ายภาพโดยอัตโนมัติ ให้กด "ข้าม" เพื่อถ่ายภาพ',
        solve_pnp_title: 'ปรับตำแหน่งของจุดมาร์คเกอร์',
        solve_pnp_step1: 'โปรดจัดแนวจุดแกะสลักตามหมายเลขและตำแหน่งที่คาดหวังของแต่ละจุดเครื่องหมายสีแดง',
        solve_pnp_step2: 'คุณสามารถกด "ถ่ายรูปใหม่" เพื่อจัดตำแหน่งใหม่หรือปรับตำแหน่งของจุดมาร์คเกอร์เองได้',
        align_olt: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านนอกบนซ้าย',
        align_ort: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านนอกบนขวา',
        align_olb: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านนอกล่างซ้าย',
        align_orb: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านนอกล่างขวา',
        align_ilt: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านในบนซ้าย',
        align_irt: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านในบนขวา',
        align_ilb: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านในล่างซ้าย',
        align_irb: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านในล่างขวา',
        elevate_and_cut: 'ยกและตัด',
        elevate_and_cut_step_1: 'วางชิ้นไม้ขนาด A4 สีสว่างในศูนย์กลางของพื้นที่ทำงานและยกไปยังความสูง 20mm.',
        elevate_and_cut_step_1_prism_lift: 'ใช้ Ador Prism Lift ด้วยความยาวสูงสุด 14 มม. พร้อมกับไม้ที่มีความหนาอย่างน้อย 6 มม. เพื่อยกไม้ให้สูง 20 มม.',
        with_prism_lift: 'ด้วย Ador Prism Lift',
        without_prism_lift: 'โดยไม่มี Ador Prism Lift',
        camera_parameter_saved_successfully: 'บันทึกพารามิเตอร์ของกล้องสำเร็จ',
        failed_to_save_camera_parameter: 'การบันทึกพารามิเตอร์ของกล้องล้มเหลว',
        failed_to_solve_pnp: 'ไม่สามารถแก้ไขตำแหน่งของกล้องได้.',
        calibrating: 'กำลังปรับเทียบ...',
        moving_laser_head: 'กำลังย้ายหัวเลเซอร์...',
        failed_to_move_laser_head: 'ล้มเหลวในการย้ายหัวเลเซอร์.',
        put_chessboard: 'วางกระดานหมากรุก',
        put_chessboard_bb2_desc_1: 'กรุณาดาวน์โหลดไฟล์กระดานหมากรุกด้านล่างและพิมพ์ลงบนกระดาษ A4 (กระดานหมากรุกที่พิมพ์ควรมีช่องขนาด 1x1 ซม.)',
        put_chessboard_bb2_desc_2: 'ติดกระดาษกระดานหมากรุกที่พิมพ์ลงบนแผ่นที่ไม่เสียรูป เช่น อะคริลิกหรือกระจก โดยให้กระดานหมากรุกเรียบ ไม่มีรอยย่นหรือขอบที่ยกขึ้น',
        put_chessboard_bb2_desc_3: 'วางแผ่นกระดานหมากรุกให้อยู่ในแนวราบตรงกลางพื้นที่ทำงาน',
        put_chessboard_1: 'กรุณาไปที่แผงควบคุมเครื่องและกด AF เพื่อทำการโฟกัสอัตโนมัติ',
        put_chessboard_2: 'กรุณาเลื่อนกระดาษหมากรุกหรือหัวเลเซอร์จนกระทั่งหมากรุกทั้งหมดได้รับการยืนยันว่าอยู่ภายในกรอบสีแดงในหน้าต่างแสดงตัวอย่างสด',
        put_chessboard_3: 'คลิกขวาเพื่อดาวน์โหลดรูปกระดานหมากรุกในตำแหน่งปัจจุบันและตรวจสอบความคมชัด',
        put_chessboard_promark_desc_1: 'กรุณาใช้กระดาษตารางหมากรุกที่จัดเตรียมไว้ในกล่องอุปกรณ์เสริมหรือพิมพ์ลายตารางหมากรุกต่อไปนี้ลงบนกระดาษ A4 สำหรับการสอบเทียบกล้อง',
        put_chessboard_promark_desc_2: 'กรุณาวางกระดานหมากรุกให้แบนราบและอยู่กึ่งกลางบนแท่นทำงาน',
        put_chessboard_promark_1: 'ปรับระยะโฟกัสให้เหมาะสมกับเลนส์สนาม',
        put_chessboard_promark_2: 'กรุณาตรวจสอบให้แน่ใจว่ากระดาษตารางหมากรุกมองเห็นได้ชัดเจนโดยไม่มีแสงสะท้อน คลิก "ถัดไป" เพื่อถ่ายภาพ',
        download_chessboard_file: 'ดาวน์โหลดไฟล์กระดานหมากรุก',
        failed_to_calibrate_chessboard: 'ไม่สามารถปรับเทียบด้วยภาพหมากรุกได้',
        calibrate_chessboard_success_msg: 'ถ่ายภาพกระดานหมากรุกสำเร็จ คะแนนสำหรับภาพนี้คือ %s (%.2f).',
        res_excellent: 'ยอดเยี่ยม',
        res_average: 'ปานกลาง',
        res_poor: 'แย่',
        perform_autofocus_bb2: 'กรุณาไปที่แผงควบคุมเครื่องและกด AF เพื่อทำการโฟกัสอัตโนมัติ',
        promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    },
    input_machine_password: {
        require_password: '"%s" ต้องการรหัสผ่าน',
        connect: 'เชื่อมต่อ',
        password: 'รหัสผ่าน',
    },
    tutorial: {
        skip: 'ข้าม',
        welcome: 'ยินดีต้อนรับ',
        suggest_calibrate_camera_first: 'เราขอแนะนำให้ผู้ใช้ทำการปรับแต่งกล้องและปรับโฟกัสใหม่ก่อนการดูตัวอย่างเพื่อให้ได้ผลลัพธ์ที่ดีที่สุด<br/>ยืนยันเพื่อดำเนินการปรับแต่งตอนนี้หรือไม่?<br/>หรือคุณสามารถข้ามไปก่อนแล้วทำภายหลังได้โดยการคลิกที่ "เมนู" > "เครื่อง" > [เครื่องของคุณ] > "ปรับแต่งกล้อง"',
        camera_calibration_failed: 'การปรับเทียบกล้องล้มเหลว',
        ask_retry_calibration: 'คุณต้องการปรับเทียบกล้องใหม่อีกครั้งหรือไม่?',
        needNewUserTutorial: 'เราขอแนะนำให้ผู้ใช้สองเลนส์ในการสะท้อนภาพเพื่อปรับตำแหน่งให้พอดี<br/>คุณต้องการใช้พารามิเตอร์เลนส์ปัจจุบันหรือไม่?',
        needNewInterfaceTutorial: 'คุณต้องการเริ่มต้นการสอนสำหรับอินเตอร์เฟซใหม่ของ Beam Studio หรือไม่?<br/>หรือคุณสามารถข้ามไปก่อนแล้วเริ่มภายหลังได้โดยการคลิกที่ "ช่วยเหลือ" > "แสดงการสอนเบื้องต้น"',
        next: 'ถัดไป',
        look_for_machine: 'กําลังค้นหาเครื่องสําหรับตัวช่วยสอน...',
        unable_to_find_machine: 'ไม่พบเครื่องสําหรับตัวช่วยสอน คุณต้องการไปที่หน้าตั้งค่าการเชื่อมต่อ ลองใหม่อีกครั้ง หรือข้ามตัวช่วยสอนหรือไม่?',
        skip_tutorial: 'คุณได้ข้ามตัวช่วยสอนแล้ว คุณสามารถเริ่มตัวช่วยสอนได้ตลอดเวลาโดยคลิก "ช่วยเหลือ" > "แสดงตัวช่วยสอนเริ่มต้น"',
        set_connection: 'ตั้งค่าการเชื่อมต่อ',
        retry: 'ลองใหม่อีกครั้ง',
        newUser: {
            draw_a_circle: 'วาดวงกลม',
            drag_to_draw: 'ลากเพื่อวาด',
            infill: 'เปิดใช้งานช่องว่างภายใน',
            switch_to_object_panel: 'สลับไปยังหน้าต่างการจัดการอ็อบเจกต์',
            switch_to_layer_panel: 'สลับไปที่แผงชั้น',
            set_preset_wood_engraving: 'ตั้งค่าที่กําหนดไว้ล่วงหน้า: ไม้ - การกัดลาย',
            set_preset_wood_cut: 'ตั้งค่าที่กําหนดไว้ล่วงหน้า: ไม้ - ตัด',
            add_new_layer: 'เพิ่มชั้นใหม่',
            draw_a_rect: 'วาดสี่เหลี่ยมผืนผ้า',
            switch_to_preview_mode: 'สลับไปที่โหมดดูตัวอย่าง',
            preview_the_platform: 'ดูตัวอย่างแพลตฟอร์ม',
            end_preview_mode: 'สิ้นสุดโหมดดูตัวอย่าง',
            put_wood: '1. วางตัวอย่างไม้',
            adjust_focus: '2. ปรับโฟกัส',
            close_cover: '3. ปิดฝาครอบ',
            send_the_file: 'ส่งไฟล์',
            end_alert: 'คุณแน่ใจหรือไม่ว่าต้องการสิ้นสุดการสอน?',
            please_select_wood_engraving: 'โปรดเลือกค่าที่ตั้งล่วงหน้า "ไม้ - แกะสลัก"',
            please_select_wood_cutting: 'โปรดเลือกค่าที่ตั้งล่วงหน้า "ไม้ - ตัด"',
        },
        newInterface: {
            camera_preview: 'ดูตัวอย่างจากกล้อง',
            select_image_text: 'เลือก / รูปภาพ / ข้อความ',
            basic_shapes: 'รูปทรงพื้นฐาน',
            pen_tool: 'เครื่องมือปากกา',
            add_new_layer: 'เพิ่มเลเยอร์ใหม่',
            rename_by_double_click: 'เปลี่ยนชื่อโดยดับเบิ้ลคลิก',
            drag_to_sort: 'ลากเพื่อเรียงลําดับ',
            layer_controls: 'คลิกขวาเพื่อเลือกตัวควบคุมเลเยอร์:\nทําสําเนา / ผสาน / ล็อก / ลบเลเยอร์',
            switch_between_layer_panel_and_object_panel: 'สลับระหว่างแผงเลเยอร์และแผงวัตถุ',
            align_controls: 'ตัวควบคุมการจัดเรียง',
            group_controls: 'ตัวควบคุมกลุ่ม',
            shape_operation: 'การดําเนินการรูปทรง',
            flip: 'พลิก',
            object_actions: 'การกระทําวัตถุ',
            end_alert: 'คุณแน่ใจหรือไม่ว่าต้องการสิ้นสุดการแนะนํา UI ใหม่?',
            select_machine: 'เลือกเครื่อง',
            start_work: 'เริ่มทํางาน',
        },
        gesture: {
            pan: 'เลื่อนดูผังงานด้วยสองนิ้ว',
            zoom: 'หุบ/ขยายนิ้วเพื่อซูมเข้า/ออกผังงาน',
            click: 'แตะเพื่อเลือกวัตถุ',
            drag: 'ลากเพื่อเลือกวัตถุหลายรายการ',
            hold: 'กดค้างเพื่อเปิดเมนูบริบท',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'นั่นคือทั้งหมดสําหรับบทสอน ตอนนี้เป็นเวลาที่จะสร้าง!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'เลเซอร์',
        laser_10w_diode: 'เลเซอร์ไดโอด 10W',
        laser_20w_diode: 'เลเซอร์ไดโอด 20W',
        laser_2w_infrared: 'เลเซอร์อินฟราเรด 2W',
        printing: 'การพิมพ์',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'คุณต้องการแปลงโมดูลการพิมพ์เป็นโมดูลเลเซอร์หรือไม่?',
            convertFromPrintingModuleMsg: 'โปรดทราบว่าหากคุณดําเนินการครบถ้วนแล้ว การตั้งค่าสีของชั้นการพิมพ์จะถูกลบออกและตั้งค่าตามชั้นปัจจุบัน',
            convertFromLaserModuleTitle: 'คุณต้องการแปลงโมดูลเลเซอร์เป็นโมดูลการพิมพ์หรือไม่?',
            convertFromLaserModuleMsg: 'โปรดทราบว่าหากคุณดําเนินการครบถ้วนแล้ว การตั้งค่าของชั้นเลเซอร์จะถูกลบออกและตั้งค่าตามชั้นปัจจุบัน',
            importedDocumentContainsPrinting: 'เอกสารมีชั้นการพิมพ์ คุณต้องการเปลี่ยนพื้นที่ทํางานเป็น Ador หรือไม่',
            printingLayersCoverted: 'ชั้นพิมพ์ได้ถูกแปลงเป็นชั้นเลเซอร์แล้ว',
            performPrintingCaliTitle: 'ปรับเทียบโมดูลการพิมพ์',
            performPrintingCaliMsg: 'คลิก "ยืนยัน" เพื่อทําการปรับเทียบ หรือเข้าถึงการปรับเทียบผ่านเมนูด้านบน <br />(เครื่อง > [ชื่อเครื่องของคุณ] > ปรับเทียบโมดูลการพิมพ์)',
            performIRCaliTitle: 'ปรับเทียบโมดูลอินฟราเรด',
            performIRCaliMsg: 'คลิก "ยืนยัน" เพื่อทําการปรับเทียบ หรือเข้าถึงการปรับเทียบผ่านเมนูด้านบน <br /> (เครื่อง > [ชื่อเครื่องของคุณ] > ปรับเทียบโมดูลอินฟราเรด)',
        },
        non_working_area: 'พื้นที่ที่ไม่ทำงาน',
    },
    qr_code_generator: {
        title: 'ตัวสร้างคิวอาร์โค้ด',
        placeholder: 'กรอกลิงก์หรือข้อความ',
        preview: 'ตัวอย่าง',
        error_tolerance: 'ระดับความคลาดเคลื่อนที่ยอมรับได้',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'สีพื้นหลังกลับกัน',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'กล่องพื้นฐาน',
        coming_soon: 'เร็วๆ นี้',
        workarea: 'พื้นที่ทํางาน',
        max_dimension_tooltip: 'ความกว้าง/ความสูง/ความลึกสูงสุดคือ %s',
        volume: 'ปริมาตร',
        outer: 'ภายนอก',
        inner: 'ภายใน',
        width: 'ความกว้าง',
        height: 'ความสูง',
        depth: 'ความลึก',
        cover: 'ฝาปิด',
        thickness: 'ความหนา',
        add_option: 'เพิ่มตัวเลือก',
        joints: 'รอยต่อ',
        finger: 'นิ้ว',
        finger_warning: 'ความยาวด้านในของกล่องต้องมีอย่างน้อย 6 มม. (0.24 นิ้ว) เพื่อให้สามารถใช้งานการเชื่อมต่อนิ้วมือได้',
        tSlot: 'ร่อง T ',
        tSlot_warning: 'ความยาวด้านข้างของกล่องต้องมีอย่างน้อย 30 มม. (1.18 นิ้ว) เพื่อให้สามารถใช้งานการเชื่อมต่อร่อง T ได้',
        edge: 'ขอบ',
        tCount: 'จํานวน T ',
        tCount_tooltip: 'จํานวนช่อง T นั้นจะนับจากด้านสั้น ส่วนปริมาณบนด้านยาวจะคํานวณจากความยาว',
        tDiameter: 'เส้นผ่านศูนย์กลาง T',
        tLength: 'ความยาว T',
        continue_import: 'ดําเนินการนําเข้าต่อ',
        customize: 'ปรับแต่ง',
        merge: 'รวม',
        text_label: 'ป้ายชื่อ',
        beam_radius: 'ช่องว่างสําหรับตัด',
        beam_radius_warning: 'เมื่อขอบหรือรอยต่อของกล่องสั้น ให้ลดรัศมีของลําแสงเลเซอร์เพื่อให้แน่ใจว่ากล่องประกอบได้',
        import: 'นําเข้า',
        cancel: 'ยกเลิก',
        reset: 'รีเซ็ต',
        zoom: 'ซูม',
        control_tooltip: 'เมาส์ซ้ายเพื่อหมุน\nเลื่อนเพื่อซูม\nเมาส์ขวาเพื่อเลื่อน',
        control_tooltip_touch: 'ลากเพื่อหมุน\nหุบเพื่อซูม\nสองนิ้วเพื่อเลื่อน',
    },
    my_cloud: {
        title: 'คลาวด์ของฉัน',
        loading_file: 'กําลังโหลด...',
        no_file_title: 'บันทึกไฟล์ไปยัง คลาวด์ของฉัน เพื่อเริ่มต้น',
        no_file_subtitle: 'ไปที่เมนู > "ไฟล์" > "บันทึกไปยังคลาวด์"',
        file_limit: 'ไฟล์ฟรี',
        upgrade: 'อัพเกรด',
        sort: {
            most_recent: 'ล่าสุด',
            oldest: 'เก่าสุด',
            a_to_z: 'ชื่อ: ก-ฮ',
            z_to_a: 'ชื่อ: ฮ-ก',
        },
        action: {
            open: 'เปิด',
            rename: 'เปลี่ยนชื่อ',
            duplicate: 'ทําสําเนา',
            download: 'ดาวน์โหลด',
            delete: 'ลบ',
            confirmFileDelete: 'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้? การกระทํานี้ไม่สามารถยกเลิกได้',
        },
        save_file: {
            choose_action: 'บันทึกไฟล์:',
            save: 'บันทึก',
            save_new: 'บันทึกเป็นไฟล์ใหม่',
            input_file_name: 'กรอกชื่อไฟล์:',
            invalid_char: 'อักขระไม่ถูกต้อง:',
            storage_limit_exceeded: 'พื้นที่จัดเก็บข้อมูลบนคลาวด์ของคุณเต็มแล้ว โปรดลบไฟล์ที่ไม่จําเป็นออกก่อนบันทึกไฟล์ใหม่',
        },
    },
    camera_data_backup: {
        title: 'การสำรองข้อมูลกล้อง',
        no_picture_found: 'ไม่พบรูปในเครื่อง',
        folder_not_exists: 'โฟลเดอร์ที่เลือกไม่มีอยู่',
        incorrect_folder: 'การอัปโหลดข้อมูลการจัดเก็บล้มเหลว โปรดตรวจสอบว่าโฟลเดอร์ที่คุณเลือกถูกต้องหรือไม่',
        downloading_data: 'กำลังดาวน์โหลดข้อมูล...',
        estimated_time_left: 'เวลาที่เหลือโดยประมาณ:',
        uploading_data: 'กำลังอัปโหลดข้อมูล...',
        download_success: 'ดาวน์โหลดข้อมูลกล้องสำเร็จ',
        upload_success: 'อัปโหลดข้อมูลกล้องสำเร็จ',
    },
    insecure_websocket: {
        extension_detected: 'ตรวจพบส่วนขยาย Beam Studio Connect',
        extension_detected_description: "เราตรวจพบว่าคุณได้ติดตั้งส่วนขยาย Beam Studio Connect กรุณาคลิก 'ยืนยัน' เพื่อเปลี่ยนเส้นทางไปที่ HTTPS หรือคลิก 'ยกเลิก' เพื่อทำต่อตาม HTTP",
        extension_not_deteced: 'ไม่สามารถตรวจพบส่วนขยาย Beam Studio Connect',
        extension_not_deteced_description: "หากต้องการใช้ HTTPS โปรดคลิก 'ยืนยัน' เพื่อติดตั้งส่วนขยาย Beam Studio Connect หลังจากติดตั้งส่วนขยายแล้ว กรุณารีเฟรชหน้าเพื่อเปิดใช้งาน มิฉะนั้น โปรดคลิกลิงก์ด้านล่างเพื่อดูวิธีการใช้ HTTP บน Chrome",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">ลิงก์</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'วัดพื้นที่โฟกัสอัตโนมัติ',
        amount: 'จำนวน',
        gap: 'ช่องว่าง',
        rows: 'แถว',
        coloumns: 'คอลัมน์',
        row_gap: 'ช่องว่างระหว่างแถว',
        column_gap: 'ช่องว่างระหว่างคอลัมน์',
        set_object_height: 'ตั้งค่าความสูงของวัตถุ',
        set_object_height_desc: 'วัดความหนาสูงสุดของวัตถุ',
        reselect_area: 'เลือกพื้นที่ใหม่',
        start_autofocus: 'เริ่มโฟกัสอัตโนมัติ',
        starting_measurement: 'กำลังเริ่มวัด...',
        preview_3d_curve: 'แสดงตัวอย่างเส้นโค้ง 3 มิติ',
        apply_arkwork: 'ใช้ภาพวาดกับเส้นโค้ง 3 มิติ',
        apply_camera: 'ใช้ภาพกล้องกับเส้นโค้ง 3 มิติ',
        click_to_select_point: 'คลิกเพื่อเลือกหรือล้างการเลือกจุดที่จะวัดอีกครั้ง',
        remeasure: 'วัดใหม่',
        remeasuring_points: 'กำลังวัดจุดใหม่...',
        take_reference: 'รับค่าการอ้างอิง',
        take_reference_desc: 'กรุณาย้ายหัวเลเซอร์ไปยังจุดที่สูงที่สุดของวัตถุ ลดหัววัดโฟกัสลง และคลิก "ยืนยัน" เพื่อปรับโฟกัส',
        sure_to_delete: 'คุณต้องการลบข้อมูลโฟกัสของเส้นโค้ง 3D หรือไม่?',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    },
    pass_through: {
        title: 'โหมดผ่าน',
        help_text: 'วิธีตั้งค่า โหมดผ่าน สำหรับ Ador',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'ความยาวของวัตถุ',
        workarea_height: 'พื้นที่ทำงาน (ความสูง):',
        height_desc: 'ตั้งค่าความสูงของแต่ละส่วนของพื้นที่ทำงาน',
        ref_layer: 'ชั้นอ้างอิง',
        ref_layer_desc: 'โปรดทราบว่าการดำเนินการของชั้นอ้างอิงถูกตั้งค่าเป็น 0 โดยค่าเริ่มต้น จะไม่ถูกดำเนินการแต่ใช้สำหรับอ้างอิงการจัดแนวเท่านั้น',
        ref_layer_name: 'อ้างอิง',
        guide_mark: 'จุดหมาย',
        guide_mark_length: 'เส้นผ่านศูนย์กลาง:',
        guide_mark_x: 'พิกัด X:',
        guide_mark_desc: 'จุดหมายจะถูกแกะสลักเป็นจุดอ้างอิงเพื่อจัดแนวงานศิลปะ',
        export: 'ส่งออกไปยังพื้นที่ทำงาน',
        exporting: 'กำลังส่งออก...',
    },
    auto_fit: {
        title: 'ปรับให้พอดีอัตโนมัติ',
        step1: '1. วางวัตถุในตำแหน่งที่ต้องการบนชิ้นวัสดุชิ้นหนึ่ง',
        step2: '2. กด "ยืนยัน" เพื่อคัดลอกวัตถุไปยังวัสดุที่คล้ายกันทั้งหมดในตัวอย่างกล้อง',
        preview_first: 'โปรดทำตัวอย่างกล้องก่อน',
        failed_to_auto_fit: 'การปรับพอดีอัตโนมัติล้มเหลว โปรดตรวจสอบ:',
        error_tip1: '1. งานศิลปะถูกวางไว้อย่างถูกต้องบนวัสดุหรือไม่?',
        error_tip2: '2. ขอบเขตของวัสดุชัดเจนพอสำหรับการจดจำหรือไม่?',
        learn_more: 'เรียนรู้วิธีการทำงานของการปรับพอดีอัตโนมัติ',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'ประเภท',
        object_diameter: 'เส้นผ่านศูนย์กลางของวัตถุ',
        circumference: 'เส้นรอบวง',
    },
    framing: {
        low_laser: 'เลเซอร์กำลังต่ำ',
        low_laser_desc: 'ตั้งค่าเลเซอร์กำลังต่ำสำหรับงานกำหนดกรอบ',
        framing: 'การกำหนดกรอบ',
        hull: 'โครงร่าง',
        area_check: 'ตรวจสอบพื้นที่',
        framing_desc: 'แสดงตัวอย่างกล่องขอบเขตของวัตถุ',
        hull_desc: 'แสดงตัวอย่างรูปทรงที่ใกล้เคียงกับการออกแบบ เหมือนยางยืดพันรอบวัตถุ',
        areacheck_desc: 'ตรวจสอบความปลอดภัยของพื้นที่ทำงานโดยการแสดงกล่องขอบเขตของวัตถุและโซนเร่งความเร็วของหัวเลเซอร์',
        calculating_task: 'กำลังคำนวณงาน...',
    },
    material_test_generator: {
        title: 'ตัวสร้างการทดสอบวัสดุ',
        table_settings: 'การตั้งค่าตาราง',
        block_settings: 'การตั้งค่าบล็อก',
        text_settings: 'การตั้งค่าข้อความ',
        preview: 'ดูตัวอย่าง',
        export: 'ส่งออก',
        cut: 'ตัด',
        engrave: 'แกะสลัก',
        columns: 'คอลัมน์',
        rows: 'แถว',
        parameter: 'พารามิเตอร์',
        min: 'น้อยสุด',
        max: 'มากสุด',
        count: 'จำนวน',
        size: 'ขนาด (สxก)',
        spacing: 'ระยะห่าง',
    },
    web_cam: {
        no_permission: 'Beam Studio ไม่มีสิทธิ์เข้าถึงกล้อง โปรดตรวจสอบให้แน่ใจว่า Beam Studio ได้รับสิทธิ์ในเบราว์เซอร์หรือในการตั้งค่าระบบ',
        no_device: 'ไม่สามารถตรวจพบอุปกรณ์กล้องได้ โปรดเชื่อมต่อกล้องใหม่และลองอีกครั้ง',
    },
    promark_settings: {
        title: 'การตั้งค่า Promark',
        field: 'ฟิลด์',
        red_dot: 'จุดแดง',
        galvo_configuration: 'การตั้งค่า Galvo',
        switchXY: 'สลับ X/Y',
        workarea_hint: 'คุณสามารถเปลี่ยนพื้นที่ทำงานได้ใน "การตั้งค่าเอกสาร"',
        offsetX: 'การชดเชย X',
        offsetY: 'การชดเชย Y',
        angle: 'มุม',
        scaleX: 'มาตราส่วน X',
        scaleY: 'มาตราส่วน Y',
        scale: 'มาตราส่วน',
        bulge: 'การโป่งพอง',
        skew: 'การบิดเบือน',
        trapezoid: 'รูปสี่เหลี่ยมคางหมู',
        mark_parameters: 'พารามิเตอร์การมาร์ก',
        preview: 'ดูตัวอย่าง',
        mark: 'ทำเครื่องหมาย',
        z_axis_adjustment: {
            title: 'การปรับแกน Z',
            section1: 'ปรับความสูงของแกน Z เพื่อปรับโฟกัสให้ละเอียดขึ้น',
            tooltip1: 'ลองทำเครื่องหมายสี่เหลี่ยมขนาด 1x1 ซม. เพื่อยืนยันว่าระยะโฟกัสปัจจุบันเหมาะสมหรือไม่',
        },
    },
    code_generator: {
        qr_code: 'คิวอาร์โค้ด',
        barcode: 'บาร์โค้ด',
    },
    barcode_generator: {
        bar_width: 'ความกว้างของแท่ง',
        bar_height: 'ความสูงของแท่ง',
        text_margin: 'ระยะขอบข้อความ',
        invert_color: 'กลับสี',
        font: 'ฟอนต์',
        font_size: 'ขนาดฟอนต์',
        hide_text: 'ซ่อนข้อความ',
        barcode: {
            invalid_value: 'ค่านี้ไม่ถูกต้องสำหรับรูปแบบที่เลือก',
        },
    },
    social_media: {
        instagram: 'รับแรงบันดาลใจ ข้อเสนอ และของแจกฟรีล่าสุด!',
        facebook: 'พูดคุยกับ FLUXers ถามคำถาม และเรียนรู้เคล็ดลับ!',
        youtube: 'ดูบทเรียน Beam Studio และไอเดียงานฝีมือ',
    },
};
export default lang;
