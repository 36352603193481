const lang = {
    global: {
        cancel: 'Ακύρωση',
        back: 'Πίσω',
        save: 'Αποθήκευση',
        ok: 'Εντάξει',
        stop: 'Σταμάτημα',
    },
    general: {
        processing: 'Επεξεργασία...',
        choose_folder: 'Επιλέξτε φάκελο',
    },
    buttons: {
        next: 'Επόμενο',
        back: 'Πίσω',
        done: 'Τέλος',
        back_to_beam_studio: 'Επιστροφή στο Beam Studio',
    },
    topbar: {
        untitled: 'Χωρίς τίτλο',
        titles: {
            settings: 'Προτιμήσεις',
        },
        export: 'Εξαγωγή',
        preview: 'ΠΡΟΕΠΙΣΚΟΠΗΣΗ',
        preview_title: 'Προεπισκόπηση',
        preview_press_esc_to_stop: 'Πατήστε ESC για να σταματήσετε την προεπισκόπηση της κάμερας.',
        curve_engrave: '3D Καμπύλη',
        task_preview: 'Προεπισκόπηση διαδρομής',
        frame_task: 'Εκτέλεση πλαισίου',
        borderless: '(ΑΝΟΙΧΤΟ ΚΑΤΩ)',
        tag_names: {
            rect: 'Ορθογώνιο',
            ellipse: 'Ελλειψοειδές',
            path: 'Διαδρομή',
            polygon: 'Πολύγωνο',
            image: 'Εικόνα',
            text: 'Κείμενο',
            text_path: 'Κείμενο σε Διαδρομή',
            pass_through_object: 'Διέλευση Αντικειμένου',
            line: 'Γραμμή',
            g: 'Ομάδα',
            multi_select: 'Πολλαπλά Αντικείμενα',
            use: 'Εισαγόμενο Αντικείμενο',
            svg: 'SVG Αντικείμενο',
            dxf: 'DXF Αντικείμενο',
            no_selection: 'Καμία επιλογή',
        },
        alerts: {
            start_preview_timeout: '#803 Χρονικό όριο κατά την εκκίνηση της προεπισκόπησης. Κάντε επανεκκίνηση του μηχανήματος ή του Beam Studio. Αν το σφάλμα επιμένει, ακολουθήστε <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">αυτόν τον οδηγό</a>.',
            fail_to_start_preview: '#803 Αποτυχία εκκίνησης προεπισκόπησης. Κάντε επανεκκίνηση του μηχανήματος ή του Beam Studio. Αν το σφάλμα επιμένει, ακολουθήστε <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">αυτόν τον οδηγό</a>.',
            fail_to_connect_with_camera: '#803 Αποτυχία σύνδεσης με την κάμερα. Κάντε επανεκκίνηση του μηχανήματος ή του Beam Studio. Αν το σφάλμα επιμένει, ακολουθήστε <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">αυτόν τον οδηγό</a>.',
            add_content_first: 'Παρακαλώ προσθέστε αντικείμενα πρώτα',
            headtype_mismatch: 'Εντοπίστηκε λανθασμένος τύπος κεφαλής. ',
            headtype_none: 'Δεν εντοπίστηκε κεφαλή. ',
            headtype_unknown: 'Εντοπίστηκε άγνωστος τύπος κεφαλής. ',
            install_correct_headtype: 'Παρακαλώ εγκαταστήστε σωστά τις διοδικές λέιζερ κεφαλές 10W/20W για να ενεργοποιήσετε τη χαμηλής ισχύος λέιζερ λειτουργία.',
            door_opened: 'Παρακαλώ κλείστε το κάλυμμα της πόρτας για να ενεργοποιήσετε τη χαμηλής ισχύος λέιζερ λειτουργία.',
            fail_to_get_door_status: 'Παρακαλώ βεβαιωθείτε ότι το κάλυμμα της πόρτας είναι κλειστό για να ενεργοποιήσετε τη χαμηλής ισχύος λέιζερ λειτουργία.',
            QcleanScene: 'Θέλετε να διαγράψετε το σχέδιο;<br/>Αυτό θα διαγράψει επίσης το ιστορικό αναίρεσής σας!',
            power_too_high: 'ΥΠΕΡΒΟΛΙΚΗ ΙΣΧΥΣ',
            power_too_high_msg: 'Η χρήση χαμηλότερης ισχύος λέιζερ (κάτω από 70%) θα επεκτείνει τη διάρκεια ζωής του σωλήνα λέιζερ.\nΕισάγετε "ΣΗΜΕΙΩΘΗΚΕ" για να προχωρήσετε.',
            power_too_high_confirm: 'ΣΗΜΕΙΩΘΗΚΕ',
            pwm_unavailable: 'Η Λειτουργία Βάθους απαιτεί έκδοση υλικολογισμικού 4.3.4 / 5.3.4 ή νεότερη. Θέλετε να ενημερώσετε το υλικολογισμικό τώρα;',
            job_origin_unavailable: 'Η ρύθμιση της προέλευσης της εργασίας απαιτεί έκδοση υλικολογισμικού 4.3.5 / 5.3.5 ή νεότερη. Θέλετε να ενημερώσετε το υλικολογισμικό τώρα;',
            job_origin_warning: 'Χρησιμοποιείτε την “τρέχουσα θέση” ως σημείο εκκίνησης. Βεβαιωθείτε ότι η κεφαλή λέιζερ έχει μετακινηθεί στη σωστή θέση για να αποφύγετε συγκρούσεις.',
        },
        hint: {
            polygon: 'Πατήστε το πλήκτρο + / - για να αυξήσετε / μειώσετε τις πλευρές.',
        },
        menu: {
            preferences: 'Προτιμήσεις',
            hide: 'Απόκρυψη Beam Studio',
            hideothers: 'Απόκρυψη άλλων',
            service: 'Υπηρεσίες',
            quit: 'Έξοδος',
            window: 'Παράθυρο',
            minimize: 'Ελαχιστοποίηση',
            close: 'Κλείσιμο παραθύρου',
            file: 'Αρχείο',
            edit: 'Επεξεργασία',
            help: 'Βοήθεια',
            open: 'Άνοιγμα',
            samples: 'Παραδείγματα',
            example_files: 'Αρχεία Παραδείγματος',
            material_test: 'Δοκιμή Υλικού',
            calibration: 'Βαθμονομήσεις',
            import_hello_beamo: 'Παράδειγμα του beamo',
            import_hello_beambox: 'Παράδειγμα Beambox',
            import_beambox_2_example: 'Παράδειγμα του Beambox II',
            import_material_testing_old: 'Δοκιμή χάραξης υλικού - Κλασική',
            import_material_testing_simple_cut: 'Δοκιμή κοπής υλικού - Απλή',
            import_material_testing_cut: 'Δοκιμή κοπής υλικού',
            import_material_testing_engrave: 'Δοκιμή χάραξης υλικού',
            import_material_testing_line: 'Δοκιμή γραμμής υλικού',
            import_material_printing_test: 'Δοκιμή εκτύπωσης υλικού',
            import_ador_laser_example: 'Παράδειγμα Laser Ador',
            import_ador_printing_example_single: 'Παράδειγμα εκτύπωσης Ador - Ένα χρώμα',
            import_ador_printing_example_full: 'Παράδειγμα εκτύπωσης Ador - Πλήρες χρώμα',
            import_acrylic_focus_probe: 'Δοκιμή εστίασης ακρυλικού - 3mm',
            import_beambox_2_focus_probe: 'Beambox II Αισθητήρας Εστίασης - 3mm',
            import_promark_example: 'Παράδειγμα Promark',
            import_hexa_example: 'Παράδειγμα HEXA',
            export_to: 'Εξαγωγή σε...',
            export_flux_task: 'Εξαγωγή εργασίας FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Αποθήκευση',
            save_as: 'Αποθήκευση ως...',
            save_to_cloud: 'Αποθήκευση στο Cloud',
            about_beam_studio: 'Σχετικά με το Beam Studio',
            undo: 'Αναίρεση',
            redo: 'Επανάληψη',
            cut: 'Αποκοπή',
            copy: 'Αντιγραφή',
            paste: 'Επικόλληση',
            paste_in_place: 'Επικόλληση στη θέση',
            group: 'Ομαδοποίηση',
            ungroup: 'Κατάργηση ομαδοποίησης',
            delete: 'Διαγραφή',
            duplicate: 'Αντιγραφή',
            offset: 'Μετατόπιση',
            scale: 'Κλίμακα',
            rotate: 'Περιστροφή',
            reset: 'Επαναφορά',
            align_center: 'Στοίχιση στο κέντρο',
            photo_edit: 'Επεξεργασία εικόνας',
            svg_edit: 'SVG',
            path: 'Διαδρομή',
            decompose_path: 'Αποσυνθέστε',
            object: 'Αντικείμενο',
            layer_setting: 'Στρώμα',
            layer_color_config: 'Ρυθμίσεις χρωμάτων',
            image_sharpen: 'Εστίαση',
            image_crop: 'Περικοπή',
            image_invert: 'Αντιστροφή',
            image_stamp: 'Υδατογραφήματα',
            image_vectorize: 'Ιχνηλάτηση',
            image_curve: 'Καμπύλη',
            align_to_edges: 'Ευθυγράμμιση με κορυφές',
            document_setting: 'Ρυθμίσεις εγγράφου',
            document_setting_short: 'Έγγραφο',
            rotary_setup: 'Ρυθμίσεις Περιστροφής',
            clear_scene: 'Νέα αρχεία',
            machines: 'Μηχανές',
            add_new_machine: 'Ρύθμιση μηχανής',
            help_center: 'Κέντρο βοήθειας',
            show_gesture_tutorial: 'Εισαγωγή χειρονομιών',
            show_start_tutorial: 'Εμφάνιση εισαγωγικού μαθήματος',
            show_ui_intro: 'Εμφάνιση εισαγωγής διεπαφής',
            questionnaire: 'Ερωτηματολόγιο ανατροφοδότησης',
            change_logs: 'Καταγραφή αλλαγών',
            contact: 'Επικοινωνήστε μαζί μας',
            tutorial: 'Ξεκινήστε το εκπαιδευτικό πρόγραμμα εκτύπωσης Delta',
            design_market: 'Αγορά σχεδίων',
            forum: 'Κοινότητα φόρουμ',
            software_update: 'Ενημέρωση λογισμικού',
            bug_report: 'Αναφορά σφαλμάτων',
            dashboard: 'Πίνακας ελέγχου',
            machine_info: 'Πληροφορίες μηχανήματος',
            network_testing: 'Δοκιμή ρυθμίσεων δικτύου',
            commands: 'Εντολές',
            update_firmware: 'Ενημέρωση υλικολογισμικού',
            using_beam_studio_api: 'Χρήση Beam Studio API',
            set_as_default: 'Ορισμός ως προεπιλογή',
            calibrate_beambox_camera: 'Βαθμονόμηση κάμερας',
            calibrate_printer_module: 'Βαθμονόμηση εκτυπωτικής μονάδας',
            calibrate_ir_module: 'Βαθμονόμηση υπέρυθρης μονάδας',
            calibrate_beambox_camera_borderless: 'Βαθμονόμηση κάμερας (Ανοιχτό κάτω μέρος)',
            calibrate_diode_module: 'Βαθμονόμηση μονάδας λέιζερ διόδου',
            calibrate_camera_advanced: 'Ρύθμιση κάμερας (Προχωρημένο)',
            manage_account: 'Διαχείριση λογαριασμού',
            sign_in: 'Σύνδεση',
            sign_out: 'Αποσύνδεση',
            account: 'Λογαριασμός',
            my_account: 'Ο λογαριασμός μου',
            download_log: 'Κατεβάστε αρχεία καταγραφής',
            download_log_canceled: 'Η λήψη αρχείου καταγραφής ακυρώθηκε',
            download_log_error: 'Παρουσιάστηκε άγνωστο σφάλμα, δοκιμάστε ξανά αργότερα',
            keyboard_shortcuts: 'Συντομεύσεις πληκτρολογίου',
            log: {
                network: 'Δίκτυο',
                hardware: 'Υλικό',
                discover: 'Ανακάλυψη',
                usb: 'USB',
                usblist: 'Λίστα USB',
                camera: 'Κάμερα',
                cloud: 'Νέφος',
                player: 'Πρόγραμμα αναπαραγωγής',
                robot: 'Ρομπότ',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Προβολή',
            zoom_in: 'Μεγέθυνση',
            zoom_out: 'Σμίκρυνση',
            fit_to_window: 'Προσαρμογή στο μέγεθος παραθύρου',
            zoom_with_window: 'Αυτόματη προσαρμογή στο μέγεθος παραθύρου',
            borderless_mode: 'Λειτουργία χωρίς πλαίσιο',
            show_grids: 'Εμφάνιση πλεγμάτων',
            show_rulers: 'Εμφάνιση οδηγών',
            show_layer_color: 'Χρήση χρώματος στρώματος',
            anti_aliasing: 'Αντι-aliasing',
            disassemble_use: 'Αποσυναρμολόγηση',
            about: 'Σχετικά με το Beam Studio',
            switch_to_beta: 'Μετάβαση σε Beta Έκδοση',
            switch_to_latest: 'Μετάβαση σε Σταθερή Έκδοση',
            reload_app: 'Επαναφόρτωση Εφαρμογής',
            recent: 'Άνοιγμα Πρόσφατων',
            update: 'Έλεγχος Ενημέρωσης',
            dev_tool: 'Εργαλείο Εντοπισμού Σφαλμάτων',
            camera_calibration_data: 'Δεδομένα Βαθμονόμησης Κάμερας',
            upload_data: 'Ανέβασμα Δεδομένων',
            download_data: 'Κατέβασμα Δεδομένων',
            tools: {
                title: 'Εργαλεία',
                material_test_generator: 'Γεννήτρια Δοκιμής Υλικού',
                code_generator: 'Γεννήτρια κώδικα',
                box_generator: 'Γεννήτρια Κουτιού',
            },
            follow_us: 'Ακολουθήστε μας',
        },
        select_machine: 'Επιλέξτε μηχανή',
    },
    support: {
        no_webgl: 'Το WebGL δεν υποστηρίζεται. Παρακαλώ χρησιμοποιήστε άλλη συσκευή.',
        no_vcredist: 'Παρακαλώ εγκαταστήστε το Visual C++ Redistributable 2015<br/>Μπορείτε να το κατεβάσετε από flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[ΑΕ] Παρουσιάστηκε άγνωστο σφάλμα. Παρακαλώ επανεκκινήστε το Beam Studio και τη μηχανή.',
        OPERATION_ERROR: '[ΣΕ] Παρουσιάστηκε σύγκρουση κατάστασης, παρακαλώ επαναλάβετε την ενέργεια.',
        SUBSYSTEM_ERROR: '[ΣΥΕ] Σφάλμα κατά την εκτέλεση του έργου από το firmware της μηχανής. Παρακαλώ επανεκκινήστε τη μηχανή.',
        UNKNOWN_COMMAND: 'Παρακαλώ ενημερώστε το υλικολογισμικό της συσκευής',
    },
    device_selection: {
        no_beambox: '#801 Δεν μπορούμε να βρούμε το μηχάνημά σας στο δίκτυο.\nΠαρακαλώ ακολουθήστε τον <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">οδηγό</a> για αντιμετώπιση προβλημάτων σύνδεσης.',
        no_device_web: "#801 Ελέγξτε την κατάσταση της μηχανής σας ή κάντε κλικ στο 'Ρύθμιση μηχανής' παρακάτω για να ρυθμίσετε τη μηχανή.",
        select_usb_device: 'Επιλέξτε συσκευή USB',
    },
    update: {
        update: 'Ενημέρωση',
        release_note: 'Σημείωση έκδοσης:',
        firmware: {
            caption: 'Μια ενημέρωση υλικολογισμικού για το μηχάνημα είναι διαθέσιμη',
            message_pattern_1: 'Το "%s" είναι τώρα έτοιμο για ενημέρωση υλικολογισμικού.',
            message_pattern_2: '%s Υλικολογισμικό v%s είναι τώρα διαθέσιμο - Έχετε το v%s.',
            latest_firmware: {
                caption: 'Ενημέρωση υλικολογισμικού μηχανήματος',
                message: 'Έχετε την τελευταία έκδοση υλικολογισμικού μηχανήματος',
                still_update: 'ΕΝΗΜΕΡΩΣΗ',
                cant_get_latest: 'Αδυναμία λήψης πληροφοριών για την τελευταία έκδοση υλικολογισμικού.',
            },
            confirm: 'ΜΕΤΑΦΟΡΤΩΣΗ',
            upload_file: 'Φόρτωση υλικολογισμικού (*.bin / *.fxfw)',
            update_success: 'Επιτυχής αναβάθμιση υλικολογισμικού',
            update_fail: '#822 Αποτυχία αναβάθμισης',
            too_old_for_web: 'Η τρέχουσα έκδοση υλικολογισμικού της μηχανής σας είναι η v%s.\nΑν θέλετε να χρησιμοποιήσετε την online έκδοση του Beam Studio, αναβαθμίστε το υλικολογισμικό της μηχανής στην τελευταία έκδοση.',
            force_update_message: '#814 Παρακαλώ αναβαθμίστε τη μηχανή σας στην τελευταία έκδοση υλικολογισμικού.',
            firmware_too_old_update_by_sdcard: 'Η έκδοση υλικολογισμικού είναι πολύ παλιά. Παρακαλώ αναβαθμίστε το υλικολογισμικό χρησιμοποιώντας μια κάρτα SD.',
        },
        software: {
            checking: 'Έλεγχος για ενημέρωση',
            switch_version: 'Αλλαγή έκδοσης',
            check_update: 'Έλεγχος για ενημέρωση',
            caption: 'Διαθέσιμη ενημέρωση λογισμικού για το Beam Studio',
            downloading: 'Γίνεται λήψη ενημερώσεων στο παρασκήνιο, μπορείτε να κάνετε κλικ στο "ΟΚ" για να συνεχίσετε την εργασία σας.',
            install_or_not: 'είναι έτοιμο για ενημέρωση. Θα θέλατε να κάνετε επανεκκίνηση και να αναβαθμίσετε τώρα;',
            switch_or_not: 'Το λογισμικό είναι έτοιμο για αναβάθμιση. Θέλετε να κάνετε επανεκκίνηση τώρα;',
            available_update: 'Το Beam Studio v%s είναι διαθέσιμο. Έχετε την έκδοση v%s. Θέλετε να κατεβάσετε την ενημέρωση;',
            available_switch: 'Το Beam Studio v%s είναι διαθέσιμο. Έχετε την έκδοση v%s. Θέλετε να αναβαθμίσετε;',
            not_found: 'Χρησιμοποιείτε την τελευταία έκδοση του Beam Studio.',
            no_response: 'Αποτυχία σύνδεσης με τον διακομιστή, ελέγξτε τις ρυθμίσεις δικτύου σας.',
            switch_version_not_found: 'Δεν βρέθηκε διαθέσιμη έκδοση για αναβάθμιση.',
            yes: 'Ναι',
            no: 'Όχι',
            update_for_ador: 'Η τρέχουσα έκδοση λογισμικού %s δεν είναι συμβατή, παρακαλώ κατεβάστε την τελευταία έκδοση του Beam Studio για τον Ador.',
        },
        updating: 'Αναβάθμιση...',
        skip: 'Παράλειψη Αυτής Της Έκδοσης',
        preparing: 'Προετοιμασία...',
        later: 'ΑΡΓΟΤΕΡΑ',
        download: 'ΔΙΑΔΙΚΤΥΑΚΗ ΕΝΗΜΕΡΩΣΗ',
        cannot_reach_internet: '#823 Ο διακομιστής δεν είναι προσβάσιμος<br/>Ελέγξτε τη σύνδεση στο διαδίκτυο',
        install: 'ΕΓΚΑΤΑΣΤΑΣΗ',
        upload: 'ΜΕΤΑΦΟΡΤΩΣΗ',
    },
    topmenu: {
        version: 'Έκδοση',
        credit: 'Το Beam Studio καθίσταται δυνατό από το ανοιχτού κώδικα έργο <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> και άλλο <a target="_blank" href="https://flux3dp.com/credits/">λογισμικό ανοιχτού κώδικα</a>.',
        ok: 'ΕΝΤΑΞΕΙ',
        file: {
            label: 'Αρχείο',
            import: 'Εισαγωγή',
            save_fcode: 'Εξαγωγή Εργασίας FLUX',
            save_scene: 'Αποθήκευση Σκηνής',
            save_svg: 'Εξαγωγή SVG',
            save_png: 'Εξαγωγή PNG',
            save_jpg: 'Εξαγωγή JPG',
            converting: 'Μετατροπή σε εικόνα...',
            all_files: 'Όλα τα αρχεία',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Σκηνές Beam Studio',
            fcode_files: 'Κώδικας FLUX',
            clear_recent: 'Εκκαθάριση πρόσφατων αρχείων',
            path_not_exit: 'Αυτή η διαδρομή δεν υπάρχει πλέον στον δίσκο.',
        },
        device: {
            download_log_canceled: 'Η λήψη αρχείου καταγραφής ακυρώθηκε',
            download_log_error: 'Παρουσιάστηκε άγνωστο σφάλμα, δοκιμάστε ξανά αργότερα',
            log: {
                usblist: 'Λίστα USB',
            },
            network_test: 'Δοκιμή δικτύου',
        },
    },
    initialize: {
        next: 'Επόμενο',
        start: 'Έναρξη',
        skip: 'Παράλειψη',
        cancel: 'Ακύρωση',
        confirm: 'Επιβεβαίωση',
        connect: 'Σύνδεση',
        back: 'Πίσω',
        retry: 'Επανάληψη',
        no_machine: 'Δεν έχω μηχάνημα αυτή τη στιγμή.',
        select_language: 'Επιλέξτε γλώσσα',
        select_machine_type: 'Επιλέξτε τον τύπο του μηχανήματός σας',
        select_beambox: 'Επιλέξτε το Beambox σας',
        select_connection_type: 'Πώς θέλετε να συνδεθείτε;',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Ενσύρματο δίκτυο',
            ether2ether: 'Άμεση σύνδεση',
            usb: 'Σύνδεση USB',
        },
        connect_wifi: {
            title: 'Σύνδεση στο Wi-Fi',
            tutorial1: '1. Μεταβείτε στον Πίνακα αφής > Πατήστε στο "Δίκτυο" > "Σύνδεση στο WiFi".',
            tutorial1_ador: '1. Μεταβείτε στον Πίνακα αφής > Πατήστε στο "ΜΗΧΑΝΗΜΑ" > Πατήστε στο "Δίκτυο" > "Σύνδεση στο WiFi".',
            tutorial2: '2. Επιλέξτε και συνδεθείτε στο προτιμώμενο Wi-Fi δίκτυό σας.',
            what_if_1: 'Τι συμβαίνει αν δεν βλέπω το Wi-Fi μου;',
            what_if_1_content: '1. Η κρυπτογράφηση Wi-Fi πρέπει να είναι WPA2 ή χωρίς κωδικό πρόσβασης.\n2. Η κρυπτογράφηση μπορεί να ρυθμιστεί στο περιβάλλον διαχείρισης δρομολογητή Wi-Fi. Αν ο δρομολογητής δεν υποστηρίζει WPA2 και χρειάζεστε βοήθεια στην επιλογή του σωστού δρομολογητή, επικοινωνήστε με την υποστήριξη.',
            what_if_2: 'Τι συμβαίνει αν δεν βλέπω κανένα Wi-Fi;',
            what_if_2_content: '1. Βεβαιωθείτε ότι το Wi-Fi dongle είναι πλήρως συνδεδεμένο.\n2. Αν δεν υπάρχει διεύθυνση MAC του ασύρματου δικτύου στην οθόνη αφής, επικοινωνήστε με την υποστήριξη.\n3. Το κανάλι Wi-Fi πρέπει να είναι 2.4Ghz (το 5Ghz δεν υποστηρίζεται).',
        },
        connect_wired: {
            title: 'Σύνδεση σε Ενσύρματο Δίκτυο',
            tutorial1: '1. Συνδέστε το μηχάνημα με το δρομολογητή σας.',
            tutorial2: '2. Πατήστε "Δίκτυο" για να λάβετε τη διεύθυνση IP ενσύρματου δικτύου.',
            tutorial2_ador: '2. Πατήστε "ΜΗΧΑΝΗΜΑ"> "Δίκτυο" για να λάβετε τη διεύθυνση IP ενσύρματου δικτύου.',
            what_if_1: 'Τι συμβαίνει αν η διεύθυνση IP είναι κενή;',
            what_if_1_content: '1. Βεβαιωθείτε ότι το καλώδιο Ethernet είναι πλήρως συνδεδεμένο.\n2. Αν δεν υπάρχει διεύθυνση MAC του ενσύρματου δικτύου στην οθόνη αφής, επικοινωνήστε με την υποστήριξη.',
            what_if_2: 'Τι συμβαίνει αν η διεύθυνση IP ξεκινάει με 169;',
            what_if_2_content: '1. Αν η διεύθυνση IP ξεκινάει με 169.254, θα πρέπει να υπάρχει πρόβλημα ρύθμισης DHCP, επικοινωνήστε με τον πάροχο υπηρεσιών διαδικτύου (ISP) για περαιτέρω βοήθεια.\n2. Αν ο υπολογιστής σας συνδέεται στο διαδίκτυο απευθείας χρησιμοποιώντας PPPoE, αλλάξτε ώστε να χρησιμοποιείτε το δρομολογητή για σύνδεση μέσω PPPoE και ενεργοποιήστε τη λειτουργία DHCP στο δρομολογητή.',
        },
        connect_ethernet: {
            title: 'Άμεση σύνδεση',
            tutorial1: '1. Συνδέστε το μηχάνημα με τον υπολογιστή σας με καλώδιο Ethernet.',
            tutorial2_1: '2. Ακολουθήστε ',
            tutorial2_a_text: 'αυτόν τον οδηγό',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' για να κάνετε τον υπολογιστή σας ως δρομολογητή.',
            tutorial3: '3. Κάντε κλικ στο Επόμενο.',
        },
        connect_usb: {
            title: 'Σύνδεση USB',
            title_sub: ' (HEXA & Ador Only)',
            tutorial1: 'Συνδέστε το μηχάνημα με τον υπολογιστή σας με καλώδιο USB.',
            tutorial2: 'Κάντε κλικ στο "Επόμενο".',
            turn_off_machine: 'Απενεργοποιήστε το μηχάνημα.',
            turn_on_machine: 'Ενεργοποιήστε το μηχάνημα.',
            wait_for_turning_on: 'Πατήστε "Επόμενο" μετά την ολοκλήρωση της διαδικασίας εκκίνησης και αφού έχετε μπει στην κύρια οθόνη.',
            connect_camera: 'Συνδέστε την κάμερα της μηχανής στον υπολογιστή σας με καλώδιο USB.',
        },
        connect_machine_ip: {
            check_usb: 'Έλεγχος σύνδεσης USB',
            enter_ip: 'Εισάγετε τη διεύθυνση IP του μηχανήματός σας',
            check_ip: 'Έλεγχος διαθεσιμότητας IP',
            invalid_ip: 'Μη έγκυρη διεύθυνση IP: ',
            invalid_format: 'Μη έγκυρη μορφή',
            starts_with_169254: 'Ξεκινά με 169.254',
            unreachable: 'Μη προσβάσιμη διεύθυνση IP',
            check_connection: 'Έλεγχος σύνδεσης μηχανήματος',
            check_firmware: 'Έλεγχος έκδοσης υλικολογισμικού',
            check_camera: 'Έλεγχος διαθεσιμότητας κάμερας',
            retry: 'Επανάληψη',
            succeeded_message: 'Επιτυχής σύνδεση 🎉',
            finish_setting: 'Ξεκινήστε τη δημιουργία!',
            check_swiftray_connection: 'Έλεγχος σύνδεσης διακομιστή',
            check_swiftray_connection_unreachable: 'ο διακομιστής δεν είναι προσβάσιμος',
            promark_hint: 'Εάν αποτύχετε επανειλημμένα να συνδεθείτε μέσω USB, ανατρέξτε στο <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">άρθρο του Κέντρου βοήθειας</a>.',
            alert: {
                swiftray_connection_error: 'Αδύνατη η σύνδεση με τον διακομιστή. Επανεκκινήστε το Beam Studio και δοκιμάστε ξανά.',
            },
        },
        connecting: 'Σύνδεση...',
        setting_completed: {
            start: 'Έναρξη',
            great: 'Καλώς ήρθατε στο Beam Studio',
            setup_later: 'Μπορείτε πάντα να ρυθμίσετε τη μηχανή σας από τη γραμμή τίτλου > "Μηχανές" > "Ρύθμιση μηχανής"',
            back: 'Πίσω',
            ok: 'ΞΕΚΙΝΗΣΤΕ ΤΗ ΔΗΜΙΟΥΡΓΙΑ',
        },
        promark: {
            select_laser_source: 'Επιλέξτε το Promark σας',
            select_workarea: 'Επιλέξτε Περιοχή Εργασίας',
            settings: 'Ρυθμίσεις Promark',
            qc_instructions: 'Συμπληρώστε τις παραμέτρους που βρίσκονται στο πίσω μέρος της κάρτας "QC Pass"',
            configuration_confirmation: 'Αυτό διασφαλίζει ότι το Promark σας είναι σωστά ρυθμισμένο για βέλτιστη απόδοση και ακρίβεια.',
            or_complete_later: `Ή παραλείψτε αυτό το βήμα και ολοκληρώστε τις Ρυθμίσεις Promark αργότερα στο:
    Μηχανήματα > "Όνομα Promark" > Ρυθμίσεις Promark`,
        },
    },
    error_pages: {
        screen_size: 'Λάβετε υπόψη ότι το Beam Studio μπορεί να μην λειτουργεί βέλτιστα στη συσκευή σας. Για βέλτιστη εμπειρία, θα πρέπει να είναι μια συσκευή με πλάτος οθόνης τουλάχιστον 1024 εικονοστοιχεία.',
    },
    menu: {
        mm: 'χιλιοστά',
        inches: 'Ίντσες',
    },
    settings: {
        on: 'Ενεργοποιημένο',
        off: 'Απενεργοποιημένο',
        low: 'Χαμηλό',
        normal: 'Υψηλό',
        high: 'Υψηλή',
        caption: 'Ρυθμίσεις',
        tabs: {
            general: 'Γενικά',
            device: 'Συσκευή',
        },
        ip: 'Διεύθυνση IP συσκευής',
        guess_poke: 'Αναζήτηση διεύθυνσης IP συσκευής',
        auto_connect: 'Αυτόματη επιλογή μοναδικής συσκευής',
        wrong_ip_format: 'Λανθασμένη μορφή IP',
        default_machine: 'Προεπιλεγμένο μηχάνημα',
        default_machine_button: 'Κενό',
        remove_default_machine_button: 'Αφαίρεση',
        confirm_remove_default: 'Το προεπιλεγμένο μηχάνημα θα αφαιρεθεί.',
        reset: 'Επαναφορά Beam Studio',
        reset_now: 'Επαναφορά Beam Studio',
        confirm_reset: 'Επιβεβαίωση επαναφοράς Beam Studio',
        language: 'Γλώσσα',
        notifications: 'Ειδοποιήσεις επιφάνειας εργασίας',
        check_updates: 'Αυτόματος έλεγχος',
        autosave_enabled: 'Αυτόματη αποθήκευση',
        autosave_path: 'Τοποθεσία αυτόματης αποθήκευσης',
        autosave_interval: 'Αυτόματη αποθήκευση κάθε',
        autosave_number: 'Αριθμός αυτόματων αποθηκεύσεων',
        autosave_path_not_correct: 'Η καθορισμένη διαδρομή δεν βρέθηκε.',
        preview_movement_speed: 'Ταχύτητα προεπισκόπησης κίνησης',
        medium: 'Μέτριος',
        default_units: 'Προεπιλεγμένες μονάδες',
        default_font_family: 'Προεπιλεγμένη γραμματοσειρά',
        default_font_style: 'Προεπιλεγμένο στυλ γραμματοσειράς',
        fast_gradient: 'Βελτιστοποίηση ταχύτητας',
        engraving_direction: 'Κατεύθυνση',
        top_down: 'Από πάνω προς τα κάτω',
        bottom_up: 'Από κάτω προς τα πάνω',
        vector_speed_constraint: 'Όριο ταχύτητας',
        loop_compensation: 'Αντιστάθμιση βρόγχου',
        blade_radius: 'Ακτίνα λεπίδας',
        blade_precut_switch: 'Προκοπή λεπίδας',
        blade_precut_position: 'Θέση προκοπής',
        default_beambox_model: 'Προεπιλεγμένη ρύθμιση εγγράφου',
        guides_origin: 'Προέλευση οδηγών',
        guides: 'Οδηγοί',
        image_downsampling: 'Ποιότητα προεπισκόπησης bitmap',
        anti_aliasing: 'Αντι-aliasing',
        continuous_drawing: 'Συνεχής σχεδίαση',
        trace_output: 'Έξοδος ιχνηλάτησης εικόνας',
        single_object: 'Ένα Αντικείμενο',
        grouped_objects: 'Ομαδοποιημένα Αντικείμενα',
        simplify_clipper_path: 'Βελτιστοποίηση Υπολογισμένης Διαδρομής',
        enable_low_speed: 'Ενεργοποίηση Χαμηλής Ταχύτητας',
        enable_custom_backlash: 'Ενεργοποίηση Προσαρμοσμένης Αντιστάθμισης Οπισθοδρόμησης',
        calculation_optimization: 'Επιτάχυνση υπολογισμού διαδρομής',
        auto_switch_tab: 'Αυτόματη εναλλαγή μεταξύ πίνακα επιπέδων και αντικειμένων',
        custom_preview_height: 'Προσαρμοσμένο Ύψος Προεπισκόπησης',
        mask: 'Περικοπή Περιοχής Εργασίας',
        text_path_calc_optimization: 'Βελτιστοποίηση Υπολογισμού Διαδρομής Κειμένου',
        font_substitute: 'Υποκατάσταση Μη Υποστηριζόμενων Χαρακτήρων',
        font_convert: 'Μετατροπέας κειμένου σε μονοπάτι',
        default_borderless_mode: 'Προεπιλογή Άνοιγμα Κάτω',
        default_enable_autofocus_module: 'Προεπιλογή Αυτόματης Εστίασης',
        default_enable_diode_module: 'Προεπιλογή Διόδου Λέιζερ',
        diode_offset: 'Αντιστάθμιση λέιζερ διόδου',
        autofocus_offset: 'Αντιστάθμιση αυτόματης εστίασης',
        diode_one_way_engraving: 'Μονόδρομη χάραξη λέιζερ διόδου',
        diode_two_way_warning: 'Η αμφίδρομη εκπομπή φωτός είναι ταχύτερη και μπορεί να προκαλέσει ανακρίβεια στη θέση χάραξης λέιζερ. Συνιστάται δοκιμή πρώτα.',
        share_with_flux: 'Κοινοποίηση αναλυτικών Beam Studio',
        none: 'Κανένα',
        close: 'Κλείσιμο',
        enabled: 'Ενεργοποιημένο',
        disabled: 'Απενεργοποιημένο',
        cancel: 'Ακύρωση',
        done: 'Εφαρμογή',
        module_offset_10w: 'Αντιστάθμιση λέιζερ διόδου 10W',
        module_offset_20w: '20W λέιζερ διόδου αντιστάθμιση',
        module_offset_printer: 'Αντιστάθμιση εκτυπωτή',
        module_offset_2w_ir: '2W υπέρυθρης ακτίνας λέιζερ αντιστάθμιση',
        printer_advanced_mode: 'Προηγμένη λειτουργία εκτυπωτή',
        default_laser_module: 'Προεπιλεγμένη μονάδα λέιζερ',
        low_laser_for_preview: 'Λέιζερ για Εκτέλεση πλαισίου',
        groups: {
            general: 'Γενικά',
            update: 'Ενημερώσεις λογισμικού',
            connection: 'Σύνδεση',
            autosave: 'Αυτόματη αποθήκευση',
            camera: 'Κάμερα',
            editor: 'Επεξεργαστής',
            engraving: 'Χάραξη (σάρωση)',
            path: 'Διανύσματα (Περιγράμματα)',
            mask: 'Περικοπή Περιοχής Εργασίας',
            text_to_path: 'Κείμενο',
            modules: 'Πρόσθετα',
            ador_modules: 'Πρόσθετα Ador',
            privacy: 'Ιδιωτικότητα',
        },
        notification_on: 'Ενεργοποίηση',
        notification_off: 'Απενεργοποίηση',
        update_latest: 'Τελευταία',
        update_beta: 'Δοκιμαστική έκδοση',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
            calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
        },
    },
    beambox: {
        tag: {
            g: 'Ομάδα',
            use: 'Εισαγωγή SVG',
            image: 'Εικόνα',
            text: 'Κείμενο',
        },
        context_menu: {
            cut: 'Αποκοπή',
            copy: 'Αντιγραφή',
            paste: 'Επικόλληση',
            paste_in_place: 'Επικόλληση στη θέση',
            duplicate: 'Αντιγραφή',
            delete: 'Διαγραφή',
            group: 'Ομάδα',
            ungroup: 'Κατάργηση ομαδοποίησης',
            move_front: 'Μετακινήστε μπροστά',
            move_up: 'Μετακινήστε πάνω',
            move_down: 'Μετακινήστε κάτω',
            move_back: 'Μετακινήστε πίσω',
        },
        popup: {
            select_import_method: 'Επιλέξτε στυλ στρώσης:',
            select_import_module: 'Επιλέξτε Μονάδα:',
            touchpad: 'TouchPad',
            mouse: 'Ποντίκι',
            layer_by_layer: 'Στρώμα',
            layer_by_color: 'Χρώμα',
            nolayer: 'Μονό στρώμα',
            loading_image: 'Φόρτωση εικόνας, παρακαλώ περιμένετε...',
            no_support_text: 'Το Beam Studio δεν υποστηρίζει ετικέτες κειμένου αυτή τη στιγμή. Μεταφέρετε το κείμενο σε μονοπάτι πριν από την εισαγωγή.',
            speed_too_high_lower_the_quality: 'Η χρήση πολύ υψηλής ταχύτητας σε αυτή την ανάλυση μπορεί να έχει ως αποτέλεσμα χαμηλότερη ποιότητα χάραξης.',
            both_power_and_speed_too_high: 'Η χρήση χαμηλότερης ισχύος λέιζερ θα επεκτείνει τη διάρκεια ζωής του σωλήνα λέιζερ.  Επίσης, πολύ υψηλή ταχύτητα σε αυτή την ανάλυση μπορεί να έχει ως αποτέλεσμα χαμηλότερη ποιότητα χάραξης.',
            too_fast_for_path: 'Η χρήση πολύ υψηλής ταχύτητας σε στρώματα που περιέχουν αντικείμενα διαδρομής μπορεί να έχει ως αποτέλεσμα χαμηλότερη ακρίβεια κατά την κοπή. Δεν συνιστούμε τη χρήση ταχύτητας ταχύτερη από %(limit)s κατά την κοπή.',
            too_fast_for_path_and_constrain: 'Τα ακόλουθα στρώματα: %(layers)s \nπεριέχουν αντικείμενα διανυσματικής διαδρομής και έχουν ταχύτητα που υπερβαίνει τα %(limit)s. Η ταχύτητα κοπής των αντικειμένων διανυσματικής διαδρομής θα περιοριστεί στα %(limit)s. Μπορείτε να καταργήσετε αυτόν τον περιορισμό στις Ρυθμίσεις.',
            should_update_firmware_to_continue: '#814 Το υλικολογισμικό σας δεν υποστηρίζει αυτή την έκδοση του Beam Studio. Παρακαλώ ενημερώστε το υλικολογισμικό για να συνεχίσετε. (Μενού > Μηχάνημα > [Το μηχάνημά σας] > Ενημέρωση υλικολογισμικού)',
            recommend_downgrade_software: 'Εντοπίσαμε μια παλαιότερη έκδοση υλικολογισμικού. Αντιμετωπίζουμε θέματα συμβατότητας, αλλά προς το παρόν συνιστούμε να επιστρέψετε στο Beam Studio 1.9.5.',
            recommend_upgrade_firmware: 'Εντοπίσαμε μια παλαιότερη έκδοση υλικολογισμικού. Αντιμετωπίζουμε θέματα συμβατότητας, αλλά προς το παρόν συνιστούμε την ενημέρωση στο τελευταίο υλικολογισμικό.',
            still_continue: 'Συνέχεια',
            more_than_two_object: 'Πάρα πολλά αντικείμενα. Υποστήριξη μόνο για 2 αντικείμενα',
            not_support_object_type: 'Μη υποστηριζόμενος τύπος αντικειμένου',
            select_first: 'Επιλέξτε πρώτα ένα αντικείμενο.',
            select_at_least_two: 'Επιλέξτε δύο αντικείμενα για να συνεχίσετε',
            import_file_contain_invalid_path: '#808 Το εισαγόμενο αρχείο SVG περιέχει μη έγκυρη διαδρομή εικόνας. Βεβαιωθείτε ότι όλα τα αρχεία εικόνας υπάρχουν ή ενσωματώστε την εικόνα στο αρχείο',
            import_file_error_ask_for_upload: 'Αποτυχία εισαγωγής αρχείου SVG. Θέλετε να παρέχετε το αρχείο στην ομάδα ανάπτυξης για αναφορά σφαλμάτων;',
            upload_file_too_large: '#819 Το αρχείο είναι πολύ μεγάλο για αποστολή.',
            successfully_uploaded: 'Η μεταφόρτωση του αρχείου ολοκληρώθηκε με επιτυχία.',
            upload_failed: '#819 Η μεταφόρτωση του αρχείου απέτυχε.',
            or_turn_off_borderless_mode: ' Ή απενεργοποιήστε τη λειτουργία Ανοιχτό Κάτω Μέρος.',
            svg_1_1_waring: 'Η έκδοση αυτού του αρχείου SVG είναι v 1.1, ενδέχεται να υπάρχουν πιθανά προβλήματα ασυμβατότητας.',
            svg_image_path_waring: 'Αυτό το αρχείο SVG περιέχει φόρτωση <image> από διαδρομή αρχείου. Αυτό ενδέχεται να προκαλέσει αποτυχίες κατά τη φόρτωση.\nΓια να αποφύγετε αυτόν τον κίνδυνο, χρησιμοποιήστε ενσωματωμένη εικόνα κατά την εξαγωγή SVG.',
            dxf_version_waring: 'Η έκδοση αυτού του αρχείου DXF δεν είναι 2013, ενδέχεται να υπάρχουν πιθανά προβλήματα ασυμβατότητας.',
            dont_show_again: 'Να μην εμφανιστεί αυτό την επόμενη φορά.',
            convert_to_path_fail: 'Αποτυχία μετατροπής σε μονοπάτι.',
            save_unsave_changed: 'Θέλετε να αποθηκεύσετε τις μη αποθηκευμένες αλλαγές;',
            dxf_bounding_box_size_over: 'Το μέγεθος του σχεδίου είναι εκτός της περιοχής εργασίας. Μετακινήστε το σχέδιό σας πιο κοντά στην αρχή στο λογισμικό CAD σας ή βεβαιωθείτε ότι η μονάδα έχει οριστεί σωστά.',
            progress: {
                uploading: 'Μεταφόρτωση',
                calculating: 'Υπολογισμός',
            },
            backend_connect_failed_ask_to_upload: '#802 Συνεχίζουν να προκύπτουν σφάλματα κατά τη σύνδεση με το backend. Θέλετε να μεταφορτώσετε την αναφορά σφαλμάτων;',
            backend_error_hint: 'Οι λειτουργίες ενδέχεται να μην λειτουργούν σωστά λόγω σφάλματος backend.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Σφάλμα κατά τη μετατροπή του PDF σε SVG:',
                error_pdf2svg_not_found: '#825 Σφάλμα: Η εντολή pdf2svg δεν βρέθηκε. Εγκαταστήστε το pdf2svg με τον διαχειριστή πακέτων σας (π.χ. "yum install pdf2svg" ή "apt-get install pdf2svg").',
            },
            ungroup_use: 'Αυτό θα καταργήσει την ομαδοποίηση των εισαγόμενων DXF ή SVG. Επειδή το αρχείο ενδέχεται να περιέχει μεγάλο αριθμό στοιχείων, ενδέχεται να χρειαστεί χρόνος για την κατάργηση της ομαδοποίησης. Είστε σίγουροι ότι θέλετε να προχωρήσετε;',
            vectorize_shading_image: 'Οι εικόνες με διαβάθμιση χρώματος χρειάζονται περισσότερο χρόνο για να ιχνηλατηθούν και είναι επιρρεπείς στο θόρυβο. Απενεργοποιήστε τη διαβάθμιση της εικόνας πριν εκτελέσετε.',
            change_workarea_before_preview: 'Η περιοχή εργασίας του %s δεν ταιριάζει με την τρέχουσα περιοχή εργασίας που έχει οριστεί. Θέλετε να αλλάξετε την τρέχουσα περιοχή εργασίας;',
            bug_report: 'Αναφορά σφαλμάτων',
            sentry: {
                title: 'Ας βελτιώσουμε το Beam Studio μαζί',
                message: 'Συμφωνείτε να μεταφορτώνουμε αυτόματα τις σχετικές πληροφορίες στην ομάδα ανάπτυξης όταν προκύπτουν σφάλματα;',
            },
            questionnaire: {
                caption: 'Βοηθήστε μας να βελτιώσουμε την εφαρμογή',
                message: 'Βοηθήστε μας να βελτιώσουμε την εφαρμογή συμπληρώνοντας το ερωτηματολόγιο',
                unable_to_get_url: 'Αποτυχία λήψης του συνδέσμου προς το ερωτηματολόγιο. Ελέγξτε τη σύνδεσή σας στο διαδίκτυο.',
                no_questionnaire_available: 'Δεν υπάρχει διαθέσιμο ερωτηματολόγιο αυτή τη στιγμή.',
            },
            facebook_group_invitation: {
                title: 'Συμμετοχή στην επίσημη ομάδα χρηστών',
                message: 'Συμμετάσχετε στην επίσημη ομάδα μας στο Facebook για να συνδεθείτε με άλλους χρήστες, να συζητήσετε, να μοιραστείτε έργα και να ενημερωθείτε για τα νέα μας. Ανυπομονούμε να σας δούμε!',
                join_now: 'Μέσα',
                later: 'Ίσως αργότερα',
                already_joined: 'Ήδη μέλος',
                dont_show_again: 'Να μην εμφανιστεί ξανά',
            },
            ai_credit: {
                relogin_to_use: 'Παρακαλούμε συνδεθείτε ξανά για να χρησιμοποιήσετε αυτήν τη λειτουργία.',
                insufficient_credit: 'Έχετε εξαντλήσει την πίστωση',
                insufficient_credit_msg: 'Δεν μπορείτε να χρησιμοποιήσετε το %s. Μεταβείτε στο κέντρο μελών και αγοράστε πίστωση AI.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Μετάβαση',
            },
            text_to_path: {
                caption: 'Μετατροπέας κειμένου σε μονοπάτι 2.0',
                message: "Το Beam Studio εισάγει τώρα έναν νέο μετατροπέα κειμένου σε μονοπάτι (Μετατροπέας 2.0), ο οποίος παράγει πιο αξιόπιστα αποτελέσματα! Θα θέλατε να τον ενεργοποιήσετε τώρα; \\nΜπορείτε επίσης να προσαρμόσετε αυτήν τη ρύθμιση αργότερα στην ενότητα 'Μετατροπέας κειμένου σε μονοπάτι' εντός των προτιμήσεων.",
            },
            auto_switch_tab: {
                title: 'Αυτόματη Εναλλαγή Πάνελ Επίπεδου και Αντικειμένου',
                message: 'Μια νέα επιλογή για αυτόματη εναλλαγή μεταξύ του πίνακα Επιπέδων και του πίνακα Αντικειμένων έχει προστεθεί στις Προτιμήσεις. Αυτή η επιλογή είναι απενεργοποιημένη από προεπιλογή. Θέλετε να ενεργοποιήσετε την αυτόματη εναλλαγή τώρα; <br/>Μπορείτε να αλλάξετε αυτήν τη ρύθμιση οποιαδήποτε στιγμή στις Προτιμήσεις.',
            },
        },
        zoom_block: {
            fit_to_window: 'Προσαρμογή στο παράθυρο',
        },
        time_est_button: {
            calculate: 'Υπολογισμός χρόνου',
            estimate_time: 'Εκτιμώμενος χρόνος:',
        },
        left_panel: {
            unpreviewable_area: 'Τυφλή περιοχή',
            diode_blind_area: 'Τυφλή περιοχή υβριδικού laser προσθήκης',
            borderless_blind_area: 'Μη χαρακτική περιοχή',
            borderless_preview: 'Προεπισκόπηση κάμερας ανοιχτής λειτουργίας κάτω μέρους',
            rectangle: 'Ορθογώνιο',
            ellipse: 'Έλλειψη',
            line: 'Γραμμή',
            image: 'Εικόνα',
            text: 'Κείμενο',
            label: {
                cursor: 'Επιλογή',
                photo: 'Εικόνα',
                text: 'Κείμενο',
                line: 'Γραμμή',
                rect: 'Ορθογώνιο',
                oval: 'Στρογγυλεμένο ορθογώνιο',
                polygon: 'Πολύγωνο',
                pen: 'Πένα',
                shapes: 'Σχήματα',
                array: 'Πίνακας',
                preview: 'Προεπισκόπηση κάμερας',
                trace: 'Ιχνηλάτηση εικόνας',
                end_preview: 'Τέλος προεπισκόπησης',
                clear_preview: 'Εκκαθάριση προεπισκόπησης',
                choose_camera: 'Κάμερα',
                live_feed: 'Ζωντανή μετάδοση',
                adjust_height: 'Ρύθμιση ύψους',
                qr_code: 'Κωδικός QR',
                boxgen: 'Boxgen',
                my_cloud: 'Το Cloud μου',
                pass_through: 'Διέλευση',
                curve_engraving: {
                    title: '3D Καμπύλη',
                    exit: 'Έξοδος',
                    select_area: 'Επιλέξτε περιοχή',
                    preview_3d_curve: 'Προεπισκόπηση 3D καμπύλης',
                    clear_area: 'Εκκαθάριση επιλεγμένης περιοχής',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Επίπεδα',
                objects: 'Αντικείμενα',
                path_edit: 'Επεξεργασία Μονοπατιού',
            },
            layer_panel: {
                layer1: 'Στρώμα 1',
                layer_bitmap: 'Χάρτης bit',
                layer_engraving: 'Χάραξη',
                layer_cutting: 'Κοπή',
                current_layer: 'Τρέχον Στρώμα',
                move_elems_to: 'Μετακίνηση στοιχείων σε:',
                notification: {
                    dupeLayerName: 'Υπάρχει ήδη ένα στρώμα με αυτό το όνομα!',
                    newName: 'ΝΕΟ ΟΝΟΜΑ',
                    enterUniqueLayerName: 'Παρακαλώ εισάγετε ένα μοναδικό όνομα στρώματος',
                    enterNewLayerName: 'Παρακαλώ εισάγετε το νέο όνομα στρώματος',
                    layerHasThatName: 'Το στρώμα έχει ήδη αυτό το όνομα',
                    QmoveElemsToLayer: "Μετακινήστε τα επιλεγμένα στοιχεία στο στρώμα '%s';",
                    moveElemFromPrintingLayerTitle: 'Μετακινήστε το επιλεγμένο στοιχείο στο %s και μετατρέψτε το σε στοιχείο λέιζερ;',
                    moveElemFromPrintingLayerMsg: 'Λάβετε υπόψη ότι αν ολοκληρώσετε αυτήν τη λειτουργία, οι ρυθμίσεις χρώματος του επιλεγμένου στοιχείου θα αφαιρεθούν και θα οριστούν σύμφωνα με το %s.',
                    moveElemToPrintingLayerTitle: 'Μετακινήστε το επιλεγμένο στοιχείο στο %s και μετατρέψτε το σε στοιχείο εκτύπωσης;',
                    moveElemToPrintingLayerMsg: 'Λάβετε υπόψη ότι αν ολοκληρώσετε αυτήν τη λειτουργία, οι ρυθμίσεις του επιλεγμένου στοιχείου θα αφαιρεθούν και θα οριστούν σύμφωνα με το %s.',
                    splitColorTitle: 'Θέλετε να επεκτείνετε το επιλεγμένο στρώμα σε στρώματα CMYK;',
                    splitColorMsg: 'Λάβετε υπόψη ότι αν συνεχίσετε με αυτήν τη λειτουργία, δεν θα μπορείτε να επιστρέψετε στα αρχικά στρώματα χρωμάτων.',
                    mergeLaserLayerToPrintingLayerTitle: 'Θέλετε να συγχωνεύσετε αυτά τα στρώματα σε ένα στρώμα εκτύπωσης;',
                    mergeLaserLayerToPrintingLayerMsg: 'Λάβετε υπόψη ότι αν ολοκληρώσετε αυτήν τη λειτουργία, οι ρυθμίσεις του στρώματος λέιζερ θα αφαιρεθούν και θα οριστούν σύμφωνα με το τρέχον στρώμα.',
                    mergePrintingLayerToLaserLayerTitle: 'Θέλετε να συγχωνεύσετε αυτά τα στρώματα σε ένα στρώμα λέιζερ;',
                    mergePrintingLayerToLaserLayerMsg: 'Λάβετε υπόψη ότι αν ολοκληρώσετε αυτήν τη λειτουργία, οι ρυθμίσεις χρώματος του στρώματος εκτύπωσης θα αφαιρεθούν και θα οριστούν σύμφωνα με το τρέχον στρώμα.',
                },
                layers: {
                    layer: 'Στρώμα',
                    layers: 'Στρώματα',
                    del: 'Διαγραφή Στρώματος',
                    move_down: 'Μετακίνηση Στρώματος Κάτω',
                    new: 'Νέο Στρώμα',
                    rename: 'Μετονομασία Στρώματος',
                    move_up: 'Μετακίνηση Στρώματος Πάνω',
                    dupe: 'Διπλασιασμός Στρώματος',
                    lock: 'Κλείδωμα Στρώματος',
                    unlock: 'Ξεκλείδωμα',
                    merge_down: 'Συγχώνευση με Κάτω Στρώμα',
                    merge_all: 'Συγχώνευση Όλων των Στρωμάτων',
                    merge_selected: 'Συγχώνευση Επιλεγμένων Στρωμάτων',
                    move_elems_to: 'Μετακινήστε στοιχεία σε:',
                    move_selected: 'Μετακινήστε τα επιλεγμένα στοιχεία σε διαφορετικό επίπεδο',
                    switchToFullColor: 'Μετάβαση σε επίπεδο πλήρους χρώματος',
                    switchToSingleColor: 'Μετάβαση σε επίπεδο μονού χρώματος',
                    splitFullColor: 'Διαίρεση επιπέδου πλήρους χρώματος',
                    fullColor: 'Πλήρες χρώμα',
                },
            },
            laser_panel: {
                preset_setting: 'Προκαθορισμένες ρυθμίσεις (%s)',
                multi_layer: 'Πολλαπλά στρώματα',
                parameters: 'Παράμετροι',
                strength: 'Ισχύς',
                pwm_advanced_setting: 'Ρυθμίσεις Ισχύος Λειτουργίας Βάθους',
                pwm_advanced_desc: 'Ρυθμίστε μια ελάχιστη ισχύ για τη λειτουργία βάθους.',
                pwm_advanced_hint: 'Αυτή η ρύθμιση εφαρμόζεται συγκεκριμένα σε εικόνες κλίσης όταν χρησιμοποιείτε τη λειτουργία βάθους.',
                low_power_warning: 'Χαμηλή ισχύς λέιζερ (κάτω από 10%) ενδέχεται να μην εκπέμπει το φως λέιζερ.',
                speed: 'Ταχύτητα',
                speed_contrain_warning: 'Η ταχύτητα κοπής των διανυσμάτων θα περιοριστεί στα %(limit)s. Μπορείτε να καταργήσετε αυτό το όριο από τις Ρυθμίσεις.',
                low_speed_warning: 'Η χαμηλή ταχύτητα μπορεί να προκαλέσει κάψιμο του υλικού.',
                promark_speed_desc: 'Οι παράμετροι ταχύτητας δεν ισχύουν για εικόνες διαβάθμισης.',
                repeat: 'Αριθμός Περασμάτων',
                advanced: 'Προηγμένες',
                lower_focus: 'Χαμηλώστε την Εστίαση',
                by: 'Κατά',
                stepwise_focusing: 'Βηματική Εστίαση',
                single_color: 'Μονόχρωμο',
                lower_focus_desc: 'Χαμήλωμα του ύψους εστίασης κατά συγκεκριμένη απόσταση μετά την εστίαση για βελτίωση της απόδοσης κοπής.',
                stepwise_focusing_desc: 'Σταδιακό χαμήλωμα της απόστασης εστίασης βάσει του ύψους του αντικειμένου κατά κάθε αριθμό περάσματος.',
                single_color_desc: 'Ισχύει μόνο για πλήρως έγχρωμο στρώμα και δεν μπορεί να χρησιμοποιηθεί με επεκταμένο μονοχρωματικό στρώμα.',
                focus_adjustment: 'Ρύθμιση εστίασης',
                height: 'Ύψος αντικειμένου',
                z_step: 'Βήμα Z',
                diode: 'Δίοδος λέιζερ',
                backlash: 'Οπίσθια κίνηση',
                ink_saturation: 'Κορεσμός',
                print_multipass: 'Πολλαπλή διέλευση',
                white_ink: 'Λευκή μελάνη',
                white_ink_settings: 'Ρυθμίσεις λευκής μελάνης',
                color_adjustment: 'Ρύθμιση καναλιού',
                color_adjustment_short: 'Κανάλι',
                halftone: 'Μισοτόνος',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Δύναμη',
                times: 'φορές',
                cut: 'Κόψιμο',
                engrave: 'Χαράξτε',
                more: 'Διαχείριση',
                apply: 'Εφαρμογή',
                custom_preset: 'Προσαρμοσμένο',
                various_preset: 'Διάφορες Προεπιλογές',
                module: 'Μονάδα',
                pulse_width: 'Πλάτος Παλμού',
                frequency: 'Συχνότητα',
                fill_setting: 'Ρυθμίσεις Γέμισης',
                fill_interval: 'Διάστημα Γεμίσματος',
                fill_angle: 'Γωνία Γεμίσματος',
                bi_directional: 'Διπλής Κατεύθυνσης',
                cross_hatch: 'Διασταυρωμένη Σκίαση',
                dottingTime: 'Χρόνος στίξης',
                gradient_only: 'Μόνο για εικόνες με διαβάθμιση',
                filled_path_only: 'Μόνο για διαδρομές πλήρωσης',
                slider: {
                    regular: 'Κανονικό',
                    low: 'Χαμηλό',
                    very_low: 'Ελάχιστο',
                    high: 'Υψηλό',
                    very_high: 'Μέγιστο',
                    slow: 'Αργά',
                    very_slow: 'Πολύ αργά',
                    fast: 'Γρήγορα',
                    very_fast: 'Πολύ γρήγορα',
                },
                dropdown: {
                    parameters: 'Προεπιλογές',
                    save: 'Προσθήκη τρέχοντος παραμέτρων',
                    mm: {
                        wood_3mm_cutting: 'Ξύλο - 3mm κοπή',
                        wood_5mm_cutting: 'Ξύλο - 5mm κοπή',
                        wood_7mm_cutting: 'Ξύλο - 7mm κοπή',
                        wood_8mm_cutting: 'Ξύλο - 8mm κοπή',
                        wood_10mm_cutting: 'Ξύλο - 10mm κοπή',
                        wood_engraving: 'Ξύλο - χάραξη',
                        acrylic_3mm_cutting: 'Ακρυλικό - 3mm κοπή',
                        acrylic_5mm_cutting: 'Ακρυλικό - 5mm κοπή',
                        acrylic_8mm_cutting: 'Ακρυλικό - 8mm κοπή',
                        acrylic_10mm_cutting: 'Ακρυλικό - 10mm κοπή',
                        acrylic_engraving: 'Ακρυλικό - Χάραξη',
                        mdf_3mm_cutting: 'MDF 3 χιλιοστά - Κοπή',
                        mdf_5mm_cutting: 'MDF 5 χιλιοστά - Κοπή',
                        mdf_engraving: 'MDF - Χάραξη',
                        leather_3mm_cutting: 'Δέρμα - 3 χιλιοστά Κοπή',
                        leather_5mm_cutting: 'Δέρμα - 5 χιλιοστά Κοπή',
                        leather_engraving: 'Δέρμα - Χάραξη',
                        denim_1mm_cutting: 'Τζην - 1 χιλιοστό Κοπή',
                        fabric_3mm_cutting: 'Ύφασμα - 3 χιλιοστά Κοπή',
                        fabric_5mm_cutting: 'Ύφασμα - 5 χιλιοστά Κοπή',
                        fabric_engraving: 'Ύφασμα - Χάραξη',
                        rubber_bw_engraving: 'Καουτσούκ - Χάραξη',
                        glass_bw_engraving: 'Γυαλί - Χάραξη',
                        metal_bw_engraving: 'Μέταλλο - Χάραξη',
                        steel_engraving_spray_engraving: 'Μέταλλο - Χάραξη',
                        stainless_steel_bw_engraving_diode: 'Ανοξείδωτος χάλυβας - Χάραξη (Δίοδος λέιζερ)',
                        gold_engraving: 'Χρυσός - Χάραξη',
                        brass_engraving: 'Ορείχαλκος - Χάραξη',
                        ti_engraving: 'Τιτάνιο - Χάραξη',
                        stainless_steel_engraving: 'Ανοξείδωτος χάλυβας - Χάραξη',
                        aluminum_engraving: 'Αλουμίνιο - Χάραξη',
                        black_acrylic_3mm_cutting: 'Μαύρο ακρυλικό - 3mm Κοπή',
                        black_acrylic_5mm_cutting: 'Μαύρο ακρυλικό - 5mm Κοπή',
                        black_acrylic_engraving: 'Μαύρο ακρυλικό - Χάραξη',
                        abs_engraving: 'ABS - Χάραξη',
                        silver_engraving: 'Ασήμι - Χάραξη',
                        iron_engraving: 'Σίδηρος - Χάραξη',
                        fabric_printing: 'Ύφασμα - Εκτύπωση',
                        canvas_printing: 'Καμβάς - Εκτύπωση',
                        cardstock_printing: 'Χαρτόνι - Εκτύπωση',
                        wood_printing: 'Ξύλο - Εκτύπωση',
                        bamboo_printing: 'Μπαμπού - Εκτύπωση',
                        cork_printing: 'Φελλός - Εκτύπωση',
                        flat_stone_printing: 'Επίπεδη Πέτρα - Εκτύπωση',
                        acrylic_printing: 'Aκρυλικό - Εκτύπωση',
                        pc_printing: 'Πολυκαρβονικό - Εκτύπωση',
                        stainless_steel_printing: 'Ανοξείδωτος Χάλυβας - Εκτύπωση',
                        gloss_leather_printing: 'Γυαλιστερό δέρμα - Εκτύπωση',
                        glass_printing: 'Γυαλί - Εκτύπωση',
                        aluminum_light: 'Αλουμίνιο (ανοιχτό)',
                        stainless_steel_dark: 'Ανοξείδωτο ατσάλι (σκούρο)',
                        stainless_steel_light: 'Ανοξείδωτο ατσάλι (ανοιχτό)',
                        brass_dark: 'Ορείχαλκος (σκούρο)',
                        brass_light: 'Ορείχαλκος (ανοιχτό)',
                        copper: 'Χαλκός',
                        titanium_dark: 'Τιτάνιο (σκούρο)',
                        titanium_light: 'Τιτάνιο (ανοιχτό)',
                        black_abs: 'Μαύρο ABS',
                        white_abs: 'Λευκό ABS',
                        opaque_acrylic: 'Αδιαφανές ακρυλικό',
                        stone: 'Πέτρα',
                    },
                    inches: {
                        wood_3mm_cutting: 'Ξύλο - 76 χιλ Κοπή',
                        wood_5mm_cutting: 'Ξύλο - 127 χιλ Κοπή',
                        wood_7mm_cutting: 'Ξύλο - 178 χιλ Κοπή',
                        wood_8mm_cutting: 'Ξύλο - 203 χιλ Κοπή',
                        wood_10mm_cutting: 'Ξύλο - 254 χιλ Κοπή',
                        wood_engraving: 'Ξύλο - Χάραξη',
                        acrylic_3mm_cutting: 'Aκρυλικό - 76 χιλ Κοπή',
                        acrylic_5mm_cutting: 'Aκρυλικό - 127 χιλ Κοπή',
                        acrylic_8mm_cutting: 'Aκρυλικό - 203 χιλ Κοπή',
                        acrylic_10mm_cutting: 'Aκρυλικό - 254 χιλ Κοπή',
                        acrylic_engraving: 'Ακρυλικό - Χάραξη',
                        mdf_3mm_cutting: 'MDF Νέας Ζηλανδίας - 0,1" Κοπή',
                        mdf_5mm_cutting: 'MDF Νέας Ζηλανδίας - 0,2" Κοπή',
                        mdf_engraving: 'MDF Νέας Ζηλανδίας - Χάραξη',
                        leather_3mm_cutting: 'Δέρμα - 0,1" Κοπή',
                        leather_5mm_cutting: 'Δέρμα - 0,2" Κοπή',
                        leather_engraving: 'Δέρμα - Χάραξη',
                        denim_1mm_cutting: 'Τζην - 0,04" Κοπή',
                        fabric_3mm_cutting: 'Ύφασμα - 0,1" Κοπή',
                        fabric_5mm_cutting: 'Ύφασμα - 0,2" Κοπή',
                        fabric_engraving: 'Ύφασμα - Χάραξη',
                        rubber_bw_engraving: 'Καουτσούκ - Χάραξη',
                        glass_bw_engraving: 'Γυαλί - Χάραξη',
                        metal_bw_engraving: 'Μέταλλο - Χάραξη',
                        steel_engraving_spray_engraving: 'Μέταλλο - Χάραξη',
                        stainless_steel_bw_engraving_diode: 'Ανοξείδωτος χάλυβας - Χάραξη (Δίοδος λέιζερ)',
                        gold_engraving: 'Χρυσός - Χάραξη',
                        brass_engraving: 'Ορείχαλκος - Χάραξη',
                        ti_engraving: 'Τιτάνιο - Χάραξη',
                        stainless_steel_engraving: 'Ανοξείδωτος χάλυβας - Χάραξη',
                        aluminum_engraving: 'Αλουμίνιο - Χάραξη',
                        black_acrylic_3mm_cutting: 'Μαύρο ακρυλικό - 2,5 χιλ. Κοπή',
                        black_acrylic_5mm_cutting: 'Μαύρο ακρυλικό - 5 χιλ. Κοπή',
                        black_acrylic_engraving: 'Μαύρο ακρυλικό - Χάραξη',
                        abs_engraving: 'ABS - Χάραξη',
                        silver_engraving: 'Ασήμι - Χάραξη',
                        iron_engraving: 'Σίδηρος - Χάραξη',
                        fabric_printing: 'Ύφασμα - Εκτύπωση',
                        canvas_printing: 'Καμβάς - Εκτύπωση',
                        cardstock_printing: 'Χαρτόνι - Εκτύπωση',
                        wood_printing: 'Ξύλο - Εκτύπωση',
                        bamboo_printing: 'Μπαμπού - Εκτύπωση',
                        cork_printing: 'Φελλός - Εκτύπωση',
                        flat_stone_printing: 'Επίπεδη Πέτρα - Εκτύπωση',
                        acrylic_printing: 'Aκρυλικό - Εκτύπωση',
                        pc_printing: 'Πολυκαρβονικό - Εκτύπωση',
                        stainless_steel_printing: 'Ανοξείδωτος Χάλυβας - Εκτύπωση',
                        gloss_leather_printing: 'Γυαλιστερό δέρμα - Εκτύπωση',
                        glass_printing: 'Γυαλί - Εκτύπωση',
                        aluminum_light: 'Αλουμίνιο (ανοιχτό)',
                        stainless_steel_dark: 'Ανοξείδωτο ατσάλι (σκούρο)',
                        stainless_steel_light: 'Ανοξείδωτο ατσάλι (ανοιχτό)',
                        brass_dark: 'Ορείχαλκος (σκούρο)',
                        brass_light: 'Ορείχαλκος (ανοιχτό)',
                        copper: 'Χαλκός',
                        titanium_dark: 'Τιτάνιο (σκούρο)',
                        titanium_light: 'Τιτάνιο (ανοιχτό)',
                        black_abs: 'Μαύρο ABS',
                        white_abs: 'Λευκό ABS',
                        opaque_acrylic: 'Αδιαφανές ακρυλικό',
                        stone: 'Πέτρα',
                    },
                },
                laser_speed: {
                    text: 'Ταχύτητα',
                    unit: 'mm/s',
                    fast: 'Γρήγορα',
                    slow: 'Αργά',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Ισχύς',
                    high: 'Υψηλή',
                    low: 'Χαμηλή',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Τύπος μελανιού',
                    normal: 'Μελάνι',
                    UV: 'UV μελάνι',
                },
                para_in_use: 'Αυτή η παράμετρος χρησιμοποιείται.',
                do_not_adjust_default_para: 'Οι προεπιλογές δεν μπορούν να τροποποιηθούν.',
                existing_name: 'Αυτό το όνομα έχει ήδη χρησιμοποιηθεί.',
                presets: 'Προεπιλογή',
                preset_management: {
                    preset: 'Προκαθορισμένο',
                    title: 'Διαχείριση Παραμέτρων',
                    add_new: 'Προσθήκη Νέου',
                    lower_focus_by: 'Μείωση Εστίασης κατά',
                    save_and_exit: 'Αποθήκευση και Έξοδος',
                    delete: 'Διαγραφή',
                    reset: 'Επαναφορά',
                    sure_to_reset: 'Αυτό θα διαγράψει τις εξατομικευμένες παραμέτρους σας και θα επαναφέρει όλες τις προεπιλογές. Είστε βέβαιοι ότι θέλετε να συνεχίσετε;',
                    show_all: 'Εμφάνιση Όλων',
                    laser: 'Λέιζερ',
                    print: 'Εκτύπωση',
                    export: 'Εξαγωγή',
                    export_preset_title: 'Εξαγωγή Προεπιλογών',
                    import: 'Εισαγωγή',
                    sure_to_import_presets: 'Αυτό θα φορτώσει τη διάταξη των προεπιλογών και θα αντικαταστήσει τις εξατομικευμένες παραμέτρους. Είστε βέβαιοι ότι θέλετε να συνεχίσετε;',
                    new_preset_name: 'Νέο Όνομα Προεπιλογής',
                },
            },
            object_panel: {
                zoom: 'Μεγέθυνση',
                group: 'Ομάδα',
                ungroup: 'Κατάργηση ομαδοποίησης',
                distribute: 'Διανομή',
                hdist: 'Οριζόντια διανομή',
                vdist: 'Κάθετη κατανομή',
                align: 'Ευθυγράμμιση',
                left_align: 'Ευθυγράμμιση αριστερά',
                center_align: 'Ευθυγράμμιση κέντρου',
                right_align: 'Ευθυγράμμιση δεξιά',
                top_align: 'Ευθυγράμμιση επάνω',
                middle_align: 'Ευθυγράμμιση μέσης',
                bottom_align: 'Ευθυγράμμιση κάτω',
                boolean: 'Λογική πράξη',
                union: 'Ένωση',
                subtract: 'Αφαίρεση',
                intersect: 'Τομή',
                difference: 'Διαφορά',
                flip: 'Αναστροφή',
                hflip: 'Οριζόντια αναστροφή',
                vflip: 'Κάθετη αναστροφή',
                lock_aspect: 'Κλείδωμα αναλογίας',
                unlock_aspect: 'Ξεκλείδωμα αναλογίας',
                option_panel: {
                    fill: 'Εσωτερική πλήρωση',
                    rounded_corner: 'Στρογγυλεμένη γωνία',
                    sides: 'Πλευρές',
                    font_family: 'Γραμματοσειρά',
                    font_style: 'Στυλ',
                    font_size: 'Μέγεθος',
                    letter_spacing: 'Διάστημα γραμμάτων',
                    line_spacing: 'Διάστημα γραμμών',
                    vertical_text: 'Κάθετο κείμενο',
                    start_offset: 'Μετατόπιση κειμένου',
                    vertical_align: 'Στοίχιση',
                    text_infill: 'Πλήρωση κειμένου',
                    path_infill: 'Πλήρωση διαδρομής',
                    shading: 'Διαβάθμιση',
                    pwm_engraving: 'Λειτουργία Βάθους',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Φωτεινότητα ορίου',
                    threshold_short: 'Κατώφλι',
                    stroke: 'Περίγραμμα',
                    stroke_color: 'Χρώμα περιγράμματος',
                    stroke_width: 'Πλάτος γραμμής',
                    color: 'Χρώμα',
                },
                actions_panel: {
                    replace_with: 'Αντικατάσταση με...',
                    replace_with_short: 'Αντικατάσταση',
                    trace: 'Ιχνηλάτηση',
                    grading: 'Βαθμονόμηση',
                    brightness: 'Φωτεινότητα',
                    sharpen: 'Εστίαση',
                    crop: 'Περικοπή',
                    bevel: 'Λοξοτομία',
                    invert: 'Αντιστροφή',
                    weld_text: 'Συγχώνευση κειμένου',
                    convert_to_path: 'Μετατροπή σε μονοπάτι',
                    fetching_web_font: 'Λήψη διαδικτυακής γραμματοσειράς...',
                    uploading_font_to_machine: 'Μεταφόρτωση γραμματοσειράς στο μηχάνημα...',
                    wait_for_parsing_font: 'Επεξεργασία γραμματοσειράς... Παρακαλώ περιμένετε',
                    offset: 'Μετατόπιση',
                    array: 'Πίνακας',
                    auto_fit: 'Αυτόματη Προσαρμογή',
                    smart_nest: 'Έξυπνη Τοποθέτηση',
                    decompose_path: 'Αποσύνθεση',
                    disassemble_use: 'Αποσυναρμολόγηση',
                    create_textpath: 'Δημιουργία κειμένου σε μονοπάτι',
                    create_textpath_short: 'Μονοπάτι κειμένου',
                    detach_path: 'Αποσύνθεση κειμένου από μονοπάτι',
                    detach_path_short: 'Αποσυνθέτηση',
                    edit_path: 'Επεξεργασία μονοπατιού',
                    disassembling: 'Αποσυναρμολόγηση...',
                    ungrouping: 'Κατάργηση ομαδοποίησης...',
                    simplify: 'Απλοποίηση',
                    ai_bg_removal: 'Αφαίρεση φόντου',
                    ai_bg_removal_short: 'Αφαίρεση φόντου',
                    ai_bg_removal_reminder: 'Πατώντας το κουμπί θα χρησιμοποιηθούν αμέσως 0,2 πιστώσεις, θέλετε να συνεχίσετε;',
                    outline: 'Περίγραμμα',
                },
                path_edit_panel: {
                    node_type: 'ΤΥΠΟΣ ΚΟΜΒΟΥ',
                    sharp: 'Αιχμηρό',
                    round: 'Στρογγυλό',
                    connect: 'Συνδέστε',
                    disconnect: 'Αποσυνδέστε',
                    delete: 'Διαγράψτε',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Μετατρέψτε το κείμενο σε μονοπάτι πριν από την εξαγωγή',
            retreive_image_data: 'Ανάκτηση δεδομένων εικόνας...',
            export_file_error_ask_for_upload: 'Αποτυχία εξαγωγής εργασίας. Είστε πρόθυμοι να παρέχετε τη σκηνή εργασίας στην ομάδα ανάπτυξης για αναφορά σφαλμάτων;',
        },
        image_trace_panel: {
            apply: 'Εφαρμογή',
            back: 'Πίσω',
            cancel: 'Ακύρωση',
            next: 'Επόμενο',
            brightness: 'Φωτεινότητα',
            contrast: 'Αντίθεση',
            threshold: 'Κατώφλι',
            okay: 'Εντάξει',
            tuning: 'Ρυθμίσεις',
        },
        photo_edit_panel: {
            apply: 'Εφαρμογή',
            back: 'Πίσω',
            cancel: 'Ακύρωση',
            next: 'Επόμενο',
            sharpen: 'Εστίαση',
            sharpness: 'Ευκρίνεια',
            radius: 'Ακτίνα',
            crop: 'Περικοπή',
            aspect_ratio: 'Αναλογία διαστάσεων',
            original: 'Αρχικό',
            free: 'Ελεύθερο',
            curve: 'Καμπύλη',
            start: 'Έναρξη',
            processing: 'Επεξεργασία',
            invert: 'Αντιστροφή χρωμάτων',
            okay: 'Εντάξει',
            compare: 'Σύγκριση',
            phote_edit: 'Επεξεργασία φωτογραφίας',
            brightness_and_contrast: 'Φωτεινότητα / Αντίθεση',
            brightness: 'Φωτεινότητα',
            contrast: 'Αντίθεση',
            rotary_warped: 'Περιστροφική Παραμόρφωση',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Διάμετρος',
            circumference: 'Περίμετρος',
            warp: 'Παραμόρφωση',
        },
        document_panel: {
            document_settings: 'Ρυθμίσεις εγγράφου',
            machine: 'Μηχανή',
            laser_source: 'Πηγή λέιζερ',
            workarea: 'Περιοχή εργασίας',
            rotary_mode: 'Περιστροφική λειτουργία',
            borderless_mode: 'Ανοιχτό κάτω μέρος',
            engrave_dpi: 'Ανάλυση',
            enable_diode: 'Λέιζερ διόδου',
            enable_autofocus: 'Αυτόματη εστίαση',
            extend_workarea: 'Επέκταση περιοχής εργασίας',
            mirror: 'Κατοπτρισμός',
            pass_through: 'Διέλευση',
            pass_through_height_desc: 'Εισαγάγετε το μήκος του αντικειμένου για να επεκτείνετε την περιοχή εργασίας.',
            start_position: 'Θέση Έναρξης',
            start_from: 'Ξεκινάει Από',
            origin: 'Αρχή',
            current_position: 'Τρέχουσα Θέση',
            job_origin: 'Αφετηρία Εργασίας',
            add_on: 'Πρόσθετες λειτουργίες',
            low: 'Χαμηλή',
            medium: 'Μέτρια',
            high: 'Υψηλή',
            ultra: 'Πολύ υψηλή',
            enable: 'Ενεργοποίηση',
            disable: 'Απενεργοποίηση',
            notification: {
                changeFromPrintingWorkareaTitle: 'Θέλετε να μετατρέψετε τα επίπεδα εκτύπωσης σε επίπεδα λέιζερ;',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Ανάλυση γραμματοσειράς... Παρακαλώ περιμένετε ένα δευτερόλεπτο',
            text_to_path: {
                font_substitute_pop: 'Το κείμενό σας περιέχει χαρακτήρες που δεν υποστηρίζονται από την τρέχουσα γραμματοσειρά. <br/>Θα θέλατε να χρησιμοποιήσετε το <strong>%s</strong> ως υποκατάστατο;',
                check_thumbnail_warning: 'Ορισμένα κείμενα άλλαξαν σε άλλες γραμματοσειρές κατά τη μετατροπή κειμένων σε διαδρομές και ορισμένοι χαρακτήρες ενδέχεται να μην μετατραπούν κανονικά. \nΕλέγξτε ξανά την προεπισκόπηση εικόνας πριν στείλετε την εργασία.',
                error_when_parsing_text: 'Σφάλμα κατά τη μετατροπή κειμένου σε διαδρομή',
                use_current_font: 'Χρήση Τρέχουσας Γραμματοσειράς',
                retry: 'Παρακαλώ δοκιμάστε αργότερα ή επιλέξτε άλλη γραμματοσειρά',
            },
            lock_desc: 'Διατήρηση του λόγου πλάτους και ύψους (SHIFT)',
        },
        tool_panels: {
            cancel: 'Ακύρωση',
            confirm: 'Επιβεβαίωση',
            grid_array: 'Δημιουργία Πλέγματος Πίνακα',
            array_dimension: 'Διάσταση Πίνακα',
            rows: 'Γραμμές',
            columns: 'Στήλες',
            array_interval: 'Διάστημα Πίνακα',
            dx: 'X',
            dy: 'Y',
            offset: 'Μετατόπιση',
            nest: 'Βελτιστοποίηση διάταξης',
            _offset: {
                direction: 'Κατεύθυνση μετατόπισης',
                inward: 'Προς τα μέσα',
                outward: 'Προς τα έξω',
                dist: 'Απόσταση μετατόπισης',
                corner_type: 'Γωνία',
                sharp: 'Αιχμηρή',
                round: 'Στρογγυλή',
                fail_message: 'Αποτυχία μετατόπισης αντικειμένων.',
                not_support_message: 'Τα επιλεγμένα στοιχεία περιέχουν μη υποστηριζόμενες ετικέτες SVG:\nΕικόνα, Ομάδα, Κείμενο και Εισαγόμενο Αντικείμενο.',
            },
            _nest: {
                start_nest: 'Τοποθέτηση',
                stop_nest: 'Σταμάτημα',
                end: 'Κλείσιμο',
                spacing: 'Απόσταση',
                rotations: 'Πιθανή Περιστροφή',
                no_element: 'Δεν υπάρχουν στοιχεία για τοποθέτηση.',
            },
        },
        network_testing_panel: {
            network_testing: 'Δοκιμή Δικτύου',
            local_ip: 'Τοπική Διεύθυνση IP:',
            insert_ip: 'Διεύθυνση IP προορισμού:',
            empty_ip: '#818 Παρακαλώ εισάγετε πρώτα τη διεύθυνση IP της συσκευής προορισμού.',
            start: 'Έναρξη',
            end: 'Τέλος',
            testing: 'Έλεγχος Δικτύου...',
            invalid_ip: '#818 Μη έγκυρη διεύθυνση IP',
            ip_startswith_169: '#843 Η διεύθυνση IP του μηχανήματος ξεκινά με 169.254',
            connection_quality: 'Ποιότητα σύνδεσης',
            average_response: 'Μέσος χρόνος απόκρισης',
            test_completed: 'Ολοκλήρωση δοκιμής',
            test_fail: 'Αποτυχία δοκιμής',
            cannot_connect_1: '#840 Αποτυχία σύνδεσης στην IP διεύθυνση-στόχο.',
            cannot_connect_2: '#840 Αποτυχία σύνδεσης στην IP διεύθυνση-στόχο. Παρακαλώ βεβαιωθείτε ότι ο στόχος βρίσκεται στο ίδιο δίκτυο.',
            network_unhealthy: '#841 Ποιότητα σύνδεσης <70 ή μέσος χρόνος απόκρισης >100ms',
            device_not_on_list: '#842 Το μηχάνημα δεν βρίσκεται στη λίστα, αλλά η ποιότητα σύνδεσης είναι >70 και ο μέσος χρόνος απόκρισης είναι <100ms',
            hint_device_often_on_list: 'Το μηχάνημα συχνά δεν βρίσκεται στη λίστα;',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Αποτυχία σύνδεσης κατά την αποστολή εργασίας;',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Χρονικό όριο συμβαίνει κατά την εκκίνηση της προεπισκόπησης κάμερας;',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Αποτυχία πρόσβασης στην τοπική διεύθυνση IP.',
            fail_to_start_network_test: '#817 Αποτυχία έναρξης δοκιμής δικτύου.',
            linux_permission_hint: 'Αυτό το σφάλμα συνήθως προκύπτει λόγω ανεπαρκών δικαιωμάτων. \nΕυγενικά εκτελέστε "sudo beam-studio --no-sandbox" στο τερματικό για να λάβετε άδειες και να εκτελέσετε δοκιμή δικτύου.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Διαμορφώσεις χρωμάτων στρώσης',
            color: 'Χρώμα',
            power: 'Ισχύς',
            speed: 'Ταχύτητα',
            repeat: 'Επανάληψη',
            add: 'Προσθήκη',
            default: 'Επαναφορά στις προεπιλογές',
            add_config: 'Προσθήκη χρώματος',
            in_use: 'Αυτό το χρώμα χρησιμοποιείται.',
            no_input: 'Παρακαλώ εισάγετε έγκυρο κωδικό χρώματος δεκαεξαδικού.',
            sure_to_reset: 'Θα χαθούν όλες οι προσαρμοσμένες ρυθμίσεις. Είστε σίγουροι ότι θέλετε επαναφορά στις προεπιλογές;',
            sure_to_delete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη ρύθμιση χρώματος;',
        },
        rating_panel: {
            title: 'Απολαμβάνετε το Beam Studio;',
            description: 'Αν σας αρέσει το Beam Studio, θα εκτιμούσαμε πολύ την αξιολόγησή σας.',
            dont_show_again: 'Να μην εμφανιστεί ξανά την επόμενη φορά.',
            thank_you: 'Ευχαριστώ!',
        },
        svg_editor: {
            unnsupported_file_type: 'Ο τύπος αρχείου δεν υποστηρίζεται. Μετατρέψτε το αρχείο σε SVG ή bitmap',
            unable_to_fetch_clipboard_img: 'Αποτυχία λήψης εικόνας από το πρόχειρο',
        },
        units: {
            walt: 'W',
            mm: 'χιλιοστόμετρα',
        },
        path_preview: {
            play: 'Αναπαραγωγή',
            pause: 'Παύση',
            stop: 'Σταμάτημα',
            play_speed: 'Ταχύτητα αναπαραγωγής',
            travel_path: 'Διαδρομή μετακίνησης',
            invert: 'Αντιστροφή',
            preview_info: 'Πληροφορίες προεπισκόπησης',
            size: 'Μέγεθος',
            estimated_time: 'Συνολικός εκτιμώμενος χρόνος',
            cut_time: 'Χρόνος κοπής',
            rapid_time: 'Χρόνος μετακίνησης',
            cut_distance: 'Απόσταση κοπής',
            rapid_distance: 'Απόσταση μετακίνησης',
            current_position: 'Τρέχουσα θέση',
            remark: '* Όλες οι πληροφορίες είναι εκτιμήσεις για αναφορά.',
            start_here: 'Ξεκινήστε εδώ',
            end_preview: 'Τέλος προεπισκόπησης',
        },
        shapes_panel: {
            title: 'Σχήματα',
            basic: 'Βασικά',
            shape: 'Σχήμα',
            graphics: 'Γραφικά',
            arrow: 'Βέλος',
            label: 'Ετικέτα',
            decor: 'Διακόσμηση',
            circular: 'Κυκλικό',
            corner: 'Γωνία',
            line: 'Γραμμή',
            photo: 'Φωτογραφία',
            ribbon: 'Κορδέλα',
            speech: 'Ομιλία',
            text: 'Πλαίσιο κειμένου',
            animals: 'Ζώο',
            birds: 'Πουλί',
            land: 'Ξηρά',
            sea: 'Θάλασσα',
            holidays: 'Αργίες',
            celebration: 'Εορτασμός',
            CNY: 'Κινεζική Πρωτοχρονιά',
            easter: 'Πάσχα',
            halloween: 'Απόκριες',
            valentines: 'Αγίου Βαλεντίνου',
            Xmas: 'Χριστούγεννα',
            nature: 'Φύση',
            elements: 'Στοιχεία',
            environment: 'Περιβάλλον',
            plants: 'Φυτά',
            weather: 'Καιρός',
        },
        announcement_panel: {
            title: 'Ανακοίνωση',
            dont_show_again: 'Να μην εμφανιστεί ξανά',
        },
    },
    editor: {
        prespray_area: 'Περιοχή προετοιμασίας',
        opacity: 'Προεπισκόπηση Αδιαφάνειας',
        exposure: 'Προεπισκόπηση έκθεσης',
    },
    flux_id_login: {
        connection_fail: '#847 Αποτυχία σύνδεσης στην υπηρεσία μέλους FLUX.',
        login_success: 'Επιτυχής σύνδεση.',
        login: 'Σύνδεση',
        unlock_shape_library: 'Συνδεθείτε για να ξεκλειδώσετε τη βάση δεδομένων σχημάτων.',
        email: 'Email',
        password: 'Κωδικός πρόσβασης',
        remember_me: 'Να με θυμάσαι',
        forget_password: 'Ξεχάσατε τον κωδικό σας;',
        register: 'Δημιουργήστε τον λογαριασμό σας FLUX',
        offline: 'Εργασία εκτός σύνδεσης',
        work_offline: 'Εργασία εκτός σύνδεσης',
        incorrect: 'Το email ή ο κωδικός πρόσβασης δεν είναι σωστά.',
        not_verified: 'Το email δεν έχει επιβεβαιωθεί ακόμα.',
        new_to_flux: 'Νέος στο FLUX; Δημιουργήστε έναν λογαριασμό.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Εξερευνήστε τα σχέδια FLUX+',
            thank_you: 'Σας ευχαριστούμε που είστε τιμημένο μέλος!',
            ai_credit_tooltip: 'Για αφαίρεση φόντου με τεχνητή νοημοσύνη',
            flux_credit_tooltip: 'Για αρχεία Αγοράς Σχεδίων και αφαίρεση φόντου με τεχνητή νοημοσύνη',
            goto_member_center: 'Μεταβείτε στο Κέντρο Μελών',
            access_plus_feature_1: 'Έχετε πρόσβαση σε μια',
            access_plus_feature_2: 'λειτουργία.',
            access_plus_feature_note: 'Πρέπει να έχετε συνδρομή FLUX+ για να έχετε πρόσβαση σε αυτή τη λειτουργία.',
            access_monotype_feature: 'Δεν έχετε το πρόσθετο γραμματοσειρών Monotype.',
            access_monotype_feature_note: 'Πρέπει να έχετε συνδρομή FLUX+ Pro ή το πρόσθετο γραμματοσειρών Monotype για να έχετε πρόσβαση σε αυτή τη λειτουργία.',
            learn_more: 'Μάθετε περισσότερα',
            get_addon: 'Αποκτήστε το πρόσθετο',
            subscribe_now: 'Εγγραφείτε τώρα',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Αφαίρεση φόντου με τεχνητή νοημοσύνη',
                my_cloud: 'Απεριόριστη αποθήκευση στο cloud',
                boxgen: 'Γεννήτρια 3D κουτιών',
                dmkt: '1000+ αρχεία σχεδίασης',
                monotype: '250+ προπληρωμένες γραμματοσειρές',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Συνδεθείτε για να ξεκλειδώσετε τη βάση δεδομένων σχημάτων.',
        enjoy_shape_library: 'Απολαύστε τη βάση δεδομένων σχημάτων.',
        shapes: 'Σχήματα',
        elements: 'Στοιχεία',
        recent: 'Πρόσφατα',
        search: 'Αναζήτηση',
        clear: 'Καθαρισμός',
        export_svg_title: 'Αδυναμία εξαγωγής SVG',
        export_svg_warning: 'Αυτό το έργο περιέχει αντικείμενα που προστατεύονται από το νόμο περί πνευματικής ιδιοκτησίας. Επομένως, το Beam Studio θα εξαιρέσει αυτόματα αυτά τα αντικείμενα κατά την εξαγωγή. Μπορείτε ακόμα να αποθηκεύσετε το έργο σας σε μορφή Beam Studio Scene (.beam) για να διατηρήσετε όλα τα δεδομένα σας. Θέλετε ακόμα να εξαγάγετε;',
        learn_more: 'Μάθετε περισσότερα',
    },
    change_logs: {
        change_log: 'Αλλαγές:',
        added: 'Προστέθηκαν:',
        fixed: 'Διορθώθηκαν:',
        changed: 'Άλλαξαν:',
        see_older_version: 'Δείτε παλαιότερες εκδόσεις',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Επιλέξτε συσκευή',
        auth_failure: '#811 Αποτυχία πιστοποίησης',
        unable_to_connect: '#810 Αδυναμία δημιουργίας σταθερής σύνδεσης με τη μηχανή',
    },
    device: {
        pause: 'Παύση',
        paused: 'Σε παύση',
        pausing: 'Παύει',
        select_printer: 'Επιλέξτε εκτυπωτή',
        retry: 'Επανάληψη',
        status: 'Κατάσταση',
        busy: 'Απασχολημένος',
        ready: 'Έτοιμος',
        reset: 'Επαναφορά',
        abort: 'Ακύρωση',
        start: 'Εκκίνηση',
        please_wait: 'Παρακαλώ περιμένετε...',
        quit: 'Έξοδος',
        completing: 'Ολοκλήρωση',
        aborted: 'Ακυρώθηκε',
        completed: 'Ολοκληρώθηκε',
        aborting: 'Ακύρωση',
        starting: 'Εκκίνηση',
        preparing: 'Προετοιμασία',
        resuming: 'Συνέχιση',
        scanning: 'Σάρωση',
        occupied: 'Συντήρηση',
        running: 'Λειτουργία',
        uploading: 'Μεταφόρτωση',
        processing: 'Επεξεργασία',
        disconnectedError: {
            caption: 'Η σύνδεση της συσκευής αποσυνδέθηκε',
            message: 'Παρακαλώ επιβεβαιώστε αν η πρόσβαση δικτύου του %s είναι διαθέσιμη',
        },
        unknown: 'Άγνωστη',
        pausedFromError: 'Σε παύση λόγω σφάλματος',
        model_name: 'Μοντέλο',
        IP: 'Διεύθυνση IP',
        serial_number: 'Σειριακός αριθμός',
        firmware_version: 'Έκδοση υλικολογισμικού',
        UUID: 'UUID',
        select: 'Επιλογή',
        deviceList: 'Λίστα συσκευών',
        disable: 'Απενεργοποίηση',
        enable: 'Ενεργοποίηση',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: 'Αλλαγή κεφαλής εργαλείου',
    },
    monitor: {
        monitor: 'Παρακολούθηση',
        go: 'Έναρξη',
        resume: 'Συνέχεια',
        start: 'Έναρξη',
        pause: 'Παύση',
        stop: 'Διακοπή',
        record: 'Εγγραφή',
        camera: 'Κάμερα',
        taskTab: 'Εργασία',
        connecting: 'Σύνδεση, παρακαλώ περιμένετε...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Κρίσιμο σφάλμα: Το κύριο τμήμα εκτός σύνδεσης. Παρακαλούμε επικοινωνήστε με την υποστήριξη.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Κρίσιμο σφάλμα: Το υποσύστημα δεν απαντά. Παρακαλούμε επικοινωνήστε με την υποστήριξη.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Παρακαλώ ελέγξτε τη δεξαμενή νερού σας.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Κλείστε την πόρτα για να συνεχίσετε.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Υπερθερμάνθηκε. Παρακαλώ περιμένετε λίγα λεπτά.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Το κάτω μέρος άνοιξε. Κλείστε το κάτω μέρος για να συνεχίσετε.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Αποτυχία επαναφοράς άξονα z',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Το συρτάρι άνοιξε',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Ανώμαλος αισθητήρας φλόγας',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Αισθητήρας ροής αέρα ανώμαλος',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Δεν εντοπίστηκε μονάδα περιστροφής',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Σφάλμα Ομαλής Εκκίνησης',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Εντοπίστηκε λανθασμένη κεφαλή. Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Δεν εντοπίστηκε κεφαλή. Παρακαλώ βεβαιωθείτε ότι η κεφαλή έχει εγκατασταθεί σωστά για να συνεχίσετε.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Εντοπίστηκε άγνωστη κεφαλή. Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Καμία απόκριση από τη μονάδα εκτύπωσης.',
        USER_OPERATION_ROTARY_PAUSE: 'Παρακαλώ αλλάξτε στον περιστροφικό κινητήρα.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Παρακαλώ αφαιρέστε την κασέτα για να συνεχίσετε.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Εντοπίστηκε λανθασμένη κεφαλή. Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Δεν εντοπίστηκε κεφαλή. Παρακαλώ βεβαιωθείτε ότι η κεφαλή έχει εγκατασταθεί σωστά για να συνεχίσετε.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Εντοπίστηκε άγνωστη κεφαλή. Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Παρακαλούμε εισάγετε την κάρτα για να συνεχίσετε.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Παρακαλούμε εισάγετε τη σωστή κάρτα για να συνεχίσετε.',
        USER_OPERATION: 'Ακολουθήστε τις οδηγίες στο πίνακα της συσκευής για να συνεχίσετε.',
        RESOURCE_BUSY: 'Το μηχάνημα είναι απασχολημένο\\nΑν δεν λειτουργεί, παρακαλώ επανεκκινήστε το μηχάνημα',
        DEVICE_ERROR: 'Κάτι πήγε στραβά\\nΠαρακαλώ επανεκκινήστε το μηχάνημα',
        NO_RESPONSE: '#905 Σφάλμα κατά τη σύνδεση με την κεντρική πλακέτα.\\nΠαρακαλώ επανεκκινήστε το μηχάνημα.',
        SUBSYSTEM_ERROR: '#402 Κρίσιμο Σφάλμα: Καμία απόκριση υποσυστήματος. Παρακαλώ επικοινωνήστε με την υποστήριξη.',
        HARDWARE_FAILURE: 'Κάτι πήγε στραβά. Παρακαλώ επανεκκινήστε το μηχάνημα',
        MAINBOARD_OFFLINE: '#905 Σφάλμα κατά τη σύνδεση με την κεντρική πλακέτα. Παρακαλώ επανεκκινήστε το μηχάνημα.',
        bug_report: 'Αναφορά Σφαλμάτων',
        processing: 'Επεξεργασία',
        savingPreview: 'Δημιουργία προεπισκοπήσεων',
        hour: 'ώ',
        minute: 'λ',
        second: 'δ',
        left: 'αριστερά',
        temperature: 'Θερμοκρασία',
        forceStop: 'Θέλετε να ακυρώσετε την τρέχουσα εργασία;',
        upload: 'Μεταφόρτωση',
        download: 'Λήψη',
        relocate: 'Μετεγκατάσταση',
        cancel: 'Ακύρωση',
        prepareRelocate: 'Προετοιμασία για μετεγκατάσταση',
        extensionNotSupported: 'Αυτή η μορφή αρχείου δεν υποστηρίζεται',
        fileExistContinue: 'Το αρχείο υπάρχει ήδη, θέλετε να το αντικαταστήσετε;',
        confirmFileDelete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο;',
        ask_reconnect: 'Η σύνδεση με τη μηχανή διακόπηκε. Θέλετε να συνδεθείτε ξανά;',
        task: {
            BEAMBOX: 'Χάραξη με λέιζερ',
            'N/A': 'Ελεύθερη λειτουργία',
        },
    },
    alert: {
        caption: 'Σφάλμα',
        duplicated_preset_name: 'Όνομα προεπιλογής υπάρχει ήδη',
        info: 'ΠΛΗΡΟΦΟΡΙΕΣ',
        warning: 'ΠΡΟΕΙΔΟΠΟΙΗΣΗ',
        error: 'Σφάλμα',
        instruction: 'Οδηγία',
        oops: 'Ωχ...',
        retry: 'Επανάληψη',
        abort: 'Ακύρωση',
        confirm: 'Επιβεβαίωση',
        cancel: 'Ακύρωση',
        close: 'Κλείσιμο',
        ok: 'Εντάξει',
        ok2: 'Εντάξει',
        yes: 'Ναι',
        no: 'Όχι',
        stop: 'Σταματήστε',
        save: 'Αποθήκευση',
        dont_save: 'Μην αποθηκεύσετε',
        learn_more: 'Μάθετε περισσότερα',
    },
    caption: {
        connectionTimeout: 'Λήξη χρονικού ορίου σύνδεσης',
    },
    message: {
        cancelling: 'Ακύρωση...',
        connecting: 'Σύνδεση...',
        connectingMachine: 'Σύνδεση με %s...',
        tryingToConenctMachine: 'Προσπάθεια σύνδεσης με το μηχάνημα...',
        connected: 'Συνδέθηκε',
        authenticating: 'Πιστοποίηση σε εξέλιξη...',
        enteringRawMode: 'Είσοδος σε ακατέργαστη λειτουργία...',
        endingRawMode: 'Έξοδος από ακατέργαστη λειτουργία...',
        enteringLineCheckMode: 'Είσοδος σε λειτουργία ελέγχου γραμμής...',
        endingLineCheckMode: 'Έξοδος από τη λειτουργία ελέγχου γραμμής...',
        enteringRedLaserMeasureMode: 'Είσοδος σε λειτουργία μέτρησης με κόκκινο λέιζερ...',
        redLaserTakingReference: 'Λήψη αναφοράς...',
        exitingRotaryMode: 'Έξοδος από τη λειτουργία περιστροφής...',
        turningOffFan: 'Απενεργοποίηση ανεμιστήρα...',
        turningOffAirPump: 'Απενεργοποίηση αντλίας αέρα...',
        gettingLaserSpeed: 'Ανάγνωση ταχύτητας κεφαλής λέιζερ...',
        settingLaserSpeed: 'Ρύθμιση ταχύτητας κεφαλής λέιζερ...',
        retrievingCameraOffset: 'Ανάγνωση μετατόπισης κάμερας...',
        connectingCamera: 'Σύνδεση κάμερας...',
        homing: 'Ομοιοποίηση...',
        connectionTimeout: '#805 Χρονικό όριο σύνδεσης συσκευής. Ελέγξτε την κατάσταση του δικτύου σας και τον δείκτη Wi-Fi του μηχανήματός σας.',
        getProbePosition: 'Λήψη θέσης Αισθητήρα...',
        device_not_found: {
            caption: 'Δεν βρέθηκε προεπιλεγμένη συσκευή',
            message: '#812 Ελέγξτε την ένδειξη Wi-Fi της συσκευής σας',
        },
        device_busy: {
            caption: 'Η συσκευή είναι απασχολημένη',
            message: 'Η συσκευή εκτελεί άλλη εργασία, δοκιμάστε αργότερα. Αν σταματήσει να λειτουργεί, επανεκκινήστε τη συσκευή.',
        },
        device_is_used: 'Η συσκευή χρησιμοποιείται, θέλετε να ακυρώσετε την τρέχουσα εργασία;',
        unknown_error: '#821 Η εφαρμογή συνάντησε άγνωστο σφάλμα, χρησιμοποιήστε Βοήθεια > Μενού > Αναφορά σφαλμάτων.',
        unknown_device: '#826 Δεν είναι δυνατή η σύνδεση με τη συσκευή, βεβαιωθείτε ότι το USB είναι συνδεδεμένο με τη συσκευή',
        unsupport_osx_version: 'Η τρέχουσα έκδοση macOS %s ενδέχεται να μην υποστηρίζει ορισμένες λειτουργίες. Ενημερώστε παρακαλώ σε macOS 11+.',
        unsupport_win_version: 'Η τρέχουσα έκδοση λειτουργικού συστήματος %s ενδέχεται να μην υποστηρίζει ορισμένες λειτουργίες. Ενημερώστε παρακαλώ στην πιο πρόσφατη έκδοση.',
        need_password: 'Απαιτείται κωδικός πρόσβασης για σύνδεση με τη συσκευή',
        unavailableWorkarea: '#804 Η τρέχουσα περιοχή εργασίας υπερβαίνει την περιοχή εργασίας αυτής της μηχανής. Παρακαλώ ελέγξτε την περιοχή εργασίας της επιλεγμένης μηχανής ή ορίστε την περιοχή εργασίας από Επεξεργασία > Ρυθμίσεις εγγράφου.',
        please_enter_dpi: 'Παρακαλώ εισάγετε τη μονάδα του αρχείου σας (σε mm)',
        auth_error: '#820 Σφάλμα έγκρισης: Παρακαλώ ενημερώστε το Beam Studio και το υλικολογισμικό της μηχανής στην πιο πρόσφατη έκδοση.',
        usb_unplugged: 'Η σύνδεση USB έχει χαθεί. Παρακαλώ ελέγξτε τη σύνδεση USB σας',
        uploading_fcode: 'Μεταφόρτωση FCode',
        cant_connect_to_device: '#827 Αδυναμία σύνδεσης με τη μηχανή, παρακαλώ ελέγξτε τη σύνδεσή σας',
        unable_to_find_machine: 'Αδυναμία εύρεσης μηχανής',
        disconnected: 'Ασταθής σύνδεση, παρακαλώ ελέγξτε τη σύνδεση της συσκευής και δοκιμάστε ξανά αργότερα',
        unable_to_start: '#830 Αδυναμία έναρξης της εργασίας. Παρακαλώ δοκιμάστε ξανά. Αν συμβεί ξανά, επικοινωνήστε μαζί μας με αναφορά σφαλμάτων:',
        camera: {
            camera_cable_unstable: 'Ανιχνεύθηκε ασταθής μετάδοση εικόνας από την κάμερα. Η προεπισκόπηση μπορεί ακόμα να εκτελεστεί κανονικά, αλλά μπορεί να υπάρχει πρόβλημα αργής προεπισκόπησης ή λήξης χρονικού ορίου.',
            fail_to_transmit_image: '#845 Κάτι πήγε στραβά με τη μετάδοση εικόνας. Παρακαλώ επανεκκινήστε το μηχάνημά σας ή το Beam Studio. Αν αυτό το σφάλμα επιμένει, ακολουθήστε τον οδηγό.',
            ws_closed_unexpectly: '#844 Η σύνδεση με την κάμερα του μηχανήματος έχει κλείσει απροσδόκητα. Αν αυτό το σφάλμα επιμένει, ακολουθήστε τον οδηγό.',
            continue_preview: 'Συνέχεια',
            abort_preview: 'Ακύρωση',
        },
        preview: {
            camera_preview: 'Προεπισκόπηση κάμερας',
            auto_focus: 'Αυτόματη εστίαση',
            auto_focus_instruction: 'Παρακαλώ μετακινήστε το κεφάλι του λέιζερ πάνω από το αντικείμενο και ακολουθήστε τις οδηγίες κίνησης για να πατήσετε AF για να εστιάσετε.',
            already_performed_auto_focus: 'Έχετε ήδη εκτελέσει αυτόματη εστίαση, να χρησιμοποιήσετε τις υπάρχουσες ρυθμίσεις;',
            please_enter_height: 'Παρακαλώ εισάγετε το ύψος του αντικειμένου για ακριβή λήψη κάμερας.',
            apply: 'Εφαρμογή',
            enter_manually: 'Εισαγωγή χειροκίνητα',
            adjust: 'Προσαρμογή',
            adjust_height_tooltip: 'Κάντε κλικ στο πλαίσιο ελέγχου για να ενεργοποιήσετε την επεξεργασία.',
        },
        unsupported_example_file: 'Το επιλεγμένο παράδειγμα αρχείου δεν υποστηρίζεται από την τρέχουσα περιοχή εργασίας.',
        time_remaining: 'Υπολειπόμενος χρόνος:',
        promark_disconnected: '#850 Η σύνδεση με τη συσκευή διακόπηκε, ελέγξτε την κατάσταση σύνδεσης της συσκευής.',
        swiftray_disconnected: 'Δεν είναι δυνατή η σύνδεση με το backend, προσπάθεια επανασύνδεσης.',
        swiftray_reconnected: 'Το backend επανασυνδέθηκε, δοκιμάστε να στείλετε ξανά την εργασία.',
        device_blocked: {
            caption: 'Μη εξουσιοδοτημένος αριθμός σειράς',
            online: 'Ο σειριακός αριθμός της συσκευής σας φαίνεται να είναι απενεργοποιημένος. Παρακαλούμε να δώσετε τον σειριακό αριθμό της συσκευής (που βρίσκεται στο πίσω μέρος της συσκευής) στον έμπορό σας και να τους ζητήσετε να επικοινωνήσουν με το support@flux3dp.com για να ενεργοποιηθεί η εξουσιοδότηση της συσκευής.',
            offline: 'Ο σειριακός αριθμός της συσκευής σας περιορίζεται στη χρήση εκτός σύνδεσης καθώς φαίνεται να είναι απενεργοποιημένος. Παρακαλούμε να δώσετε τον σειριακό αριθμό της συσκευής (που βρίσκεται στο πίσω μέρος της συσκευής) στον έμπορό σας και να τους ζητήσετε να επικοινωνήσουν με το support@flux3dp.com για να ενεργοποιηθεί η εξουσιοδότηση σύνδεσης. Εάν θέλετε να χρησιμοποιήσετε τη συσκευή εκτός σύνδεσης, επικοινωνήστε απευθείας με το support@flux3dp.com.',
        },
    },
    machine_status: {
        '0': 'Έτοιμο',
        '1': 'Εκτελείται',
        '2': 'Παύση',
        '4': 'Σε παύση',
        '6': 'Σε παύση',
        '16': 'Σε παύση',
        '18': 'Σε παύση',
        '32': 'Σε παύση',
        '36': 'Σε παύση',
        '38': 'Παύση',
        '48': 'Σε παύση',
        '50': 'Παύση',
        '64': 'Ολοκληρώθηκε',
        '66': 'Ολοκλήρωση',
        '68': 'Προετοιμασία',
        '128': 'Ακυρώθηκε',
        '256': 'Συναγερμός',
        '512': 'Θανατηφόρο',
        '-17': 'Λειτουργία κασέτας',
        '-10': 'Λειτουργία συντήρησης',
        '-2': 'Σάρωση',
        '-1': 'Συντήρηση',
        UNKNOWN: 'Άγνωστο',
    },
    calibration: {
        update_firmware_msg1: 'Το υλικολογισμικό σας δεν υποστηρίζει αυτήν τη λειτουργία. Ενημερώστε το υλικολογισμικό σε v',
        update_firmware_msg2: 'ή νεότερη έκδοση για να συνεχίσετε. (Μενού > Μηχάνημα > [Το μηχάνημά σας] > Ενημέρωση λογισμικού)',
        camera_calibration: 'Βαθμονόμηση κάμερας',
        diode_calibration: 'Βαθμονόμηση διόδου λέιζερ',
        module_calibration_printer: 'Βαθμονόμηση μονάδας εκτυπωτή',
        module_calibration_2w_ir: 'Βαθμονόμηση υπέρυθρης μονάδας',
        back: 'Πiσω',
        next: 'Επόμενο',
        skip: 'Παράλειψη',
        cancel: 'Ακύρωση',
        finish: 'Τέλος',
        do_engraving: 'Κάντε χάραξη',
        start_engrave: 'Ξεκινήστε τη χάραξη',
        start_printing: 'Ξεκινήστε την εκτύπωση',
        ask_for_readjust: 'Θέλετε να παραλείψετε το βήμα χάραξης και στη συνέχεια να τραβήξετε φωτογραφία και να βαθμονομήσετε απευθείας;',
        please_goto_beambox_first: 'Παρακαλώ μεταβείτε πρώτα στη λειτουργία χάραξης (Beambox) προκειμένου να χρησιμοποιήσετε αυτήν τη δυνατότητα.',
        please_place_paper: 'Παρακαλώ τοποθετήστε ένα λευκό χαρτί μεγέθους Α4 ή Letter στην αριστερή επάνω γωνία της περιοχής εργασίας.',
        please_place_paper_center: 'Παρακαλώ τοποθετήστε ένα λευκό χαρτί μεγέθους Α4 ή Letter στο κέντρο της περιοχής εργασίας.',
        please_place_dark_colored_paper: 'Παρακαλώ τοποθετήστε ένα σκουρόχρωμο χαρτί μεγέθους Α4 ή Letter στο κέντρο της περιοχής εργασίας.',
        please_refocus: {
            beambox: 'Παρακαλώ ρυθμίστε την πλατφόρμα στο εστιακό σημείο (το ύψος του ανεστραμμένου ακρυλικού)',
            beamo: 'Παρακαλώ ρυθμίστε το λέιζερ κεφαλής για να εστιάσει στο αντικείμενο χάραξης (το ύψος του ανεστραμμένου ακρυλικού)',
            beamo_af: 'Παρακαλώ κάντε διπλό πάτημα στο πλάι κουμπί της προσθήκης αυτόματης εστίασης και αφήστε τον αισθητήρα να αγγίξει απαλά το υλικό.',
            hexa: 'Κάντε διπλό κλικ στο κουμπί ρύθμισης ύψους για να ανεβάσετε το τραπέζι μελισσών πάνω και να κάνετε τον αισθητήρα να αγγίξει το υλικό χάραξης.',
        },
        without_af: 'Χωρίς Αυτόματη Εστίαση',
        with_af: 'Με Αυτόματη Εστίαση',
        dx: 'Χ',
        dy: 'Υ',
        rotation_angle: 'Περιστροφή',
        x_ratio: 'Αναλογία Χ',
        y_ratio: 'Αναλογία Υ',
        show_last_config: 'Εμφάνιση Τελευταίας Ρύθμισης',
        use_last_config: 'Χρήση Τελευταίας Βαθμονόμησης',
        taking_picture: 'Λήψη Φωτογραφίας...',
        analyze_result_fail: 'Αποτυχία ανάλυσης της καταγεγραμμένης εικόνας. <br/> Παρακαλώ βεβαιωθείτε: <br/> 1. Η καταγεγραμμένη εικόνα καλύπτεται πλήρως με λευκό χαρτί. <br/> 2. Η πλατφόρμα εστιάζεται σωστά.',
        drawing_calibration_image: 'Σχεδιάζοντας εικόνα βαθμονόμησης...',
        calibrate_done: 'Η βαθμονόμηση ολοκληρώθηκε. Καλύτερη ακρίβεια κάμερας επιτυγχάνεται όταν γίνεται σωστή εστίαση.',
        calibrate_done_diode: 'Η βαθμονόμηση ολοκληρώθηκε. Η αντιστάθμιση της διοδικής μονάδας λέιζερ έχει αποθηκευτεί.',
        hint_red_square: 'Ευθυγραμμίστε την εξωτερική πλευρά του κόκκινου τετραγώνου με το τετράγωνο κοπής.',
        hint_adjust_parameters: 'Χρησιμοποιήστε αυτές τις παραμέτρους για να προσαρμόσετε το κόκκινο τετράγωνο',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Κατά τη βαθμονόμηση της διοδικής μονάδας λέιζερ, χρειάζεται κάμερα.\nΒεβαιωθείτε ότι η κάμερα αυτού του μηχανήματος έχει βαθμονομηθεί.\nΚαι ευγενικά προσαρμόστε την πλατφόρμα στο εστιακό σημείο (το ύψος του ανεστραμμένου ακρυλικού)',
            beamo: 'Κατά τη βαθμονόμηση της διοδικής μονάδας λέιζερ, χρειάζεται κάμερα.\nΒεβαιωθείτε ότι η κάμερα αυτού του μηχανήματος έχει βαθμονομηθεί.\nΚαι ευγενικά προσαρμόστε την κεφαλή λέιζερ για να εστιάσετε στο αντικείμενο χάραξης (το ύψος του ανεστραμμένου ακρυλικού)',
        },
        downloading_pictures: 'Λήψη Εικόνων...',
        failed_to_download_pictures: '#848 Αποτυχία λήψης εικόνων, επικοινωνήστε με την υποστήριξη FLUX.',
        uploading_images: 'Μεταφόρτωση Εικόνων...',
        calculating_camera_matrix: 'Υπολογισμός Μήτρας Κάμερας...',
        calculating_regression_parameters: 'Υπολογισμός παραμέτρων παλινδρόμησης...',
        failed_to_calibrate_camera: '#848 Αποτυχία βαθμονόμησης κάμερας, παρακαλώ επικοινωνήστε με την υποστήριξη FLUX.',
        failed_to_save_calibration_results: '#849 Αποτυχία αποθήκευσης αποτελεσμάτων βαθμονόμησης, παρακαλώ δοκιμάστε ξανά. Αν αυτό συνεχίζει να συμβαίνει, παρακαλώ επικοινωνήστε με την υποστήριξη FLUX.',
        ador_autofocus_material: 'Πατήστε το εικονίδιο "AF" στην κύρια σελίδα της μηχανής για 3 δευτερόλεπτα και αφήστε τον αισθητήρα να αγγίξει απαλά το υλικό.',
        ador_autofocus_focusing_block: 'Πατήστε το εικονίδιο "AF" στην κύρια σελίδα της μηχανής για 3 δευτερόλεπτα και αφήστε τον αισθητήρα να αγγίξει το μπλοκ εστίασης.',
        align_red_cross_cut: 'Παρακαλώ ευθυγραμμίστε τη μέση του κόκκινου σταυρού με τον σταυρό κοπής.',
        align_red_cross_print: 'Παρακαλώ ευθυγραμμίστε τη μέση του κόκκινου σταυρού με τον τυπωμένο σταυρό.',
        retake: 'Επανάληψη λήψης',
        calibrate_camera_before_calibrate_modules: 'Παρακαλώ εκτελέστε βαθμονόμηση κάμερας πριν βαθμονομήσετε τις μονάδες.',
        check_checkpoint_data: 'Δεδομένα σημείου ελέγχου',
        checking_checkpoint: 'Ελέγχος δεδομένων σημείου ελέγχου...',
        found_checkpoint: 'Δεδομένα σημείου ελέγχου βρέθηκαν στη συσκευή σας. Θέλετε να ανακτήσετε από το σημείο ελέγχου;',
        use_old_camera_parameter: 'Θέλετε να χρησιμοποιήσετε την τρέχουσα παράμετρο φακού της κάμερας;',
        downloading_checkpoint: 'Λήψη δεδομένων σημείου ελέγχου...',
        failed_to_parse_checkpoint: 'Απέτυχε η ανάλυση των δεδομένων σημείου ελέγχου.',
        check_device_pictures: 'Έλεγχος Εικόνων Συσκευής',
        checking_pictures: 'Γίνεται έλεγχος των εικόνων της συσκευής...',
        no_picutre_found: '#846 Η συσκευή σας δεν έχει διαθέσιμες ακατέργαστες φωτογραφίες για βαθμονόμηση. Παρακαλώ επικοινωνήστε με την υποστήριξη της FLUX.',
        unable_to_load_camera_parameters: "#851 Δεν υπάρχουν διαθέσιμες παράμετροι ρύθμισης της κάμερας στη συσκευή σας. Μεταβείτε στις 'Ρυθμίσεις' > 'Ρύθμιση κάμερας (Προχωρημένο)' για να ολοκληρώσετε τη ρύθμιση και να αποκτήσετε τις παραμέτρους.",
        calibrating_with_device_pictures: 'Βαθμονόμηση με τις εικόνες της συσκευής...',
        failed_to_calibrate_with_pictures: '#848 Απέτυχε η βαθμονόμηση με τις εικόνες της συσκευής.',
        getting_plane_height: 'Λήψη Ύψους Επιπέδου...',
        preparing_to_take_picture: 'Προετοιμασία για λήψη φωτογραφίας...',
        put_paper: 'Τοποθετήστε χαρτί',
        put_paper_step1: 'Παρακαλούμε τοποθετήστε ένα λευκό χαρτί μεγέθους A4 ή Λέιζερ στο κέντρο της περιοχής εργασίας.',
        put_paper_step2: 'Ασφαλίστε τις τέσσερις γωνίες του χαρτιού για να διασφαλίσετε ότι είναι επίπεδο.',
        put_paper_step3: 'Κάντε κλικ στο "Ξεκινήστε τη χάραξη".',
        put_paper_promark_1: 'Τοποθετήστε το μαύρο χαρτόνι από το κουτί των εξαρτημάτων στην πλατφόρμα εργασίας.',
        put_paper_promark_2: 'Ρυθμίστε σωστά την εστίαση και μετά κάντε κλικ στο "Ξεκινήστε τη χάραξη" για να συνεχίσετε με τη χάραξη.',
        put_paper_skip: 'Εάν το σχέδιο βαθμονόμησης δεν προχωρήσει αυτόματα στη λήψη εικόνας, πατήστε "Παράλειψη" για να τραβήξετε εικόνα.',
        solve_pnp_title: 'Ευθυγραμμίστε τα σημεία σήμανσης',
        solve_pnp_step1: 'Παρακαλώ ευθυγραμμίστε τα σημεία χάραξης σύμφωνα με τον αριθμό και την αναμενόμενη θέση κάθε κόκκινου δείκτη.',
        solve_pnp_step2: 'Μπορείτε να πατήσετε "Επανάληψη λήψης" για να ευθυγραμμιστείτε ξανά ή να ρυθμίσετε χειροκίνητα τις θέσεις των σημείων σήμανσης.',
        align_olt: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εξωτερικό επάνω αριστερά.',
        align_ort: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εξωτερικό επάνω δεξιά.',
        align_olb: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εξωτερικό κάτω αριστερά.',
        align_orb: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εξωτερικό κάτω δεξιά.',
        align_ilt: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εσωτερικό επάνω αριστερά.',
        align_irt: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εσωτερικό επάνω δεξιά.',
        align_ilb: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εσωτερικό κάτω αριστερά.',
        align_irb: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εσωτερικό κάτω δεξιά.',
        elevate_and_cut: 'Ανύψωση και Κοπή',
        elevate_and_cut_step_1: 'Τοποθετήστε ένα ξύλο μεγέθους A4 ανοιχτού χρώματος στο κέντρο της περιοχής εργασίας, υψωμένο στα 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Χρησιμοποιήστε το Ador Prism Lift με μέγιστο μήκος 14mm μαζί με ένα ξύλο τουλάχιστον 6mm πάχους.',
        with_prism_lift: 'Με το Ador Prism Lift',
        without_prism_lift: 'Χωρίς το Ador Prism Lift',
        camera_parameter_saved_successfully: 'Οι παράμετροι της κάμερας αποθηκεύτηκαν επιτυχώς.',
        failed_to_save_camera_parameter: 'Αποτυχία αποθήκευσης των παραμέτρων της κάμερας.',
        failed_to_solve_pnp: 'Δεν ήταν δυνατή η επίλυση της θέσης της κάμερας.',
        calibrating: 'Καλιμπράρισμα...',
        moving_laser_head: 'Μετακίνηση της κεφαλής λέιζερ...',
        failed_to_move_laser_head: 'Αποτυχία μετακίνησης της κεφαλής λέιζερ.',
        put_chessboard: 'Τοποθέτηση Σκακιέρας',
        put_chessboard_bb2_desc_1: 'Κατεβάστε το παρακάτω αρχείο σκακιέρας και εκτυπώστε το σε χαρτί A4 (η εκτυπωμένη σκακιέρα πρέπει να έχει τετράγωνα 1x1 cm).',
        put_chessboard_bb2_desc_2: 'Στερεώστε τη σκακιέρα που εκτυπώσατε σε μια μη παραμορφώσιμη επιφάνεια, όπως ακρυλικό ή γυαλί, διασφαλίζοντας ότι η σκακιέρα είναι επίπεδη και χωρίς ζάρες ή ανασηκωμένες γωνίες.',
        put_chessboard_bb2_desc_3: 'Τοποθετήστε τη σκακιέρα στο κέντρο της περιοχής εργασίας.',
        put_chessboard_1: 'Παρακαλώ πηγαίνετε στον πίνακα ελέγχου της μηχανής και πατήστε AF για να εκτελέσετε την αυτόματη εστίαση.',
        put_chessboard_2: 'Παρακαλώ μετακινήστε το χαρτί με το μοτίβο σκακιέρας ή την κεφαλή λέιζερ μέχρι να επιβεβαιωθεί ότι ολόκληρο το μοτίβο σκακιέρας είναι εντός του κόκκινου πλαισίου στο παράθυρο ζωντανής προεπισκόπησης.',
        put_chessboard_3: 'Κάντε δεξί κλικ για να κατεβάσετε την εικόνα της σκακιέρας στην τρέχουσα τοποθεσία και να ελέγξετε την ευκρίνειά της.',
        put_chessboard_promark_desc_1: 'Παρακαλώ χρησιμοποιήστε το χαρτί σκακιέρας που παρέχεται στο κουτί αξεσουάρ ή εκτυπώστε το ακόλουθο μοτίβο σκακιέρας σε χαρτί Α4 για την καλιμπράρισμα της κάμερας.',
        put_chessboard_promark_desc_2: 'Ρυθμίστε σωστά την εστίαση και μετά κάντε κλικ στο «Επόμενο» για να συνεχίσετε με τη χάραξη.',
        put_chessboard_promark_1: 'Ρυθμίστε την απόσταση εστίασης στην κατάλληλη εστίαση για τον φακό πεδίου.',
        put_chessboard_promark_2: 'Βεβαιωθείτε ότι το χαρτί σκακιέρας είναι καθαρά ορατό χωρίς αντηλιά. Κάντε κλικ στο "Επόμενο" για να τραβήξετε φωτογραφία.',
        download_chessboard_file: 'Λήψη αρχείου σκακιέρας',
        failed_to_calibrate_chessboard: 'Η βαθμονόμηση με την εικόνα της σκακιέρας απέτυχε.',
        calibrate_chessboard_success_msg: 'Η φωτογραφία της σκακιέρας ελήφθη με επιτυχία.<br/>Το σκορ για αυτή τη φωτογραφία είναι %s (%.2f).',
        res_excellent: 'Εξαιρετικό',
        res_average: 'Μέτριο',
        res_poor: 'Κακό',
        perform_autofocus_bb2: 'Παρακαλώ πηγαίνετε στον πίνακα ελέγχου της μηχανής και πατήστε AF για να εκτελέσετε την αυτόματη εστίαση.',
        promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    },
    input_machine_password: {
        require_password: '"%s" απαιτεί κωδικό πρόσβασης',
        connect: 'ΣΥΝΔΕΣΗ',
        password: 'Κωδικός πρόσβασης',
    },
    tutorial: {
        skip: 'Παράλειψη',
        welcome: 'ΚΑΛΩΣ ΟΡΙΣΑΤΕ',
        suggest_calibrate_camera_first: 'Συνιστούμε στους χρήστες να βαθμονομήσουν αρχικά την κάμερα και να επαναφέρουν την εστίαση πριν από κάθε προεπισκόπηση για βέλτιστα αποτελέσματα.<br/>Θα θέλατε να επιβεβαιώσετε τη διενέργεια της βαθμονόμησης τώρα;<br/>(Μπορείτε να το παραλείψετε τώρα και να το κάνετε αργότερα κάνοντας κλικ στο "Μενού" > "Μηχάνημα" > [Η Συσκευή Σας] > "Βαθμονόμηση Κάμερας".)',
        camera_calibration_failed: 'Αποτυχία βαθμονόμησης κάμερας',
        ask_retry_calibration: 'Θα θέλατε να επαναλάβετε τη βαθμονόμηση της κάμερας;',
        needNewUserTutorial: 'Θέλετε να ξεκινήσετε ένα εκπαιδευτικό πρόγραμμα;<br/>(Μπορείτε να το παραλείψετε τώρα και να ξεκινήσετε αργότερα κάνοντας κλικ στο "Βοήθεια" > "Εμφάνιση Έναρξης Οδηγού".)',
        needNewInterfaceTutorial: 'Θα θέλατε να ξεκινήσετε ένα εκπαιδευτικό πρόγραμμα για το νέο διεπαφής του Beam Studio;<br/>(Μπορείτε να το παραλείψετε τώρα και να ξεκινήσετε αργότερα κάνοντας κλικ στο "Βοήθεια" > "Εμφάνιση Εισαγωγής Διεπαφής".)',
        next: 'ΕΠΟΜΕΝΟ',
        look_for_machine: 'Αναζήτηση μηχανής για το εκπαιδευτικό πρόγραμμα...',
        unable_to_find_machine: 'Δεν βρέθηκε μηχανή για το εκπαιδευτικό. Θέλετε να μεταβείτε στις ρυθμίσεις, να επαναλάβετε ή να το παραλείψετε;',
        skip_tutorial: 'Παρακάμψατε το εκπαιδευτικό. Μπορείτε να το ξεκινήσετε από το μενού Βοήθεια.',
        set_connection: 'Ορισμός σύνδεσης',
        retry: 'Επανάληψη',
        newUser: {
            draw_a_circle: 'Σχεδιάστε έναν κύκλο',
            drag_to_draw: 'Σύρετε για σχεδίαση',
            infill: 'Ενεργοποίηση πλήρωσης',
            switch_to_object_panel: 'Μετάβαση στο πίνακα αντικειμένων',
            switch_to_layer_panel: 'Μετάβαση στο πάνελ στρωμάτων',
            set_preset_wood_engraving: 'Ορισμός προεπιλογής: Ξύλο - χάραξη',
            set_preset_wood_cut: 'Ορισμός προεπιλογής: Ξύλο - κοπή',
            add_new_layer: 'Προσθήκη νέου στρώματος',
            draw_a_rect: 'Σχεδιάστε ένα ορθογώνιο',
            switch_to_preview_mode: 'Μετάβαση σε λειτουργία προεπισκόπησης',
            preview_the_platform: 'Προεπισκόπηση της Πλατφόρμας',
            end_preview_mode: 'Τερματισμός Λειτουργίας Προεπισκόπησης',
            put_wood: '1. Τοποθετήστε το δείγμα ξύλου',
            adjust_focus: '2. Ρυθμίστε την εστίαση',
            close_cover: '3. Κλείστε το κάλυμμα',
            send_the_file: 'Αποστολή Αρχείου',
            end_alert: 'Είστε σίγουροι ότι θέλετε να τερματίσετε την εκμάθηση;',
            please_select_wood_engraving: 'Παρακαλώ επιλέξτε την προεπιλογή "Ξύλο - Χάραξη"',
            please_select_wood_cutting: 'Παρακαλώ επιλέξτε την προεπιλογή "Ξύλο - Κοπή"',
        },
        newInterface: {
            camera_preview: 'Προεπισκόπηση Κάμερας',
            select_image_text: 'Επιλογή / Εικόνα / Κείμενο',
            basic_shapes: 'Βασικά Σχήματα',
            pen_tool: 'Εργαλείο Πένας',
            add_new_layer: 'Προσθήκη Νέου Επιπέδου',
            rename_by_double_click: 'Μετονομασία με διπλό κλικ',
            drag_to_sort: 'Σύρετε για ταξινόμηση',
            layer_controls: 'Δεξί κλικ για επιλογή ελέγχων στρώματος: Αντιγραφή / Συγχώνευση / Κλείδωμα / Διαγραφή στρωμάτων',
            switch_between_layer_panel_and_object_panel: 'Εναλλαγή μεταξύ του πίνακα στρωμάτων και του πίνακα αντικειμένων',
            align_controls: 'Έλεγχοι ευθυγράμμισης',
            group_controls: 'Έλεγχοι ομαδοποίησης',
            shape_operation: 'Λειτουργία σχήματος',
            flip: 'Αναστροφή',
            object_actions: 'Ενέργειες αντικειμένου',
            end_alert: 'Είστε σίγουροι ότι θέλετε να τερματίσετε την εισαγωγή στο νέο περιβάλλον χρήστη;',
            select_machine: 'Επιλέξτε μια μηχανή',
            start_work: 'Ξεκινήστε την εργασία',
        },
        gesture: {
            pan: 'Κάντε κύλιση στον καμβά με δύο δάχτυλα.',
            zoom: 'Αγγίξτε με δύο δάχτυλα για μεγέθυνση / σμίκρυνση',
            click: 'Πατήστε για επιλογή αντικειμένου',
            drag: 'Σύρετε για πολλαπλή επιλογή',
            hold: 'Πατήστε παρατεταμένα για αναδυόμενο μενού',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Αυτό ήταν όλο για το εκπαιδευτικό. Ώρα για δημιουργία!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Λέιζερ',
        laser_10w_diode: 'Λέιζερ διόδου 10W',
        laser_20w_diode: 'Λέιζερ διόδου 20W',
        laser_2w_infrared: 'Υπέρυθρο λέιζερ 2W',
        printing: 'Εκτύπωση',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Θέλετε να μετατρέψετε την εκτύπωση σε λέιζερ;',
            convertFromPrintingModuleMsg: 'Προσέξτε, αν ολοκληρώσετε αυτή τη λειτουργία, οι ρυθμίσεις χρωμάτων του στρώματος εκτύπωσης θα αφαιρεθούν.',
            convertFromLaserModuleTitle: 'Θέλετε να μετατρέψετε το λέιζερ σε εκτύπωση;',
            convertFromLaserModuleMsg: 'Προσέξτε, αν ολοκληρώσετε αυτή τη λειτουργία, οι ρυθμίσεις του στρώματος λέιζερ θα αφαιρεθούν.',
            importedDocumentContainsPrinting: 'Το έγγραφο περιέχει στρώμα εκτύπωσης, θέλετε να αλλάξετε την περιοχή εργασίας σε Ador;',
            printingLayersCoverted: 'Τα στρώματα εκτύπωσης έχουν μετατραπεί σε στρώματα λέιζερ.',
            performPrintingCaliTitle: 'Εκτελέστε βαθμονόμηση εκτύπωσης',
            performPrintingCaliMsg: 'Κάντε κλικ στο "Επιβεβαίωση" για να εκτελέσετε βαθμονόμηση ή μέσω του μενού (Μηχάνημα > [Όνομα Μηχανήματος] > Βαθμονόμηση Εκτύπωσης)',
            performIRCaliTitle: 'Εκτελέστε βαθμονόμηση υπερύθρου',
            performIRCaliMsg: 'Κάντε κλικ στο "Επιβεβαίωση" για να εκτελέσετε βαθμονόμηση ή μέσω του μενού (Μηχάνημα > [Όνομα Μηχανήματος] > Βαθμονόμηση Υπερύθρου)',
        },
        non_working_area: 'Μη Εργασιακή Περιοχή',
    },
    qr_code_generator: {
        title: 'Δημιουργός κωδικού QR',
        placeholder: 'Εισάγετε έναν σύνδεσμο ή κείμενο',
        preview: 'Προεπισκόπηση',
        error_tolerance: 'Ανοχή σφάλματος',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Αντιστροφή χρωμάτων φόντου',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Βασικό Κουτί',
        coming_soon: 'Έρχεται Σύντομα',
        workarea: 'Περιοχή εργασίας',
        max_dimension_tooltip: 'Το μέγιστο πλάτος/ύψος/βάθος ρύθμιση είναι %s.',
        volume: 'Όγκος',
        outer: 'Εξωτερικό',
        inner: 'Εσωτερικό',
        width: 'Πλάτος',
        height: 'Ύψος',
        depth: 'Βάθος',
        cover: 'Κάλυμμα',
        thickness: 'Πάχος',
        add_option: 'Προσθήκη επιλογής',
        joints: 'Αρμός',
        finger: 'Δάχτυλο',
        finger_warning: 'Το εσωτερικό μήκος πλευράς του κουτιού πρέπει να είναι τουλάχιστον 6 χιλιοστά 6mm (0.24 inches) για να είναι συμβατό με τη σύνδεση δακτύλου.',
        tSlot: 'T-Υποδοχή',
        tSlot_warning: 'Το μήκος πλευράς του κουτιού πρέπει να είναι τουλάχιστον 30mm (1.18 inches) για να είναι συμβατό με τη σύνδεση T-Υποδοχή.',
        edge: 'Άκρη',
        tCount: 'Μέτρηση T',
        tCount_tooltip: 'Ο αριθμός υποδοχών T ισχύει για τη μικρή πλευρά. Η ποσότητα στη μεγάλη πλευρά υπολογίζεται με βάση το μήκος της.',
        tDiameter: 'Διάμετρος T',
        tLength: 'Μήκος T',
        continue_import: 'Συνεχίστε την εισαγωγή',
        customize: 'Προσαρμογή',
        merge: 'Συγχώνευση',
        text_label: 'Ετικέτα',
        beam_radius: 'Αντιστάθμιση ακτίνας κοπής',
        beam_radius_warning: 'Αφαιρέστε την αντιστάθμιση Kerf όταν οι άκρες ή οι αρμοί του κουτιού είναι σύντομοι για να διασφαλίσετε τη συναρμολόγηση του κουτιού',
        import: 'Εισαγωγή',
        cancel: 'Ακύρωση',
        reset: 'Επαναφορά',
        zoom: 'Μεγέθυνση',
        control_tooltip: 'Αριστερό κλικ για περιστροφή\nΤροχός ποντικιού για μεγέθυνση\nΔεξί κλικ για μετακίνηση',
        control_tooltip_touch: 'Σύρετε για περιστροφή\nΤσίμπημα για μεγέθυνση\nΔύο δάχτυλα για μετακίνηση',
    },
    my_cloud: {
        title: 'Το Cloud μου',
        loading_file: 'Φόρτωση...',
        no_file_title: 'Αποθηκεύστε αρχεία στο My Cloud για να ξεκινήσετε.',
        no_file_subtitle: 'Μεταβείτε στο Μενού > "Αρχείο" > "Αποθήκευση στο Cloud"',
        file_limit: 'δωρεάν αρχείο',
        upgrade: 'Αναβάθμιση',
        sort: {
            most_recent: 'Πιο πρόσφατα',
            oldest: 'Παλαιότερα',
            a_to_z: 'Όνομα: Α - Ω',
            z_to_a: 'Όνομα: Ω - Α',
        },
        action: {
            open: 'Άνοιγμα',
            rename: 'Μετονομασία',
            duplicate: 'Αντίγραφο',
            download: 'Λήψη',
            delete: 'Διαγραφή',
            confirmFileDelete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο; Αυτή η ενέργεια δεν μπορεί να αναιρεθεί.',
        },
        save_file: {
            choose_action: 'Αποθήκευση αρχείου:',
            save: 'Αποθήκευση',
            save_new: 'Αποθήκευση ως νέο αρχείο',
            input_file_name: 'Εισαγωγή ονόματος αρχείου:',
            invalid_char: 'Μη έγκυροι χαρακτήρες:',
            storage_limit_exceeded: 'Η αποθήκευση στο cloud σας έχει φτάσει στο ανώτατο όριο. Παρακαλώ διαγράψτε τυχόν περιττά αρχεία πριν αποθηκεύσετε νέα.',
        },
    },
    camera_data_backup: {
        title: 'Αντίγραφο ασφαλείας δεδομένων κάμερας',
        no_picture_found: 'Δεν βρέθηκε εικόνα στη μηχανή.',
        folder_not_exists: 'Ο επιλεγμένος φάκελος δεν υπάρχει.',
        incorrect_folder: 'Αποτυχία μεταφόρτωσης δεδομένων βαθμονόμησης. Παρακαλώ ελέγξτε εάν ο φάκελος που επιλέξατε είναι σωστός.',
        downloading_data: 'Λήψη δεδομένων...',
        estimated_time_left: 'Υπολοιπόμενος εκτιμώμενος χρόνος:',
        uploading_data: 'Ανεβάζοντας δεδομένα...',
        download_success: 'Επιτυχής λήψη δεδομένων κάμερας.',
        upload_success: 'Επιτυχής αποστολή δεδομένων κάμερας.',
    },
    insecure_websocket: {
        extension_detected: 'Εντοπίστηκε η επέκταση Beam Studio Connect',
        extension_detected_description: 'Ανιχνεύσαμε ότι έχετε εγκαταστήσει την επέκταση Beam Studio Connect. Κάντε κλικ στο "Επιβεβαίωση" για να ανακατευθυνθείτε στο HTTPS, ή κάντε κλικ στο "Ακύρωση" για να συνεχίσετε να χρησιμοποιείτε το HTTP.',
        extension_not_deteced: 'Δεν ήταν δυνατή η ανίχνευση της επέκτασης Beam Studio Connect',
        extension_not_deteced_description: "Για να χρησιμοποιήσετε το HTTPS, κάντε κλικ στο 'Επιβεβαίωση' για να εγκαταστήσετε την επέκταση Beam Studio Connect. Μετά την εγκατάσταση της επέκτασης, παρακαλώ ανανεώστε τη σελίδα για να την ενεργοποιήσετε.<br/>Διαφορετικά, κάντε κλικ στον παρακάτω σύνδεσμο για να δείτε πώς να χρησιμοποιήσετε το HTTP στο Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Σύνδεσμος</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Μετρήστε την περιοχή αυτόματης εστίασης',
        amount: 'Ποσότητα',
        gap: 'Κενό',
        rows: 'Σειρές',
        coloumns: 'Στήλες',
        row_gap: 'Διάκενο σειρών',
        column_gap: 'Διάκενο στηλών',
        set_object_height: 'Ρύθμιση ύψους αντικειμένου',
        set_object_height_desc: 'Μετρήστε το μέγιστο πάχος του αντικειμένου.',
        reselect_area: 'Επαναεπιλογή περιοχής',
        start_autofocus: 'Εκκίνηση αυτόματης εστίασης',
        starting_measurement: 'Ξεκινώντας μέτρηση...',
        preview_3d_curve: 'Προεπισκόπηση 3D Καμπύλης',
        apply_arkwork: 'Εφαρμογή έργου τέχνης σε 3D καμπύλη',
        apply_camera: 'Εφαρμογή εικόνας κάμερας σε 3D καμπύλη',
        click_to_select_point: 'Κάντε κλικ για να επιλέξετε ή να αποεπιλέξετε σημεία για επαναμέτρηση.',
        remeasure: 'Επαναμέτρηση',
        remeasuring_points: 'Επαναμέτρηση σημείων...',
        take_reference: 'Λήψη αναφοράς',
        take_reference_desc: 'Παρακαλώ μετακινήστε την κεφαλή του λέιζερ στο υψηλότερο σημείο του αντικειμένου, χαμηλώστε τον αισθητήρα εστίασης και κάντε κλικ στο "Επιβεβαίωση" για εστίαση.',
        sure_to_delete: 'Θέλετε να διαγράψετε τα δεδομένα εστίασης της 3D καμπύλης;',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    },
    pass_through: {
        title: 'Λειτουργία Διέλευσης',
        help_text: 'Πώς να ρυθμίσετε το Λειτουργία Διέλευσης για το Ador;',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'Μήκος Αντικειμένου',
        workarea_height: 'Περιοχή Εργασίας (Ύψος):',
        height_desc: 'Ρυθμίστε το ύψος κάθε τμήματος της περιοχής εργασίας.',
        ref_layer: 'Επίπεδο Αναφοράς',
        ref_layer_desc: 'Σημειώστε ότι η εκτέλεση του επιπέδου αναφοράς έχει οριστεί σε 0 από προεπιλογή. Δεν θα εκτελεστεί, αλλά είναι μόνο για αναφορά ευθυγράμμισης.',
        ref_layer_name: 'Αναφορά',
        guide_mark: 'Σημείο Οδηγού',
        guide_mark_length: 'Διάμετρος:',
        guide_mark_x: 'Συντεταγμένη Χ:',
        guide_mark_desc: 'Τα σημεία οδηγού θα χαραχθούν ως σημείο αναφοράς για την ευθυγράμμιση του έργου τέχνης.',
        export: 'Εξαγωγή στην Περιοχή Εργασίας',
        exporting: 'Εξαγωγή...',
    },
    auto_fit: {
        title: 'Έξυπνη Τοποθέτηση',
        step1: '1. Τοποθετήστε το αντικείμενο στη επιθυμητή θέση σε ένα κομμάτι υλικού.',
        step2: '2. Πατήστε "Επιβεβαίωση" για να αντιγράψετε το αντικείμενο σε όλα τα παρόμοια κομμάτια υλικού στην προεπισκόπηση της κάμερας.',
        preview_first: 'Παρακαλώ εκτελέστε πρώτα την προεπισκόπηση της κάμερας.',
        failed_to_auto_fit: 'Αποτυχία αυτόματης προσαρμογής, ελέγξτε παρακαλώ:',
        error_tip1: '1. Είναι το έργο τοποθετημένο σωστά στο υλικό;',
        error_tip2: '2. Είναι τα περιγράμματα του υλικού αρκετά καθαρά για αναγνώριση;',
        learn_more: 'Μάθετε πώς λειτουργεί το Έξυπνη Τοποθέτηση.',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'τύπος',
        object_diameter: 'Διάμετρος Αντικειμένου',
        circumference: 'Περιφέρεια',
    },
    framing: {
        low_laser: 'Χαμηλή ισχύς λέιζερ',
        low_laser_desc: 'Ορίστε τιμή χαμηλής ισχύος λέιζερ για την εργασία πλαισίωσης.',
        framing: 'Πλαισίωση',
        hull: 'Περίβλημα',
        area_check: 'Έλεγχος περιοχής',
        framing_desc: 'Προεπισκόπηση του ορίου αντικειμένου.',
        hull_desc: 'Προεπισκοπεί ένα σχήμα που ακολουθεί στενά το σχέδιο, όπως ένα λάστιχο γύρω από το αντικείμενο.',
        areacheck_desc: 'Εξασφαλίζει την ασφάλεια της περιοχής εργασίας προβάλλοντας το πλαίσιο οριοθέτησης του αντικειμένου και τη ζώνη επιτάχυνσης της κεφαλής λέιζερ.',
        calculating_task: 'Υπολογισμός εργασίας...',
    },
    material_test_generator: {
        title: 'Γεννήτρια Δοκιμής Υλικού',
        table_settings: 'Ρυθμίσεις Πίνακα',
        block_settings: 'Ρυθμίσεις Μπλοκ',
        text_settings: 'Ρυθμίσεις κειμένου',
        preview: 'Προεπισκόπηση',
        export: 'Εξαγωγή',
        cut: 'Κοπή',
        engrave: 'Χάραξη',
        columns: 'Στήλες',
        rows: 'Σειρές',
        parameter: 'Παράμετρος',
        min: 'Ελάχ',
        max: 'Μέγ',
        count: 'Ποσότητα',
        size: 'Μέγεθος (ΥxΠ)',
        spacing: 'Απόσταση',
    },
    web_cam: {
        no_permission: 'Το Beam Studio δεν έχει άδεια πρόσβασης στην κάμερα. Βεβαιωθείτε ότι έχει δοθεί άδεια στο Beam Studio από τις ρυθμίσεις του προγράμματος περιήγησης ή του συστήματος.',
        no_device: 'Δεν είναι δυνατή η ανίχνευση της κάμερας. Παρακαλώ συνδέστε ξανά την κάμερα και δοκιμάστε ξανά.',
    },
    promark_settings: {
        title: 'Ρυθμίσεις Promark',
        field: 'Πεδίο',
        red_dot: 'Κόκκινη κουκκίδα',
        galvo_configuration: 'Ρυθμίσεις Galvo',
        switchXY: 'Εναλλαγή X/Y',
        workarea_hint: 'Μπορείτε να αλλάξετε την περιοχή εργασίας στις "Ρυθμίσεις Εγγράφου".',
        offsetX: 'Μετατόπιση X',
        offsetY: 'Μετατόπιση Y',
        angle: 'Γωνία',
        scaleX: 'Κλίμακα X',
        scaleY: 'Κλίμακα Y',
        scale: 'Κλίμακα',
        bulge: 'Εξόγκωμα',
        skew: 'Κλίση',
        trapezoid: 'Τραπέζιο',
        mark_parameters: 'Παράμετροι χάραξης',
        preview: 'Προεπισκόπηση',
        mark: 'Σήμανση',
        z_axis_adjustment: {
            title: 'Ρύθμιση Άξονα Ζ',
            section1: 'Ρυθμίστε το ύψος του άξονα Ζ για να βελτιώσετε την εστίαση.',
            tooltip1: 'Δοκιμάστε να μαρκάρετε ένα τετράγωνο 1x1 εκ. για να επιβεβαιώσετε αν η τρέχουσα εστιακή απόσταση είναι κατάλληλη.',
        },
    },
    code_generator: {
        qr_code: 'QR Κωδικός',
        barcode: 'Γραμμωτός κώδικας',
    },
    barcode_generator: {
        bar_width: 'Πλάτος γραμμής',
        bar_height: 'Ύψος γραμμής',
        text_margin: 'Περιθώριο κειμένου',
        invert_color: 'Αντιστροφή χρώματος',
        font: 'Γραμματοσειρά',
        font_size: 'Μέγεθος γραμματοσειράς',
        hide_text: 'Απόκρυψη κειμένου',
        barcode: {
            invalid_value: 'Η τιμή δεν είναι έγκυρη για την επιλεγμένη μορφή.',
        },
    },
    social_media: {
        instagram: 'Λάβετε τις τελευταίες εμπνεύσεις, προσφορές και δώρα!',
        facebook: 'Συζητήστε με FLUXers, κάντε ερωτήσεις και μάθετε συμβουλές!',
        youtube: 'Δείτε τα μαθήματα Beam Studio και ιδέες χειροτεχνίας.',
    },
};
export default lang;
