import React from 'react';
import { Flex } from 'antd';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import styles from './Block.module.scss';
const RedDotBlock = ({ isInch, redDot, setRedDot }) => {
    const { promark_settings: t } = useI18n();
    const { offsetX, offsetY, scaleX, scaleY } = redDot;
    return (React.createElement(Flex, { className: styles.block, vertical: true, gap: 8 },
        React.createElement("div", { className: styles.title }, t.red_dot),
        React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
            React.createElement("span", { className: styles.label }, t.offsetX),
            React.createElement(UnitInput, { "data-testid": "offset-x", className: styles.input, size: "small", value: offsetX, precision: isInch ? 5 : 3, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, onChange: (val) => setRedDot((cur) => (Object.assign(Object.assign({}, cur), { offsetX: val }))) })),
        React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
            React.createElement("span", { className: styles.label }, t.offsetY),
            React.createElement(UnitInput, { "data-testid": "offset-y", className: styles.input, size: "small", value: offsetY, precision: isInch ? 5 : 3, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, onChange: (val) => setRedDot((cur) => (Object.assign(Object.assign({}, cur), { offsetY: val }))) })),
        React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
            React.createElement("span", { className: styles.label }, t.scaleX),
            React.createElement(UnitInput, { "data-testid": "scale-x", className: styles.input, size: "small", value: scaleX, precision: 3, step: 0.001, onChange: (val) => setRedDot((cur) => (Object.assign(Object.assign({}, cur), { scaleX: val }))) })),
        React.createElement(Flex, { className: styles.row, justify: "space-between", align: "center" },
            React.createElement("span", { className: styles.label }, t.scaleY),
            React.createElement(UnitInput, { "data-testid": "scale-y", className: styles.input, size: "small", value: scaleY, precision: 3, step: 0.001, onChange: (val) => setRedDot((cur) => (Object.assign(Object.assign({}, cur), { scaleY: val }))) }))));
};
export default RedDotBlock;
