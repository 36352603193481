// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-promark-PromarkSettings-module__container--12Ny3{width:560px;margin:28px 0;display:grid;grid-template-columns:1fr 1fr;gap:28px 40px}.src-web-app-components-dialogs-promark-PromarkSettings-module__footer--T2aNE{height:32px}.src-web-app-components-dialogs-promark-PromarkSettings-module__footer--T2aNE .src-web-app-components-dialogs-promark-PromarkSettings-module__button--8Cj\\+V{display:inline-flex;align-items:center;font-size:14px}.src-web-app-components-dialogs-promark-PromarkSettings-module__footer--T2aNE .src-web-app-components-dialogs-promark-PromarkSettings-module__button--8Cj\\+V .src-web-app-components-dialogs-promark-PromarkSettings-module__icon---uxHu{margin-left:8px;font-size:10px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/promark/PromarkSettings.module.scss"],"names":[],"mappings":"AAAA,iFACE,WAAA,CACA,aAAA,CACA,YAAA,CACA,6BAAA,CACA,aAAA,CAGF,8EACE,WAAA,CAEA,6JACE,mBAAA,CACA,kBAAA,CACA,cAAA,CAEA,yOACE,eAAA,CACA,cAAA","sourcesContent":[".container {\n  width: 560px;\n  margin: 28px 0;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 28px 40px;\n}\n\n.footer {\n  height: 32px;\n\n  .button {\n    display: inline-flex;\n    align-items: center;\n    font-size: 14px;\n\n    .icon {\n      margin-left: 8px;\n      font-size: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-dialogs-promark-PromarkSettings-module__container--12Ny3",
	"footer": "src-web-app-components-dialogs-promark-PromarkSettings-module__footer--T2aNE",
	"button": "src-web-app-components-dialogs-promark-PromarkSettings-module__button--8Cj+V",
	"icon": "src-web-app-components-dialogs-promark-PromarkSettings-module__icon---uxHu"
};
export default ___CSS_LOADER_EXPORT___;
