export const workareaOptions = [110, 150, 220];
export const promarkWatts = [20, 30, 50];
export const mopaWatts = [20, 60, 100];
export const laserSourceWattMap = { Desktop: promarkWatts, MOPA: mopaWatts };
export var LaserType;
(function (LaserType) {
    LaserType[LaserType["Desktop"] = 0] = "Desktop";
    LaserType[LaserType["MOPA"] = 1] = "MOPA";
})(LaserType || (LaserType = {}));
export const defaultField = { offsetX: 0, offsetY: 0, angle: 0 };
export const defaultGalvoParameters = {
    x: { scale: 100, bulge: 1, skew: 1, trapezoid: 1 },
    y: { scale: 100, bulge: 1, skew: 1, trapezoid: 1 },
};
export const defaultRedLight = { offsetX: 0, offsetY: 0, scaleX: 1, scaleY: 1 };
export const controlConfig = {
    travelSpeed: 4000,
    zSpeed: 3,
    jumpDelay: 300,
    laserDelay: 200, // us (LaserOffDelay-LaserOnDelay)
};
export default {
    promarkWatts,
    mopaWatts,
    workareaOptions,
};
