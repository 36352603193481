import classNames from 'classnames';
import React, { useMemo } from 'react';
import useI18n from 'helpers/useI18n';
import { supportUsbModels } from 'app/actions/beambox/constant';
import { useLocation } from 'react-router-dom';
import styles from './SelectConnectionType.module.scss';
const TYPE_URL_MAP = {
    wifi: '#initialize/connect/connect-wi-fi',
    wired: '#initialize/connect/connect-wired',
    ether2ether: '#initialize/connect/connect-ethernet',
    usb: '#initialize/connect/connect-usb',
};
const SelectConnectionType = () => {
    const lang = useI18n().initialize;
    const { search } = useLocation();
    const model = useMemo(() => new URLSearchParams(search).get('model'), [search]);
    const handleBack = () => {
        window.location.hash = '#initialize/connect/select-machine-model';
    };
    const handleConnectionTypeClick = (type) => {
        const url = TYPE_URL_MAP[type];
        const urlParams = new URLSearchParams({ model });
        const queryString = urlParams.toString();
        window.location.hash = `${url}?${queryString}`;
    };
    const renderConnectionTypeButton = (type) => (React.createElement("button", { id: `connect-${type}`, type: "button", className: classNames('btn', styles.btn), onClick: () => handleConnectionTypeClick(type) }, lang.connection_types[type]));
    const renderConnectionTypeContainer = (type) => (React.createElement("div", { className: styles['btn-container'] },
        React.createElement("img", { className: styles.icon, src: `img/init-panel/icon-${type}.svg`, draggable: "false" }),
        renderConnectionTypeButton(type)));
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles['top-bar'] }),
        React.createElement("div", { className: styles.btns },
            React.createElement("div", { className: classNames(styles.btn, styles.primary), onClick: handleBack }, lang.back)),
        React.createElement("div", { className: styles.main },
            React.createElement("h1", { className: styles.title }, lang.select_connection_type),
            React.createElement("div", { className: styles.btns },
                renderConnectionTypeContainer('wifi'),
                renderConnectionTypeContainer('wired'),
                renderConnectionTypeContainer('ether2ether'),
                supportUsbModels.has(model) && renderConnectionTypeContainer('usb')))));
};
export default SelectConnectionType;
