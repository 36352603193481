const lang = {
    global: {
        cancel: 'Peruuta',
        back: 'Takaisin',
        save: 'Tallenna',
        ok: 'OK',
        stop: 'Pysäytä',
    },
    general: {
        processing: 'Käsitellään...',
        choose_folder: 'Valitse kansio',
    },
    buttons: {
        next: 'SEURAAVA',
        back: 'TAKAISIN',
        done: 'VALMIS',
        back_to_beam_studio: 'Takaisin Beam Studioon',
    },
    topbar: {
        untitled: 'Nimeämätön',
        titles: {
            settings: 'Asetukset',
        },
        export: 'VIENTI',
        preview: 'ESIKATSELU',
        preview_title: 'Esikatselu',
        preview_press_esc_to_stop: 'Paina ESC lopettaaksesi kameran esikatselun.',
        curve_engrave: '3D Käyrä',
        task_preview: 'Polun esikatselu',
        frame_task: 'Käynnissä oleva kehys',
        borderless: '(AVAA ALA)',
        tag_names: {
            rect: 'Suorakaide',
            ellipse: 'Soikio',
            path: 'Polku',
            polygon: 'Monikulmio',
            image: 'Kuva',
            text: 'Teksti',
            text_path: 'Teksti polulla',
            pass_through_object: 'Läpi Kulkeva Kohde',
            line: 'Viiva',
            g: 'Ryhmä',
            multi_select: 'Useita kohteita',
            use: 'Tuotu kohde',
            svg: 'SVG-kohde',
            dxf: 'DXF-kohde',
            no_selection: 'Ei valintaa',
        },
        alerts: {
            start_preview_timeout: '#803 Aikakatkaisu esikatselutilan käynnistyksessä. Käynnistä kone tai Beam Studio uudelleen. Jos virhe toistuu, noudata <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tätä ohjetta</a>.',
            fail_to_start_preview: '#803 Esikatselutilan käynnistys epäonnistui. Käynnistä kone tai Beam Studio uudelleen. Jos virhe toistuu, noudata <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tätä ohjetta</a>.',
            fail_to_connect_with_camera: '#803 Yhteyden muodostaminen kameraan epäonnistui. Käynnistä tietokone tai Beam Studio uudelleen. Jos virhe toistuu, noudata <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">näitä ohjeita</a>.',
            add_content_first: 'Lisää ensin kohteita',
            headtype_mismatch: 'Väärä moduuli havaittu. ',
            headtype_none: 'Moduulia ei havaittu. ',
            headtype_unknown: 'Tuntematon moduuli havaittu. ',
            install_correct_headtype: 'Asenna 10 W / 20 W diodilasermoduulit asianmukaisesti, jotta kehys voidaan käynnistää matalalla teholla.',
            door_opened: 'Sulje ovensuojus, jotta kehys voidaan käynnistää matalalla teholla.',
            fail_to_get_door_status: 'Varmista, että ovensuojus on kiinni, jotta kehys voidaan käynnistää matalalla teholla.',
            QcleanScene: 'Haluatko tyhjentää piirustuksen?<br/>Tämä poistaa myös kumoamishistoriasi!',
            power_too_high: 'LIIAN SUURI TEHO',
            power_too_high_msg: 'Matalampi laserteho (alle 70 %) pidentää laserputken käyttöikää.\nKirjoita "HUOMAUTETTU" jatkaaksesi.',
            power_too_high_confirm: 'HUOMAUTETTU',
            pwm_unavailable: 'Syvyystila vaatii laiteohjelmistoversion 4.3.4 / 5.3.4 tai uudemman. Haluatko päivittää laiteohjelmiston nyt?',
            job_origin_unavailable: 'Työn aloituskohdan asettaminen vaatii laiteohjelmistoversion 4.3.5 / 5.3.5 tai uudemman. Haluatko päivittää laiteohjelmiston nyt?',
            job_origin_warning: 'Käytät tällä hetkellä “nykyistä sijaintia” aloituspisteenä. Varmista, että laserkärki on siirretty oikeaan asentoon törmäysten välttämiseksi.',
        },
        hint: {
            polygon: 'Paina +-näppäintä lisätäksesi/vähentääksesi sivuja.',
        },
        menu: {
            preferences: 'Asetukset',
            hide: 'Piilota Beam Studio',
            hideothers: 'Piilota muut',
            service: 'Palvelut',
            quit: 'Lopeta',
            window: 'Ikkuna',
            minimize: 'Pienennä',
            close: 'Sulje ikkuna',
            file: 'Tiedosto',
            edit: 'Muokkaa',
            help: 'Ohje',
            open: 'Avaa',
            samples: 'Esimerkkejä',
            example_files: 'Esimerkkitiedostot',
            material_test: 'Materiaalitesti',
            calibration: 'Kalibroinnit',
            import_hello_beamo: 'beamo-esimerkki',
            import_hello_beambox: 'Beambox-esimerkki',
            import_beambox_2_example: 'Beambox II-esimerkki',
            import_material_testing_old: 'Materiaalitestaus - Klassinen',
            import_material_testing_simple_cut: 'Materiaalileikkaustesti - Yksinkertainen',
            import_material_testing_cut: 'Materiaalileikkaustesti',
            import_material_testing_engrave: 'Materiaalietsaustesti',
            import_material_testing_line: 'Materiaaliviivatesti',
            import_material_printing_test: 'Materiaalin tulostustesti',
            import_ador_laser_example: 'Adorin laser -esimerkki',
            import_ador_printing_example_single: 'Adorin tulostuksen esimerkki - Yksivärinen',
            import_ador_printing_example_full: 'Adorin tulostuksen esimerkki - Täysvärinen',
            import_acrylic_focus_probe: 'Akryylitarkkuustesti - 3 mm',
            import_beambox_2_focus_probe: 'Beambox II Tarkennusanturi - 3 mm',
            import_promark_example: 'Promark-esimerkki',
            import_hexa_example: 'Esimerkki HEXA:sta',
            export_to: 'Vie...',
            export_flux_task: 'FLUX-tehtävä',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Tallenna',
            save_as: 'Tallenna nimellä...',
            save_to_cloud: 'Tallenna pilvipalveluun',
            about_beam_studio: 'Tietoja Beam Studiosta',
            undo: 'Kumoa',
            redo: 'Tee uudelleen',
            cut: 'Leikkaa',
            copy: 'Kopioi',
            paste: 'Liitä',
            paste_in_place: 'Liitä paikalleen',
            group: 'Ryhmitä',
            ungroup: 'Poista ryhmitys',
            delete: 'Poista',
            duplicate: 'Monista',
            offset: 'Siirrä',
            scale: 'Skaalaa',
            rotate: 'Kierrä',
            reset: 'Nollaa',
            align_center: 'Keskitä',
            photo_edit: 'Kuva',
            svg_edit: 'SVG',
            path: 'Polku',
            decompose_path: 'Hajota',
            object: 'Objekti',
            layer_setting: 'Taso',
            layer_color_config: 'Väriasetukset',
            image_sharpen: 'Terävöitä',
            image_crop: 'Rajaa',
            image_invert: 'Käännä',
            image_stamp: 'Viistota',
            image_vectorize: 'Jäljitä',
            image_curve: 'Kaareva',
            align_to_edges: 'Kohdista reunoihin',
            document_setting: 'Asiakirjan asetukset',
            document_setting_short: 'Asiakirja',
            rotary_setup: 'Kierron Asetukset',
            clear_scene: 'Uudet tiedostot',
            machines: 'Koneet',
            add_new_machine: 'Koneen asetukset',
            help_center: 'Ohjekeskus',
            show_gesture_tutorial: 'Käden eleiden esittely',
            show_start_tutorial: 'Näytä aloitusopastus',
            show_ui_intro: 'Näytä käyttöliittymän esittely',
            questionnaire: 'Palautekysely',
            change_logs: 'Muutosloki',
            contact: 'Ota yhteyttä',
            tutorial: 'Aloita Delta-perheen tulostusopastus',
            design_market: 'Muotoilumarkkinat',
            forum: 'Yhteisöfoorumi',
            software_update: 'Ohjelmistopäivitys',
            bug_report: 'Virheraportti',
            dashboard: 'Hallintapaneeli',
            machine_info: 'Koneen tiedot',
            network_testing: 'Testaa verkkoasetukset',
            commands: 'Komennot',
            update_firmware: 'Päivitä laiteohjelmisto',
            using_beam_studio_api: 'Beam Studion API:n käyttö',
            set_as_default: 'Aseta oletukseksi',
            calibrate_beambox_camera: 'Kalibroi kamera',
            calibrate_printer_module: 'Kalibroi tulostinmoduuli',
            calibrate_ir_module: 'Kalibroi infrapunamoduuli',
            calibrate_beambox_camera_borderless: 'Kalibroi kamera (avoin pohja)',
            calibrate_diode_module: 'Kalibroi diodilasermoduuli',
            calibrate_camera_advanced: 'Kalibroi kamera (Edistynyt)',
            manage_account: 'Hallitse tiliäni',
            sign_in: 'Kirjaudu sisään',
            sign_out: 'Kirjaudu ulos',
            account: 'Tili',
            my_account: 'Oma tili',
            download_log: 'Lataa lokit',
            download_log_canceled: 'Lokien lataus peruutettu',
            download_log_error: 'Tapahtui tuntematon virhe, yritä myöhemmin uudelleen',
            keyboard_shortcuts: 'Pikanäppäimet',
            log: {
                network: 'Verkko',
                hardware: 'Laitteisto',
                discover: 'Etsi',
                usb: 'USB',
                usblist: 'USB-luettelo',
                camera: 'Kamera',
                cloud: 'Pilvi',
                player: 'Soitin',
                robot: 'Robotti',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Näytä',
            zoom_in: 'Lähennä',
            zoom_out: 'Loitonna',
            fit_to_window: 'Sovita ikkunaan',
            zoom_with_window: 'Sovita ikkunaan automaattisesti',
            borderless_mode: 'Reunaton tila',
            show_grids: 'Näytä ruudukot',
            show_rulers: 'Näytä viivain',
            show_layer_color: 'Käytä kerroksen väriä',
            anti_aliasing: 'Anti-Aliasing',
            disassemble_use: 'Purkaa',
            about: 'Tietoja Beam Studiosta',
            switch_to_beta: 'Vaihda Beta-versioon',
            switch_to_latest: 'Vaihda Vakaa versioon',
            reload_app: 'Lataa Sovellus Uudelleen',
            recent: 'Avaa Viimeaikaiset',
            update: 'Tarkista Päivitys',
            dev_tool: 'Vianmääritystyökalu',
            camera_calibration_data: 'Kameran Kalibrointitiedot',
            upload_data: 'Lähetä Tiedot',
            download_data: 'Lataa Tiedot',
            tools: {
                title: 'Työkalut',
                material_test_generator: 'Materiaalitestin Generaattori',
                code_generator: 'Koodigeneraattori',
                box_generator: 'Laatikkogeneraattori',
            },
            follow_us: 'Seuraa meitä',
        },
        select_machine: 'Valitse kone',
    },
    support: {
        no_webgl: 'WebGL ei ole tuettu. Käytä muita laitteita.',
        no_vcredist: 'Asenna Visual C++ Redistributable 2015<br/>Sen voi ladata osoitteesta flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Tuntematon virhe. Käynnistä Beam Studio ja laite uudelleen.',
        OPERATION_ERROR: '[OE] Tilakonflikti tapahtui, yritä toimintoa uudelleen.',
        SUBSYSTEM_ERROR: '[SE] Virhe, kun laiteohjelmisto suoritti tehtävää. Käynnistä laite uudelleen.',
        UNKNOWN_COMMAND: '[UC] Päivitä laitteen laiteohjelmisto',
    },
    device_selection: {
        no_beambox: '#801 Emme löydä laitettasi verkosta.\nNoudata <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">ohjetta</a> yhteysongelman vianmääritykseen.',
        no_device_web: "#801 Tarkista koneesi tila tai napsauta alla olevaa 'Koneen asetukset' asettaaksesi koneen.",
        select_usb_device: 'Valitse USB-laite',
    },
    update: {
        update: 'Päivitä',
        release_note: 'Julkaisutiedot:',
        firmware: {
            caption: 'Laitteeseen on saatavilla laiteohjelmistopäivitys',
            message_pattern_1: '"%s" on nyt valmis laiteohjelmistopäivitykseen.',
            message_pattern_2: '%s laiteohjelmisto v%s on nyt saatavilla - Sinulla on v%s.',
            latest_firmware: {
                caption: 'Laitteen laiteohjelmistopäivitys',
                message: 'Sinulla on uusin laitteen laiteohjelmisto',
                still_update: 'PÄIVITÄ',
                cant_get_latest: 'Uusimman laiteohjelmiston tietoja ei voi hakea.',
            },
            confirm: 'LÄHETÄ',
            upload_file: 'Laiteohjelmiston lataus (*.bin / *.fxfw)',
            update_success: 'Laiteohjelmistopäivitys ladattu onnistuneesti',
            update_fail: '#822 Päivitys epäonnistui',
            too_old_for_web: 'Nykyinen laitteen laiteohjelmistoversiosi on v%s.\nJos haluat käyttää Beam Studion verkkoversiota, päivitä laitteen laiteohjelmisto uusimpaan versioon.',
            force_update_message: '#814 Päivitä laitteesi uusimpaan laiteohjelmistoversioon.',
            firmware_too_old_update_by_sdcard: 'Laiteohjelmistoversio on liian vanha. Päivitä laiteohjelmisto SD-kortin avulla.',
        },
        software: {
            checking: 'Tarkistetaan päivityksiä',
            switch_version: 'Vaihda versiota',
            check_update: 'Tarkista päivitykset',
            caption: 'Beam Studio -ohjelmistopäivitys on saatavilla',
            downloading: 'Lataan päivityksiä taustalla, voit jatkaa työskentelyä painamalla "OK".',
            install_or_not: 'on valmis päivittämään. Haluatko käynnistää uudelleen ja päivittää nyt?',
            switch_or_not: 'on valmis vaihtamaan. Haluatko käynnistää uudelleen ja vaihtaa nyt?',
            available_update: 'Uusi Beam Studio v%s on nyt saatavilla. Sinulla on v%s. Haluatko ladata päivityksen?',
            available_switch: 'Uusi Beam Studio v%s on nyt saatavilla. Sinulla on v%s. Haluatko vaihtaa tähän versioon?',
            not_found: 'Käytät uusinta Beam Studio -versiota.',
            no_response: 'Yhteys palvelimeen epäonnistui, tarkista verkkoasetuksesi.',
            switch_version_not_found: 'Vaihdettavaa versiota ei löytynyt.',
            yes: 'Kyllä',
            no: 'Ei',
            update_for_ador: 'Ohjelmistoversio %s ei ole yhteensopiva, lataa uusin Beam Studio -versio Adorille.',
        },
        updating: 'Päivitetään...',
        skip: 'Ohita tämä versio',
        preparing: 'Valmistellaan...',
        later: 'MYÖHEMMIN',
        download: 'VERKKO-PÄIVITYS',
        cannot_reach_internet: '#823 Palvelin ei ole tavoitettavissa<br/>Tarkista internet-yhteys',
        install: 'ASENNA',
        upload: 'LÄHETÄ',
    },
    topmenu: {
        version: 'Versio',
        credit: 'Beam Studio on mahdollinen <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> avoimen lähdekoodin projektin ja muiden <a target="_blank" href="https://flux3dp.com/credits/">avoimen lähdekoodin ohjelmistojen</a> ansiosta.',
        ok: 'OK',
        file: {
            label: 'Tiedosto',
            import: 'Tuo',
            save_fcode: 'Vie FLUX-tehtävä',
            save_scene: 'Tallenna kohtaus',
            save_svg: 'Vie SVG',
            save_png: 'Vie PNG',
            save_jpg: 'Vie JPG',
            converting: 'Muunnetaan kuvaksi...',
            all_files: 'Kaikki tiedostot',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio Scene',
            fcode_files: 'FLUX Code',
            clear_recent: 'Tyhjennä viimeksi avatut',
            path_not_exit: 'Tätä polkua ei näytä enää olevan levyllä.',
        },
        device: {
            download_log_canceled: 'Lokin lataus peruutettu',
            download_log_error: 'Tuntematon virhe tapahtui, yritä myöhemmin uudelleen',
            log: {
                usblist: 'USB-luettelo',
            },
            network_test: 'Testaa verkko',
        },
    },
    initialize: {
        next: 'Seuraava',
        start: 'Aloita',
        skip: 'Ohita',
        cancel: 'Peruuta',
        confirm: 'Vahvista',
        connect: 'Yhdistä',
        back: 'Takaisin',
        retry: 'Yritä uudelleen',
        no_machine: 'Minulla ei ole konetta nyt.',
        select_language: 'Valitse kieli',
        select_machine_type: 'Valitse mallisi',
        select_beambox: 'Valitse Beamboxisi',
        select_connection_type: 'Miten haluat muodostaa yhteyden?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Langallinen verkko',
            ether2ether: 'Suora yhteys',
            usb: 'USB-yhteys',
        },
        connect_wifi: {
            title: 'Yhdistetään Wi-Fiin',
            tutorial1: '1. Siirry kosketusnäytössä kohtaan Verkko > Valitse "Yhdistä WiFiin".',
            tutorial1_ador: '1. Siirry kosketusnäytölle > Paina "KONE" > Paina "Verkko" > "Yhdistä WiFiin".',
            tutorial2: '2. Valitse ja yhdistä haluamasi Wi-Fi.',
            what_if_1: 'Entä jos en näe Wi-Fiäni?',
            what_if_1_content: '1. Wi-Fi-salauksen tilan tulisi olla WPA2 tai ei salasanaa.\n2. Salaustilan voi asettaa Wi-Fi-reitittimen hallintaliittymässä. Jos reititin ei tue WPA2:ta ja tarvitset apua oikean reitittimen valinnassa, ota yhteyttä FLUX-tukeen.',
            what_if_2: 'Mitä teen, jos en näe yhtään Wi-Fi-verkkoa?',
            what_if_2_content: '1. Varmista, että Wi-Fi-tikku on kytketty kunnolla.\n2. Jos kosketusnäytöllä ei näy langattoman verkon MAC-osoitetta, ota yhteyttä FLUX-tukeen.\n3. Wi-Fi-kanavan tulisi olla 2,4 GHz (5 GHz ei ole tuettu).',
        },
        connect_wired: {
            title: 'Langallisen verkon yhdistäminen',
            tutorial1: '1. Yhdistä laite reitittimeesi.',
            tutorial2: '2. Paina "Verkko" saadaksesi langallisen verkon IP:n.',
            tutorial2_ador: '2. Paina "KONE" > "Verkko" saadaksesi langallisen verkon IP:n.',
            what_if_1: 'Mitä teen, jos IP on tyhjä?',
            what_if_1_content: '1. Varmista, että Ethernet-kaapeli on kytketty kunnolla.\n2. Jos kosketusnäytöllä ei näy langallisen verkon MAC-osoitetta, ota yhteyttä FLUX-tukeen.',
            what_if_2: 'Mitä teen, jos IP alkaa numerolla 169?',
            what_if_2_content: '1. Jos IP-osoite alkaa numerolla 169.254, kyseessä on todennäköisesti DHCP-asetusten ongelma, ota yhteyttä internetpalveluntarjoajaasi lisäavun saamiseksi.\n2. Jos tietokoneesi yhdistyy internetiin suoraan käyttäen PPPoE:tä, vaihda käyttämään reititintä PPPoE-yhteyden muodostamiseen ja ota DHCP käyttöön reitittimessä.',
        },
        connect_ethernet: {
            title: 'Suora yhteys',
            tutorial1: '1. Yhdistä laite tietokoneeseesi Ethernet-kaapelilla.',
            tutorial2_1: '2. Noudata ',
            tutorial2_a_text: 'tätä ohjetta',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' tehdäksesi tietokoneestasi reitittimen.',
            tutorial3: '3. Paina Seuraava.',
        },
        connect_usb: {
            title: 'USB-yhteys',
            title_sub: ' (vain HEXA)',
            tutorial1: '1. Yhdistä laite tietokoneeseesi USB-kaapelilla.',
            tutorial2: '2. Paina Seuraava.',
            turn_off_machine: 'Sammuta kone.',
            turn_on_machine: 'Käynnistä kone.',
            wait_for_turning_on: 'Paina "Seuraava" kun käynnistys on valmis ja olet päävalikossa.',
            connect_camera: 'Yhdistä laitteen kamera tietokoneeseesi USB-kaapelilla.',
        },
        connect_machine_ip: {
            check_usb: 'Tarkista USB-yhteys',
            enter_ip: 'Anna laitteesi IP-osoite',
            check_ip: 'Tarkistetaan IP-osoitteen saatavuus',
            invalid_ip: 'Virheellinen IP: ',
            invalid_format: 'Virheellinen muoto',
            starts_with_169254: 'Alkaa numerolla 169.254',
            unreachable: 'IP-osoite ei vastaa',
            check_connection: 'Tarkistetaan laitteen yhteyttä',
            check_firmware: 'Tarkistetaan laiteohjelmiston versio',
            check_camera: 'Tarkistetaan kameran saatavuus',
            retry: 'Yritä uudelleen',
            succeeded_message: 'Yhteys muodostettu onnistuneesti 🎉',
            finish_setting: 'Aloita luominen!',
            check_swiftray_connection: 'Tarkistetaan yhteys palvelimeen',
            check_swiftray_connection_unreachable: 'palvelin ei ole saavutettavissa',
            promark_hint: 'Jos USB-yhteyden muodostaminen epäonnistuu toistuvasti, katso <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">Ohjekeskuksen artikkeli</a>.',
            alert: {
                swiftray_connection_error: 'Yhteyttä palvelimeen ei voitu muodostaa. Käynnistä Beam Studio uudelleen ja yritä uudelleen.',
            },
        },
        connecting: 'Yhdistetään...',
        setting_completed: {
            start: 'Aloita',
            great: 'Tervetuloa Beam Studioon',
            setup_later: 'Voit määrittää laitteesi myöhemmin yläpalkista kohdasta "Laitteet" > "Laitteen asetukset"',
            back: 'Takaisin',
            ok: 'ALOITA LUOMINEN',
        },
        promark: {
            select_laser_source: 'Valitse Promarkisi',
            select_workarea: 'Valitse työalue',
            settings: 'Promark-asetukset',
            qc_instructions: 'Täytä parametrit "QC Pass" -kortin takapuolella',
            configuration_confirmation: 'Tämä varmistaa, että Promark on oikein määritetty optimaalista tehokkuutta ja tarkkuutta varten.',
            or_complete_later: `Tai ohita tämä vaihe ja viimeistele Promark-asetukset myöhemmin:
    Koneet > "Promark-nimi" > Promark-asetukset`,
        },
    },
    error_pages: {
        screen_size: 'Huomaa, että Beam Studio ei välttämättä toimi optimaalisesti laitteellasi. Paras kokemus saadaan näytöllä, jonka leveys on vähintään 1024 pikseliä.',
    },
    menu: {
        mm: 'mm',
        inches: 'tuumaa',
    },
    settings: {
        on: 'Päällä',
        off: 'Pois',
        low: 'Matala',
        normal: 'Normaali',
        high: 'Korkea',
        caption: 'Asetukset',
        tabs: {
            general: 'Yleiset',
            device: 'Laite',
        },
        ip: 'Laitteen IP-osoite',
        guess_poke: 'Etsi laitteen IP-osoite',
        auto_connect: 'Valitse automaattisesti ainoa laite',
        wrong_ip_format: 'Väärä IP-muoto',
        default_machine: 'Oletuslaite',
        default_machine_button: 'Tyhjennä',
        remove_default_machine_button: 'Poista',
        confirm_remove_default: 'Oletuslaite poistetaan.',
        reset: 'Nollaa Beam Studio',
        reset_now: 'Nollaa Beam Studio',
        confirm_reset: 'Vahvista Beam Studion nollaus',
        language: 'Kieli',
        notifications: 'Työpöytäilmoitukset',
        check_updates: 'Automaattinen tarkistus',
        autosave_enabled: 'Automaattinen tallennus',
        autosave_path: 'Automaattisen tallennuksen sijainti',
        autosave_interval: 'Tallenna joka',
        autosave_number: 'Automaattisten tallennusten määrä',
        autosave_path_not_correct: 'Määritettyä polkua ei löydy.',
        preview_movement_speed: 'Esikatselun liikenopeus',
        medium: 'Keskitaso',
        default_units: 'Oletusyksiköt',
        default_font_family: 'Oletusfontti',
        default_font_style: 'Oletusfontin tyyli',
        fast_gradient: 'Nopeusoptimointi',
        engraving_direction: 'Suunta',
        top_down: 'Ylös-alas',
        bottom_up: 'Alas-ylös',
        vector_speed_constraint: 'Nopeusrajoitus',
        loop_compensation: 'Silmukkakompensaatio',
        blade_radius: 'Terän säde',
        blade_precut_switch: 'Terän esileikkaus',
        blade_precut_position: 'Esileikkausasento',
        default_beambox_model: 'Oletusasiakirja-asetukset',
        guides_origin: 'Apuviivojen alkuperä',
        guides: 'Apuviivat',
        image_downsampling: 'Bitmap-esikatselun laatu',
        anti_aliasing: 'Anti-Aliasing',
        continuous_drawing: 'Jatkuva piirto',
        trace_output: 'Kuvan jäljityslähtö',
        single_object: 'Yksittäinen objekti',
        grouped_objects: 'Ryhmitetyt objektit',
        simplify_clipper_path: 'Optimoi laskettu polku',
        enable_low_speed: 'Ota hitaan nopeuden liike käyttöön',
        enable_custom_backlash: 'Ota mukautettu välys kompensointi käyttöön',
        calculation_optimization: 'Reitin laskennan kiihtyvyys',
        auto_switch_tab: 'Automaattinen vaihto kerros- ja objektipaneelin välillä',
        custom_preview_height: 'Mukautettu esikatselukorkeus',
        mask: 'Työalueen rajaus',
        text_path_calc_optimization: 'Polun laskennan optimointi',
        font_substitute: 'Korvaa tukemattomat merkit',
        font_convert: 'Teksti-polku muunnin',
        default_borderless_mode: 'Avaa alaosa oletuksena',
        default_enable_autofocus_module: 'Automaattitarkennuksen oletus',
        default_enable_diode_module: 'Diodilaserin oletus',
        diode_offset: 'Diodilaserin siirtymä',
        autofocus_offset: 'Automaattitarkennuksen siirtymä',
        diode_one_way_engraving: 'Diodilaserin yksisuuntainen kaiverrus',
        diode_two_way_warning: 'Kaksisuuntainen valon lähettäminen on nopeampaa ja todennäköisesti tuottaa epätarkkuutta laserkaiverruksen sijainnissa. Suositellaan testaamaan ensin.',
        share_with_flux: 'Jaa Beam Studion analytiikka',
        none: 'Ei mitään',
        close: 'Sulje',
        enabled: 'Käytössä',
        disabled: 'Ei käytössä',
        cancel: 'Peruuta',
        done: 'Valmis',
        module_offset_10w: '10 W diodilaserin siirtymä',
        module_offset_20w: '20 W diodilaserin siirtymä',
        module_offset_printer: 'Tulostimen siirtymä',
        module_offset_2w_ir: '2 W infrapunalaerin siirtymä',
        printer_advanced_mode: 'Tulostimen lisäasetukset',
        default_laser_module: 'Oletuslasermoduuli',
        low_laser_for_preview: 'Käynnissä oleva kehys:lle',
        groups: {
            general: 'Yleiset',
            update: 'Ohjelmistopäivitykset',
            connection: 'Yhteys',
            autosave: 'Automaattinen tallennus',
            camera: 'Kamera',
            editor: 'Editori',
            engraving: 'Rasterointi (skannaus)',
            path: 'Vektori (ääriviivat)',
            mask: 'Työalueen rajaus',
            text_to_path: 'Teksti',
            modules: 'Lisäosa',
            ador_modules: 'Adorin moduulit',
            privacy: 'Yksityisyys',
        },
        notification_on: 'Päällä',
        notification_off: 'Pois päältä',
        update_latest: 'Viimeisin',
        update_beta: 'Beeta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
            calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
        },
    },
    beambox: {
        tag: {
            g: 'Ryhmä',
            use: 'Tuo SVG',
            image: 'Kuva',
            text: 'Teksti',
        },
        context_menu: {
            cut: 'Leikkaa',
            copy: 'Kopioi',
            paste: 'Liitä',
            paste_in_place: 'Liitä paikalleen',
            duplicate: 'Monista',
            delete: 'Poista',
            group: 'Ryhmä',
            ungroup: 'Poista ryhmitys',
            move_front: 'Tuo etualalle',
            move_up: 'Tuo eteenpäin',
            move_down: 'Lähetä taaksepäin',
            move_back: 'Lähetä taustalle',
        },
        popup: {
            select_import_method: 'Valitse tuontityyli:',
            select_import_module: 'Valitse moduuli:',
            touchpad: 'Kosketuslevy',
            mouse: 'Hiiri',
            layer_by_layer: 'Kerros',
            layer_by_color: 'Väri',
            nolayer: 'Yksikerroksinen',
            loading_image: 'Ladataan kuvaa, odota...',
            no_support_text: 'Beam Studio ei tällä hetkellä tue tekstiä. Muuta teksti poluiksi ennen tuontia.',
            speed_too_high_lower_the_quality: 'Liian korkea nopeus tällä resoluutiolla voi heikentää varjostuksen laatua.',
            both_power_and_speed_too_high: 'Matalampi laserteho pidentää laserputken käyttöikää. Liian korkea nopeus tällä resoluutiolla voi myös heikentää varjostuksen laatua.',
            too_fast_for_path: 'Liian korkea nopeus polkuobjekteja sisältävissä kerroksissa voi heikentää leikkaustarkkuutta. Emme suosittele yli %(limit)s nopeutta leikattaessa.',
            too_fast_for_path_and_constrain: 'Seuraavat kerrokset: %(layers)s \nsisältävät vektoripolkuobjekteja yli %(limit)s nopeudella. Polkuobjektien leikkausnopeus rajoitetaan %(limit)s:iin. Voit poistaa rajoituksen asetuksista.',
            should_update_firmware_to_continue: '#814 Nykyinen laiteohjelmisto ei tue tätä Beam Studion versiota. Päivitä laiteohjelmisto jatkaaksesi. (Valikko > Laite > [Laite] > Päivitä laiteohjelmisto)',
            recommend_downgrade_software: 'Havaitsimme vanhemman laiteohjelmistoversion. Työskentelemme parhaillaan yhteensopivuusongelmien kanssa, mutta toistaiseksi suosittelemme palaamaan Beam Studioon 1.9.5.',
            recommend_upgrade_firmware: 'Havaitsimme vanhemman laiteohjelmistoversion. Yhteensopivuusongelmia korjataan parhaillaan, mutta toistaiseksi suosittelemme päivittämään uusimpaan laiteohjelmistoversioon.',
            still_continue: 'Jatka',
            more_than_two_object: 'Liikaa objekteja. Vain 2 objektia tuetaan.',
            not_support_object_type: 'Objektityyppiä ei tueta.',
            select_first: 'Valitse objekti ensin.',
            select_at_least_two: 'Valitse kaksi objektia jatkaaksesi',
            import_file_contain_invalid_path: '#808 Tuodussa SVG-tiedostossa on virheellisiä kuvapolkuja. Varmista, että kaikki kuvatiedostot ovat olemassa tai upota kuvat tiedostoon.',
            import_file_error_ask_for_upload: 'Tiedoston tuonti epäonnistui. Haluatko tarjota tiedoston kehitystiimille vianraporttia varten?',
            upload_file_too_large: '#819 Tiedosto on liian suuri latausta varten.',
            successfully_uploaded: 'Tiedoston lataus onnistui.',
            upload_failed: '#819 Tiedoston lataus epäonnistui.',
            or_turn_off_borderless_mode: ' Tai poista avoimen pohjan tila käytöstä.',
            svg_1_1_waring: 'Tämän SVG-tiedoston versio 1.1 voi aiheuttaa yhteensopivuusongelmia.',
            svg_image_path_waring: 'Tämä SVG-tiedosto sisältää tiedostopolkuja. Se voi aiheuttaa virheitä ladattaessa.\nVälttääksesi tämän riskin, liitä kuvat SVG-tiedostoon vietäessä.',
            dxf_version_waring: 'Tämän DXF-tiedoston versio ei ole 2013, mikä voi aiheuttaa yhteensopivuusongelmia.',
            dont_show_again: 'Älä näytä tätä enää',
            convert_to_path_fail: 'Muuntaminen poluiksi epäonnistui.',
            save_unsave_changed: 'Haluatko tallentaa tallentamattomat muutokset?',
            dxf_bounding_box_size_over: 'Piirroksen koko ylittää työalueen. Siirrä piirrosta lähemmäs origoa CAD-ohjelmassa tai tarkista mittayksiköt.',
            progress: {
                uploading: 'Lähetetään',
                calculating: 'Lasketaan',
            },
            backend_connect_failed_ask_to_upload: '#802 Virheitä yhdistettäessä taustajärjestelmään. Haluatko lähettää vianraportin?',
            backend_error_hint: 'Ominaisuuksissa voi olla ongelmia taustajärjestelmän virheen takia.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Virhe muunnettaessa PDF:ää SVG:ksi:',
                error_pdf2svg_not_found: '#825 Virhe: pdf2svg-komentoa ei löydy. Asenna pdf2svg paketinhallinnalla.',
            },
            ungroup_use: 'Tämä purkaa tuodun tiedoston ryhmityksen. Koska tiedosto voi sisältää suuren määrän elementtejä, ryhmityksen purku voi viedä aikaa. Haluatko jatkaa?',
            vectorize_shading_image: 'Liukuvärit kuvissa altistuvat kohinalle. Poista kuvan liukuvärit ennen suorittamista.',
            change_workarea_before_preview: '%s:n työalue ei vastaa nykyistä työaluetta. Haluatko vaihtaa nykyisen työalueen?',
            bug_report: 'Vianraportti',
            sentry: {
                title: 'Tehdään Beam Studio paremmaksi yhdessä',
                message: 'Hyväksytkö automaattisen virheraporttien lähettämisen kehitystiimille virheiden sattuessa?',
            },
            questionnaire: {
                caption: 'Auta meitä täyttämään kysely',
                message: 'Auta meitä tekemään Beam Studio paremmaksi täyttämällä kysely.',
                unable_to_get_url: 'Linkkiä kyselyyn ei voitu hakea internetistä. Tarkista internetyhteys.',
                no_questionnaire_available: 'Kyselyä ei ole saatavilla tällä hetkellä.',
            },
            facebook_group_invitation: {
                title: 'Liity FLUX:n viralliseen käyttäjäryhmään',
                message: 'Liity viralliseen FLUX Facebook-ryhmäämme yhdistääksesi muiden FLUX-laserkäyttäjien kanssa, keskustellaksesi FLUX-lasereista, jakaaksesi laser-taidetta ja pysyäksesi ajan tasalla tuotteidemme uusimmista päivityksistä. Odotamme sinua innolla!',
                join_now: 'Liity nyt',
                later: 'Ehkä myöhemmin',
                already_joined: 'Jo liittynyt',
                dont_show_again: 'Älä näytä uudelleen',
            },
            ai_credit: {
                relogin_to_use: 'Kirjaudu sisään uudelleen käyttääksesi tätä toimintoa',
                insufficient_credit: 'Creditit loppu',
                insufficient_credit_msg: 'Et voi käyttää %s. Mene jäsenkeskukseen ja osta AI-credittejä.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Siirry',
            },
            text_to_path: {
                caption: 'Teksti-polku muunnin 2.0',
                message: 'Beam Studio esittelee nyt uuden teksti-polku muuntimen (Muunnin 2.0), joka tuottaa luotettavampia tuloksia! Haluaisitko vaihtaa siihen nyt? \nVoit myös säätää tätä asetusta myöhemmin Teksti-polku muunnin -osiossa asetuksissa.',
            },
            auto_switch_tab: {
                title: 'Automaattinen Vaihto Tason ja Kohteen Paneelien Välillä',
                message: 'Uusi vaihtoehto automaattiselle vaihtamiselle Tasojen ja Objektien paneelien välillä on lisätty Asetuksiin. Tämä vaihtoehto on oletusarvoisesti pois käytöstä. Haluatko ottaa automaattisen vaihtamisen käyttöön nyt? <br/>Voit muuttaa tätä asetusta milloin tahansa Asetuksissa.',
            },
        },
        zoom_block: {
            fit_to_window: 'Sovita ikkunaan',
        },
        time_est_button: {
            calculate: 'Arvioi aika',
            estimate_time: 'Arvioitu aika:',
        },
        left_panel: {
            unpreviewable_area: 'Sokkoalue',
            diode_blind_area: 'Hybridilaser-lisävarusteen sokkoalue',
            borderless_blind_area: 'Ei-kaiverrusalue',
            borderless_preview: 'Avaa alareunan kameran esikatselu',
            rectangle: 'Suorakaide',
            ellipse: 'Ellipsi',
            line: 'Viiva',
            image: 'Kuva',
            text: 'Teksti',
            label: {
                cursor: 'Valitse',
                photo: 'Kuva',
                text: 'Teksti',
                line: 'Viiva',
                rect: 'Suorakaide',
                oval: 'Soikio',
                polygon: 'Monikulmio',
                pen: 'Kynä',
                shapes: 'Kuvio',
                array: 'Taulukko',
                preview: 'Kameran esikatselu',
                trace: 'Jäljitä kuva',
                end_preview: 'Lopeta esikatselu',
                clear_preview: 'Tyhjennä esikatselu',
                choose_camera: 'Kamera',
                live_feed: 'Live-syöte',
                adjust_height: 'Säädä korkeutta',
                qr_code: 'QR-koodi',
                boxgen: 'Boxgen',
                my_cloud: 'Pilveni',
                pass_through: 'Läpäisymoodi',
                curve_engraving: {
                    title: '3D Käyrä',
                    exit: 'Poistu',
                    select_area: 'Valitse alue',
                    preview_3d_curve: 'Esikatselu 3D-käyrä',
                    clear_area: 'Tyhjennä valittu alue',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Tasot',
                objects: 'Kuvio',
                path_edit: 'Polun muokkaus',
            },
            layer_panel: {
                layer1: 'Taso 1',
                layer_bitmap: 'Bitmappi',
                layer_engraving: 'Kaiverrus',
                layer_cutting: 'Leikkaus',
                current_layer: 'Nykyinen kerros',
                move_elems_to: 'Siirrä kuvio tasolle:',
                notification: {
                    dupeLayerName: 'Samanniminen taso on jo olemassa!',
                    newName: 'UUSI NIMI',
                    enterUniqueLayerName: 'Anna tasolle uniikki nimi',
                    enterNewLayerName: 'Anna uusi tason nimi',
                    layerHasThatName: 'Tasolla on jo tuo nimi',
                    QmoveElemsToLayer: "Siirretäänkö valitut kuvio tasolle '%s'?",
                    moveElemFromPrintingLayerTitle: 'Siirretäänkö valitut elementit kerrokseen %s ja muunnetaan laser-elementeiksi?',
                    moveElemFromPrintingLayerMsg: 'Huomioi, että jos suoritat tämän toiminnon, valitun elementin väriasetukset poistetaan ja asetetaan %s mukaisesti.',
                    moveElemToPrintingLayerTitle: 'Siirretäänkö valittu elementti kohteeseen %s ja muunnetaan se tulostuselementiksi?',
                    moveElemToPrintingLayerMsg: 'Huomioi, että jos suoritat tämän toiminnon, valitun elementin asetukset poistetaan ja asetetaan %s mukaisesti.',
                    splitColorTitle: 'Haluatko laajentaa valitun tason CMYK-tasoiksi?',
                    splitColorMsg: 'Huomioi, että jos jatkat tätä toimintoa, et voi palata alkuperäisiin väritasoihin.',
                    mergeLaserLayerToPrintingLayerTitle: 'Haluatko yhdistää nämä tasot yhdeksi tulostustasoksi?',
                    mergeLaserLayerToPrintingLayerMsg: 'Huomioi, että jos suoritat tämän toiminnon, laser-tason asetukset poistetaan ja asetetaan nykyisen tason mukaisesti.',
                    mergePrintingLayerToLaserLayerTitle: 'Haluatko yhdistää nämä tasot yhdeksi laser-tasoksi?',
                    mergePrintingLayerToLaserLayerMsg: 'Huomioi, että jos suoritat tämän toiminnon, tulostustason väriasetukset poistetaan ja asetetaan nykyisen tason mukaisesti.',
                },
                layers: {
                    layer: 'Taso',
                    layers: 'Tasot',
                    del: 'Poista taso',
                    move_down: 'Siirrä taso alaspäin',
                    new: 'Uusi taso',
                    rename: 'Nimeä taso uudelleen',
                    move_up: 'Siirrä taso ylöspäin',
                    dupe: 'Kopioi taso',
                    lock: 'Lukitse taso',
                    unlock: 'Poista lukitus',
                    merge_down: 'Yhdistä alas',
                    merge_all: 'Yhdistä kaikki',
                    merge_selected: 'Yhdistä valitut tasot',
                    move_elems_to: 'Siirrä elementit:',
                    move_selected: 'Siirrä valitut elementit eri tasolle',
                    switchToFullColor: 'Vaihda täysväritilaan',
                    switchToSingleColor: 'Vaihda yksiväritilaan',
                    splitFullColor: 'Laajenna tasoa',
                    fullColor: 'Täysväri',
                },
            },
            laser_panel: {
                preset_setting: 'Parametriasetukset (%s)',
                multi_layer: 'Useita tasoja',
                parameters: 'Parametrit',
                strength: 'Teho',
                pwm_advanced_setting: 'Syvyystilan Tehoasetukset',
                pwm_advanced_desc: 'Aseta vähimmäisteho syvyystilaan.',
                pwm_advanced_hint: 'Tämä asetus koskee erityisesti liukuvärikuvia käytettäessä syvyystilaa.',
                low_power_warning: 'Alhainen laserteho (alle 10 %) ei välttämättä emittoi lasersädettä.',
                speed: 'Nopeus',
                speed_contrain_warning: 'Vektoripolkuobjektien leikkausnopeus rajoitetaan %(limit)s. Voit poistaa tämän rajoituksen asetuksista.',
                low_speed_warning: 'Alhainen nopeus voi aiheuttaa materiaalin palamista.',
                promark_speed_desc: 'Nopeusparametrit eivät koske liukuvärikuvia.',
                repeat: 'Ohitusmäärä',
                advanced: 'Edistynyt',
                lower_focus: 'Laske Tarkennusta',
                by: 'Määrällä',
                stepwise_focusing: 'Askeltarkennus',
                single_color: 'Yksivärinen',
                lower_focus_desc: 'Fokuskorkeuden laskeminen tietyn matkan verran tarkennuksen jälkeen parantaaksesi leikkaustehokkuutta.',
                stepwise_focusing_desc: 'Fokusetäisyyden asteittainen laskeminen objektin korkeuden perusteella jokaisen ohitusmäärän aikana.',
                single_color_desc: 'Vain täysvärikerrokselle ja ei sovellu laajennettuun yksivärikerrokseen.',
                focus_adjustment: 'Tarkennuksen säätö',
                height: 'Kohteen korkeus',
                z_step: 'Z-askel',
                diode: 'Diodilaser',
                backlash: 'Takaisinkytkentä',
                ink_saturation: 'Kylläisyys',
                print_multipass: 'Monikerrospainatus',
                white_ink: 'Valkoinen muste',
                white_ink_settings: 'Valkoisen musteen asetukset',
                color_adjustment: 'Värikanavan säätö',
                color_adjustment_short: 'Värikanava',
                halftone: 'Puolitonni',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Voimakkuus',
                times: 'kertaa',
                cut: 'Leikkaa',
                engrave: 'Kaiverra',
                more: 'Hallitse',
                apply: 'Käytä',
                custom_preset: 'Mukautettu',
                various_preset: 'Useita esiasetuksia',
                module: 'Moduuli',
                pulse_width: 'Pulssin leveys',
                frequency: 'Taajuus',
                fill_setting: 'Täyttöasetukset',
                fill_interval: 'Täyttöväli',
                fill_angle: 'Täyttökulma',
                bi_directional: 'Kaksisuuntainen',
                cross_hatch: 'Ristikkäinen Varjostus',
                dottingTime: 'Pisteytysaika',
                gradient_only: 'Vain liukuväriä varten',
                filled_path_only: 'Vain täyttöpoluille',
                slider: {
                    regular: 'Normaali',
                    low: 'Matala',
                    very_low: 'Minimi',
                    high: 'Korkea',
                    very_high: 'Maksimi',
                    slow: 'Hidas',
                    very_slow: 'Hidain',
                    fast: 'Nopea',
                    very_fast: 'Nopein',
                },
                dropdown: {
                    parameters: 'Esiasetukset',
                    save: 'Lisää nykyiset parametrit',
                    mm: {
                        wood_3mm_cutting: 'Puu - 3 mm leikkaus',
                        wood_5mm_cutting: 'Puu - 5 mm leikkaus',
                        wood_7mm_cutting: 'Puu - 7mm leikkaus',
                        wood_8mm_cutting: 'Puu - 8 mm leikkaus',
                        wood_10mm_cutting: 'Puu - 10 mm leikkaus',
                        wood_engraving: 'Puu - kaiverrus',
                        acrylic_3mm_cutting: 'Akryyli - 3 mm leikkaus',
                        acrylic_5mm_cutting: 'Akryyli - 5 mm leikkaus',
                        acrylic_8mm_cutting: 'Akryyli - 8 mm leikkaus',
                        acrylic_10mm_cutting: 'Akryyli - 10 mm leikkaus',
                        acrylic_engraving: 'Akryyli - kaiverrus',
                        mdf_3mm_cutting: 'MDF - 3 mm leikkaus',
                        mdf_5mm_cutting: 'MDF - 5 mm leikkaus',
                        mdf_engraving: 'MDF - kaiverrus',
                        leather_3mm_cutting: 'Nahka - 3 mm leikkaus',
                        leather_5mm_cutting: 'Nahka - 5 mm leikkaus',
                        leather_engraving: 'Nahka - kaiverrus',
                        denim_1mm_cutting: 'Denim - 1 mm leikkaus',
                        fabric_3mm_cutting: 'Kangas - 3 mm leikkaus',
                        fabric_5mm_cutting: 'Kangas - 5 mm leikkaus',
                        fabric_engraving: 'Kangas - kaiverrus',
                        rubber_bw_engraving: 'Kumi - kaiverrus',
                        glass_bw_engraving: 'Lasi - kaiverrus',
                        metal_bw_engraving: 'Metalli - kaiverrus',
                        steel_engraving_spray_engraving: 'Metalli - kaiverrus',
                        stainless_steel_bw_engraving_diode: 'Ruostumaton teräs - kaiverrus (Diodilaser)',
                        gold_engraving: 'Kulta - kaiverrus',
                        brass_engraving: 'Messinki - kaiverrus',
                        ti_engraving: 'Titaani - kaiverrus',
                        stainless_steel_engraving: 'Ruostumaton teräs - kaiverrus',
                        aluminum_engraving: 'Alumiini - kaiverrus',
                        black_acrylic_3mm_cutting: 'Musta akryyli - 3 mm leikkaus',
                        black_acrylic_5mm_cutting: 'Musta akryyli - 5 mm leikkaus',
                        black_acrylic_engraving: 'Musta akryyli - kaiverrus',
                        abs_engraving: 'ABS - kaiverrus',
                        silver_engraving: 'Hopea - kaiverrus',
                        iron_engraving: 'Rauta - kaiverrus',
                        fabric_printing: 'Kangas - tulostus',
                        canvas_printing: 'Kanvas - tulostus',
                        cardstock_printing: 'Pahvi - tulostus',
                        wood_printing: 'Puu - tulostus',
                        bamboo_printing: 'Bambu - tulostus',
                        cork_printing: 'Korkki - tulostus',
                        flat_stone_printing: 'Tasainen kivi - tulostus',
                        acrylic_printing: 'Akryyli - tulostus',
                        pc_printing: 'PC - tulostus',
                        stainless_steel_printing: 'Ruostumaton teräs - tulostus',
                        gloss_leather_printing: 'Kiiltävä nahka - tulostus',
                        glass_printing: 'Lasi - tulostus',
                        aluminum_light: 'Alumiini (vaalea)',
                        stainless_steel_dark: 'Ruostumaton teräs (tumma)',
                        stainless_steel_light: 'Ruostumaton teräs (vaalea)',
                        brass_dark: 'Messinki (tumma)',
                        brass_light: 'Messinki (vaalea)',
                        copper: 'Kupari',
                        titanium_dark: 'Titaani (tumma)',
                        titanium_light: 'Titaani (vaalea)',
                        black_abs: 'Musta ABS',
                        white_abs: 'Valkoinen ABS',
                        opaque_acrylic: 'Läpinäkymätön akryyli',
                        stone: 'Kivi',
                    },
                    inches: {
                        wood_3mm_cutting: 'Puu - 0,1" leikkaus',
                        wood_5mm_cutting: 'Puu - 0,2" leikkaus',
                        wood_7mm_cutting: 'Puu - 0,3" leikkaus',
                        wood_8mm_cutting: 'Puu - 0,3" leikkaus',
                        wood_10mm_cutting: 'Puu - 0,4" leikkaus',
                        wood_engraving: 'Puu - kaiverrus',
                        acrylic_3mm_cutting: 'Akryyli - 0,1" leikkaus',
                        acrylic_5mm_cutting: 'Akryyli - 0,2" leikkaus',
                        acrylic_8mm_cutting: 'Akryyli - 0,3" leikkaus',
                        acrylic_10mm_cutting: 'Akryyli - 0,4" leikkaus',
                        acrylic_engraving: 'Akryyli - kaiverrus',
                        mdf_3mm_cutting: 'MDF - 0,1" leikkaus',
                        mdf_5mm_cutting: 'MDF - 0,2" leikkaus',
                        mdf_engraving: 'MDF - kaiverrus',
                        leather_3mm_cutting: 'Nahka - 0,1" leikkaus',
                        leather_5mm_cutting: 'Nahka - 0,2" leikkaus',
                        leather_engraving: 'Nahka - kaiverrus',
                        denim_1mm_cutting: 'Denim - 0,04" leikkaus',
                        fabric_3mm_cutting: 'Kangas - 0,1" leikkaus',
                        fabric_5mm_cutting: 'Kangas - 0,2" leikkaus',
                        fabric_engraving: 'Kangas - kaiverrus',
                        rubber_bw_engraving: 'Kumi - kaiverrus',
                        glass_bw_engraving: 'Lasi - kaiverrus',
                        metal_bw_engraving: 'Metalli - kaiverrus',
                        steel_engraving_spray_engraving: 'Metalli - kaiverrus',
                        stainless_steel_bw_engraving_diode: 'Metalli - kaiverrus (Diodilaser)',
                        gold_engraving: 'Kulta - kaiverrus',
                        brass_engraving: 'Messinki - kaiverrus',
                        ti_engraving: 'Titaani - kaiverrus',
                        stainless_steel_engraving: 'Ruostumaton teräs - kaiverrus',
                        aluminum_engraving: 'Alumiini - kaiverrus',
                        black_acrylic_3mm_cutting: 'Musta akryyli - 0,1" leikkaus',
                        black_acrylic_5mm_cutting: 'Musta akryyli - 0,2" leikkaus',
                        black_acrylic_engraving: 'Musta akryyli - kaiverrus',
                        abs_engraving: 'ABS - kaivertaminen',
                        silver_engraving: 'Hopea - kaivertaminen',
                        iron_engraving: 'Rauta - kaivertaminen',
                        fabric_printing: 'Kangas - tulostus',
                        canvas_printing: 'Kanvas - tulostus',
                        cardstock_printing: 'Pakkopahvi - tulostus',
                        wood_printing: 'Puu - tulostus',
                        bamboo_printing: 'Bambusu - tulostus',
                        cork_printing: 'Korkki - tulostus',
                        flat_stone_printing: 'Tasainen kivi - tulostus',
                        acrylic_printing: 'Akryyli - tulostus',
                        pc_printing: 'PC - tulostus',
                        stainless_steel_printing: 'Ruostumaton teräs - tulostus',
                        gloss_leather_printing: 'Kiiltävä nahka - tulostus',
                        glass_printing: 'Lasi - tulostus',
                        aluminum_light: 'Alumiini (vaalea)',
                        stainless_steel_dark: 'Ruostumaton teräs (tumma)',
                        stainless_steel_light: 'Ruostumaton teräs (vaalea)',
                        brass_dark: 'Messinki (tumma)',
                        brass_light: 'Messinki (vaalea)',
                        copper: 'Kupari',
                        titanium_dark: 'Titaani (tumma)',
                        titanium_light: 'Titaani (vaalea)',
                        black_abs: 'Musta ABS',
                        white_abs: 'Valkoinen ABS',
                        opaque_acrylic: 'Läpinäkymätön akryyli',
                        stone: 'Kivi',
                    },
                },
                laser_speed: {
                    text: 'Nopeus',
                    unit: 'mm/s',
                    fast: 'Nopea',
                    slow: 'Hidas',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Teho',
                    high: 'Korkea',
                    low: 'Matala',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Mustetyyppi',
                    normal: 'Muste',
                    UV: 'UV-muste',
                },
                para_in_use: 'Tätä parametriä käytetään.',
                do_not_adjust_default_para: 'Oletusasetuksia ei voi säätää.',
                existing_name: 'Tämä nimi on jo käytössä.',
                presets: 'Esiasetus',
                preset_management: {
                    preset: 'Esiasetus',
                    title: 'Hallitse Parametrejä',
                    add_new: 'Lisää Uusi',
                    lower_focus_by: 'Laske Tarkennusta',
                    save_and_exit: 'Tallenna ja poistu',
                    delete: 'Poista',
                    reset: 'Nollaa',
                    sure_to_reset: 'Tämä poistaa mukautetut parametrisi ja palauttaa kaikki esiasetukset, oletko varma, että haluat jatkaa?',
                    show_all: 'Näytä Kaikki',
                    laser: 'Laser',
                    print: 'Tulosta',
                    export: 'Vie',
                    export_preset_title: 'Vie esiasetukset',
                    import: 'Tuo',
                    sure_to_import_presets: 'Tämä lataa esiasetusten järjestelyn ja korvaa mukautetut parametrit. Oletko varma, että haluat jatkaa?',
                    new_preset_name: 'Uusi esiasetuksen nimi',
                },
            },
            object_panel: {
                zoom: 'Lähennä',
                group: 'Ryhmitä',
                ungroup: 'Poista ryhmitys',
                distribute: 'Jaa',
                hdist: 'Jaa vaakasuunnassa',
                vdist: 'Jaa pystysuunnassa',
                align: 'Tasaa',
                left_align: 'Tasaa vasemmalle',
                center_align: 'Keskitä',
                right_align: 'Tasaa oikealle',
                top_align: 'Tasaa ylös',
                middle_align: 'Keskitä pystyssä',
                bottom_align: 'Tasaa alas',
                boolean: 'Boolen',
                union: 'Yhdistä',
                subtract: 'Vähennä',
                intersect: 'Leikkaa',
                difference: 'Ero',
                flip: 'Käännä',
                hflip: 'Vaakasuora kääntö',
                vflip: 'Pystysuora kääntö',
                lock_aspect: 'Lukitse mittasuhde',
                unlock_aspect: 'Poista mittasuhteen lukitus',
                option_panel: {
                    fill: 'Täyttö',
                    rounded_corner: 'Pyöristetty kulma',
                    sides: 'Sivut',
                    font_family: 'Fontti',
                    font_style: 'Tyyli',
                    font_size: 'Koko',
                    letter_spacing: 'Kirjainväli',
                    line_spacing: 'Riviväli',
                    vertical_text: 'Pystyteksti',
                    start_offset: 'Tekstin siirtymä',
                    vertical_align: 'Tasaus',
                    text_infill: 'Tekstin täyttö',
                    path_infill: 'Polun täyttö',
                    shading: 'Liukuväri',
                    pwm_engraving: 'Syvyystila',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Kynnysarvo',
                    threshold_short: 'Kynnys',
                    stroke: 'Ääriviiva',
                    stroke_color: 'Ääriviivan väri',
                    stroke_width: 'Viivan leveys',
                    color: 'Väri',
                },
                actions_panel: {
                    replace_with: 'Korvaa...',
                    replace_with_short: 'Korvaa',
                    trace: 'Jäljitä',
                    grading: 'Arvostelu',
                    brightness: 'Kirkkaus',
                    sharpen: 'Terävöitä',
                    crop: 'Rajaa',
                    bevel: 'Viiste',
                    invert: 'Käännä',
                    weld_text: 'Yhdistä teksti',
                    convert_to_path: 'Muunna poluksi',
                    fetching_web_font: 'Noudetaan verkkofonttia...',
                    uploading_font_to_machine: 'Ladataan fontti koneeseen...',
                    wait_for_parsing_font: 'Jäsennetään fonttia... Odota hetki',
                    offset: 'Siirtymä',
                    array: 'Taulukko',
                    auto_fit: 'Automaattinen Sovitus',
                    smart_nest: 'Älykäs Pesä',
                    decompose_path: 'Hajota',
                    disassemble_use: 'Purkaa',
                    create_textpath: 'Luo teksti polulle',
                    create_textpath_short: 'Polun teksti',
                    detach_path: 'Purkaa polun teksti',
                    detach_path_short: 'Purkaa',
                    edit_path: 'Muokkaa polkua',
                    disassembling: 'Purkaa...',
                    ungrouping: 'Poista ryhmitys...',
                    simplify: 'Yksinkertaista',
                    ai_bg_removal: 'Taustan poisto',
                    ai_bg_removal_short: 'TP',
                    ai_bg_removal_reminder: 'Painamalla nappia käytetään välittömästi 0,2 hyvitystä, haluatko jatkaa?',
                    outline: 'Ääriviiva',
                },
                path_edit_panel: {
                    node_type: 'SOLMUN TYYPPI',
                    sharp: 'Terävä',
                    round: 'Pyöreä',
                    connect: 'Yhdistä',
                    disconnect: 'Katkaise yhteys',
                    delete: 'Poista',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Muunna teksti poluksi...',
            retreive_image_data: 'Nouda kuvan tiedot...',
            export_file_error_ask_for_upload: 'Vientitehtävän epäonnistui. Haluatko tarjota toimivan näkymän kehitystiimille vianraportointia varten?',
        },
        image_trace_panel: {
            apply: 'Käytä',
            back: 'Takaisin',
            cancel: 'Peruuta',
            next: 'Seuraava',
            brightness: 'Kirkkaus',
            contrast: 'Kontrasti',
            threshold: 'Kynnys',
            okay: 'OK',
            tuning: 'Parametrit',
        },
        photo_edit_panel: {
            apply: 'Käytä',
            back: 'Takaisin',
            cancel: 'Peruuta',
            next: 'Seuraava',
            sharpen: 'Terävöitä',
            sharpness: 'Terävyys',
            radius: 'Säde',
            crop: 'Rajaa',
            aspect_ratio: 'Kuvasuhde',
            original: 'Alkuperäinen',
            free: 'Vapaa',
            curve: 'Käyrä',
            start: 'Aloita',
            processing: 'Käsitellään',
            invert: 'Käännä värit',
            okay: 'OK',
            compare: 'Vertaa',
            phote_edit: 'Kuvan muokkaus',
            brightness_and_contrast: 'Kirkkaus / Kontrasti',
            brightness: 'Kirkkaus',
            contrast: 'Kontrasti',
            rotary_warped: 'Kiertyvä Vääristymä',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Halkaisija',
            circumference: 'Ympärys',
            warp: 'Vääristymä',
        },
        document_panel: {
            document_settings: 'Asiakirjan asetukset',
            machine: 'Kone',
            laser_source: 'Laserlähde',
            workarea: 'Työalue',
            rotary_mode: 'Pyörivä',
            borderless_mode: 'Avoin pohja',
            engrave_dpi: 'Resoluutio',
            enable_diode: 'Diodilaser',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Laajenna työaluetta',
            mirror: 'Peilaus',
            pass_through: 'Läpäisymoodi',
            pass_through_height_desc: 'Syötä objektin pituus työalueen laajentamiseksi.',
            start_position: 'Aloituspaikka',
            start_from: 'Aloita Mistä',
            origin: 'Alkuperä',
            current_position: 'Nykyinen Sijainti',
            job_origin: 'Työn Alkuperä',
            add_on: 'Lisäosat',
            low: 'Matala',
            medium: 'Keskitaso',
            high: 'Korkea',
            ultra: 'Erittäin korkea',
            enable: 'Ota käyttöön',
            disable: 'Poista käytöstä',
            notification: {
                changeFromPrintingWorkareaTitle: 'Haluatko muuntaa tulostuskerrokset laserkerroksiksi?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Jäsentää fonttia... Odota hetki',
            text_to_path: {
                font_substitute_pop: 'Tekstissäsi on merkkejä, joita nykyinen fontti ei tue. <br/>Haluaisitko käyttää <strong>%s</strong> korvaavana fonttina?',
                check_thumbnail_warning: 'Joitakin tekstejä vaihdettiin toisiin fontteihin muunnettaessa tekstejä poluiksi ja joitakin merkkejä ei ehkä muunnettu oikein. <br/>Tarkista esikatselukuva uudelleen ennen tehtävän lähettämistä.',
                error_when_parsing_text: 'Virhe tekstiä poluksi muunnettaessa',
                use_current_font: 'Käytä nykyistä fonttia',
                retry: 'Yritä myöhemmin uudelleen tai valitse toinen fontti',
            },
            lock_desc: 'Säilytä leveyden ja korkeuden suhde (SHIFT)',
        },
        tool_panels: {
            cancel: 'Peruuta',
            confirm: 'Vahvista',
            grid_array: 'Luo ruudukko',
            array_dimension: 'Ruudukon mitat',
            rows: 'Rivit',
            columns: 'Sarakkeet',
            array_interval: 'Ruudukon väli',
            dx: 'X',
            dy: 'Y',
            offset: 'Siirtymä',
            nest: 'Järjestelyoptimointi',
            _offset: {
                direction: 'Siirtymän suunta',
                inward: 'Sisäänpäin',
                outward: 'Ulospäin',
                dist: 'Siirtymän etäisyys',
                corner_type: 'Kulma',
                sharp: 'Terävä',
                round: 'Pyöreä',
                fail_message: 'Siirtymä epäonnistui.',
                not_support_message: 'Valitut elementit sisältävät SVG-tageja, joita ei tueta:\nKuva, Ryhmä, Teksti ja Tuotu objekti.',
            },
            _nest: {
                start_nest: 'Järjestä',
                stop_nest: 'Pysäytä',
                end: 'Sulje',
                spacing: 'Väli',
                rotations: 'Mahdolliset kierrot',
                no_element: 'Ei ole järjestettäviä elementtejä',
            },
        },
        network_testing_panel: {
            network_testing: 'Verkkotestaus',
            local_ip: 'Paikallinen IP-osoite:',
            insert_ip: 'Kohdelaitteen IP-osoite:',
            empty_ip: '#818 Anna kohdelaitteen IP-osoite.',
            start: 'Aloita',
            end: 'Lopeta',
            testing: 'Testataan verkkoa...',
            invalid_ip: '#818 Virheellinen IP-osoite',
            ip_startswith_169: '#843 Laitteen IP-osoite alkaa 169.254',
            connection_quality: 'Yhteyden laatu',
            average_response: 'Keskimääräinen vasteaika',
            test_completed: 'Testi valmis',
            test_fail: 'Testi epäonnistui',
            cannot_connect_1: '#840 Yhteyden muodostaminen kohde-IP-osoitteeseen epäonnistui.',
            cannot_connect_2: '#840 Yhteyden muodostaminen kohde-IP-osoitteeseen epäonnistui. Varmista, että kohde on samassa verkossa.',
            network_unhealthy: '#841 Yhteyden laatu <70 tai keskimääräinen vasteaika >100 ms',
            device_not_on_list: '#842 Laite ei ole listalla, mutta yhteyden laatu on >70 ja keskimääräinen vasteaika on <100 ms',
            hint_device_often_on_list: 'Laite ei usein löydy listalta?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Yhteyden muodostaminen epäonnistui työn lähetyksen yhteydessä?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Aikakatkaisu esikatselun käynnistyksen yhteydessä?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Paikallisen IP-osoitteen käyttö epäonnistui.',
            fail_to_start_network_test: '#817 Verkkotestauksen käynnistys epäonnistui.',
            linux_permission_hint: 'Tämä virhe johtuu yleensä riittämättömistä käyttöoikeuksista.\nSuorita terminaalissa komento "sudo beam-studio --no-sandbox" käyttöoikeuksien saamiseksi ja verkkotestauksen suorittamiseksi.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Kerroksen värimääritykset',
            color: 'Väri',
            power: 'Teho',
            speed: 'Nopeus',
            repeat: 'Suorita',
            add: 'Lisää',
            default: 'Palauta oletusarvot',
            add_config: 'Lisää väri',
            in_use: 'Tätä väriä käytetään.',
            no_input: 'Anna kelvollinen heksavärikoodi.',
            sure_to_reset: 'Menetät kaikki mukautetut asetukset, haluatko varmasti palauttaa oletusarvot?',
            sure_to_delete: 'Haluatko varmasti poistaa tämän väriasetuksen?',
        },
        rating_panel: {
            title: 'Pidätkö Beam Studio -ohjelmasta?',
            description: 'Jos pidät Beam Studiosta, arvostaisimme suuresti, jos voisit arvostella meidät.',
            dont_show_again: 'Älä näytä tätä seuraavalla kerralla.',
            thank_you: 'Kiitos!',
        },
        svg_editor: {
            unnsupported_file_type: 'Tiedostotyyppiä ei tueta suoraan. Muunna tiedosto SVG:ksi tai bittikartaksi',
            unable_to_fetch_clipboard_img: 'Leikepöydältä kuvan haku epäonnistui',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Toista',
            pause: 'Keskeytä',
            stop: 'Pysäytä',
            play_speed: 'Toistonopeus',
            travel_path: 'Siirtymäreitti',
            invert: 'Käännä',
            preview_info: 'Esikatselutiedot',
            size: 'Koko',
            estimated_time: 'Arvioitu kokonaisaika',
            cut_time: 'Leikkausaika',
            rapid_time: 'Siirtymäaika',
            cut_distance: 'Leikkausmatka',
            rapid_distance: 'Siirtymämatka',
            current_position: 'Nykyinen sijainti',
            remark: '* Kaikki tiedot ovat arvioituja viitearvoja.',
            start_here: 'Aloita tästä',
            end_preview: 'Lopeta esikatselu',
        },
        shapes_panel: {
            title: 'Kuvakkeet',
            basic: 'Perus',
            shape: 'Muoto',
            graphics: 'Grafiikka',
            arrow: 'Nuoli',
            label: 'Teksti',
            decor: 'Koriste',
            circular: 'Ympyrä',
            corner: 'Kulma',
            line: 'Viiva',
            photo: 'Valokuva',
            ribbon: 'Nauha',
            speech: 'Puhe',
            text: 'Tekstikehys',
            animals: 'Eläin',
            birds: 'Lintu',
            land: 'Maa',
            sea: 'Meri',
            holidays: 'Juhlapäivä',
            celebration: 'Juhlinta',
            CNY: 'Kiinalainen uusivuosi',
            easter: 'Pääsiäinen',
            halloween: 'Halloween',
            valentines: 'Ystävänpäivä',
            Xmas: 'Joulu',
            nature: 'Luonto',
            elements: 'Alkuaineet',
            environment: 'Ympäristö',
            plants: 'Kasvit',
            weather: 'Sää',
        },
        announcement_panel: {
            title: 'Tiedote',
            dont_show_again: 'Älä näytä uudelleen',
        },
    },
    editor: {
        prespray_area: 'Esisuihkutusalue',
        opacity: 'Esikatselun läpinäkyvyys',
        exposure: 'Esikatselun altistus',
    },
    flux_id_login: {
        connection_fail: '#847 Yhteyden muodostaminen FLUX-jäsenyyteen epäonnistui.',
        login_success: 'Kirjautuminen onnistui.',
        login: 'Kirjaudu sisään',
        unlock_shape_library: 'Kirjaudu sisään avataksesi muotokirjaston.',
        email: 'Sähköposti',
        password: 'Salasana',
        remember_me: 'Muista minut',
        forget_password: 'Unohtunut salasana?',
        register: 'Luo FLUX-tili',
        offline: 'Työskentele offline-tilassa',
        work_offline: 'Työskentele offline-tilassa',
        incorrect: 'Virheellinen sähköpostiosoite tai salasana',
        not_verified: 'Sähköpostiosoitetta ei ole vielä vahvistettu',
        new_to_flux: 'Uusi käyttäjä? Luo tili',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Tutustu FLUX+ -suunnitelmiin',
            thank_you: 'Kiitos arvokkaasta jäsenyydestä!',
            ai_credit_tooltip: 'Taustan poistoon tekoälyllä',
            flux_credit_tooltip: 'Design Market -tiedostoihin ja tekoälyn taustanpoistoon',
            goto_member_center: 'Siirry jäsenkeskukseen',
            access_plus_feature_1: 'Käytät',
            access_plus_feature_2: 'ominaisuutta.',
            access_plus_feature_note: 'Sinulla on oltava FLUX+ -jäsenyys käyttääksesi tätä ominaisuutta.',
            access_monotype_feature: 'Sinulla ei ole Monotype-fontteja.',
            access_monotype_feature_note: 'Sinulla on oltava FLUX+ Pro -jäsenyys tai Monotype-fontit lisäosa käyttääksesi tätä ominaisuutta.',
            learn_more: 'Lue lisää',
            get_addon: 'Hanki lisäosa',
            subscribe_now: 'Tilaa nyt',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI-taustanpoisto',
                my_cloud: 'Rajoittamaton pilvitallennustila',
                boxgen: '3D-laatikon luonti',
                dmkt: 'Yli 1000 suunnittelutiedostoa',
                monotype: 'Yli 250 Premium-fonttia',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Kirjaudu sisään avataksesi muotokirjaston',
        enjoy_shape_library: 'Nauti muotokirjastosta',
        shapes: 'Muodot',
        elements: 'Elementit',
        recent: 'Viimeaikaiset',
        search: 'Etsi',
        clear: 'Tyhjennä',
        export_svg_title: 'SVG-viennin virhe',
        export_svg_warning: 'Tämä projekti sisältää tekijänoikeussuojan alaisia objekteja. Beam Studio jättää nämä objektit automaattisesti pois viennin yhteydessä. Voit silti tallentaa projektisi Beam Studio -kohtauksena (.beam-muodossa) säilyttääksesi kaikki tiedot. Haluatko silti viedä?',
        learn_more: 'Lue lisää',
    },
    change_logs: {
        change_log: 'Muutosloki:',
        added: 'Lisätty:',
        fixed: 'Korjattu:',
        changed: 'Muutettu:',
        see_older_version: 'Katso vanhempia versioita',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Valitse laite',
        auth_failure: '#811 Todennus epäonnistui',
        unable_to_connect: '#810 Yhteyden muodostaminen laitteeseen epäonnistui',
    },
    device: {
        pause: 'Keskeytä',
        paused: 'Keskeytetty',
        pausing: 'Keskeytetään',
        select_printer: 'Valitse tulostin',
        retry: 'Yritä uudelleen',
        status: 'Tila',
        busy: 'Varattu',
        ready: 'Valmis',
        reset: 'Nollaa',
        abort: 'Keskeytä',
        start: 'Käynnistä',
        please_wait: 'Odota...',
        quit: 'Lopeta',
        completing: 'Viimeistelee',
        aborted: 'Keskeytetty',
        completed: 'Valmis',
        aborting: 'Keskeytetään',
        starting: 'Käynnistyy',
        preparing: 'Valmistelee',
        resuming: 'Jatkaa',
        scanning: 'Skannaa',
        occupied: 'Huoltaa',
        running: 'Työskentelee',
        uploading: 'Lähettää',
        processing: 'Käsittelee',
        disconnectedError: {
            caption: 'Laite irrotettu',
            message: 'Varmista, onko %s:n verkkoyhteys käytettävissä',
        },
        unknown: 'Tuntematon',
        pausedFromError: 'Keskeytetty virheestä',
        model_name: 'Mallin nimi',
        IP: 'IP',
        serial_number: 'Sarjanumero',
        firmware_version: 'Laiteohjelmiston versio',
        UUID: 'UUID',
        select: 'Valitse',
        deviceList: 'Laiteluettelo',
        disable: 'Poista käytöstä',
        enable: 'Ota käyttöön',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: 'Vaihda työkalupää',
    },
    monitor: {
        monitor: 'MONITORI',
        go: 'Aloita',
        resume: 'Jatka',
        start: 'Aloita',
        pause: 'Keskeytä',
        stop: 'Pysäytä',
        record: 'Tallenna',
        camera: 'Kamera',
        taskTab: 'Tehtävä',
        connecting: 'Yhdistetään, odota...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kriittinen virhe: Pääkortti offline. Ota yhteyttä FLUX-tukeen.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kriittinen virhe: Alijärjestelmä ei vastaa. Ota yhteyttä FLUX-tukeen.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Tarkista vesisäiliö.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Sulje ovi jatkaaksesi',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Ylikuumeneminen. Odota muutama minuutti',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Alaosa auki. Sulje jatkaaksesi',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-akselin nollaaminen epäonnistui',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Laatikko auki',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Liekkianturi poikkeava',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Ilmavirtausanturi poikkeava',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Pyörivää moduulia ei havaittu',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Kotiutumisen irrotusvirhe',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Virheellinen moduuli havaittu. Asenna oikea moduuli jatkaaksesi.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Moduulia ei havaittu. Varmista, että moduuli on asennettu oikein jatkaaksesi.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Tuntematon moduuli havaittu. Asenna oikea moduuli jatkaaksesi.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Tulostinmoduuli ei vastaa.',
        USER_OPERATION_ROTARY_PAUSE: 'Vaihda pyörivään moottoriin',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Poista kasetti jatkaaksesi.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Virheellinen moduuli havaittu. Asenna oikea moduuli jatkaaksesi.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Moduulia ei havaittu. Varmista, että moduuli on asennettu oikein jatkaaksesi.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Tuntematon moduuli havaittu. Asenna oikea moduuli jatkaaksesi.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Asenna oikea moduuli jatkaaksesi.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Lisää patruuna jatkaaksesi.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Lisää oikea patruuna jatkaaksesi.',
        USER_OPERATION: 'Noudata laitteen paneelissa olevia ohjeita jatkaaksesi.',
        RESOURCE_BUSY: 'Laite varattu\nJos ei toimi, käynnistä uudelleen',
        DEVICE_ERROR: 'Jotain meni pieleen\nKäynnistä uudelleen',
        NO_RESPONSE: '#905 Virhe yhdistettäessä pääkorttiin.\nKäynnistä uudelleen.',
        SUBSYSTEM_ERROR: '#402 Kriittinen virhe: Alijärjestelmä ei vastaa. Ota yhteys tukeen.',
        HARDWARE_FAILURE: 'Jotain meni pieleen\nKäynnistä uudelleen',
        MAINBOARD_OFFLINE: '#905 Virhe yhdistettäessä pääkorttiin.\nKäynnistä uudelleen.',
        bug_report: 'Virheraportti',
        processing: 'Käsitellään',
        savingPreview: 'Luodaan pikkukuvia',
        hour: 'h',
        minute: 'min',
        second: 's',
        left: 'vasen',
        temperature: 'Lämpötila',
        forceStop: 'Haluatko keskeyttää nykyisen tehtävän?',
        upload: 'Lataa',
        download: 'Lataa',
        relocate: 'Siirrä',
        cancel: 'Peruuta',
        prepareRelocate: 'Valmistaudutaan siirtämään',
        extensionNotSupported: 'Tätä tiedostomuotoa ei tueta',
        fileExistContinue: 'Tiedosto on jo olemassa, haluatko korvata sen?',
        confirmFileDelete: 'Haluatko varmasti poistaa tämän tiedoston?',
        ask_reconnect: 'Yhteys koneeseen on katkennut. Haluatko yhdistää uudelleen?',
        task: {
            BEAMBOX: 'Laserleikkaus',
            'N/A': 'Vapaa tila',
        },
    },
    alert: {
        caption: 'Virhe',
        duplicated_preset_name: 'Kaksoiskappaleen esiasetuksen nimi',
        info: 'INFO',
        warning: 'VAROITUS',
        error: 'VIRHE',
        instruction: 'Ohje',
        oops: 'Hups',
        retry: 'Yritä uudelleen',
        abort: 'Keskeytä',
        confirm: 'Vahvista',
        cancel: 'Peruuta',
        close: 'Sulje',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Kyllä',
        no: 'Ei',
        stop: 'Pysäytä',
        save: 'Tallenna',
        dont_save: 'Älä tallenna',
        learn_more: 'Lisätietoja',
    },
    caption: {
        connectionTimeout: 'Yhteyden aikakatkaisu',
    },
    message: {
        cancelling: 'Peruuttaminen...',
        connecting: 'Yhdistetään...',
        connectingMachine: 'Yhdistetään %s...',
        tryingToConenctMachine: 'Yritetään yhdistää laitteeseen...',
        connected: 'Yhdistetty',
        authenticating: 'Todentaudutaan...',
        enteringRawMode: 'Siirrytään raakatilaan...',
        endingRawMode: 'Poistutaan raakatilasta...',
        enteringLineCheckMode: 'Siirrytään linjantarkistustilaan...',
        endingLineCheckMode: 'Poistutaan linjantarkistustilasta...',
        enteringRedLaserMeasureMode: 'Siirrytään punaisen lasermittauksen tilaan...',
        redLaserTakingReference: 'Otetaan viite...',
        exitingRotaryMode: 'Poistutaan pyörintätilasta...',
        turningOffFan: 'Sammutetaan tuuletin...',
        turningOffAirPump: 'Sammutetaan ilmapumppu...',
        gettingLaserSpeed: 'Luetaan laserpään nopeutta...',
        settingLaserSpeed: 'Asetetaan laserpään nopeutta...',
        retrievingCameraOffset: 'Luetaan kameran siirtymää...',
        connectingCamera: 'Yhdistetään kameraan...',
        homing: 'Hakeudutaan kotiasentoon...',
        connectionTimeout: '#805 Laitteen yhteys katkaistiin. Tarkista verkkoyhteytesi ja laitteen Wi-Fi-merkkivalo.',
        getProbePosition: 'Haetaan anturin sijaintia...',
        device_not_found: {
            caption: 'Oletuslaitetta ei löytynyt',
            message: '#812 Tarkista koneen Wi-Fi-merkkivalo',
        },
        device_busy: {
            caption: 'Kone varattu',
            message: 'Kone suorittaa toista tehtävää, yritä myöhemmin uudelleen. Jos se lakkaa toimimasta, käynnistä kone uudelleen.',
        },
        device_is_used: 'Konetta käytetään, haluatko keskeyttää nykyisen tehtävän?',
        unknown_error: '#821 Sovelluksessa tapahtui tuntematon virhe, ilmoita virheestä valikossa Apua > Valikko > Virheraportti.',
        unknown_device: '#826 Ei voi muodostaa yhteyttä koneeseen, varmista että USB on liitetty koneeseen',
        unsupport_osx_version: 'Nykyinen macOS-versio %s ei ehkä tue kaikkia toimintoja. Päivitä macOS 11+ -versioon.',
        unsupport_win_version: 'Nykyinen käyttöjärjestelmäversio %s ei ehkä tue kaikkia toimintoja. Päivitä uusimpaan versioon.',
        need_password: 'Tarvitaan salasana koneeseen yhdistämiseen',
        unavailableWorkarea: '#804 Nykyinen työalue ylittää koneen työalueen. Tarkista valitun koneen työalue tai aseta työalue valikosta Muokkaa > Asiakirja-asetukset.',
        please_enter_dpi: 'Anna tiedoston mittayksikkö (mm)',
        auth_error: '#820 Todennusvirhe: Päivitä Beam Studio ja koneen laiteohjelmisto uusimpaan versioon.',
        usb_unplugged: 'USB-yhteys katkesi. Tarkista USB-yhteys',
        uploading_fcode: 'Lähetetään FCodea',
        cant_connect_to_device: '#827 Ei voi muodostaa yhteyttä koneeseen, tarkista yhteys',
        unable_to_find_machine: 'Ei löydy konetta ',
        disconnected: 'Epävakaa yhteys, tarkista laitteen yhteys ja yritä myöhemmin uudelleen',
        unable_to_start: '#830 Tehtävän käynnistäminen ei onnistunut. Yritä uudelleen. Jos tämä toistuu, ota meihin yhteyttä vianraportilla:',
        camera: {
            camera_cable_unstable: 'Kameran kuvan siirrossa havaittiin epävakautta. Esikatselu toimii edelleen normaalisti, mutta esikatselussa voi ilmetä hitautta tai aikakatkaisuja.',
            fail_to_transmit_image: '#845 Kuvan siirrossa tapahtui virhe. Käynnistä kone tai Beam Studio uudelleen. Jos virhe ei poistu, noudata <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">tätä ohjetta</a>.',
            ws_closed_unexpectly: '#844 Yhteys kameran ja koneen välillä katkesi odottamatta. Jos virhe ei poistu, noudata <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">tätä ohjetta</a>.',
            continue_preview: 'Jatka',
            abort_preview: 'Keskeytä',
        },
        preview: {
            camera_preview: 'Kameran esikatselu',
            auto_focus: 'Automaattitarkennus',
            auto_focus_instruction: 'Siirrä lasermoduulin pää kohteen yläpuolelle ja seuraa animaatio-ohjeita painaaksesi AF tarkentaaksesi.',
            already_performed_auto_focus: 'Olet jo suorittanut automaattitarkennuksen, käytetäänkö olemassa olevia arvoja?',
            please_enter_height: 'Anna kohteen korkeus. Jotta kameran kuva olisi tarkka.',
            apply: 'Käytä',
            enter_manually: 'Anna manuaalisesti',
            adjust: 'Säädä',
            adjust_height_tooltip: 'Valitse valintaruutu muokataksesi.',
        },
        unsupported_example_file: 'Valittu esimerkkitiedosto ei ole tuettu tällä työalueella.',
        time_remaining: 'Aikaa jäljellä:',
        promark_disconnected: '#850 Yhteys laitteeseen katkaistu, tarkista laitteen yhteystila.',
        swiftray_disconnected: 'Yhteyttä backend-palvelimeen ei voitu muodostaa, yritetään uudelleen.',
        swiftray_reconnected: 'Backend on yhdistetty uudelleen, yritä lähettää tehtävä uudelleen.',
        device_blocked: {
            caption: 'Luvaton sarjanumero',
            online: 'Laitteesi sarjanumero näyttää olevan pois käytöstä. Anna laitteen sarjanumero (löytyy laitteen takaa) jälleenmyyjällesi ja pyydä häntä ottamaan yhteyttä osoitteeseen support@flux3dp.com laitteen valtuutuksen aktivoimiseksi.',
            offline: 'Laitteesi sarjanumero on rajoitettu offline-käyttöön, koska se näyttää olevan pois käytöstä. Anna laitteen sarjanumero (löytyy laitteen takaa) jälleenmyyjällesi ja pyydä häntä ottamaan yhteyttä osoitteeseen support@flux3dp.com online-valtuutuksen aktivoimiseksi. Jos haluat käyttää laitetta offline-tilassa, ota suoraan yhteyttä support@flux3dp.com.',
        },
    },
    machine_status: {
        '0': 'Käyttämätön',
        '1': 'Alustaa',
        '2': 'Muunnos',
        '4': 'Käynnistää',
        '6': 'Jatka',
        '16': 'Työskentelee',
        '18': 'Jatka',
        '32': 'Keskeytetty',
        '36': 'Keskeytetty',
        '38': 'Keskeytä',
        '48': 'Keskeytetty',
        '50': 'Keskeytä',
        '64': 'Valmis',
        '66': 'Viimeistelee',
        '68': 'Valmistelee',
        '128': 'Keskeytetty',
        '256': 'Hälytys',
        '512': 'Vakava virhe',
        '-17': 'Kasetin IO-tila',
        '-10': 'Huoltotila',
        '-2': 'Skannaa',
        '-1': 'Huoltaa',
        UNKNOWN: 'Tuntematon',
    },
    calibration: {
        update_firmware_msg1: 'Firmwaresi ei tue tätä toimintoa. Päivitä firmwareen v',
        update_firmware_msg2: 'tai uudempaan jatkaaksesi。 (Valikko > Kone > [Sinun koneesi] > Päivitä firmware)',
        camera_calibration: 'Kameran kalibrointi',
        diode_calibration: 'Diodilasermoduulin kalibrointi',
        module_calibration_printer: 'Tulostinmoduulin kalibrointi',
        module_calibration_2w_ir: 'Infrapunamoduulin kalibrointi',
        back: 'Takaisin',
        next: 'Seuraava',
        skip: 'Ohita',
        cancel: 'Peruuta',
        finish: 'Valmis',
        do_engraving: 'Tee Kaiverrus',
        start_engrave: 'Aloita Kaiverrus',
        start_printing: 'Aloita Tulostus',
        ask_for_readjust: 'Haluatko ohittaa kaiverrusvaiheen ja ottaa kuvan ja kalibroida suoraan?',
        please_goto_beambox_first: 'Siirry kaiverrustilaan (Beambox) käyttääksesi tätä ominaisuutta.',
        please_place_paper: 'Aseta A4- tai kirjekokoinen valkoinen paperi työalueen vasempaan ylänurkkaan.',
        please_place_paper_center: 'Aseta A4- tai kirjekokoinen valkoinen paperi työalueen keskelle.',
        please_place_dark_colored_paper: 'Aseta A4- tai kirjekokoinen tumma paperi työalueen keskelle.',
        please_refocus: {
            beambox: 'Säädä työtasoa polttoetäisyydelle (käännetyn akryylin korkeus).',
            beamo: 'Säädä laserpäätä kohdistumaan kaiverrettavaan esineeseen (käännetyn akryylin korkeus).',
            beamo_af: 'Napauta kaksoisnapautus autofokus-lisälaitteen sivupainiketta ja anna anturin koskettaa materiaalia kevyesti.',
            hexa: 'Napsauta korkeudensäätöpainiketta kahdesti nostaaksesi hunajakennopöydän ylös ja saadaksesi anturin koskettamaan kaiverrettavaa materiaalia.',
        },
        without_af: 'Ilman autofokusta',
        with_af: 'Autofokuksella',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Kierto',
        x_ratio: 'X-suhde',
        y_ratio: 'Y-suhde',
        show_last_config: 'Näytä viimeisin tulos',
        use_last_config: 'Käytä viimeisintä kalibrointiarvoa',
        taking_picture: 'Otetaan kuvaa...',
        analyze_result_fail: 'Kuvan analysointi epäonnistui.<br/>Tarkista:<br/>1. Kuvassa on kokonaan valkoinen paperi.<br/>2. Työtaso on tarkennettu oikein.',
        drawing_calibration_image: 'Piirretään kalibrointikuva...',
        calibrate_done: 'Kalibrointi valmis. Tarkempi kameratarkkuus saavutetaan tarkalla tarkennuksella.',
        calibrate_done_diode: 'Kalibrointi valmis. Diodilasermoduulin poikkeama on tallennettu.',
        hint_red_square: 'Kohdista punaisen neliön ulkoreuna leikatun neliön kanssa.',
        hint_adjust_parameters: 'Käytä näitä parametreja punaisen neliön säätämiseen',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Diodilasermoduulin kalibrointi vaatii kameran.\nVarmista, että tämän koneen kamera on kalibroitu.\nJa säädä alusta polttopisteeseen (käännetyn akryylin korkeus)',
            beamo: 'Diodilasermoduulin kalibrointi vaatii kameran.\nVarmista, että tämän koneen kamera on kalibroitu.\nJa kohdista laserpää kaiverrettavaan kohteeseen (käännetyn akryylin korkeus)',
        },
        downloading_pictures: 'Ladataan kuvia...',
        failed_to_download_pictures: '#848 Kuvien lataus epäonnistui, ota yhteyttä FLUX-tukeen.',
        uploading_images: 'Lähetetään kuvia...',
        calculating_camera_matrix: 'Lasketaan kameramatriisia...',
        calculating_regression_parameters: 'Lasketaan regressioparametreja...',
        failed_to_calibrate_camera: '#848 Kameran kalibrointi epäonnistui, ota yhteyttä FLUX-tukeen.',
        failed_to_save_calibration_results: '#849 Kalibrointitulosten tallennus epäonnistui, yritä uudelleen. Jos ongelma jatkuu, ota yhteyttä FLUX-tukeen.',
        ador_autofocus_material: 'Paina pääsivulla "AF"-kuvaketta 3 sekunnin ajan ja anna anturin koskettaa materiaalia kevyesti.',
        ador_autofocus_focusing_block: 'Paina koneen pääsivulla "AF"-kuvaketta 3 sekunnin ajan ja anna anturin koskettaa tarkennuslohkoa.',
        align_red_cross_cut: 'Kohdista punaisen ristin keskikohta leikkausristin kanssa.',
        align_red_cross_print: 'Kohdista punaisen ristin keskikohta tulostetun ristin kanssa.',
        retake: 'Ota kuva uudelleen',
        calibrate_camera_before_calibrate_modules: 'Suorita kamerakalibrointi ennen moduulien kalibrointia.',
        check_checkpoint_data: 'Tarkistuspisteen tiedot',
        checking_checkpoint: 'Tarkistetaan tarkistuspisteen tietoja...',
        found_checkpoint: 'Tarkistuspisteen tiedot löytyivät laitteestasi. Haluatko palauttaa ne tarkistuspisteestä?',
        use_old_camera_parameter: 'Haluatko käyttää nykyisiä kameran linssiparametreja?',
        downloading_checkpoint: 'Ladataan tarkistuspisteen tietoja...',
        failed_to_parse_checkpoint: 'Tarkistuspisteen tietojen jäsentäminen epäonnistui.',
        check_device_pictures: 'Tarkista laitteen kuvat',
        checking_pictures: 'Tarkistetaan laitteen kuvia...',
        no_picutre_found: '#846 Laitteellasi ei ole saatavilla raakakuvia kalibrointia varten. Ota yhteyttä FLUX-tukeen.',
        unable_to_load_camera_parameters: "#851 Laitteellasi ei ole saatavilla kameran kalibrointiparametreja. Siirry kohtaan 'Kalibrointi' > 'Kalibroi kamera (Edistynyt)' suorittaaksesi kalibroinnin ja saadaksesi parametrit.",
        calibrating_with_device_pictures: 'Kalibroidaan laitteen kuvien avulla...',
        failed_to_calibrate_with_pictures: '#848 Kalibrointi laitteen kuvilla epäonnistui.',
        getting_plane_height: 'Haetaan Tasokorkeutta...',
        preparing_to_take_picture: 'Valmistaudutaan valokuvan ottamiseen...',
        put_paper: 'Aseta paperi',
        put_paper_step1: 'Aseta A4- tai kirjekoon valkoinen paperi työalueen keskelle.',
        put_paper_step2: 'Kiinnitä paperin neljä kulmaa varmistaaksesi, että se on tasainen.',
        put_paper_step3: 'Napsauta "Aloita Kaiverrus".',
        put_paper_promark_1: 'Aseta musta pahvi tarvikelaatikosta työalustalle.',
        put_paper_promark_2: 'Säädä tarkennus oikein ja napsauta sitten "Aloita Kaiverrus" jatkaaksesi kaiverrusta.',
        put_paper_skip: 'Jos kalibrointipiirros ei siirry automaattisesti kuvauksen ottamiseen, paina "Ohita" ottaaksesi kuvan.',
        solve_pnp_title: 'Kohdista merkkipisteet',
        solve_pnp_step1: 'Kohdista kaiverruspisteet punaisen merkintäpisteen numeron ja odotetun sijainnin mukaan.',
        solve_pnp_step2: 'Voit painaa "Ota kuva uudelleen" uudelleen suuntautua tai säätää merkkipisteiden sijaintia manuaalisesti.',
        align_olt: 'Sijainti: Kohdista kaiverrettuun pisteeseen vasemmassa yläkulmassa ulkopuolella.',
        align_ort: 'Sijainti: Kohdista kaiverrettuun pisteeseen oikeassa yläkulmassa ulkopuolella.',
        align_olb: 'Sijainti: Kohdista kaiverrettuun pisteeseen vasemmassa alakulmassa ulkopuolella.',
        align_orb: 'Sijainti: Kohdista kaiverrettuun pisteeseen oikeassa alakulmassa ulkopuolella.',
        align_ilt: 'Sijainti: Kohdista kaiverrettuun pisteeseen vasemmassa yläkulmassa sisäpuolella.',
        align_irt: 'Sijainti: Kohdista kaiverrettuun pisteeseen oikeassa yläkulmassa sisäpuolella.',
        align_ilb: 'Sijainti: Kohdista kaiverrettuun pisteeseen vasemmassa alakulmassa sisäpuolella.',
        align_irb: 'Sijainti: Kohdista kaiverrettuun pisteeseen oikeassa alakulmassa sisäpuolella.',
        elevate_and_cut: 'Nosta ja Leikkaa',
        elevate_and_cut_step_1: 'Aseta A4-kokoinen vaaleanvärinen puu työtilan keskelle ja nosta se 20 mm korkeuteen.',
        elevate_and_cut_step_1_prism_lift: 'Käytä Ador Prism Liftiä, jonka maksimipituus on 14 mm, yhdessä vähintään 6 mm paksun puun kanssa.',
        with_prism_lift: 'Ador Prism Liftin kanssa',
        without_prism_lift: 'Ilman Ador Prism Liftiä',
        camera_parameter_saved_successfully: 'Kameran parametrit tallennettu onnistuneesti.',
        failed_to_save_camera_parameter: 'Kameran parametrien tallennus epäonnistui.',
        failed_to_solve_pnp: 'Kameran sijaintia ei voitu ratkaista.',
        calibrating: 'Kalibrointi...',
        moving_laser_head: 'Siirtää laserpäätä...',
        failed_to_move_laser_head: 'Laserpään siirtäminen epäonnistui.',
        put_chessboard: 'Aseta Shakkilauta',
        put_chessboard_bb2_desc_1: 'Lataa seuraava shakkilautatiedosto ja tulosta se A4-paperille (tulostetun shakkilaudan neliöiden koko tulee olla 1x1 cm).',
        put_chessboard_bb2_desc_2: 'Kiinnitä tulostettu shakkilauta muotonsa pitävälle alustalle, kuten akryylille tai lasille, varmistaen, että lauta on sileä eikä siinä ole ryppyjä tai kohoumia.',
        put_chessboard_bb2_desc_3: 'Aseta alusta, jossa on shakkilauta, tasaisesti työalueen keskelle.',
        put_chessboard_1: 'Mene laitteen ohjauspaneeliin ja paina AF suorittaaksesi automaattitarkennuksen.',
        put_chessboard_2: 'Siirrä shakkilautapaperia tai laserpäätä, kunnes koko shakkilauta vahvistetaan olevan punaisen kehyksen sisällä live-esikatseluikkunassa.',
        put_chessboard_3: 'Napsauta hiiren oikealla painikkeella ladataksesi shakkilautakuvan nykyisestä sijainnista ja tarkistaaksesi sen selkeyden.',
        put_chessboard_promark_desc_1: 'Käytä tarvikelaatikossa mukana toimitettua shakkilautapaperia tai tulosta seuraava shakkikuvio A4-paperille kameran kalibrointia varten.',
        put_chessboard_promark_desc_2: 'Säädä tarkennus oikein ja napsauta sitten "Seuraava" jatkaaksesi kaiverrusta.',
        put_chessboard_promark_1: 'Säädä polttoväli sopivaksi kenttälinssin tarkennukselle.',
        put_chessboard_promark_2: 'Varmista, että shakkilautapaperi on selkeästi näkyvissä ilman häikäisyä. Napsauta "Seuraava" ottaaksesi kuvan.',
        download_chessboard_file: 'Lataa shakkiruudukko-tiedosto',
        failed_to_calibrate_chessboard: 'Kalibrointi shakkilautakuvan kanssa epäonnistui.',
        calibrate_chessboard_success_msg: 'Shakkilautakuva otettiin onnistuneesti.<br/>Tämän kuvan pistemäärä on %s (%.2f).',
        res_excellent: 'Erinomainen',
        res_average: 'Keskitasoinen',
        res_poor: 'Heikko',
        perform_autofocus_bb2: 'Mene laitteen ohjauspaneeliin ja paina AF suorittaaksesi automaattitarkennuksen.',
        promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    },
    input_machine_password: {
        require_password: '"%s" vaatii salasanan',
        connect: 'YHDISTÄ',
        password: 'Salasana',
    },
    tutorial: {
        skip: 'Ohita',
        welcome: 'TERVETULOA',
        suggest_calibrate_camera_first: 'Suosittelemme käyttäjiä kalibroimaan kameran aluksi ja tarkentamaan uudelleen ennen jokaista esikatselua optimaalisten tulosten saavuttamiseksi.<br/>Haluatko vahvistaa suorittaaksesi kalibroinnin nyt?<br/>(Voit ohittaa sen nyt ja tehdä sen myöhemmin napsauttamalla "Valikko" > "Laite" > [Laiteesi] > "Kalibroi Kamera".)',
        camera_calibration_failed: 'Kameran kalibrointi epäonnistui',
        ask_retry_calibration: 'Haluaisitko kalibroida kameran uudelleen?',
        needNewUserTutorial: 'Haluaisitko aloittaa opastuksen?<br/>(Voit ohittaa sen nyt ja aloittaa myöhemmin napsauttamalla "Ohje" > "Näytä Aloita Opastus".)',
        needNewInterfaceTutorial: 'Haluaisitko aloittaa opastuksen Beam Studion uudelle käyttöliittymälle?<br/>(Voit ohittaa sen nyt ja aloittaa myöhemmin napsauttamalla "Ohje" > "Näytä Käyttöliittymän Esittely".)',
        next: 'SEURAAVA',
        look_for_machine: 'Etsitään konetta opetusohjelmaa varten...',
        unable_to_find_machine: 'Opetusohjelman konetta ei löydy. Haluatko mennä yhteysasetussivulle, yrittää uudelleen tai ohittaa opetusohjelman?',
        skip_tutorial: 'Ohitat opetusohjelman. Voit aina käynnistää opetusohjelman napsauttamalla "Ohje" > "Näytä aloita opetusohjelma"',
        set_connection: 'Aseta yhteys',
        retry: 'Yritä uudelleen',
        newUser: {
            draw_a_circle: 'Piirrä ympyrä',
            drag_to_draw: 'Vedä piirtääksesi',
            infill: 'Käännä täyttö päälle',
            switch_to_object_panel: 'Vaihda objektipaneeliin',
            switch_to_layer_panel: 'Vaihda kerrospaneeliin',
            set_preset_wood_engraving: 'Aseta esiasetus: Puu - kaiverrus',
            set_preset_wood_cut: 'Aseta esiasetus: Puu - leikkaus',
            add_new_layer: 'Lisää uusi taso',
            draw_a_rect: 'Piirrä suorakaide',
            switch_to_preview_mode: 'Vaihda esikatselutilaan',
            preview_the_platform: 'Esikatsele alustaa',
            end_preview_mode: 'Lopeta esikatselutila',
            put_wood: '1. Aseta puunäyte',
            adjust_focus: '2. Säädä tarkennusta',
            close_cover: '3. Sulje kansi',
            send_the_file: 'Lähetä tiedosto',
            end_alert: 'Oletko varma, että haluat lopettaa opetusohjelman?',
            please_select_wood_engraving: 'Valitse ystävällisesti "Puu - kaiverrus" -esiasetus.',
            please_select_wood_cutting: 'Valitse ystävällisesti "Puu - leikkaus" -esiasetus.',
        },
        newInterface: {
            camera_preview: 'Kameran esikatselu',
            select_image_text: 'Valitse / Kuva / Teksti',
            basic_shapes: 'Perusmuodot',
            pen_tool: 'Kynätyökalu',
            add_new_layer: 'Lisää uusi taso',
            rename_by_double_click: 'Nimeä uudelleen kaksoisnapsauttamalla',
            drag_to_sort: 'Lajittele raahaamalla',
            layer_controls: 'Napsauta hiiren oikealla painikkeella valitaksesi tason hallinta:\nKopioi / Yhdistä / Lukitse / Poista tasoja',
            switch_between_layer_panel_and_object_panel: 'Vaihda tason ja objektin välillä',
            align_controls: 'Kohdistushallinta',
            group_controls: 'Ryhmähallinta',
            shape_operation: 'Muoto-operaatio',
            flip: 'Käännä',
            object_actions: 'Objektitoiminnot',
            end_alert: 'Haluatko varmasti lopettaa uuden käyttöliittymän esittelyn?',
            select_machine: 'Valitse kone',
            start_work: 'Aloita työ',
        },
        gesture: {
            pan: 'Vieritä kangasta kahdella sormella.',
            zoom: 'Lähennä ja loitonna nipistämällä kahta sormea.',
            click: 'Valitse objekti napauttamalla.',
            drag: 'Valitse useita objekteja vetämällä.',
            hold: 'Avaa pikavalikko pitämällä painettuna.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Tässä oli tämän opastuksen anti. Nyt on aika luoda!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: '10 W diodilaser',
        laser_20w_diode: '20 W diodilaser',
        laser_2w_infrared: '2 W infrapunainen laser',
        printing: 'Tulostus',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Haluatko muuntaa tulostusmoduulin lasermoduuliksi?',
            convertFromPrintingModuleMsg: 'Huomaa, että jos suoritat tämän toiminnon, tulostuskerroksen väriasetukset poistetaan ja asetetaan nykyisen kerroksen mukaan.',
            convertFromLaserModuleTitle: 'Haluatko muuntaa lasermoduulin tulostusmoduuliksi?',
            convertFromLaserModuleMsg: 'Huomaa, että jos suoritat tämän toiminnon, laserkerroksen asetukset poistetaan ja asetetaan nykyisen kerroksen mukaan.',
            importedDocumentContainsPrinting: 'Asiakirja sisältää tulostuskerroksen, haluatko vaihtaa työalueen Adoriin?',
            printingLayersCoverted: 'Tulostuskerrokset on muunnettu laserkerroksiksi.',
            performPrintingCaliTitle: 'Suorita tulostusmoduulin kalibrointi',
            performPrintingCaliMsg: 'Napsauta "Vahvista" suorittaaksesi kalibroinnin, tai käytä kalibrointia ylävalikon kautta.<br />(Kone > [Koneen nimi] > Kalibroi tulostusmoduuli)',
            performIRCaliTitle: 'Suorita infrapunamoduulin kalibrointi',
            performIRCaliMsg: 'Napsauta "Vahvista" suorittaaksesi kalibroinnin, tai käytä kalibrointia ylävalikon kautta.<br />(Kone > [Koneen nimi] > Kalibroi infrapunamoduuli)',
        },
        non_working_area: 'Ei-Työskentelyalue',
    },
    qr_code_generator: {
        title: 'QR-koodigeneraattori',
        placeholder: 'Anna linkki tai teksti',
        preview: 'Esikatselu',
        error_tolerance: 'Virhesieto',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Käännä värit',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Peruslaatikko',
        coming_soon: 'Tulossa pian',
        workarea: 'Työalue',
        max_dimension_tooltip: 'Maksimileveys/korkeus/syvyys on %s.',
        volume: 'Tilavuus',
        outer: 'Ulkoinen',
        inner: 'Sisäinen',
        width: 'Leveys',
        height: 'Korkeus',
        depth: 'Syvyys',
        cover: 'Kansi',
        thickness: 'Paksuus',
        add_option: 'Lisää vaihtoehto',
        joints: 'Liitos',
        finger: 'Sormi',
        finger_warning: 'Laatikon sisäpuolen sivun pituuden on oltava vähintään 6 mm (0,24 tuumaa), jotta se on yhteensopiva sormiliitoksen kanssa.',
        tSlot: 'T-ura',
        tSlot_warning: 'Laatikon sivun pituuden on oltava vähintään 30 mm (1,18 tuumaa), jotta se on yhteensopiva T-uriliitoksen kanssa.',
        edge: 'Reuna',
        tCount: 'T-määrä',
        tCount_tooltip: 'T-urien määrä koskee lyhyttä sivua; pitkän sivun määrä lasketaan sen pituuden mukaan.',
        tDiameter: 'T-halkaisija',
        tLength: 'T-pituus',
        continue_import: 'Jatka tuontia',
        customize: 'Mukauta',
        merge: 'Yhdistä',
        text_label: 'Tekstikenttä',
        beam_radius: 'Säteen kompensaatio',
        beam_radius_warning: 'Poista saumakompensaatio, kun laatikon reunat tai liitokset ovat lyhyitä, jotta laatikon kokoaminen onnistuu',
        import: 'Tuo',
        cancel: 'Peruuta',
        reset: 'Nollaa',
        zoom: 'Lähennä',
        control_tooltip: 'Vasen hiiren painike kääntää\nRulla lähentääksesi\nOikea hiiren painike siirtää',
        control_tooltip_touch: 'Vedä kääntääksesi\nNipistä lähentääksesi\nKahdella sormella siirtää',
    },
    my_cloud: {
        title: 'Pilveni',
        loading_file: 'Ladataan...',
        no_file_title: 'Aloita tallentamalla tiedostoja pilveen.',
        no_file_subtitle: 'Siirry valikkoon > "Tiedosto" > "Tallenna pilvipalveluun"',
        file_limit: 'Ilmainen tiedosto',
        upgrade: 'Päivitä',
        sort: {
            most_recent: 'Viimeisimmät',
            oldest: 'Vanhimmat',
            a_to_z: 'Nimi: A - Ö',
            z_to_a: 'Nimi: Ö - A',
        },
        action: {
            open: 'Avaa',
            rename: 'Nimeä uudelleen',
            duplicate: 'Kopioi',
            download: 'Lataa',
            delete: 'Poista',
            confirmFileDelete: 'Haluatko varmasti poistaa tämän tiedoston? Toimintoa ei voi perua.',
        },
        save_file: {
            choose_action: 'Tallenna tiedosto:',
            save: 'Tallenna',
            save_new: 'Tallenna uutena tiedostona',
            input_file_name: 'Anna tiedoston nimi:',
            invalid_char: 'Virheelliset merkit:',
            storage_limit_exceeded: 'Pilvitallennustilasi on saavuttanut ylärajan. Poista kaikki tarpeettomat tiedostot ennen uusien tallentamista.',
        },
    },
    camera_data_backup: {
        title: 'Kameran tietojen varmuuskopiointi',
        no_picture_found: 'Kuvaa ei löytynyt laitteesta.',
        folder_not_exists: 'Valittu kansio ei ole olemassa.',
        incorrect_folder: 'Kalibrointitietojen lataaminen epäonnistui. Tarkista, että valitsemasi kansio on oikea.',
        downloading_data: 'Tietojen lataaminen...',
        estimated_time_left: 'Arvioitu jäljellä oleva aika:',
        uploading_data: 'Tietojen lataaminen...',
        download_success: 'Kameran tiedot on ladattu onnistuneesti.',
        upload_success: 'Kameran tiedot on ladattu onnistuneesti.',
    },
    insecure_websocket: {
        extension_detected: 'Havaittu Beam Studio Connect -laajennus',
        extension_detected_description: "Olemme havainneet, että olet asentanut Beam Studio Connect -laajennuksen. Napsauta 'Vahvista' ohjataksesi HTTPS:ään tai napsauta 'Peruuta' jatkaaksesi HTTP:n käyttöä.",
        extension_not_deteced: 'Beam Studio Connect -laajennusta ei voitu havaita',
        extension_not_deteced_description: "Käyttääksesi HTTPS:ää, napsauta 'Vahvista' asentaaksesi Beam Studio Connect -laajennuksen. Asennettuasi laajennuksen, päivitä sivu aktivoidaksesi sen.<br/>Muussa tapauksessa napsauta alla olevaa linkkiä nähdäksesi, miten HTTP käytetään Chromessa.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Linkki</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Mittaa automaattitarkennusalue',
        amount: 'Määrä',
        gap: 'Välirako',
        rows: 'Rivien määrä',
        coloumns: 'Sarakkeiden määrä',
        row_gap: 'Riviväli',
        column_gap: 'Sarakeväli',
        set_object_height: 'Aseta objektin korkeus',
        set_object_height_desc: 'Mittaa objektin suurin paksuus.',
        reselect_area: 'Valitse alue uudelleen',
        start_autofocus: 'Käynnistä automaattitarkennus',
        starting_measurement: 'Aloitetaan mittaus...',
        preview_3d_curve: 'Esikatselu 3D-käyrä',
        apply_arkwork: 'Käytä taideteosta 3D-käyrällä',
        apply_camera: 'Käytä kamerakuvaa 3D-käyrällä',
        click_to_select_point: 'Napsauta valitaksesi tai poistaaksesi valinnan mittauksesta.',
        remeasure: 'Mittaa uudelleen',
        remeasuring_points: 'Mittauspisteiden uudelleenmittaus...',
        take_reference: 'Ota referenssi',
        take_reference_desc: 'Siirrä laserpää objektin korkeimpaan kohtaan, laske tarkennusanturi ja napsauta "Vahvista" tarkentaaksesi.',
        sure_to_delete: 'Haluatko poistaa 3D-käyrän tarkennustiedot?',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    },
    pass_through: {
        title: 'Läpäisymoodi',
        help_text: 'Kuinka asettaa Läpäisymoodi Adorille?',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'Objektin Pituus',
        workarea_height: 'Työalue (Korkeus):',
        height_desc: 'Aseta kunkin työalueen osan korkeus.',
        ref_layer: 'Viitekerros',
        ref_layer_desc: 'Huomaa, että referenssikerroksen suoritus on oletuksena asetettu arvoon 0. Sitä ei suoriteta, vaan se on vain kohdistusviite.',
        ref_layer_name: 'Viite',
        guide_mark: 'Opasmerkki',
        guide_mark_length: 'Halkaisija:',
        guide_mark_x: 'X-koordinaatti:',
        guide_mark_desc: 'Opasmerkit kaiverretaan viitepisteenä taideteoksen kohdistamiseksi.',
        export: 'Vie Työalueelle',
        exporting: 'Viedään...',
    },
    auto_fit: {
        title: 'Automaattinen Sovitus',
        step1: '1. Aseta kohde haluttuun kohtaan yhdelle materiaalikappaleelle.',
        step2: '2. Paina "Vahvista" kopioidaksesi kohteen kaikille samanlaisille materiaalikappaleille kameran esikatselussa.',
        preview_first: 'Suorita ensin kameran esikatselu.',
        failed_to_auto_fit: 'Automaattinen sovitus epäonnistui, tarkista:',
        error_tip1: '1. Onko taideteos asetettu oikein materiaalille?',
        error_tip2: '2. Ovatko materiaalin ääriviivat riittävän selkeät tunnistamista varten?',
        learn_more: 'Opi, miten Automaattinen Sovitus toimii.',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'Tyyppi',
        object_diameter: 'Kohteen Halkaisija',
        circumference: 'Ympärysmitta',
    },
    framing: {
        low_laser: 'Matala laser',
        low_laser_desc: 'Aseta pienitehoinen laserteho kehyksen tehtävää varten.',
        framing: 'Kehystys',
        hull: 'Runko',
        area_check: 'Alueen tarkistus',
        framing_desc: 'Esikatselee objektin raja-laatikon.',
        hull_desc: 'Esikatselu, joka seuraa tiiviisti muotoilua, kuin kuminauha kietoutuneena objektin ympärille.',
        areacheck_desc: 'Varmistaa työalueen turvallisuuden näyttämällä objektin rajauslaatikon ja laserpään kiihdytysvyöhykkeen.',
        calculating_task: 'Lasketaan tehtävää...',
    },
    material_test_generator: {
        title: 'Materiaalitestin Generaattori',
        table_settings: 'Taulukon Asetukset',
        block_settings: 'Lohkon Asetukset',
        text_settings: 'Tekstiasetukset',
        preview: 'Esikatselu',
        export: 'Vie',
        cut: 'Leikkaa',
        engrave: 'Kaiverra',
        columns: 'Sarakkeet',
        rows: 'Rivit',
        parameter: 'Parametri',
        min: 'Min',
        max: 'Max',
        count: 'Määrä',
        size: 'Koko (KxL)',
        spacing: 'Väli',
    },
    web_cam: {
        no_permission: 'Beam Studiolla ei ole lupaa käyttää kameraa. Varmista, että lupa on myönnetty Beam Studiolle selainasetuksissa tai järjestelmäasetuksissa.',
        no_device: 'Kameralaitetta ei havaittu. Kytke kamera uudelleen ja yritä uudelleen.',
    },
    promark_settings: {
        title: 'Promark-asetukset',
        field: 'Kenttä',
        red_dot: 'Punainen piste',
        galvo_configuration: 'Galvo-konfiguraatio',
        workarea_hint: 'Voit vaihtaa työalueen "Asiakirjan asetuksista".',
        switchXY: 'Vaihda X/Y',
        offsetX: 'Siirtymä X',
        offsetY: 'Siirtymä Y',
        angle: 'Kulma',
        scaleX: 'Mittakaava X',
        scaleY: 'Mittakaava Y',
        scale: 'Mittakaava',
        bulge: 'Pullistuma',
        skew: 'Vinous',
        trapezoid: 'Trapezoidi',
        mark_parameters: 'Merkintäparametrit',
        preview: 'Esikatselu',
        mark: 'Merkitse',
        z_axis_adjustment: {
            title: 'Z-Akselin Säätö',
            section1: 'Säädä Z-akselin korkeutta tarkentaaksesi kohdistusta.',
            tooltip1: 'Kokeile merkitä 1x1 cm neliö varmistaaksesi, että nykyinen polttoväli on sopiva.',
        },
    },
    code_generator: {
        qr_code: 'QR-koodi',
        barcode: 'Viivakoodi',
    },
    barcode_generator: {
        bar_width: 'Palkin leveys',
        bar_height: 'Palkin korkeus',
        text_margin: 'Tekstin marginaali',
        invert_color: 'Käännä värit',
        font: 'Fontti',
        font_size: 'Fontin koko',
        hide_text: 'Piilota teksti',
        barcode: {
            invalid_value: 'Arvo ei kelpaa valittuun muotoon.',
        },
    },
    social_media: {
        instagram: 'Hanki uusimmat inspiraatiot, tarjoukset ja ilmaistuotteet!',
        facebook: 'Keskustele FLUXersin kanssa, kysy kysymyksiä ja opi vinkkejä!',
        youtube: 'Katso Beam Studion opetusohjelmat ja askarteluideat.',
    },
};
export default lang;
