var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Modal, Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import changeWorkarea from 'app/svgedit/operations/changeWorkarea';
import constant, { promarkModels } from 'app/actions/beambox/constant';
import diodeBoundaryDrawer from 'app/actions/canvas/diode-boundary-drawer';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import isDev from 'helpers/is-dev';
import isWeb from 'helpers/is-web';
import LayerModule, { modelsWithModules } from 'app/constants/layer-module/layer-modules';
import localeHelper from 'helpers/locale-helper';
import OpenBottomBoundaryDrawer from 'app/actions/beambox/open-bottom-boundary-drawer';
import presprayArea from 'app/actions/canvas/prespray-area';
import rotaryAxis from 'app/actions/canvas/rotary-axis';
import Select from 'app/widgets/AntdSelect';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import { getPromarkInfo, setPromarkInfo } from 'helpers/device/promark/promark-info';
import { getSupportInfo } from 'app/constants/add-on';
import { LaserType, workareaOptions as pmWorkareaOptions } from 'app/constants/promark-constants';
import { getWorkarea } from 'app/constants/workarea-constants';
import styles from './DocumentSettings.module.scss';
const workareaOptions = [
    { label: 'beamo', value: 'fbm1' },
    { label: 'Beambox', value: 'fbb1b' },
    { label: 'Beambox Pro', value: 'fbb1p' },
    { label: 'HEXA', value: 'fhexa1' },
    { label: 'Ador', value: 'ado1' },
    !isWeb() && (localeHelper.isTwOrHk || isDev()) && { label: 'Promark', value: 'fpm1' },
    (localeHelper.isTwOrHk || isDev()) && { label: 'Beambox II', value: 'fbb2' },
    isDev() && { label: 'Lazervida', value: 'flv1' },
].filter(Boolean);
const promarkLaserOptions = [
    { label: 'Desktop - 20W', value: `${LaserType.Desktop}-20` },
    { label: 'Desktop - 30W', value: `${LaserType.Desktop}-30` },
    { label: 'Desktop - 50W', value: `${LaserType.Desktop}-50` },
    { label: 'MOPA - 20W', value: `${LaserType.MOPA}-20` },
    { label: 'MOPA - 60W', value: `${LaserType.MOPA}-60` },
    { label: 'MOPA - 100W', value: `${LaserType.MOPA}-100` },
];
const dpiOptions = ['low', 'medium', 'high', 'ultra'];
const DocumentSettings = ({ unmount }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { global: tGlobal, beambox: { document_panel: tDocu }, } = useI18n();
    const [engraveDpi, setEngraveDpi] = useState(BeamboxPreference.read('engrave_dpi'));
    const origWorkarea = useMemo(() => BeamboxPreference.read('workarea'), []);
    const [pmInfo, setPmInfo] = useState(getPromarkInfo());
    const [workarea, setWorkarea] = useState(origWorkarea || 'fbb1b');
    const [customDimension, setCustomDimension] = useState((_a = BeamboxPreference.read('customized-dimension')) !== null && _a !== void 0 ? _a : {});
    const supportInfo = useMemo(() => getSupportInfo(workarea), [workarea]);
    const isPromark = useMemo(() => promarkModels.has(workarea), [workarea]);
    const [rotaryMode, setRotaryMode] = useState((_b = BeamboxPreference.read('rotary_mode')) !== null && _b !== void 0 ? _b : 0);
    const [enableJobOrigin, setEnableJobOrigin] = useState((_c = BeamboxPreference.read('enable-job-origin')) !== null && _c !== void 0 ? _c : 0);
    const [jobOrigin, setJobOrigin] = useState((_d = BeamboxPreference.read('job-origin')) !== null && _d !== void 0 ? _d : 1);
    const [extendRotaryWorkarea, setExtendRotaryWorkarea] = useState(!!BeamboxPreference.read('extend-rotary-workarea'));
    const [mirrorRotary, setMirrorRotary] = useState(!!BeamboxPreference.read('rotary-mirror'));
    const [borderless, setBorderless] = useState(!!BeamboxPreference.read('borderless'));
    const [enableDiode, setEnableDiode] = useState(!!BeamboxPreference.read('enable-diode'));
    const [enableAutofocus, setEnableAutofocus] = useState(!!BeamboxPreference.read('enable-autofocus'));
    const [passThrough, setPassThrough] = useState(!!BeamboxPreference.read('pass-through'));
    const isInch = useMemo(() => storage.get('default-units') === 'inches', []);
    const workareaObj = useMemo(() => getWorkarea(workarea), [workarea]);
    const [passThroughHeight, setPassThroughHeight] = useState(BeamboxPreference.read('pass-through-height') || workareaObj.displayHeight || workareaObj.height);
    useEffect(() => {
        if (rotaryMode > 0) {
            setBorderless(false);
            setPassThrough(false);
        }
    }, [rotaryMode]);
    useEffect(() => {
        if (borderless)
            setRotaryMode(0);
        else if (supportInfo.openBottom)
            setPassThrough(false);
    }, [supportInfo, borderless]);
    useEffect(() => {
        if (passThrough)
            setRotaryMode(0);
    }, [passThrough]);
    useEffect(() => {
        if (borderless)
            setPassThroughHeight((cur) => Math.max(cur, workareaObj.height));
    }, [borderless, workareaObj]);
    const handleRotaryModeChange = (on) => setRotaryMode(on ? 1 : 0);
    const showPassThrough = supportInfo.passThrough && (supportInfo.openBottom ? borderless : true);
    const handleSave = () => {
        var _a, _b, _c, _d, _e;
        BeamboxPreference.write('engrave_dpi', engraveDpi);
        const dpiEvent = eventEmitterFactory.createEventEmitter('dpi-info');
        dpiEvent.emit('UPDATE_DPI', engraveDpi);
        BeamboxPreference.write('borderless', supportInfo.openBottom && borderless);
        BeamboxPreference.write('enable-diode', supportInfo.hybridLaser && enableDiode);
        BeamboxPreference.write('enable-autofocus', supportInfo.autoFocus && enableAutofocus);
        const workareaChanged = workarea !== origWorkarea;
        let customDimensionChanged = false;
        if (workareaObj.dismensionCustomizable) {
            const origVal = (_a = BeamboxPreference.read('customized-dimension')) !== null && _a !== void 0 ? _a : {};
            customDimensionChanged =
                ((_b = customDimension[workarea]) === null || _b === void 0 ? void 0 : _b.width) !== ((_c = origVal[workarea]) === null || _c === void 0 ? void 0 : _c.width) ||
                    ((_d = customDimension[workarea]) === null || _d === void 0 ? void 0 : _d.height) !== ((_e = origVal[workarea]) === null || _e === void 0 ? void 0 : _e.height);
            BeamboxPreference.write('customized-dimension', Object.assign(Object.assign({}, origVal), { [workarea]: customDimension[workarea] }));
        }
        const rotaryChanged = rotaryMode !== BeamboxPreference.read('rotary_mode') ||
            extendRotaryWorkarea !== !!BeamboxPreference.read('extend-rotary-workarea');
        BeamboxPreference.write('rotary_mode', rotaryMode);
        if (rotaryMode > 0) {
            if (supportInfo.rotary.extendWorkarea)
                BeamboxPreference.write('extend-rotary-workarea', extendRotaryWorkarea);
            if (supportInfo.rotary.mirror)
                BeamboxPreference.write('rotary-mirror', mirrorRotary);
        }
        const newPassThrough = showPassThrough && passThrough;
        const passThroughChanged = newPassThrough !== !!BeamboxPreference.read('pass-through');
        BeamboxPreference.write('pass-through', newPassThrough);
        const passThroughHeightChanged = passThroughHeight !== BeamboxPreference.read('pass-through-height');
        BeamboxPreference.write('pass-through-height', passThroughHeight);
        BeamboxPreference.write('enable-job-origin', enableJobOrigin);
        BeamboxPreference.write('job-origin', jobOrigin);
        if (workareaChanged ||
            customDimensionChanged ||
            rotaryChanged ||
            passThroughChanged ||
            passThroughHeightChanged) {
            changeWorkarea(workarea, { toggleModule: workareaChanged });
            rotaryAxis.toggleDisplay();
        }
        else {
            // this is called in changeWorkarea
            OpenBottomBoundaryDrawer.update();
            if (supportInfo.hybridLaser && enableDiode)
                diodeBoundaryDrawer.show();
            else
                diodeBoundaryDrawer.hide();
        }
        if (promarkModels.has(workarea))
            setPromarkInfo(pmInfo);
        presprayArea.togglePresprayArea();
        const canvasEvents = eventEmitterFactory.createEventEmitter('canvas');
        canvasEvents.emit('document-settings-saved');
    };
    return (React.createElement(Modal, { open: true, centered: true, width: 440, title: tDocu.document_settings, onCancel: unmount, onOk: () => __awaiter(void 0, void 0, void 0, function* () {
            if (origWorkarea !== workarea &&
                modelsWithModules.has(origWorkarea) &&
                !modelsWithModules.has(workarea) &&
                document.querySelectorAll(`g.layer[data-module="${LayerModule.PRINTER}"]`).length) {
                const res = yield new Promise((resolve) => {
                    alertCaller.popUp({
                        id: 'save-document-settings',
                        message: tDocu.notification.changeFromPrintingWorkareaTitle,
                        messageIcon: 'notice',
                        buttonType: alertConstants.CONFIRM_CANCEL,
                        onConfirm: () => resolve(true),
                        onCancel: () => resolve(false),
                    });
                });
                if (!res)
                    return;
            }
            handleSave();
            unmount();
        }), cancelText: tGlobal.cancel, okText: tGlobal.save },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.block },
                React.createElement("div", { className: styles.row },
                    React.createElement("label", { className: styles.title, htmlFor: "workareaSelect" },
                        tDocu.machine,
                        ":"),
                    React.createElement(Select, { id: "workareaSelect", value: workarea, className: styles.control, bordered: true, onChange: setWorkarea }, workareaOptions.map((option) => (React.createElement(Select.Option, { key: option.value, value: option.value }, option.label))))),
                workareaObj.dismensionCustomizable && (React.createElement("div", { className: styles.row },
                    React.createElement("label", { className: styles.title, htmlFor: "customDimension" },
                        tDocu.workarea,
                        ":"),
                    React.createElement(Select, { id: "customDimension", value: (_f = (_e = customDimension[workarea]) === null || _e === void 0 ? void 0 : _e.width) !== null && _f !== void 0 ? _f : workareaObj.width, className: styles.control, bordered: true, onChange: (val) => setCustomDimension((cur) => (Object.assign(Object.assign({}, cur), { [workarea]: { width: val, height: val } }))) }, pmWorkareaOptions.map((val) => (React.createElement(Select.Option, { key: val, value: val }, `${val} x ${val} mm`)))))),
                isPromark && (React.createElement("div", { className: styles.row },
                    React.createElement("label", { className: styles.title, htmlFor: "pm-laser-source" }, tDocu.laser_source),
                    React.createElement(Select, { id: "pm-laser-source", value: `${pmInfo.laserType}-${pmInfo.watt}`, className: styles.control, bordered: true, onChange: (val) => {
                            const [type, watt] = val.split('-').map(Number);
                            setPmInfo({ laserType: type, watt });
                        } }, promarkLaserOptions.map(({ label, value }) => (React.createElement(Select.Option, { key: value, value: value }, label)))))),
                React.createElement("div", { className: styles.row },
                    React.createElement("label", { className: styles.title, htmlFor: "dpi" },
                        tDocu.engrave_dpi,
                        ":"),
                    React.createElement(Select, { id: "dpi", value: engraveDpi, className: styles.control, bordered: true, onChange: setEngraveDpi }, dpiOptions.map((val) => (React.createElement(Select.Option, { key: val, value: val },
                        tDocu[val],
                        " (",
                        constant.dpiValueMap[val],
                        " DPI)")))))),
            supportInfo.jobOrigin && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.separator },
                    React.createElement("div", null, tDocu.start_position),
                    React.createElement("div", { className: styles.bar })),
                React.createElement("div", { className: styles.block },
                    React.createElement("div", { className: styles.row },
                        React.createElement("label", { className: styles.title, htmlFor: "startFrom" },
                            tDocu.start_from,
                            ":"),
                        React.createElement(Select, { id: "startFrom", value: enableJobOrigin, className: styles.control, bordered: true, onChange: setEnableJobOrigin },
                            React.createElement(Select.Option, { value: 0 }, tDocu.origin),
                            React.createElement(Select.Option, { value: 1 }, tDocu.current_position))),
                    enableJobOrigin === 1 && (React.createElement("div", { className: styles.row },
                        React.createElement("label", { className: styles.title },
                            tDocu.job_origin,
                            ":"),
                        React.createElement("div", { className: styles.control },
                            React.createElement("div", { className: styles.radioGroup }, [1, 2, 3, 4, 5, 6, 7, 8, 9].map((val) => (React.createElement("input", { id: `jobOrigin-${val}`, key: val, name: "jobOrigin", type: "radio", checked: jobOrigin === val, onChange: () => setJobOrigin(val) })))),
                            React.createElement("div", { className: styles['job-origin-example'] },
                                React.createElement("img", { src: "core-img/document-panel/job-origin-example.jpg", alt: "Origin" }),
                                React.createElement("div", { className: classNames(styles.mark, {
                                        [styles.l]: jobOrigin % 3 === 1,
                                        [styles.m]: jobOrigin % 3 === 2,
                                        [styles.r]: jobOrigin % 3 === 0,
                                        [styles.t]: jobOrigin <= 3,
                                        [styles.c]: jobOrigin > 3 && jobOrigin <= 6,
                                        [styles.b]: jobOrigin > 6,
                                    }) })))))))),
            React.createElement("div", { className: styles.separator },
                React.createElement("div", null, tDocu.add_on),
                React.createElement("div", { className: styles.bar })),
            React.createElement("div", { className: styles.modules },
                supportInfo.rotary && (React.createElement("div", { className: classNames(styles.row, {
                        [styles.full]: supportInfo.rotary.mirror || supportInfo.rotary.extendWorkarea,
                    }) },
                    React.createElement("div", { className: styles.title },
                        React.createElement("label", { htmlFor: "rotary_mode" }, tDocu.rotary_mode)),
                    React.createElement("div", { className: styles.control },
                        React.createElement(Switch, { id: "rotary_mode", className: styles.switch, checked: rotaryMode > 0, disabled: !supportInfo.rotary, onChange: handleRotaryModeChange }),
                        (supportInfo.rotary.mirror || supportInfo.rotary.extendWorkarea) &&
                            rotaryMode > 0 && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.subCheckbox },
                                supportInfo.rotary.extendWorkarea && (React.createElement(Checkbox, { checked: extendRotaryWorkarea, onChange: (e) => setExtendRotaryWorkarea(e.target.checked) }, tDocu.extend_workarea)),
                                supportInfo.rotary.mirror && (React.createElement(Checkbox, { checked: mirrorRotary, onChange: (e) => setMirrorRotary(e.target.checked) }, tDocu.mirror)))))))),
                supportInfo.autoFocus && (React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.title },
                        React.createElement("label", { htmlFor: "autofocus-module" }, tDocu.enable_autofocus)),
                    React.createElement("div", { className: styles.control },
                        React.createElement(Switch, { id: "autofocus-module", className: styles.switch, checked: supportInfo.autoFocus && enableAutofocus, disabled: !supportInfo.autoFocus, onChange: setEnableAutofocus })))),
                supportInfo.openBottom && (React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.title },
                        React.createElement("label", { htmlFor: "borderless_mode" }, tDocu.borderless_mode)),
                    React.createElement("div", { className: styles.control },
                        React.createElement(Switch, { id: "borderless_mode", className: styles.switch, checked: supportInfo.openBottom && borderless, disabled: !supportInfo.openBottom, onChange: setBorderless })))),
                supportInfo.hybridLaser && (React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.title },
                        React.createElement("label", { htmlFor: "diode_module" }, tDocu.enable_diode)),
                    React.createElement("div", { className: styles.control },
                        React.createElement(Switch, { id: "diode_module", className: styles.switch, checked: supportInfo.hybridLaser && enableDiode, disabled: !supportInfo.hybridLaser, onChange: setEnableDiode })))),
                showPassThrough && (React.createElement("div", { className: classNames(styles.row, styles.full) },
                    React.createElement("div", { className: styles.title },
                        React.createElement("label", { htmlFor: "pass_through" }, tDocu.pass_through)),
                    React.createElement("div", { className: styles.control },
                        React.createElement(Switch, { id: "pass_through", className: styles.switch, checked: supportInfo.passThrough && passThrough, disabled: !supportInfo.passThrough, onChange: setPassThrough }),
                        passThrough && (React.createElement(React.Fragment, null,
                            React.createElement(UnitInput, { id: "pass_through_height", className: styles.input, value: passThroughHeight, min: (_g = workareaObj.displayHeight) !== null && _g !== void 0 ? _g : workareaObj.height, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, precision: isInch ? 2 : 0, onChange: setPassThroughHeight, size: "small" }),
                            React.createElement(Tooltip, { title: tDocu.pass_through_height_desc },
                                React.createElement(QuestionCircleOutlined, { className: styles.hint })))))))))));
};
export default DocumentSettings;
