// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-CodeGenerator-index-module__modal--Oab6k{min-width:520px}.src-web-app-components-dialogs-CodeGenerator-index-module__title-flex--Oqxoa{line-height:\"24px\";margin-bottom:20}.src-web-app-components-dialogs-CodeGenerator-index-module__fw-n--8xlC1{font-weight:normal}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/CodeGenerator/index.module.scss"],"names":[],"mappings":"AAAA,yEACE,eAAA,CAGF,8EACE,kBAAA,CACA,gBAAA,CAGF,wEACE,kBAAA","sourcesContent":[".modal {\n  min-width: 520px;\n}\n\n.title-flex {\n  line-height: '24px';\n  margin-bottom: 20;\n}\n\n.fw-n {\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "src-web-app-components-dialogs-CodeGenerator-index-module__modal--Oab6k",
	"title-flex": "src-web-app-components-dialogs-CodeGenerator-index-module__title-flex--Oqxoa",
	"fw-n": "src-web-app-components-dialogs-CodeGenerator-index-module__fw-n--8xlC1"
};
export default ___CSS_LOADER_EXPORT___;
