var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import { sprintf } from 'sprintf-js';
import alertCaller from 'app/actions/alert-caller';
import dialog from 'implementations/dialog';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'helpers/react-contextmenu';
import { calibrateChessboard } from 'helpers/camera-calibration-helper';
import ExposureSlider from '../common/ExposureSlider';
import styles from './Chessboard.module.scss';
import useCamera from '../common/useCamera';
const Chessboard = ({ chessboard, updateParam, onNext, onClose }) => {
    const t = useI18n();
    const tCali = useI18n().calibration;
    const [img, setImg] = useState(null);
    const cameraLive = useRef(true);
    const liveTimeout = useRef(null);
    const handleImg = useCallback((imgBlob) => {
        const url = URL.createObjectURL(imgBlob);
        setImg({ blob: imgBlob, url });
        return true;
    }, []);
    const { exposureSetting, setExposureSetting, handleTakePicture } = useCamera(handleImg);
    useEffect(() => {
        if (cameraLive.current) {
            liveTimeout.current = setTimeout(() => {
                handleTakePicture({ silent: true });
                liveTimeout.current = null;
            }, 1000);
        }
    }, [img, handleTakePicture]);
    const handleCalibrate = () => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({ id: 'calibrate-chessboard', message: tCali.calibrating });
        clearTimeout(liveTimeout.current);
        cameraLive.current = false;
        let success = false;
        try {
            const res = yield calibrateChessboard(img.blob, 0, chessboard);
            if (res.success === true) {
                const { ret, k, d, rvec, tvec } = res.data;
                const resp = yield new Promise((resolve) => {
                    let rank = tCali.res_excellent;
                    if (ret > 2)
                        rank = tCali.res_poor;
                    else if (ret > 1)
                        rank = tCali.res_average;
                    alertCaller.popUp({
                        message: sprintf(tCali.calibrate_chessboard_success_msg, rank, ret),
                        buttons: [
                            {
                                label: tCali.next,
                                onClick: () => resolve(true),
                                className: 'primary',
                            },
                            {
                                label: tCali.cancel,
                                onClick: () => resolve(false),
                            },
                        ],
                    });
                });
                if (!resp)
                    return;
                updateParam({ ret, k, d, rvec, tvec });
                onNext();
                success = true;
                return;
            }
            const { reason } = res.data;
            alertCaller.popUpError({ message: `${tCali.failed_to_calibrate_chessboard} ${reason}` });
        }
        catch (error) {
            console.error(error);
        }
        finally {
            progressCaller.popById('calibrate-chessboard');
            if (!success) {
                cameraLive.current = true;
                handleTakePicture({ silent: true });
            }
        }
    });
    const handleDownload = useCallback(() => {
        dialog.writeFileDialog(() => img.blob, 'Save Chessboard Picture', 'chessboard.jpg');
    }, [img]);
    return (React.createElement(Modal, { width: "80vw", open: true, centered: true, maskClosable: false, title: tCali.camera_calibration, okText: tCali.next, cancelText: tCali.cancel, onOk: handleCalibrate, onCancel: () => onClose(false), okButtonProps: { disabled: !img } },
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.desc },
                React.createElement("div", null,
                    "1. ",
                    tCali.put_chessboard_1),
                React.createElement("div", null,
                    "2. ",
                    tCali.put_chessboard_2),
                React.createElement("div", null,
                    "3. ",
                    tCali.put_chessboard_3)),
            React.createElement("div", { className: styles.imgContainer }, img ? (React.createElement(React.Fragment, null,
                React.createElement(ContextMenuTrigger, { id: "chessboard-context-menu", holdToDisplay: -1, holdToDisplayMouse: -1, hideOnLeaveHoldPosition: true },
                    React.createElement("img", { src: img === null || img === void 0 ? void 0 : img.url, alt: "Chessboard" }),
                    React.createElement("div", { className: styles.indicator })),
                React.createElement(ContextMenu, { id: "chessboard-context-menu" },
                    React.createElement(MenuItem, { attributes: { id: 'download' }, onClick: handleDownload }, t.monitor.download)))) : (React.createElement(Spin, { indicator: React.createElement(LoadingOutlined, { className: styles.spinner, spin: true }) }))),
            React.createElement(ExposureSlider, { className: styles.slider, exposureSetting: exposureSetting, setExposureSetting: setExposureSetting, onChanged: handleTakePicture }))));
};
export default Chessboard;
