// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-CodeGenerator-Barcode-module__hidden--EIO6W{display:none}.src-web-app-components-dialogs-CodeGenerator-Barcode-module__visible--n4KGl{display:inline-block}.src-web-app-components-dialogs-CodeGenerator-Barcode-module__error-span--a7kJ3{vertical-align:middle;text-align:center;color:red}.src-web-app-components-dialogs-CodeGenerator-Barcode-module__container---14pm{display:flex;min-height:200px;justify-content:center;align-items:center}.src-web-app-components-dialogs-CodeGenerator-Barcode-module__barcode-svg--VyMNZ{max-width:1000px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/CodeGenerator/Barcode.module.scss"],"names":[],"mappings":"AAAA,4EACE,YAAA,CAGF,6EACE,oBAAA,CAGF,gFACE,qBAAA,CACA,iBAAA,CACA,SAAA,CAGF,+EACE,YAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,iFACE,gBAAA","sourcesContent":[".hidden {\n  display: none;\n}\n\n.visible {\n  display: inline-block;\n}\n\n.error-span {\n  vertical-align: middle;\n  text-align: center;\n  color: red;\n}\n\n.container {\n  display: flex;\n  min-height: 200px;\n  justify-content: center;\n  align-items: center;\n}\n\n.barcode-svg {\n  max-width: 1000px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "src-web-app-components-dialogs-CodeGenerator-Barcode-module__hidden--EIO6W",
	"visible": "src-web-app-components-dialogs-CodeGenerator-Barcode-module__visible--n4KGl",
	"error-span": "src-web-app-components-dialogs-CodeGenerator-Barcode-module__error-span--a7kJ3",
	"container": "src-web-app-components-dialogs-CodeGenerator-Barcode-module__container---14pm",
	"barcode-svg": "src-web-app-components-dialogs-CodeGenerator-Barcode-module__barcode-svg--VyMNZ"
};
export default ___CSS_LOADER_EXPORT___;
