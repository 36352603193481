// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-monitor-Monitor-module__icon--YYqk7{margin-right:6px}", "",{"version":3,"sources":["webpack://./src/web/app/views/monitor/Monitor.module.scss"],"names":[],"mappings":"AAAA,uDACE,gBAAA","sourcesContent":[".icon {\n  margin-right: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "src-web-app-views-monitor-Monitor-module__icon--YYqk7"
};
export default ___CSS_LOADER_EXPORT___;
