var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
// Swiftray Client Typescript API Client
import EventEmitter from 'eventemitter3';
import communicator from 'implementations/communicator';
import deviceMaster from 'helpers/device-master';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import TopBarController from 'app/views/beambox/TopBar/contexts/TopBarController';
import { booleanConfig, getDefaultConfig } from 'helpers/layer/layer-config-helper';
import { getWorkarea } from 'app/constants/workarea-constants';
import { promarkModels } from 'app/actions/beambox/constant';
class SwiftrayClient extends EventEmitter {
    constructor(url) {
        super();
        this.url = url;
        this.retryCount = 0;
        this.maxRetries = 200;
        this.retryDelay = 5000;
        this.port = '';
        this.instanceId = '';
        this.status = 'init';
        this.lastPromark = null;
        this.instanceId = Math.random().toString(36).substr(2, 9);
        this.status = 'init';
        console.log(`Swiftray Client instance ${this.instanceId} created`);
        this.connect();
    }
    get readyState() {
        var _a, _b;
        // Defaults to CLOSED if socket is not initialized
        return (_b = (_a = this.socket) === null || _a === void 0 ? void 0 : _a.readyState) !== null && _b !== void 0 ? _b : WebSocket.CLOSED;
    }
    connect() {
        this.socket = new WebSocket(this.url);
        this.socket.onopen = this.handleOpen.bind(this);
        this.socket.onclose = this.handleClose.bind(this);
        this.socket.onerror = this.handleError.bind(this);
        this.socket.onmessage = this.handleMessage.bind(this);
    }
    updateStatus(newStatus) {
        return __awaiter(this, void 0, void 0, function* () {
            if (newStatus === 'disconnected') {
                if (this.status !== 'connected')
                    return;
                MessageCaller.openMessage({
                    key: 'swiftray-error-hint',
                    content: i18n.lang.message.swiftray_disconnected,
                    level: MessageLevel.ERROR,
                });
                this.emit('disconnected');
                const device = TopBarController.getSelectedDevice();
                this.lastPromark = promarkModels.has(device === null || device === void 0 ? void 0 : device.model) ? device : null;
            }
            else if (newStatus === 'connected' && this.status === 'disconnected') {
                // Reconnect to Promark
                let device = TopBarController.getSelectedDevice();
                let retry = 8;
                let reconnect = false;
                while (!device && this.lastPromark && retry > 0) {
                    // eslint-disable-next-line no-await-in-loop
                    yield new Promise((resolve) => setTimeout(resolve, 2000));
                    device = TopBarController.getSelectedDevice();
                    retry -= 1;
                }
                if (promarkModels.has(device === null || device === void 0 ? void 0 : device.model)) {
                    const resp = yield deviceMaster.select(device);
                    reconnect = resp.success;
                    console.log('Reconnect to Promark', reconnect);
                }
                MessageCaller.openMessage({
                    key: 'swiftray-error-hint',
                    content: i18n.lang.message.swiftray_reconnected,
                    level: MessageLevel.SUCCESS,
                });
                this.emit('reconnected', reconnect);
            }
            this.status = newStatus;
        });
    }
    handleOpen() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            console.log('Connected to Swiftray server 🎉');
            this.updateStatus('connected');
            this.retryCount = 0;
            const resp = yield this.getSystemInfo();
            console.log(`Swiftray version ${(_a = resp === null || resp === void 0 ? void 0 : resp.info) === null || _a === void 0 ? void 0 : _a.swiftrayVersion}`);
        });
    }
    handleClose() {
        console.log('Disconnected from Swiftray server');
        this.updateStatus('disconnected');
        this.retry();
    }
    handleError(error) {
        console.error('Error connecting to Swiftray server:', error, this.retryCount);
    }
    retry() {
        if (this.retryCount < this.maxRetries) {
            this.retryCount += 1;
            console.log(`Retrying connection (attempt ${this.retryCount})`);
            setTimeout(() => {
                this.connect();
            }, this.retryDelay);
        }
        else {
            throw new Error('Failed to connect to Swiftray server after maximum retries');
        }
    }
    handleMessage(event) {
        const data = JSON.parse(event.data);
        this.emit(data.type, data);
    }
    action(path, action, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const id = Math.random().toString(36).substr(2, 9);
                const payload = { id, action, params };
                this.socket.send(JSON.stringify({ type: 'action', path, data: payload }));
                const callback = (data) => {
                    if (data.id === id) {
                        this.removeListener('callback', callback);
                        // console.log("SR Client Callback", data);
                        resolve(data.result);
                    }
                };
                this.addListener('callback', callback);
            });
        });
    }
    // Parser API
    loadSVG(file, eventListeners, loadOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const defaultConfig = getDefaultConfig();
            booleanConfig.forEach((key) => {
                if (defaultConfig[key])
                    defaultConfig[key] = 1;
            });
            const uploadRes = yield this.action('/parser', 'loadSVG', {
                file,
                model: loadOptions.model,
                rotaryMode: loadOptions.rotaryMode,
                engraveDpi: loadOptions.engraveDpi,
                defaultConfig,
            });
            return uploadRes;
        });
    }
    convert(type, eventListeners, convertOptions) {
        return __awaiter(this, void 0, void 0, function* () {
            const workarea = getWorkarea(convertOptions.model);
            const convertResult = yield this.action('/parser', 'convert', Object.assign({ type, workarea: {
                    width: workarea.width,
                    height: workarea.displayHeight || workarea.height,
                } }, convertOptions));
            const taskBlob = new Blob([type === 'fcode' ? Buffer.from(convertResult.fcode, 'base64') : convertResult.gcode], { type: 'text/plain' });
            eventListeners.onFinished(taskBlob, convertResult.fileName, convertResult.timeCost, convertResult.metadata);
            return {
                success: convertResult.success,
                error: convertResult.error,
            };
        });
    }
    interruptCalculation() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action('/parser', 'interrupt');
        });
    }
    loadSettings(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action('/parser', 'loadSettings', data);
        });
    }
    // System API
    getSystemInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action('/ws/sr/system', 'getInfo');
        });
    }
    // Device API
    listDevices() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action('/devices', 'list');
        });
    }
    connectDevice(port) {
        return __awaiter(this, void 0, void 0, function* () {
            this.port = port;
            return this.action(`/devices/${port}`, 'connect');
        });
    }
    startTask() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'start');
        });
    }
    pauseTask() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'pause');
        });
    }
    resumeTask() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'resume');
        });
    }
    stopTask() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'stop');
        });
    }
    getDeviceParam(name) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'getParam', { name });
        });
    }
    setDeviceParam(name, value) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'setParam', { name, value });
        });
    }
    setScanaheadParams(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'setScanaheadParams', params);
        });
    }
    setDeviceCorrection(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'setCorrection', data);
        });
    }
    getDeviceSettings() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'getSettings');
        });
    }
    updateDeviceSettings(settings) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'updateSettings', settings);
        });
    }
    deleteDeviceSettings(name) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'deleteSettings', { name });
        });
    }
    updateFirmware(blob) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'updateFirmware', blob);
        });
    }
    endMode() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'endMode');
        });
    }
    switchMode(mode) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'switchMode', mode);
        });
    }
    quitTask() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'quit');
        });
    }
    downloadLog(logName) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'downloadLog', logName);
        });
    }
    downloadFile(fileNameWithPath) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'downloadFile', fileNameWithPath);
        });
    }
    close() {
        return __awaiter(this, void 0, void 0, function* () {
            console.error('Someone trying to close the Swiftray client');
            console.trace();
            this.socket.close();
        });
    }
    deviceInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'info');
        });
    }
    getPreview() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'getPreview');
        });
    }
    startFraming() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'startFraming');
        });
    }
    stopFraming() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'stopFraming');
        });
    }
    kick() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'kick');
        });
    }
    upload(data, path) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const text = yield data.text();
                return yield this.action(`/devices/${this.port}`, 'upload', { data: text, path });
            }
            catch (e) {
                return this.action(`/devices/${this.port}`, 'upload', { data, path });
            }
        });
    }
    sendGCode(command) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'sendGCode', command);
        });
    }
    getDeviceStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'getStatus');
        });
    }
    home() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.action(`/devices/${this.port}`, 'home');
        });
    }
}
const checkSwiftray = () => {
    const res = !isWeb() && window.os !== 'Linux';
    if (!res)
        return false;
    return communicator.sendSync('CHECK_SWIFTRAY');
};
const hasSwiftray = checkSwiftray();
const swiftrayClient = new SwiftrayClient('ws://localhost:6611');
const getDeviceClient = (port) => __awaiter(void 0, void 0, void 0, function* () {
    console.log(`Connecting to device on port ${port}`);
    // TODO:SWIFTRAY - Open a new instance of Swiftray, and use different port number
    // const sc = new SwiftrayClient(`ws://localhost:6611/`);
    yield swiftrayClient.connectDevice(port);
    return swiftrayClient;
});
export { hasSwiftray, swiftrayClient, // default connection to Swiftray server
getDeviceClient, SwiftrayClient, };
